import React,{useEffect, useState} from "react";

export const SubAnalyticalStat = (props) =>{

    const [loadingTime, setloadingTime]  = useState(true)

    useEffect(()=>{
        setloadingTime(props.loading)
    },[props.loading])
    
    return (
        <>
            <div className="p-col-12 p-md-4">
            <div className="card widget-overview-box widget-overview-box-1">
                <span className="overview-title">
                {props.header}
                </span>
                <div className="p-jc-between">
                    <div className="overview-detail p-d-flex p-jc-between">
                        <div className="overview-text">
                            {
                                loadingTime ?
                                <b><i className="pi pi-spin pi-spinner" /></b>:
                                <b>{props.value}</b>
                            }
                        </div>                               
                    </div>
                </div>
                <i className={`${props.icon} side-icon`} style={{ fontSize: '2rem' }}></i>
            </div>
            </div>

        </>
    )
}



export const SubAnalyticalStatForTickets = (props) =>{

    const [loadingTime, setloadingTime]  = useState(true)

    useEffect(()=>{
        setloadingTime(props.loading)
    },[props.loading])
    
    return (
        <>
            
            <div className="card widget-overview-box widget-overview-box-1">
                <span className="overview-title">
                {props.header}
                </span>
                <div className="p-jc-between">
                    <div className="overview-detail p-d-flex p-jc-between">
                        <div className="overview-text">
                            {
                                loadingTime ?
                                <b><i className="pi pi-spin pi-spinner" /></b>:
                                <b>{props.value}</b>
                            }
                        </div>                               
                    </div>
                </div>
                <i className={`${props.icon} side-icon`} style={{ fontSize: '2rem' }}></i>
            </div>
            

        </>
    )
}