import React, { useEffect, useState , useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

export const AddFacility = (props) =>{

    let facilityTemplate = {
        d_id: ["self"],
        userid: null,
        facility:[{}]
    }

    let corefacilityTemp = {
        admin_default: false,
        admins: [],
        billing_default: false,
        facname: ""
    } 

    let facBillingDetails = {
        addr: "",
        city: {id: "", name: "", state_id: ""},
        cmpname: "",
        country: {id: "", sortname: "", name: "", phonecode: ""},
        gstnumber: "",
        locality: "",
        state: {id: "", name: "", country_id: ""}
    }

    let facAddresstemp = {

        address: "",                
        city: {id: "", name: "", state_id: ""},
        country: {id: "", sortname: "", name: "", phonecode: ""},
        state: {id: "", name: "", country_id: ""}
    }

    let setAdminTemp = { name : '', cno : ''}


    const [facilityDialog,setFacilityDialog] = useState(false)
    const [selectCountry, setSelectCountry] = useState(null);
    const [country, setCountry] = useState(null);
    const [selectState, setSelectState] = useState(null);
    const [state, setState] = useState(null);
    const [selectCity, setSelectCity] = useState(null);
    const [city, setCity] = useState(null);
    const [defaultAdmin, setDefaultAdmin] = useState(true);    
    const [facAddress, setFacAddress] = useState(null);
    const [bselectCountry, setBSelectCountry] = useState(null);
    const [bstate, setBState] = useState(null);
    const [bselectState, setBSelectState] = useState(null);
    const [bcity, setBCity] = useState(null);
    const [bselectCity, setBSelectCity] = useState(null);
    const [facilityTemp, setfacilityTemp] = useState(facilityTemplate);
    const [coreFacilityTemp, setCoreFacilityTemp] = useState(corefacilityTemp);
    const [facBilling, setfacBilling] = useState(facBillingDetails);
    const [facilityADTemp, setfacilityADTemp] = useState(facAddresstemp);
    
    const [facility, setFacility] = useState([{facname:'none'}]);
    const [facilityItem, setFacilityItem] = useState(null);
    const [formAction, setFormAction] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [addFacilityTemplate, setAddFacilityTemplate] = useState(null);
    const [adminTemplate, setAdminTemplate] = useState(setAdminTemp);
    const [allAdmins, setAllAdmins] = useState([]);
    const [isLead, setIsLead] = useState(false);
    const [copyAddress, setCopyAddress] = useState(false);

    
    
    const toast = useRef(null);

    const success = (msg) =>{
        toast.current.show({ severity: 'success', summary: 'Successful', detail:`${msg}`, life: 3000 });
    } 

    const failure = (msg) => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail:`${msg}`, life: 3000 });
    }

    useEffect(()=>{
        setFacilityDialog(props.facilityDialog) 
        setFormDefault()
    },[props.facilityDialog])
    
    useEffect(()=>{
        getCountry()
    },[])

    const setFormDefault = () =>{
        setAdminTemplate(setAdminTemp)
        setSelectCountry(null)
        setSelectState(null)
        setSelectCity(null)
        setAllAdmins([])
        setBSelectCountry(null)
        setBSelectState(null)
        setBSelectCity(null)
        setFacAddress(null)
        setAddFacilityTemplate(null)
        
    }

    useEffect((x)=>{
        setSelectedAccount(props.selectedAccount)
        setIsLead(false)
    },[props.selectedAccount])

    useEffect((x)=>{
        setSelectedAccount(props.selectedLeads)
        setIsLead(true)
    },[props.selectedLeads])


    const getCountry = async() =>{
        const response = await apiServices.getCountry();
        setCountry(response?.data)
        setBSelectCountry(response?.data)
    }



    const facilitySubmit = async(e) =>{
        e.preventDefault();
        
        if(isLead){

            const finalData = {...addFacilityTemplate,admins : allAdmins , admin_default : defaultAdmin, billing_default : false}
            props.getFacilityDataForLead(finalData)
            success('Facility Added Successfully')
            props.getFac(selectedAccount.userid)

        }else{
                
            const finalData = {...addFacilityTemplate,admins : allAdmins , admin_default : defaultAdmin, billing_default : false}
            const finalOne = { d_id: ["self"],userid: selectedAccount.userid , facility :[finalData] }
            const response = await apiServices.addFacility(finalOne);  
            response?.status == 200 ? success('Facility Added Successfully') : failure('Failed to Add')
            props.getFac(selectedAccount.userid)           
        }
        
        // props.getMachines()            
        props.setFacilityDialog(false)  
    }

    const changeFac = (e) =>{
        setFacilityItem(e.value)
        setCoreFacilityTemp({...coreFacilityTemp,[e.target.name]:e.target.value})
        setAddFacilityTemplate({...addFacilityTemplate,[e.target.name]:e.target.value})
    }

    const changeFacAddr = (e) =>{
        setfacilityADTemp({...facilityADTemp,[e.target.name]:e.target.value})
        setFacAddress(e.target.value)

        setAddFacilityTemplate({...addFacilityTemplate,address : {...addFacilityTemplate?.address,[e.target.name]:e.target.value}})
    }



    const changePin = async(e) =>{

        if(e.target.value.toString().length == 6 && !isNaN(Number(e.target.value))){

            // setAddFacilityTemplate({...addFacilityTemplate,address : {...addFacilityTemplate?.address,[e.target.name]:Number(e.target.value)}})            
            const response = await apiServices.getPincodeDetails(e.target.value);

            if(response?.data[0]?.PostOffice?.length > 0){

                const countryResult = response?.data[0]?.PostOffice[0].Country;
                const stateResult = response?.data[0]?.PostOffice[0].State;
                const cityResult = response?.data[0]?.PostOffice[0].District;
                const blockResult = response?.data[0]?.PostOffice[0].Block;
                const divisionResult = response?.data[0]?.PostOffice[0].Division;
                
                const defaultCountry = country.filter((x)=>{ if(x.name == countryResult){ return x; } })
                setSelectCountry(defaultCountry[0])

                const res = await apiServices.getState({ country_id: defaultCountry[0].id });
                setState(res?.data);
            
                const defaultState = res?.data?.filter((x)=>{ if(x.name == stateResult){ return x; }})
                setSelectState(defaultState[0])

                const res1 = await apiServices.getCity({ state_id: defaultState[0].id });
                setCity(res1.data);

                const defaultCity = res1?.data?.filter((x)=>{ if(x.name == cityResult || x.name == blockResult || x.name == divisionResult ){ return x; }})
                setSelectCity(defaultCity[0])

                

                setAddFacilityTemplate({...addFacilityTemplate,address : {...addFacilityTemplate?.address, country : defaultCountry[0] , state : defaultState[0] , city : defaultCity[0] , pincode : e.target.value }})


                // if(defaultCity && defaultCity[0].name){
                //     const LatLongResponse = await apiServices.getCityLatLong();
                //     LatLongResponse.map((x)=>{
                //         if(x.name == cityResult || x.name == blockResult || x.name == divisionResult){
                //             setAddFacilityTemplate({...addFacilityTemplate,address : {...addFacilityTemplate?.address, location : { lat: x.lat , lng : x.lon } }})
                //         }else{

                //             setAddFacilityTemplate({...addFacilityTemplate,address : {...addFacilityTemplate?.address, [e.target.name]:e.value}})
                //         }
                //     })
                // }


            }
        }
    }



    const changeCountry = async(e)=>{
        setSelectCountry(e.value)
        console.log(e.value);
        const response = await apiServices.getState({country_id: e.value.id});  
        setState(response?.data)
        setfacilityADTemp({...facilityADTemp,[e.target.name]:e.value})

        setAddFacilityTemplate({...addFacilityTemplate,address : {...addFacilityTemplate?.address, [e.target.name]:e.value}})

    }

    const changeState = async(e) =>{
        setSelectState(e.value)
        const response = await apiServices.getCity({state_id: e.value.id});  
        setCity(response.data)
        setfacilityADTemp({...facilityADTemp,[e.target.name]:e.value})

        setAddFacilityTemplate({...addFacilityTemplate,address : {...addFacilityTemplate?.address, [e.target.name]:e.value}})

    }

    const changeCity = async(e) =>{
        setSelectCity(e.value)
        setfacilityADTemp({...facilityADTemp,[e.target.name]:e.value})

        setAddFacilityTemplate({...addFacilityTemplate,address : {...addFacilityTemplate?.address, [e.target.name]:e.value}})

    }

    const changeLatLong = (e) =>{
        setAddFacilityTemplate({...addFacilityTemplate,address : {...addFacilityTemplate?.address, location : {...addFacilityTemplate?.address?.location,[e.target.name]:e.target.value } }})
    }


    const changeAdmin = (e) =>{
        setAdminTemplate({...adminTemplate,[e.target.name]:e.target.value})
    }

    const addAdmin = () =>{
        console.log(adminTemplate)
        const x = [...allAdmins,adminTemplate]
        setAllAdmins(x)
        // console.log(x);        
    }


    const handleAdminAuth = (e) =>{
        setDefaultAdmin(e.target.checked)
        setCoreFacilityTemp({...coreFacilityTemp,[e.target.name]:e.target.checked})
        setAllAdmins([])
        setAdminTemplate(setAdminTemp)
        // setAddFacilityTemplate({...addFacilityTemplate,[e.target.name]:e.target.checked})
    }

    const handleCopyAdd = async(e) =>{
        
        setCopyAddress(e.target.checked)

        if(e.target.checked){

            const countryResult =  addFacilityTemplate?.address?.country?.name
            const cityResult = addFacilityTemplate?.address?.city?.name
            const stateResult = addFacilityTemplate?.address?.state?.name

            const defaultCountry = country.filter((x)=>{ if(x.name == countryResult){ return x; } })
            console.log();
            setBSelectCountry(defaultCountry[0])

            const res = await apiServices.getState({ country_id: defaultCountry[0]?.id });
            setBState(res?.data)
        
            const defaultState = res?.data?.filter((x)=>{ if(x.name == stateResult){ return x; }})
            setBSelectState(defaultState[0])

            const res1 = await apiServices.getCity({ state_id: defaultState[0]?.id });
            setBCity(res1.data)

            const defaultCity = res1?.data?.filter((x)=>{ if(x.name == cityResult ){ return x; }})
            setBSelectCity(defaultCity[0]);

            setAddFacilityTemplate({...addFacilityTemplate,billing_details : {...addFacilityTemplate?.billing_details,pincode : addFacilityTemplate?.address?.pincode , addr : addFacilityTemplate?.address?.address , country : defaultCountry[0] , city : defaultCity[0] , state : defaultState[0] }})            


        }else{

            setBSelectCountry(null)
            setBSelectCity(null)
            setBSelectState(null)
            setAddFacilityTemplate({...addFacilityTemplate,billing_details : {...addFacilityTemplate?.billing_details,pincode : '' , addr : '' , country : null , city : null , state : null }})            

        }
    }



    const facComp = (e) =>{
        setfacBilling({...facBilling,[e.target.name]:e.target.value})

        setAddFacilityTemplate({...addFacilityTemplate,billing_details : {...addFacilityTemplate?.billing_details,[e.target.name]:e.target.value}})
    }

    const facExt = (e) =>{
        setAddFacilityTemplate({...addFacilityTemplate,billing_details : {...addFacilityTemplate?.billing_details,[e.target.name]:Number(e.target.value)}})
    }

    const changeFacPin = (e) =>{
        e.target.value.length <= 6 && setAddFacilityTemplate({...addFacilityTemplate,billing_details : {...addFacilityTemplate?.billing_details,[e.target.name]:Number(e.target.value)}})
    }

    const billingCountry = async(e)=>{
        setBSelectCountry(e.value)
        console.log(e.value);
        const response = await apiServices.getState({country_id: e.value.id});  
        setBState(response.data)
        setfacBilling({...facBilling,[e.target.name]:e.target.value})
        setAddFacilityTemplate({...addFacilityTemplate,billing_details : {...addFacilityTemplate?.billing_details,[e.target.name]:e.target.value}})
    }

    const billingState = async(e) =>{
        setBSelectState(e.value)
        const response = await apiServices.getCity({state_id: e.value.id});  
        setBCity(response.data)
        setfacBilling({...facBilling,[e.target.name]:e.target.value})
        setAddFacilityTemplate({...addFacilityTemplate,billing_details : {...addFacilityTemplate?.billing_details,[e.target.name]:e.target.value}})
    }

    const billingCity = async(e) =>{
        setBSelectCity(e.value)
        setfacBilling({...facBilling,[e.target.name]:e.target.value})
        setAddFacilityTemplate({...addFacilityTemplate,billing_details : {...addFacilityTemplate?.billing_details,[e.target.name]:e.target.value}})
    }   

    const removeFac = (e) =>{
        delete allAdmins[e.target.id]
        const arr = allAdmins.filter((x)=>{return x != null})
        setAllAdmins(arr)
    }   
    
    const facActionTemplate = (rowData,props) => { return ( <> <Button icon="pi pi-times" title="Delete" id={props.rowIndex} className="p-button-rounded p-button-danger p-button-text" onClick={removeFac}/></>)}

    const commonBodyTemplate = (rowData,props) => { return ( <> <span className="p-column-title">{props?.header}</span> {rowData[props?.field]} </>)}

    const facilityDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={()=>{props.setFacilityDialog(false)}} />
            <Button label="Submit" type="submit" form='add-facility' icon="pi pi-plus" className="p-button-success"/>
        </>
    );

    return (

        <>
            <Toast ref={toast} position='center'/>

            <Dialog visible={facilityDialog} style={{ width: '850px' }} header="Add Facility" className="p-fluid" modal footer={facilityDialogFooter} onHide={()=>{props.setFacilityDialog(false)}}  >
            <form onSubmit={facilitySubmit} id="add-facility" className="p-fluid">    
                <h5 className='p-mt-2'>Facility Details</h5>
                <div className="p-field">
                    <label htmlFor="facname">Facility Name</label>
                    <InputText id="facname" name='facname' onChange={changeFac} placeholder='Facility Name' required autoFocus autoComplete="off" />
                </div>
                <div className="p-field">
                    <label htmlFor="facadd">Address</label>
                    <InputText id="facadd" name='address' onChange={changeFacAddr} placeholder='Address' autoComplete="off" required/>
                </div>
                <div className='p-grid'>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="pincode">Pincode</label>
                            <InputText id="pincode" maxLength={6} name='pincode' value={addFacilityTemplate?.address?.pincode ? addFacilityTemplate?.address?.pincode : null} onChange={changePin} placeholder='Pincode' autoComplete="off" required/>
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="faccoun">Country</label>
                            <Dropdown id="faccoun" name='country' value={selectCountry} onChange={changeCountry}  options={country} optionLabel="name" placeholder="Select Country" disabled={formAction} filter required></Dropdown>
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="facstate">State</label>
                            <Dropdown id="facstate" name='state' value={selectState} onChange={changeState}  options={state} optionLabel="name" placeholder="Select State" disabled={formAction} filter required></Dropdown>
                        </div>
                    </div>
                    <div className="p-col-6">
                        <div className="p-field">
                            <label htmlFor="faccity">City</label>
                            <Dropdown id="faccity" name='city' value={selectCity}  onChange={changeCity} options={city} optionLabel="name" placeholder="Select City" disabled={formAction} filter required></Dropdown>
                        </div>
                    </div>

                </div>
                

                <div className="p-field">
                    <label htmlFor="lat">Lattitude</label>
                    <InputText id="lat" name='lat' defaultValue={addFacilityTemplate?.address?.location?.lat} onChange={changeLatLong} placeholder='Lattitude' autoComplete="off" required/>
                </div>

                <div className="p-field">
                    <label htmlFor="lng">Longitude</label>
                    <InputText id="lng" name='lng' defaultValue={addFacilityTemplate?.address?.location?.lng} onChange={changeLatLong} placeholder='Longitude' autoComplete="off" required/>
                </div>

                <div className="p-field-checkbox">
                    <Checkbox name="admin_default" checked={defaultAdmin} onChange={handleAdminAuth}  />
                    <label>Default Admin details</label>
                </div>

                {
                    !defaultAdmin &&
                    <div className="Admin_default_section">
                        <div className="Facility_defaultsettings">
                        <h5>Admin Details</h5>
                        </div>
                        <div className="p-grid"> 
                        <div className="p-field p-col-6 ">
                            <label htmlFor="experiod"> Admin Name </label>
                            <InputText type="text" name="name"  placeholder="Admin Name" autoComplete="off" onChange={changeAdmin} required />
                        </div>
                        <div className="p-field p-col-6 ">
                            <label htmlFor="experiod">Admin Contact</label>
                            <InputText type="text" maxLength={10} name="cno"  placeholder="Admin Contact" autoComplete="off" onChange={changeAdmin} required />
                        </div>
                        <div className="p-field p-col-2 ">
                            <Button label="Add New"  icon="pi pi-check" className="p-button-info" disabled={adminTemplate?.name == '' || adminTemplate?.cno == ''} onClick={addAdmin}  />
                        </div>
                        </div>

                        <div className="p-grid crud-demo">
                            <div className="card" >
                            <DataTable className="datatable-responsive" value={allAdmins} style={{margin:'0 auto', textAlign:'center'}} responsiveLayout="scroll">
                                <Column field="name" header="Admin Name" body={commonBodyTemplate}></Column>
                                <Column field="cno" header="Admin Contact" body={commonBodyTemplate}></Column>
                                <Column field="Action" header="Action" body={facActionTemplate}></Column>
                            </DataTable>
                            </div>
                        </div>

                    </div>
                }

                <h5 className='p-mt-2'>BILLING DETAILS</h5>


                <div className="p-field-checkbox">
                    <Checkbox name="" checked={copyAddress} onChange={handleCopyAdd}  />
                    <label>Same As Above</label>
                </div>

                <div className="p-field">
                    <label htmlFor="extension">Extension Period (Calender Days)</label>
                    <InputText id="extension" type='number' name='extension' onChange={facExt} placeholder='Extension Period (Calender Days)' autoComplete="off" required/>
                </div>
                
                <div className="p-field">
                    <label htmlFor="faccname">Company Name</label>
                    <InputText id="faccname" name='cmpname' onChange={facComp} placeholder='Company Name' autoComplete="off" required/>
                </div>
                <div className="p-field">
                    <label htmlFor="facgst">GST Number</label>
                    <InputText id="facgst" name='gstnumber' onChange={facComp} placeholder='GST Number' autoComplete="off" required/>
                </div>
                <div className="p-field">
                    <label htmlFor="faccadd">Address</label>
                    <InputText id="faccadd" name='addr' defaultValue={addFacilityTemplate?.billing_details?.addr} onChange={facComp} placeholder='Address' autoComplete="off" required/>
                </div>

                <div className="p-field">
                    <label htmlFor="pincode">Pincode</label>
                    <InputText id="pincode" type='number' name='pincode' value={addFacilityTemplate?.billing_details?.pincode} onChange={changeFacPin} placeholder='Pincode' autoComplete="off" required/>
                </div>

                {/* <div className="p-field">
                    <label htmlFor="facloc">Locality</label>
                    <InputText id="facloc" name='locality' onChange={facComp} placeholder='Locality' autoComplete="off" required/>
                </div> */}
                <div className='p-grid'>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="faccoun">Country</label>
                            <Dropdown id="faccoun" name='country' value={bselectCountry}  onChange={billingCountry} options={country} optionLabel="name" placeholder="Select Country" disabled={formAction} filter required></Dropdown>
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="facstate">State</label>
                            <Dropdown id="facstate" name='state' value={bselectState} onChange={billingState}  options={bstate} optionLabel="name" placeholder="Select State" disabled={formAction} filter required></Dropdown>
                        </div>
                    </div>
                </div>
                <div className="p-field">
                    <label htmlFor="faccity">City</label>
                    <Dropdown id="faccity" name='city' value={bselectCity}  onChange={billingCity} options={bcity} optionLabel="name" placeholder="Select City" disabled={formAction} filter required></Dropdown>
                </div>   
            </form>
        </Dialog>
        
        </>
    )
}