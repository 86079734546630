import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { SubAnalyticalStat } from '../NewAnalytics.js/SubAnalyticalStat';
import { CommonBodyForFormattedStrTable, CommonBodyForTable, DateBodyForTable, srBodyTemplate } from '../Common/CommonComponents';
import { successToast, failureToast, getFormattedAmount, getFormattedString } from '../Common/CommonFunctions';
import { commonCloseDialogFooter, commonDialogFooter } from '../Common/CommonComponents';
import { invoiceStatusItems, invoiceTypeItems, durationOptions } from './Constant';
// Context
import { permissionContext } from '../../AppWrapper';
//
import { dateBodyForTable, fileBodyForTable } from '../Users/ActiveUsers';
// Services
import CommonCal from '../../service/common/CommonCal';
import ApiServices from '../../service/api/ApiServices';

const apiServices = new ApiServices();
const commonCal = new CommonCal();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ==========================================================================================
// ==========================================================================================

export const InvoicePage = () => {

    const rolePermissions = useContext(permissionContext);

    const [globalFilter1, setGlobalFilter1] = useState('');
    const [globalFilter2, setGlobalFilter2] = useState('');
    const [loading1, setLoading1] = useState(true);
    const [allMachines, setAllMachines] = useState();
    const [usersByCtvm, setUsersByCtvm] = useState();
    const [allUsersByCtvm, setAllUsersByCtvm] = useState();
    const [invoiceModal, setInvoiceModal] = useState(false);
    const [consumptionInvoice, setConsumptionInvoice] = useState(null);
    const [subscriptionInvoice, setSubscriptionInvoice] = useState(null);
    const [invoiceListForTable, setInvoiceListForTable] = useState([]);
    const [allInvoices, setAllInvoices] = useState([]);
    const [invoiceList, setInvoiceList] = useState(null);
    const [selectedInType, setSelectedInType] = useState({ name: 'All', code: 'all' });
    const [selectedInStatus, setSelectedInStatus] = useState({ name: 'All', code: 'all' });
    const [selectedAcc, setSelectedAcc] = useState(null)
    const [selectedMachine, setSelectedMachine] = useState({ displayLabel: 'All' })
    const [timeselectValue, setTimeselectValue] = useState({ name: 'All', code: 'all' });
    const [allDates, setAllDates] = useState(null);
    const [customFilterDialog, setCustomFilterDialog] = useState(false);
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [modelLoading, setModelLoading] = useState(true);
    const [deleteInvoiceOrderDialog, setDeleteInvoiceOrderDialog] = useState(false);
    const [invoiceOrder, setInvoiceOrder] = useState(null);
    const [viewCvesDetailedDialog, setViewCvesDetailedDialog] = useState(false);
    const [agreementsDialog, setAgreementsDialog] = useState(false);
    const [custAgreements, setCustAgreements] = useState([]);
    const [accountsCves, setAccountsCves] = useState(null);
    const [billingStat, setBillingStat] = useState({});
    const [statLoading, setStatLoading] = useState(true);
    const [widgetData, setWidgetData] = useState({})
    const toast = useRef(null);

    useEffect(() => {
        getMachine();
        getUserByCTVM();
        getCustomerDataDirectly()
        getDates()
        getBillingStats()
    }, []);

    const getBillingStats = async() =>{
        setStatLoading(true)
        const response = await apiServices.getAllBillingStats({});
        console.log(response?.data?.data);
        // const advance = response?.data?.data?.reduce((acc, total) => { return acc + Number(total?.Advance_Payment_Amounts) }, 0)
        // const pending = response?.data?.data?.reduce((acc, total) => { return acc + Number(total?.Pending_Payment_Amount) }, 0)
        // const received = response?.data?.data?.reduce((acc, total) => { return acc + Number(total?.Received_Payment_Amounts) }, 0)
        // const data = { advance , pending, received }
        setBillingStat(response?.data?.data)
        setStatLoading(false)
    }

    const getMachine = async () => {

        const response = await apiServices.findMachines();
        const response1 = await apiServices.getUserReduced();
        const response2 = await apiServices.getUserReduced();
        const userred = response2?.data?.data.map((x) => { return x.userid })
        let arr = []
        response?.data?.data?.forEach(x => { if (userred.includes(x.customer)) { arr.push(x) } });
        console.log(arr);
        const result = arr.filter((x) => { return x.datastore?.stage == "finishedgoods" && x.subscription?.package?.site == "permanent" && x.siteName != "" })
        var resultedMachine = result.map((x) => {
            const subid = x.subid ? x.subid : 'CIPL';
            const address = response1.data.data.find(o => o.userid === x.customer)
            return { 
                ...x, 
                displayLabel: `${x.id} / ${subid} / ${address?.billing_details?.company}`, 
                address: address?.addr 
            };
        });

        resultedMachine.unshift({ displayLabel: 'All' });
        setAllMachines(resultedMachine)
    }

    const getUserByCTVM = async () => {
        const res = await apiServices.getActiveZohoUser();
        const response = await apiServices.getAllAgreements({});
        const userred = response?.data?.data?.map((x) => { return x.userid })

        const arr=[];
        res?.data?.data?.forEach((x)=>{
            userred?.includes(x?.userid) ? arr.push({...x, isAgreementUploaded : true }) : arr.push({...x, isAgreementUploaded : false })
        })
        console.log(arr);

        const arrData = arr?.filter((x) => { return x.userType === 'customer' })
        const filData = arrData?.map((x) => { return { ...x, companyName: x.billing_details?.company } });
        const finalArr = filData?.sort((a, b) => a.companyName.trim() !== b.companyName.trim() ? a.companyName.trim() < b.companyName.trim() ? -1 : 1 : 0)

        if(initialValue?.userType === 'distributor'){

            const data = finalArr?.filter((x)=>{
                if(x.ownerShip === "PARTNER" && initialValue?.userid === x.distributor_data?.userid){
                    return x;
                }
            })

            setAllUsersByCtvm(data)
            setUsersByCtvm(data)

        }
        else{
            setAllUsersByCtvm(finalArr)
            setUsersByCtvm(finalArr)
        }

        // const filData = arrData?.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0).map((x) => { return { ...x, companyName: x.billing_details?.company } });
        // setAllUsersByCtvm(filData)
        // setUsersByCtvm(filData)

        setLoading1(false)
    }

    const getDates = async () => {
        const response = await commonCal.getDates()
        console.log(response);
        setAllDates(response)
    }

    const typeBodyTemplate = (data, props) => {
        let type = data[props.field] == 'distributor' ? 'Distributor' : 'Account'
        return (<><span className="p-column-title">{props.header}</span>{type}</>)
    };

    const nameBodyForTable = (data, props) => (
        <>
            <span className="p-column-title">{props.header}</span>{data[props.field] ? data[props.field] + " " + data.lastName : 'NA'}
        </>
    )

    const machineBodyForTable = (data, props) => (<> <span className="p-column-title">{props.header}</span>{data[props.field]?.join(", ")}</>)

    const mobileBodyForTable = (data, props) => {
        const cno = data[props.field].length == 10 ? '+91' + data[props.field] : data[props.field];
        return (<> <span className="p-column-title">{props.header}</span>{cno ? cno : 'NA'}</>)
    }

    const addBodyTemplate = (data, props) => { return (<><span className="p-column-title">{props.header}</span>{data[props.field] ? data[props.field][props.field] : 'NA'}</>) }

    const dateBodyTemplate = (rowData, props) => {
        let convertedDate;
        if (rowData[props.field]) {
            const date = new Date(rowData[props.field]);
            convertedDate = date?.toISOString().substring(0, 10);
        } else {
            convertedDate = 'NA'
        }
        return (<>{convertedDate}</>);
    }

    const paymentStatusTemplate = (data, props) => {
        const paymentStatus = data[props.field] == 'COMPLETED' ? 'PAID' : data[props.field];
        return (<><span className="p-column-title">{props.header}</span>{paymentStatus ? getFormattedString(paymentStatus) : 'NA'} </>)
    }

    const viewInvoiceModal = async(rowData) => {
        console.log(rowData);
        setModelLoading(true)
        setSelectedAcc(rowData)
        setInvoiceModal(true)
        const res = await apiServices.getInoviesById({ userId: rowData.userid });
        console.log(res?.data?.data);
        setInvoiceListForTable(res?.data?.data)
        setAllInvoices(res?.data?.data)
        getTopData(res?.data?.data)
        setModelLoading(false)
        setTimeselectValue({ name: 'All', code: 'all' })
    }

    const viewCVESModal = (rowData) => {
        console.log(rowData);
        setViewCvesDetailedDialog(true)
        setSelectedAcc(rowData)
        getMachinesByAccount(rowData)
    }

    const getMachinesByAccount = async (data) => {
        const payload = { "userType": data?.userType, "userid": data?.userid }
        const response = await apiServices.getMchByAccount(payload)
        console.log(response?.data?.data);
        setAccountsCves(response?.data?.data)
    }

    const viewAgreementModal = (rowData) => {
        console.log(rowData);
        setAgreementsDialog(true)
        setSelectedAcc(rowData)
        const payload = { "userid": rowData?.userid }
        getAllAgreements(payload)
    }

    const getAllAgreements = async (data) => {
        const response = await apiServices.getAgreements(data);
        console.log(response?.data?.data);
        setCustAgreements(response?.data?.data)
    }

    const getCustomerDataDirectly = async () => {
        const res = await apiServices.getInoviesById({ userId: initialValue.userid });
        console.log(res?.data?.data);
        setInvoiceListForTable(res?.data?.data)
        setAllInvoices(res?.data?.data)
        getTopData(res?.data?.data)
    }

    const getTopData = (data) =>{
        const invoiceLength = data?.length;
        const paidInvoice = data?.filter((x) => { return x?.paymentStatus === 'COMPLETED' })?.length;
        const pendingInvoice = data?.filter((x) => { return x?.paymentStatus === 'PENDING' })?.length;
        const pAmt = data?.filter((x) => { return x?.paymentStatus === 'PENDING' })?.reduce((acc, total) => { return acc + Number(total?.inoviceAmount) }, 0);
        const pendingAmt = getFormattedAmount(pAmt)
        const rAmt = data?.filter((x) => { return x?.paymentStatus == 'COMPLETED' })?.reduce((acc, total) => { return acc + Number(total?.inoviceAmount) }, 0);
        const receivedAmt = getFormattedAmount(rAmt)
        const payload = {invoiceLength, paidInvoice, pendingInvoice, pendingAmt, receivedAmt}
        setWidgetData(payload)
    }

    const viewInvoiceTab = (rowData) => {
        const link = rowData?.inoviceLink?.url;
        window.open(link, '_blank');
    }

    const changeMachine = (e) => {
        console.log(e.value);
        setSelectedMachine(e.value)
        if (e.value.displayLabel == 'All') {
            setUsersByCtvm(allUsersByCtvm)
        } else {
            const filData = allUsersByCtvm.filter(m => (m.userid == e.value.subscription.userid))
            setUsersByCtvm(filData)
        }
    }

    const changeInType = (e) => {
        setSelectedInType(e.value)
        switch (e.value.code) {
            case "consumable":
                setInvoiceListForTable(consumptionInvoice)
                break;
            case "subscription":
                setInvoiceListForTable(subscriptionInvoice)
                break;
            default:
                setInvoiceListForTable(invoiceList)
                break;
        }
    }

    const changeInStatus = (e) => {
        setSelectedInStatus(e.value)
        switch (e.value.code) {
            case 'COMPLETED': {
                const data = selectedDate ? allInvoices.filter((x) => { return x.paymentStatus == 'COMPLETED' && x.date > selectedDate.startDate && x.date < selectedDate.endDate }) : allInvoices.filter((x) => { return x.paymentStatus == 'COMPLETED' })
                setInvoiceListForTable(data)
                break;
            }
            case 'PENDING': {
                const data = selectedDate ? allInvoices.filter((x) => { return x.paymentStatus == 'PENDING' && x.date > selectedDate.startDate && x.date < selectedDate.endDate }) : allInvoices.filter((x) => { return x.paymentStatus == 'PENDING' })
                setInvoiceListForTable(data)
                break;
            }
            default: {
                const data = selectedDate ? allInvoices.filter((x) => { return x.date > selectedDate.startDate && x.date < selectedDate.endDate }) : allInvoices
                setInvoiceListForTable(data)
                break;
            }
        }
    }

    const filterTime = (e) => {
        e.target.value.code !== 'custom' && setTimeselectValue(e.target.value);
        switch (e.target.value.code) {
            case 'all': {
                setSelectedDate(null)
                const data = selectedInStatus.code == 'all' ? allInvoices : allInvoices.filter((x) => { return x.paymentStatus == selectedInStatus.code })
                setInvoiceListForTable(data)
                break;
            }
            case 'today': {
                console.log(allInvoices);
                setSelectedDate({ startDate: allDates.startToday, endDate: allDates.endToday })
                const data = selectedInStatus.code == 'all' ? allInvoices.filter((x) => { return x.date > allDates.startToday && x.date < allDates.endToday; }) : allInvoices.filter((x) => { return x.date > allDates.startToday && x.date < allDates.endToday && x.paymentStatus == selectedInStatus.code })
                setInvoiceListForTable(data)
                break;
            }
            case 'yesterday': {
                setSelectedDate({ startDate: allDates.YestStart, endDate: allDates.EndStart })
                const data = selectedInStatus.code == 'all' ? allInvoices.filter((x) => { return x.date > allDates.YestStart && x.date < allDates.EndStart; }) : allInvoices.filter((x) => { return x.date > allDates.YestStart && x.date < allDates.EndStart && x.paymentStatus == selectedInStatus.code })
                setInvoiceListForTable(data)
                break;
            }
            case 'this_week': {
                setSelectedDate({ startDate: allDates.weekstart, endDate: allDates.weekEnd })
                const data = selectedInStatus.code == 'all' ? allInvoices.filter((x) => { return x.date > allDates.weekstart && x.date < allDates.weekEnd; }) : allInvoices.filter((x) => { return x.date > allDates.weekstart && x.date < allDates.weekEnd && x.paymentStatus == selectedInStatus.code })
                setInvoiceListForTable(data)
                break;
            }
            case 'this_month': {
                setSelectedDate({ startDate: allDates.monthStart, endDate: allDates.endThisMonth })
                const data = selectedInStatus.code == 'all' ? allInvoices.filter((x) => { return x.date > allDates.monthStart && x.date < allDates.endThisMonth; }) : allInvoices.filter((x) => { return x.date > allDates.monthStart && x.date < allDates.endThisMonth && x.paymentStatus == selectedInStatus.code })
                setInvoiceListForTable(data)
                break;
            }
            case 'last_month': {
                setSelectedDate({ startDate: allDates.lastStart, endDate: allDates.lastend })
                const data = selectedInStatus.code == 'all' ? allInvoices.filter((x) => { return x.date > allDates.lastStart && x.date < allDates.lastend; }) : allInvoices.filter((x) => { return x.date > allDates.lastStart && x.date < allDates.lastend && x.paymentStatus == selectedInStatus.code })
                setInvoiceListForTable(data)
                break;
            }
            case 'custom':
                setCustomFilterDialog(true);
                break;
            default:
                setInvoiceListForTable(allInvoices)
                break;
        }
    }

    const hideCustomDialog = () => {
        setCustomFilterDialog(false);
        setTimeselectValue({ name: 'All', code: 'all' });
    }

    const handleSubmit = (e) => {
        setTimeselectValue(timeselectValue);
        setCustomFilterDialog(false)
        e.preventDefault()
        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        const cust_start = new Date(startt).toISOString();
        const cust_end = new Date(endd).toISOString();
        setSelectedDate({ startDate: cust_start, endDate: cust_end })
        const data = selectedInStatus.code == 'all' ? allInvoices.filter((x) => { return x.date > cust_start && x.date < cust_end; }) : allInvoices.filter((x) => { return x.date > cust_start && x.date < cust_end && x.paymentStatus == selectedInStatus.code })
        setInvoiceListForTable(data)
        setTimeselectValue(null);
    }

    const deleteInOrder = (data) => {
        console.log(data);
        setDeleteInvoiceOrderDialog(true)
        setInvoiceOrder(data)
    }

    const removeInOrder = async () => {
        setDeleteInvoiceOrderDialog(false)
        const res = await apiServices.removeInoviceOrder({ orderId: invoiceOrder?.orderId });
        if (res?.data?.success) {
            successToast(toast, 'Invoice Order deleted Successfully')
            const resp = await apiServices.getInoviesById({ userId: selectedAcc.userid });
            setInvoiceListForTable(resp?.data?.data)
            setAllInvoices(resp?.data?.data)
        } else {
            failureToast(toast, 'Failed to Delete')
        }
    }

    const showAgreements = async (rowData) => {
        console.log(rowData);
        const response = await apiServices.sendNewAgreement(rowData);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const downloadURL = window.URL.createObjectURL(blob);
        window.open(downloadURL, '_blank');
    }

    const actionTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <div className="actions" style={{ display: "flex" }}>
                    <Button icon="pi pi-eye" title='View Invoices' className="p-button-rounded p-button-info p-mr-2 p-mb-2" onClick={() => { viewInvoiceModal(rowData) }} />
                    <Button icon="pi pi-info-circle" title='View CVES Details' className="p-button-rounded p-button-warning p-mr-2 p-mb-2" onClick={() => { viewCVESModal(rowData) }} />
                    {/* <Button icon="pi pi-file-pdf" title={`${rowData?.isAgreementUploaded ? 'View Agreement' : 'No Agreements Uploaded' }`}  className={`${rowData?.isAgreementUploaded ? 'p-button-primary' : 'p-button-danger' }   p-button-rounded p-mr-2 p-mb-2`} onClick={() => { viewAgreementModal(rowData) }} /> */}
                    {/* <Button icon="pi pi-file-pdf" title='View Agreement' className="p-button-rounded p-button-info p-mr-2 p-mb-2" onClick={() => { viewAgreementModal(rowData) }} /> */}
                </div>
            </>
        )
    }

    const actionTemplate1 = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <Button icon="pi pi-eye" title='View Invoice' className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={() => { viewInvoiceTab(rowData) }} />
                {
                    rolePermissions?.rmInvoiceOrder && 
                    <Button icon="pi pi-trash" title='Delete Invoice' className="p-button-rounded p-button-danger p-mr-2 p-mb-2" onClick={() => { deleteInOrder(rowData) }} />
                }
            </>
        )
    }

    const viewAgreement = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {
                    rolePermissions?.viewAgree &&
                    <Button icon="pi pi-eye" title='View Details' className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={() => { showAgreements(rowData) }} />
                }
            </>
        )
    }

    const leftToolbarTemplate = () => {
        return (
            <>
                {
                    initialValue?.userType != 'customer' &&

                    <div className='p-col-12'>
                        <div className="p-inputgroup p-mt-2">
                            <Button className='custom-group-btn' label="CVES" />
                            <Dropdown value={selectedMachine} onChange={changeMachine} options={allMachines} optionLabel="displayLabel" placeholder="Select CVES Id" filter className="multiselect-custom" />
                        </div>
                    </div>
                }
            </>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                <div className='p-col-12'>
                    <div className="p-inputgroup p-mt-2">
                        <Button className='custom-group-btn' label="Search" />
                        <InputText value={globalFilter1} onChange={(e) => setGlobalFilter1(e.target.value)} placeholder="Search" />
                    </div>
                </div>
            </>
        )
    }

    const RightInvoiceToolbarTemplate = () => {
        return (
            <>
                <div className='p-col-12'>
                    <div className="p-inputgroup p-mt-2">
                        <Button className='custom-group-btn' label="Duration" />
                        <Dropdown value={timeselectValue} onChange={filterTime} autoFocus options={durationOptions} optionLabel="name" placeholder="Custom" style={{ width: '100px' }}></Dropdown>

                        <Button className='custom-group-btn p-ml-1' label="Search" />
                        <InputText value={globalFilter2} onChange={(e) => setGlobalFilter2(e.target.value)} placeholder="Search" />
                    </div>
                </div>
            </>
        )
    }

    const leftInvoiceToolbarTemplate = () => {
        return (
            <>
                {/* <div className='p-col-6'>
                    <div className="p-inputgroup p-mt-2">
                        <Button className='custom-group-btn' label="Type" />
                        <Dropdown value={selectedInType} onChange={changeInType} autoFocus options={invoiceTypeItems} optionLabel="name" placeholder="Select Payment Status"></Dropdown>
                    </div>
                </div> */}

                {/* <div className='p-col-6'> */}
                <div className="p-inputgroup p-mt-2">
                    <Button className='custom-group-btn' label="Status" />
                    <Dropdown value={selectedInStatus} onChange={changeInStatus} autoFocus options={invoiceStatusItems} optionLabel="name" placeholder="Select Payment Status" style={{ width: '100px' }}></Dropdown>
                </div>
                {/* </div> */}

            </>
        )
    }

    const hideAgreeDialog = () =>{ setAgreementsDialog(false) }
    const hideViewCvesDialog = () =>{ setViewCvesDetailedDialog(false) }
    const hideInvoiceDialog = () =>{ setInvoiceModal(false) }
    const hideDeleteDialog = () =>{ setDeleteInvoiceOrderDialog(false) }
    
    const agreementDialogFooter = commonCloseDialogFooter('Close', hideAgreeDialog)
    const cvesDialogFooter = commonCloseDialogFooter('Close', hideViewCvesDialog)
    const invoiceDialogFooter = commonCloseDialogFooter('Close', hideInvoiceDialog)
    const deleteInOrderDialogFooter = commonDialogFooter('Close',hideDeleteDialog,'Yes, Delete it.',removeInOrder)
    
    return (

        <>
            <div className="p-grid table-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} position='center' />

                        {
                            initialValue?.userType === 'customer' &&
                                <>
                                    <Toolbar className="p-mb-4 p-toolbar" left={leftInvoiceToolbarTemplate} right={RightInvoiceToolbarTemplate} ></Toolbar>

                                    <div className="p-grid p-my-5">
                                        <BillingWidget widgetData={widgetData} />
                                    </div>

                                    <DataTable value={invoiceListForTable} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover
                                        globalFilter={globalFilter2} emptyMessage="No Data Found." loading={loading1}>
                                        <Column field="srno" header="Sr. No." body={srBodyTemplate} ></Column>
                                        {/* <Column field="orderId" header="Order Id" body={CommonBodyForTable}></Column> */}
                                        <Column field="SDid" header="Order Id" body={CommonBodyForTable}></Column>
                                        <Column field="paymentStatus" header="Payment Status" body={CommonBodyForFormattedStrTable} ></Column>
                                        <Column field="inoviceAmount" header="Total Amount" body={CommonBodyForTable}></Column>
                                        <Column field="date" header="Date & Time" body={DateBodyForTable}></Column>
                                        <Column header="Action" body={actionTemplate1}></Column>
                                    </DataTable>
                                </>
                        }

                        {
                             initialValue?.userType === 'distributor' &&
                             <>
                                 <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>


                                 <DataTable value={usersByCtvm} paginator className="p-datatable-customers" rows={10} rowsPerPageOptions={[5, 10, 25]} dataKey="id" rowHover
                                     globalFilter={globalFilter1} emptyMessage="No Users Found." paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users" loading={loading1}>
                                     <Column field="srno" header="Sr.No." body={srBodyTemplate} style={{ width: '8rem' }}></Column>
                                     {/* <Column field="name" header="Full Name" sortable body={nameBodyForTable}></Column> */}
                                     {/* <Column field="name" header="Account Name" sortable body={nameBodyForTable}></Column> */}
                                     <Column field="companyName" header="Account Name" sortable body={CommonBodyForTable}></Column>
                                     <Column field="Customer_Account_ID" header="Account Id" sortable body={CommonBodyForTable}></Column>
                                     {/* <Column field="company" header="Comapny Name" sortable body={combodyTemplate}></Column> */}
                                     {/* <Column field="userType" header="User Type" sortable body={typeBodyTemplate}></Column> */}
                                     {/* <Column field="cno" header="Contact Number" sortable body={mobileBodyForTable}></Column> */}
                                     {/* <Column field="machineId" header="CVES Id" sortable body={machineBodyForTable}></Column> */}
                                     <Column field="cno" header="Contact Number" sortable body={mobileBodyForTable}></Column>
                                     <Column field="addr" header="Address" sortable body={addBodyTemplate}></Column>
                                     <Column header="Actions" headerStyle={{ width: '15rem', textAlign: 'left' }} bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }} body={actionTemplate}></Column>
                                 </DataTable>
                             </>
                        }

                        {
                             (initialValue?.userType !== 'customer' && initialValue?.userType !== 'distributor') &&
                             <>
                                 <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                                 <div className="p-grid p-my-5">
                                     {/* <SubAnalyticalStat header="Total Invoices" value={0} /> */}
                                     {/* <SubAnalyticalStat header="Paid Invoices" value={0} /> */}
                                     {/* <SubAnalyticalStat header="Total Amount" value={`₹${Number(billingStat?.All_Invoice_Amount_Total1)?.toFixed(2)}`} loading={statLoading} /> */}
                                     <SubAnalyticalStat header="Advance Amount" value={`₹ ${getFormattedAmount(Number(billingStat?.advance_payment_amounts)?.toFixed(2)) }`} loading={statLoading} />
                                     <SubAnalyticalStat header="Pending Amount" value={`₹ ${getFormattedAmount(Number(billingStat?.pending_payment_amount)?.toFixed(2)) }`} loading={statLoading} />
                                     <SubAnalyticalStat header="Received Amount" value={`₹ ${getFormattedAmount(Number(billingStat?.recived_payment_amounts)?.toFixed(2)) }`} loading={statLoading} />
                                 </div>

                                 <DataTable value={usersByCtvm} paginator className="p-datatable-customers" rows={10} rowsPerPageOptions={[5, 10, 25]} dataKey="id" rowHover
                                     globalFilter={globalFilter1} emptyMessage="No Users Found." paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users" loading={loading1}>
                                     <Column field="srno" header="Sr.No." body={srBodyTemplate} style={{ width: '8rem' }}></Column>
                                     {/* <Column field="name" header="Full Name" sortable body={nameBodyForTable}></Column> */}
                                     {/* <Column field="name" header="Account Name" sortable body={nameBodyForTable}></Column> */}
                                     <Column field="companyName" header="Account Name" sortable body={CommonBodyForTable}></Column>
                                     <Column field="Customer_Account_ID" header="Account Id" sortable body={CommonBodyForTable}></Column>
                                     {/* <Column field="company" header="Comapny Name" sortable body={combodyTemplate}></Column> */}
                                     {/* <Column field="userType" header="User Type" sortable body={typeBodyTemplate}></Column> */}
                                     {/* <Column field="cno" header="Contact Number" sortable body={mobileBodyForTable}></Column> */}
                                     {/* <Column field="machineId" header="CVES Id" sortable body={machineBodyForTable}></Column> */}
                                     <Column field="cno" header="Contact Number" sortable body={mobileBodyForTable}></Column>
                                     <Column field="addr" header="Address" sortable body={addBodyTemplate}></Column>
                                     <Column header="Actions" headerStyle={{ width: '15rem', textAlign: 'left' }} bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }} body={actionTemplate}></Column>
                                 </DataTable>
                             </>
                        }

                    </div>
                </div>
            </div>

            <Dialog visible={invoiceModal} style={{ width: '1300px' }} header={`View Invoices (${selectedAcc?.companyName})`} modal footer={invoiceDialogFooter} onHide={() => { setInvoiceModal(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                {
                    modelLoading ?
                        <center><h5><i className="pi pi-spin pi-spinner" /></h5></center> :
                        <>
                            {
                                (allInvoices?.length > 0) &&
                                <>
                                    <div className="p-grid p-my-5">
                                        <BillingWidget widgetData={widgetData} />
                                    </div>
                                    <Toolbar className="p-mb-4 p-toolbar" left={leftInvoiceToolbarTemplate} right={RightInvoiceToolbarTemplate} ></Toolbar>
                                </>
                            }

                            <div className="p-grid table-demo">
                                <div className="p-col-12">
                                    <div className="card">
                                        <Toast ref={toast} />
                                        <DataTable value={invoiceListForTable} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover
                                            globalFilter={globalFilter2} emptyMessage="No Data Found." loading={loading1}>
                                            <Column field="srno" header="Sr. No." body={srBodyTemplate} ></Column>
                                            <Column field="orderId" header="Order Id" body={CommonBodyForTable}></Column>
                                            <Column field="SDid" header="Sales Order Id" body={CommonBodyForTable}></Column>
                                            <Column field="paymentStatus" header="Payment Status" body={paymentStatusTemplate} ></Column>
                                            <Column field="inoviceAmount" header="Total Amount" body={CommonBodyForTable}></Column>
                                            <Column field="date" header="Date & Time" body={DateBodyForTable}></Column>
                                            <Column header="Action" body={actionTemplate1}></Column>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </Dialog>

            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter" onHide={hideCustomDialog} modal className="p-fluid">
                <form onSubmit={handleSubmit}>
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From</label>
                            <InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => { setCustomStart(e.value) }} required autoFocus />
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' value={customEnd} onChange={(e) => { setCustomEnd(e.value) }} required />
                        </div>
                    </div>
                    <div className="p-dialog-footer p-mt-4">
                        <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
                        <button className="p-button p-button-success" type="submit"><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={deleteInvoiceOrderDialog} style={{ width: '450px' }} header={`Confirm ?`} modal footer={deleteInOrderDialogFooter} onHide={() => { setDeleteInvoiceOrderDialog(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br />
                        <span className='p-mt-4'><b>Are you sure you want to delete the selected invoice?</b></span>
                    </center>
                </div>
            </Dialog>

            <Dialog visible={viewCvesDetailedDialog} style={{ width: '450px' }} header={`CVES Details`} modal footer={cvesDialogFooter} onHide={() => { setViewCvesDetailedDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className="p-grid table-demo">
                    <div className="p-col-12">
                        <div className="card p-m-0">
                            <Toast ref={toast} />
                            <DataTable value={accountsCves} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover
                                globalFilter={globalFilter2} emptyMessage="No CVES Found.">
                                <Column field="srno" header="Sr. No." body={srBodyTemplate} ></Column>
                                <Column field="id" header="CVES Id" body={CommonBodyForTable}></Column>
                                <Column field="subid" header="Subscription Id" body={CommonBodyForTable}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={agreementsDialog} style={{ width: '950px' }} header={`Agreement Details (${selectedAcc?.companyName})`} modal footer={agreementDialogFooter} onHide={() => { setAgreementsDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className="p-grid table-demo">
                    <div className="p-col-12">
                        <div className="card">
                            <Toast ref={toast} />
                            <DataTable value={custAgreements} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover
                                globalFilter={globalFilter2} emptyMessage="No Agreements Found.">
                                <Column field="srno" header="Sr. No." body={srBodyTemplate} style={{ width: '4rem' }} ></Column>
                                <Column field="filename" header="File Name" body={fileBodyForTable}></Column>
                                <Column field="startDate" header="Start Date" body={dateBodyForTable} ></Column>
                                <Column field="endDate" header="End Date" body={dateBodyForTable} ></Column>
                                <Column field="createdAt" header="Uploaded At" body={dateBodyForTable} ></Column>
                                <Column header="Action" body={viewAgreement}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}


export const BillingWidget = (props) =>{
    return (
        <>
            <SubAnalyticalStat header="Total Invoices" value={props.widgetData?.invoiceLength || '-'} />
            <SubAnalyticalStat header="Paid Invoices" value={props.widgetData?.paidInvoice || '-'} />
            <SubAnalyticalStat header="Pending Invoices" value={props.widgetData?.pendingInvoice || '-'} />
            <SubAnalyticalStat header="Pending Amount" value={props.widgetData?.pendingAmt || '-'} />
            <SubAnalyticalStat header="Received Amount" value={props.widgetData?.receivedAmt || '-'} />
        </>
    )
}

