import React, { useState, useEffect, useRef, useContext } from 'react'
import ApiServices from '../../service/api/ApiServices'
import { TableWrapper } from '../../utilComponents/DataTableWrapper'
import { CommonBodyForTable, DateBodyForTable, commonDialogFooter, commonFormDialogFooter, srBodyTemplate } from '../Common/CommonComponents'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import { FormWrapper, FullFormWrapper } from '../../utilComponents/Forms'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { Toast } from 'primereact/toast';
import { failureToast, successToast } from '../Common/CommonFunctions'
import { useParams } from 'react-router-dom'
import { permissionContext } from '../../AppWrapper'

const apiServices = new ApiServices()

export const AppVersionController = () => {

    const rolePermissions = useContext(permissionContext);
    const { version_id, versionHeading } = useParams()

    const operateModeOptions = [
        { name : 'Keypad', code : 'KEYPAD'},
        { name : 'Display', code : 'DISPLAY'}
    ]

    const payloadData = {
        "type": "",
        "heading": "",
        "version_number": "",
        "category": "",
        "description": ""
    }

    const versionTypes = [ { name : "App" }, { name : "Gateway" }, { name : "Esp" }]

    const [loading, setLoading] = useState(true)
    const [globalFilter, setGlobalFilter] = useState('');
    const [versionData, setVersionData] = useState([])
    const [addVersionDialog, setAddVersionDialog] = useState(false)
    const [versionPayload, setVersionPayload] = useState(payloadData)
    const [selectedVersion, setSelectedVersion] = useState(null)
    const [selectedVersionType, setSelectedVersionType] = useState(null)
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false)
    const [selectedOperateMode, setSelectedOperateMode] = useState(null)


    const dt = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        getData()
    }, [])

    const getData = async() =>{
        const payload = version_id ? { version_number : version_id} : {};
        const response = await apiServices.getAllVersions(payload)
        console.log(response?.data?.data);
        setVersionData(response?.data?.data)
        setLoading(false)
    }

    const openAddDialog = () =>{
        setAddVersionDialog(true)
        setSelectedVersion(null)
    }
    const hideVersionDialog = () =>{ 
        setAddVersionDialog(false) 
        setVersionPayload(payloadData)
        setSelectedVersionType(null)
        setSelectedOperateMode(null)
    }

    const changeData = (e) =>{

        if(e.target.name == 'operateMode'){
            setSelectedOperateMode(e.value)
            setVersionPayload({...versionPayload,[e.target.name]: e.value.code })
        }else if(e.target.name == 'type'){
            setSelectedVersionType(e.value)
            setVersionPayload({...versionPayload,[e.target.name]: e.value.name })
        }else{
            setVersionPayload({...versionPayload,[e.target.name]: e.target.value })
        }
    }

    const actionVersionData = async(e) =>{
        e.preventDefault();
        console.log(versionPayload);
        const response = selectedVersion ? await apiServices.updateVersionsData(versionPayload) : await apiServices.addVersionsData(versionPayload);
        console.log(response?.data?.success);
        if(response?.data?.success){
            hideVersionDialog()
            getData()
            successToast(toast, `Version Data ${selectedVersion ? 'Updated' : 'Added'} Successfully`)
        }else{
            failureToast(toast, `Failed to ${selectedVersion ? 'Update' : 'Add'}...`)
        }
    }

    const editVersion = (rowData) =>{
        console.log(rowData);
        setAddVersionDialog(true)
        setSelectedVersion(rowData)
        setVersionPayload(rowData)
        setSelectedVersionType({ name : rowData?.type })

        const operateMode = rowData?.operateMode;
        switch (operateMode) {
            case 'KEYPAD':
                setSelectedOperateMode(operateModeOptions[0]);
                break;
            case 'DISPLAY':
                setSelectedOperateMode(operateModeOptions[1]);
                break;
            default:
                setSelectedOperateMode(null);
        }
    }

    const comfirmDelete = (rowData) =>{
        console.log(rowData); 
        setConfirmDeleteDialog(true) 
        setSelectedVersion(rowData)
    }

    const deleteVersion = async() =>{
        const response = await apiServices.deleteVersionsData({ _id : selectedVersion?._id})
        if(response?.data?.success){
            successToast(toast, 'Version Data Deleted Successfully')
            hideConfirmDialog()
            getData()
        }else{
            failureToast(toast, 'Failed to delete...');
        }
    }

    const exportCSV = () => { dt.current.exportCSV(); }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                <h6 className="p-mt-3 p-mr-5">App Version Details</h6>
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV} />
                </div>
            </span>
        </div>
    )

    const rightToolbarTemplate = () => (
            <Button className='p-custom-btn' label="ADD VERSIONS DATA" icon="pi pi-plus-circle" iconPos="left" onClick={openAddDialog} />
    )

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                { rolePermissions?.updateVersionData && <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-warning p-mr-2" onClick={() => editVersion(rowData)} /> }
                { rolePermissions?.deleteVersionData && <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mr-2" onClick={() => comfirmDelete(rowData)} /> }
            </div>
        )
    }

    const hideConfirmDialog = () =>{ setConfirmDeleteDialog(false) }
    const versionFooter = commonFormDialogFooter('Close',hideVersionDialog,'Submit','add-version')
    const confirmFooter = commonDialogFooter('Cancel',hideConfirmDialog,'OK', deleteVersion)


    return (
        <>
            <Toast ref={toast} position='center' />
            <TableWrapper>
                    {
                        rolePermissions?.addVersionData &&
                        <Toolbar className='p-mb-2' right={rightToolbarTemplate}></Toolbar>
                    }

                    <DataTable value={versionData} ref={dt}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} record's"
                        globalFilter={globalFilter} emptyMessage="No Data Found." header={header} loading={loading} >
                        <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '4rem' }}></Column>
                        <Column field="version_number" header="Version" body={CommonBodyForTable} sortable ></Column>
                        <Column field="category" header="Category" body={CommonBodyForTable} sortable></Column>
                        <Column field="heading" header="Heading" body={CommonBodyForTable} sortable></Column>
                        <Column field="type" header="Type" body={CommonBodyForTable} sortable></Column>
                        <Column field="description" header="Description" body={CommonBodyForTable} sortable></Column>
                        <Column field="createdAt" header="Created At" body={DateBodyForTable} sortable></Column>
                        <Column header="Actions" body={actionBodyTemplate} sortable></Column>
                    </DataTable>
            </TableWrapper>

            <Dialog visible={addVersionDialog} style={{ width: '550px' }} header={` ${selectedVersion ? 'Edit' : 'Add'} Version Details`} className="p-fluid" modal footer={versionFooter} onHide={hideVersionDialog}>
                <form onSubmit={actionVersionData} id="add-version">
                <div className='p-grid'>
                    <FullFormWrapper id="operateMode" label="Operate Mode">
                        <Dropdown id="operateMode" name='operateMode' value={selectedOperateMode} options={operateModeOptions} optionLabel='name' placeholder='Enter Operate Mode' onChange={changeData} required/>
                    </FullFormWrapper>
                    <FullFormWrapper id="type" label="Version Type">
                        <Dropdown id="type" name='type' value={selectedVersionType} options={versionTypes} optionLabel='name' placeholder='Enter Version Type' onChange={changeData} required/>
                    </FullFormWrapper>
                    <FullFormWrapper id="heading" label="Heading">
                            <InputText id="heading" name='heading' placeholder='Enter Heading' defaultValue={selectedVersion?.heading} onChange={changeData} required/>
                    </FullFormWrapper>
                    <FullFormWrapper id="version_number" label="Version Number">
                            <InputText id="version_number" name='version_number' placeholder='Enter Version Number' defaultValue={selectedVersion?.version_number} onChange={changeData} required/>
                    </FullFormWrapper>
                    <FullFormWrapper id="category" label="Category">
                            <InputText id="category" name='category' placeholder='Enter Category' defaultValue={selectedVersion?.category} onChange={changeData} required/>
                    </FullFormWrapper>
                    <FullFormWrapper id="description" label="Description">
                            <InputTextarea id="description" name='description' rows={10} cols={20} placeholder='Enter Description....' defaultValue={selectedVersion?.description} onChange={changeData} required/>
                    </FullFormWrapper>
                </div>
                </form>
            </Dialog>

            <Dialog visible={confirmDeleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={confirmFooter} onHide={hideConfirmDialog}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br/>
                        <b><span>Are you sure you want to delete this version data? </span></b> 
                    </center>
                </div>
            </Dialog>

        </>
    )
}