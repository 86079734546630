

export const prefixOptions = [ {name : 'Mr'}, {name : 'Mrs'}] 

export const levelOptions = [
    { name : 'All', code: 'all'},
    { name : 'Level 0', code: 'Level 0'},
    { name : 'Level 1', code: 'Level 1'},
    { name : 'Level 2', code: 'Level 2'},
    { name : 'Level 2 Ops', code: 'Level 2 Ops'},
    { name : 'Level 3', code: 'Level 3'}
]

export const statusOptions = [
    { name : 'All', code: 'all'},
    { name : 'Open', code: 'OPEN'},
    { name : 'Closed', code: 'CLOSED'},
    { name : 'Hold', code: 'HOLD'},
    { name : 'Requested Closure', code: 'REQUESTED CLOSURE'}
]


export const durationOptions = [
    {name:'All',code:'all'},
    {name:'Today',code:'today'},
    {name:'Yesterday',code:'yesterday'},
    {name:'This Week',code:'this_week'},
    {name:'This Month',code:'this_month'},
    {name:'Last Month',code:'last_month'},
    {name:'Custom',code:'custom'}
]

export const ticketTypeOptions = [
    {name:'CVES',code:'CVES'},
    {name:'Other',code:'other'},
]

export const ticketPurposeOptions = [
    {name:'Issue',code:'issue'},
    {name:'Request',code:'request'},
]

export const defaultTicketBody = {
    machineId:'',
    message:'',
    name:'',
    email:'',
    ticketType:'CVES',
    ticketPurpose:'Issue',
    contact:'',
    last_name:'',
    prefix:'Mr',
    zoho_customer_id:'',
    zoho_facility_id:'',
    ticket_category: '',
    attachment : ''
}