import React from 'react';
import { Button } from 'primereact/button';

export const DeliveryChallan = () => {

    const print = () => {
        window.print();
    }

    return (

        <div className="layout-invoice-page">
        <Button type="button" label="Print" icon="pi pi-print" onClick={print} style={{ display: 'block', marginBottom: '20px', marginLeft: '6px' }} className="p-button-outlined invoice-button"></Button>
        <div className="p-grid">
        <div className="p-col">
        <div className="card" >
        <div id="invoice-content">
            <div className="invoice">
                
                <center><h2>Delivery Challan</h2></center>

                <div className="invoice-header">
                    <div>
                    <div className="invoice-company p-d-flex p-ai-center">
                        {/* <img className="invoice-logo" src={`assets/layout/images/logo-dark.png`} alt="atlantis-layout" /> */}
                        {/* <img className="invoice-appname" src={`assets/layout/images/appname-dark.png`} alt="atlantis-layout" /> */}
                        <img className="invoice-logo" src={`assets/layout/images/cherise-logo-cropped.png`} alt="atlantis-layout" style={{height:'4.25rem'}} />
                    </div>

                    <div className='p-pt-2'>Cherise India Pvt Limited</div>
                    <div className="invoice-address">201, Parijat, North South Rd 10, Juhu, Vile Parle West Juhu, Vile parle West,</div>
                    <div className="invoice-address">Mumbai Maharashtra 400049,</div>
                    <div className="invoice-address">India GSTIN 27AAHCC8654L1Z2</div>
                    <div className="invoice-address">cherees@gmail.com</div>
                    <div className="invoice-address">+91 8978456123</div>
            
                    </div>

                    <div className="p-ml-2">
                        <div className="customer-id">ID : INV1643352822668</div>
                        {/* <div className="invoice-address">9137 3rd Lane California City CA 93504, U.S.A.</div> */}
                    </div>
                </div>

                <div className="invoice-to">
                    <div className="invoice-date">29 January, 2021</div>
                    {/* <div className="invoice-id">Invoice <span>#00002</span></div> */}

                    <div className='p-grid'>
                        <div className='p-col-4'>
                            <div className="invoice-to-name">Client Information</div>
                            <div className='p-mt-2'>TATA MOTORS (RUDRA MOTORS)</div>
                            <div className="invoice-to-info">
                                <div>Ubhale Nagar, Maharashtra, Pune</div>
                                <div>94304. </div>
                            </div>
                        </div>

                        <div className='p-col-4'>
                            <div className="invoice-to-name">Order Information</div>
                            <div className="invoice-to-info">
                                <div>Machine Id : CH-64</div>
                                <div>From : 10-Jan-2022</div>
                                <div>To : 08-Feb-2022</div>
                            </div>
                        </div>

                        <div className='p-col-4'>
                            <div className="invoice-to-name">Delivered To</div>
                            <div className='p-mt-2'>TATA MOTORS (RUDRA MOTORS)</div>
                            <div className="invoice-to-info">
                                <div>Gat No. 1343/A, Near Ubhale Nagar Bus Stop, Wagholi, Pune-412207, India</div>
                                <div>Maharashtra, Pune.</div>
                                <div>27AAWFR7876H1Z5</div>
                            </div>
                        </div>

                    </div>
                    
                    

                </div>

                <div className="invoice-items">

                    <table>
                        <thead>
                        <tr>
                            <th>Sr.No</th>
                            <th>Particulars</th>
                            <th>HSN/SAC</th>
                            <th>Quantity</th>
                            <th>UOM</th>
                            <th>Rate</th>
                            <th>Taxable Amount</th>
                            <th colspan="2" scope='colgroup'>CGST</th>
                            <th colspan="2" scope='colgroup'>SGST</th>
                            <th>Total amount</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th scope='col'>Rate</th>
                            <th scope='col'>Amount</th>
                            <th scope='col'>Rate</th>
                            <th scope='col'>Amount</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        
                            <tr>
                                <td>1</td>
                                <td>PUNERI GAUTI CHAI</td>
                                <td>21012010</td>
                                <td>64</td>
                                <td>CUP</td>
                                <td>5.5</td>
                                <td>352.00</td>
                                <td>9%</td>
                                <td>31.68</td>
                                <td>9%</td>
                                <td>31.68</td>
                                <td>415.36</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>MUMBAI MASALA CHAI</td>
                                <td>21012010</td>
                                <td>64</td>
                                <td>CUP</td>
                                <td>5.5</td>
                                <td>352.00</td>
                                <td>9%</td>
                                <td>31.68</td>
                                <td>9%</td>
                                <td>31.68</td>
                                <td>415.36</td>
                            </tr>
                        </tbody>
                    </table> 


                </div>

                <div className="invoice-summary">
                    <table>
                        <tbody>
                            <tr>
                                <td>Sub total:</td>
                                <td>2,440.24</td>
                            </tr>
                            <tr>
                                <td>Freight</td>
                                <td>00.00</td>
                            </tr>
                            <tr>
                                <td>GST on Freight:</td>
                                <td>00.00</td>
                            </tr>
                            <tr>
                                <td>Additional charges,if any:</td>
                                <td>00.00</td>
                            </tr>
                            <tr>
                                <td>Total Amount:</td>
                                <td>2,440.24</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* <div className="invoice-notes">
                    <div>Note: </div>
                </div> */}
            </div>
        </div>
        </div>
        </div>
        </div>
        </div>

    );
}
