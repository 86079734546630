
export default class CommonCal {

    getDates = () =>{

        const currdate = new Date();
        const nowDate = new Date().toISOString();

        const firstDay = new Date(currdate.getFullYear(), currdate.getMonth(), 1).setHours(0, 0, 0, 0);
        const lastDay = new Date(currdate.getFullYear(), currdate.getMonth() + 1, 0).setHours(23, 59, 59, 0);
        const monthStart = new Date(firstDay).toISOString();
        const endThisMonth = new Date(lastDay).toISOString();

        const lastMonthStart = new Date(currdate.getFullYear(), currdate.getMonth()-1, 1).setHours(0, 0, 0, 0);
        const lastMonthEnd = new Date(currdate.getFullYear(), currdate.getMonth(), 0).setHours(23, 59, 59, 0);
        const lastStart = new Date(lastMonthStart).toISOString();
        const lastend = new Date(lastMonthEnd).toISOString();

        const last3MonthStart = new Date(currdate.getFullYear(), currdate.getMonth()-3, 1).setHours(0, 0, 0, 0);
        const last3MonthStartDate = new Date(last3MonthStart).toISOString();

        const last6MonthStart = new Date(currdate.getFullYear(), currdate.getMonth()-6, 1).setHours(0, 0, 0, 0);
        const last6MonthStartDate = new Date(last6MonthStart).toISOString();

        const last12MonthStart = new Date(currdate.getFullYear(), currdate.getMonth()-12, 1).setHours(0, 0, 0, 0);
        const last12MonthStartDate = new Date(last12MonthStart).toISOString();
     
        const todaymilisecond = new Date(currdate.setDate(currdate.getDate())).setHours(0, 0, 0, 0);
        const startToday = new Date(todaymilisecond).toISOString();
        const endToday = currdate.toISOString();

        const Yestmilisecond1 = new Date(currdate.setDate(currdate.getDate() - 1)).setHours(0, 0, 0, 0);
        const Yestmilisecond2 = new Date(currdate.setDate(currdate.getDate())).setHours(23, 59, 59, 0);
        const YestStart = new Date(Yestmilisecond1).toISOString();
        const EndStart = new Date(Yestmilisecond2).toISOString();

        const firstWeekday = new Date(currdate.setDate(currdate.getDate() - currdate.getDay())).setHours(0, 0, 0, 0);
        const lastWeekday = new Date(currdate.setDate(currdate.getDate() - currdate.getDay()+6)).setHours(23, 59, 59, 0);
        const weekstart = new Date(firstWeekday).toISOString();
        const weekEnd = new Date(lastWeekday).toISOString();

        const firstDayOfTheWeek = (currdate.getDate() - currdate.getDay());
        const lastDayOfTheWeek = firstDayOfTheWeek + 6;
        const firstDayOfLastWeek = new Date(new Date(currdate.setDate(firstDayOfTheWeek - 7)).setHours(0, 0, 0, 0)).toISOString();
        const lastDayOfLastWeek = new Date(new Date(currdate.setDate(lastDayOfTheWeek - 7)).setHours(23, 59, 59, 0)).toISOString();

        const data = {nowDate,startToday,endToday,YestStart,EndStart,weekstart,weekEnd,monthStart,endThisMonth,lastStart,lastend,firstDayOfLastWeek,lastDayOfLastWeek,last3MonthStartDate,last6MonthStartDate,last12MonthStartDate};
        return data;        

    }

}