export const BevVendingMchOptions = [
    {name:'TAPRI KIOSK',code:'TAPRI'},
    {name:'TKAFFE KIOSK',code:'TKAFFE'},
    {name:'TAPRI KETTLE',code:'MERI_TAPRI'}
]

export const mchStatusOptions = [
    {name:'Active',code:'active'},
    {name:'Inactive',code:'inactive'}
]

export const TimeSelectValues = [
    {name:'All',code:'all'},
    {name:'Today',code:'today'},
    {name:'Yesterday',code:'yesterday'},
    {name:'This Week',code:'this_week'},
    {name:'This Month',code:'this_month'},
    {name:'Last Month',code:'last_month'},
    {name:'Custom',code:'custom'}
]

export const TimeSelectValuesForManagement = [
    {name:'All',code:'all'},
    {name:'Today',code:'today'},
    {name:'Yesterday',code:'yesterday'},
    {name:'This Week',code:'this_week'},
    {name:'This Month',code:'this_month'},
    {name:'Custom',code:'custom'}
]

export const customWise = [
    { name:"Month Wise", code : 'monthwise' },
    { name:"Date Wise", code : 'datewise' }
]

export const machineTypeOptions = [
    { name:"All", code : 'all' },
    { name:"Demo", code : 'demo' },
    { name:"Commercial", code : 'commercial' }
]

export const monthsData = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const yearsData = ["2022", "2023", "2024"];