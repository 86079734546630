import React, {useState, useRef} from "react";
import { Chart } from 'primereact/chart';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const BeverageWiseCupChart = (props) =>{

        const datavalues = props.bvgCupCData?.sort((a, b) => Number(b.cups) - Number(a.cups));
        const dt = useRef(null);

        const basicData = {
            labels: props.BvgCupCLabels,
            datasets: [
                {
                    label: 'Cup Consumption',
                    data: props.BvgCupCValues,
                    backgroundColor: props.BvgCupCColor,
                    borderRadius: 5
                }
            ]
        };

        const basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    display: false
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    },
                    title: {
                        display: true,
                        text: 'Beverages'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    },
                    title: {
                        display: true,
                        text: 'No. of Cups'
                    }
                    
                }
            }
        };

        const exportCSV = () => {
            dt.current.exportCSV();
        }
    
    
        const fieldTemplate = (rowData, props) => {
            return (
                <>
                    <span className="p-column-title">{props.header}</span>
                    {rowData[props.field]}
                </>
            );
        }

    return (
        
        <>
        <div className="card">
            <div className="card-header">
                    <span>Beverage-Wise Cup Consumption Performance Details</span>
                    <div className='p-col-sm-4'>
                        <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-danger p-ml-4 p-mb-2" onClick={exportCSV} />
                    </div>
            </div>
            <div className="graph p-mt-5">
                <Chart height="600" type="bar" data={basicData} options={basicOptions} />
            </div>
        </div>

        <DataTable style={{display:'none'}} ref={dt} value={datavalues} dataKey="id" exportFilename={ `Beverage-Wise Cup Consumption Details`}  className="datatable-responsive" emptyMessage="No data found.">
            <Column field="_id" header="Beverage Name"  body={fieldTemplate}></Column>
            <Column field="cups" header="Cup Count" body={fieldTemplate}></Column>
        </DataTable>

        </>
    )
}