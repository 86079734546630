import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../service/api/ApiServices';
import CommonCal from '../../service/common/CommonCal';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { NumberCommonBodyForTable, srBodyTemplate } from '../Common/CommonComponents';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { exportReport } from '../Common/CommonFunctions';
import { Dropdown } from 'primereact/dropdown';
import { activityStatusOptions, brandsOptions, filterOptions } from './Constants';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { Chip } from 'primereact/chip';
const apiServices = new ApiServices();
const commonCal = new CommonCal();

// =================================================================================================
// =================================================================================================

export const MonthWiseCVESPerformance = () => {

    const durationOptions = [
        { name: 'Last Month', code: 'this_month' },
        { name: 'Last 3 Months', code: 'last_three_month' },
        { name: 'Last 6 Months', code: 'last_six_month' },
        { name: 'Last 12 Months', code: 'last_twelve_months' }
    ];

    const sortingOptions = [
        { name: 'High to Low', code: 'desc' },
        { name: 'Low to High', code: 'asc' }
    ]

    const viewOptions = [
        { name: 'Excellent', code: 'one' },
        { name: 'Good', code: 'two' },
        { name: 'Average', code: 'three' },
        { name: 'Below Average', code: 'four' },
        { name: 'Poor', code: 'five' },
    ]

    const monthsArr = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC']

    const [loading, setLoading] = useState(true)
    const [globalFilter, setGlobalFilter] = useState('');
    const [dates, setDates] = useState(null)
    const [dayWiseMasterData, setDayWiseMasterData] = useState(null)
    const [dayWiseData, setDayWiseData] = useState(null)
    const [allMaterials, setAllMaterials] = useState(null)
    const [selectedDuration, setSelectedDuration] = useState(durationOptions[0])
    const [customDateDialog, setCustomDateDialog] = useState(false)
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    const [filterType, setFilterType] = useState(filterOptions[0]);
    const [usersData, setUsersData] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedbrand, setSelectedBrand] = useState(brandsOptions)
    const [btnVisibility, setBtnVisibility] = useState(false)
    const [selectedSortBy, setSelectedSortBy] = useState(sortingOptions[0])
    const [selectedFiltered, setSelectedFiltered] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState([{ name: 'Active', code: 'ACTIVE' }])
    const [subscriptionStatus, setSubscriptionStatus] = useState('ACTIVE')

    const dataRef = useRef(null)

    useEffect(()=>{
        getDates()
        getUsers()
    },[])

    const getUsers = async() =>{
        const response = await apiServices.getUserReduced();
        setUsersData(response?.data?.data)
    }

    const getDates = async() =>{
        const response = await commonCal.getDates()
        setDates(response)
        // getData({date : { startDate: response.startToday, endDate: new Date() }})
        // setSelectedDate({ date : { startDate: response.monthStart, endDate: response.endThisMonth }})

        const currdate = new Date();
        const nowDate = new Date().toISOString();
        // const lastsix = new Date(currdate.getFullYear(), currdate.getMonth()-6, 1).setHours(0, 0, 0, 0);
        // const lastSixStart = new Date(lastsix).toISOString();
        // const sixDate = { startDate: lastSixStart, endDate:nowDate };

        // const lastTwelve = new Date(currdate.getFullYear(), currdate.getMonth()-3, 1).setHours(0, 0, 0, 0);
        // const lastTwelveStart = new Date(lastTwelve).toISOString();
        // const twelveDate = { startDate: lastTwelveStart, endDate:nowDate };
        // setSelectedDate({ date : twelveDate})

        const lastOneStart = new Date(currdate.getFullYear(), currdate.getMonth()-1, 1).setHours(0, 0, 0, 0);
        const lastStart1 = new Date(lastOneStart).toISOString();
        const dateLastMonth = { startDate: lastStart1, endDate:nowDate };
        setSelectedDate({ date : dateLastMonth})

        getData({ subscription_status : subscriptionStatus, date : dateLastMonth})

    }

    const getData = async(data) =>{
        setLoading(true)

        if(data?.accountWise){  

            const response = await apiServices.monthWisePeformance(data);
            console.log(response?.data?.data);

            const newData = response?.data?.data?.map((x)=>{
                const userid = x.customer;
                return { ...x ,userName : usersData && usersData.find(y => y.userid === userid)?.billing_details?.company || userid }
            })

            console.log(newData);
            // console.log(usersData);

            const fildata = newData?.map((x)=>{ 
                const headerKey =  monthsArr[x.month - 1] + x.year;
                return  { id :  x.id.toString(), Account_Name : x.userName , [headerKey] : x.cups}  
            })

            console.log(fildata);

            const concatenatedArray = fildata?.reduce((acc, obj) => {
                const existingObj = acc.find(item => item.Account_Name === obj.Account_Name);
                if (existingObj) {
                    Object.assign(existingObj, obj );
                } else {
                    acc.push(obj);
                }
                return acc;
            }, []);

            console.log(concatenatedArray);

            const keysToExclude = ['id', 'Account_Name'];

            const datata = concatenatedArray.map((x)=>{
                const sum =  Object.keys(x).reduce((sum, key) => {
                    if (!keysToExclude.includes(key)) {
                      return sum + x[key];
                    }
                    return sum;
                }, 0);

                return {...x, Total : sum}

            })

            console.log(datata);

            let filteredData;
            if(selectedSortBy?.code == 'desc'){
                filteredData = datata.sort((a, b) => b.Total - a.Total);
            }else if(selectedSortBy?.code == 'asc'){
                filteredData = datata.sort((a, b) => a.Total - b.Total);
            }   


            setDayWiseData(filteredData)

            const keysArray = filteredData.map(obj => Object.keys(obj));
            const allKeys = keysArray.flat().filter((x)=>{ return x !== 'id' });
            const uniqueKeys = [...new Set(allKeys)];
            console.log(uniqueKeys);

            // const newDates = uniqueKeys.sort( function(c,d){
            //     const rx = /(\d+)\-(\d+)\-(\d+)/;
            //     const a = Number(c.replace(rx, '$3$1$20000'));
            //     const b = Number(d.replace(rx, '$3$1$20000'));
            //     return a > b ? -1 : a == b ? 0 : 1; 
            // });

            // setAllMaterials(newDates)

            
            const monthsArray = uniqueKeys. slice(1, uniqueKeys.length) 

            console.log(monthsArray);

            // const monthsArray = ['DEC2022', 'JAN2023', 'FEB2023'];

            monthsArray.sort((a, b) => {
                const monthOrder = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

                const aMonth = monthOrder.indexOf(a.substr(0, 3));
                const bMonth = monthOrder.indexOf(b.substr(0, 3));

                const aYear = parseInt(a.substr(3), 10);
                const bYear = parseInt(b.substr(3), 10);

                if (aYear === bYear) {
                    return aMonth - bMonth;
                } else {
                    return aYear - bYear;
                }
            });

            console.log(monthsArray);
            const first5 = uniqueKeys. slice(0, 1) 
            const newDates = [...first5,...monthsArray.reverse()];
            setAllMaterials(newDates)

        }else{

            const response = await apiServices.monthWisePeformance(data);
            console.log(response?.data?.data);

            const fildata = response?.data?.data?.map((x)=>{ 
                const headerKey =  monthsArr[x.month - 1] + x.year;
                return{ 
                    id :  x.id,
                    Company_Name : x.siteName, 
                    Facility_Address : x.facility_address,
                    State : x.facility_state, 
                    City : x.facility_city, 
                    KAM : x?.accountmanager ?  x.accountmanager_name + " " + x.accountmanager_lastname + " / " + x.accountmanager_number : 'NA',
                    [headerKey] : x.cups
                }  
            })

            const concatenatedArray = fildata?.reduce((acc, obj) => {
                const existingObj = acc.find(item => item.id === obj.id);
                if (existingObj) {
                    Object.assign(existingObj, obj );
                } else {
                    acc.push(obj);
                }
                return acc;
            }, []);

            console.log(concatenatedArray);

            const keysToExclude = ['id', 'City', 'State', 'Company_Name', 'Facility_Address', 'KAM'];

            const datata = concatenatedArray?.map((x)=>{
                const sum =  Object.keys(x).reduce((sum, key) => {
                    if (!keysToExclude.includes(key)) {
                        return sum + x[key];
                    }
                    return sum;
                }, 0);

                return {...x, Total : sum}

            })

            console.log(datata);

            let filteredData;
            if(selectedSortBy?.code == 'desc'){
                filteredData = datata?.sort((a, b) => b.Total - a.Total);
            }else if(selectedSortBy?.code == 'asc'){
                filteredData = datata?.sort((a, b) => a.Total - b.Total);
            }

            setDayWiseData(filteredData)
            setDayWiseMasterData(filteredData)

            const keysArray = filteredData.map(obj => Object.keys(obj));
            const allKeys = keysArray.flat().filter((x)=>{ return x !== 'id' });

            console.log(allKeys);

            const uniqueKeys = ['id',...new Set(allKeys)];
            console.log(uniqueKeys);


            const monthsArray = uniqueKeys.slice(6, uniqueKeys.length) 

            console.log(monthsArray);

            // const monthsArray = ['DEC2022', 'JAN2023', 'FEB2023'];

            monthsArray.sort((a, b) => {
                const monthOrder = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

                const aMonth = monthOrder.indexOf(a.substr(0, 3));
                const bMonth = monthOrder.indexOf(b.substr(0, 3));

                const aYear = parseInt(a.substr(3), 10);
                const bYear = parseInt(b.substr(3), 10);

                if (aYear === bYear) {
                    return aMonth - bMonth;
                } else {
                    return aYear - bYear;
                }
            });

            console.log(monthsArray);
            const first5 = uniqueKeys. slice(0, 6) 
            const newDates = [...first5,...monthsArray.reverse()];
            setAllMaterials(newDates)
            getAnalytics(filteredData)
        }

        setLoading(false)
    }

    const getAnalytics = (data) =>{

        if(selectedSortBy?.code == 'desc'){
            const highRange = data[0]?.Total || 0;
            const cuppageRange = getCuppageRange(highRange);
        }else{
            const highRange = data[data.length - 1]?.Total || 0;
            const cuppageRange = getCuppageRange(highRange);
        }
    }

    const changeView = (e) =>{
        setSelectedFiltered(e.target.value)
        
        switch (e.target.value?.code) {
            case 'one':{
                const data = getDataFromChip(80,100)
                console.log(data);
                getFilteredData(data)
                break;
            }
            case 'two':{
                const data = getDataFromChip(60,80)
                console.log(data);
                getFilteredData(data)
                break;
            }
            case 'three':{
                const data = getDataFromChip(40,60)
                console.log(data);
                getFilteredData(data)
                break;
            }
            case 'four':{
                const data = getDataFromChip(20,40)
                console.log(data);
                getFilteredData(data)
                break;
            }
            case 'five':{
                const data = getDataFromChip(0,20)
                console.log(data);
                getFilteredData(data)
                break;
            }

            default:
                break;
        }
    }

    const getDataFromChip = (a, b) =>{
        const min = getCuppageRange(a);
        const max = getCuppageRange(b);
        return { min, max }
    }

    const getCuppageRange = (percentage) =>{
        let number = dayWiseMasterData?.length > 0 &&  (selectedSortBy?.code == 'desc' ? dayWiseMasterData[0].Total : dayWiseMasterData[dayWiseMasterData?.length - 1].Total); 
        let result = (percentage / 100) * number;
        return result;
    }

    const getFilteredData = (payload) =>{

        const data =  dayWiseMasterData.filter((x)=>{
            return x.Total > payload?.min && x.Total <= payload?.max;
        })

        setDayWiseData(data)
        console.log(data);
    }

    // const getCuppageRange = () =>{
    //     let number = 55510;
    //     let percentage = 80;
    //     let result = (percentage / 100) * number;
    //     return result;
    // }

    const changeDuration = (e) =>{
        // setLoading(true)
        setBtnVisibility(true)
        setSelectedDuration(e.target.value)

        const currdate = new Date();
        const nowDate = new Date().toISOString();
        const filteredBrand = selectedbrand.map((x)=>{ return x.code })

        switch(e.target.value.code) {
            case 'this_month':

                const lastOneStart = new Date(currdate.getFullYear(), currdate.getMonth()-1, 1).setHours(0, 0, 0, 0);
                const lastStart1 = new Date(lastOneStart).toISOString();
                const dateLastMonth = { startDate: lastStart1, endDate:nowDate };
                setSelectedDate({ date : dateLastMonth})
                // getData({ brand : filteredBrand, accountWise : filterType.code == 'accountWise' ? true : false, date : date })
                break;
            case 'last_three_month':

                const lastMonthStart = new Date(currdate.getFullYear(), currdate.getMonth()-3, 1).setHours(0, 0, 0, 0);
                const lastStart = new Date(lastMonthStart).toISOString();
                const date = { startDate: lastStart, endDate:nowDate };
                setSelectedDate({ date : date})
                // getData({ brand : filteredBrand, accountWise : filterType.code == 'accountWise' ? true : false, date : date })
                break;
            case 'last_six_month':
                const lastsix = new Date(currdate.getFullYear(), currdate.getMonth()-6, 1).setHours(0, 0, 0, 0);
                const lastSixStart = new Date(lastsix).toISOString();
                const sixDate = { startDate: lastSixStart, endDate:nowDate };
                setSelectedDate({ date : sixDate})
                // getData({ brand : filteredBrand, accountWise : filterType.code == 'accountWise' ? true : false, date : sixDate })
                break;
            case 'last_twelve_months':

                const lastTwelve = new Date(currdate.getFullYear(), currdate.getMonth()-12, 1).setHours(0, 0, 0, 0);
                const lastTwelveStart = new Date(lastTwelve).toISOString();
                const twelveDate = { startDate: lastTwelveStart, endDate:nowDate };
                setSelectedDate({ date : twelveDate})
                // getData({ brand : filteredBrand, accountWise : filterType.code == 'accountWise' ? true : false, date : twelveDate })

                break;
            case 'custom':
                setCustomDateDialog(true)
                break;
            default:
                const defaultD = new Date(currdate.getFullYear(), currdate.getMonth()-3, 1).setHours(0, 0, 0, 0);
                const defaultDStart = new Date(defaultD).toISOString();
                const defaultSate = { startDate: defaultDStart, endDate:nowDate };
                setSelectedDate({ date : defaultSate})
                // getData({ brand : filteredBrand, accountWise : filterType.code == 'accountWise' ? true : false, date : defaultSate })
          }
    }

    const changeSortBy = (e) =>{
        setSelectedSortBy(e.target.value)
        setBtnVisibility(true)
    }

    const changeBrand = (e) =>{
        
        if(e.value?.length > 0){
            setSelectedBrand(e.value)
            setBtnVisibility(true)
            // const filteredData = e.value.map((x)=>{ return x.code })
            // getData({ 
            //     brand : filteredData,
            //     accountWise : filterType.code == 'accountWise' ? true : false,
            //     ...selectedDate
            // })
        }
    }

    const changeStatus = (e) =>{
        setBtnVisibility(true)
        if(e.value?.length == 1){
            setSubscriptionStatus(e.value[0].code)
            setSelectedStatus(e.value)
        }else if(e.value?.length >= 2){
            setSubscriptionStatus('ALL')
            setSelectedStatus(activityStatusOptions)
        }
    }

    const changeWise = (e) =>{
        setFilterType(e.target.value)
        setBtnVisibility(true)
        // const filteredData = selectedbrand.map((x)=>{ return x.code })
        // getData({ brand : filteredData, accountWise : e.target.value.code == 'accountWise' ? true : false,...selectedDate})
    }

    const tableStyle={ overflowX: 'scroll', width:'100%' };

    const hideCustomDialog = () =>{
        setLoading(false)
        setCustomDateDialog(false)
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
        setCustomDateDialog(false)
        setSelectedDuration({ name: 'Custom', code: 'custom' });
        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        let cust_start = new Date(startt).toISOString();
        let cust_end = new Date(endd).toISOString();
        const date = { startDate: cust_start, endDate:cust_end };
        setSelectedDate({ date : date})
        // getData({ date })
        const filteredData = selectedbrand.map((x)=>{ return x.code })
        getData({ subscription_status : subscriptionStatus, brand : filteredData, accountWise : filterType.code == 'accountWise' ? true : false, date })
    }

    const filterData = () =>{
        setBtnVisibility(false)
        const filteredData = selectedbrand.map((x)=>{ return x.code })
        getData({ brand : filteredData, subscription_status : subscriptionStatus, accountWise : filterType.code == 'accountWise' ? true : false, date : selectedDate?.date })
    }

    const customFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
            <button className="p-button p-button-success" type="submit" form="custom-form" ><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
        </>
    )

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                <h6 className="p-mt-3 p-mr-5">Month Wise Cup Consumption Performance (commercial)</h6>
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button className='custom-group-btn p-button-rounded p-m-2' onClick={()=>{exportReport(dataRef)}} icon="pi pi-download"></Button>
                </div>
            </span>
        </div>
    )

    const leftToolbarTemplate = () =>(
        <>
            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="BRAND" />
                <MultiSelect name='brand' value={selectedbrand} onChange={changeBrand} options={brandsOptions} optionLabel="name" display="chip" placeholder="Select Brand" maxSelectedLabels={3} filter  />
                {/* <Dropdown value={selectedbrand} options={brandsOptions} onChange={changeBrand} optionLabel="name" filter className="p-m-0 multiselect-custom"/> */}
            </div>
            {
                filterType?.code == 'cvesWise' &&
                <div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' label="STATUS" />
                    <MultiSelect name='brand' value={selectedStatus} onChange={changeStatus} options={activityStatusOptions} optionLabel="name" display="chip" placeholder="Select Status" maxSelectedLabels={3} filter  />
                </div>
            }

            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="FILTER BY" />
                <Dropdown value={filterType} options={filterOptions} onChange={changeWise} optionLabel="name" filter className="p-m-0 multiselect-custom"/>
            </div>
        </>
    )

    const rightToolbarTemplate = () => (
        <>
            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="DURATION" />
                <Dropdown value={selectedDuration} options={durationOptions} onChange={changeDuration} optionLabel="name" placeholder="Select Duration" filter className="p-m-0 multiselect-custom"/>
            </div>

            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="SORT BY" />
                <Dropdown value={selectedSortBy} options={sortingOptions} onChange={changeSortBy} optionLabel="name" filter className="p-m-0 multiselect-custom"/>
            </div>

            <div className="p-inputgroup p-m-1">
                {
                    btnVisibility && <Button icon="pi pi-filter" label='Filter' className="custom-group-btn p-button p-my-2 p-mx-2" onClick={filterData} />
                }
                {/* <Dropdown value={selectedDuration} options={durationOptions} onChange={changeDuration} optionLabel="name" placeholder="Select Duration" filter className="p-m-0 multiselect-custom"/> */}
            </div>
        </>
    ) 

    const analyticsToolbarTemplate = () =>{
        return(
            <div className='p-grid p-mt-1'>
                <div className='p-col-sm-4'>
                    <Chip label={`Total ${filterType?.name}'s  : ${dayWiseData?.length ? dayWiseData?.length : '-'}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                    {/* <Chip label={`One`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" onClick={()=>{getDataFromChip(80,100)}} />
                    <Chip label={`Two`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                    <Chip label={`Three`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                    <Chip label={`Four`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                    <Chip label={`FIve`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" /> */}

                </div>
            </div>
        )
    }

    const rightAnalyticsToolbarTemplate = () =>{
        return (
            <>
                {/* <div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' label="SORTTT" />
                    <Dropdown options={viewOptions} onChange={changeView} value={selectedFiltered} optionLabel="name" filter className="p-m-0 multiselect-custom"/>
                </div> */}
            </>
        )
    }


    return (
        <>
             <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        {
                            loading ?
                            <>
                                <div className="spinner-div">
                                    <center><ProgressSpinner strokeWidth="7" animationDuration='4s' /></center>
                                </div>
                            </>
                            :
                            <>
                                <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>  
                                <Toolbar className="p-mb-4 p-toolbar" left={analyticsToolbarTemplate} right={rightAnalyticsToolbarTemplate} ></Toolbar> 
                                <DataTable style={tableStyle} className="datatable-responsive"  paginator rows={10} rowsPerPageOptions={[5, 10, 25]} ref={dataRef} 
                                value={dayWiseData} dataKey="id" exportFilename={`Month Wise Consumption Report`} 
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                loading={loading} globalFilter={globalFilter} header={header} >
                                    <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                                    {
                                        allMaterials.map((x,i)=>(
                                            <Column key={i} field={x} header={x == 'id' ? "CVES ID" : x} body={NumberCommonBodyForTable} style={{width : '10rem'}} sortable ></Column>
                                        ))
                                    }

                                </DataTable>
                            </>
                        }

                    </div>
                </div>
            </div>

            <Dialog visible={customDateDialog} style={{ width: '550px' }} header="Custom Filter" footer={customFooter} onHide={hideCustomDialog} modal className="p-fluid">
                <form onSubmit={handleSubmit} id="custom-form" className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From</label>
                            <InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => {setCustomStart(e.target.value)} } required autoFocus/>
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' min={customStart}  value={customEnd} onChange={(e) => {setCustomEnd(e.value)}} required/>
                        </div>
                    </div>
                </form>
            </Dialog>

        </>
    )
}
