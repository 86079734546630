import React , { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { AccountWiseCupChartNew } from './AccountWiseCupChartNew';
import { BeverageWiseCupChartNew } from './BeverageWiseCupChartNew';
import { bevVendingMchOptions, accountTypeValues, subTypeOptions, durationOptions } from './Constants';

import CommonCal from '../../service/common/CommonCal';
import ApiServices from '../../service/api/ApiServices';
const commonCal = new CommonCal();
const apiServices = new ApiServices();

// ================================================================================
// ================================================================================

export const MisReportNew = () => {

	const [loading, setLoading] = useState(true)
	const [selectedDate, setSelectedDate] = useState(null);
    const [selectedBVMBrand, setSelectedBVMBrand] = useState(bevVendingMchOptions);
	const [accType, setAccType] = useState(accountTypeValues[0]);
	const [accountsOptions, setAccountsOptions] = useState(null);
	const [selectedAccount, setSelectedAccount] = useState({name : 'All'});
	const [selectedSubType, setSelectedSubType] = useState(subTypeOptions[0]);
	const [selectedDuration, setSelectedDuration] = useState(durationOptions[2]);
	const [filterButton, setFilterButton] = useState(true)
	const [datesData, setDatesData] = useState(null)
	const [accountWiseData, setAccountWiseData] = useState(null)
	const [customFilterDialog, setCustomFilterDialog] = useState(false)
	const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
	const [savedPayload, setSavedPayload] = useState(null)
	const [allFacilities, setAllFacilities] = useState([{facname:'All'}])
	const [selectedFacility, setSelectedFacility] = useState({facname:'All'})
	

	useEffect(() => {
        getInitialData()
		getDates()
    }, []);

	const getDates = async() =>{
        const response = commonCal.getDates()
        console.log(response);
        setDatesData(response)
    }

    const getInitialData = () =>{

        const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 0);
        const lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(23, 59, 59, 0);
        const date = { startDate: new Date(firstDay).toISOString(), endDate:new Date(lastDay).toISOString() };
		setSelectedDate(date)

		const payload = { date,
            "beverageWise": false,
            "brand": [ "TAPRI", "TKAFFE", "MERI_TAPRI" ],
            "subscriptionType": "all",
        }

		accWiseData(payload)
    }

	const accWiseData = async (payload, filter ) =>{
		setLoading(true)
		console.log(payload);
		setSavedPayload(payload)
        
        // const response = await apiServices.accountWisePerformance(payload);
        const response = await apiServices.accountWiseConsumption(payload);

        const response2 = await apiServices.getActiveZohoUser();
        const arr = [];
        response2?.data?.data.forEach((element) => {
            const userid= element.userid;
            response?.data?.data.map((x) => {        
                if(userid == x.customer){
                    arr.push({...x,name:element?.billing_details?.company}) 
                }
              });
        });

        const descending = arr.sort((a, b) => Number(b.cups) - Number(a.cups));
        console.log(descending);
		setAccountWiseData(descending)
		!filter && setAccountsOptions([{name : 'All'},...descending])
		setLoading(false)
    }

	const changeBVMBrand = (e) =>{
        setSelectedBVMBrand(e.value)
		if(e.value?.length == 0){
			setFilterButton(true)
		}else{
			setFilterButton(false)	
		}
	}

	const filterAccType = (e) =>{
		setFilterButton(false)	
        setAccType(e.value)
	}

	const changeAccount = (e) =>{
		setFilterButton(false)	
		setSelectedAccount(e.value)
		getFacility(e.value)
		setSelectedFacility({facname:'All'})
	}

	const changefacility = (e) =>{
		setFilterButton(false)	
		setSelectedFacility(e.value)
	}

	const getFacility = async(data) =>{
		const payload = { userid: data?.customer }
		const response = await apiServices.getFacility(payload);
		console.log(response?.data?.data);
		const arr = [{facname:'All'},...response?.data?.data];
		setAllFacilities(arr)
	}

	const changeSubType = (e) =>{
		setFilterButton(false)	
		setSelectedSubType(e.value)
	}

	const OnChangeTimeValue = (e) =>{
		setSelectedDuration(e.value)
		e.value?.code == 'custom' ? setFilterButton(true) : setFilterButton(false)
		
		const today = { startDate: datesData.startToday, endDate: new Date() };
        const yesterday = { startDate: datesData.YestStart, endDate:datesData.EndStart };
        const thisWeek = { startDate: datesData.weekstart, endDate:datesData.weekEnd };
        const thisMonth = { startDate: datesData.monthStart, endDate:datesData.endThisMonth };
        const lastMonth = { startDate: datesData.lastStart, endDate:datesData.lastend };

		switch (e.value?.code) {
			case 'all':
				setSelectedDate(null)
			break;
			case 'this_week':
				setSelectedDate(thisWeek)
			break;
			case 'this_month':
				setSelectedDate(thisMonth)
			break;
			case 'last_month':
				setSelectedDate(lastMonth)
			break;
			case 'custom':
				setCustomFilterDialog(true);
			break;
			default:
			break;
		}
	}

	const filterData = (e) =>{
		e.preventDefault();
		setFilterButton(true)	
		const brandPayload = [];
		selectedBVMBrand.forEach((x)=>{ brandPayload.push(x.code) })

		const payload = { 
			"date": selectedDate,
			"beverageWise": false,
			"brand": brandPayload,
			"subscriptionType": selectedSubType?.code,
			"customer": selectedAccount?.name == 'All' ? null : selectedAccount?.customer,
			"facid" : selectedFacility?.facname === 'All' ? null : selectedFacility?.facid
		}

		accWiseData(payload, 'filter')
	}

	const hideCustomDialog = () =>{
        setCustomFilterDialog(false);
		setCustomStart('')
		setCustomEnd('')
    }

	const handleSubmit = async(e) =>{
        setCustomFilterDialog(false)
        e.preventDefault()

		const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
		const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
		const cust_start = new Date(startt).toISOString();
		const cust_end = new Date(endd).toISOString();
		const date = { startDate: cust_start, endDate:cust_end };
		setSelectedDate(date)

		const brandPayload = [];
		selectedBVMBrand.forEach((x)=>{ brandPayload.push(x.code) })

		const payload = { "date": date, "beverageWise": true,
            "brand": brandPayload, "subscriptionType": selectedSubType?.code,
			"customer": selectedAccount?.name == 'All' ? null : selectedAccount?.customer
        }


		accWiseData(payload, 'filter')  
    }

	const customFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
            <button className="p-button p-button-success" type="submit" form="custom-form" ><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
        </>
    )

	const leftToolbarTemplate = () => {
        return (
            <>
				<div className="p-inputgroup p-m-1" style={{minWidth:'450px', maxWidth:'450px'}}>
					<Button className='custom-group-btn' label="CATEGORY" />
					<MultiSelect value={selectedBVMBrand} onChange={changeBVMBrand} options={bevVendingMchOptions} optionLabel="name" display="chip"
					placeholder="Select Categories" maxSelectedLabels={3} className={`${selectedBVMBrand?.length == 0 && 'p-invalid'} w-full md:w-20rem`} filter  />
				</div>

				{/* <div className="p-inputgroup p-m-0">
                    <Button className='custom-group-btn' label="ACC TYPE" tooltip="Account Type" />
                    <Dropdown id='select-mc' value={accType} onChange={filterAccType} options={accountTypeValues} optionLabel="name" placeholder="Select Account Type" filter className="p-p-1 multiselect-custom " />
                </div> */}

				<div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' label="ACCOUNT" tooltip={`Accounts ${accountsOptions?.length - 1}`} />
                    <Dropdown id='select-mc' value={selectedAccount} onChange={changeAccount} options={accountsOptions} optionLabel="name" placeholder="Select Account" filter className="p-p-1 multiselect-custom " />
                </div>			

				{
					selectedAccount?.name != 'All' && 
					<div className="p-inputgroup p-m-1" style={{width:'300px'}}>
						<Button className='custom-group-btn' label="FACILITY" tooltip="Facility" />
						<Dropdown id='select-mc' value={selectedFacility} onChange={changefacility} options={allFacilities} optionLabel="facname" placeholder="Select Facility" filter className="p-p-1 multiselect-custom " />
					</div>			
				}

            </>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <>
				<div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' label="SUB TYPE" tooltip="Subscription Type" />
                    <Dropdown id='select-mc' value={selectedSubType} onChange={changeSubType} options={subTypeOptions} optionLabel="name" placeholder="Select Subscription Type" filter className="p-p-1 multiselect-custom " />
                </div>

                <div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' label="DURATION" />
                    <Dropdown value={selectedDuration} onChange={OnChangeTimeValue} options={durationOptions} optionLabel="name" placeholder="Select Filter" filter
                        className="p-p-1 multiselect-custom" />    
                </div> 

				<div>
                    { (!filterButton && selectedBVMBrand?.length > 0) && <Button icon="pi pi-filter" label='Filter' className="custom-group-btn p-button p-my-2 p-mx-2" disabled={filterButton} onClick={filterData} /> }
                </div> 
            </>
        )
    }



	return (
		<>
			<div className='card'>
            	<Toolbar className="p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            </div>

			<div className='card'>
				<TabView>
					<TabPanel header="Account Wise Cup Consumption">
						<AccountWiseCupChartNew loading={loading} accountWiseData={accountWiseData} />
					</TabPanel>
					<TabPanel header="Beverage Wise Cup Consumption">
						<BeverageWiseCupChartNew loading={loading} savedPayload={savedPayload} />
					</TabPanel>
				</TabView>
			</div>

			<Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter" footer={customFooter}  onHide={hideCustomDialog} modal className="p-fluid" maximizable={true} blockScroll={true}>

                {
                    selectedBVMBrand?.length == 0 ? 
                    <>
                        <center><h6 className="p-error">Please select atleast one category</h6></center>
                    </>
                    : 
                    <form onSubmit={handleSubmit} id="custom-form" className="p-fluid">
                        <div className='p-grid'>
							<div className='p-col-6'>
								<label htmlFor="from">From </label>
								<InputText id="from" type="date" name='start_date' max={new Date().toISOString().slice(0, 10)}  value={customStart} onChange={(e) => {setCustomStart(e.target.value) } } required autoFocus/>
							</div>
							<div className='p-col-6'>
								<label htmlFor="to">To</label>
								<InputText id="to" type="date" name='end_date' min={customStart} max={new Date().toISOString().slice(0, 10)} value={customEnd} onChange={(e) => {setCustomEnd(e.target.value)}} required/>
							</div>
                        </div>
                    </form>
                }

            </Dialog>
        

		</>
	)
}
