import React, { useEffect, useState } from "react";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()
// LocalStorage
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);


export const RefillOrder = () =>{

    const [facilityData,setFacilityData] = useState(null);
    const [selectedFacility,setSelectedFacility] = useState(null);
    const [encryptedNumber,setEncryptedNumber] = useState(null);

    const Appurl = 'https://app.cherisegloabal.com'
    // https://app.cherisegloabal.com?contact=encryptedkey
    
    useEffect(()=>{
        getfacilities()
        getEncrypt()
    },[])


    const getEncrypt = async() =>{
        
        const contact = initialValue.cno.length > 10 ? initialValue.cno.substr(-10) : initialValue.cno
        const response = await apiServices.getEncrytedNumber({ cno: Number(contact)});
        console.log(response?.data?.encryptedNumber)
        setEncryptedNumber(response?.data?.encryptedNumber)        
    }

    const getfacilities = async() =>{
        const response = await apiServices.getFacility({userid: initialValue.userid});
        console.log(response?.data?.data);
        setFacilityData(response?.data?.data)
    }

    const changeFacility = (e) =>{
        setSelectedFacility(e.target.value)
        console.log(e.target.value);
    }

    return (

        <>

        {
            encryptedNumber &&

            <>
                {/* Testing */}

                {/* <iframe style={{height:'100%', width:'100%', overflow: 'scroll'}} src={`http://91.205.173.97:6001/?contact=${encryptedNumber}&redirect=REFILL_SCREEN#/splash`}></iframe> */}

                {/* MAIN */}

                <iframe style={{height:'100%', width:'100%', overflow: 'scroll'}} src={`${process.env.REACT_APP_IFRAME_URL}?contact=${encryptedNumber}`}></iframe>
                {/* <iframe style={{height:'100%', width:'100%', overflow: 'scroll'}} src={`https://app.cheriseglobal.com?contact=${encryptedNumber}`}></iframe> */}
            </>
        }



            {/* <div className="card">
                <div className="card">
                    <span className="p-input-icon-left">
                        <div className="p-inputgroup">
                            <Button className='custom-group-btn' label="Facility" />
                            <Dropdown value={selectedFacility} onChange={changeFacility} options={facilityData} optionLabel="facname" placeholder="Select Facility" filter
                                className="multiselect-custom" />
                        </div>
                    </span >
                </div>

                {
                    selectedFacility &&
                    <Products selectedFacility={selectedFacility} />
                }
            </div> */}


        </>
    )
}