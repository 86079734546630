import React, {useState, useEffect, useRef} from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
// Common
import { CommonForDetails } from '../CRM/Leads';
import { CommonBodyForTable, commonCloseDialogFooter, commonDialogFooter, commonFormDialogFooter } from '../Common/CommonComponents';
import { unitOptions, manufacturerOptions, brandsOptions, accOptions } from './Constants';
// Services
import ApiServices from '../../service/api/ApiServices';
const apiServices = new ApiServices();

// =======================================================================================================
// =======================================================================================================

export const RawMaterial = () =>{

    const [globalFilter, setGlobalFilter] = useState('');
    const [loading, setLoading] = useState('');
    const [allGroups, setAllGroups] = useState([{group_name : 'All'}]);
    const [allItems, setAllItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [viewItemsDialog, setViewItemsDialog] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState({group_name : 'All'})
    const [addItemDialog, setAddItemDialog] = useState(false)
    const [allGroupsOptions, setAllGroupsOptions] = useState(null)
    const [allContacts, setAllContacts] = useState([]);
    const [addItemTemplate, setAddItemTemplate] = useState({});

    const [selectedGroupOp, setSelectedGroupOp] = useState(null);
    const [itemTypeOp, setItemTypeOp] = useState(null);
    const [selectUnitOp, setSelectUnitOp] = useState(null);
    const [returnItemChecked, setReturnItemChecked] = useState(false);
    const [taxPreferenceOp, setTaxPreferenceOp] = useState(null);
    const [selectedManufacturerOp, setSelectedManufacturerOp] = useState(null);
    const [selectedBrandOp, setSelectedBrandOp] = useState(null);
    const [selectedAccOpt, setSelectedAccOpt] = useState(null);
    const [selectedVendorOpt, setSelectedVendorOpt] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const toast = useRef(null)

    useEffect(()=>{
        getAllGroups()
        getAllItems()
        getAllContacts()
    },[])

    const getAllGroups = async() =>{
        const response = await apiServices.getGroups();
        setAllGroupsOptions(response?.data?.data)
        setAllGroups([...allGroups,...response?.data?.data])
    }
    
    const getAllItems = async() =>{
        const response = await apiServices.getItems();
        console.log(response?.data?.data);
        setAllItems(response?.data?.data);
        setFilteredItems(response?.data?.data);
    }

    const getAllContacts = async() =>{
        const response = await apiServices.getcontacts();
        console.log(response?.data?.data);
        setAllContacts(response?.data?.data)
    }
    
    const openAddItem = () =>{
        setAddItemDialog(true)
    }

    const hideItemDialog = () =>{
        setAddItemDialog(false)
        setAddItemTemplate({})
        setSelectedGroupOp(null)
        setItemTypeOp(null)
        setSelectUnitOp(null)
        setReturnItemChecked(false)
        setTaxPreferenceOp(null)
        setSelectedManufacturerOp(null)
        setSelectedBrandOp(null)
        setSelectedAccOpt(null)
        setSelectedVendorOpt(null)
        setSelectedItem(null)
    }

    const changeFilter = (e) =>{
        console.log(e.target.value)
        setSelectedGroup(e.target.value)
        if(e.target.value.group_name == 'All'){
            setFilteredItems(allItems)
        }else{
            const filteredItem = allItems.filter((x)=>{ return x.group_name == e.target.value.group_name; })
            setFilteredItems(filteredItem)
        } 
    }

    const changeTemp = (e) =>{

        if(e.target.name == 'group_name'){
            setSelectedGroupOp(e.target.value)
            setAddItemTemplate({...addItemTemplate,[e.target.name] : e.target.value.group_name})
        }

        if(e.target.name == 'item_type'){
            setItemTypeOp(e.target.value)
            setAddItemTemplate({...addItemTemplate,[e.target.name] : e.target.value})
        }

        if(e.target.name == 'item_name' || e.target.name == 'hsn'){
            setAddItemTemplate({...addItemTemplate,[e.target.name] : e.target.value})
        }

        if(e.target.name == 'length' || e.target.name == 'width' || e.target.name == 'height' || e.target.name == 'weight'
        || e.target.name == 'upc' || e.target.name == 'ean' || e.target.name == 'mpn' || e.target.name == 'isbn' || e.target.name == 'pprice' || e.target.name == 'pdescript'){

            setAddItemTemplate({...addItemTemplate,product_details : {...addItemTemplate?.product_details, [e.target.name] : e.target.value}})
        }

        if(e.target.name == 'sku'){
            setAddItemTemplate({...addItemTemplate,stock : {...addItemTemplate?.stock, [e.target.name] : e.target.value}})
        }

        if(e.target.name == 'unit'){
            setSelectUnitOp(e.target.value)
            setAddItemTemplate({...addItemTemplate,stock : {...addItemTemplate?.stock, [e.target.name] : e.target.value.code}})
        }

        if(e.target.name == 'returnitem'){
            setReturnItemChecked(e.target.checked)
            setAddItemTemplate({...addItemTemplate,product_details : {...addItemTemplate?.product_details, [e.target.name] : e.target.checked}})
        }

        if(e.target.name == 'taxpre'){
            setTaxPreferenceOp(e.target.value)
            setAddItemTemplate({...addItemTemplate,product_details : {...addItemTemplate?.product_details, [e.target.name] : e.target.value}})
        }

        if(e.target.name == 'manufacture'){
            setSelectedManufacturerOp(e.value)
            setAddItemTemplate({...addItemTemplate,product_details : {...addItemTemplate?.product_details, [e.target.name] : e.value.name}})
        }

        if(e.target.name == 'brand'){
            setSelectedBrandOp(e.value)
            setAddItemTemplate({...addItemTemplate,product_details : {...addItemTemplate?.product_details, [e.target.name] : e.value.name}})
        }

        if(e.target.name == 'paccount'){
            setSelectedAccOpt(e.value)
            setAddItemTemplate({...addItemTemplate,product_details : {...addItemTemplate?.product_details, [e.target.name] : e.value.name}})
        }

        if(e.target.name == 'pvendor'){
            setSelectedVendorOpt(e.value)
            setAddItemTemplate({...addItemTemplate,product_details : {...addItemTemplate?.product_details, [e.target.name] : e.value.company_name}})
        }

    }

    // const xx = {
    //     "product_details":{
    //         "pvendor":"xyz",
    //         "returnitem":true,
    //         "taxpre":"Taxable",
    //         "length":"10101",
    //         "width":"10",
    //         "height":"10",
    //         "weight":"10",
    //         "manufacture":"All Types",
    //         "brand":"All Types",
    //         "upc":"10",
    //         "ean":"10",
    //         "mpn":"10",
    //         "isbn":"10",
    //         "pprice":"10",
    //         "paccount":"Bank Fee and Charges",
    //         "pdescript":"Desvfbjbhjbhjvjbh"
    //     },
    //     "stock":{
    //         "sku":100000,
    //         "unit":"Box",
    //         "duration":"2022-11-11T04:46:26.064Z"
    //     },
    //     "item_type":"Goods",
    //     "item_name":"Item Name",
    //     "hsn":"GHGVG",
    //     "group_name":"Machine"
    // }

    const submitItems = async(e) =>{
        e.preventDefault();
        setAddItemDialog(false)
        
        const data = {...addItemTemplate,stock : {...addItemTemplate?.stock, duration : new Date(new Date).toISOString()}}
        
        if(selectedItem){
            const response = await apiServices.updateitem(data);
            response?.data?.success ? success('Item Updated Successfully') : failure('Failed to Update') 
        }else{    
            const response = await apiServices.additem(data);
            response?.data?.success ? success('Item Added Successfully') : failure('Failed to Add') 
        }

        getAllItems();
        hideItemDialog()
    }


    const openEdit = (data) =>{
        console.log(data);
        setSelectedItem(data)
        setAddItemDialog(true)
        setFormForEdit(data)
    }

    const setFormForEdit = (data) =>{
        setAddItemTemplate(data)

        const filgrp = allGroupsOptions.filter((x)=>{ return x.group_name == data?.group_name})
        setSelectedGroupOp(filgrp ? filgrp[0] : null )
        setItemTypeOp(data?.item_type)

        const filCon = allContacts.filter((x)=>{ return x.company_name == data?.product_details?.pvendor })
        setSelectedVendorOpt(filCon ? filCon[0] : null)

        const filUnit = unitOptions.filter((x)=>{ return x.name == data?.stock?.unit;})
        setSelectUnitOp(filUnit ? filUnit[0] : null)

        setReturnItemChecked(data?.product_details?.returnitem)
        setTaxPreferenceOp(data?.product_details?.taxpre)

        const filman = manufacturerOptions.filter((x)=>{ return x.name == data?.product_details?.manufacture });
        setSelectedManufacturerOp(filman ? filman[0] : null )

        const filbrand = brandsOptions.filter((x)=>{ return x.name == data?.product_details?.brand });
        setSelectedBrandOp(filbrand ? filbrand[0] : null )

        const filAcc = accOptions.filter((x)=>{ return x.name == data?.product_details?.paccount });
        setSelectedAccOpt(filAcc ? filAcc[0] : null )

    }

    const openDelete = (data) =>{
        setDeleteItemDialog(true)
        setSelectedItem(data)
    }

    const deleteItem = async() =>{
        setDeleteItemDialog(false)
        const response = await apiServices.deleteitem({_id: selectedItem?._id});
        response?.data?.success ? success('Item Deleted Successfully') : failure('Failed to Delete') 
        getAllItems();        
    }

    const viewItem = (data) =>{
        console.log(data);
        setViewItemsDialog(true)
        setSelectedItem(data)
    }
 

    const leftToolbarTemplate = () =>(
        <div className="p-inputgroup p-mt-2" >
            <Button className="custom-group-btn" label="GROUP" />
            <Dropdown value={selectedGroup} options={allGroups} onChange={changeFilter} optionLabel="group_name" placeholder="Select Group" filter className="p-mr-2 multiselect-custom" />
        </div>
    )

    const rightToolbarTemplate = () =>(<Button className='p-custom-btn' label="ADD ITEM" icon="pi pi-plus-circle" iconPos="left" onClick={()=>{openAddItem()}}/>)
    const srBodyTemplate = (rowData,props) => (<><span className="p-column-title">Sr.No</span>{props.rowIndex + 1}</>)
    const productBodyTemplate = (data, props) => (<><span className="p-column-title">{props.header}</span>{data.product_details[props.field]}</>)
    const skuBodyTemplate = (data, props) => (<><span className="p-column-title">{props.header}</span>{data.stock[props.field]}</>)

    const itemsActionBodyTemplate = rowData => (
        <>
            <span className="p-column-title">Actions</span>
            <Button icon="pi pi-pencil" title="Edit" className="p-button-rounded p-button-success p-mr-2" onClick={()=>{openEdit(rowData)}}/>
            <Button icon="pi pi-trash" title="Delete" className="p-button-rounded p-button-danger p-mr-2" onClick={()=>{openDelete(rowData)}}/>
            <Button icon="pi pi-eye" title="View" className="p-button-rounded p-button-info p-mr-2" onClick={()=>{viewItem(rowData)}}/>
        </>
    ) 

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right"><span>Groups</span></span>
        </div>
    );

    const hideDelteItemDialog = () =>{ setDeleteItemDialog(false) }
    const hideViewItemDialog = () => { setViewItemsDialog(false) }

    const itemDialogFooter = commonFormDialogFooter('Close', hideItemDialog, 'Submit', 'items-form' )
    const deleteItemDialogFooter = commonDialogFooter('No', hideDelteItemDialog, 'Yes, Delete it', deleteItem )
    const viewItemDialogFooter = commonCloseDialogFooter('Close', hideViewItemDialog )    

    const success = (msg , lifeTime = 3000) =>{
        toast.current.show({ severity: 'success', summary: 'Successful', detail:`${msg}`, life: lifeTime });
    }

    const failure = (msg) =>{
        toast.current.show({ severity: 'error', summary: 'Failed', detail:`${msg}`, life: 3000 });
    }
    
    return(
        <>
            <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} position='center' />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>
                    <DataTable value={filteredItems} dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Groups" globalFilter={globalFilter}
                            emptyMessage="No Groups found." header={'Items'} loading={loading}>
                            <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '6rem' }} ></Column>
                            <Column field="group_name" header="Group Name" body={CommonBodyForTable} sortable ></Column>
                            <Column field="item_name" header="Item Name" body={CommonBodyForTable} sortable ></Column>
                            <Column field="pvendor" header="Vendor Name" body={productBodyTemplate} sortable ></Column>
                            <Column field="pprice" header="Purchase Cost" body={productBodyTemplate} sortable ></Column>
                            <Column field="sku" header="SKU" body={skuBodyTemplate} sortable ></Column>
                            <Column header="Action" body={itemsActionBodyTemplate}></Column>
                    </DataTable>

                </div>
            </div>
            </div>

            <Dialog visible={addItemDialog} style={{width: '200vh'}} header={`${selectedItem ? 'Update' : 'Add'} Item`} modal footer={itemDialogFooter} onHide={(e)=>{hideItemDialog()}}>
                <form onSubmit={submitItems} id="items-form" className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="userId">Select Group</label>
                                <Dropdown id="userId" name="group_name" options={allGroupsOptions} value={selectedGroupOp} onChange={changeTemp} optionLabel="group_name" placeholder="Select Group" ></Dropdown>
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <label htmlFor="userId">Type</label>
                            <div className='p-grid p-mt-3'>
                                <div className='p-col-3'>
                                    <div className="field-radiobutton">
                                        <RadioButton inputId="goods" name="item_type" value={'Goods'} onChange={changeTemp} checked={itemTypeOp == 'Goods'}  />
                                        <label htmlFor="goods1"> Goods</label>
                                    </div>
                                </div>
                                <div className='p-col-3'>
                                    <div className="field-radiobutton">
                                        <RadioButton inputId="services" name="item_type" value={'Services'} onChange={changeTemp} checked={itemTypeOp == 'Services'} />
                                        <label htmlFor="services"> Services</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="item_name">Item Name <i className="p-error fa-sharp fa-thin fa-asterisk"></i> </label>
                                <InputText id="item_name" name='item_name' placeholder="Item Name" defaultValue={selectedItem ? selectedItem?.item_name : null} onChange={changeTemp} />
                            </div>
                        </div>
                        
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="vendorr">Vendor <i className="p-error fa-light fa-asterisk"></i></label>
                                <Dropdown id="vendorr" name="pvendor" value={selectedVendorOpt} options={allContacts} optionLabel="company_name" placeholder="Vendor" onChange={changeTemp} ></Dropdown>
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="sku">SKU <i className="p-error fa-light fa-asterisk"></i></label>
                                <InputText type='number' id="sku" name='sku' placeholder="SKU" defaultValue={selectedItem ? selectedItem?.stock?.sku : null} onChange={changeTemp} tooltip='Stock keeping unit for this item' />
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="userId">Units <i className="p-error fa-light fa-asterisk"></i></label>
                                <Dropdown id="userId" name="unit" value={selectUnitOp} options={unitOptions} optionLabel="name" placeholder="Select Unit" onChange={changeTemp} tooltip='Unit of Measurement' ></Dropdown>
                            </div>
                        </div>

                        <div className="p-col-6">
                            <p>Check</p>
                            <Checkbox inputId="cb1" name='returnitem' value="Returnable Item" checked={returnItemChecked} onChange={changeTemp} tooltip='Returnable item'></Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label"> Returnable Item</label>
                        </div>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="batch_name">HSN Code</label>
                                <InputText id="batch_name" name='hsn' placeholder="HSN Code" defaultValue={selectedItem ? selectedItem?.hsn : null}  onChange={changeTemp} />
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <label htmlFor="userId">Tax Preference <i className="p-error fa-light fa-asterisk"></i></label>
                            <div className='p-grid p-mt-3'>
                                <div className='p-col-3'>
                                    <div className="field-radiobutton">
                                        <RadioButton inputId="taxable" name="taxpre" value="Taxable" onChange={changeTemp} checked={taxPreferenceOp == 'Taxable'}/>
                                        <label htmlFor="taxable"> Taxable</label>
                                    </div>
                                </div>
                                <div className='p-col-3'>
                                    <div className="field-radiobutton">
                                        <RadioButton inputId="non-taxable" name="taxpre" value="Non-Taxable" onChange={changeTemp} checked={taxPreferenceOp == 'Non-Taxable'} />
                                        <label htmlFor="non-taxable"> Non Taxable</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-6">
                            <label htmlFor="batch_name">Diamensions(cm) <small>Length * width * Height</small> </label>
                            <div className="p-inputgroup p-mt-2">
                                <InputText type='number' min={0} placeholder="Length" name='length' defaultValue={selectedItem ? selectedItem?.product_details?.length : null} onChange={changeTemp} /> 
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-times"></i>
                                </span>
                                <InputText type='number' min={0} placeholder="Width" name='width' defaultValue={selectedItem ? selectedItem?.product_details?.width : null} onChange={changeTemp} /> 
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-times"></i>
                                </span>
                                <InputText type='number' min={0} placeholder="Height" name='height' defaultValue={selectedItem ? selectedItem?.product_details?.height : null} onChange={changeTemp} />
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="batch_name">Weight (kg)</label>
                                <InputText type='number' min={0} id="batch_name" name='weight' placeholder="Weight" defaultValue={selectedItem ? selectedItem?.product_details?.weight : null} onChange={changeTemp} />
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="userId">Manufacturer</label>
                                <Dropdown id="userId" name="manufacture" value={selectedManufacturerOp} options={manufacturerOptions} optionLabel="name" placeholder="Select Manufacturer" onChange={changeTemp} ></Dropdown>
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="userId">Brand</label>
                                <Dropdown id="userId" name="brand" value={selectedBrandOp} options={brandsOptions} optionLabel="name" placeholder="Select Brand" onChange={changeTemp} ></Dropdown>
                            </div>
                        </div>
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="batch_name">UPC</label>
                                <InputText id="batch_name" name='upc' placeholder="UPC" defaultValue={selectedItem ? selectedItem?.product_details?.upc : null} onChange={changeTemp} tooltip='Twelve digit unique number associated with bar code (Universal Product Code)' />
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="batch_name">MPN</label>
                                <InputText id="batch_name" name='mpn' placeholder="MPN" defaultValue={selectedItem ? selectedItem?.product_details?.mpn : null} onChange={changeTemp} tooltip='Manufacturing Part Number Unambiguously Identifies a part design' />
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="batch_name">EAN</label>
                                <InputText id="batch_name" name='ean' placeholder="EAN" defaultValue={selectedItem ? selectedItem?.product_details?.ean : null} onChange={changeTemp} tooltip='Thirteen digit Unique number (International Article Number)' />
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="batch_name">ISBN</label>
                                <InputText id="batch_name" name='isbn' placeholder="ISBN" defaultValue={selectedItem ? selectedItem?.product_details?.isbn : null} onChange={changeTemp} tooltip='Thirteen digit Unique commercial book Identifier (International Standard Book Number)' />
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="batch_name">Purchase Price (INR) <i className="p-error fa-light fa-asterisk"></i></label>
                                <InputText type='number' min={0} id="batch_name" name='pprice' placeholder="Purchase Price (INR)" defaultValue={selectedItem ? selectedItem?.product_details?.pprice : null} onChange={changeTemp} />
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="batch_name">Account <i className="p-error fa-light fa-asterisk"></i></label>
                                <Dropdown id="userId" name="paccount" value={selectedAccOpt} options={accOptions} optionLabel="name" placeholder="Select Account" onChange={changeTemp} tooltip='All transactions related to the items you purchase will be displayed in this account' ></Dropdown>
                            </div>
                        </div>

                        <div className='p-col-12'>
                            <div className="p-field">
                                <label htmlFor="batch_name">Description</label>
                                <InputTextarea rows={5} cols={30} name='pdescript' placeholder="Description" defaultValue={selectedItem ? selectedItem?.product_details?.pdescript : null} onChange={changeTemp} autoResize />
                            </div>
                        </div>


                    </div>
                </form>
            </Dialog>

            <Dialog visible={deleteItemDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={()=>{setDeleteItemDialog(false)}}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br/><br/>
                        <b><span>Are you sure you want to delete the Item {selectedItem?.item_name} ? </span></b> <br/><br/>
                        <small>You will no longer have access to this item if deleted</small>
                    </center>
                </div>
            </Dialog>

            <Dialog visible={viewItemsDialog} style={{ width: '50vw' }} header={`${selectedItem?.item_name}`} modal footer={viewItemDialogFooter}  onHide={()=>{setViewItemsDialog(false)}} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                    <div className="p-col-12">
                        <div className=" widget-performance">
                            <h5>Item Details</h5>
                            <div className="content">
                                <ul>
                                <div className='p-grid'>
                                    <CommonForDetails name="Vendor" value={selectedItem?.product_details?.pvendor} />
                                    <CommonForDetails name="UPC" value={selectedItem?.product_details?.upc} />
                                    <CommonForDetails name="Item Type" value={selectedItem?.item_type} />
                                    <CommonForDetails name="EAN" value={selectedItem?.product_details?.ean} />
                                    <CommonForDetails name="SKU" value={selectedItem?.stock?.sku} />
                                    <CommonForDetails name="ISBN" value={selectedItem?.product_details?.isbn} />
                                    <CommonForDetails name="HSN Code" value={selectedItem?.hsn} />
                                    <CommonForDetails name="Manufacturer" value={selectedItem?.product_details?.manufacture} />
                                    <CommonForDetails name="Unit" value={selectedItem?.stock?.unit} />
                                    <CommonForDetails name="Brand" value={selectedItem?.product_details?.brand} />
                                    <CommonForDetails name="Weight" value={selectedItem?.product_details?.weight} />
                                </div>
                                </ul>
                            </div>
                            <h5>PURCHASE DETAILS</h5>
                            <div className="content">
                                <ul>
                                <div className='p-grid'>
                                    <CommonForDetails name="Purchase Cost" value={selectedItem?.product_details?.pprice} />
                                    <CommonForDetails name="Purchase Account" value={selectedItem?.product_details?.paccount} />
                                    <CommonForDetails name="Description" value={selectedItem?.product_details?.pdescript} />
                                </div>
                                </ul>
                            </div>
                        </div>                        
                    </div>
                </div>
            </Dialog>

            


        </>
    )
}