import React, {useState, useEffect} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CommonBodyForTable } from "../Common/CommonComponents";
// 
import { srBodyTemplate } from "../Common/CommonComponents";
// Service
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// =====================================================================================
// =====================================================================================

export const AdminManager = () =>{

    const [otpList, setOtpList] = useState([])
    const [mobileNumber, setMobileNumber] = useState('')
    const [otpValue, setOtpValue] = useState(null)
    const [userAuth, setUserAuth] = useState(false)
    
    useEffect(()=>{
        getOTP()
    },[])
    
    const getOTP = async() =>{
        const userCheck = [ 'usr-em890k81kypncp79','usr-4kcz06434k16hr64q', 'usr-em8907y3leqy5c8v', 'usr-4kcz067ppk1bogdzy', 'usr-em890mjolcq0j6sy' ]
        const check = userCheck.includes(initialValue?.userid)
        setUserAuth(check)
        if(check){
            const response = await apiServices.getOtp();
            const decArr = response?.data?.data?.reverse()
            setOtpList(decArr)
        }
    }

    const changeNumber = (e) =>{
        if(e.target.name === 'contact'){
            setMobileNumber(e.target.value)
        }
    }

    const getAppOtp = async(e) =>{
        e.preventDefault();
        const payload = { "contact": Number(mobileNumber), "country_code":"+91", "secret_key": "CIPL@#1999"}
        const response = await apiServices.getAppOTP(payload);
        setOtpValue(response?.data?.response_obj?.verification_code)
    }


    const header = (
        <div className="table-header">
            <h6 className="p-mb-1">OTP List</h6>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button  icon="pi pi-refresh" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={()=>{getOTP()}} />
                </div>
            </span>
        </div>
    )

    return (
        userAuth &&
        <>  
            <div className="p-grid crud-demo">
                <div className="p-col-6">
                <div className="p-card p-p-4">
                        <h4>OTP Manager (IOT) </h4>
                        <DataTable value={otpList}
                            dataKey="id" className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records" header={header} emptyMessage="No data found.">
                            <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                            <Column field="cno" header="Mobile Number" body={CommonBodyForTable}></Column>
                            <Column field="otp" header="OTP" body={CommonBodyForTable}></Column>
                        </DataTable>
                    </div>
                </div>

                <div className="p-col-6">
                    <div className="p-card p-p-4">
                    <form onSubmit={getAppOtp} id="my-form" className="p-fluid">
                    <h4>OTP Manager (APPLICATION) </h4>
                    <hr></hr>
                        <div className="p-field">
                            <label htmlFor="cno">Contact Number</label>
                            <InputText id="cno" name='contact' placeholder='Contact Number' minLength={10} maxLength={10} defaultValue={null} onChange={changeNumber} autoComplete="off" required />
                        </div>
                    </form>
                        <h6>OTP is : {otpValue}</h6>
                    </div>
                </div>
            </div>
        </>
    )
}