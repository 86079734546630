import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { TabView } from 'primereact/tabview';
import { TabPanel } from 'primereact/tabview';
import { commonCloseDialogFooter,commonConfirmDialogFooter, commonDialogFooter } from '../Common/CommonComponents';
// RazorPay
import useRazorpay from "react-razorpay";
// Common
import { CommonBodyForTable } from '../Common/CommonComponents';
import { copyData } from '../Common/CommonFunctions';
import { successToast, failureToast } from '../Common/CommonFunctions';
import { permissionContext } from '../../AppWrapper';
// Services
import ApiServices from '../../service/api/ApiServices';
const apiServices = new ApiServices();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ====================================================================================================
// ====================================================================================================

export const Products = (props) => {

    const Razorpay = useRazorpay();

    const rolePermissions = useContext(permissionContext);

    const isLive = true;
    const RPAY_KEY = isLive ? "rzp_live_33WIg85yHAfbEv" : "rzp_test_J1drhhEipPb1Rl"
    const RPAY_SECRET = isLive ? "H4QUfaVxXmL2mHKj1jCVpuZX" : "c7FvosSLkmvjE7anmzS8fNiu"

    const [loading, setLoading] = useState(true)
    const [comboBeverages, setComboBeverages] = useState(null)
    const [packsBeverages, setPacksBeverages] = useState(null)
    const [totalCups, setTotalCups] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [packsTotalCups, setPacksTotalCups] = useState(0);
    const [packsTotalPrice, setPacksTotalPrice] = useState(0);
    const [specialTotalCups,setSpecialTotalCups] = useState(0);
    const [specialTotalPrice,setSpecialTotalPrice] = useState(0);
    const [specialPacketCount, setSpecialPacketCount] = useState(0);
    const [packetCount, setPacketCount] = useState(0);
    const [reviewOrderFlag, setReviewOrderFlag] = useState(true);
    // const [paymentType, setPaymentType] = useState('ONLINE');
    const [paymentType, setPaymentType] = useState(null);
    const [orderSummary, setOrderSummary] = useState(null);
    const [mainOrderSummary, setMainOrderSummary] = useState(null);
    const [orderPlaced, setOrderPlaced] = useState(false);
    const [couponCodeDialog, setCouponCodeDialog] = useState(false);
    const [allCouponsList, setAllCouponsList] = useState([]);
    const [couponLoading, setCouponLoading] = useState(true);
    const [typedCouponCode, setTypedCouponCode] = useState('');
    const [isCouponApplied, setIsCouponApplied] = useState(null);
    const [proceedClicked, setProceedClicked] = useState(false)
    const [inCartProducts, setInCartProducts] = useState([])
    const [successDialog, setSuccessDialog] = useState(false)
    const [placedOrderData, setPlacedOrderData] = useState(null)
    const [confirmPlaceDialog, setConfirmPlaceDialog] = useState(false);
    const [specialiteas, setSpecialiteas] = useState([])

    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [packetCombo, setPacketCombo] = useState([])
    const [visible, setVisible] = useState(false);
    const [specialDialog, setSpecialDialog] = useState(false)
    const [specialiteasComboData, setSpecialiteasComboData] = useState(null)
    const [inCartSpecialiteas, setInCartSpecialiteas] = useState([])
    const [allComboBeverages, setAllComboBeverages] = useState(null)
    const [layout, setLayout] = useState('grid');
    const [sortKey, setSortKey] = useState(null);
    const [cartItems, setCartItems] = useState("0");
    const [value18, setValue18] = useState(0);
    const buttonEl = useRef(null);

    const toast = useRef(null);
    // 339.20
    useEffect(() => {
        // getData(props.productPayload)
        getInitiate(props.productPayload)
    }, [props.productPayload]);
    
    const getInitiate = (data) =>{
        if(props.selectedBrand == 'MERI_TAPRI'){
            getKettleData(data)
            AddSpecial()
        }else{
            getData(data);
        }
    }

    const orderTemplate = {
        "combos": '',
        "shipping_address": {
            "address": props?.selectedFacility?.address?.address,
            "country": props?.selectedFacility?.address?.country?.name,
            "state": props?.selectedFacility?.address?.state?.name,
            "city": props?.selectedFacility?.address?.city?.name,
            "pincode": props?.selectedFacility?.address?.pincode
        },
        "billing_address": {
            "company_name": props?.selectedFacility?.billing_details?.cmpname,
            "gst": props?.selectedFacility?.billing_details?.gstnumber,
            "address": props?.selectedFacility?.billing_details?.addr,
            "country": props?.selectedFacility?.billing_details?.country?.name,
            "state": props?.selectedFacility?.billing_details?.state?.name,
            "city": props?.selectedFacility?.billing_details?.city?.name,
            "pincode": props?.selectedFacility?.billing_details?.pincode
        },
        "payment_mode": paymentType,
        "IOT_FacilityId": props?.selectedFacility?.facid,
        "IOT_UserId": props?.selectedFacility?.userid,
        "coupon": typedCouponCode,
        "price_per_cup": props.selectedAccount?.cup_cost,
        "customer_name": props.selectedAccount?.name,
        "customer_contact": props.selectedAccount?.cno,
        "customer_email": props.selectedAccount?.email,
        "company_name": props.selectedAccount?.compnyName || props.selectedAccount?.billing_details?.company,
        "offset": props.selectedAccount?.beverages_offset,
        "mode": props.selectedAccount?.payemntTerms?.mode,
        "extPeriod": props.selectedAccount?.payemntTerms?.extPeriod ? props.selectedAccount?.payemntTerms?.extPeriod : 0,
        "minAmtPercent": props.selectedAccount?.payemntTerms?.minAmtPercent,
        "payment_terms": {...props.selectedAccount?.payemntTerms,extPeriod : props.selectedAccount?.payemntTerms?.extPeriod ? props.selectedAccount?.payemntTerms?.extPeriod : 0},
        "billing_pattern": props.selectedBrand === 'MERI_TAPRI' ? 'Cupwise' : props.selectedAccount?.billing_pattern,
        "packet_cost": props.selectedAccount?.packet_cost,
        "price_per_cup": props.selectedAccount?.cup_cost,
        "speciality_rate": props.selectedAccount?.speciality_cost,
        "kam_name": props?.selectedFacility?.kam_name,
        "kam_userid": props?.selectedFacility?.kam_userid,
        "kam_zohoId": props?.selectedFacility?.kam_zohoId
    }

    const sortOptions = [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
    ];

    const setDefaultOrderValues = () => {
        setOrderPlaced(false)
        setTotalCups(0)
        setTotalPrice(0)
        setReviewOrderFlag(true)
        setIsCouponApplied(null)
        setMainOrderSummary(null)
        setOrderSummary(null)
        setTypedCouponCode('')
        setProceedClicked(false)
        // getData(props.productPayload)
        getInitiate(props.productPayload)
        
    }

    const getKettleData = async(payload) =>{
        console.log(payload);
        const data = await apiServices.getKettleRefill({cup_price : payload?.price_per_cup || '4.24'})
        // payload?.price_per_cup
        const mappedArr = data?.data?.response_obj?.map(v => ({ ...v, cartQuantity: 0 }))
        console.log(mappedArr);

        // const newArrValue = mappedArr?.map((x) => { return {...x, 
        //     offset_price: props.selectedAccount?.billing_pattern == "PacketWise" ? props.selectedAccount?.packet_cost : x?.price ,
        //     cupWiseCost: props.selectedAccount?.billing_pattern == "PacketWise" ? (Number(props.selectedAccount?.packet_cost) / x.cups_count)?.toFixed(2) : x?.price_per_cup
        // }})

        const newArrValue = mappedArr?.map((x) => { return {...x, 
            offset_price: x?.price ,
            cupWiseCost: x?.price_per_cup
        }})

        console.log(newArrValue);
        setAllComboBeverages(newArrValue)
        setComboBeverages(newArrValue)
        setLoading(false)
    }

    const getData = async(payload) => {
        console.log(payload);
        const data = await apiServices.getBeverageProducts(payload);
        const mappedArr = data?.data?.response_obj.combos?.map(v => ({ ...v, cartQuantity: 0 }))
        console.log(mappedArr);
        
        const newArrValue = mappedArr?.map((x) => { return {...x, 
            offset_price: props.selectedAccount?.billing_pattern == "PacketWise" ? props.selectedAccount?.packet_cost : x?.offset_price ,
            cupWiseCost: props.selectedAccount?.billing_pattern == "PacketWise" ? (Number(props.selectedAccount?.packet_cost) / x.cups_count)?.toFixed(2) : props.selectedAccount?.cup_cost
        }})

        setAllComboBeverages(newArrValue)
        setComboBeverages(newArrValue)
        const packsArr = data?.data?.response_obj.packs?.map(v => ({ ...v, cartQuantity: 0 }))

        const packArrValue = packsArr?.map((x) => {
            const reducedValue = x?.combos?.reduce(function (accumulator, currentValue) { return accumulator + currentValue?.qty }, 0);
            // console.log(reducedValue);
            // 
            
            return {...x,
                offset_price: props.selectedAccount?.billing_pattern == "PacketWise" ? (Number(props.selectedAccount?.packet_cost) * reducedValue) : x?.offset_price,
                cupWiseCost: props.selectedAccount?.billing_pattern == "PacketWise" ? ((Number(props.selectedAccount?.packet_cost) * reducedValue) / Number(x.cups_count))?.toFixed(2) : props.selectedAccount?.cup_cost
            }
        })

        setPacksBeverages(packArrValue)

        const specialTeas = data?.data?.response_obj?.speciality_combos?.map((x)=>{ 
            return {
                ...x, 
                cartQuantity : 0, 
                cupWiseCost : (Number(x?.offset_price) / Number(x?.cups_count)).toFixed(2)
            }
        })

        setSpecialiteas(specialTeas)
        setLoading(false)
    }


    const changePackCartValue = (data) => {

        console.log(data);

        const newD = data?.combos?.map((x) => ({ id: x.combo_id, qty: x.qty }));
        const packets = newD?.reduce(function(prev, cur){ return prev + cur?.qty}, 0);
        setPacketCount(packetCount + Number(packets));

        const arr1 = [];
        comboBeverages.forEach((x) => {
            newD.forEach((y) => {
                if(x?._id === y?.id){ arr1.push({ ...x, cartQuantity: y.qty })}
            })
        })

        const arr2 = [];
        comboBeverages.forEach((x) => {
            const again = newD.findIndex((obj) => x._id === obj.id)
            if (again === -1) { arr2.push({ ...x }) }
        })

        setInCartProducts([...inCartProducts, ...arr1])
        const comb = [...inCartProducts, ...arr1];        
        const hey = [];
        comb.forEach((x) => {
            const val = hey.findIndex((obj) => x._id === obj._id)
            if (val === -1) {
                hey.push(x);
            } else {
                const getId = hey[val]._id;
                const result = comb.filter((x) => { if (x._id == getId){ return x }}).reduce((acc, obj)=>{ return acc + obj.cartQuantity}, 0);
                hey.push({ ...x, cartQuantity: result });
            }
        })

        setComboBeverages([...arr1, ...arr2])
        setPacketCombo(newD)

        const arr = [];
        packsBeverages.forEach((x) => { (x._id == data._id) ? arr.push({ ...x, cartQuantity: 1 }) :  arr.push({ ...x })})
        setPacksBeverages(arr)
        packsCalculations(arr)
        successToast(toast, 'Pack added in cart')
    }

    const removeFromCart = (data) => {

        console.log(data);
        failureToast(toast,'Pack removed from Cart','Removed')

        const arr = [];
        packsBeverages.forEach((x) => { (x._id == data._id) ? arr.push({ ...x, cartQuantity: 0 }) : arr.push({ ...x })})

        const newD = data?.combos?.map((x) => ({ id: x.combo_id, qty: x.qty }));
        const packets = newD?.reduce(function(prev, cur){ return prev + cur?.qty}, 0);
        setPacketCount(packetCount - Number(packets));

        const unSelectedArr = [];
        inCartProducts.forEach((x) => {
            const again = data?.combos?.findIndex((obj) => obj?.combo_id === x?._id);
            if (again !== -1) { unSelectedArr.push({ ...x, cartQuantity: 0 }) }
        })

        console.log(comboBeverages);
        const bev = [];
        comboBeverages.forEach((x) => {
            const again = data?.combos?.findIndex((obj) => (obj?.combo_id === x?._id));
            (again !== -1) ? bev.push({ ...x, cartQuantity: 0 }) : bev.push({ ...x });
        })

        setComboBeverages(bev)
        const inCartData = bev.map((x) => { return x.cartQuantity > 0 })
        setInCartProducts(inCartData)
        setPacksBeverages(arr)
        packsCalculations(arr)
    }

    const packsCalculations = (arr) => {
        const totalC = arr.map((x) => { return (x.cartQuantity === 1) ? x.cups_count : 0 }).reduce(function (previousValue, currentValue) { return previousValue + currentValue }, 0);
        const totalSum = arr.map((x) => { return (x.cartQuantity === 1) ? x.offset_price : 0 }).reduce(function (previousValue, currentValue) { return previousValue + currentValue }, 0);
        setPacksTotalCups(totalC)
        setPacksTotalPrice(totalSum)
    }


    const changeCartValue = (e, data) => {

        const QuantityValue = e.value > 999 ? 0 : e.value;

        if(data?.brand === "SPECIALITEAS"){
            const specArr = [];
            specialiteas.forEach((x) => { (x._id == data._id) ? specArr.push({ ...x, cartQuantity: QuantityValue }) : specArr.push({ ...x }) })
            // console.log(specArr);

            setInCartSpecialiteas(specArr)
            setSpecialiteas(specArr)
            const packets = specArr?.reduce(function(prev, cur){ return prev + cur?.cartQuantity}, 0);
            const conclude = specArr.filter((x) => { if (x.cartQuantity > 0) { return x; } })
            const totalC = conclude.map((x) => { return x.cartQuantity * x.cups_count }).reduce(function (previousValue, currentValue){ return previousValue + currentValue }, 0);
            const sum = conclude.map((x) => { return x.cartQuantity * x.offset_price }).reduce(function (previousValue, currentValue){ return previousValue + currentValue }, 0);
            console.log(packets);
            setSpecialTotalCups(Number(totalC))
            setSpecialTotalPrice(Number(sum))
            setSpecialPacketCount(Number(packets))

        }else{

            const arr = [];
            comboBeverages.forEach((x) => { (x._id === data._id) ? arr.push({ ...x, cartQuantity: QuantityValue }) : arr.push({ ...x }) })
            // setInCartProducts(arr)
            setComboBeverages(arr)

            const packets = arr?.reduce(function(prev, cur){ return prev + cur?.cartQuantity}, 0);
            const conclude = arr.filter((x) => { if (x.cartQuantity > 0) { return x; } })
            const totalC = conclude.map((x) => { return x.cartQuantity * x.cups_count }).reduce(function (previousValue, currentValue){ return previousValue + currentValue }, 0);
            const sum = conclude.map((x) => { return x.cartQuantity * x.offset_price }).reduce(function (previousValue, currentValue){ return previousValue + currentValue }, 0);
            // console.log(packets);
            setTotalCups(Number(totalC))
            setTotalPrice(Number(sum))
            setPacketCount(Number(packets))
        }

        !reviewOrderFlag && changeCart(e.value,data)
    }

    const changeCart = (value, data) =>{

        const QuantityValue = value > 999 ? 0 : value;
        const cart = [];
        inCartProducts.forEach((x) => { 
            if(x._id === data._id){
                cart.push({ ...x, cartQuantity: QuantityValue })
            }else{
                cart.push({ ...x }) 
            }
        })
        
        const filteredCartProducts = cart.filter((x) => { if (x.cartQuantity > 0) { return x; } })
        setInCartProducts(filteredCartProducts);
        filteredCartProducts?.length === 0 && setReviewOrderFlag(true);
    }

    const reviewOrder = () => {
        const added = comboBeverages?.filter((x) => { if (x.cartQuantity > 0) { return x; } }) || [];
        const addedArr = specialiteas?.filter((x) => { if (x.cartQuantity > 0) { return x; } }) || [];
        const allProducts = [...added,...addedArr];
        setInCartProducts(allProducts)
        setReviewOrderFlag(false)
        window.scrollTo(0, 0)
    }

    const removeCouponCode = () => {
        setTypedCouponCode('')
        setIsCouponApplied(null)
        failureToast(toast,'Coupon code removed')
        setOrderSummary(mainOrderSummary)
    }

    const openCouponList = () => {
        setCouponCodeDialog(true)
        getCouponCodes()
    }

    const getCouponCodes = async () => {
        const response = await apiServices.getCouponCodes({ "page": 1 });
        console.log(response.data.response_obj);
        setAllCouponsList(response.data.response_obj)
        setCouponLoading(false)
    }

    const hideCouponDialog = () => { setCouponCodeDialog(false) }
    const changeCouponValue = (e) => { setTypedCouponCode(e.target.value) }

    const applyCouponCode = async (e) => {
        e.preventDefault();
        // const comboData = comboBeverages.map((x) => { return { id: x.id, qty: x.cartQuantity } })
        const comboData = inCartProducts.map((x) => { return { id: x.id, qty: x.cartQuantity } })
        typedCouponCode && applyCouponFn({ ...orderTemplate, "combos": comboData })
    }

    const applyCouponFn = async (data) => {
        console.log(data);
        const response = await apiServices.applyCouponCode(data);
        if (response?.data?.response_code == 1) {
            successToast(toast,response?.data?.response_message)
            
            setIsCouponApplied(data?.coupon)
            const calculatedGst = (Number(response?.data?.response_obj?.subtotal) - Number(response?.data?.response_obj?.discount)) * 18 / 100;
            const finalValue = Number(response?.data?.response_obj?.total) + calculatedGst
            setOrderSummary({ ...orderSummary, discount: response?.data?.response_obj?.discount, tax: calculatedGst, subtotal: response?.data?.response_obj?.total, total: finalValue })
        } else {
            failureToast(toast,response?.data?.response_message)
        }
    }

    const placeOrder = async () => {
        window.scrollTo(0, 0)
        setOrderPlaced(true)

        // if(props.selectedBrand == 'MERI_TAPRI'){
        //     props.selectedAccount?.payemntTerms?.mode == "POSTPAID" ? setPaymentType('PAY_LATER') : setPaymentType('LINK')
        // }else{
        //     props.selectedAccount?.payemntTerms?.mode == "POSTPAID" ? setPaymentType('PAY_LATER') : setPaymentType('ONLINE')
        // }

        const isPostpaid = props.selectedAccount?.payemntTerms?.mode === 'POSTPAID';
        const isMeriTapriBrand = props.selectedBrand === 'MERI_TAPRI';
        const paymentType = isPostpaid ? 'PAY_LATER' : (isMeriTapriBrand ? 'LINK' : 'ONLINE');
        setPaymentType(paymentType);

        const data = comboBeverages?.filter((x) => { if (x.cartQuantity > 0) { return x; } }).map((x) => { return { id: x.id, qty: x.cartQuantity } }) || [];
        const specialdata = specialiteas?.filter((x) => { if (x.cartQuantity > 0) { return x; } }).map((x) => { return { id: x.id, qty: x.cartQuantity } }) || [];
        const comboArr = [...data,...specialdata]
        console.log(comboArr);

        const finalData = {
            "combos": comboArr,
            "billing_pattern": props.selectedBrand === 'MERI_TAPRI' ? 'Cupwise' : props.selectedAccount?.billing_pattern,
            "packet_cost": props.selectedAccount?.packet_cost,
            "price_per_cup": props.selectedAccount?.cup_cost,
            "mode": props.selectedAccount?.payemntTerms?.mode,
            "extPeriod": props.selectedAccount?.payemntTerms?.extPeriod || 0,
            "minAmtPercent": props.selectedAccount?.payemntTerms?.minAmtPercent,
            "payment_terms": {...props.selectedAccount?.payemntTerms, extPeriod : props.selectedAccount?.payemntTerms?.extPeriod || 0},
            "coupon": typedCouponCode,
            "offset": props.selectedAccount?.beverages_offset,
            "speciality_rate": props.selectedAccount?.speciality_cost,
        };
        console.log(finalData);
        const response = await apiServices.cartRefillData(finalData);
        setMainOrderSummary(response?.data?.response_obj?.amount)
        console.log(response?.data?.response_obj?.amount)
        setOrderSummary(response?.data?.response_obj?.amount)
    }

    const proceedWithOrder = async () => {
        
        setConfirmPlaceDialog(false)
        setProceedClicked(true)
        const comboData = comboBeverages.filter((x) => { if (x.cartQuantity > 0) { return x; } }).map((x) => { return { id: x.id, qty: x.cartQuantity } });
        const specialdata = specialiteas?.filter((x) => { if (x.cartQuantity > 0) { return x; } }).map((x) => { return { id: x.id, qty: x.cartQuantity } });
        const comboArr = [...comboData,...specialdata]
        const orderData = {
            ...orderTemplate, 
            "combos": comboArr,
            "machine_type" : props.selectedBrand === 'MERI_TAPRI' ? 'KETTLE' : props.selectedBrand
        }
        console.log(orderData);
        const response = await apiServices.createRefillOrder(orderData);
        console.log(response?.data);
        if (response?.data?.response_code == 1) {
            setPlacedOrderData(response?.data?.response_obj)
            paymentType == 'ONLINE' ? handlePayment(response?.data?.response_obj) : setSuccessDialog(true);
        } else if (response?.data?.response_code == 0) {
            failureToast(toast, 'Failed to place order')
            setProceedClicked(false)
        } else {
            failureToast(toast, 'Something went wrong')
            setProceedClicked(false)
        }
    }

    const handlePayment = async (data) => {

        const options = {
            key: RPAY_KEY,
            amount: mainOrderSummary?.payable * 100,
            currency: "INR",
            name: "Cherise",
            description: "Cherise Refill",
            order_id: data?.razorpay_order_id,
            handler: function (response) {
                alert(response.razorpay_payment_id);
                alert(response.razorpay_order_id);
                alert(response.razorpay_signature);
            },
            prefill: { name: initialValue.name, email: initialValue.email, contact: initialValue.cno },
            // notes: { address: "Razorpay Corporate Office" },
            'notes.shopping_order_id': data?.shoppingOrderId,
            theme: { color: "#3399cc" },
            "modal": { "ondismiss": function(){ setSuccessDialog(true) } }
        };

        const rzp1 = new Razorpay(options);

        console.log(rzp1);

        rzp1.on("payment.failed", function (response) { transactionFailed(); })
        rzp1.on("payment.error", function (response) { transactionFailed(); })
        rzp1.on("payment.external_wallet", function (response) { transactionFailed(); })
        rzp1.on("payment.success", function (response) {
            updateTransaction(response)
            setDefaultOrderValues()
        });

        rzp1.open();
    };

    const transactionFailed = () => { toast.current.show({ severity: 'error', summary: 'Failed', detail: `Payment Failed`, life: 3000 })}

    const updateTransaction = async (data) => {
        const params = { "razorpay_payment_id": data?.paymentId, "razorpay_order_id": data?.orderId, "razorpay_signature": RPAY_SECRET };
        const response = await apiServices.transactionSucceed(params);
        if (response.responseCode == 1) {
            successToast(toast, 'Payment done')
            setReviewOrderFlag(true)
        } else {
            failureToast(toast, 'Payment Failed')
        }
    }

    const applyCode = (data) => {
        setTypedCouponCode(data.coupon)
        const comboData = inCartProducts.map((x) => { return { id: x.id, qty: x.cartQuantity } })
        const codeData = { ...orderTemplate, "combos": comboData, coupon: data.coupon }
        applyCouponFn(codeData)
        setCouponCodeDialog(false)
    }

    const backFromReviewOrder = () => {
        // setReviewOrderFlag(false)
        setReviewOrderFlag(true)
        // getData(props.productPayload)
        // setTotalCups(0)
        // setTotalPrice(null)
        // setComboBeverages({...allComboBeverages,...comboBeverages})
        // setComboBeverages({...comboBeverages,...allComboBeverages})
    }

    const backFromPlaceOrder = () => {
        setOrderPlaced(false)
        setOrderSummary(mainOrderSummary)
        setPaymentType('LINK')
        setTypedCouponCode(null)
        setIsCouponApplied(false)
    }

    const onclickThis = (e) => {
        const copyText = placedOrderData?.razorpay_url;
        copyData(copyText)
        toast.current.show({ severity: 'info', summary: 'Copied', detail: 'Link Copied to Clipboad', life: 1000 });
    }

    const hideSuccessDialog = () => {
        setSuccessDialog(false)
        // getData(props.productPayload)
        getInitiate(props.productPayload)
        setPlacedOrderData(null)
        setReviewOrderFlag(true)
        setOrderPlaced(false)
        setTotalCups(0)
        setTotalPrice(0)
        setPacksTotalCups(0)
        setPacksTotalPrice(0)
        props.setConfirmSelection(false)
    }

    const hideSpecialiteaDialog = () =>{
        setSpecialDialog(false)
        setSpecialiteas(null)
        setSpecialiteasComboData(null)
    }

    const AddSpecial = async() =>{
        // setSpecialDialog(true)
        const payload = { 
            "brand" : "SPECIALITEAS", "machine_type" : "OTHER", 
            "price": props.selectedAccount?.speciality_cost || props.selectedAccount?.cup_cost
        }

        const response = await apiServices.getSpecialiteas(payload);
        const newArr = response?.data?.response_obj?.map((x)=>{ 
            return {
                ...x, 
                cartQuantity : 0, 
                cupWiseCost : (Number(x?.offset_price)/Number(x?.cups_count)).toFixed(2)
        }})

        console.log(newArr);
        setSpecialiteas(newArr)
        // x?.offset_price/x?.cups_count
        // cupWiseCost
    }

    const addSpecialiteas = () =>{
        console.log(specialiteas);
        const filteredArr = specialiteas?.filter((x)=>{ return x.cartQuantity > 0;});
        const comboData = filteredArr.map((x) => { return { id: x.id, qty: x.cartQuantity } })
        console.log(comboData);
        setSpecialiteasComboData(comboData)
    }

    const changeSpecialiteasCartValue = (e, data) => {
        const QuantityValue = e.value > 999 ? 0 : e.value;
        const arr = [];
        specialiteas.forEach((x) => { (x._id == data._id) ? arr.push({ ...x, cartQuantity: QuantityValue }) : arr.push({ ...x }) })
        setSpecialiteas(arr)
    }

    const codeBodyTemplate = (rowData, props) => (<Tag className="mr-2" severity="success" value={rowData[props.field]} rounded></Tag>)

    const actionBodyTemplate = (rowData) => (
        <>
            <span className="p-column-title">Action</span>
            <div className="actions">
                {
                    rowData?.coupon === isCouponApplied ?
                    <i className="pi pi-check"></i>
                    :
                    <Button style={{ width: '60px' }} label='APPLY' className="p-button-primary p-button-sm" onClick={() => { applyCode(rowData) }}/>
                }
            </div>
        </>
    )

    const dataviewGridItem = (data) => {
        return (
            <div className="p-col-12 p-md-3">
                <div className="product-grid-item card">
                    <div className="product-grid-item-top">
                        <div>
                            <i className="pi pi-tag product-category-icon"></i>
                            <span className="product-category">{data?.brand}</span>
                        </div>
                    </div>
                    <div className="product-grid-item-content p-mb-2">
                        <img src={data.image} alt={data.title} style={{ boxShadow: 'none' }} />
                        {/* <div className="product-name">{data?.title} {`(${data?.cups_count} Cups)`} </div> */}
                        <div className="product-name">{data?.title} </div>
                        <div className="product-name">{`(${data?.cups_count} Cups)`} </div>
                    </div>
                    <div className='product-grid-item-bottom'>
                        <div className='p-col-7'>
                            <span className="product-price">₹{Number(data.offset_price).toFixed(2)} <small> {data?.cupWiseCost ?  `( ₹${data?.cupWiseCost}/Cup )` : ''}</small> </span>
                        </div>
                        <div className='p-col-5'>
                            <div className="grid p-fluid">
                                <div className="col-12">
                                    <InputNumber inputStyle={{ textAlign: 'center' }} inputId="horizontal" value={data.cartQuantity} onChange={(e) => { changeCartValue(e, data) }} showButtons buttonLayout="horizontal" min={0} max={999}
                                        decrementButtonClassName="p-button-primary" incrementButtonClassName="p-button-primary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const dataviewGridItemForCart = (data) => {

        if(data?.cartQuantity > 0){

            return (
                <div className="p-col-12 p-md-3" key={data?._id}>
                    <div className="product-grid-item card">
                        <div className="product-grid-item-top">
                            <div>
                                <i className="pi pi-tag product-category-icon"></i>
                                <span className="product-category">{data?.brand}</span>
                            </div>
                        </div>
                        <div className="product-grid-item-content">
                            <img src={data.image} alt={data.title} style={{ boxShadow: 'none' }} />
                            <div className="product-name">{data.title} {`(${data?.cups_count} Cups)`} </div>
                        </div>
                        <div className='product-grid-item-bottom'>
                            <div className='p-col-7'>
                                <span className="product-price">₹{Number(data.offset_price).toFixed(2)} <small> {data?.cupWiseCost ? `( ₹${data?.cupWiseCost}/Cup )` : ''}</small></span>
                            </div>
                            <div className='p-col-5'>
                                <div className="grid p-fluid">
                                    <div className="col-12">
                                        <InputNumber inputStyle={{ textAlign: 'center' }} inputId="horizontal" value={data.cartQuantity} onChange={(e) => { changeCartValue(e, data) }} showButtons buttonLayout="horizontal" min={0} max={999}
                                            decrementButtonClassName="p-button-primary" incrementButtonClassName="p-button-primary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }else{
            return null;
        }
    };

    const dataViewForPacksGridItem = (data) => {
        return (
            <div className="p-col-12 p-md-4">
                <div className="product-grid-item card">
                    <div className="product-grid-item-top">
                        <div>
                            <i className="pi pi-tag product-category-icon"></i>
                            <span className="product-category">{data?.brand}</span>
                        </div>
                    </div>
                    <div className="product-grid-item-content">
                        <img src={data.image} alt={data.title} />
                        <div className="product-name">{data.title} {`(${data?.cups_count} Cups)`} </div>
                        <div className="product-description">{data.description}</div>
                    </div>
                    <div className='product-grid-item-bottom'>
                        <div className='p-col-10'>
                            <span className="product-price">₹{data.offset_price} <small>{data?.cupWiseCost ? `( ₹${data?.cupWiseCost}/Cup )` : ''} </small> </span>
                        </div>
                        <div className='p-col-2'>
                            <div className="grid p-fluid">
                                <div className="col-12">
                                    { data?.cartQuantity == 0 ? <Button icon="pi pi-shopping-cart" onClick={() => { changePackCartValue(data) }}></Button> : <Button className="p-button-danger" icon="pi pi-trash" onClick={() => { removeFromCart(data) }}></Button> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const productItemTemplate = (data, layout) => { return dataviewGridItem(data) };
    const packItemTemplate = (data, layout) => { return dataViewForPacksGridItem(data) };
    const productItemTemplateForCart = (data, layout) => { return dataviewGridItemForCart(data) };

    const hideConfirmDialog = () => { setConfirmPlaceDialog(false) }
    const successDialogFooter = commonConfirmDialogFooter('OK', hideSuccessDialog)
    const couponDialogFooter = commonCloseDialogFooter('Cancel', hideCouponDialog)
    const confirmDialogFooter = commonDialogFooter('No', hideConfirmDialog, 'Yes', proceedWithOrder)

    const leftToolbarTemplate = () => {
        return (
            <>  
                { (!orderPlaced && reviewOrderFlag) && <Button className='p-custom-btn' label="Go Back" icon="pi pi-backward" iconPos="left" onClick={() => { props.setConfirmSelection(false) }}/> }
                { (!orderPlaced && !reviewOrderFlag) && <Button className='p-custom-btn' label="Go Back" icon="pi pi-backward" iconPos="left" onClick={backFromReviewOrder}/> }
                { orderPlaced &&  <Button className='p-custom-btn' label="Go Back" icon="pi pi-backward" iconPos="left" onClick={backFromPlaceOrder}/> }
            </>
        )
    }

    const rightToolbarTemplate = () =>(
        <>
            {
                (reviewOrderFlag && comboBeverages?.reduce(function(prev, cur) { return prev + cur?.cartQuantity}, 0) > 4) &&
                <Button className='p-custom-btn' label="Review Order" icon="pi pi-forward" iconPos="right" onClick={reviewOrder} />
            }

            {
                (!reviewOrderFlag && (totalCups || packsTotalCups) && (inCartProducts.reduce(function(prev, cur) { return prev + cur?.cartQuantity}, 0) > 4)) && 
                <Button className='p-custom-btn' label="Checkout & Pay" icon="pi pi-forward" iconPos="right" onClick={placeOrder} />
            }
            
        </>
    )
    
    return (
        <>
            <Toast ref={toast} position='center' />
            <div className="p-grid list-demo">

                {
                    !orderPlaced &&
                    <div className="p-col-12">

                        {/* {!reviewOrderFlag && <Button icon="pi pi-arrow-left" label='Go Back2' className="p-button-rounded p-button-outlined p-button-danger p-mb-2" aria-label="Back" onClick={backFromReviewOrder} />} */}
                        {/* {reviewOrderFlag && <Button icon="pi pi-arrow-left" label='Go Back1' className="p-button-rounded p-button-outlined p-button-danger p-mb-2" aria-label="Back" onClick={() => { props.setConfirmSelection(false) }} />} */}

                        <div className='card'>
                            <Toolbar className="p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                        </div>

                        {
                            reviewOrderFlag ?
                                <>
                                    {
                                        packsBeverages &&
                                        <>
                                            <div className="card">
                                                <h5>Material Packs</h5>
                                                <DataView value={packsBeverages} layout={layout} paginator rows={6} sortOrder={sortOrder} sortField={sortField} itemTemplate={packItemTemplate} loading={loading}></DataView>
                                            </div>
                                        </>
                                    }
                                    
                                    <div className="card">
                                        <TabView>
                                            <TabPanel header="MATERIAL PRODUCTS">
                                                    <DataView value={comboBeverages} layout={layout} paginator rows={8} sortOrder={sortOrder} sortField={sortField} itemTemplate={productItemTemplate} loading={loading}></DataView>
                                            </TabPanel>
                                            <TabPanel header="SPECIALITEAS">
                                                    <DataView value={specialiteas} layout={layout} paginator rows={8} sortOrder={sortOrder} sortField={sortField} itemTemplate={productItemTemplate} loading={loading}></DataView>
                                            </TabPanel>
                                        </TabView>
                                    </div>
                                </> :
                                <>
                                    <div className="card">
                                        <DataView header={`Cart (${inCartProducts?.length} Products)`} value={inCartProducts} layout={layout} paginator rows={8} sortOrder={sortOrder} sortField={sortField} itemTemplate={productItemTemplateForCart} loading={loading}></DataView>
                                        {/* <TabView activeIndex={1}>
                                            <TabPanel header={`Products in Cart (${inCartProducts?.length})`}>
                                                <DataView value={inCartProducts} layout={layout} paginator rows={8} sortOrder={sortOrder} sortField={sortField} itemTemplate={productItemTemplateForCart} loading={loading}></DataView>
                                            </TabPanel>
                                            <TabPanel header={`Specialiteas (${inCartSpecialiteas?.length})`}>
                                                <DataView value={inCartSpecialiteas} layout={layout} paginator rows={8} sortOrder={sortOrder} sortField={sortField} itemTemplate={productItemTemplateForCart} loading={loading}></DataView>
                                            </TabPanel>
                                        </TabView> */}
                                    </div>
                                </>
                        }

                        {
                            ((packsTotalCups != 0 || totalCups != 0 || specialPacketCount != 0) && reviewOrderFlag) &&
                            <div className='card'>
                                <h6>Beverages</h6>
                                <h6> Packets : {packetCount + specialPacketCount}  | Cups : {totalCups + packsTotalCups + specialTotalCups}  | Order Total : ₹ {Number(packsTotalPrice + totalPrice + specialTotalPrice).toFixed(2)} </h6>
                                {
                                    ((packetCount + specialPacketCount) >  (rolePermissions?.refillOrderCheck ? 0 : 4)) &&
                                    // 
                                    <Button className='p-custom-btn p-button-lg p-col-12 p-mt-2' icon="pi pi-arrow-right" iconPos="right" label='Review Order' onClick={reviewOrder}></Button>
                                }
                            </div>
                        }

                        {
                            ((packsTotalCups != 0 || totalCups != 0 || specialPacketCount!=0) && !reviewOrderFlag) &&
                            <div className='card'>
                                <h6>Beverages</h6>
                                <h6>Packets : {packetCount + specialPacketCount}  | Cups : {totalCups + packsTotalCups + specialTotalCups} | Order Total : ₹ {Number(packsTotalPrice + totalPrice + specialTotalPrice).toFixed(2)} </h6>
                                {
                                    (totalCups || packsTotalCups || specialPacketCount) && ((packetCount + specialPacketCount > (rolePermissions?.refillOrderCheck ? 0 : 4))) && 
                                    <Button className='p-custom-btn p-button-lg p-col-12 p-mt-2' icon="pi pi-arrow-right" iconPos="right" label='Checkout & Pay' onClick={placeOrder}></Button>
                                }
                            </div>
                        }
                    </div>
                }

                {
                    orderPlaced &&
                    <>
                        <div className='p-col-12'>
                            <div className='card'>
                                <Toolbar className="p-toolbar" left={leftToolbarTemplate}></Toolbar>
                            </div>

                            <div className='card p-m-2'>
                                {/* <Button icon="pi pi-arrow-left" label='Go Back' className="p-button-rounded p-button-outlined p-button-danger p-mb-2" aria-label="Back" onClick={backFromPlaceOrder} /> */}
                                <div className='p-grid'>
                                    <div className='p-col-6'>
                                        <h5 className='p-mt-2'>Delivered to </h5>
                                        <h6 className='p-mt-2'>Account Name :  {props.selectedAccount?.compnyName || props.selectedAccount?.billing_details?.company}</h6>
                                        <h6 className='p-mt-2'>Contact Person Name : {props.selectedAccount?.name} {props.selectedAccount?.lastName}</h6>
                                        <h6 className='p-mt-2'>Contact : {props.selectedAccount?.cno}</h6>
                                        <h6 className='p-mt-2'>GST Number : {props.selectedAccount?.gstNumber || 'NA'}</h6>
                                        
                                        <hr></hr>
                                        <h6 className='p-mt-2'>Billing Address</h6>
                                        {/* <p>{initialValue?.addr?.addr}</p> */}
                                        <p>{props.selectedFacility?.billing_details?.addr}, {props.selectedFacility?.billing_details?.city?.name}, {props.selectedFacility?.billing_details?.state?.name}, {props.selectedFacility?.billing_details?.country?.name}, {props.selectedFacility?.billing_details?.pincode}.   </p>
                                        <hr></hr>
                                        <h6 className='p-mt-2'>Shipping Address</h6>
                                        <p>{props.selectedFacility?.address?.address}, {props.selectedFacility?.address?.city?.name}, {props.selectedFacility?.address?.state?.name}, {props.selectedFacility?.address?.country?.name}, {props.selectedFacility?.address?.pincode}.</p>
                                        <hr></hr>
                                        <h5 className='p-mt-2'>GOT A COUPON CODE? </h5>
                                        <form onSubmit={applyCouponCode} id="coupon-form" className="p-fluid">
                                            <div className='p-grid'>
                                                <div className='p-col-10'>
                                                    <div className="p-field">
                                                        <InputText id="couponcode" name='couponcode' placeholder='Enter your coupon code' value={typedCouponCode} onChange={(e) => { changeCouponValue(e) }} autoComplete="off" required disabled={isCouponApplied} />
                                                    </div>
                                                </div>
                                                <div className='p-col-2'>
                                                    {!isCouponApplied && <Button label="APPLY" type="submit" form='coupon-form' className="p-button-success p-mt-2" />}
                                                    {isCouponApplied && <Button label="REMOVE" className="p-button-danger p-mt-2" onClick={removeCouponCode} />}
                                                </div>
                                                <small className='p-ml-2' onClick={openCouponList} style={{ cursor: 'pointer' }}><i className="pi pi-paperclip" style={{ 'fontSize': '1em' }}></i> See available coupons</small>
                                            </div>
                                        </form>
                                    </div>
                                    <Divider layout="vertical"/>
                                    <div className='p-col-5'>
                                        <h5>Order Summary</h5>
                                        {/* <div className="card" > */}
                                        <div id="invoice-content">
                                            <div className="invoice">
                                                <div className="invoice-summary">
                                                    <table>
                                                        <tbody>
                                                            <tr><td>Beverage ({packsTotalCups + totalCups + specialTotalCups} Cups)</td> <td>₹{Number(packsTotalPrice + totalPrice + specialTotalPrice)?.toFixed(2)}</td> </tr>
                                                            { orderSummary?.discount != 0 && <tr> <td>Discount:</td> <td>{-orderSummary?.discount}</td> </tr> }
                                                            <tr> <td>Sub Total:</td> <td>₹{Number(orderSummary?.subtotal)?.toFixed(2)}</td></tr>
                                                            <tr> <td>Taxes (GST):</td> <td>₹{Number(orderSummary?.tax)?.toFixed(2)}</td></tr>
                                                            {/* <tr>
                                                                <td>Total Payable Amount:</td>
                                                                <td>{Number(orderSummary?.total)?.toFixed(2)}</td>
                                                            </tr> */}
                                                        </tbody>
                                                        <tbody>
                                                            <tr> <td><b>Total Payable :</b> </td> <td><b>₹{Number(orderSummary?.total)?.toFixed(2)}</b></td> </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}

                                        <h5>PAYMENT MODE</h5>

                                        {
                                            props.selectedAccount?.payemntTerms?.mode == "POSTPAID" ?
                                            <>
                                                <div className='card'>
                                                    <div className="field-radiobutton p-mb-4">
                                                        <RadioButton inputId="OnlinePay" name="paymentMethod" value="PAY_LATER" onChange={(e) => setPaymentType(e.value)} checked={paymentType == 'PAY_LATER'} required />
                                                        <label htmlFor="OnlinePay"> Pay Later <small>  ( Your Payment would be due in <b>{props.selectedAccount?.payemntTerms?.extPeriod} Days.</b></small> )</label>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='card'>
                                                    <div className="field-radiobutton p-mb-4">
                                                        <RadioButton inputId="OnlinePay" name="paymentMethod" value="LINK" onChange={(e) => setPaymentType(e.value)} checked={paymentType == 'LINK'} />
                                                        <label htmlFor="OnlinePay"> Bank Transfer (Get Payment Link on email and SMS)</label>
                                                    </div>
                                                    {
                                                        props.selectedBrand != 'MERI_TAPRI' &&
                                                        <>
                                                            <div className="field-radiobutton p-mb-4">
                                                                <RadioButton inputId="bankTrans" name="paymentMethod" value="ONLINE" onChange={(e) => setPaymentType(e.value)} checked={paymentType == 'ONLINE'} />
                                                                <label htmlFor="bankTrans"> Online</label>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        }

                                        <h6 className='p-error' ><small> <b>Note :</b> Your payment mode is as per the signed agreement</small></h6>

                                    </div>
                                </div>
                                <br></br>
                                <Button className='p-custom-btn p-button-lg p-col-12 p-mt-2' icon="pi pi-arrow-right" iconPos="right" label='Proceed' onClick={() => { setConfirmPlaceDialog(true) }} disabled={proceedClicked} ></Button>
                            </div>
                        </div>
                    </>
                }

            </div>

            <Dialog visible={couponCodeDialog} style={{ width: '750px' }} header={`Available Coupon Codes`} modal className="p-fluid" footer={couponDialogFooter} onHide={hideCouponDialog} maximizable={true} blockScroll={true}>
                <div className="p-grid table-demo p-m-4">
                    <DataTable value={allCouponsList} className="p-datatable-customers" rows={10} dataKey="id" rowHover emptyMessage="No Coupons Available." loading={couponLoading} >
                        <Column field="coupon" header="Coupon Code" body={codeBodyTemplate}></Column>
                        <Column field="description" header="Description" body={CommonBodyForTable}></Column>
                        <Column header="Action" body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </Dialog>

            <Dialog header="Success" footer={successDialogFooter} visible={successDialog} style={{ width: '30vw' }} onHide={hideSuccessDialog}>
                <center>
                    <i className="pi pi-check-circle" style={{ fontSize: '8rem', color: "green" }}></i>
                    <h3>Order Placed</h3>
                    <h6>Order Id : <b>{placedOrderData?.order_no}</b></h6>
                    {
                        placedOrderData?.razorpay_url &&
                        <>
                            <h6>Payment Link : <br></br> <a href={placedOrderData?.razorpay_url} target='_blank'>{placedOrderData?.razorpay_url}</a>  <i className="p-m-1 pi pi-copy" style={{ cursor: 'pointer' }} onClick={onclickThis} ></i>  </h6>
                        </>
                    }
                </center>
            </Dialog>

            <Dialog visible={confirmPlaceDialog} style={{ width: '450px' }} header="Confirm" modal footer={confirmDialogFooter} onHide={() => { setConfirmPlaceDialog(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br />
                        <b><span>Are you sure you want to place the order? </span></b>
                    </center>
                </div>
            </Dialog> 
        </>
    )
}
