import React, { useEffect, useRef, useState } from 'react'

import { TableWrapper } from '../../utilComponents/DataTableWrapper'
import { CommonBodyForTable, CommonConfirmationDialog, commonFormDialogFooter, srBodyTemplate } from '../Common/CommonComponents'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { dateBodyForTable } from '../Users/ActiveUsers'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { FormWrapper, FullFormWrapper } from '../../utilComponents/Forms'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'

import ApiServices from '../../service/api/ApiServices'
import { Toast } from 'primereact/toast'
import { failureToast, successToast } from '../Common/CommonFunctions'
const apiServices = new ApiServices()

export const MainCategory = () => {

    const [categories, setCategories] = useState(null)
    const [categoryDialog, setCategoryDialog] = useState(false)
    const [categoryIssues, setCategoryIssues] = useState('')
    const [selectedCategory, setSelectedCategory] = useState(null)

    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false)

    const toast = useRef(null)

    useEffect(() => {
        getCategories()
    }, [])

    const getCategories = async() =>{
        const response = await apiServices.getIssueCategories({});
        console.log(response?.data?.data);
        setCategories(response?.data?.data)
    }


    const openCategoryDialog = () =>{ 
        setSelectedCategory(null)
        setCategoryDialog(true) 
    }


    const rightTemplate = () =>(
        <Button className='p-custom-btn' label="Add Category" icon="pi pi-plus-circle" iconPos="left" onClick={openCategoryDialog} />
    )

    const hideCategoryDialog = () => { 
        setCategoryDialog(false) 
        setCategoryIssues('')
    }

    const categoryFooter = commonFormDialogFooter('Cancel', hideCategoryDialog, 'Submit', 'category-form')

    const saveCategory = async(e) =>{
        e.preventDefault()

        let response;
        if(selectedCategory){
            const payload = {...selectedCategory, issue : categoryIssues }
            response = await apiServices.updateIssueCategory(payload)
        }else{
            response = await apiServices.addIssueCategory({"issue": categoryIssues})
        }

        if(response?.data?.success){
            successToast(toast,`Category ${selectedCategory ? 'Updated' : 'Added'} Successfully`)
            hideCategoryDialog()
            getCategories()
        }else{
            failureToast(toast, `Failed to ${selectedCategory ? 'Update' : 'Add'}`)
        }
    }

    const openEdit = (rowData) =>{
        console.log(rowData);
        setCategoryDialog(true)
        setSelectedCategory(rowData)
        setCategoryIssues(rowData?.issue)
    }

    const openDelete = (rowData) =>{
        setOpenDeleteConfirmDialog(true)
        setSelectedCategory(rowData)
    } 

    const deleteCategory = async() =>{
        const response = await apiServices.deleteIssueCategory({ _id : selectedCategory._id })
        if(response?.data?.success){
            successToast(toast,'Category Deleted Successfully.')
            hideDeleteDialog()
            getCategories()
        }else{
            failureToast(toast,'Failed to Delete.')
        }
    }

    const actionBodyTemplate = (rowData) =>{
        return (
            <>
                <span className="p-column-title">Actions</span>
                <div className="actions" style={{ display: "flex" }}>
                    <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-warning p-mx-1" aria-label="Edit" onClick={()=>{openEdit(rowData)}} />
                    <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-1" aria-label="Delete" onClick={()=>{openDelete(rowData)}} />
                </div>
            </>
        )
    }

    const hideDeleteDialog = () =>{
        setOpenDeleteConfirmDialog(false)
    }

    const deleteDialogFooter = () =>{
        return (
            <>
                <Button label="Yes, Delete it." icon="pi pi-check" className=" p-button-success" onClick={deleteCategory} />
                <Button label="Cancel" icon="pi pi-times" className=" p-button-danger" onClick={hideDeleteDialog}  />
            </>
        )
    }

    const deleteProps = {
        dialogState : openDeleteConfirmDialog,
        dialogFooter : deleteDialogFooter,
        hideDialog : hideDeleteDialog,
        msg : 'Do You Want To Delete this Category?'
    }

    return (
        <>
            <TableWrapper>
                <Toast ref={toast} position='center' />
                <Toolbar left='List of Issue Categories' right={rightTemplate}></Toolbar>
                <DataTable value={categories} dataKey="id" className="datatable-responsive" emptyMessage="No Categories Found." >
                    <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '8rem' }} exportable={false}></Column>
                    <Column field="issue" header="Category" body={CommonBodyForTable} sortable ></Column>
                    <Column field="createdAt" header="Created At" body={dateBodyForTable} sortable ></Column>
                    <Column header="Actions" body={actionBodyTemplate} exportable={false}></Column>
                </DataTable>
            </TableWrapper>


            <Dialog visible={categoryDialog} style={{ width: '650px' }} header={`${selectedCategory ? 'Edit' : 'Add'} New Category`} modal className="p-fluid" footer={categoryFooter} onHide={hideCategoryDialog} maximizable={true} blockScroll={true} >
                <form onSubmit={saveCategory} id="category-form">
                    <div className='p-grid'>
                        <FullFormWrapper id="issue" label="Issue Category">
                            <InputText id="issue" name='issue' placeholder='Enter Issue Category' value={categoryIssues} onChange={(e)=>{ setCategoryIssues(e.target.value)}}  required autoFocus />
                        </FullFormWrapper>
                    </div>
                </form>
            </Dialog>

            <CommonConfirmationDialog {...deleteProps}/>
        </>
    )
}
