import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
// Context
import { permissionContext } from '../../AppWrapper';
// Components
import { AddLead } from '../Users/AddLead';
// Services
import ApiServices from '../../service/api/ApiServices';
const apiServices = new ApiServices();

export const Leads = () => {
    
    const rolePermissions = useContext(permissionContext);
    
    const [leads, setLeads] = useState([]);
    const [allLeads, setAllLeads] = useState([]);
    const [leadDialog, setLeadDialog] = useState(false);
    const [viewLeadDialog, setViewLeadDialog] = useState(false);
    const [unOrLeadDialog, setUnOrLeadDialog] = useState(false);
    const [deleteLeadDialog, setDeleteLeadDialog] = useState(false);
    const [indexCount, setIndexCount] = useState(0);

    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [onDemandCount,setOnDemandCount] = useState(50)
    const [onDemandIndex,setOnDemandIndex] = useState(0)
    const [sourceData,setSourceData] = useState([])
    const [onDemandId,setOnDemandId] = useState(0)

    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedLead, setSelectedLead] = useState(null);
    const [multiselectValue, setdropdownValue] = useState({name:"All",code: "all"});
    const [loading, setLoading] = useState(true);
    const [length, setLength] = useState(0);
    
    const toast = useRef(null);
    const dt = useRef(null);

    const multiselectValues = [
        { name: 'All', code: 'all' },
        { name: 'Interested', code: 'Interested'},
        { name: 'Non-Interested', code: 'Semi-Interested' },
        { name: 'Semi-Interested', code: 'Not-Interested' },
        { name: 'Pitch Again', code: 'Pitch-Again' }
    ];

    const dropdownItems = [
        { name: 'Organized', code: 'organized' },
        // { name: 'Unorganized', code: 'unorganized' }
    ];

    useEffect(()=>{
        getLeads()
    },[])

    const success = (msg) =>{
        toast.current.show({ severity: 'success', summary: 'Successful', detail:`${msg}`, life: 3000 });
    } 

    const failure = (msg) => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail:`${msg}`, life: 3000 });
    }

    const getLeads = async() => {
        const arr = [];
        const data = {count: 50,index: indexCount}
        const response = await apiServices.getLeads(data);
        setLoading(false)
        response?.data?.data && arr.push(...response?.data?.data)
        console.log(arr);
        setLeads(arr)
        setAllLeads(arr)
        setSourceData(arr);
        setLength(arr.length)
        setTotalRecords(arr.length);
    };


    const onPageChange = async(event) => {

        setOnDemandId(event.rows * event.page)
        setTimeout(() => {
            const startIndex = event.first;
            const endIndex = event.first + rows;
            setFirst(startIndex);
            setLeads(sourceData.slice(startIndex, endIndex));

        }, 250);

        if((event.page + 1) % 5 == 0){

            const data = { count : onDemandCount , index : onDemandIndex + 50}
            const response = await apiServices.getLeads(data);
            const arr = response?.data?.data;
            const totalData = [...sourceData,...arr]
            setSourceData(totalData);
            setTotalRecords(totalData.length);
            setLength(totalData.length)
            setLeads(totalData.slice(event.first, event.first + rows));
            setOnDemandIndex(onDemandIndex + 50)
        }

    }


    const onInstallChange = (e) => {
        // setSelectUserType(e.value.code);
        setdropdownValue(e.value);
        if(e.value.code == 'all'){
        setLeads(allLeads);
        } else {
        const intData = allLeads.filter((int) => int.install == e.value.code);
        setLeads(intData);
        console.log(intData);
        }
    };

    const OnChangeValue = (e) =>{
        const value = e.value.name;
        if(e.value.code == 'organized' ) {
            setSelectedLead(null)
            setLeadDialog(true)
        }else if(e.value.code == 'unorganized'){
            setUnOrLeadDialog(true)
        }
    }   
    
    const openAddLeadDialog = () =>{
        setSelectedLead(null)
        setLeadDialog(true)
    }

    const hideViewLeadDialog = () =>{
        setViewLeadDialog(false);
    }
 
    const hideDeleteLeadDialog = () => {
        setDeleteLeadDialog(false);
    }

    const editLeadDetails = (rowData) => {
        setLeadDialog(true);
        setSelectedLead(rowData);
        console.log(rowData);
    }

    const viewInfo = (data) => {
        console.log(data);
        setSelectedLead(data)
        setViewLeadDialog(true);
    }

    const confirmDeleteLead = (rowData) => {
        setSelectedLead(rowData);
        setDeleteLeadDialog(true);
    }

    const deleteLead = async() => {
        const response = await apiServices.deleteLead({userid:selectedLead?.userid})
        console.log(response.data.success);
        response?.data?.success ? success('Lead deleted successfully') : failure('Failed to delete')
        setDeleteLeadDialog(false)
        getLeads()
        setLoading(true)
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const leftToolbarTemplate = () =>(
        <>
            <div className="p-inputgroup" >
                <Button className='custom-group-btn' label="TYPE " />
                <Dropdown value={multiselectValue}  options={multiselectValues} onChange={onInstallChange} optionLabel="name" placeholder="Select Filter" filter
                        className="p-m-0 multiselect-custom" />
            </div>
        </>
    )

    const rightToolbarTemplate = () => (
            <>
                {/* <div className="p-inputgroup" > */}
                    {/* <Button className='custom-group-btn' label="ADD NEW LEAD " />
                    <Dropdown id="state" onChange={OnChangeValue} options={dropdownItems} optionLabel="name" placeholder="Select Type"></Dropdown> */}
                {/* </div> */}
                    { rolePermissions?.CRMLeadAddBtn &&  <Button className='p-custom-btn' label="ADD NEW LEAD" icon="pi pi-plus-circle" iconPos="left" onClick={()=>{openAddLeadDialog()}}/> } 
            </>
    )

    const onDemandidTemplate = (rowData,props) => (<><span className="p-column-title">Sr.No.</span>{props.rowIndex + 1 + onDemandId}</>)

    const nameBodyTemplate = (rowData) => {
        return (
          <>
            <span className="p-column-title">Name</span>
            {rowData.name}
          </>
        );
      };

    const contactBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Contact</span>
                {rowData.cno}
            </>
        );
    }

    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Address</span>
                {rowData.addr?.addr}
            </>
        );
    }

    const dateBodyTemplate = (rowData) => {
        const convertedDate = new Date(rowData.startDate).toDateString()
        return (<><span className="p-column-title">Date</span>{convertedDate}</>)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                { rolePermissions?.CRMLeadEditBtn && <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-success p-mr-2" onClick={() => editLeadDetails(rowData)}/> }
                { rolePermissions?.CRMLeadDeleteBtn && <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mr-2" onClick={() => confirmDeleteLead(rowData)} /> }
                { rolePermissions?.CRMLeadViewBtn && <Button icon="pi pi-eye" title='Info' className="p-button-rounded p-button-info p-mr-2" onClick={() => viewInfo(rowData)}/> }
            </div>
        )
    }

    const header = (
        <div className="table-header">
            <h6>Leads</h6>
            <span className="p-input-icon-left">
                <div className="p-inputgroup" >
                    {/* <Button className='custom-group-btn' icon="pi pi-search" /> */}
                    {/* <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." /> */}
                </div>
            </span>
        </div>
    );

 
    const deleteLeadDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideDeleteLeadDialog} />
            <Button label="Yes, Delete it!" icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" onClick={deleteLead} />
        </>
    );

    const viewLeadFooter = (<><Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideViewLeadDialog} /></>)

    

    const footer = `In total there are ${length} Leads.`;

    return (
            <div className="p-grid crud-demo">
            <div className="p-col-12">
            <div className="card">
            <Toast ref={toast} />
            <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

            <DataTable ref={dt} value={leads} 
            dataKey="id" paginator rows={10} className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={`Showing {first} to {last} of ${length} leads`}
            globalFilter={globalFilter} emptyMessage="No leads found." header={header}
            loading={loading} footer={footer} totalRecords={totalRecords} lazy first={first} onPage={onPageChange} >
                <Column field="srid" header="Sr.No."  body={onDemandidTemplate}  exportable={false} ></Column>
                {/* <Column field="id" header="Sr.No." sortable body={onDemandidTemplate}></Column> */}
                <Column field="name" header="Name" sortable body={nameBodyTemplate}></Column>
                <Column field="contact" header="Contact" sortable body={contactBodyTemplate}></Column>
                <Column field="address" header="Address" sortable body={addressBodyTemplate}></Column>
                <Column field="date" header="Date" sortable body={dateBodyTemplate}></Column>
                <Column header="Action" body={actionBodyTemplate}></Column>
            </DataTable>

            <Dialog visible={deleteLeadDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteLeadDialogFooter} onHide={hideDeleteLeadDialog}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br/><br/>
                        <b><span>Are you sure you want to delete this lead?</span></b> <br/><br/>
                        <span>You wont be able to revert.</span> 
                    </center>
                </div>
            </Dialog>

            <AddLead leadDialog={leadDialog} setLeadDialog={setLeadDialog} getLeads={getLeads} selectedLead={selectedLead} />

            <Dialog visible={viewLeadDialog} style={{ width: '650px' }} header={`Lead Details (${selectedLead?.name ? selectedLead?.name : '-'})`} modal footer={viewLeadFooter}  onHide={hideViewLeadDialog} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                    <div className="p-col-12">
                        <div className=" widget-performance">
                            <div className="content">
                                <ul>
                                <div className='p-grid'>
                                <CommonForDetails name="Install" value={selectedLead?.install}/>
                                <CommonForDetails name="Name" value={selectedLead?.name} />
                                <CommonForDetails name="Location" value={selectedLead?.location} />
                                <CommonForDetails name="Email Id" value={selectedLead?.email} />
                                <CommonForDetails name="Gender" value={`-`} />
                                <CommonForDetails name="Contact Number" value={selectedLead?.cno} />
                                <CommonForDetails name="Date" value={selectedLead?.startDate ? new Date(selectedLead?.startDate).toLocaleDateString('fr-CA') : 'NA'} />
                                <CommonForDetails name="Age" value={`-`} />
                                <CommonForDetails name="Shop Area" value={selectedLead?.shopdetails.shoparea} />
                                <CommonForDetails name="Address" value={selectedLead?.addr?.addr} />
                                <CommonForDetails name="Machine Type" value={selectedLead?.shopdetails?.machinetype} />
                                </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>


            </div>
            </div>
            </div>
    );
}



    export const CommonForDetails = props =>{
        return (
            <div className='p-col-6'>
                <li className="person-item p-p-0">
                <div className="person-info">
                    <div className="amount">{props.name}</div>
                    <div className="name p-mt-1">{props.value}</div>
                </div>
                </li>
            </div>
        )
    }
