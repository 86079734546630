import React, { useEffect, useState,useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { failureToast, successToast } from "../Common/CommonFunctions";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

export const AddVirtualCards = (props) =>{

    const [virtualDialog,setVirtualDialog] = useState(false)
    const [cardMachines,setCardMachines] = useState([])
    const [cardTemplate,setCardTemplate] = useState(null)
    const [selectedCTVM,setSelectedCTVM] = useState(null)
    const [selectedCupLimit,setSelectedCupLimit] = useState(null)
    const [selectedCard, setSelectedCard] = useState(null)
    const [selectedMachine, setSelectedMachine] = useState(null)
    const [getCards, setGetCards] = useState(null)
    
    const toast = useRef(null);
    
    const cupLimitOptions = [
        { name: 'Daily', code: 'daily' },
        { name: 'Monthly', code: 'monthly' }
    ]

    useEffect(()=>{
        setVirtualDialog(props.virtualCardDialog)
    },[props.virtualCardDialog])

    useEffect(()=>{
        setCardMachines(props.virtualCardMach)
    },[props.virtualCardMach])

    useEffect(()=>{
        getSelectedCardDetails(props.selectedCard)
    },[props.selectedCard])

    useEffect(()=>{
        setSelectedMachine(props.selectedVirtualMach)
    },[props.selectedVirtualMach])
    
    const getSelectedCardDetails = (data) =>{
        if(data){
            console.log(data);

            setCardTemplate(data)
            setSelectedCard(data)

            setTimeout(() => {
                const sCard = cardMachines.filter((x)=>{ if(x.id == data.machineId){ return x; } })
                setSelectedCTVM(sCard[0])
            }, 1000);

            if(data.renewalPlan == 'daily'){
                setSelectedCupLimit({ name: 'Daily', code: 'daily' })
            }else if(data.renewalPlan == 'monthly'){
                setSelectedCupLimit({ name: 'Monthly', code: 'monthly' })
            }
        }
    }


    const hideDialog = () =>{
        setVirtualDialog(false)
        props.setVirtualCardDialog(false)
    }

    const changeValue = (e) =>{

        if(e.target.name == 'name' || e.target.name == 'cno' || e.target.name == 'password' || e.target.name == 'cups'){
            setCardTemplate({...cardTemplate,[e.target.name] : e.target.value})
        }

        if(e.target.name == 'cups'){
            setCardTemplate({...cardTemplate,[e.target.name] : parseInt(e.target.value)})
        }

        if(e.target.name == 'machineId'){
            setSelectedCTVM(e.value)
            setCardTemplate({...cardTemplate,[e.target.name] : e.value.id})
        }

        if(e.target.name == 'renewalPlan'){
            setSelectedCupLimit(e.value)
            setCardTemplate({...cardTemplate,[e.target.name] : e.value.code})
        }
    }

    const cardSubmit = async(e) =>{
        e.preventDefault();
        hideDialog()

        if(selectedCard){
            // Update
            const response = await apiServices.updateVirtualCards(cardTemplate);
            response?.data?.success ? successToast(toast,'Operator is updated successfully') : failureToast(toast,'Failed to update');

        }else{
            // Add
            const response = await apiServices.addVirtualCards(cardTemplate);
            response?.data?.success ? successToast(toast,'Operator is added successfully') : failureToast(toast,'Failed to add');
        }

        props.getVirtualCards(selectedMachine)        
    }

    const cardDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2"  onClick={()=>hideDialog()} />
            <Button label="Submit" type='submit' form='card-submit' icon="pi pi-check" className=" p-button-success p-mr-2 p-mb-2" />
        </>
    )

    return (
        <>
            <Toast ref={toast} position="center" /> 
            {/* selectedCard.machineId */}
            <Dialog visible={props.virtualCardDialog} style={{ width: '650px' }} header={`${selectedCard ? 'Edit' : 'Add New'} Virtual Card`} modal className="p-fluid" footer={cardDialogFooter} onHide={()=>{hideDialog()}} maximizable={true} blockScroll={true}>
                <form onSubmit={cardSubmit} id="card-submit" className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="name">Name</label>
                        <InputText id="name" name="name" placeholder='Name' onChange={changeValue} autoComplete="off"  defaultValue={selectedCard?.name} autoFocus required/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="contact-num">Contact Number</label>
                        <InputText id="contact-num" name="cno" onChange={changeValue} maxLength={10} placeholder='Contact Number' defaultValue={selectedCard?.cno} autoComplete="off" required/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="password">Password</label>
                        <InputText id="password" name="password" onChange={changeValue} placeholder='Password' autoComplete="off" defaultValue={selectedCard?.password} required/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="ctvm-id">CTVM Id</label>
                        <Dropdown id="ctvm-id" name='machineId' options={cardMachines} onChange={changeValue} value={selectedCTVM} optionLabel="id" placeholder="Select CTVM Id" filter required ></Dropdown>
                    </div>
                    <div className="p-field">
                        <label htmlFor="cup-limit">Cup Limit</label>
                        <Dropdown id="cup-limit" name='renewalPlan' options={cupLimitOptions} onChange={changeValue} value={selectedCupLimit} optionLabel="name" placeholder="Select Cup Limit" required></Dropdown>
                    </div>
                    <div className="p-field">
                        <label htmlFor="cup-count">Cup Count</label>
                        <InputText type='number' name="cups" id="cup-count" onChange={changeValue} placeholder='Cup Count' defaultValue={selectedCard?.cups?.half} required/>
                    </div>
                </form>
            </Dialog>
        </>
    )

}