export const durationOptions = [
    { name: 'All', code: 'all' },
    { name: 'Today', code: 'today' },
    { name: 'Yesterday', code: 'yesterday' },
    { name: 'This Week', code: 'this_week' },
    { name: 'This Month', code: 'this_month' },
    { name: 'Last Month', code: 'last_month' },
    { name: 'Custom', code: 'custom' }
]

export const invoiceStatusItems = [
    { name: 'All', code: 'all' },
    { name: 'Pending', code: 'PENDING' },
    { name: 'Paid', code: 'COMPLETED' }
]

export const invoiceTypeItems = [
    { name: 'All', code: 'all' },
    { name: 'Consumable', code: 'consumable' },
    { name: 'Subscription', code: 'subscription' }
];