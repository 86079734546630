import React from 'react';

export const TransactionStats = props =>{
    return (
        <>
            
            <div className="card widget-overview-box widget-overview-box-1">
                <span className="overview-title">
                {props.title}
                </span>
                <div className="p-d-flex p-jc-between">
                    <div className="overview-detail p-d-flex p-jc-between">
                        {        
                            props.loading ?
                            <i className="pi pi-spin pi-spinner overview-text" />:
                            <div className="overview-text"><b>{props.value}</b></div>
                        }
                    </div>
                </div>
                <i className={`${props.icon} side-icon`} style={{ fontSize: '2rem' }}></i>
            </div>
            
        </>
    )
}