import React, {useRef} from "react";
import { Chart } from "primereact/chart";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const AccountWiseInventoryChart = (props) => {

    const dt = useRef(null);
    const dataValues = props.inventoryData;    

    const stackedData = {
        labels: props.InAccLabels,
        datasets: [{
            type: 'bar',
            label: 'Loaded Packets',
            backgroundColor: '#06685d4a',
            data: props.InLoadedPackets,
            borderRadius: 5
        }, {
            type: 'bar',
            label: 'Used Packets',
            backgroundColor: '#00544D',
            data: props.InUsedPackets,
            borderRadius: 5
        }]
    };
    
    const stackedOptions = {
        maintainAspectRatio: false,
        aspectRatio: .5,
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                },
                title: {
                    display: true,
                    text: 'Accounts'
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                },
                title: {
                    display: true,
                    text: 'Packets'
                }
            }
        }
    };
    

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const fieldTemplate = (rowData, props) => (<><span className="p-column-title">{props.header}</span>{rowData[props.field]}</>)


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <span>{props.accType.case}-Wise Inventory Performance Details</span>
                    <div className='p-col-sm-4'>
                        <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-danger p-ml-4 p-mb-2" onClick={exportCSV} />
                    </div>
                </div>
                <div className="graph">
                <Chart type="bar" data={stackedData} options={stackedOptions} />
                </div>
            </div>

            <DataTable  style={{display:'none'}} ref={dt} value={dataValues} dataKey="id" exportFilename={ `${props.accType.case}-Wise Inventory Details`}  className="datatable-responsive" emptyMessage="No data found.">
                <Column field="name" header="Name"  body={fieldTemplate}></Column>
                <Column  field="used_packets" header="Used Packets" body={fieldTemplate}></Column>
                <Column field="loaded_packets" header="Loaded Packets" body={fieldTemplate}></Column>
            </DataTable>

        </>
    )

}