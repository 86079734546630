import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { permissionContext } from '../../AppWrapper';
// Service
import ApiServices from '../../service/api/ApiServices';
import { DateBodyForTable, } from '../Common/CommonComponents';
const apiServices = new ApiServices();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);



export const DetailedQrTable = props => {

    const despensedOptions = [
        { name: 'All', code: 'all' },
        { name: 'Dispensed', code: 'dispensed' },
        { name: 'Non-Dispensed', code: 'non_dispensed' }
    ]

    const CommonForDetails = props => {
        return (
            <div className='p-col-6'>
                <li className="person-item p-p-0">
                    <div className="person-info">
                        <div className="amount">{props.name}</div>
                        <div className="name p-mt-1">{props.value}</div>
                    </div>
                </li>
            </div>
        )
    }

    const rolePermissions = useContext(permissionContext);

    // console.log();
    const [globalFilter, setGlobalFilter] = useState(null);
    const [transactionData, setTransactionData] = useState(null);
    const [allTransactionData, setAllTransactionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [despensed, setDespensed] = useState({ name: 'All', code: 'all' })
    const [refundDialog, setRefundDialog] = useState(false)
    const [selectedTransaction, setSelectedTransaction] = useState(null)
    const [selectedDurationDates, setSelectedDurationDates] = useState(null)
    const [selectedAccount, setSelectedAccount] = useState(null)
    const [allMachines, setAllMachines] = useState([{ id: 'All' }])
    const [selectedMachine, setSelectedMachine] = useState({ id: 'All' })
    const [manualRefundDialog, setManualRefundDialog] = useState(false);
    const [manualRefundData, setManualRefundData] = useState({
        id: '',
        subid: '',
        comment: '',
        txn_id: '',
        orderId: '',
        beverage_name: '',
        amount: '',
        mid: ''
    })
    const [viewRequestDialog, setViewRequestDialog] = useState(false);
    const [refundRequestData, setRefundRequestData] = useState({});

    const dt = useRef(null);
    const toast = useRef(null);


    // useEffect(()=>{
    //     getTransactionData(props.transTableData)
    // },[props.transTableData])


    useEffect(() => {
        setSelectedDurationDates(props.selectedDurationDates)
        console.log(props.selectedDurationDates);
    }, [props.selectedDurationDates])


    useEffect(() => {
        console.log(props.selectedAccount);
        setSelectedAccount(props.selectedAccount)
    }, [props.selectedAccount])


    useEffect(() => {
        getData(props.selectedAccount, props.selectedDurationDates)
    }, [props.selectedAccount, props.selectedDurationDates])


    const getData = (account, duration) => {
        setAllMachines([{ id: 'All' }])
        setSelectedMachine({ id: 'All' })
        setDespensed({ name: 'All', code: 'all' })
        if (account?.company == 'All') {
            getDetailedTransactions({ date: duration })
        } else {
            getDetailedTransactions({ "userType": account.userType, "userid": account.userid, date: duration })
        }
    }

    const getDetailedTransactions = async (data) => {
        const response = await apiServices.transactionsByUser({ ...data, "detailWise": true });
        console.log('response', response?.data?.data);
        getTransactionData(response?.data?.data)
    }

    const getTransactionData = async (data) => {
        setLoading(true)
        // const response = await apiServices.getPaytmAcc()
        const response = await apiServices.getPaytmAccount({ "userType": initialValue?.userType })
        console.log(response?.data?.data);
        const arr = []
        const res = data?.map((x) => {
            const res = response?.data?.data.filter((y) => {
                if (x.txndetails.mid == y.paytmmid || x.txndetails.MID == y.paytmmid) {
                    arr.push({ ...x, username: y.name })
                }
            })
        })
        const unique = [...new Set(arr.map(item => item.machineId))];
        const machineData = unique.map((x) => { return { 'id': x } })
        const x = [{ id: 'All' }]
        setAllMachines([...x, ...machineData])
        setTransactionData(arr)
        setAllTransactionData(arr)
        setLoading(false)
    }


    const changeMachine = (e) => {
        setSelectedMachine(e.value)
        if (e.value.id == 'All') {
            const data = despensed.code == 'all' ? allTransactionData : allTransactionData.filter((x) => { return x.dispensed == (despensed.code == 'dispensed' ? true : false) })
            setTransactionData(data)
        } else {
            const data = despensed.code == 'all' ? allTransactionData.filter((x) => { return x.machineId == e.value.id }) : allTransactionData.filter((x) => { return x.machineId == e.value.id && x.dispensed == (despensed.code == 'dispensed' ? true : false) })
            setTransactionData(data)
        }
    }


    const changeDispense = (e) => {
        setDespensed(e.value)

        switch (e.value.code) {
            case 'dispensed': {
                const data = selectedMachine.id == 'All' ? allTransactionData.filter((x) => { return x.dispensed == true }) : allTransactionData.filter((x) => { return x.dispensed == true && x.machineId == selectedMachine.id })
                // const data = allTransactionData.filter((x)=>{ return x.dispensed == true})
                setTransactionData(data)
                break;
            }
            case 'non_dispensed': {
                const data = selectedMachine.id == 'All' ? allTransactionData.filter((x) => { return x.dispensed == false }) : allTransactionData.filter((x) => { return x.dispensed == false && x.machineId == selectedMachine.id })
                // const data = allTransactionData.filter((x)=>{ return x.dispensed == false})
                setTransactionData(data)
                break;
            }
            default:
                const data = selectedMachine.id == 'All' ? allTransactionData : allTransactionData.filter((x) => { return x.machineId == selectedMachine.id })
                setTransactionData(data)
                break;
        }
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const refundFun = (data) => {
        console.log(data);
        setRefundDialog(true)
        setSelectedTransaction(data)
    }

    const hideRefundDialog = () => { setRefundDialog(false) }

    const refundNow = async () => {

        hideRefundDialog()

        const data = {
            "id": selectedTransaction?.machineId,
            "subid": selectedTransaction?.subid,
            "orderId": selectedTransaction?.orderId
        }

        const response = await apiServices.refundManual(data)
        console.log(response);

        if (response?.data?.success) {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Refund is done Successfully', life: 3000 });

            props.getDetailedTransactions({ id: selectedTransaction?.machineId, date: selectedDurationDates })
            setDespensed({ name: 'All', code: 'all' })

        } else {
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Failed to Refund', life: 3000 });
        }
    }

    const manualRefund = async (data) => {
        console.log('manualRefund', data);
        setManualRefundDialog(true);
        setManualRefundData({
            id: data.machineId,
            subid: data.subid,
            comment: '',
            txn_id: data?.txndetails?.txnId,
            orderId: data.orderId,
            beverage_name: data?.beverage || 'TAPRI BEVERAGE',
            amount: data?.amount,
            mid: data?.txndetails?.mid
        })

    }

    const hideManualRefund = () => {
        setManualRefundDialog(false);
        setManualRefundData({
            id: '',
            subid: '',
            comment: '',
            txn_id: '',
            orderId: '',
            beverage_name: '',
            mid: '',
            amount: ''
        })
    }

    const proceedRefundRequest = async (e) => {
        e.preventDefault();
        const data = {
            ...manualRefundData,
            userid: initialValue?.userid,
            userType: initialValue?.userType,
            userName: `${initialValue?.name} ${initialValue?.lastName}`
        }
        console.log('proceedRefundRequest', data);
        const response = await apiServices.raiseRefundRequest(data)
        // console.log(response);
        if (response?.data?.success) {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Refund raised Successfully', life: 3000 });
            getDetailedTransactions({
                "userType": selectedAccount.userType,
                "userid": selectedAccount.userid,
                date: selectedDurationDates
            })
        } else {
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Failed to Refund', life: 3000 });
        }
        hideManualRefund();
    }

    const manualRefundDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideManualRefund} />
            <Button label="Proceed" form='manual-refund' icon="pi pi-undo" className="p-button-success p-mr-2 p-mb-2" />
        </>
    )


    const refundDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideRefundDialog} />
            <Button label="Refund Now" icon="pi pi-undo" className="p-button-success p-mr-2 p-mb-2" onClick={refundNow} />
        </>
    )


    const srBodyTemplate = (rowData, props) => (<><span className="p-column-title">{props.header}</span>{props.rowIndex + 1}</>)
    const commonBodyTemplate = (rowData, props) => (<><span className="p-column-title">{props.header}</span>{rowData[props.field]}</>)

    const dateBodyTemplate = (rowData, props) => (
        <>
            <span className="p-column-title">{props.header}</span>{new Date(rowData[props.field]).toLocaleString()}
        </>
    )

    const despensedBodyTemplate = (rowData, props) => (<>
        <span className="p-column-title">{props.header}</span>{rowData[props.field] ? <i className="pi pi-check p-success"></i> : <i className="pi pi-times p-error"></i>}

        {
            (rolePermissions?.transRefund && !rowData?.manualRefund && (rowData?.txndetails?.refund?.body?.resultInfo?.resultStatus === "TXN_FAILURE" || rowData?.txndetails?.refund?.body?.resultInfo?.resultStatus === "PENDING")) &&
            (rowData?.txndetails?.refund?.body?.resultInfo?.resultMsg !== 'Refund amount is invalid or greater than transaction amount') &&
            <Button icon="pi pi-undo" label='Refund' className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={() => { refundFun(rowData) }} />
        }

        {
            (rolePermissions?.refundRequestBtn && !rowData?.txndetails?.refund && rowData?.dispensed && !rowData?.manualRefund) &&
            <Button icon="pi pi-undo" label='Raise Refund' className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={() => { manualRefund(rowData) }} />
        }

        {
            (!rowData?.dispensed && rowData?.manualRefund) &&
            <Button icon="pi pi-eye" label='Manual Refund' className="p-button-rounded p-button-primary" onClick={() => { viewRefundRequestDetails(rowData) }} />
        }

    </>
    )

    const txnIdBodyTemplate = (rowData, props) => (<><span className="p-column-title">{props.header}</span>{rowData.txndetails[props.field] || rowData.txndetails.TXNID}</>)
    const txnStatusBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {rowData.txndetails?.resultInfo?.resultStatus ? rowData.txndetails?.resultInfo?.resultStatus : null}
                {rowData.txndetails?.STATUS ? rowData.txndetails?.STATUS : null}
                {/* {rowData.txndetails.refund ? <small> (Refunded)</small>  : null}  */}
                {rowData?.txndetails?.refund?.body?.resultInfo?.resultStatus == "TXN_SUCCESS" && <small> (Refunded)</small>}
                {/* Refund amount is invalid or greater than transaction amount */}
                {/* {(rowData?.txndetails?.refund?.body?.resultInfo?.resultStatus == "TXN_FAILURE" || rowData?.txndetails?.refund?.body?.resultInfo?.resultStatus == "PENDING")  && <small > (Not Refunded) <p className='p-error'> ({rowData?.txndetails?.refund?.body?.resultInfo?.resultMsg}) </p> </small>}  */}

                {(rowData?.txndetails?.refund?.body?.resultInfo?.resultStatus == "TXN_FAILURE" || rowData?.txndetails?.refund?.body?.resultInfo?.resultStatus == "PENDING") && <small > {rowData?.txndetails?.refund?.body?.resultInfo?.resultMsg == 'Refund amount is invalid or greater than transaction amount' ? '(Refunded)' : '(Not Refunded)'}  <p className='p-error'> {rowData?.txndetails?.refund?.body?.resultInfo?.resultMsg == 'Refund amount is invalid or greater than transaction amount' ? '' : (rowData?.txndetails?.refund?.body?.resultInfo?.resultMsg)} </p> </small>}

            </>
        )
    }

    const header = (
        <div className="table-header">
            <span className='p-input-icon-right'>
                <div className="p-inputgroup p-mr-2">
                    <Button className='custom-group-btn' label="CVES" />
                    <Dropdown value={selectedMachine} onChange={changeMachine} options={allMachines} optionLabel="id" placeholder="Select CVES" filter
                        className="p-m-0 multiselect-custom" />

                    <Button className='custom-group-btn p-ml-2' label="STATUS" />
                    <Dropdown value={despensed} onChange={changeDispense} options={despensedOptions} optionLabel="name" placeholder="Select Filter" filter
                        className="p-m-0 multiselect-custom" />
                </div>
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV} />
                </div>
            </span>
        </div>
    );


    const viewRefundRequestDetails = async (rowData) => {
        setViewRequestDialog(true);
        const response = await apiServices.viewRaisedRequestDetails({ orderId: rowData.orderId })
        console.log("response", response?.data);
        if (response?.data?.data) {
            const date = new Date(response?.data?.data?.createdAt).toLocaleString();
            const data = { ...response?.data?.data, date }
            setRefundRequestData(data)
        }
    }

    const hideRefundRequestDetails = () => {
        setViewRequestDialog(false);
        setRefundRequestData({});
    }

    const viewRefundRequestDetailsFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className=" p-button-danger p-mr-2 p-mb-2" onClick={hideRefundRequestDetails} />
        </>
    )


    return (
        <>
            <Toast ref={toast} position='center' />
            <DataTable ref={dt} value={transactionData}
                dataKey="id" paginator rows={25} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Transactions"
                globalFilter={globalFilter} emptyMessage="No Data found." header={header} loading={loading}
                exportFilename={`Detailed QR Transaction`}>
                <Column field="srid" header="Sr.No." sortable body={srBodyTemplate} exportable={false}></Column>
                <Column field="machineId" header="CVES Id" sortable body={commonBodyTemplate}></Column>
                <Column field="beverage" header="Beverage" sortable body={commonBodyTemplate}></Column>
                <Column field="amount" header="Amount Per Cup" sortable body={commonBodyTemplate}></Column>
                <Column field="Date" header="QR Transaction Date" sortable body={DateBodyForTable}></Column>
                <Column field="username" header="User Name" sortable body={commonBodyTemplate}></Column>
                <Column field="orderId" header="Order Id" sortable body={commonBodyTemplate}></Column>
                <Column field="resultStatus" header="Result Status" sortable body={txnStatusBodyTemplate} exportable={false}></Column>
                <Column field="txnId" header="QR Transaction Id" sortable body={txnIdBodyTemplate} style={{ wordWrap: 'break-word' }} exportable={false}></Column>
                <Column field="dispensed" header="Dispensed" sortable body={despensedBodyTemplate}></Column>
            </DataTable>


            <Dialog visible={refundDialog} style={{ width: '450px' }} header="Confirm" modal footer={refundDialogFooter} onHide={hideRefundDialog}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br />
                        <b><span>Are you sure you want to Refund Manually?</span></b>
                    </center>
                </div>
            </Dialog>


            <Dialog visible={manualRefundDialog} style={{ width: '650px' }} header={`Raise Refund Request`} modal className="p-fluid" footer={manualRefundDialogFooter} onHide={hideManualRefund} maximizable={true} blockScroll={true}>
                <form id='manual-refund' onSubmit={proceedRefundRequest}>
                    <div className="p-field">
                        <label htmlFor="name">CVES ID</label>
                        <InputText id="id" name='id' placeholder='CVES ID' autoComplete="off" defaultValue={manualRefundData?.id} autoFocus required disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="name">Order ID</label>
                        <InputText id="orderId" name='orderId' placeholder='Order ID' autoComplete="off" defaultValue={manualRefundData?.orderId} autoFocus required disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="name">Transaction ID</label>
                        <InputText id="txn_id" name='txn_id' placeholder='Transaction ID' autoComplete="off" defaultValue={manualRefundData?.txn_id} autoFocus required disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="name">Subid</label>
                        <InputText id="subid" name='subid' placeholder='subid' autoComplete="off" defaultValue={manualRefundData?.subid} autoFocus required disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="name">Beverage Name</label>
                        <InputText id="beverage_name" name='beverage_name' placeholder='beverage_name' autoComplete="off" defaultValue={manualRefundData?.beverage_name} autoFocus required disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="name">Amount</label>
                        <InputText id="amount" name='amount' placeholder='amount' autoComplete="off" defaultValue={manualRefundData?.amount} autoFocus required disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="name">Reason</label>
                        <InputText id="comment" name='comment' placeholder='comment' autoComplete="off" onChange={(e) => setManualRefundData((state) => { return { ...state, 'comment': e.target.value } })} autoFocus required />
                    </div>
                </form>

            </Dialog>

            <Dialog visible={viewRequestDialog} style={{ width: '500px' }} header="Refund Request Details" modal footer={viewRefundRequestDetailsFooter} onHide={hideRefundRequestDetails}>
                <div className='p-grid'>
                    <div className="p-col-12">
                        <div className=" widget-performance">
                            <div className="content">
                                <ul>
                                    <div className='p-grid'>
                                        <CommonForDetails name="Order ID" value={refundRequestData?.orderId} />
                                        <CommonForDetails name="Comment" value={refundRequestData?.comment} />
                                        <CommonForDetails name="UserId" value={refundRequestData?.userid} />
                                        <CommonForDetails name="User Type" value={refundRequestData?.userType} />
                                        <CommonForDetails name="User Name" value={refundRequestData?.userName} />
                                        <CommonForDetails name="Beverage Name" value={refundRequestData?.beverage_name} />
                                        <CommonForDetails name="Raised Date" value={refundRequestData?.date} />
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

        </>
    )
}