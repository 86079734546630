// import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import ChildOne from './ChildOne'
// import ChildTwo from './ChildTwo'

// export const Parent = () => {
//   return (
//     <>
//       <h5>Hello Header</h5>
        
//     </>
//   );
// };

import React from "react";


class Parent extends React.Component{

  constructor(props){
    super(props)
  }

  render(){
    return (
      <>
        <p>Hello</p>
      </>
    )
  }



}

export default Parent;