import React from 'react'
import { FonePayMerchants } from './FonePayMerchants'

export const PayMerchants = () => {
    return (
        <>
            <div className='p-grid'>
                <div className='p-col-12'>
                    <FonePayMerchants/>
                </div>
                <div className='p-col-6'></div>
            </div>
        </>
    )
}
