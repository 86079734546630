import React, { useEffect, useState } from 'react'
import ApiServices from '../../service/api/ApiServices'
import { Chart } from 'primereact/chart';
import { barDataForBarChart, barOptionsForBarChart } from '../Common/CommonFunctions';
const apiServices = new ApiServices();

// ==============================================================================
// ==============================================================================

export const BeverageWiseCupChartNew = props => {

    const [loading, setLoading] = useState(true)
    const [labels, setLabels] = useState(null)
    const [values, setValues] = useState(null)
    const [color, setColor] = useState(null)

    useEffect(() => {
        getData(props.savedPayload)
    }, [props.savedPayload])

    const getData = async(data) =>{
        if(data){
            setLoading(true)
            const payload = { ...data, "beverageWise": true }
            const response = await apiServices.accountWiseConsumption(payload);
            const response1 = await apiServices.getMaterialType();

            const arr = [];
            response1?.data?.mate?.forEach((element) => {
                const materialName = element.materialName;
                response?.data?.data?.forEach((x) => {        
                    if(materialName == x.beverage){ arr.push({...x,color:element.color}) }
                  });
            });

            const datavalues = arr?.sort((a, b) => Number(b.cups) - Number(a.cups));
            console.log(datavalues);

            setLabels(datavalues.map((x)=>{ return x.beverage}))
            setValues(datavalues.map((x)=>{ return x.cups}))
            setColor(datavalues.map((x)=>{ return x.color}))
            setLoading(false)
        }
    }

    const basicData = barDataForBarChart(labels,values,'Cup Consumption',color);
    const basicOptions = barOptionsForBarChart('beverages','Cup Consumption');


    return (
       <>
            {
                loading ? 
                <>
                    <center> <span><i className="pi pi-spin pi-spinner p-mt-5" style={{ fontSize: '2rem' }}></i></span> </center>  
                </> 
                :
                <>
                    <div className="card">
                        <div className="card-header">
                                <span>Beverage-Wise Cup Consumption Performance Details</span>
                                <div className='p-col-sm-4'>
                                    {/* <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-danger p-ml-4 p-mb-2" onClick={exportCSV} /> */}
                                </div>
                        </div>
                        <div className="graph p-mt-5">
                            <Chart type="bar" data={basicData} options={basicOptions} />
                        </div>
                    </div>
                </>  
            }
       </>
    )
}
