import axios from 'axios';

export default class ProductService {

    
    getHeaderImages() {
        return axios.get('assets/demo/data/header-img.json').then((res) => res.data.data);
    }

    // getProductsSmall() {
    //     return axios.get('assets/demo/data/products-small.json').then((res) => res.data.data);
    // }

    // getProducts() {
    //     return axios.get('assets/demo/data/products.json').then((res) => res.data.data);
    // }

    // getProductsMixed() {
    //     return axios.get('assets/demo/data/products-mixed.json').then((res) => res.data.data);
    // }

    // getProductsWithOrdersSmall() {
    //     return axios.get('assets/demo/data/products-orders-small.json').then((res) => res.data.data);
    // }

    // getOnboardCtvm() {
    //     return axios.get('assets/demo/data/on-board-ctvm.json').then((res) => res.data.data);
    // }

    // getEmployees() {
    //     return axios.get('assets/demo/data/employees.json').then((res) => res.data.data);
    // }

    // getMachineHistory() {
    //     return axios.get('assets/demo/data/machine-history.json').then((res) => res.data.data);
    // }

    // getLogFiledetails() {
    //     return axios.get('assets/demo/data/log-files.json').then((res) => res.data.data);
    // }

    // getRestoreData() {
    //     return axios.get('assets/demo/data/restore-data.json').then((res) => res.data.data);
    // }

    // getBeverages() {
    //     return axios.get('assets/demo/data/all-beverages.json').then((res) => res.data.data);
    // }


    

}
