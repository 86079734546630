import React,{useState, useEffect, useRef} from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Chips } from "primereact/chips";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

// ==================================================================================================
// ==================================================================================================

export const InventorySetting = () =>{

    const [allUser, setAllUser] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [barcodes, setBarcodes] = useState(null)
    const [addingTemplate,setAddingTemplate] = useState([])
    const toast = useRef(null);

    useEffect(()=>{ getAccounts() },[])

    const getAccounts = async() =>{
        const response = await apiServices.cvesUsers({userType: "customer"})
        console.log(response?.data?.data);
        const data = response?.data?.data?.map((x)=>{ return {...x,displayName : x?.name + ' / ' + x?.userid } })
        setAllUser(data)
    }

    const changeTemp = (e) =>{
        if(e.target.name == 'barcode'){
            setBarcodes(e.value)
            setAddingTemplate({...addingTemplate,barcodes : e.value})
        }

        if( e.target.name == 'userid'){
            setSelectedUser(e.target.value);
            setAddingTemplate({...addingTemplate,customer : e.target.value.userid})
        }
    }


    const saveChangeBarcode = async(e) =>{
        e.preventDefault();
        console.log(addingTemplate);
        const response = await apiServices.switchBarcodes(addingTemplate)
        response?.data?.success ? success('Barcode Switched Successfully') : error('Failed to Switch')
        setDefault()
    }

    const setDefault =() =>{
        setSelectedUser(null)
        setBarcodes([])
        setAddingTemplate([])
    }

    const success = (msg) => {
        toast.current.show({ severity: 'success', summary: 'Success', detail:`${msg}` , life: 3000 });
    }

    const error = (msg) => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail:`${msg}`, life: 3000 });
    }


    return(
        <>
            <Toast ref={toast} position='center' />

            <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <TabView>
                        <TabPanel header="Swiching Barcodes">
                        <div className="p-card p-fluid p-p-5">
                            <form onSubmit={saveChangeBarcode} id="barcode-form">
                            <div className="p-grid">
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="barcode">Enter Barcode</label>
                                        <Chips name="barcode" value={barcodes}  onChange={changeTemp} />
                                    </div>
                                </div>
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="userid">User ID</label>
                                        <Dropdown id="userid"  name='userid' filter value={selectedUser} options={allUser} onChange={changeTemp} optionLabel="displayName" placeholder="Select User" required></Dropdown>
                                    </div>
                                </div>
                                <div className='p-col-2'>
                                    <div className="p-field">
                                        <Button label="Submit" type='submit' form='barcode-form' icon="pi pi-check" className=" p-button-success p-mr-2 p-mb-2" />
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
            </div> 




            
        </>
    )

}