import React, {useState,useRef} from "react";
import { Chart } from 'primereact/chart';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const AccountWiseCupChart = (props) =>{

    // const [selectedProducts, setSelectedProducts] = useState(null);
    const dt = useRef(null);

    if(props.accType.code == 'account' ){
        var label = props.AccWiseChartLabels;
        var values = props.AccWiseChartValues;
        var chartData = props.accountWiseCupData;
    }else if(props.accType.code == 'facility'){
        var label = props.FacWiseChartLabels;
        var values = props.FacWiseChartValues;
        var chartData = props.facWiseCupData;
    }else if(props.accType.code == 'subscription'){
        var label = props.SubWiseCupLabels;
        var values = props.SubWiseCupValues;
        var chartData = props.subWiseCupData;
    }
    

    const barData = {
        labels: label,
        datasets: [
            {
                label: 'Cups',
                backgroundColor: '#00544D',
                borderColor: 'rgb(255, 99, 132)',
                data: values,
                borderRadius: 5
            }
        ]
    };
    
    const barOptions = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                },
                title: {
                    display: true,
                    text: 'Accounts'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                },
                title: {
                    display: true,
                    text: 'Cups'
                }
            }
        }
    };

    
    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const fieldTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {rowData[props.field]}
            </>
        );
    }



    return (
        
        <>
        <div className="card">
                <div className="card-header">
                    <span>{props.accType.case}-Wise Cup Consumption Performance Details</span>
                    <div className='p-col-sm-4'>
                        <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-danger p-mr-2 p-mb-2" onClick={exportCSV} />
                    </div>
                </div>
                <div className="graph p-mt-5">
                <Chart type="bar" data={barData} options={barOptions} />
                </div>
        </div>


        {/* */}
        
        <DataTable  style={{display:'none'}} ref={dt} value={chartData} dataKey="id" exportFilename={ `${props.accType.case}-Wise Cup Consumption Performance Details`}  className="datatable-responsive" emptyMessage="No data found.">
            { props.accType.code == 'subscription' && <Column field="id" header="Machine" body={fieldTemplate}></Column> }
            <Column width="500" field="name" header="Account"  body={fieldTemplate}></Column>
            <Column field={props.accType.code == 'subscription'?'hcup':'cups'} header="Cup Count" body={fieldTemplate}></Column>
        </DataTable>

        </>
    )
}