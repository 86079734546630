import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import { srBodyTemplate, CommonBodyForTable, commonCloseDialogFooter } from '../Common/CommonComponents';
import { failureToast, successToast } from '../Common/CommonFunctions';
// Services
import ApiServices from '../../service/api/ApiServices';
const apiServices = new ApiServices();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ===========================================================================================
// ================================== List of Accounts =======================================
// ===========================================================================================

export const AccountsWise = () => {

    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [settingDialog, setSettingDialog] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedNotificationData, setSelectedNotificationData] = useState(null)
    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);

    useEffect(() => { getAccounts() }, [])

    const getAccounts = async() =>{
        const response = await apiServices.getActiveZohoUser();
        const res = response?.data?.data?.map((x)=>{ return {...x, company: x.billing_details?.company} })

        if(initialValue?.userType ==='customer'){
            const filtered = res.filter((x)=>{ return x.userid === initialValue?.userid });

            if(filtered?.length > 0){
                setAccounts(filtered)
                setSelectedCustomer(filtered[0])
                getCustomersNotifications(filtered[0])
            }

        }else{

            const sortedArr = res?.sort((a, b) => a.company.trim() !== b.company.trim() ? a.company.trim() < b.company.trim() ? -1 : 1 : 0)
            console.log(sortedArr);
            setAccounts(sortedArr)
        }

        setLoading(false)
    }

    const openSetting = (e, rowData) =>{
        setLoading1(true)
        console.log(rowData);
        setSelectedCustomer(rowData)
        getCustomersNotifications(rowData)
        setSettingDialog(true)
        setExpandedRows(null)
    }

    const getCustomersNotifications = async(data) =>{
        const contact = data?.cno.replace('+91','')
        const response = await apiServices.getNotifications({ "contact": contact });
        setSelectedNotificationData(response?.data?.response_obj);

        if(response?.data?.response_code == 1){
            const filtered = response?.data?.response_obj?.Notifications;
            setCategories(filtered)
        }else{
            failureToast(toast,response?.data?.response_message)
            setCategories([])
        }
        setLoading1(false)
    }

    const changeStatus = (e, rowData) =>{

        console.log(e.value);

        const orderedData = selectedNotificationData.Notifications?.map((x,i)=>{ return {...x, index : i+1} })
        console.log(orderedData);

        const data = orderedData?.filter((x)=>{ return x.name === rowData?.category})
        const untouchedData = orderedData?.filter((x)=>{ return x.name !== rowData?.category})

        const arr = [];
        data[0].sub_category?.forEach((x)=>{
            if(x._id === rowData?._id){
                arr.push({...x,status : e.value})
            }else{
                arr.push(x);
            }
        })

        const newArr = data?.map((x)=>{ return {...x,sub_category : arr} })
        const reOrdered = [...untouchedData,...newArr].sort((a, b) => (a.index > b.index ? 1 : -1))
        reOrdered.forEach(function(x){ delete x.index });
        const final = {...selectedNotificationData , Notifications : reOrdered}

        const info = {
            name : rowData?.name,
            flag : e.value ? 'ON' : 'OFF'
        }

        updateNotifications(final, info);
    }

    const updateNotifications = async(payload, info) =>{
        console.log(payload);
        const response = await apiServices.updateNotifications(payload);
        console.log(response?.data);
        if(response?.data?.response_code === 1){
            getCustomersNotifications(selectedCustomer);

            if(info?.name?.includes("Notification")){
                successToast(toast, `${info?.name} turned ${info?.flag} Successfully`)
            }else{
                successToast(toast, `${info?.name} Notification turned ${info?.flag} Successfully`)
            }

        }else{
            failureToast(toast, 'Failed to Change')
        }
    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                <h6>{initialValue?.userType === 'customer' ? 'Account Details' : 'List of Accounts'}</h6>
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </div>
            </span>
        </div>
    )

    const actionBodyTemplate = (rowData) =>(
        <>
            <span className="p-column-title">Actions</span>
            <Button icon="pi pi-cog" title="View Customer" className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={(e) => openSetting(e, rowData)}/>
            {/* <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} /> */}
        </>
    )


    const actionSubCategoryBodyTemplate = (rowData) =>{
        return(
            <>
                <span className="p-column-title">Actions</span>
                <InputSwitch checked={rowData?.status} onChange={(e)=>{changeStatus(e,rowData)}} />
            </>
        )
    }

    const hideSettingDialog = () =>{ setSettingDialog(false) }
    const settingFooter = commonCloseDialogFooter('Cancel', hideSettingDialog);

    const nameBodyForTable = (data, props) =>( <> <span className="p-column-title">{props.header}</span>{ `${data?.prefix} ${data?.name} ${data?.lastName}`} </> )

    const allowExpansion = (rowData) => {
        console.log(rowData);
        return rowData.sub_category.length > 0;
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <div className="p-5">
                            <h6>SubCategory</h6>
                            <DataTable value={data?.sub_category} className="datatable-responsive">
                                <Column field="id" header="Sr.No." body={srBodyTemplate}></Column>
                                <Column field="name" header="Sub Category Name" body={CommonBodyForTable}></Column>
                                <Column header="Status" body={actionSubCategoryBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const expandAll = () => {
        let _expandedRows = {};
        categories?.forEach((p) => (_expandedRows[`${p._id}`] = true));
        setExpandedRows(_expandedRows);
    };

    const collapseAll = () => { setExpandedRows(null)};

    const collapseHeader = (
        <div className='p-grid'>
            <div className='p-col-11'> <h6 className='p-mb-0 p-mt-2'>List of Notification Categories</h6> </div>
            <div className='p-col-1'>
                {
                    expandedRows ? <Button icon="pi pi-minus" className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={collapseAll}/>
                    :
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={expandAll}/>
                }
            </div>
        </div>
    );

    return (
        <>
            <Toast ref={toast} position='center' />
            <div className="p-grid crud-demo">
                
                    {
                        initialValue?.userType !== 'customer' &&
                        <>
                            <div className="p-col-12">
                            <div className="card">
                                <DataTable value={accounts}
                                    dataKey="id" paginator rows={15} rowsPerPageOptions={[5, 10, 15, 20]} className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                    globalFilter={globalFilter} emptyMessage="No Data found." header={header} loading={loading} >
                                    <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '6rem' }}></Column>
                                    <Column field="company" header="Account Name" body={CommonBodyForTable} ></Column>
                                    <Column field="Type_of_Entity" header="Type of Entity" body={CommonBodyForTable} ></Column>
                                    <Column field="Customer_Account_ID" header="Account ID" body={CommonBodyForTable} ></Column>
                                    <Column field="name" header="User Name" body={nameBodyForTable} ></Column>
                                    <Column field="cno" header="Contact Number" body={CommonBodyForTable} ></Column>
                                    <Column header="Manage Notifications" body={actionBodyTemplate}></Column>
                                </DataTable>
                            </div>
                            </div>
                        </>
                    }

                    {
                        initialValue?.userType === 'customer' &&
                        <div className="p-col-10">
                            <div className='p-fluid'>
                                <div className="card p-m-5">
                                    {
                                        categories?.length > 0 ?
                                        <DataTable className="datatable-responsive" value={categories} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate} dataKey="_id" tableStyle={{ minWidth: '60rem' }} header={collapseHeader} loading={loading1}>
                                            <Column expander={allowExpansion} style={{ width: '5rem' }} />
                                            <Column field="name" header="Category Name"/>
                                        </DataTable>
                                        :
                                        loading1 ? 
                                        <center> <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i></center> 
                                        : 
                                        <center> <h5 className='p-error p-mb-0'>Categories Not Found</h5> </center>
                                    }
                                </div>
                            </div>
                        </div>
                    }

            </div>

            <Dialog visible={settingDialog} style={{ width: "900px" }} header={`Manage Notifications (${selectedCustomer?.company})`} modal className="p-fluid" onHide={()=>{setSettingDialog(false);}} footer={settingFooter} maximizable={true} blockScroll={true} >
                <div className="card p-mb-0">
                    {
                        categories?.length > 0 ?
                        <DataTable className="datatable-responsive" value={categories} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate} dataKey="_id" tableStyle={{ minWidth: '60rem' }} header={collapseHeader} loading={loading1}>
                            <Column expander={allowExpansion} style={{ width: '5rem' }} />
                            <Column field="name" header="Category Name"/>
                        </DataTable>
                        :
                        loading1 ? 
                        <center> <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i></center> 
                        : 
                        <center> <h5 className='p-error p-mb-0'>Categories Not Found</h5> </center>
                    }
                </div>
            </Dialog>
        </>
    )
}
