import React, { useEffect, useState } from 'react'

const PMachines = () => {

    const data = [
        {
            id:1,
            name : 'swapnil',
            lastName : 'Kurhade'
        },
        {
            id:2,
            name : 'swapnil2',
            lastName : 'Kurhade2'
        },
        {
            id:3,
            name : 'swapnil3',
            lastName : 'Kurhade3'
        }
    ]

    const [useData, setUserData] = useState(data)


    useEffect(() => {
        // getData();
        getTest();
    }, [])

    const getTest = async() =>{
        
        function getStartAndEndDateOfMonth(month, year) {
            const startDate = new Date(year, month, 1).toISOString();
            const endDate = new Date(year, month + 1, 0).toISOString(); 
            return { startDate, endDate };
        }
        
        const month = 1; 
        const year = 2024;
        const { startDate, endDate } = getStartAndEndDateOfMonth(month, year);
        
        console.log("Start date:", startDate);
        console.log("End date:", endDate);

    }



    const getData = () =>{
        const apps = [
            {id:1, name:'Jon'}, 
            {id:2, name:'Dave'},
            {id:3, name:'Joe'}
          ]
          
          //remove item with id=2
          const itemToBeRemoved = {id:2, name:'Dave'}

        apps.splice(apps.findIndex((x)=> x.id === itemToBeRemoved.id),1)           
          //print result
          console.log(apps)
    }

    const handleClick = (x) =>{
        useData.splice(useData.findIndex(a => a.id === x.id) , 1)
        console.log(useData);
        setUserData([...useData]);
    }

    return (
        <>
            <ul>
                {
                    useData.map((x)=>(
                        <li onClick={()=>{handleClick(x)}} >{x.name}</li>
                    ))
                }
            </ul>
        </>
    )
}

export default PMachines