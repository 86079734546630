

export const categoryValues = [
    { name: 'All', code: 'All' },
    { name: 'CTVM', code: 'Beverage Vending Machine' },
    { name: 'Capsule Machine', code: 'Capsule Machine' }
]

export const vendingMachineBrands = [
    { name: 'Tapri', code: 'TAPRI' },
    { name: 'TKaffe', code: 'TKAFFE' }
]

export const capsuleBrands = [
    { name: 'Buddy', code: 'BUDDY' }
]

export const thirdCategoryBrands = [
    { name: 'Meri Tapri', code: 'MERI_TAPRI' }
]

export const applicationCategoryItems = [
    { name: 'Coffee/Beverage Vending', code: 'bevmachine'},
    { name: 'Capsule Machine' , code: 'capsule'},
    { name: 'Tapri Kettle' , code: 'tapriKettle'}
    // { name: 'Tapri Kettle' , code: 'Tumbler'}
];

export const applicationCategoryItems1 = [
    { name: 'Coffee/Beverage Vending', code: 'bevmachine'}
];


export const modelItems = [
    {name: 'V5'},
    {name: 'V8'},
    {name: 'V12'}
]

export const canisterItems = [
    {name: '3'},
    {name: '4'}
]

export const machineStageOptions = [
    { name: 'All', code: 'all' },
    { name: 'Testing', code: 'testing' },
    { name: 'Finished Good', code: 'finishedgoods' },
    { name: 'Demo', code:'demo' },
    { name: 'Commercial', code:'commercial' },
    { name: 'Distributor', code:'distributor' },
    { name: 'CIPL', code:'cipl' }
];

export const ownershipDropdownItems = [
    { name: 'Self'},
    { name: 'Distributor'}
];

