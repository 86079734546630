import React, { useState, useEffect, useRef, version } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import { CommonBodyForTable, DateBodyForTable, srBodyTemplate } from '../Common/CommonComponents';
import { successToast, failureToast } from '../Common/CommonFunctions';

import ApiServices from '../../service/api/ApiServices';
const apiServices = new ApiServices();

// ================================================================================
// ================================================================================

export const CvesVersions = () => {

    const [loading, setLoading] = useState(true);
    const [versionData, setVersionData] = useState(null);
    const [addVersionDialog, setAddVersionDialog] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState({});
    const [comfirmDelete, setComfirmDelete] = useState(false)
    const [uploadVersion, setUploadVersion] = useState({
        devicetype: '',
        devicemodel: '',
        appcat: 'Coffee/Beverage Vending',
        updateFor: '',
        version: '',
        files: undefined,
        filename: ''
    })
    const [deviceModel, setDeviceModel] = useState([])

    const addNewOptions = [
        { label: 'CVES', code: 'ctvm' },
        { label: 'Category', code: 'category' },
        { label: 'Device Type', code: 'deviceType' },
        { label: 'Device Model', code: 'deviceModel' },
    ]

    const deviceTypeOptions = [
        { name: 'cherise-3' },
        { name: 'Cherise-4' }
    ]

    const deviceModelOptions = [
        { name: 'V7', code: 'V7', type: 'cherees-3' },
        { name: 'V8', code: 'V8', type: 'Cherise-4' },
        { name: 'V12', code: 'V12', type: 'Cherise-4' },
        { name: 'V15', code: 'V15', type: 'Cherise-4' },
        { name: 'V24', code: 'V24', type: 'Cherise-4' },
        { name: 'V20', code: 'V20', type: 'Cherise-4' },

        { name: 'V18', code: 'V18', type: 'cherise-3' },
        { name: 'V16', code: 'V16', type: 'cherise-3' },
        { name: 'V22', code: 'V22', type: 'cherise-3' }

    ]

    const updateForOptions = [{ name: 'App', code: 'App' }, { name: 'Firmware', code: 'Firmware' }]

    const [percentage, setPercentage] = useState(0) // we will set it as zero initially



    const toast = useRef(null);

    useEffect(() => {
        const filter = deviceModelOptions.filter(x => x.type === uploadVersion?.devicetype?.name)
        setDeviceModel(filter);
    }, [uploadVersion?.devicetype])

    useEffect(() => {
        getData({})
    }, [])

    const getData = async (data) => {
        const result = await apiServices.getVersionsList();
        console.log(result?.data?.data);
        setVersionData(result?.data?.data || []);
        setLoading(false)
    }

    const handleChange = (e) => {
        setUploadVersion((state) => {
            return {
                ...state,
                [e.target.id]: e.target.value
            }
        })
    }

    const handleFileChange = (e) => {
        setUploadVersion((state) => {
            return {
                ...state,
                'files': e.target.files[0],
                'filename': [...e.target.files][0].name
            }
        })
    }



    const handleSubmit = async (e) => {
        e.preventDefault();
        let percent = 0
        const formData = new FormData();
        formData.append('appcat', uploadVersion.appcat);
        formData.append('devicetype', uploadVersion.devicetype?.name);
        formData.append('devicemodel', uploadVersion.devicemodel?.code);
        formData.append('updateFor', uploadVersion.updateFor?.code);
        formData.append('version', uploadVersion.version);
        formData.append('file', uploadVersion.files);

        const config = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                percent = Math.floor((loaded * 100) / total)
                console.log(`${loaded}kb of ${total}kb | ${percent}%`) // just to see whats happening in the console

                if (percent <= 100) {
                    setPercentage(percent) // hook to set the value of current level that needs to be passed to the progressbar
                }
            }
        }
        const response = await apiServices.uploadCvesVersion(formData, config);
        console.log("response", response?.data);
        if (response?.data?.success) {
            successToast(toast, 'Version Uploaded Successfully');
            setAddVersionDialog(false);
        } else {
            failureToast(toast, response?.data?.error || 'Failed to upload');
            setAddVersionDialog(false);
        }

        setUploadVersion({
            devicetype: '',
            devicemodel: '',
            appcat: 'Coffee/Beverage Vending',
            updateFor: '',
            version: '',
            files: undefined,
            filename: ''
        })
        setPercentage(0);
    }

    const openAddVersionDialog = () => {
        setAddVersionDialog(true);
        setUploadVersion({
            devicetype: '',
            devicemodel: '',
            appcat: 'Coffee/Beverage Vending',
            updateFor: '',
            version: '',
            files: undefined,
            filename: ''
        })
    }



    const confirmDeleteVersion = (data) => {
        setComfirmDelete(true)
        setSelectedVersion(data);
    }

    const deleteVersion = async () => {
        const data = {
            filename: selectedVersion.filename,
            type: selectedVersion.devicetype,
            model: selectedVersion.devicemodel
        }
        console.log(data);
        const response = await apiServices.deleteCvesVersion(data);
        console.log("response", response);
        if (response?.data?.type) {
            successToast(toast, 'Version Deleted Successfully')
            setComfirmDelete(false);
            setSelectedVersion(null);
            getData();
        } else {
            failureToast(toast, 'Failed to Delete')
            setComfirmDelete(false);
            setSelectedVersion(null);
        }
    }

    const htmlForTable = (data, props) => (
        <>
            <span className="p-column-title">{props.header}</span>
            <div dangerouslySetInnerHTML={{ __html: data[props.field] }} />
        </>
    )

    const actionBodyTemplate = (rowData) => (
        <>
            <span className="p-column-title">Action</span>
            <Button icon="pi pi-trash" title="Delete" className="p-button-rounded p-button-danger" onClick={() => { confirmDeleteVersion(rowData) }} />
        </>
    )

    const rightToolbarTemplate = () => (<Button className='p-custom-btn' label="Upload Version" icon="pi pi-plus-circle" iconPos="left" onClick={() => { openAddVersionDialog() }} />)

    // const leftToolbarTemplate = () => ()

    const uploadVersionDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger" onClick={() => { setAddVersionDialog(false) }} />
            <Button disabled={percentage > 0} label="Submit" type="submit" form='upload-version' icon="pi pi-plus" className="p-button-success" />
        </>
    );

    const deleteDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-success p-mr-2 p-mb-2" onClick={() => { setComfirmDelete(false) }} />
            <Button label="Delete" icon="pi pi-trash" className="p-button-danger p-mr-2 p-mb-2" onClick={() => { deleteVersion() }} />
        </>
    );


    return (
        <>
            <Toast ref={toast} position='center' />
            <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <DataTable value={versionData}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Version's" emptyMessage="No data found." loading={loading}>
                            <Column field="srid" header="Sr.No." body={srBodyTemplate} exportable={false} ></Column>
                            <Column field="devicetype" header="Device Type" sortable body={CommonBodyForTable}></Column>
                            <Column field="devicemodel" header="Model" sortable body={CommonBodyForTable}></Column>
                            <Column field="appcat" header="Application Category" sortable body={CommonBodyForTable}></Column>
                            <Column field="filename" header="Filename" sortable body={CommonBodyForTable}></Column>
                            <Column field="date" header="Date" sortable body={DateBodyForTable}></Column>
                            <Column header="Action" sortable body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            <Dialog visible={addVersionDialog} style={{ width: '950px' }} header='Upload Version' modal footer={uploadVersionDialogFooter} onHide={() => { setAddVersionDialog(false) }} maximizable={true} blockScroll={true}>
                <form onSubmit={handleSubmit} id='upload-version' className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label>Device Type</label>
                                <Dropdown id="devicetype" options={deviceTypeOptions} value={uploadVersion?.devicetype} onChange={handleChange} optionLabel="name" placeholder="Select Target Type" required></Dropdown>
                            </div>
                        </div>

                        <div className='p-col-12'>
                            <div className="p-field">
                                <label>Device Model</label>
                                <Dropdown id="devicemodel" options={deviceModel} value={uploadVersion?.devicemodel} onChange={handleChange} optionLabel="name" placeholder="Select Device Model" required></Dropdown>
                            </div>
                        </div>

                        <div className='p-col-12'>
                            <div className="p-field">
                                <label>Update For</label>
                                <Dropdown id="updateFor" options={updateForOptions} value={uploadVersion?.updateFor} onChange={handleChange} optionLabel="name" placeholder="Select Update For" required></Dropdown>
                            </div>
                        </div>


                        <div className='p-col-6'>
                            <div className="p-field">
                                <label>Application Category</label>
                                <InputText name='appcat' defaultValue={uploadVersion?.appcat} disabled autoComplete='off' />
                            </div>
                        </div>


                        <div className='p-col-6'>
                            <div className="p-field">
                                <label>Version</label>
                                <InputText id='version' defaultValue={uploadVersion?.version} onChange={handleChange} autoComplete='off' required />
                            </div>
                        </div>

                        <div className='p-col-12'>
                            <div className="p-field">
                                <label>Upload File</label>
                                <InputText type="file" id="File" name='File' onChange={handleFileChange} required />
                            </div>
                        </div>
                        {percentage > 0 && <div className='p-col-12'>
                            <div className="p-field">
                                <label>Uploading</label>
                                <ProgressBar style={{ height: '20px' }} value={percentage}></ProgressBar>
                            </div>
                        </div>}
                    </div>
                </form>
            </Dialog>

            <Dialog visible={comfirmDelete} style={{ width: '450px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={() => { setComfirmDelete(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br />
                        <span className='p-mt-2'><b>Are you sure you want to delete the selected Version?</b></span>
                    </center>
                </div>
            </Dialog>
        </>
    )
}
