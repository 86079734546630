import React,{useEffect,useState, useRef, useContext} from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Employees } from './Employees';
import { EmployeeCupConsumption } from './EmployeeCupConsumption';
import { VirtualCards } from './VirtualCards';
import { permissionContext } from '../../AppWrapper';
// Components
import { AddVirtualCards } from './AddVirtualCards';
// Common
import { successToast, failureToast } from '../Common/CommonFunctions';
// Service
import ApiServices from '../../service/api/ApiServices';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CommonBodyForTable } from '../Common/CommonComponents';
const apiServices = new ApiServices()

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ============================================================================================
// ========================================Employee Component (Tab) ===========================
// =============================================================================================

export const EmployeeMain = () => {

    const rolePermissions = useContext(permissionContext);

    const [loading, setLoading] = useState([]);
    const [allMachines, setAllMachines] = useState([]);
    const [rfidMachines, setRFIDMachines] = useState([]);
    const [selectedMachine, setSelectedMachine] = useState([]);
    const [virtualCardMach, setVirtualCardMach] = useState([]);
    const [selectedVirtualMach, setSelectedVirtualMach] = useState([]);
    const [mchEmployee, setMchEmployee] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [addNfcDialog, setAddNfcDialog] = useState(false);
    const [virtualCardDialog, setVirtualCardDialog] = useState(false);
    const [employeeConsump,setEmployeeConsump] = useState(null)
    const [employeeDialog, setemployeeDialog] = useState(false);
    const [tabIndex,setTabIndex] = useState(0);
    const [cardsData,setCardsData] = useState(null);
    const [flagForToolbar,setFlagForToolbar] = useState(false);
    const [triggerRFIDDialog,setTriggerRFIDDialog] = useState(false);
    
    const toast = useRef(null);
    const dt = useRef(null);
    
    useEffect(()=>{
        getMachines()
    },[])

    const getMachines = async() =>{

        const response = await apiServices.findMachines();
        console.log(response?.data?.data);        
        const response1 = await apiServices.getUserReduced();
        const userred = response1?.data?.data?.map((x)=>{return x.userid})
        let arr = []
        response?.data?.data.map((x)=>{if(userred.includes(x.customer)){arr.push(x)}})
        const result = arr.filter((m)=>{return m.siteName != ''});
        var resultedMachine =  result.map((x) => {
            const subid = x.subid || 'CIPL';
            const address = response1.data.data.find(o => o.userid === x.customer)
            return {
                ...x,
                displayLabel: `${x.id} / ${subid} / ${address?.billing_details?.company}`,
                address : address?.addr
            }
        });

        console.log(resultedMachine);

        const empMachArr = []
        resultedMachine.filter((x)=>{
            if(x.mchmode.paymode.onlineRfid || x.mchmode.paymode.nfc || x.mchmode.paymode.rfid){
                empMachArr.push({...x})
            }
        })

        console.log(empMachArr);
        setAllMachines(resultedMachine)
        setRFIDMachines(empMachArr)
        setSelectedMachine(empMachArr[0])
        getEmployees(empMachArr[0])
        getEmployeeConFn({filter: "chart",machineid: [empMachArr[0]?.id]})

        const virtual = resultedMachine.filter((x)=>{if(x?.mchmode?.paymode?.password){return x;}})
        setVirtualCardMach(virtual)
        setSelectedVirtualMach(virtual[0])
        console.log(virtual);
        getVirtualCards(virtual[0])
    }

    const getVirtualCards = async(data) =>{
        console.log(data);
        const response = await apiServices.getVirtualCards({machineId: data?.id})
        console.log(response?.data?.doc);
        setCardsData(response?.data?.doc)
        setLoading(false)
    }

    const getEmployeeConFn = async(data) =>{
        const res = await apiServices.getEmployeeConsumption(data);
        console.log(res);
        let total = 0;
        let arr = []
        for(let i=0;i<res?.data?.data?.length;i++){
            total += res.data.data[i].cups;
            arr.push({...res.data.data[i],total:total})
        }
        setEmployeeConsump(arr);
        arr.length > 0 ? setFlagForToolbar(true) : setFlagForToolbar(false) 
    }

    const getEmployeeConFn1 = async(data) =>{       
        const res = await apiServices.getEmployeeConsumption(data);
        console.log(res);
        let total = 0;
        let arr = []
        for(let i=0;i<res?.data?.data?.length;i++){
            total += res.data.data[i].cups;
            arr.push({...res.data.data[i],total:total})
        }
        setEmployeeConsump(arr);
    }

    const getEmployees = async(data) =>{
        const res = await apiServices.getEmployee({machineid: data?.id})
        console.log(res);
        setMchEmployee(res?.data?.doc)
        setLoading(false)
    }
 
    const openNew = () =>{ setemployeeDialog(true) }
  
    const changeMachine = (e) =>{
        console.log(e.value);
        setLoading(true)
        setSelectedMachine(e.value)
        getEmployees(e.value)
        getEmployeeConFn({filter: "chart",machineid: [e.value?.id]})
        setTabIndex(0)
    }

    const deleteEmployee = async(data) =>{
        const data1 = { rfid:data.rfid, machineid:data.machineid, cardtype:data.cardtype }
        const res = await apiServices.removeEmployee(data1)
        res.data.success ? successToast(toast,"Employee Deleted Successfully") : failureToast(toast,"Failed to Delete")
        setDeleteDialog(false)
        getEmployees(selectedMachine)
    }

    const onNfcSubmit = async(data) =>{
        const res = await apiServices.updateEmployee(data)
        res.data.success ? successToast(toast,"Employee Details Updated Successfully") : failureToast(toast,`Failed to Update , ${res?.data?.msg}`)
        getEmployees(selectedMachine)
    }

    const openTrigger = () =>{ setTriggerRFIDDialog(true) }
    const hideTriggerDialog = () =>{ setTriggerRFIDDialog(false) }

    // const createTrigger = (status) =>{
    //     if(status == 'add'){
    //         triggerSubmit({ "id": selectedMachine?.id })
    //     }else{
    //         triggerSubmit({ "id": selectedMachine?.id, "updateCard": true})
    //     }
    // }

    const createTrigger = (status) => {
        const triggerData = { "id": selectedMachine?.id };
        if (status !== 'add') { triggerData.updateCard = true; };
        triggerSubmit(triggerData);
    };

    const triggerSubmit = async(data) =>{
        const res = await apiServices.triggerRfidCards(data);
        res?.data?.success ? successToast(toast,'Rfid triggered Successfully') : failureToast(toast, 'Failed to Trigger');
    }

    const exportCSV = () => { dt.current.exportCSV(); }

    const leftToolbarTemplate = () =>{
        return (
            <div className="p-inputgroup" style={{maxWidth:'70%'}}>
                <Button className='custom-group-btn' icon="pi pi-download" label="RFID CVES" onClick={exportCSV} />
                <Dropdown id='select-access' value={selectedMachine} options={rfidMachines} onChange={changeMachine} optionLabel="displayLabel" placeholder="Select CVES" filter className="p-mr-2 multiselect-custom" />
            </div>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                {/* <Button label="Export" icon="pi pi-upload" className="p-button-help p-button-rounded p-mr-2" onClick={exportCSV} /> */}
                { (rolePermissions?.addEmployee && rfidMachines?.length > 0) && <Button label="Add New Employee" icon="pi pi-plus-circle" className="p-custom-btn p-mt-1" onClick={openNew} /> }

                {
                    (rolePermissions?.triggerRfid && rfidMachines?.length > 0) &&
                    <Button label="Trigger RFID" icon="pi pi-upload" className="p-custom-btn p-ml-2" onClick={openTrigger}/>
                }
            </>
        )
    }

    const triggerDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className=" p-button-danger p-mr-2 p-mb-2" onClick={hideTriggerDialog} />
            <Button label="Create Trigger" icon="pi pi-plus-circle" className=" p-button-success p-mr-2 p-mb-2" onClick={()=>{createTrigger('add')}} />
            <Button label="Update Trigger" icon="pi pi-upload" className=" p-button-success p-mr-2 p-mb-2" onClick={()=>{createTrigger('update')}}/>
        </>
    );

    return (

        <>
            <TabView >

                {
                    initialValue?.tabChecker?.rfidCheck ? 
                    <TabPanel header="Employees">
                        <div className="p-grid crud-demo">
                            <div className="p-col-12">
                            <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={tabIndex==0?rightToolbarTemplate:null}></Toolbar>
                            <div className="card">
                                <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}>
                                <TabPanel header="List of Employees">
                                    <Employees 
                                        loading={loading} 
                                        mchEmployee={mchEmployee} 
                                        toast={toast} 
                                        deleteDialog={deleteDialog} 
                                        addNfcDialog={addNfcDialog} 
                                        deleteEmployee={deleteEmployee} 
                                        onNfcSubmit={onNfcSubmit} 
                                        allMachines={allMachines} 
                                        employeeDialog={employeeDialog} 
                                        setemployeeDialog={setemployeeDialog} 
                                        // success={success} 
                                        // failure={failure} 
                                        getEmployees={getEmployees} 
                                        selectedMachine={selectedMachine} 
                                    />
                                </TabPanel>
                                <TabPanel header="Cup Consumption">
                                    <EmployeeCupConsumption 
                                        flagForToolbar={flagForToolbar} 
                                        mchEmployee={mchEmployee} 
                                        selectedMachine={selectedMachine} 
                                        employeeConsump={employeeConsump} 
                                        getEmployeeConFn={getEmployeeConFn1} 
                                    />
                                </TabPanel>
                                </TabView>
                            </div>
                            </div>
                        </div>
                    </TabPanel>
                    :
                    <TabPanel header=""></TabPanel>
                }
                
                {
                    initialValue?.tabChecker?.passwordCheck ? 
                    <TabPanel header="Virtual Cards">
                        <div className='p-grid crud-demo'>
                            <div className='p-col-12'>
                                <VirtualCards 
                                    selectedMachine={selectedVirtualMach} 
                                    toast={toast} 
                                    virtualCardMach={virtualCardMach} 
                                    // success={success} 
                                    // failure={failure} 
                                    cardsData={cardsData} 
                                    getVirtualCards={getVirtualCards} 
                                />
                            </div>
                        </div>
                    </TabPanel>
                    :
                    <TabPanel header=""></TabPanel>
                }

            </TabView>

            <AddVirtualCards 
                virtualCardDialog={virtualCardDialog} 
                setVirtualCardDialog={setVirtualCardDialog} 
                virtualCardMach={virtualCardMach} 
                allMachines={allMachines} 
            />

            <Dialog visible={triggerRFIDDialog} style={{ width: '450px' }} header="Confirm" modal footer={triggerDialogFooter} onHide={hideTriggerDialog} blockScroll={true}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br /><br />
                        <b><span>Are you sure you want trigger Create/Update RFID's.?</span></b> <br /><br />
                    </center>
                </div>
            </Dialog>

            <DataTable style={{display:'none'}} ref={dt} value={rfidMachines} dataKey="id" exportFilename="RFID Machines Data"  className="datatable-responsive" emptyMessage="No data found.">
                <Column field="id" header="CVES Id"  body={CommonBodyForTable}></Column>
                <Column field="subid" header="Sub Id"  body={CommonBodyForTable}></Column>
                <Column field="siteName" header="Site Name" body={CommonBodyForTable}></Column>
            </DataTable>

        </>
    );
}







            {/* <div className="p-grid crud-demo">
                <div className="p-col-12">
                <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={tabIndex==0?rightToolbarTemplate:null}></Toolbar>
                <div className="card">
                    <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}>
                    <TabPanel header="Employees">
                        <Employees loading={loading} mchEmployee={mchEmployee} toast={toast} deleteDialog={deleteDialog} addNfcDialog={addNfcDialog} deleteEmployee={deleteEmployee} onNfcSubmit={onNfcSubmit} allMachines={allMachines} employeeDialog={employeeDialog} setemployeeDialog={setemployeeDialog} success={success} failure={failure} getEmployees={getEmployees} selectedMachine={selectedMachine} />
                    </TabPanel>
                    <TabPanel header="Cup Consumption">
                        <EmployeeCupConsumption mchEmployee={mchEmployee} selectedMachine={selectedMachine} employeeConsump={employeeConsump} getEmployeeConFn={getEmployeeConFn} />
                    </TabPanel>
                    </TabView>
                </div>
                </div>
            </div> */}