import React from "react";
import { TabPanel } from "primereact/tabview";
import { TabView } from "primereact/tabview";
// Components
import { BeverageTab } from "./BeverageTab";
import { ProductMasterTab } from "./ProductMasterTab";
import { ProductionBatch } from "./ProductionBatch";
import { ProductMasterNewTab } from "./ProductMasterNewTab";

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// =========================================================================================================
// =========================================================================================================

export const FgBeverage = () =>{

    return (    
        <>
            <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">

                    {
                        initialValue?.userType==='formulation' &&
                        <TabView>
                            <TabPanel header="Product Master">
                                <ProductMasterTab/>
                            </TabPanel>
                            <TabPanel header="Production Batch">
                                <ProductionBatch/>
                            </TabPanel>
                        </TabView>
                    }

                    {
                        (initialValue?.userType=='operation' || initialValue?.userType=='techqc' || initialValue?.userType=='inventory' ) &&
                        <TabView>
                            <TabPanel header="Beverages">
                                <BeverageTab/>
                            </TabPanel>
                            <TabPanel header="Production Batch">
                                <ProductionBatch/>
                            </TabPanel>
                        </TabView>
                    }

                    {
                        (initialValue?.userType=='techops') &&
                        <TabView>
                            <TabPanel header="Beverages">
                                <BeverageTab/>
                            </TabPanel>
                        </TabView>
                    }

                    {
                        initialValue?.userType=='owner' &&
                        <TabView>
                            <TabPanel header="Beverages">
                                <BeverageTab/>
                            </TabPanel>
                            <TabPanel header="Product Master">
                                <ProductMasterTab/>
                            </TabPanel>
                            {/* <TabPanel header="Product Master (Updated)">
                                <ProductMasterNewTab/>
                            </TabPanel> */}
                            <TabPanel header="Production Batch">
                                <ProductionBatch/>
                            </TabPanel>
                        </TabView>
                    }
                    
                </div>
            </div>
            </div>
        </>
    )

}








































