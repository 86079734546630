import React from "react";
import { Chart } from "primereact/chart";
import { AnalyticsStat } from "../Analytics/AnalyticStat";

const countryChart = {
    labels: ['Total', 'Completed', 'Other'],
    datasets: [
        {
            data: [30, 45, 25],
            backgroundColor: [
                '#FC6161',
                '#00D0DE',
                '#873EFE',
            ],
            hoverBackgroundColor: [
                '#FC6161',
                '#00D0DE',
                '#873EFE',
            ],
            borderColor: 'transparent',
            fill: true
        }
    ]
};

const countryChartOptions = {
    responsive: true
};

const statastics = [
    {header : 'Top Lead Source', value: 'Other', icon : 'pi pi-th-large'},
    {header : 'Top Performing Superman', value: '-', icon : 'pi pi-th-large'},
    {header : 'Top Performing Machine', value: '-', icon : 'pi pi-th-large'},
    {header : 'Top Performing Beverage', value: 'Madras Coffee', icon : 'pi pi-th-large'},
    {header : 'Top Machine On-boarding Owner', value: '-', icon : 'pi pi-th-large'},
    {header : 'Top Benificial Location', value: '-', icon : 'pi pi-th-large'},
]


export const CrmAnalytics = () =>{
    return (       
        <>
            <div className="layout-dashboard">

                {/* <AnalyticsStat statastics={statastics}/> */}

            </div>

            <div className="p-grid p-fluid">
                <div className="p-col-12 p-md-4">
                <div className="card widget-country-graph">
                    <div className="country-title">CRM Analysis (Task)</div>
                    <div className="country-graph p-d-flex p-jc-center">
                        <Chart type="doughnut" id="country-chart" data={countryChart} options={countryChartOptions} style={{ position: 'relative', width: '75%' }}></Chart>
                    </div>
                    <div className="country-content">
                        <ul>
                            <li className="p-d-flex p-jc-between p-ai-center">
                                <div className="p-d-flex p-jc-between p-ai-center">
                                    <div className="color" style={{ backgroundColor: '#00D0DE', boxShadow: '0px 0px 10px rgba(0, 208, 222, 0.3)' }}></div>
                                    <span>Completed</span>
                                </div>
                                <span>45%</span>
                            </li>
                            <li className="p-d-flex p-jc-between p-ai-center">
                                <div className="p-d-flex p-jc-between p-ai-center">
                                    <div className="color" style={{ backgroundColor: '#873EFE', boxShadow: '0px 0px 10px rgba(135, 62, 254, 0.3)' }}></div>
                                    <span>Other</span>
                                </div>
                                <span>25%</span>
                            </li>
                            <li className="p-d-flex p-jc-between p-ai-center">
                                <div className="p-d-flex p-jc-between p-ai-center">
                                    <div className="color" style={{ backgroundColor: '#FC6161', boxShadow: '0px 0px 10px rgba(252, 97, 97, 0.3)' }}></div>
                                    <span>Total</span>
                                </div>
                                <span>30%</span>
                            </li>
                        </ul>
                    </div>
                </div>
                </div>

                <div className="p-col-12 p-md-8">
                    <div className="card">
                        <div className="card-header">
                            <h6>User Dashboard</h6>
                        </div>
                        <div className="p-m-5">
                        <div className="p-grid">
                            <div className="p-col-3"> 
                                <img src="assets/layout/images/dashboard/gene.png" alt="atlantis" className="user-image" />
                            </div>
                            <div className="p-col-9"> 
                                <h5>Cherise (India) Pvt. Ltd. PUNE</h5>

                                <h5>Total Machine On-Boarded : abc </h5>
                                <h5>Total Ontime Service & Support : xyz</h5>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
        
        </>

    )
}
