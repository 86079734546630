import React, { useEffect, useRef, useState, useContext } from 'react';
import ApiServices from '../../service/api/ApiServices';
import CommonCal from '../../service/common/CommonCal';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { CommonBodyForTable, DateBodyForTable, NumberCommonBodyForTable, srBodyTemplate } from '../Common/CommonComponents';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { exportReport, failureToast, infoToast, successToast } from '../Common/CommonFunctions';
import { Dropdown } from 'primereact/dropdown';
import { brandsOptions, activityStatusOptions, categoryOptions, filterOptions, sortingOptions } from './Constants';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from "primereact/toast";
import { InputTextarea } from 'primereact/inputtextarea';
import { permissionContext } from '../../AppWrapper';
import { brandOptions } from '../FinishedGood/Constants';
const apiServices = new ApiServices();
const commonCal = new CommonCal();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// =================================================================================================
// =================================================================================================

export const DayWiseCVESPerformance = () => {

    const durationOptions = [
        { name: 'All', code: 'all' },
        { name: 'Today', code: 'today' },
        { name: 'Yesterday', code: 'yesterday' },
        { name: 'This Week', code: 'this_week' },
        { name: 'This Month', code: 'this_month' },
        { name: 'Last Month', code: 'last_month' },
        // { name: 'Custom', code: 'custom' }
    ]

    const rolePermissions = useContext(permissionContext);

    const [loading, setLoading] = useState(true)
    const [globalFilter, setGlobalFilter] = useState('');
    const [dates, setDates] = useState(null)
    const [dayWiseData, setDayWiseData] = useState(null)
    const [allMaterials, setAllMaterials] = useState(null)
    // const [selectedDuration, setSelectedDuration] = useState({ name: 'This Month', code: 'this_month' })
    const [selectedDuration, setSelectedDuration] = useState(durationOptions[1])
    const [customDateDialog, setCustomDateDialog] = useState(false)
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    const [filterType, setFilterType] = useState(filterOptions[0]);
    const [usersData, setUsersData] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedbrand, setSelectedBrand] = useState(brandsOptions)
    const [btnVisibility, setBtnVisibility] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState([{ name: 'Active', code: 'ACTIVE' }])
    const [feedbackDialog, setFeedbackDialog] = useState(null)
    const [feedbackData, setFeedbackData] = useState([])
    const [addFeedbackDialog, setAddFeedbackDialog] = useState(false)
    const [isFromDate, setIsFromDate] = useState(false)
    const [allAccounts, setAllAccounts] = useState(null)
    const [allFacilities, setAllFacilities] = useState(null)
    const [machineOptions, setMachineOptions] = useState(null)
    const [selectedAccount, setSelectedAccount] = useState(null)
    const [selectedFacility, setSelectedFacility] = useState(null)
    const [selectedMachineOption, setSelectedMachineOption] = useState(null)
    const [feedbackPayload, setFeedbackPayload] = useState({})
    const [selectedSortBy, setSelectedSortBy] = useState(sortingOptions[0])
    const [selectedMachine, setSelectedMachine] = useState(null)

    const [updateFeedbackDialog, setUpdateFeedbackDialog] = useState(false)
    const [selectedFeedback, setSelectedFeedback] = useState(null)
    const [updateFeedPayload, setUpdateFeedPayload] = useState({})
    const [selectedFeedCategory, setSelectedFeedCategory] = useState(null)
    const [subscriptionStatus, setSubscriptionStatus] = useState('ACTIVE')
    const [todayDate, setTodayDate] = useState(null)
    const [addCategoryDialog, setAddCategoryDialog] = useState(false);
    
    const [addCategoryPayload, setAddCategoryPayload] = useState({});
    const [selectedCatBrand, setSelectedCatBrand] = useState(null)
    const [feedbackCategoryOptions, setFeedbackCategoryOptions] = useState(null)

    const dataRef = useRef(null);
    const toast = useRef();

    useEffect(()=>{
        getDates()
        getUsers()
        getAccounts()
        getToday()
        getFeedbackcategory()
    },[])

    const getToday = () => {
        const isoDate = new Date().toISOString();
        const date = new Date(isoDate);
        const day = date.getUTCDate();
        const month = date.getUTCMonth() + 1; 
        const year = date.getUTCFullYear();
        const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
        console.log(formattedDate); 
        setTodayDate(formattedDate)
    }

    const getFeedbackcategory = async() =>{
        const response = await apiServices.getFeedbackCategory({});
        console.log(response?.data?.data?.category);
        const data = response?.data?.data?.category?.map((x)=>{
            return {"name" : x , "code": x}
        })
        setFeedbackCategoryOptions(data)
    }

    const getUsers = async() =>{
        const response = await apiServices.getUserReduced();
        setUsersData(response?.data?.data)
    }

    const getDates = async() =>{
        const response = await commonCal.getDates()
        setDates(response)
        setSelectedDate({ date : { startDate: response.startToday, endDate: new Date() } })
        getData({ subscription_status : subscriptionStatus, date : { startDate: response.startToday, endDate: new Date() } })

        // setSelectedDate({ date : { startDate: response.monthStart, endDate: response.endThisMonth }})
        // getData({ date : { startDate: response.monthStart, endDate: response.endThisMonth }})
    }

    const getData = async(data) =>{
        setLoading(true)

        if(data?.accountWise){  

            const response = await apiServices.dayWiseConsumption(data);
            console.log(response?.data?.data);

            const newData = response?.data?.data?.map((x)=>{
                const userid = x.customer;
                return { ...x ,userName : usersData?.find(y => y.userid === userid)?.billing_details?.company || userid}
            })

            console.log(newData);
            // console.log(usersData);

            const fildata = newData?.map((x)=>{ return  { id :  x.id.toString(), Account_Name : x.userName , [x.date] : x.cups}  })

            console.log(fildata);

            const concatenatedArray = fildata?.reduce((acc, obj) => {
                const existingObj = acc.find(item => item.Account_Name === obj.Account_Name);
                if (existingObj) {
                    Object.assign(existingObj, obj );
                } else {
                    acc.push(obj);
                }
                return acc;
            }, []);

            console.log(concatenatedArray);

            const keysToExclude = ['id', 'Account_Name'];

            const datata = concatenatedArray.map((x)=>{
                const sum =  Object.keys(x).reduce((sum, key) => {
                    if (!keysToExclude.includes(key)) {
                        return sum + x[key];
                    }
                    return sum;
                }, 0);

                return {...x, Total : sum}

            })

            let filteredData;
            if(selectedSortBy?.code == 'desc'){
                filteredData = datata.sort((a, b) => b.Total - a.Total);
            }else if(selectedSortBy?.code == 'asc'){
                filteredData = datata.sort((a, b) => a.Total - b.Total);
            }   


            setDayWiseData(filteredData)

            const keysArray = filteredData.map(obj => Object.keys(obj));
            const allKeys = keysArray.flat().filter((x)=>{ return x !== 'Total' && x !== 'id' });
            const uniqueKeys = [...new Set(allKeys)];
            console.log(uniqueKeys);

            const newDates = uniqueKeys.sort( function(c,d){
                const rx = /(\d+)\-(\d+)\-(\d+)/;
                const a = Number(c.replace(rx, '$3$1$20000'));
                const b = Number(d.replace(rx, '$3$1$20000'));
                return a > b ? -1 : a == b ? 0 : 1; 
            });

            newDates.splice(1,0,"Total");

            const firstData = newDates.slice(0, 2);
            const lastData = newDates.slice(2);
            const sortedArr = getDateSorted(lastData);
            const concatRows = [...firstData,...sortedArr];
            setAllMaterials(concatRows)

        }else{

            const response = await apiServices.dayWiseConsumption(data);
            console.log(response?.data?.data);

            // const res = await apiServices.getAllFeedbackData()
            // console.log(res);
            // 2023-12-05T11:08:06.327Z 05-12-2023

            const fildata = response?.data?.data?.map((x)=>{ 
                return  { 
                    id :  x.id,
                    Company_Name : x.siteName, 
                    Customer_Id : x.customer, 
                    Facility_Address : x.facility_address, 
                    State : x.facility_state, 
                    City : x.facility_city, 
                    Email : x.email,
                    Contact : x.cno,
                    KAM : x.accountmanager_name + " " + x.accountmanager_lastname + " / " + x.accountmanager_number,
                    [x.date] : x.cups
                }  
            })

            const concatenatedArray = fildata?.reduce((acc, obj) => {
                const existingObj = acc.find(item => item.id === obj.id);
                if (existingObj) {
                    Object.assign(existingObj, obj );
                } else {
                    acc.push(obj);
                }
                return acc;
            }, []);

            console.log(concatenatedArray);

            const keysToExclude = ['id', 'Company_Name', 'Customer_Id','Facility_Address','State','City', 'Contact', 'Email','KAM'];

            const datata = concatenatedArray?.map((x)=>{
                const sum =  Object.keys(x).reduce((sum, key) => {
                    if (!keysToExclude.includes(key)) {
                      return sum + x[key];
                    }
                    return sum;
                }, 0);

                return {...x, Total : sum}
            })

            console.log(datata);

            let filteredData;
            if(selectedSortBy?.code == 'desc'){
                filteredData = datata.sort((a, b) => b.Total - a.Total);
            }else if(selectedSortBy?.code == 'asc'){
                filteredData = datata.sort((a, b) => a.Total - b.Total);
            }   

            setDayWiseData(filteredData)

            const keysArray = filteredData.map(obj => Object.keys(obj));
            const allKeys = keysArray.flat().filter((x)=>{ return x !== 'Total' && x !== 'id' });
            // const allKeys = keysArray.flat().filter((x)=>{ return x !== 'id' });

            const uniqueKeys = ['id',...new Set(allKeys)];
            console.log(uniqueKeys);

            const newDates = uniqueKeys.sort( function(c,d){
                const rx = /(\d+)\-(\d+)\-(\d+)/;
                const a = Number(c.replace(rx, '$3$1$20000'));
                const b = Number(d.replace(rx, '$3$1$20000'));
                return a > b ? -1 : a == b ? 0 : 1; 
            });

            console.log(newDates);
            newDates.splice(9,0,"Total");

            const firstData = newDates.slice(0, 10);
            const lastData = newDates.slice(10);
            const sortedArr = getDateSorted(lastData);
            const concatRows = [...firstData,...sortedArr];

            setAllMaterials(concatRows)
        }

        setLoading(false)
    }


    const getAccounts = async() =>{
        const response = await apiServices.getActiveZohoUser();
        const data = response?.data?.data?.map((x)=>{ return {...x, company : x?.billing_details?.company ? x?.billing_details?.company : 'CIPL' } })
        const ordered =  data.sort((a, b) => a?.company?.trim().localeCompare(b?.company.trim()))
        console.log(ordered);
        setAllAccounts(ordered)
    }


    const getDateSorted = (dates) =>{
            // Convert date strings to Date objects
            let formattedDates = dates.map(date => {
                let parts = date.split('-');
                return new Date(parts[2], parts[1] - 1, parts[0]);
            });
            
            // Sort the dates
            formattedDates.sort((a, b) => a - b);
            
            // Convert back to the original format
            let sortedDates = formattedDates.map(date => {
                let day = date.getDate().toString().padStart(2, '0');
                let month = (date.getMonth() + 1).toString().padStart(2, '0');
                let year = date.getFullYear();
                return `${day}-${month}-${year}`;
            });
            
            return sortedDates.reverse();
    }


    const changeDuration = (e) =>{
        
        e.target.value.code != 'custom' && setSelectedDuration(e.target.value);
        // e.target.value.code != 'custom' && setLoading(true);
        e.target.value.code != 'custom' && setBtnVisibility(true);
        
        const today = { startDate: dates.startToday, endDate: new Date() };
        const yesterday = { startDate: dates.YestStart, endDate:dates.EndStart };
        const thisWeek = { startDate: dates.weekstart, endDate:dates.weekEnd };
        const thisMonth = { startDate: dates.monthStart, endDate:dates.endThisMonth };
        const lastMonth = { startDate: dates.lastStart, endDate:dates.lastend };
        const filteredBrand = selectedbrand.map((x)=>{ return x.code })
        
        switch(e.target.value.code) {
            case 'all':
                // getData({})
                // getData({ brand : filteredBrand, accountWise : filterType.code == 'accountWise' ? true : false })
                break;
            case 'today':
                setSelectedDate({ date : today})
                // getData({ brand : filteredBrand, accountWise : filterType.code == 'accountWise' ? true : false, date : today })
                break;
            case 'yesterday':
                setSelectedDate({ date : yesterday})
                // getData({ brand : filteredBrand, accountWise : filterType.code == 'accountWise' ? true : false, date : yesterday })
                break;
            case 'this_week':
                setSelectedDate({ date : thisWeek})
                // getData({ brand : filteredBrand, accountWise : filterType.code == 'accountWise' ? true : false, date : thisWeek })
                break;
            case 'this_month':
                setSelectedDate({ date : thisMonth})
                // getData({ brand : filteredBrand, accountWise : filterType.code == 'accountWise' ? true : false, date : thisMonth })
                break;
            case 'last_month':
                setSelectedDate({ date : lastMonth})
                // getData({ brand : filteredBrand, accountWise : filterType.code == 'accountWise' ? true : false, date : lastMonth })
                break;
            case 'custom':
                setCustomDateDialog(true)
                break;
            default:
                getData({ brand : filteredBrand, accountWise : filterType.code == 'accountWise' ? true : false })
          }
    }

    const changeSortBy = (e) =>{
        setSelectedSortBy(e.target.value)
        setBtnVisibility(true)
    }

    const changeBrand = (e) =>{
        
        if(e.value?.length > 0){
            setBtnVisibility(true)
            setSelectedBrand(e.value)
            // const filteredData = e.value.map((x)=>{ return x.code })
            // getData({ 
            //     brand : filteredData,
            //     accountWise : filterType.code == 'accountWise' ? true : false,
            //     ...selectedDate
            // })
        }
    }

    const changeStatus = (e) =>{
        console.log(e.value);
        
        setBtnVisibility(true)
        if(e.value?.length == 1){
            setSubscriptionStatus(e.value[0].code)
            setSelectedStatus(e.value)
        }else if(e.value?.length >= 2){
            setSubscriptionStatus('ALL')
            setSelectedStatus(activityStatusOptions)
        }
    }

    const changeWise = (e) =>{
        setBtnVisibility(true)
        setFilterType(e.target.value)
        // const filteredData = selectedbrand.map((x)=>{ return x.code })
        // getData({ brand : filteredData, accountWise : e.target.value.code == 'accountWise' ? true : false,...selectedDate})
    }

    const filterData = () =>{
        const filteredData = selectedbrand.map((x)=>{ return x.code })
        getData({ brand : filteredData, subscription_status : subscriptionStatus, accountWise : filterType.code == 'accountWise' ? true : false,...selectedDate})
        setBtnVisibility(false)
    }

    const tableStyle={ overflowX: 'scroll', width:'100%' };

    const hideCustomDialog = () =>{
        setLoading(false)
        setCustomDateDialog(false)
        setBtnVisibility(false)
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
        setCustomDateDialog(false)
        setSelectedDuration({ name: 'Custom', code: 'custom' });
        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        let cust_start = new Date(startt).toISOString();
        let cust_end = new Date(endd).toISOString();
        const date = { startDate: cust_start, endDate:cust_end };
        setSelectedDate({ date : date})
        // getData({ date })
        const filteredData = selectedbrand.map((x)=>{ return x.code })
        getData({ brand : filteredData, subscription_status : subscriptionStatus, accountWise : filterType.code == 'accountWise' ? true : false, date })
    }

    const getModelData = (data, inputDate) =>{
        console.log(data);
        console.log(inputDate);

        setSelectedMachine(data)

        const [day, month, year] = inputDate.split('-');
        const daterr = new Date(`${year}-${month}-${day}`);

        const start = new Date(daterr.setDate(daterr.getDate())).setHours(0,0,0,0)
        const end = new Date(daterr.setDate(daterr.getDate())).setHours(23, 59, 59, 0);
        const startDate = new Date(start).toISOString();
        const endDate = new Date(end).toISOString();

        const payload = {
            "machineId": data.id,
            "date" : { startDate : startDate, endDate : endDate }
        }

        getFeedback(payload, inputDate, data)
    }

    const getFeedback = async(payload, inputDate, rowData) =>{
        const response = await apiServices.getAllFeedbackData(payload);
        if(response?.data?.data?.length > 0){
            setFeedbackDialog(true)
            setFeedbackData(response?.data?.data)
        }else{

            const rowIndex = allMaterials.indexOf(inputDate);
            if(rowIndex == 10 && (inputDate == todayDate) ){
                // console.log(todayDate);
                setAddFeedbackDialog(true)
                setIsFromDate(true)

                setFeedbackPayload({
                    "customer": rowData?.Customer_Id,
                    "compnay_name": rowData?.Company_Name,
                    "machineId": rowData?.id
                })

            }else{
                failureToast(toast, 'No feedbacks available')
            }
        }
    }

    const openFeedback = () =>{
        setAddFeedbackDialog(true)
        setIsFromDate(false)
    }

    const openCategory = () =>{
        console.log('add Category');
        setAddCategoryDialog(true)
    }

    const customFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
            <button className="p-button p-button-success" type="submit" form="custom-form" ><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
        </>
    )

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                {/* <h6 className="p-mt-3 p-mr-5">Day Wise Performance</h6> */}
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button className='custom-group-btn p-button-rounded p-m-2' onClick={()=>{exportReport(dataRef)}} icon="pi pi-download"></Button>
                    {/* <Button className='p-custom-btn' label="Add Feedback" icon="pi pi-plus-circle" iconPos="left" onClick={openFeedback} /> */}
                </div>
            </span>
        </div>
    )

    const leftToolbarTemplate = () =>(
        <>
            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="BRAND" />
                <MultiSelect name='brand' value={selectedbrand} onChange={changeBrand} options={brandsOptions} optionLabel="name" display="chip" placeholder="Select Brand" maxSelectedLabels={3} filter  />
                {/* <Dropdown value={selectedbrand} options={brandsOptions} onChange={changeBrand} optionLabel="name" filter className="p-m-0 multiselect-custom"/> */}
            </div>
            {
                filterType?.code == 'cvesWise' &&
                <div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' label="STATUS" />
                    <MultiSelect name='brand' value={selectedStatus} onChange={changeStatus} options={activityStatusOptions} optionLabel="name" display="chip" placeholder="Select Status" maxSelectedLabels={3} filter  />
                </div>
            }
            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="FILTER BY" />
                <Dropdown value={filterType} options={filterOptions} onChange={changeWise} optionLabel="name" filter className="p-m-0 multiselect-custom"/>
            </div>
        </>
    )

    const rightToolbarTemplate = () => (
        <>
            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="DURATION" />
                <Dropdown value={selectedDuration} options={durationOptions} onChange={changeDuration} optionLabel="name" placeholder="Select Duration" filter className="p-m-0 multiselect-custom"/>
            </div>

            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="SORT BY" />
                <Dropdown value={selectedSortBy} options={sortingOptions} onChange={changeSortBy} optionLabel="name" filter className="p-m-0 multiselect-custom"/>
            </div>

            <div className="p-inputgroup p-m-1">
                {
                    btnVisibility && <Button icon="pi pi-filter" label='Filter' className="custom-group-btn p-button p-my-2 p-mx-2" onClick={filterData} />
                }
            </div>
        </>
    )

    const leftFeedbackToolbarTemplate = () =>(
        <span className="p-input-icon-right">
            <h6 className="p-mt-3 p-mr-5">Day Wise Cup Consumption Performance (Commercial)</h6>
        </span>
    )

    const rightFeedbackToolbarTemplate = () =>(
        rolePermissions?.addFeedback && <Button className='p-custom-btn' label="Add Feedback" icon="pi pi-plus-circle" iconPos="left" onClick={openFeedback} />
    )

    const addCategoryToolbar = () =>(
        <Button className='p-custom-btn' label="Add Category" icon="pi pi-plus-circle" iconPos="left" onClick={openCategory} />
    )

    const NumberBtnCommonBodyForTable = (data, props) =>{
        return ( 
            <> 
                <span className="p-column-title">{props.header}</span>
                <span style={{cursor : 'pointer', padding : '10px', textDecoration: 'underline'}} onClick={()=>{getModelData(data, props.field)}}>{data[props.field] ? data[props.field] : '0'}</span>
            </> 
        )
    }
    
    const hideFeedbackFooter = () =>{ setFeedbackDialog(false) }
    const hideDialog = () =>{ 
        setAddFeedbackDialog(false) 
        setFeedbackPayload({})
        setSelectedAccount(null)
        setSelectedFacility(null)
        setSelectedMachineOption(null)
        // setAllAccounts(null)
        setAllFacilities(null)
        setMachineOptions(null)
        setSelectedFeedCategory(null)
    }

    const hideEditDialog = () =>{
        setUpdateFeedbackDialog(false)
    }

    const hideCatDialog = () =>{
        setAddCategoryDialog(false)
        setSelectedCatBrand(null)
        setAddCategoryPayload({})
    }

    const changeFeed = (e) =>{

        if(e.target.name == 'compnay_name'){
            console.log(e.value);
            setSelectedAccount(e.value)
            callfacility(e.value)
            setFeedbackPayload({...feedbackPayload, [e.target.name] : e.value?.company, customer : e.value?.userid })
        }
        
        if(e.target.name == 'facility'){
            setSelectedFacility(e.value)
            getMachineByFac(e.value)
        }
        
        if(e.target.name == 'machineId'){
            console.log(e.value);
            setSelectedMachineOption(e.value)
            setFeedbackPayload({...feedbackPayload, [e.target.name] : e.value?.id })
        }

        if(e.target.name == 'feedback_message'){
            setFeedbackPayload({...feedbackPayload, [e.target.name] : e.target.value })
        }

        if(e.target.name == 'feedback_category'){
            setSelectedFeedCategory(e.value)
            setFeedbackPayload({...feedbackPayload, [e.target.name] : e.value.name })
        }
    }

    const changeEdit = (e) =>{
        if(e.target.name == 'feedback_message'){
            setUpdateFeedPayload({...updateFeedPayload, [e.target.name] : e.target.value })
        }
    }

    const changeCat = (e) =>{
        if(e.target.name == 'brand'){
            setSelectedCatBrand(e.value);
            const mapped = e.value.map((x)=>( x.code ));
            setAddCategoryPayload({...addCategoryPayload, [e.target.name] : mapped })
        }

        if(e.target.name == 'category'){
            setAddCategoryPayload({...addCategoryPayload, [e.target.name] : e.target.value })
        }
    }

    const callfacility = async(data)=>{
        const response = await apiServices.getFacility({ userid: data?.userid });
        const arr = [];
        response?.data?.data?.forEach((x)=>{ if(x?.facilityZohoId){ arr.push(x) } })
        setAllFacilities(arr)
    }


    const getMachineByFac = async(data)=>{
        const payload = { facid: data?.facid, userType : 'customer', userid : data?.userid }
        const response = await apiServices.getMachineByFacid(payload);
        console.log(response?.data?.data);
        if(response?.data?.data?.length > 0){
            setMachineOptions(response?.data?.data)
        }else{
            infoToast(toast,'No CVES available under selected facility', 'No CVES Found')
            setMachineOptions(null)
        }
    }

    const submitFeedback = async(e) =>{
        e.preventDefault()
        setAddFeedbackDialog(false)

        const payload = {
            ...feedbackPayload,
            "login_userid": initialValue?.userid,
            "login_username": initialValue?.name,
            "login_lastName": initialValue?.lastName,
            "login_userType": initialValue?.userType
        }

        console.log(payload);
        const response = await apiServices.addFeedbackData(payload);
        console.log(response?.data);
        response?.data?.success ? successToast(toast,'Feedback Added Successfully') : failureToast(toast,response?.data?.error)
        hideDialog()
    }

    const openEdit = (data) =>{
        console.log(data);
        setSelectedFeedback(data)
        setUpdateFeedPayload({ _id : data._id, feedback_message : data.feedback_message })
        setUpdateFeedbackDialog(true)
    }

    const updateFeedback = async(e) =>{
        e.preventDefault();
        console.log(updateFeedPayload);
        const response = await apiServices.updateFeedbackData(updateFeedPayload);
        if(response?.data?.success){
            successToast(toast,'Feedback Updated Successfully')
            setUpdateFeedbackDialog(false)
            setFeedbackDialog(false)
            setUpdateFeedPayload({})
        }else{
            failureToast(toast,response?.data?.error)
        }
    }

    const submitCategory = async(e) =>{
        e.preventDefault();
        console.log(addCategoryPayload);
        const response = await apiServices.addFeedbackCategory(addCategoryPayload);
        if(response?.data?.success){
            successToast(toast,'Feedback Category Added Successfully')
            getFeedbackcategory()
        }else{
            failureToast(toast,'Failed to add')
        }
        hideCatDialog()
    }

    const feedbackFooter =  (<><Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideFeedbackFooter} /></>)

    const addFeedDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideDialog} />
            <Button label="Submit" type='submit' form='add-feed-form' icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" />
        </>
    );

    const updateFeedDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideEditDialog} />
            <Button label="Submit" type='submit' form='edit-feed-form' icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" />
        </>
    );

    const categoryDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideCatDialog} />
            <Button label="Submit" type='submit' form='add-cat-form' icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" />
        </>
    );

    const actionBodyForTable = (rowData) =>{
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{display:"flex"}}>
                    <Button icon="pi pi-pencil" title='Delete' className="p-button-rounded p-button-success p-mx-2" aria-label="Edit" onClick={()=>{openEdit(rowData)}} />
                </div>
            </>
        )
    }
    

    return (
        <>
            <Toast ref={toast} position="center" />
             <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        {
                            loading ?
                            <>
                                <div className="spinner-div">
                                    <center><ProgressSpinner strokeWidth="7" animationDuration='4s' /></center>
                                </div>
                            </>
                            :
                            <>
                                <Toolbar className="p-mb-4 p-toolbar" left={leftFeedbackToolbarTemplate} right={rightFeedbackToolbarTemplate}></Toolbar>   
                                <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>   
                                <DataTable style={tableStyle} className="datatable-responsive"  paginator rows={10} rowsPerPageOptions={[5, 10, 25]} ref={dataRef} 
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                value={dayWiseData} dataKey="id" exportFilename={`Day Wise Consumption Report`} 
                                loading={loading} globalFilter={globalFilter} header={header} >
                                    <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                                    {
                                        allMaterials.map((x,i)=>(

                                                i < 10 ?
                                                <Column key={i} field={x} header={x == 'id' ? "CVES ID" : x} body={NumberCommonBodyForTable} style={{overflowWrap: 'break-word', width : '10rem'}} sortable ></Column>
                                                :
                                                (
                                                    filterType.code == 'cvesWise'?
                                                    <Column key={i} field={x} header={x == 'id' ? "CVES ID" : x} body={NumberBtnCommonBodyForTable} style={{overflowWrap: 'break-word', width : '10rem'}} sortable ></Column>
                                                    :
                                                    <Column key={i} field={x} header={x == 'id' ? "CVES ID" : x} body={NumberCommonBodyForTable} style={{overflowWrap: 'break-word', width : '10rem'}} sortable ></Column>
                                                )
                                        ))
                                    }

                                </DataTable>
                            </>
                        }

                    </div>
                </div>
            </div>

            <Dialog visible={customDateDialog} style={{ width: '550px' }} header="Custom Filter" footer={customFooter} onHide={hideCustomDialog} modal className="p-fluid">
                <form onSubmit={handleSubmit} id="custom-form" className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From</label>
                            <InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => {setCustomStart(e.target.value)} } required autoFocus/>
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' min={customStart}  value={customEnd} onChange={(e) => {setCustomEnd(e.value)}} required/>
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={feedbackDialog} style={{ width: '750px' }} header={`Feedback Details (${feedbackData[0]?.machineId})`} modal footer={feedbackFooter} onHide={hideFeedbackFooter}>
                <div className="p-grid crud-demo">
                    <div className="p-col-12">
                        <div className="card">
                        <DataTable value={feedbackData} dataKey="id" className="datatable-responsive" emptyMessage="No data found.">
                            {/* <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '10rem' }} exportable={false}></Column> */}
                            <Column field="feedback_category" header="Category" body={CommonBodyForTable} ></Column>
                            <Column field="feedback_message" header="Feedback Message" body={CommonBodyForTable} ></Column>
                            <Column field="createdAt" header="Created At" body={DateBodyForTable} ></Column>
                            <Column header="Action" body={actionBodyForTable} ></Column>
                        </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={addFeedbackDialog} style={{ width: '650px' }} header={`Add Feedback Details`} modal className="p-fluid" footer={addFeedDialogFooter} onHide={hideDialog} maximizable={true} blockScroll={true}>
                <Toolbar className="p-mb-4 p-toolbar" right={addCategoryToolbar}></Toolbar>   

                <form onSubmit={submitFeedback} id="add-feed-form" className="p-fluid">
                    {
                        isFromDate ?
                        <>
                            <div className="p-field">
                                <label htmlFor="company-name">Select Account</label>
                                <InputText id="company-name" name='compnay_name' value={selectedMachine?.Company_Name} placeholder='Account Name' disabled />
                            </div>
                            <div className="p-field">
                                <label htmlFor="cves-id">CVES Id</label>
                                <InputText id="cves-id" name='machineId' value={selectedMachine?.id} placeholder='Select CVES Id' disabled />
                            </div>
                        </>
                        :
                        <>
                            <div className="p-field">
                                <label htmlFor="company-name">Select Account</label>
                                <Dropdown id="company-name" name='compnay_name' onChange={changeFeed} value={selectedAccount} options={allAccounts} optionLabel="company" placeholder="Select Account" required filter></Dropdown>
                            </div>

                            <div className="p-field">
                                <label htmlFor="fac-id">Select Facility</label>
                                <Dropdown id="fac-id" name='facility' onChange={changeFeed} value={selectedFacility} options={allFacilities} optionLabel="facname" placeholder="Select Facility" required filter></Dropdown>
                            </div>

                            <div className="p-field">
                                <label htmlFor="cves-id">CVES Id</label>
                                <Dropdown id="cves-id" name='machineId' value={selectedMachineOption} filter options={machineOptions} optionLabel="id" onChange={changeFeed} placeholder="Select CVES Id" required></Dropdown>
                            </div>
                        </>
                    }

                    <div className="p-field">
                        <label htmlFor="feed-cat">Feedback Category</label>
                        <Dropdown id="feed-cat" name='feedback_category' value={selectedFeedCategory} filter options={feedbackCategoryOptions} optionLabel="name" onChange={changeFeed} placeholder="Select Feedback Category" required></Dropdown>
                    </div>

                    <div className="p-field">
                        <label htmlFor="feedback-id">Feedback Message</label>
                        <InputTextarea id="feedback-id" name='feedback_message' onChange={changeFeed} rows={2} cols={20} placeholder="Enter Feedback Message..." required/>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={updateFeedbackDialog} style={{ width: '650px' }} header={`Update Feedback Details`} modal className="p-fluid" footer={updateFeedDialogFooter} onHide={hideEditDialog} maximizable={true} blockScroll={true}>
                <form onSubmit={updateFeedback} id="edit-feed-form" className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="feedback-id">Feedback Message</label>
                        <InputTextarea id="feedback-id" name='feedback_message' defaultValue={selectedFeedback?.feedback_message} onChange={changeEdit} rows={2} cols={20} placeholder="Enter Feedback Message..." required/>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={addCategoryDialog} style={{ width: '650px' }} header={`Add Feedback Category Details`} modal className="p-fluid" footer={categoryDialogFooter} onHide={hideCatDialog} maximizable={true} blockScroll={true}>
                <form onSubmit={submitCategory} id="add-cat-form" className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="brand">Select Brand</label>
                        <MultiSelect id="brand" name="brand" value={selectedCatBrand} onChange={changeCat} options={brandsOptions} optionLabel="name" display="chip" placeholder="Select Brand" maxSelectedLabels={3} filter required  />
                    </div>
                    <div className="p-field">
                        <label htmlFor="cat-name">Feedback Category Name</label>
                        <InputText id="cat-name" name="category" onChange={changeCat} placeholder='Enter Feedback Category Name' />
                    </div>
                </form>
            </Dialog>

        </>
    )
}
