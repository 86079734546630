import React, { useEffect, useRef, useState, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown"
import { ColorPicker } from "primereact/colorpicker"
import { MultiSelect } from "primereact/multiselect"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar";
// Common Components
import { CommonBodyForTable, commonCloseDialogFooter, commonDialogFooter, commonFormDialogFooter } from "../Common/CommonComponents";
import { CommonForDetails } from "../CRM/Leads";
import { materialNatureOptions, materialBrandOptions, waterTypeOptions, materialTypeOption, defaultTemp } from "./Constants";
import { copyData, exportReport } from "../Common/CommonFunctions";
// context
import { permissionContext } from "../../AppWrapper";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices();

// =======================================================================================================
// =======================================================================================================

export const ProductMasterTab = (props) => {

    const rolePermissions = useContext(permissionContext);

    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState('')
    const [materialTypesData, setMaterialTypesData] = useState(null)
    const [productionMasterDialog, setProductionMasterDialog] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedMaterialNature, setSelectedMaterialNature] = useState(materialNatureOptions[0])
    const [skuList, setSkuList] = useState([])
    const [selectedSku, setSelectedSku] = useState(null)
    const [openSkuDialog, setOpenSkuDialog] = useState(false)
    const [filteredMaterials, setFilteredMaterials] = useState([])
    const [selectedSisteBeve, setSelectedSisteBeve] = useState(null)
    const [productMasterTemplate, setProductMasterTemplate] = useState(defaultTemp)
    const [selectedMaterialType, setSelectedMaterialType] = useState(null)
    const [allMaterialTypes, setAllMaterialTypes] = useState(null)
    const [selectedBrand, setSelectedBrand] = useState(null)
    const [selectedWaterType, setSelectedWaterType] = useState(null)
    const [addSkuTemplate, setAddSkuTemplate] = useState(null)
    const [selectedData, setSelectedData] = useState(null)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [viewProductMasterDialog, setViewProductMasterDialog] = useState(false)
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        getMatTypes()
    }, [])

    const openView = (rowData) => {
        console.log(rowData);
        setViewProductMasterDialog(true)
        setSelectedData(rowData)
        setSkuList(rowData?.sku)
    }

    const openEdit = (rowData) => {
        console.log(rowData);
        setProductionMasterDialog(true)
        getData(rowData)
    }

    const deleteProduct = (rowData) => {
        setOpenDeleteDialog(true)
        setSelectedData(rowData)
    }

    const deleteTheProduct = async () => {
        setOpenDeleteDialog(false)
        const response = await apiServices.deleteMaterialType({ materialName: selectedData?.materialName })
        response?.data?.success ? success('Beverage Type Deleted Successfully.') : failure('Failed to Delete');
        getMatTypes()
    }

    const getData = (rowData) => {
        if (rowData) {
            console.log(rowData?.sku);
            setProductMasterTemplate(rowData)
            setSelectedData(rowData)
            setSkuList(rowData?.sku)
            setSelectedMaterialType({ label: rowData?.materialType, code: rowData?.materialType })
            setSelectedBrand({ label: rowData?.brand, code: rowData?.brand })
            setSelectedWaterType({ label: rowData?.watertype, code: rowData?.watertype })
            setSelectedMaterialNature({ label: 'Beverages', code: 'beverages' })
            const filteredData = allMaterialTypes?.filter((x) => { return x.materialType == rowData?.materialType; })
            setFilteredMaterials(filteredData);
            const arr = [];
            filteredData.filter((x) => { if (rowData?.altBev?.includes(x.materialName)) { arr.push(x) } })
            setSelectedSisteBeve(arr)
        }
    }

    const getMatTypes = async () => {
        setLoading(true)
        const response = await apiServices.getMaterialTypes();
        const filteredData = response?.data?.mate?.map((x)=>{ 
            return {...x, 
                halfBeverage:x?.dispense_qty?.half?.beverage, 
                halfwater:x?.dispense_qty?.half?.water
            }
        })
        // setAllMaterialTypes(response?.data?.mate)
        // setMaterialTypesData(response?.data?.mate)
        setAllMaterialTypes(filteredData)
        setMaterialTypesData(filteredData)
        setLoading(false)
    }

    const changeSku = (e) => {
        setAddSkuTemplate({ ...addSkuTemplate, [e.target.name]: e.target.value })
    }

    const skuSubmit = (e) => {
        e.preventDefault();
        skuList?.push(addSkuTemplate)
        setOpenSkuDialog(false);
    }

    const openSku = (rowData, index) => {
        console.log(index);
        console.log(rowData);
        setSelectedSku(rowData)
        setOpenSkuDialog(true)
    }

    const deleteSku = (rowData, index) => {
        delete skuList[index];
        const arr = skuList?.filter((x) => { return x != null })
        console.log(arr);
        setSkuList(arr)
    }

    const setDefaultValues = () => {
        setSkuList([]);
        setSelectedMaterialNature({ label: 'Beverages', code: 'beverages' });
        setSelectedMaterialType(null);
        setSelectedBrand(null);
        setSelectedMaterialType(null);
        setSelectedSisteBeve(null);
        setSelectedWaterType(null)
    }

    const skuActionBodyTemplate = (rowData, props) => {

        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{ display: "flex" }}>
                    <i className="pi pi-pencil p-m-1 p-success" onClick={() => { openSku(rowData, props.rowIndex) }} ></i>
                    <i className="pi pi-trash p-m-1 p-error" style={{ cursor: 'pointer' }} onClick={() => { deleteSku(rowData, props.rowIndex) }}></i>
                    {/* <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-warning p-mx-1" aria-label="Edit" onClick={null}/> */}
                    {/* <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-2" aria-label="Delete" onClick={null}/> */}
                </div>
            </>
        )
    }


    const hideProMasterModel = () => {
        setProductionMasterDialog(false)
        setDefaultValues()
    }

    const onChangeAdd = () => {
        setProductionMasterDialog(true)
        setSelectedData(null)
    }


    const changeProMaster = (e) => {

        if (e.target.name == 'materialNature') {
            setSelectedMaterialNature(e.target.value)
            setProductMasterTemplate({ ...productMasterTemplate, [e.target.name]: e.target.value.code })
        }

        if (e.target.name == 'materialType') {
            setSelectedMaterialType(e.target.value)
            const filteredData = allMaterialTypes?.filter((x) => { return x?.materialType == e.target.value.code; })
            filteredData && setFilteredMaterials(filteredData);
            setProductMasterTemplate({ ...productMasterTemplate, [e.target.name]: e.target.value.code })
        }

        if (e.target.name == 'brand') {
            setSelectedBrand(e.value)
            setProductMasterTemplate({ ...productMasterTemplate, [e.target.name]: e.target.value.code })
        }

        if (e.target.name == 'materialName') {
            setProductMasterTemplate({ ...productMasterTemplate, [e.target.name]: e.target.value })
        }

        if (e.target.name == 'watertype') {
            setSelectedWaterType(e.value)
            setProductMasterTemplate({ ...productMasterTemplate, [e.target.name]: e.target.value.code })
        }

        if (e.target.name == 'color') {
            const color = '#' + e.target.value;
            setProductMasterTemplate({ ...productMasterTemplate, [e.target.name]: color })
        }

        if (e.target.name == 'materialInfo' || e.target.name == 'barcode' || e.target.name == 'density' || e.target.name == 'formulation') {
            setProductMasterTemplate({ ...productMasterTemplate, [e.target.name]: e.target.value })
        }

        if (e.target.name == 'HSN' || e.target.name == 'igst' || e.target.name == 'cgst' || e.target.name == 'sgst') {
            setProductMasterTemplate({ ...productMasterTemplate, billing_charges: { ...productMasterTemplate?.billing_charges, [e.target.name]: e.target.value } })
        }

        if (e.target.name == 'can_lock') {
            setProductMasterTemplate({ ...productMasterTemplate, datastore: { ...productMasterTemplate?.datastore, [e.target.name]: e.target.value } })
        }

        if (e.target.name == 'quantity' || e.target.name == 'water' || e.target.name == 'beverage') {
            setProductMasterTemplate({ ...productMasterTemplate, dispense_qty: { ...productMasterTemplate?.dispense_qty, half: { ...productMasterTemplate?.dispense_qty?.half, [e.target.name]: Number(e.target.value) } } })
        }

        if (e.target.name == 'altBev') {

            console.log(e.value);
            setSelectedSisteBeve(e.value)
            const materials = e.value?.map((x) => { return x.materialName })
            setProductMasterTemplate({ ...productMasterTemplate, [e.target.name]: materials })
        }
    }

    const prodMasterSubmit = async (e) => {
        hideProMasterModel();
        e.preventDefault();
        if (selectedData) {
            const data = { ...productMasterTemplate, sku: skuList }
            const response = await apiServices.updateMaterialType(data);
            response?.data?.success ? success('Beverage Type Updated Successfully.') : failure('Failed to Update');
        } else {
            const data = { ...productMasterTemplate, sku: skuList }
            const response = await apiServices.addMaterialType(data);
            response?.data?.success ? success('Beverage Type Added Successfully.') : failure('Failed to Add');
        }
        getMatTypes();
    }

    const onclickThis = (e) => {
        const copyText = e.target.innerText;
        copyData(copyText)
        showInfo()
    }

    const success = (msg) => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: `${msg}`, life: 3000 });
    }

    const failure = (msg) => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: `${msg}`, life: 3000 });
    }

    const showInfo = (msg) => {
        toast.current.show({ severity: 'info', summary: 'Copied', detail: 'Zoho id Copied to Clipboad', life: 1000 });
    }

    const exportCsv = () =>{ exportReport(dt)}

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right p-mr-2">
                <h5>Products</h5>
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4'  onClick={()=>{exportCsv()}}/>
                </div>
            </span>
        </div>
    )

    const srBodyTemplate = (rowData, props) => { return (<> <span className="p-column-title">{props.header}</span> {props.rowIndex + 1}  </>) }

    const commonBodyTemplate1 = (rowData, props) => {
        return (
            <>
                <> <span className="p-column-title" >{props.header}</span>
                    {
                        rowData[props.field] ? <Button label={rowData[props.field]} className="p-button-secondary p-button-text p-p-0" icon="pi pi-copy" iconPos="right" onClick={onclickThis} /> : 'NA'
                    }
                </>
            </>
        )
    }

    const productBodyTemplate = (rowData, props) => { return (<> <span className="p-column-title">{props.header}</span> {rowData?.dispense_qty?.half[props.field]}</>) }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{ display: "flex" }}>
                    <Button icon="pi pi-eye" title='View' className="p-button-rounded p-button-info p-mx-1" aria-label="View" onClick={() => { openView(rowData) }} />
                    {
                        rolePermissions?.fgProductMasterEdit && <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-warning p-mx-1" aria-label="Edit" onClick={() => { openEdit(rowData) }} />
                    }
                    {
                        rolePermissions?.fgProductMasterDelete &&
                        <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-2" aria-label="Delete" onClick={() => { deleteProduct(rowData) }} />
                    }
                </div>
            </>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <div className="p-inputgroup">
                <Button className='custom-group-btn' icon="pi pi-plus" label="Add Product Type" onClick={onChangeAdd} />
            </div>
        )
    }

    const hideViewProMaster = () =>{ setViewProductMasterDialog(false) };
    const hideSkuDialog = () => { setOpenSkuDialog(false) }
    const hideOpenDeleteDialog = () =>{ setOpenDeleteDialog(false) }

    const viewProductionMasterDialogFooter = commonCloseDialogFooter('Close', hideViewProMaster )
    const productionMasterDialogFooter = commonFormDialogFooter('Close', hideProMasterModel, 'Submit', 'pro-master')
    const skuDialogFooter = commonFormDialogFooter('Close', hideSkuDialog, 'Submit', 'add-sku')
    const openDeleteDialogFooter = commonDialogFooter('Close', hideOpenDeleteDialog, 'Yes, Delete it', deleteTheProduct)

    return (
        <>
            <Toast ref={toast} position='center' />

            <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar>

            <DataTable ref={dt} value={materialTypesData}
                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Products"
                globalFilter={globalFilter} emptyMessage="No Products Found." header={header} loading={loading} >
                <Column field="srid" header="Sr.No." body={srBodyTemplate} exportable={false} style={{ width: '4rem' }} ></Column>
                <Column field="materialName" header="Beverage Name" body={CommonBodyForTable}></Column>
                <Column field="materialType" header="Material Type" body={CommonBodyForTable}></Column>
                <Column field="watertype" header="Water Type" body={CommonBodyForTable}  ></Column>
                <Column field="brand" header="Brand" body={CommonBodyForTable}  ></Column>
                {/* <Column field="beverage" header="Premix Qty (Gms)" body={productBodyTemplate}  ></Column> */}
                {/* <Column field="water" header="Water Qty (Ml)" body={productBodyTemplate}  ></Column> */}
                
                <Column field="halfBeverage" header="Premix Qty (Gms)" body={CommonBodyForTable}  ></Column>
                <Column field="halfwater" header="Water Qty (Ml)" body={CommonBodyForTable}  ></Column>
                <Column header="Actions" body={actionBodyTemplate}></Column>
            </DataTable>

            <Dialog visible={viewProductMasterDialog} style={{ width: '60%' }} header={`View Product Details`} modal className="p-fluid" footer={viewProductionMasterDialogFooter} onHide={() => { setViewProductMasterDialog(false) }} maximizable={true} blockScroll={true}>
                <div className=" widget-performance p-m-2">
                    <div className="content">
                        <ul>
                            <div className='p-grid'>
                                <CommonForDetails name="Material Nature" value={selectedData?.materialNature ? selectedData?.materialNature : 'NA'} />
                                <CommonForDetails name="Material Type" value={selectedData?.materialType ? selectedData?.materialType : 'NA'} />
                                <CommonForDetails name="Material Brand" value={selectedData?.brand ? selectedData?.brand : 'NA'} />
                                <CommonForDetails name="Material Name" value={selectedData?.materialName ? selectedData?.materialName : 'NA'} />
                                <CommonForDetails name="Water Type" value={selectedData?.watertype ? selectedData?.watertype : 'NA'} />
                                <CommonForDetails name="Material Info" value={selectedData?.materialInfo ? selectedData?.materialInfo : 'NA'} />
                                <CommonForDetails name="Density" value={selectedData?.density ? selectedData?.density : 'NA'} />
                                <CommonForDetails name="Beverage gm/100ml" value={selectedData?.formulation ? selectedData?.formulation : 'NA'} />
                                <CommonForDetails name="HSN" value={selectedData?.billing_charges?.HSN ? selectedData?.billing_charges?.HSN : 'NA'} />
                                <CommonForDetails name="IGST" value={selectedData?.billing_charges?.igst ? selectedData?.billing_charges?.igst : 'NA'} />
                                <CommonForDetails name="CGST" value={selectedData?.billing_charges?.cgst ? selectedData?.billing_charges?.cgst : 'NA'} />
                                <CommonForDetails name="Dispence Quantity(ml)" value={selectedData?.dispense_qty?.half?.water ? selectedData?.dispense_qty?.half?.water : 'NA'} />
                            </div>
                        </ul>
                    </div>
                </div>

                <div className="crud-demo">
                    <div className="card">
                        <DataTable header={'SKU LIST'} value={skuList} responsiveLayout="scroll" className="datatable-responsive">
                            <Column header="Sr.No." body={srBodyTemplate} style={{ width: '4rem' }} ></Column>
                            <Column field="itemName" header="Item Name" body={CommonBodyForTable} ></Column>
                            <Column field="item_format" header="SKU Format" body={CommonBodyForTable} ></Column>
                            <Column field="item_name" header="SKU Name" body={CommonBodyForTable} ></Column>
                            <Column field="item_id" header="SKU Id" body={CommonBodyForTable} ></Column>
                            <Column field="weight" header="Weight(gm)" body={CommonBodyForTable} ></Column>
                            <Column field="zohoId" header="Zoho Id" body={commonBodyTemplate1}  ></Column>
                        </DataTable>
                    </div>
                </div>
            </Dialog>


            <Dialog visible={productionMasterDialog} style={{ width: '850px' }} header={`${selectedData ? 'Update' : 'Add'} Product Type`} modal className="p-fluid" footer={productionMasterDialogFooter} onHide={() => { hideProMasterModel() }} maximizable={true} blockScroll={true}>
                <form onSubmit={prodMasterSubmit} id="pro-master" className="p-fluid">

                    <div className="p-grid">

                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="matNature">Material Nature </label><br />
                                <Dropdown id="matNature" name="materialNature" value={selectedMaterialNature} onChange={changeProMaster} options={materialNatureOptions} optionLabel="label" placeholder="Select Material Nature" filter required disabled></Dropdown>
                            </div>
                        </div>

                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="bev-color">Beverage Color</label><br />
                                <ColorPicker id="bev-color" name="color" defaultColor={selectedData ? selectedData?.color : '#ffffff'} onChange={changeProMaster} required />
                            </div>
                        </div>

                    </div>

                    {
                        selectedMaterialNature?.code == 'beverages' &&

                        <>
                            <div className="p-grid">
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="mat-type">Material Type</label><br />
                                        <Dropdown id="mat-type" name="materialType" value={selectedMaterialType} onChange={changeProMaster} options={materialTypeOption} optionLabel="label" placeholder="Select Material Type" filter required disabled={selectedData}></Dropdown>
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="matBrand">Material Brand</label><br />
                                        <Dropdown id="matBrand" name="brand" value={selectedBrand} onChange={changeProMaster} options={materialBrandOptions} optionLabel="label" placeholder="Select Material Brand" filter required disabled={selectedData}></Dropdown>
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="matName">Material Name</label>
                                        <InputText id="matName" name='materialName' placeholder='Material Name' defaultValue={selectedData && selectedData?.materialName} onChange={changeProMaster} autoComplete="off" required />
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="water-type">Water Type</label><br />
                                        <Dropdown id="water-type" name="watertype" value={selectedWaterType} onChange={changeProMaster} options={waterTypeOptions} optionLabel="label" placeholder="Select Water Type" filter required disabled={selectedData}></Dropdown>
                                    </div>
                                </div>

                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="matInfo">Material Info</label>
                                        <InputText id="matInfo" name='materialInfo' placeholder='Material Info' defaultValue={selectedData && selectedData?.materialInfo} onChange={changeProMaster} autoComplete="off" required />
                                    </div>
                                </div>

                                {/* <div className="p-col-6">
                                        <div className="p-field">
                                            <label htmlFor="barcode">Barcode</label>
                                            <InputText id="barcode" name='barcode' placeholder='Barcode' defaultValue={selectedData && selectedData?.barcode } onChange={changeProMaster}  autoComplete="off" required />
                                        </div>
                                    </div> */}
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="density">Density</label>
                                        <InputText type='number' id="density" name='density' placeholder='Density' defaultValue={selectedData && selectedData?.density} onChange={changeProMaster} autoComplete="off" required disabled={selectedData} />
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="bevvv">Beverage gm/100 ml Water</label>
                                        <InputText type='number' id="bevvv" name='formulation' placeholder='Beverage gm/100 ml Water' defaultValue={selectedData && selectedData?.formulation} onChange={changeProMaster} autoComplete="off" required disabled={selectedData} />
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="hsn">HSN</label>
                                        <InputText id="hsn" name='HSN' placeholder='HSN' defaultValue={selectedData && selectedData?.billing_charges?.HSN} onChange={changeProMaster} autoComplete="off" required />
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="igst">IGST</label>
                                        <InputText type='number' id="igst" name='igst' placeholder='IGST' defaultValue={selectedData && selectedData?.billing_charges?.igst} onChange={changeProMaster} autoComplete="off" required />
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="cgst">CGST</label>
                                        <InputText type='number' id="cgst" name='cgst' placeholder='CGST' defaultValue={selectedData && selectedData?.billing_charges?.cgst} onChange={changeProMaster} autoComplete="off" required />
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="sgst">SGST</label>
                                        <InputText type='number' id="sgst" name='sgst' placeholder='SGST' defaultValue={selectedData && selectedData?.billing_charges?.sgst} onChange={changeProMaster} autoComplete="off" required />
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="dis-qty">Dispence Quantity (ml)</label>
                                        <InputText type='number' id="dis-qty" name='quantity' placeholder='Dispence Quantity (ml)' defaultValue={selectedData && selectedData?.dispense_qty?.half?.quantity} onChange={changeProMaster} autoComplete="off" required />
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="can-lock">Caniter Lock</label>
                                        <InputText type='number' id="can-lock" name='can_lock' placeholder='Caniter Lock' defaultValue={selectedData && selectedData?.datastore?.can_lock} onChange={changeProMaster} autoComplete="off" required />
                                    </div>
                                </div>
                            </div>
                            <h5>Composition (ml)</h5>
                            <div className="p-grid">
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="water">Water (ml)</label>
                                        <InputText type='number' id="water" name='water' placeholder='Water (ml)' defaultValue={selectedData && selectedData?.dispense_qty?.half?.water} onChange={changeProMaster} autoComplete="off" required />
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="bev">Beverage (gm)</label>
                                        <InputText type='number' id="bev" name='beverage' placeholder='Beverage (gm)' defaultValue={selectedData && selectedData?.dispense_qty?.half?.beverage} onChange={changeProMaster} autoComplete="off" required />
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div className="p-field">
                                        <label htmlFor="sister-bev">Sister Beverage</label><br />
                                        <MultiSelect id="sister-bev" name="altBev" optionLabel="materialName" value={selectedSisteBeve} placeholder="Select Sister Bevarage" options={filteredMaterials} onChange={changeProMaster} />
                                    </div>
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-2">
                                    <span style={{ cursor: 'pointer' }} onClick={() => { setOpenSkuDialog(true); setSelectedSku(null) }} ><a > + Add SKU</a> </span>
                                </div>
                            </div>

                            <div className="crud-demo">
                                <div className="card">
                                    <DataTable value={skuList} responsiveLayout="scroll" className="datatable-responsive">
                                        <Column header="Sr.No." body={srBodyTemplate} ></Column>
                                        <Column field="item_format" header="SKU Format" body={CommonBodyForTable}></Column>
                                        <Column field="item_name" header="SKU Name" body={CommonBodyForTable} ></Column>
                                        <Column field="item_id" header="SKU Id" body={CommonBodyForTable} ></Column>
                                        <Column field="weight" header="Weight(gm)" body={CommonBodyForTable} ></Column>
                                        {/* <Column field="zohoId" header="Zoho Id" body={commonBodyTemplate} ></Column> */}
                                        <Column header="Action" body={skuActionBodyTemplate} ></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </>
                    }
                </form>
            </Dialog>



            <Dialog visible={openSkuDialog} style={{ width: '550px' }} header={`${selectedSku ? 'Update' : 'Add'} SKU`} modal className="p-fluid" footer={skuDialogFooter} onHide={() => { setOpenSkuDialog(false) }} maximizable={true} blockScroll={true}>
                <form onSubmit={skuSubmit} id="add-sku" className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="skuFormat">SKU Format</label>
                        <InputText id="skuFormat" name='item_format' placeholder='SKU Format' defaultValue={selectedSku ? selectedSku?.item_format : null} onChange={changeSku} autoComplete="off" autoFocus required />
                    </div>
                    <div className="p-field">
                        <label htmlFor="skuName">SKU Name</label>
                        <InputText id="skuName" name='item_name' placeholder='SKU Name' defaultValue={selectedSku ? selectedSku?.item_name : null} onChange={changeSku} autoComplete="off" required />
                    </div>
                    <div className="p-field">
                        <label htmlFor="skuId">SKU Id</label>
                        <InputText id="skuId" name='item_id' placeholder='SKU Id' defaultValue={selectedSku ? selectedSku?.item_id : null} onChange={changeSku} autoComplete="off" required />
                    </div>
                    <div className="p-field">
                        <label htmlFor="weight">Weight(gm)</label>
                        <InputText id="weight" name='weight' placeholder='Weight(gm)' defaultValue={selectedSku ? selectedSku?.weight : null} onChange={changeSku} autoComplete="off" required />
                    </div>
                    {/* <div className="p-field">
                        <label htmlFor="zohoId">Zoho Id</label>
                        <InputText id="zohoId" name='zohoId' placeholder='Zoho Id'  defaultValue={selectedSku ? selectedSku?.zohoId : null} onChange={changeSku}  autoComplete="off" required />
                    </div> */}
                </form>
            </Dialog>


            <Dialog visible={openDeleteDialog} style={{ width: '450px' }} header={`Confirm`} modal footer={openDeleteDialogFooter} onHide={() => { setOpenDeleteDialog(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br />
                        <span className='p-mt-4'><b>Are you sure you want to delete the selected Product?</b></span>
                    </center>
                </div>
            </Dialog>



        </>
    )
}