import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
// Services
import ApiServices from '../../service/api/ApiServices';
const apiServices = new ApiServices();

export const Tasks = () => {

    const [tasksData, setTasksData] = useState([]);
    const [allTasks, setAllTasks] = useState([]);
    const [loading, setLoading] = useState(true)
    const [taskDialog, setTaskDialog] = useState(false);
    const [viewTaskDialog, setViewTaskDialog] = useState(false);
    const [deleteTaskDialog, setDeleteTaskDialog] = useState(false);
    
    const [selectedTask, setSelectedTask] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [selectedTaskCat,setSelectedTaskCat] = useState(null)
    const [subCategoryOption,setSubCategoryOption] = useState(null)
    const [selectedSubCat,setSelectedSubCat] = useState(null)
    const [selectedUser,setSelectedUser] = useState(null)
    const [selectedAssign,setSelectedAssign] = useState(null)
    const [assignedToOptions,setAssignedToOptions] = useState(null)
    const [allUsers,setAllUsers] = useState(null)
    const [customers,setCustomers] = useState(null)
    const [seletectedCustomer,setSeletectedCustomer] = useState(null)
    const [allLeads,setAllLeads] = useState(null)
    const [selectedLead,setSelectedLead] = useState(null)
    const [addTemplate,setAddTemplate] = useState(null)
    const [defaultTaskDate,setDefaultTaskDate] = useState(null)
    const [defaultStartDate,setDefaultStartDate] = useState(null)
    const [defaultEndDate,setDefaultEndDate] = useState(null)
    
    const toast = useRef(null);
    const dt = useRef(null);

    const multiselectValues = [
        {name: 'All', code:'all'},
        { name: 'Completed', code:'completed'},
        { name: 'Pending', code:'pending'},
    ];

    const [multiselectValue, setMultiselectValue] = useState({
        name: "All",
        code: "all"
    });
        
    const taskCategoryItems = [
        { name: 'Sales/Marketing', code: 'Sales/Marketing' },
        { name: 'Service/Support', code: 'Service/Support' }
    ];

    const salesAndMarketingSubCatOptions = [
        { name: 'Pitch', code: 'pitch' },
        { name: 'Demo', code: 'demo' },
        { name: 'Onboard', code: 'onboard' }
    ];

    const ServiceAndSupportSubCatOptions = [
        { name: 'Refilling', code: 'refilling' },
        { name: 'Cleaning', code: 'cleaning' },
        { name: 'Maintenance', code: 'Maintenance' },
        { name: 'Others', code: 'others' }
    ];
    
    const userDropdownItems = [
        { name: 'Marketing Manager', code: 'marketmanager' },
        { name: 'Account Manager', code: 'accountmanager' },
        { name: 'Technoman', code: 'superman' }
    ];

    useEffect(() => {
        getTask()
        getUsers()
    },[]);

    const getUsers = async() =>{
        const response = await apiServices.getUserReduced();
        setAllUsers(response?.data?.data)
        const response1 = await apiServices.getCustomerData1();
        console.log(response1?.data?.data);
    }

    const getTask = async () => {
        const response = await apiServices.getTasks();
        const data = response?.data?.data.reverse() 
        setAllTasks(data);
        setTasksData(data);
        setLoading(false)
    };

    const onTaskDoneChange = (e) => {
        setMultiselectValue(e.value);
        if(e.value.code == 'all'){
            setTasksData(allTasks);
        } else {
            const intData = allTasks?.filter((int) => (int.task.done? 'completed':'pending') == e.value.code);
            setTasksData(intData);
        }
    }; 

    const emptyAll = () =>{
        setSelectedTask(null)
        setAddTemplate(null)
        setSelectedTaskCat(null)
        setSelectedSubCat(null)
        setSelectedUser(null)
        setSelectedAssign(null)
        setDefaultStartDate(null)
        setDefaultEndDate(null)
        setDefaultTaskDate(null)
        setSelectedLead(null)
        setSeletectedCustomer(null)
    }

    const openNewTasks = () => {
        setTaskDialog(true);
        const cust = allUsers?.filter((x)=>{if(x.userType == 'customer'){return x}})
        setCustomers(cust)
        emptyAll()        
    }

    const editTask = (data) => {
        console.log(data);
        setSelectedTask(data)
        setTaskDialog(true)
        setAddTemplate(data)
        const tdate = new Date(data?.taskdate).toLocaleDateString('fr-CA')
        setDefaultTaskDate(tdate)
        const startdate = data?.task?.starttime.replace(/:00.000Z/, "");
        setDefaultStartDate(startdate)
        const enddate = data?.task?.endtime.replace(/:00.000Z/, "");
        setDefaultEndDate(enddate)

        if(data?.task?.type == 'Sales/Marketing'){

            setSelectedTaskCat({ name: 'Sales/Marketing', code: 'Sales/Marketing' })
            setSubCategoryOption(salesAndMarketingSubCatOptions)
            const selData = salesAndMarketingSubCatOptions.filter((x)=>{if(x.code == data?.task?.subtype){return x}})
            setSelectedSubCat(selData?selData[0]:null)
            const choosenlead = allLeads.filter((x)=>{if(x.userid == data?.task?.client?.userid){return x}})
            setSelectedLead(choosenlead[0])

        }else if(data?.task?.type == 'Service/Support'){

            const choosenCust = allUsers.filter((x)=>{if(x.userid == data?.task?.client?.userid){return x}})
            console.log(choosenCust[0])
            setSeletectedCustomer(choosenCust[0])
            setSelectedTaskCat({ name: 'Service/Support', code: 'Service/Support' })
            setSubCategoryOption(ServiceAndSupportSubCatOptions)
            const selData = ServiceAndSupportSubCatOptions.filter((x)=>{if(x.code == data?.task?.subtype){return x}})
            setSelectedSubCat(selData?selData[0]:null)   
            const cust = allUsers?.filter((x)=>{if(x.userType == 'customer'){return x}})
            setCustomers(cust)

        }

        if(data?.assignedTo?.userType){
            const data1 = allUsers.filter((x)=>{if(x.userType == data?.assignedTo?.userType){return x}})
            setAssignedToOptions(data1)
            const assignedUser = data1?.filter((x)=>{if(x.userid == data.assignedTo.userid){return x}}) 
            setSelectedAssign(assignedUser[0])

            if(data?.assignedTo?.userType == 'marketmanager'){
                setSelectedUser({ name: 'Marketing Manager', code: 'marketmanager' })
            }else if(data?.assignedTo?.userType == 'accountmanager'){
                setSelectedUser({ name: 'Account Manager', code: 'accountmanager' })
            }else if(data?.assignedTo?.userType == 'superman'){
                setSelectedUser({ name: 'Technoman', code: 'superman' })
            }
        }   
    }

    const confirmDelete = (data) => {
        setSelectedTask(data);
        setDeleteTaskDialog(true);
    }

    const deleteTask = async() =>{
        const response = await apiServices.removetask({_id:selectedTask?._id})
        response.data.success ? success('Task Deleted Successfully') : failure('Failed to Delete')
        setDeleteTaskDialog(false)
        getTask()
        setLoading(true)
    }

    const hideTaskDialog = () => {
        setTaskDialog(false);
    }

    const hideViewTaskDialog = () =>{
        setViewTaskDialog(false);
    }

    const hideDeleteTaskDialog = () => {
        setDeleteTaskDialog(false);
    }

    const viewInfo = (data) => {
        console.log(data);
        setSelectedTask(data)
        setViewTaskDialog(true);
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const success = (msg) =>{
        toast.current.show({ severity: 'success', summary: 'Successful', detail:`${msg}`, life: 3000 });
    }

    const failure = (msg) =>{
        toast.current.show({ severity: 'error', summary: 'Failed', detail:`${msg}`, life: 3000 });
    }


    const changeData = (e) =>{

            if(e.target.name == 'type'){
                    setSelectedTaskCat(e.value)
                    if(e.value.name == 'Sales/Marketing'){
                        setSubCategoryOption(salesAndMarketingSubCatOptions)
                    }else if(e.value.name = 'Service/Support'){
                        setSubCategoryOption(ServiceAndSupportSubCatOptions)
                }
                selectedTask ? setAddTemplate({...addTemplate,task:{...addTemplate.task,[e.target.name]:e.value.code}}) : setAddTemplate({...addTemplate,[e.target.name]:e.value.code})
            }

            if(e.target.name == 'subtype' ){
                setSelectedSubCat(e.value)
                selectedTask ? setAddTemplate({...addTemplate,task:{...addTemplate.task,[e.target.name]:e.value.code}}) : setAddTemplate({...addTemplate,[e.target.name]:e.value.code})
            }
            
            if(e.target.name == 'location' || e.target.name == 'comment' ){
                setSelectedSubCat(e.value)
                selectedTask ? setAddTemplate({...addTemplate,task:{...addTemplate.task,[e.target.name]:e.target.value}}) : setAddTemplate({...addTemplate,[e.target.name]:e.target.value})
            }
            
            if(e.target.name == 'assignedTo'){
                setSelectedAssign(e.value)
                setAddTemplate({...addTemplate,[e.target.name]:e.value})
            }
            
            if(e.target.name == 'taskdate' || e.target.name == 'starttime' || e.target.name == 'endtime'){
                const datte = new Date(e.target.value)
                if(!isNaN(datte)){
                    const date = new Date(e.target.value).toISOString()
                    if(e.target.name == 'taskdate'){
                        setAddTemplate({...addTemplate,[e.target.name]:date})
                    }else{
                        selectedTask ? setAddTemplate({...addTemplate,task:{...addTemplate.task,[e.target.name]:date}}) : setAddTemplate({...addTemplate,[e.target.name]:date})
                    }
                } 
            }
            
            if(e.target.name == 'lead'){
                setSelectedLead(e.value)
                const data = {userid: e.value?.userid, name: e.value?.name, location: e.value?.addr?.addr}
                selectedTask ? setAddTemplate({...addTemplate,task:{...addTemplate.task,client:data}}) : setAddTemplate({...addTemplate,client:data})
            }
            
            if(e.target.name == 'customer'){
                setSeletectedCustomer(e.value)
                selectedTask ? setAddTemplate({...addTemplate,task:{...addTemplate.task,client:e.value}}) : setAddTemplate({...addTemplate,client:e.value})
            }
    }


    const changeUser = (e) =>{
        console.log(e.value);
        setSelectedUser(e.value)
        const data = allUsers?.filter((x)=>{if(x.userType == e.value.code){return x}})
        console.log(data);
        setAssignedToOptions(data)
    }

    const submitTask = async(e) =>{
        e.preventDefault();
        if(selectedTask){
            const response = await apiServices.updateTask(addTemplate)
            response.data.success ? success('Task Updated Successfully') : failure('Failed to Update')
        }else{
            const response = await apiServices.assignTask(addTemplate)
            response.data.success ? success('Task Added Successfully') : failure('Failed to Add')
        }
        setTaskDialog(false)
        getTask()
        setLoading(true)
    }


    const rightToolbarTemplate = () => {return (<><Button className='p-custom-btn' label="ADD NEW TASK" icon="pi pi-plus-circle" iconPos="left" onClick={openNewTasks}/></>)}

    const srBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">Sr.No</span>
                {props.rowIndex + 1}
            </>
        );
    }

    const assigntoBodyTemplate = (rowData) => {
        return (
          <>
            <span className="p-column-title">Assigned To</span>
            {rowData.assignedTo.name?rowData.assignedTo.name:''}
          </>
        );
      };

      const taskdateBodyTemplate = (rowData) => {
        const date = new Date(rowData.taskdate).toLocaleDateString('en-US')
        return (
          <>
            <span className="p-column-title">Task Date</span>
            {date}
          </>
        );
      };

      const typeBodyTemplate = (rowData) => {
        return (
          <>
            <span className="p-column-title">Type</span>
            {rowData?.task?.type ? rowData?.task?.type: '-'}
          </>
        );
      };

      const customerBodyTemplate = (rowData) => {
        return (
          <>
            <span className="p-column-title">Customer</span>
            {/* {rowData.task.client.name?rowData.task.client.name:''} */}
            {rowData.task?.client?.name?rowData.task?.client?.name:''}
          </>
        );
      };

      const statusBodyTemplate = (rowData) => {
        return (
          <>
            <span className="p-column-title">Status</span>
            {rowData.task.done?'Completed':'Pending'}
          </>
        );
      };



    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={() => editTask(rowData)}/>
                <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mr-2 p-mb-2" onClick={() => confirmDelete(rowData)} />
                <Button icon="pi pi-eye" title='Info' className="p-button-rounded p-button-info p-mr-2 p-mb-2" onClick={() => viewInfo(rowData)}/>
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <div className="p-inputgroup" style={{width:'20%'}} >
                <Button className='custom-group-btn' label="TASK STAGE " />
                <Dropdown value={multiselectValue}  options={multiselectValues} onChange={onTaskDoneChange} optionLabel="name" placeholder="Select Filter" filter
                        className="p-m-0 multiselect-custom" />
            </div>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </div>
            </span>
        </div>
    )
    
    const taskDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={hideTaskDialog} />
            <Button label={`${selectedTask ? 'Update' : 'Add'}`} type='submit' form='add-task' icon={`${selectedTask ? 'pi pi-plus' : 'pi pi-pencil'}`} className="p-button-success"/>
        </>
    )

    const deleteTaskDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-danger" onClick={hideDeleteTaskDialog} />
            <Button label="Yes ! delete it" icon="pi pi-check" className="p-button-success" onClick={deleteTask} />
        </>
    )

    const viewTaskFooter = (<><Button label="close" icon="pi pi-times" className="p-button-danger" onClick={hideViewTaskDialog} /></>)

    return (

            <div className="p-grid crud-demo">
            <div className="p-col-12">
            <div className="card">
            <Toast ref={toast} />
            <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar>

            <DataTable ref={dt} value={tasksData}
                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tasks"
                globalFilter={globalFilter} emptyMessage="No Tasks found." header={header} loading={loading}>
                <Column field="id" header="Sr.No." sortable body={srBodyTemplate}></Column>
                <Column field="assigned_to" header="Assigned To" sortable body={assigntoBodyTemplate}></Column>
                <Column field="task_date" header="Task Date" sortable body={taskdateBodyTemplate}></Column>
                <Column field="type" header="Type" sortable body={typeBodyTemplate}></Column>
                <Column field="customer" header="Customer" sortable body={customerBodyTemplate}></Column>
                <Column field="status" header="Status" sortable body={statusBodyTemplate}></Column>
                <Column body={actionBodyTemplate}></Column>
            </DataTable>

            <Dialog visible={taskDialog} style={{ width: '750px' }} header={`${selectedTask ? 'Edit' : 'Add'} Task Details`} modal className="p-fluid" footer={taskDialogFooter} onHide={hideTaskDialog} maximizable={true} blockScroll={true}>
            <form onSubmit={submitTask} id="add-task" className="p-fluid">
                <div className='p-grid'>
                <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="taskCat">Task Category</label>
                        <Dropdown id="taskCat" name='type' value={selectedTaskCat} onChange={changeData} options={taskCategoryItems} optionLabel="name" placeholder="Select Category"></Dropdown>
                    </div>
                </div>

                {
                    selectedTaskCat &&
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="subTaskCat">Sub Category</label>
                            <Dropdown id="subTaskCat" name='subtype' value={selectedSubCat} onChange={changeData} options={subCategoryOption} optionLabel="name" placeholder="Select Sub Category"></Dropdown>
                        </div>
                    </div>
                }
                
                <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="userId">User</label>
                        <Dropdown id="userId" value={selectedUser} onChange={changeUser} options={userDropdownItems} optionLabel="name" placeholder="Select User"></Dropdown>
                    </div>
                </div>

                {
                    selectedUser &&
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="assigned">Assigned To</label>
                            <Dropdown id="assigned" name='assignedTo' value={selectedAssign} onChange={changeData} options={assignedToOptions} optionLabel="name" placeholder="Select Assigned To"></Dropdown>
                        </div>
                    </div>
                }
                
                <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="taskDate">Task Date</label>
                        <InputText id="taskDate" name='taskdate' type="date" onChange={changeData} defaultValue={defaultTaskDate} required/>
                    </div>
                </div>
                <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="startDate">Start Date</label>
                        <InputText id="startDate" name='starttime' type="datetime-local" onChange={changeData}  defaultValue={defaultStartDate} required />
                    </div>
                </div>
                <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="endDate">End Date</label>
                        <InputText id="endDate" name='endtime' type="datetime-local" onChange={changeData} defaultValue={defaultEndDate} required />
                    </div>
                </div>
                {
                    selectedTaskCat?.name == 'Sales/Marketing'  &&
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="leadId">Lead</label>
                            <Dropdown id="leadId" name='lead' value={selectedLead} onChange={changeData} options={allLeads} filter optionLabel="name" placeholder="Select Lead"></Dropdown>
                        </div>
                    </div>
                }

                {
                    selectedTaskCat?.name == 'Service/Support'  &&

                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="customerId">Customer</label>
                            <Dropdown id="customerId" name='customer' value={seletectedCustomer} onChange={changeData} options={customers} filter optionLabel="name" placeholder="Select Customer"></Dropdown>
                        </div>
                    </div>
                }

                <div className="p-col-6">
                    <div className="p-field">
                        <label htmlFor="location">Location</label>
                        <InputText id="location" name='location' onChange={changeData} placeholder='location' defaultValue={selectedTask?.task?.location} required/>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="p-field">
                        <label htmlFor="comment">Comment</label>
                        <InputTextarea id="comment" name='comment' onChange={changeData} rows={2} cols={20} placeholder="Comment" defaultValue={selectedTask?.task?.comment} required/>
                    </div>
                </div>
                </div>
            </form>
            </Dialog>

            <Dialog visible={viewTaskDialog} style={{ width: '650px' }} header="Task Details" modal  onHide={hideViewTaskDialog} className='p-fluid' footer={viewTaskFooter} maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                <div className="p-col-12">
                    <div className=" widget-performance">
                        <div className="content">
                            <ul>
                                <div className='p-grid'>
                                <div className='p-col-6'>
                                    <li className="person-item p-p-0">
                                        <div className="person-info">
                                            <div className="amount">Assigned To:</div>
                                            <div className="name">{selectedTask?.assignedTo?.name ? selectedTask?.assignedTo?.name : 'NA'}</div>
                                        </div>
                                    </li>
                                </div>

                                <div className='p-col-6'>
                                    <li className="person-item p-p-0">
                                        <div className="person-info">
                                            <div className="amount">Contact No:</div>
                                            <div className="name">{selectedTask?.assignedTo?.cno ? selectedTask?.assignedTo?.cno : 'NA'}</div>
                                        </div>
                                    </li>
                                </div>
                                
                                <div className='p-col-6'>
                                    <li className="person-item p-p-0">
                                        <div className="person-info">
                                            <div className="amount">Task Date:</div>
                                            <div className="name">{selectedTask?.taskdate ? new Date(selectedTask?.taskdate).toLocaleDateString('fr-CA') : 'NA'}</div>
                                        </div>
                                    </li>
                                </div>

                                <div className='p-col-6'>
                                    <li className="person-item p-p-0">
                                        <div className="person-info">
                                            <div className="amount">Client Name:</div>
                                            <div className="name"> {selectedTask?.task?.client?.name ? selectedTask?.task?.client?.name : 'NA'}</div>
                                        </div>
                                    </li>
                                </div>

                                <div className='p-col-6'>
                                    <li className="person-item p-p-0">
                                        <div className="person-info">
                                            <div className="amount">Client Location:</div>
                                            <div className="name">{selectedTask?.task?.client?.location ? selectedTask?.task?.client?.location : 'NA'}</div>
                                        </div>
                                    </li>
                                </div>

                                <div className='p-col-6'>
                                    <li className="person-item p-p-0">
                                        <div className="person-info">
                                            <div className="amount">Type:</div>
                                            <div className="name">{selectedTask?.task?.type ? selectedTask?.task?.type : 'NA'}</div>
                                        </div>
                                    </li>
                                </div>

                                <div className='p-col-6'>
                                    <li className="person-item p-p-0">
                                        <div className="person-info">
                                            <div className="amount">Sub-Type:</div>
                                            <div className="name">{selectedTask?.task?.subtype ? selectedTask?.task?.subtype : 'NA'}</div>
                                        </div>
                                    </li>
                                </div> 

                                <div className='p-col-6'>
                                    <li className="person-item p-p-0">
                                        <div className="person-info">
                                            <div className="amount">Start Time:</div>
                                            <div className="name">{selectedTask?.task?.starttime ? new Date(selectedTask?.task?.starttime).toString() : 'NA'}</div>
                                        </div>
                                    </li>
                                </div>
                                <div className='p-col-6'>
                                    <li className="person-item p-p-0">
                                        <div className="person-info">
                                            <div className="amount">End Time:</div>
                                            <div className="name">{selectedTask?.task?.endtime ? new Date(selectedTask?.task?.endtime).toString() : 'NA'}</div>
                                        </div>
                                    </li>
                                </div>

                                <div className='p-col-6'>
                                    <li className="person-item p-p-0">
                                        <div className="person-info">
                                            <div className="amount">Comment:</div>
                                            <div className="name">{selectedTask?.task?.comment ? selectedTask?.task?.comment : 'NA'}</div>
                                        </div>
                                    </li>
                                </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </Dialog>

            <Dialog visible={deleteTaskDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTaskDialogFooter} onHide={hideDeleteTaskDialog}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br/><br/>
                        <b><span>Are you sure you want to delete this task?</span></b> <br/><br/>
                        <span>You wont be able to revert.</span> 
                    </center>
                </div>
            </Dialog>

            </div>
            </div>
            </div>
    );
}
