export const durationOptions = [
    { name: 'All', code: 'all' },
    { name: 'Today', code: 'today' },
    { name: 'Yesterday', code: 'yesterday' },
    { name: 'This Week', code: 'this_week' },
    { name: 'This Month', code: 'this_month' },
    { name: 'Last Month', code: 'last_month' },
    { name: 'Custom', code: 'custom' }
]

export const filterOptions = [
    { name : 'CVES', code : 'cvesWise'},
    { name : 'Account', code : 'accountWise'}
]

export const brandsOptions = [
    { name : 'TAPRI KIOSK', code : 'TAPRI'},
    { name : 'TKAFFE KIOSK', code : 'TKAFFE'},
    { name : 'TAPRI KETTLE', code : 'MERI_TAPRI'}
]

export const sortingOptions = [
    { name: 'High to Low', code: 'desc' },
    { name: 'Low to High', code: 'asc' }
]

export const feedbackCategoryOptions = [
    { name : 'Technical/Operational'},
    { name : 'Customer'},
    { name : 'KAM issue'},
    { name : 'Others'},
]

export const activityStatusOptions = [
    { name: 'Active', code: 'ACTIVE' },
    { name: 'Inactive', code: 'INACTIVE' },
]




