import React, {useState, useEffect, useRef} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Editor } from "primereact/editor";
import { Toast } from 'primereact/toast';
import { CommonBodyForTable } from '../Common/CommonComponents';
import { successToast, failureToast } from '../Common/CommonFunctions';

import ApiServices from '../../service/api/ApiServices';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
const apiServices = new ApiServices();

// ================================================================================
// ================================================================================

export const TrainingVideos = () => {

    const brandOptions = [
        { name : 'TAPRI', code : 'TAPRI'},
        { name : 'TKAFFE', code : 'TKAFFE'},
        { name : 'MERI_TAPRI', code : 'MERI_TAPRI'}
    ]

    const foldedOptions = [ { name : 'Yes'}, { name : 'No'} ]

    const [loading, setLoading] = useState(true);
	const [faqData, setFaqData] = useState(null);
    const [addFaqDialog, setAddFaqDialog] = useState(false)
    const [answerText, setAnswerText] = useState(null)
    const [payload, setPayload] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [selectedFaq, setSelectedFaq] = useState(null)
    const [comfirmDelete, setComfirmDelete] = useState(false)    
    const [selectedBrand, setSelectedBrand] = useState(null)
    const [selectedFold, setSelectedFold] = useState(null)
    
    const toast = useRef(null);

    useEffect(() => {
        getData({})
    }, [])

    const getData = async(data) =>{
        const response = await apiServices.getVideos(data);
        console.log(response);
        // const orderedData =  response?.data?.data.sort((a,b)=> (a.index > b.index ? 1 : -1))
        setFaqData(response?.data?.data);
        setLoading(false)
    }

    const openFaqDialog = () =>{
        setAddFaqDialog(true)
        setIsEdit(false)
        setAnswerText(null)
        setSelectedFaq(null)
    }

    const changeVideo = (e) =>{
        if(e.target?.name == 'link' || e.target?.name == 'question'){
            setPayload({...payload, [e.target.name] : e.target.value})
        }else if(e.target?.name == 'index'){
            setPayload({...payload, [e.target.name] : Number(e.target.value)})
        }else if(e.target?.name == 'brand'){
            setSelectedBrand(e.target.value)
            const brands = e.target.value?.map((x)=>{ return x.code})
            setPayload({...payload, [e.target.name] : brands})
        }else if(e.target?.name == 'folded'){
            setSelectedFold(e.target.value)
            setPayload({...payload, [e.target.name] : e.target.value?.name == 'Yes' ? true : false})
        }else{
            setTimeout(() => { setAnswerText(e.htmlValue) }, 1000);
        }
    }

    const submitVideos = async(e) =>{
        e.preventDefault(); 
        const data = {...payload, 'answer': answerText }
        console.log(data);

        if(isEdit){


            const payload = {
                _id: selectedFaq._id,
                "question": data?.question,
                "answer": data?.answer,
                "index": data?.index,
                "brand": data?.brand,
                "folded": data?.folded,
                "link": data?.link
            }

            console.log(payload);
            const response = await apiServices.updateVideos(payload);

            if(response?.data?.success){
                successToast(toast, 'Training Video Updated Successfully')
                hideDialog()
            }else{
                failureToast(toast, 'Failed to Update')
            }

        }else{
            const response = await apiServices.addVideos(data);
            console.log(response?.data);
            if(response?.data?.response_code == 1){
                successToast(toast,'Training Video is Added Successfully')
                hideDialog()
            }else{
                failureToast(toast, 'Failed to add')
            }
        }

    }

    const hideDialog = () =>{
        setAddFaqDialog(false)
        setAnswerText(null)
        setPayload({})
        setIsEdit(false)
        setSelectedFaq(null)
        setComfirmDelete(false)
        setSelectedFold(null)
        setSelectedBrand(null)
        getData({})
    }

    const openEdit = (data) =>{
        console.log(data);
        setAddFaqDialog(true)
        setIsEdit(true)
        setSelectedFaq(data)
        setAnswerText(data?.answer)
        // setPayload({ question : data?.question})
        setPayload(data)
        data?.folded ? setSelectedFold({ name : 'Yes'}) : setSelectedFold({ name : 'No'});

        const brandData = data.brand?.map((x)=>{ return { name : x, code : x }})
        setSelectedBrand(brandData)
    }

    const comfirmDeleteFaq = (data) =>{
        setSelectedFaq(data)
        setComfirmDelete(true)
    }

    const deleteFaq = async() =>{
        const response = await apiServices.deleteVideo({_id : selectedFaq._id});
        console.log(response);
        if(response?.data?.success){
            successToast(toast,'Training Video Deleted Successfully')
            hideDialog()
        }else{
            failureToast(toast,'Failed to Delete')
        }
    }

    const htmlForTable = (data, props) =>(
        <> 
            <span className="p-column-title">{props.header}</span>
            <div dangerouslySetInnerHTML={{__html: data[props.field]}} />
        </> 
    )

    const actionBodyTemplate = (rowData) =>(
        <>
            <span className="p-column-title">Action</span>
            <Button icon="pi pi-pencil" title="Edit" className="p-button-rounded p-button-success p-mr-2" onClick={()=>{openEdit(rowData)}}/>
            <Button icon="pi pi-trash" title="Delete" className="p-button-rounded p-button-danger" onClick={()=>{comfirmDeleteFaq(rowData)}}/>
        </>
    )

    const rightToolbarTemplate = () =>( <Button className='p-custom-btn' label="ADD VIDEO" icon="pi pi-plus-circle" iconPos="left" onClick={()=>{openFaqDialog()}}/> )
    
    const addfaqDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger" onClick={hideDialog} />
            { answerText && <Button label="Submit" type="submit" form='add-video' icon="pi pi-plus" className="p-button-success"/> } 
        </>
    );

    const deleteFaqDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-success p-mr-2 p-mb-2" onClick={()=>{setComfirmDelete(false)}}  />
            <Button label="Delete" icon="pi pi-trash" className="p-button-danger p-mr-2 p-mb-2" onClick={()=>{deleteFaq()}}/>
        </>
    );

    return (
        <>  
                <Toast ref={toast} position='center' />

                <div className="p-grid crud-demo">
                    <div className="p-col-12">
                        <div className="card">
                            <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar>
                            <DataTable value={faqData}
                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} FAQ's" emptyMessage="No data found." loading={loading}>
                                {/* <Column field="srno" header="Sr.No." style={{width:'8rem'}} sortable body={srBodyTemplate}></Column> */}
                                <Column field="index" header="Order Index" style={{width:'10rem'}} sortable body={CommonBodyForTable}></Column>
                                {/* <Column field="link" header="Link" sortable body={CommonBodyForTable}></Column> */}
                                <Column field="question" header="Question" sortable body={CommonBodyForTable}></Column>
                                <Column field="answer" header="Answer" sortable body={htmlForTable}></Column>
                                <Column field="link" header="Video Link" sortable body={CommonBodyForTable}></Column>
                                <Column header="Action" sortable body={actionBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

                <Dialog visible={addFaqDialog} style={{ width: '950px' }} header={`${isEdit ? 'Edit' : 'Add'} Training Video`} modal  footer={addfaqDialogFooter} onHide={()=>{hideDialog()}} maximizable={true} blockScroll={true}>
                    <form onSubmit={submitVideos} id="add-video" className="p-fluid">

{/* "question": "NA",
"index": 4,
"link": "WWW.LINK.COM"
"answer": "NA",
"brand": [ "MERI_TAPRI" ],
"folded": true, */}
                        <div className='p-grid'>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label>Question</label>
                                <InputText type="text" name='question' placeholder='Question' onChange={changeVideo} defaultValue={isEdit ? selectedFaq?.question : ''} autoFocus required autoComplete='off'/>
                            </div>
                        </div>
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label>Order Index</label>
                                <InputText type="number" name='index' placeholder='Order Index' min={1} onChange={changeVideo} defaultValue={isEdit ? selectedFaq?.index : ''} required autoComplete='off'/>
                            </div>
                        </div>
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label>Link</label>
                                <InputText name='link' placeholder='Link' onChange={changeVideo} defaultValue={isEdit ? selectedFaq?.link : ''} autoComplete='off'/>
                            </div>
                        </div>
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label>Brand</label>
                                <MultiSelect name='brand' value={selectedBrand} onChange={changeVideo} options={brandOptions} optionLabel="name" display="chip" placeholder="Select Brand" maxSelectedLabels={3} filter  />
                                {/* <Dropdown name='brand' value={selectedBrand} onChange={changeVideo} options={brandOptions} optionLabel="name" placeholder="Select Brand" filter className="p-mr-2 multiselect-custom " required/> */}
                            </div>
                        </div>
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label>Folded</label>
                                <Dropdown name='folded' value={selectedFold} onChange={changeVideo} options={foldedOptions} optionLabel="name" placeholder="" filter className="p-mr-2 multiselect-custom " required/>
                            </div>
                        </div>
                        <div className='p-col-12'>
                            <Editor name="editor" value={answerText} onTextChange={changeVideo} style={{ height: '320px' }} />
                        </div>
                        </div>
                    </form>
                </Dialog>

                <Dialog visible={comfirmDelete} style={{ width: '450px' }} header="Confirm" modal footer={deleteFaqDialogFooter} onHide={()=>{setComfirmDelete(false)}}>
                    <div className="confirmation-content">
                        <center>
                            <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br/>
                            <span className='p-mt-2'><b>Are you sure you want to delete the selected video?</b></span>
                        </center>
                    </div>
                </Dialog>
        </>
  )
}
