import React, { useEffect, useState } from 'react';
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { QrTransaction } from './QrTransaction';
import { RfidTransaction } from './RfidTransaction';
import { CustomQrTransaction } from './CustomQrTransaction';
// Services
import ApiServices from '../../service/api/ApiServices';
import { FonePayQrTransactions } from './FonePayQrTransactions';
import { PhonePayTransaction } from './PhonePayTransaction';
import { OnlineCvesList } from './OnlineCvesList';
const apiServices = new ApiServices();
const initialValue = JSON.parse(localStorage.getItem("user"));

const firstCheck = initialValue?.tabChecker?.onlineCheck;
const secondCheck = initialValue?.tabChecker?.rfidCheck;
const thirdCheck = initialValue?.tabChecker?.qrCheck;

export const Transactions = () => {

        const [allMachines, setAllMachines] = useState(null)
        const [onlineMachines, setOnlineMachines] = useState(null)
        const [rfidMachines, setRfidMachines] = useState(null)
        const [qrMachines, setQrMachines] = useState(null)
        const [allAccounts, setAllAccounts] = useState(null)
        const [allPaytmUsers, setAllPaytmUsers] = useState(null)
        //     const [index,setIndex] = useState(0)

        const durationOptions = [
                { name: 'Till Date', code: 'till_date' },
                { name: 'Today', code: 'today' },
                { name: 'Yesterday', code: 'yesterday' },
                { name: 'This Week', code: 'this_week' },
                { name: 'This Month', code: 'this_month' },
                { name: 'Last Month', code: 'last_month' },
                { name: 'Custom', code: 'custom' }
        ];

        useEffect(() => {
                getMachine()
                getUser()
                getPaytmUsers()
        }, [])

        const getMachine = async () => {
                const response = await apiServices.getMachineForDropDown();
                console.log(response);
                setAllMachines(response)

                const onMachines = response?.filter((x) => { if (x?.mchmode?.paymode?.online) { return x } })
                setOnlineMachines(onMachines)

                const rfidMachines = response?.filter((x) => { if (x?.mchmode?.paymode?.rfid) { return x } })
                console.log(rfidMachines);
                setRfidMachines(rfidMachines)

                const qrMachines = response?.filter((x) => { if (x?.mchmode?.paymode?.qr) { return x } })
                console.log(qrMachines);
                setQrMachines(qrMachines)
        }

        const getUser = async () => {
                const response = await apiServices.getUsersByType({ 'userType': 'customer' })
                console.log(response?.data?.data);
                const dataHere = response?.data?.data?.map((x) => { return { ...x, company: x.billing_details?.company ? x.billing_details?.company : 'CIPL' } })
                setAllAccounts(dataHere)
        }

        const getPaytmUsers = async () => {
                const response = await apiServices.getPaytmUser();
                console.log(response?.data?.data);
                const dataHere = response?.data?.data?.map((x) => { return { ...x, company: x.billing_details?.company ? x.billing_details?.company : 'CIPL' } })
                setAllPaytmUsers(dataHere)
        }

        return (

                <>
                        {
                                <div className="p-grid crud-demo">
                                        <div className="p-col-12">

                                                {
                                                        (firstCheck && secondCheck && thirdCheck) &&

                                                        <div className="card">
                                                                <TabView>
                                                                        <TabPanel header="Paytm QR Transactions">
                                                                                <QrTransaction allAccounts={allPaytmUsers} allMachines={onlineMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>
                                                                        <TabPanel header="RFID Transactions">
                                                                                <RfidTransaction allMachines={rfidMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>
                                                                        <TabPanel header="Custom QR">
                                                                                <CustomQrTransaction allMachines={qrMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>
                                                                        <TabPanel header="Fonepay QR Transactions">
                                                                                <FonePayQrTransactions allMachines={qrMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>
                                                                        <TabPanel header="Online CVES List">
                                                                                <OnlineCvesList allMachines={onlineMachines} />
                                                                        </TabPanel>
                                                                </TabView>
                                                        </div>
                                                }

                                                {
                                                        (!firstCheck && !secondCheck && !thirdCheck) &&

                                                        <div className="card">
                                                        </div>
                                                }

                                                {
                                                        (firstCheck && !secondCheck && !thirdCheck) &&

                                                        <div className="card">
                                                                <TabView>
                                                                        <TabPanel header="Paytm QR Transactions">
                                                                                <QrTransaction allAccounts={allPaytmUsers} allMachines={onlineMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>
                                                                        <TabPanel header="Fonepay QR Transactions">
                                                                                <FonePayQrTransactions allMachines={qrMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>
                                                                </TabView>
                                                        </div>
                                                }

                                                {
                                                        (!firstCheck && secondCheck && !thirdCheck) &&

                                                        <div className="card">
                                                                <TabView>

                                                                        <TabPanel header="RFID Transactions">
                                                                                <RfidTransaction allMachines={rfidMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>

                                                                </TabView>
                                                        </div>
                                                }

                                                {
                                                        (!firstCheck && !secondCheck && thirdCheck) &&

                                                        <div className="card">
                                                                <TabView>
                                                                        <TabPanel header="Custom QR">
                                                                                <CustomQrTransaction allMachines={qrMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>
                                                                </TabView>
                                                        </div>
                                                }

                                                {
                                                        (firstCheck && secondCheck && !thirdCheck) &&
                                                        <div className="card">
                                                                <TabView>
                                                                        {/* <TabPanel header="Phonepay Transactions">
                                                <PhonePayTransaction allMachines={onlineMachines} durationOptions={durationOptions}/>
                                        </TabPanel>  */}
                                                                        <TabPanel header="Paytm QR Transactions">
                                                                                <QrTransaction allAccounts={allPaytmUsers} allMachines={onlineMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>
                                                                        <TabPanel header="RFID Transactions">
                                                                                <RfidTransaction allMachines={rfidMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>
                                                                        <TabPanel header="Fonepay QR Transactions">
                                                                                <FonePayQrTransactions allMachines={qrMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>
                                                                        <TabPanel header="Online CVES List">
                                                                                <OnlineCvesList allMachines={onlineMachines} />
                                                                        </TabPanel>
                                                                </TabView>
                                                        </div>
                                                }

                                                {
                                                        (!firstCheck && secondCheck && thirdCheck) &&

                                                        <div className="card">
                                                                <TabView>
                                                                        <TabPanel header="RFID Transactions">
                                                                                <RfidTransaction allMachines={rfidMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>
                                                                        <TabPanel header="Custom QR">
                                                                                <CustomQrTransaction allMachines={qrMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>
                                                                </TabView>
                                                        </div>
                                                }

                                                {
                                                        (firstCheck && !secondCheck && thirdCheck) &&

                                                        <div className="card">
                                                                <TabView>
                                                                        <TabPanel header="Paytm QR Transactions">
                                                                                <QrTransaction allAccounts={allPaytmUsers} allMachines={onlineMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>

                                                                        <TabPanel header="Custom QR">
                                                                                <CustomQrTransaction allMachines={qrMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>
                                                                        <TabPanel header="Fonepay QR Transactions">
                                                                                <FonePayQrTransactions allMachines={qrMachines} durationOptions={durationOptions} />
                                                                        </TabPanel>
                                                                </TabView>
                                                        </div>
                                                }


                                        </div>
                                </div>
                        }

                </>



        )
}

