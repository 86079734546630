import React, { useEffect, useRef, useState } from 'react'

import { TableWrapper } from '../../utilComponents/DataTableWrapper'
import { CommonBodyForTable, CommonConfirmationDialog, commonFormDialogFooter, srBodyTemplate } from '../Common/CommonComponents'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { dateBodyForTable } from '../Users/ActiveUsers'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { FormWrapper, FullFormWrapper } from '../../utilComponents/Forms'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'

import ApiServices from '../../service/api/ApiServices'
import { Toast } from 'primereact/toast'
import { failureToast, successToast } from '../Common/CommonFunctions'
const apiServices = new ApiServices()

export const RfidSetup = () => {

    const [categories, setCategories] = useState(null)
    const [categoryDialog, setCategoryDialog] = useState(false)
    const [categoryIssues, setCategoryIssues] = useState({})
    const [selectedCategory, setSelectedCategory] = useState(null)

    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false)

    const toast = useRef(null)

    useEffect(() => {
        getCategories()
    }, [])

    const getCategories = async() =>{
        const response = await apiServices.getRfidSetup({});
        console.log(response?.data?.data);
        setCategories(response?.data?.data)
    }


    const openCategoryDialog = () =>{ 
        setSelectedCategory(null)
        setCategoryDialog(true) 
    }


    const rightTemplate = () =>(
        <Button className='p-custom-btn' label="Add RFID Setup" icon="pi pi-plus-circle" iconPos="left" onClick={openCategoryDialog} />
    )

    const hideCategoryDialog = () => { 
        setCategoryDialog(false) 
        setCategoryIssues({})
    }

    const categoryFooter = commonFormDialogFooter('Cancel', hideCategoryDialog, 'Submit', 'category-form')

    const changeInput = (e) =>{
        setCategoryIssues({...categoryIssues,[e.target.name] : e.target.value})
    }


    const saveCategory = async(e) =>{
        e.preventDefault()
        const response = selectedCategory ? await apiServices.updateRfidSetup(categoryIssues) : await apiServices.addRfidSetup(categoryIssues);

        if(response?.data?.success){
            successToast(toast,`RFID Setup ${selectedCategory ? 'Updated' : 'Added'} Successfully`)
            hideCategoryDialog()
            getCategories()
        }else{
            failureToast(toast, `Failed to ${selectedCategory ? 'Update' : 'Add'}`)
        }
    }

    const openEdit = (rowData) =>{
        console.log(rowData);
        setCategoryDialog(true)
        setSelectedCategory(rowData)
        setCategoryIssues(rowData)
    }

    const openDelete = (rowData) =>{
        setOpenDeleteConfirmDialog(true)
        setSelectedCategory(rowData)
    } 

    const deleteCategory = async() =>{
        const response = await apiServices.deleteEmailSample({ _id : selectedCategory._id })
        if(response?.data?.success){
            successToast(toast,'RFID Setup Deleted Successfully.')
            hideDeleteDialog()
            getCategories()
        }else{
            failureToast(toast,'Failed to Delete.')
        }
    }

    const actionBodyTemplate = (rowData) =>{
        return (
            <>
                <span className="p-column-title">Actions</span>
                <div className="actions" style={{ display: "flex" }}>
                    <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-warning p-mx-1" aria-label="Edit" onClick={()=>{openEdit(rowData)}} />
                    {/* <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-1" aria-label="Delete" onClick={()=>{openDelete(rowData)}} /> */}
                </div>
            </>
        )
    }

    const hideDeleteDialog = () =>{
        setOpenDeleteConfirmDialog(false)
    }

    const deleteDialogFooter = () =>{
        return (
            <>
                <Button label="Yes, Delete it." icon="pi pi-check" className=" p-button-success" onClick={deleteCategory} />
                <Button label="Cancel" icon="pi pi-times" className=" p-button-danger" onClick={hideDeleteDialog}  />
            </>
        )
    }

    const deleteProps = {
        dialogState : openDeleteConfirmDialog,
        dialogFooter : deleteDialogFooter,
        hideDialog : hideDeleteDialog,
        msg : 'Do You Want To Delete this RFID Setup?'
    }

    return (
        <>
            <TableWrapper>
                <Toast ref={toast} position='center' />
                <Toolbar left='List of RFID Setup' right={rightTemplate}></Toolbar>
                <DataTable value={categories} dataKey="id" className="datatable-responsive" emptyMessage="No RFID Setup Found." >
                    <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '8rem' }} exportable={false}></Column>
                    <Column field="steps" header="Steps" body={CommonBodyForTable} sortable ></Column>
                    <Column field="createdAt" header="Created At" body={dateBodyForTable} sortable ></Column>
                    <Column header="Actions" body={actionBodyTemplate} exportable={false}></Column>
                </DataTable>
            </TableWrapper>


            <Dialog visible={categoryDialog} style={{ width: '650px' }} header={`${selectedCategory ? 'Edit' : 'Add'} RFID Setup`} modal className="p-fluid" footer={categoryFooter} onHide={hideCategoryDialog} maximizable={true} blockScroll={true} >
                <form onSubmit={saveCategory} id="category-form">
                    <div className='p-grid'>
                        <FullFormWrapper id="steps" label="Enter Steps">
                            <InputText id="steps" name='steps' placeholder='Enter Steps' defaultValue={categoryIssues?.steps} onChange={changeInput} required autoFocus />
                        </FullFormWrapper>
                    </div>
                </form>
            </Dialog>

            <CommonConfirmationDialog {...deleteProps}/>
        </>
    )
}
