import React, {useEffect, useState, useRef, useMemo} from "react"
import { Toolbar } from "primereact/toolbar"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { DataView, DataViewLayoutOptions } from "primereact/dataview"
import { Dialog } from "primereact/dialog"
import { ProgressSpinner } from "primereact/progressspinner"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';
import { Chip } from "primereact/chip"
// Common
import { CommonBodyForTable } from "../Common/CommonComponents"
import { CommonForDetails } from "../CRM/Leads"
import { successToast, failureToast, infoToast, convertDateFn } from "../Common/CommonFunctions"
import { exportReport } from "../Common/CommonFunctions"
import { SubAnalyticalStat } from "../NewAnalytics.js/SubAnalyticalStat"
// constants
import { prefixOptions, levelOptions, statusOptions, durationOptions, ticketTypeOptions, ticketPurposeOptions, defaultTicketBody } from "./Constants"
// Services
import CommonCal from "../../service/common/CommonCal"
import ApiServices from "../../service/api/ApiServices"

const apiServices = new ApiServices()
const commonCal = new CommonCal()

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// =================================================================================
// =================================================================================

export const TicketManager = () =>{

    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState('');
    const [machinesData, setMachinesData] = useState({id : 'All'});
    const [machineOptions, setMachineOptions] = useState(null);
    const [selectedMachineOption, setSelectedMachineOption] = useState(null);
    const [ticketPayload, setTicketPayload] = useState(defaultTicketBody);
    const [selectedTicketType, setSelectedTicketType] = useState(ticketTypeOptions[0]);
    const [selectedTicketPurpose, setSelectedTicketPurpose] = useState(ticketPurposeOptions[0]);
    const [selectedMachine, setSelectedMachine] = useState({id : 'All', displayName : 'All'});
    const [allTicketData, setAllTicketData] = useState([]);
    const [dataviewValue, setDataviewValue] = useState(null);
    const [layout, setLayout] = useState('list');
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [openTicketDialog, setOpenTicketDialog] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState({ name : 'All', code: 'all'})
    const [selectedStatus, setSelectedStatus] = useState({ name : 'All', code: 'all'})
    // const [selectedDuration, setSelectedDuration] = useState({name:'All',code:'all'})
    const [selectedDuration, setSelectedDuration] = useState({name:'This Month',code:'this_month'})
    // const [allCategories, setAllCategories] = useState(null)
    const [allCategories, setAllCategories] = useState([{displayName : 'All'}])
    const [categoryData, setCategoryData] = useState(null)
    const [selectedCategoryData, setSelectedCategoryData] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState({displayName : 'All'})
    const [dataPayload, setDataPayload] = useState({})
    const [allDates, setAllDates] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [customFilterDialog, setCustomFilterDialog] = useState(null)
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    const [filterButton,setFilterButton] = useState(false)
    const [addTicketDialog, setAddTicketDialog] = useState(false)
    const [selectedPrefix, setSelectedPrefix] = useState({name : 'Mr'})
    const [allAccounts, setAllAccounts] = useState(null)
    const [selectedAccount, setSelectedAccount] = useState(null)
    const [allFacilities, setAllFacilities] = useState(null)
    const [selectedFacility, setSelectedFacility] = useState(null)
    const [allRaisedTicket, setAllRaisedTicket] = useState(null)
    const dt = useRef(null);
    const toast = useRef(null);

    useEffect(()=>{
        getAccounts()
        getMachines();
        getDates();
        getCategories();
        getAppCategory();
        getRaisedTicketData();
    },[])

    const getAccounts = async() =>{
        const response = await apiServices.getActiveZohoUser();
        // const response = await apiServices.getUsersByType({ userType : "customer"})
        const data = response?.data?.data?.map((x)=>{ return {...x, company : x?.billing_details?.company ? x?.billing_details?.company : 'CIPL' } })
        const ordered =  data.sort((a, b) => a?.company?.trim().localeCompare(b?.company.trim()))

        if(initialValue?.userType == "customer"){
            const custData = ordered?.filter((x)=>{ return x.userid == initialValue?.userid })
            console.log(custData);
            if(custData?.length > 0){

                setAllAccounts(custData)
                setSelectedAccount(custData[0])
                callfacility(custData[0])
                setTicketPayload({...ticketPayload, 
                    zoho_customer_id  : custData[0]?.zohoId, 
                    zoho_facility_id : "", 
                    contact : custData[0]?.cno.includes('+91') ? custData[0]?.cno.replace('+91','') : custData[0]?.cno,
                    name: custData[0]?.name,
                    last_name: custData[0]?.lastName,
                    email : custData[0]?.email,
                    prefix : custData[0]?.prefix,
                    ticketUrl : '',
                    account_name : custData[0]?.billing_details?.company,
                    account_id : custData[0]?.Customer_Account_ID   
                })
            }

        }else{
            setAllAccounts(ordered)
        }
    }

    const getCategories = async() =>{
        
        const response = await apiServices.ticketCatergory({})
        const data = response?.data?.data?.map((x)=>{ return {displayName : x}})
        console.log(data);
        // setCategoryData(data)
        data && setAllCategories([...allCategories,...data])
    }

    const getAppCategory = async() =>{
        const response = await apiServices.getAppCategories({})
        const res = response?.data?.response_obj[0]?.category?.map((x)=>{ return { displayName : x}})
        const ordered =  res.sort((a, b) => a?.displayName?.trim().localeCompare(b?.displayName.trim()))
        setCategoryData(ordered)
    }

    const getDates = async() =>{
        const response = await commonCal.getDates()
        console.log(response);
        setAllDates(response)
        getData({ date : { startDate : response?.monthStart, endDate : response.nowDate }});
        const date = { startDate : response?.monthStart, endDate : response.nowDate }
        setSelectedDate(date);
        setDataPayload({...dataPayload,date : date})
    }

    const getData = async(data) =>{
        setLoading(true)
        const response = await apiServices.getTickets(data)

        console.log(response?.data?.data);

        const mappedData = response?.data?.data?.map((x)=>{ 
            const date = new Date(x.date);
            return {...x,machineId:x.machineId.toString(), Newdate: date?.toString('YYYY-MM-dd') }}
        )

        const ticketStatusesToExclude = ['CLOSED', 'OPEN', 'HOLD', 'REQUESTED CLOSURE'];
        const filteredData = mappedData.filter((x) => !ticketStatusesToExclude.includes(x.ticketStatus));
        console.log(filteredData);
        
        // const filteredData = mappedData.filter((x)=>{
        //     return  x.ticketStatus !== 'CLOSED' && x.ticketStatus !== 'OPEN' && x.ticketStatus !== 'HOLD' && x.ticketStatus !== 'REQUESTED CLOSURE'
        // })

        if(initialValue?.userType == 'customer'){
            const custData = mappedData.filter((x)=>{ return x?.iotUserId == initialValue?.userid; })
            console.log(custData);
            setAllTicketData(custData)
            setDataviewValue(custData)
        }else{
            setAllTicketData(mappedData)
            setDataviewValue(mappedData)
        }

        setLoading(false)
    }

    const getRaisedTicketData = async() =>{
        const response = await apiServices.getRaisedTickets();
        console.log(response?.data?.response_obj);

        const arrangedData = Array.isArray(response?.data?.response_obj) && response?.data?.response_obj?.map((x)=>{
            return {...x, 
                ticketStatus : x.status,
                catergory : x.ticket_category,
                subCatergory : '',
                ticketQuery : x.message,
                ticketId : x.ticket_no,
                companyName : x.account_name,
                date : x.createdAt,
                zohoId : x.zoho_id,
                closerDate : x.expected_closure,
                Newdate : x.createdAt?.toString('YYYY-MM-dd')
            }
        })

        setAllRaisedTicket(arrangedData)
    }

    const getMachines = async() =>{
        const response = await apiServices.getMchArray();
        const forOptions = response?.data?.data?.map((x)=>{ return {...x, displayName : x.id + " / " + x.siteName }})
        const onBoardmachines = response?.data?.data?.map((x)=>{ return {...x, displayName : x.id + " / " + x.siteName }})
        onBoardmachines?.unshift({id : 'All', displayName : 'All'})
        setMachinesData(onBoardmachines);  
        // setMachineOptions(forOptions)
    }

    const changeCategory = (e) =>{
        setSelectedCategory(e.value)
        setDataPayload({...dataPayload,"catergory": e.value.displayName})
    }   

    const changeMachine = (e) =>{
        setSelectedMachine(e.value)
        setDataPayload({...dataPayload,"machineId": e.value?.id})
    }

    const itemTemplate = (data, layout) => {
        if (!data) {
            return;
        }
        if (layout === 'list') {
            return dataviewListItem(data);
        }
        else if (layout === 'grid') {
            return dataviewGridItem(data);
        }
    };

    const openDoc = (data) =>{ 
        data?.ticketUrl ? window.open(data?.ticketUrl,'_blank') : failureToast(toast,'Not Available');
    }

    const openView = (data) =>{
        const date = new Date(data?.date);
        const res =  {...data,dateShow:date?.toString('YYYY-MM-dd')}
        console.log(res);
        setSelectedTicket(res)
        setOpenTicketDialog(true)
    }

    const changeLevel = (e) =>{
        console.log(e.value);
        setSelectedLevel(e.value)
        setDataPayload({...dataPayload,"ticketLevel": e.value.code})
    }

    const changeStatus = (e) =>{
        console.log(e.value);
        setSelectedStatus(e.value)
        setDataPayload({...dataPayload,"ticketStatus": e.value.code})    
    }


    const changeDuration = (e) =>{
        e.value.code != 'custom' && setSelectedDuration(e.value)
        switch (e.value.code) {
            case 'all':{
                setSelectedDate(null);
                filterByDuration()
                break;
            }
            case 'today':{
                const date = {startDate : allDates.startToday, endDate : allDates.endToday}
                setSelectedDate(date);
                filterByDuration(date)
                break;
            }
            case 'yesterday':{
                const date = {startDate : allDates.YestStart, endDate : allDates.EndStart}
                setSelectedDate(date)
                filterByDuration(date)
                break;
            }
            
            case 'this_week':{
                const date = {startDate : allDates.weekstart, endDate : allDates.weekEnd}
                setSelectedDate(date)
                filterByDuration(date)
                break;
            }
            
            case 'this_month':{
                const date = {startDate : allDates.monthStart, endDate : allDates.endThisMonth}
                setSelectedDate(date)
                filterByDuration(date)
                break;
            }
            case 'last_month':{
                const date = {startDate : allDates.lastStart, endDate : allDates.lastend}
                setSelectedDate(date)
                filterByDuration(date)
                break;
            }
            
            case 'custom':
                setCustomFilterDialog(true);
                break;
            default:
                getData({})
                break;
        }
    }   


    const hideCustomDialog = () =>{ setCustomFilterDialog(false)};

    const handleSubmit = (e) =>{
        setSelectedDuration({name:'Custom',code:'custom'})
        setCustomFilterDialog(false)
        e.preventDefault()
        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        const cust_start = new Date(startt).toISOString();
        const cust_end = new Date(endd).toISOString();
        setSelectedDate({startDate : cust_start, endDate : cust_end})
        filterByDuration({startDate : cust_start, endDate : cust_end})
    }

    const filterByDuration = (date) =>{ setDataPayload({...dataPayload,date : date})}

    const exportCSV = () =>{ exportReport(dt)}

    const filtercategories = () =>{
        Object.entries(dataPayload).forEach( o => (o[1] === null || o[1] === undefined || o[1] === 'All' || o[1] === 'all' ? delete dataPayload[o[0]] : 0));
        console.log(dataPayload)
        getData(dataPayload)
    }

    const clickToRaise = () =>{ setAddTicketDialog(true) }
    const openContact = () =>{ window.open('https://cheriseglobal.com/contactus', '_blank')}

    const changeTicket = (e) =>{

        if(e.target.name == 'account'){
            console.log(e.value);
            setSelectedAccount(e.value)
            setSelectedFacility(null)
            callfacility(e.value)
            setMachineOptions(null)
            setTicketPayload(
                {...ticketPayload, 
                    zoho_customer_id  : e.value?.zohoId, 
                    zoho_facility_id : "", 
                    contact : e.value?.cno.includes('+91') ? e.value?.cno.replace('+91','') : e.value?.cno,
                    name: e.value?.name,
                    last_name: e.value?.lastName,
                    email : e.value?.email,
                    prefix : e.value?.prefix,
                    ticketUrl : e.value?.url,
                    account_name : e.value?.billing_details?.company,
                    account_id : e.value?.Customer_Account_ID   
                }
            ) 
        }

        if(e.target.name == 'facility'){
            console.log(e.value);
            setMachineOptions(null)
            setSelectedFacility(e.value)
            setTicketPayload({...ticketPayload, facility_name : e.value?.facname ,zoho_facility_id : e.value?.facilityZohoId})
            getMachineByFac(e.value)
        }

        if(e.target.name == 'message' || e.target.name == 'name' || e.target.name == 'last_name' || e.target.name == 'email' || e.target.name == 'contact'){
            setTicketPayload({...ticketPayload, [e.target.name]  : e.target.value})
        }

        if(e.target.name == 'prefix'){
            setSelectedPrefix(e.value)
            setTicketPayload({...ticketPayload, [e.target.name] : e.value.name})
        }

        if(e.target.name == 'machineId'){
            setSelectedMachineOption(e.value)
            setTicketPayload({...ticketPayload, [e.target.name] : e.value.id})
        }

        if(e.target.name == 'ticketType'){
            setSelectedTicketType(e.value)
            setTicketPayload({...ticketPayload, [e.target.name] : e.value.name})
        }

        if(e.target.name == 'ticketPurpose'){
            setSelectedTicketPurpose(e.value)
            setTicketPayload({...ticketPayload, [e.target.name] : e.value.name})
        }

        if(e.target.name == 'ticket_category'){
            setSelectedCategoryData(e.value)
            setTicketPayload({...ticketPayload, [e.target.name] : e.value.displayName})
        }

        // if(name == 'attachment'){

        //     let reader = new FileReader();
        //     // let file = e.target.files;
        //     let file = e.target.files[0];
        //     reader.readAsDataURL(file);
        //     reader.onload = async() => {
        //         // const filePreview = 'data:image/png' + ';base64,' + String(reader.result).split(',')[1];
        //         const filePreview = String(reader.result).split(',')[1];
        //         console.log(filePreview);
        //         setTicketPayload({...ticketPayload, [name] : [filePreview]})
        //     }
        // }
    }

    const callfacility = async(data)=>{
        const response = await apiServices.getFacility({ userid: data?.userid });
        const arr = [];
        response?.data?.data?.forEach((x)=>{ if(x?.facilityZohoId){ arr.push(x) } })
        setAllFacilities(arr)
    }

    const getMachineByFac = async(data)=>{
        const payload = { facid: data?.facid, userType : 'customer', userid : data?.userid }
        const response = await apiServices.getMachineByFacid(payload);
        console.log(response?.data?.data);
        if(response?.data?.data?.length > 0){
            // response?.data?.data?.push({id : 'Dont Know'})
            setMachineOptions(response?.data?.data)
        }else{
            infoToast(toast,'No CVES available under selected facility', 'No CVES Found')
            setMachineOptions(null)
        }
    }
    
    const changeAttach = (e) =>{
        const name = e.target.name;
        if(name == 'attachment'){
            const image_as_files = e.target.files;
            console.log(image_as_files);            
            setTicketPayload({...ticketPayload, [name] : [image_as_files]})
        }
    }
     
    const addTicket = async(e) =>{
        e.preventDefault();
        hideTicketDialog()
        const payloadData = {...ticketPayload}
        console.log(payloadData);
        const payload = new FormData();
        Object.entries(payloadData).forEach(([key, value]) => { payload.append(key, value)});

        console.log(payloadData?.attachment)
        if(payloadData?.attachment){

            payloadData?.attachment?.forEach((x, i)=>{
                for (let index = 0; index < x.length; index++) {
                    payload.append( "attachment", x[index], x[index]?.name);
                }
            })
        }

        const response = await apiServices.addTickets(payload);
        console.log(response);
        if(response?.data?.response_code == 1){
            successToast(toast,'Ticket Raised Successfully')
            getRaisedTicketData()
        }else{
            failureToast(toast,'Ticket Failed to be raised, please try again...')
        }
    }

    const hideTicketDialog = () =>{
        setAddTicketDialog(false)
        setSelectedAccount(null)
        setAllFacilities(null)
        setSelectedFacility(null)
        setMachineOptions(null)
        setSelectedCategoryData(null)
        setTicketPayload(defaultTicketBody)
        setSelectedMachineOption(null)
    }

    const dataviewListItem = (data) => {
        return (
            <div className="p-col-12 p-md-12">
                <div className="product-list-item">
                    <div className="product-list-detail">
                        <span className={`product-badge status-${data?.ticketStatus?.toLowerCase().replaceAll(' ', '')}`}>{data.ticketStatus}</span>
                        <div className="product-name p-mt-2"  title="Category (Subcategory)">Category :  {data?.catergory ? data?.catergory : 'Unknown Category'} </div>
                        {/* <div className="p-m-2 p-ml-0"><b> Sub Category :  <small>{data?.subCatergory ? data?.subCatergory : 'NA'}</small></b></div> */}
                        <h6 className="p-mt-2">Sub-Category : {data?.subCatergory ? data?.subCatergory : 'NA'}</h6>
                        <div className="product-description p-mt-2"> <b>Description : </b> {data?.ticketQuery}</div>                            
                            <span><b>Ticket ID :</b> {data?.ticketId}</span>
                            <hr></hr>
                            <div style={{display:'flex'}}>
                            <div className="p-mr-2 p-mt-2"> <b>CVES ID : </b>  {data?.machineId?.toString()} </div>
                            <span className="p-m-2"><b>Account Name :</b> {data?.companyName ? data?.companyName : 'NA' }</span>
                            <span className="p-m-2"><b>Account Id :</b> {data?.account_id ? data?.account_id : 'NA' }</span>
                            </div>
                        <hr></hr>
                        <div style={{display:'flex'}}>
                            {/* <span className="p-mr-2"><b>Created Date :</b> {convertDateFn(data?.Newdate) }</span> */}
                            <span className="p-mr-2"><b>Created Date :</b> { data?.Newdate }</span>
                            {/* <span className="p-mr-2"><b>Created Date :</b> {new Date(data?.date).toDateString()}</span> */}
                        </div>
                    </div>
                    <div className="product-list-action">
                        {/* <span className="product-price">Pricwe</span> */}
                        {/* <Button icon="pi pi-shopping-cart" label="Name" disabled={true}></Button> */}
                        {/* <i className="pi pi-tag product-category-icon"></i><span className="product-category">{data.ticketLevel}</span> */}
                        <span className="p-buttonset">
                            {data?.ticketUrl && <Button icon="pi pi-eye" onClick={()=>{openDoc(data)}} tooltip="Ticket Document" />}
                            <Button icon="pi pi-info-circle" onClick={()=>{openView(data)}} tooltip="Ticket Info" />
                        </span>
                    </div>
                </div>
            </div>
        );
    }


    const dataviewGridItem = (data) => {
        return (
            <div className="p-col-12 p-md-4">
                <div className="product-grid-item card">
                    <div className="product-grid-item-top">
                        <div>
                            <i className="pi pi-tag product-category-icon"></i>
                            <span className="product-category"> {data.ticketLevel}</span>
                        </div>
                        <span className={`product-badge status-${data.ticketStatus?.toLowerCase().replaceAll(' ', '')}`}>{data.ticketStatus}</span>
                    </div>
                    <div className="product-grid-item-content">
                        {/* <div className="product-name">{data?.ticketId}</div> */}
                        <div className="product-name p-mt-2">{data?.catergory ? data?.catergory : 'Unknown Category'}</div>
                        <h6 className="p-m-2">{data?.subCatergory ? data?.subCatergory : 'NA'}</h6>
                        <div className="p-mt-2"> CVES ID : {data?.machineId.toString()} </div>
                        <div className="p-mt-2 product-description">{data?.ticketQuery}</div>
                        {/* <div className="p-mt-2"><small>Date : {data?.date} </small></div> */}
                    </div>
                    <div className="product-grid-item-bottom">
                        <span><b>Ticket ID :</b> {data?.ticketId}</span>
                        {/* <Button icon="pi pi-eye" onClick={()=>{openView(data)}}></Button> */}
                        <span className="p-buttonset">
                            {data?.ticketUrl && <Button icon="pi pi-eye" onClick={()=>{openDoc(data)}} tooltip="Ticket Document" />}
                            <Button icon="pi pi-info-circle" onClick={()=>{openView(data)}} tooltip="Ticket Info"/>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const statsToolbarTemplate = () =>{
        return (
            <>
                <Chip label={`Total Tickets : ${allTicketData?.length}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                <Chip label={`Open Tickets : ${allTicketData?.filter((x)=>{ return x.ticketStatus == 'OPEN' || x.ticketStatus == 'Open' }).length}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                <Chip label={`Closed Tickets :  ${allTicketData?.filter((x)=>{ return x.ticketStatus == 'CLOSED'}).length}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                <Chip label={`Hold Tickets : ${allTicketData?.filter((x)=>{ return x.ticketStatus == 'HOLD'}).length}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                <Chip label={`Requested to Closure Tickets : ${allTicketData?.filter((x)=>{ return x.ticketStatus == 'REQUESTED CLOSURE'}).length}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
            </>
        )
    }


    const rightSearchToolbar = () =>{
        return (
            <div className="table-header">
                <span className="p-input-icon-left">
                    <div className="p-inputgroup p-m-1">
                        <Button className='custom-group-btn' icon="pi pi-search" />
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                        {/* <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV} /> */}
                    </div>
                </span>
            </div>
        )
    }

    const statsForNewToolbarTemplate = () =>{
        return (
            <>
                <Chip label={`Total Tickets : ${allRaisedTicket?.length}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                {/* <Chip label={`Open Tickets : ${allRaisedTicket?.filter((x)=>{ return x.status == 'OPEN'}).length}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" /> */}
                <Chip label={`Pending Tickets : ${allRaisedTicket?.filter((x)=>{ return x.status == 'PENDING'}).length}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                <Chip label={`Closed Tickets :  ${allRaisedTicket?.filter((x)=>{ return x.status == 'CLOSED'}).length}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                {/* <Chip label={`Requested to Closure Tickets : ${allRaisedTicket?.filter((x)=>{ return x.status == 'REQUESTED CLOSURE'}).length}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" /> */}
            </>
        )
    }

    const leftToolbarTemplate = () => {
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="CATEGORY" />
                    <Dropdown id='select-mc' value={selectedCategory} onChange={changeCategory} options={allCategories} optionLabel="displayName" placeholder="Select Categories" filter className="p-mr-2 multiselect-custom " />
                </div>   

                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="CVES" />
                    <Dropdown id='select-mc' value={selectedMachine} onChange={changeMachine} options={machinesData} optionLabel="displayName" placeholder="Select CVES" filter className="p-mr-2 multiselect-custom " />
                </div>   

                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="LEVEL" />
                    <Dropdown id='select-mc' value={selectedLevel} onChange={changeLevel} options={levelOptions} optionLabel="name" placeholder="Select Level" filter className="p-mr-2 multiselect-custom" />
                </div>          
            </>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="STATUS" />
                    <Dropdown id='select-mc' value={selectedStatus} onChange={changeStatus} options={statusOptions} optionLabel="name" placeholder="Select Status" filter className="p-mr-2 multiselect-custom" />
                </div>     

                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="DURATION" />
                    <Dropdown id='select-date' value={selectedDuration} onChange={changeDuration} options={durationOptions} optionLabel="name" placeholder="Select Duration" filter className="p-mr-2 multiselect-custom" />
                </div>  

                <div>
                    { !filterButton && <Button icon="pi pi-filter" label='Filter' className="custom-group-btn p-button p-my-2 p-mx-2" disabled={filterButton} onClick={filtercategories} /> }
                </div>
            </>
        )
    }

    const rightToolbarToRaiseTemplate = () =>{
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='p-custom-btn p-mx-2' label="RAISE TICKET" icon="pi pi-plus-circle" iconPos="left" onClick={clickToRaise} disabled={allAccounts ? false : true} />
                    {
                        initialValue?.userType === 'customer' &&
                        <Button className='p-custom-btn' label="CONTACT US" icon="pi pi-phone" iconPos="left" onClick={openContact} />
                    }
                </div>
            </>
        )
    }

    const dataviewHeader = () => {
        return (

            <div className="p-grid p-nogutter">
                <div className="p-col-6" style={{ textAlign: 'left' }}>
                    <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
                </div>
                <div className="p-col-6" style={{ textAlign: 'right' }}>
                <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded' onClick={exportCSV}/>
                </div>
            </div>
        );
    }

    const dataviewHeaderTest = () => {
        return (

            <div className="p-grid p-nogutter">
                <div className="p-col-6" style={{ textAlign: 'left' }}>
                    <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
                </div>
            </div>
        );
    }

    const srBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {props.rowIndex + 1}
            </>
        );
    }

    const addTicketDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className=" p-button-danger p-mr-2 p-mb-2" onClick={hideTicketDialog} />
            <Button label="Submit" type='submit' form='ticket-form'  icon="pi pi-check" className=" p-button-success p-mr-2 p-mb-2" />
        </>
    );

    const viewTicketFooter = ( <><Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={()=>{setOpenTicketDialog(false)}} /> </> )


    const header = dataviewHeader();
    const headerTest = dataviewHeaderTest();
    
    
    return (
        <>
            <Toast ref={toast} position='center' />
            <div className="layout-dashboard p-mb-0">
                <div className='card p-mb-0'>
                    <Toolbar className="p-toolbar p-mb-2" right={rightToolbarToRaiseTemplate} ></Toolbar>
                    {/* <Toolbar className="p-toolbar"  left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar> */}
                </div>
            </div>

            {/* <div className="p-grid list-demo">
                <div className="p-col-12">
                    <div className="card">

                        {
                            loading ? <div className="spinner-div"><center><ProgressSpinner strokeWidth="7" animationDuration='4s' /></center></div>
                            :
                            <>
                                {
                                    dataviewValue?.length > 0 ?
                                    <DataView value={dataviewValue}  footer={`Total : ${dataviewValue?.length}`} layout={layout} paginator rows={12} sortOrder={sortOrder} sortField={sortField} itemTemplate={itemTemplate} header={header}></DataView>
                                    :
                                    <center><h4 className="p-error p-mt-5">No Tickets Found</h4></center>
                                }
                            </>
                        }
                    </div>
                </div>
            </div> */}
           
            <div className="card p-mx-2">
                <TabView>
                    <TabPanel header="Confirmed Tickets">
                        <div className="layout-dashboard">
                            <div className='card'>
                                {/* <Toolbar className="p-toolbar p-mb-2" right={rightToolbarToRaiseTemplate} ></Toolbar> */}
                                <Toolbar className="p-toolbar"  left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>
                            </div>
                        </div>
                        <div className="p-grid list-demo">
                            <div className="p-col-12">
                                <div className="card">
                                    {
                                        loading ? <div className="spinner-div"><center><ProgressSpinner strokeWidth="7" animationDuration='4s' /></center></div>
                                        :
                                        <>
                                            {
                                                dataviewValue?.length > 0 ?
                                                <>
                                                    <Toolbar className="p-mb-4 p-toolbar" left={statsToolbarTemplate}></Toolbar>
                                                    <DataView 
                                                        value={dataviewValue} 
                                                        footer={`Total : ${dataviewValue?.length}`} 
                                                        layout={layout} 
                                                        paginator 
                                                        rows={12} 
                                                        sortOrder={sortOrder} 
                                                        sortField={sortField} 
                                                        itemTemplate={itemTemplate} 
                                                        header={header}
                                                    ></DataView>
                                                </>
                                                :
                                                <center><h4 className="p-error p-mt-5">No Tickets Found</h4></center>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="New Raised Tickets">
                        <div className="p-grid list-demo">
                        <div className="p-col-12">
                                <div className="card">
                                    {
                                        loading ? <div className="spinner-div"><center><ProgressSpinner strokeWidth="7" animationDuration='4s' /></center></div>
                                        :
                                        <>
                                            {
                                                allRaisedTicket?.length > 0 ?
                                                <>
                                                    <Toolbar className="p-mb-4 p-toolbar" left={statsForNewToolbarTemplate} ></Toolbar>
                                                    <DataView value={allRaisedTicket} footer={`Total : ${allRaisedTicket?.length}`} layout={layout} paginator rows={12} sortOrder={sortOrder} sortField={sortField} itemTemplate={itemTemplate} header={headerTest}></DataView>
                                                </>
                                                :
                                                <center><h4 className="p-error p-mt-5">No Tickets Found</h4></center>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            </div>

            <Dialog visible={openTicketDialog} style={{ width: '650px' }} header={`Ticket Details ( ${selectedTicket?.ticketId} )`} modal footer={viewTicketFooter}  onHide={()=>{setOpenTicketDialog(false)}} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                    <div className="p-col-12">
                    <div className=" widget-performance">
                    <div className="content">
                        <ul>
                            <div className='p-grid'>

                                <CommonForDetails name="Ticket Category" value={selectedTicket?.catergory ? selectedTicket?.catergory : 'NA'} />
                                <CommonForDetails name="Ticket Subcategory" value={selectedTicket?.subCatergory ? selectedTicket?.subCatergory : 'NA'} />
                                <CommonForDetails name="Zoho Id" value={selectedTicket?.zohoId} />
                                <CommonForDetails name="Ticket Level" value={selectedTicket?.ticketLevel} />
                                <CommonForDetails name="Ticket Status" value={selectedTicket?.ticketStatus } />
                                <CommonForDetails name="CVES" value={selectedTicket?.machineId.length > 0 ? selectedTicket?.machineId : 'NA' } />
                                <CommonForDetails name="Created Date" value={convertDateFn(selectedTicket?.Newdate) || 'NA' } />
                                <CommonForDetails name="Closer Date" value={convertDateFn(selectedTicket?.closerDate) || 'NA' } />

                                <div className='p-col-12'>
                                    <li className="person-item p-p-0">
                                        <div className="person-info">
                                            <div className="amount">Ticket Query</div>
                                            <div className="name p-mt-1">{selectedTicket?.ticketQuery}</div>
                                        </div>
                                    </li>
                                </div>
                            </div>
                        </ul>
                    </div>
                    </div>
                    </div>
                </div>
            </Dialog>

            <DataTable ref={dt} value={dataviewValue} dataKey="id" className="datatable-responsive" emptyMessage="No Data found." exportFilename={`Ticket Reports (CVES ${selectedMachine?.displayName}, Level ${selectedLevel?.name }, Status ${selectedStatus?.name}) `} style={{display:'none'}} >
                {/* <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '4rem' }}></Column> */}
                <Column field="ticketId" header="Ticket Id" body={CommonBodyForTable} ></Column>
                <Column field="machineId" header="Machine Id" body={CommonBodyForTable} ></Column>
                <Column field="catergory" header="Catergory" body={CommonBodyForTable} ></Column>
                <Column field="subCatergory" header="Sub Catergory" body={CommonBodyForTable} ></Column>
                <Column field="ticketQuery" header="Ticket Query" body={CommonBodyForTable} ></Column>
                <Column field="ticketRaiser" header="Ticket Raiser" body={CommonBodyForTable} ></Column>
                <Column field="ticketStatus" header="Ticket Status" body={CommonBodyForTable} ></Column>
                <Column field="ticketLevel" header="Ticket Level" body={CommonBodyForTable} ></Column>
                <Column field="date" header="Date" body={CommonBodyForTable} ></Column>
            </DataTable>
            
            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter"  onHide={hideCustomDialog} modal className="p-fluid">
                <form onSubmit={handleSubmit}>
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From</label>
                            <InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => {setCustomStart(e.value) } } required autoFocus/>
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' value={customEnd} onChange={(e) => {setCustomEnd(e.value)}} required/>
                        </div>
                    </div>
                    <div className="p-dialog-footer p-mt-4">
                        <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
                        <button className="p-button p-button-success" type="submit"><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={addTicketDialog} style={{ width: '950px' }} header="Raise Ticket" modal className="p-fluid" footer={addTicketDialogFooter} onHide={hideTicketDialog} maximizable={true} blockScroll={true} >
                {
                    allAccounts ?
                    <>
                        <form onSubmit={addTicket} encType="multipart/form-data" id="ticket-form">
                        <div className='p-grid'>
                            
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="acc">Account</label>
                                    <Dropdown id="acc" tooltip="Active Accounts" name='account' value={selectedAccount} onChange={changeTicket} filter options={allAccounts} optionLabel="company" placeholder="Select Account" required></Dropdown>
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="fac">Facility</label>
                                    <Dropdown id="fac" name='facility' value={selectedFacility} onChange={changeTicket} filter options={allFacilities} optionLabel="facname" placeholder="Select Facility" required></Dropdown>
                                </div>
                            </div>
                            <hr></hr>
                            {
                                selectedAccount &&
                                <>
                                    <div className='p-col-2'>
                                        <div className="p-field">
                                            <label htmlFor="prefix">Prefix</label>
                                            <Dropdown id="prefix" name='prefix' value={selectedPrefix} filter options={prefixOptions} optionLabel="name" placeholder="Select Prefix" disabled></Dropdown>
                                        </div>
                                    </div>
                                    <div className='p-col-5'>
                                        <div className="p-field">
                                            <label htmlFor="naming">First Name</label>
                                            <InputText id="naming" name='name' placeholder='First Name' value={selectedAccount?.name} disabled/>
                                        </div>
                                    </div>
                                    <div className='p-col-5'>
                                        <div className="p-field">
                                            <label htmlFor="last_name">Last Name</label>
                                            <InputText id="last_name" name='last_name' placeholder='Last Name' value={selectedAccount?.lastName} disabled/>
                                        </div>
                                    </div>
                                    <div className='p-col-6'>
                                        <div className="p-field">
                                            <label htmlFor="email">Email</label>
                                            <InputText id="email" type="email" name='email' placeholder='Email' value={selectedAccount?.email} disabled/>
                                        </div>
                                    </div>
                                    <div className='p-col-6'>
                                        <div className="p-field">
                                            <label htmlFor="contact">Contact</label>
                                            <InputText id="contact" name='contact' placeholder='Contact Number' value={selectedAccount?.cno} disabled />
                                        </div>
                                    </div>
                                </>
                            }
                        
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="machineId">CVES ID</label>
                                    <Dropdown id="machineId" name='machineId' value={selectedMachineOption} onChange={changeTicket} filter options={machineOptions} optionLabel="id" placeholder="Machine Id" required></Dropdown>
                                </div>
                            </div>  
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="ticketType">Ticket Type</label>
                                    <Dropdown id="ticketType" name='ticketType' value={selectedTicketType} options={ticketTypeOptions} onChange={changeTicket} filter optionLabel="name" placeholder="Select Ticket Type" required></Dropdown>
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="ticketPurpose">Ticket Purpose</label>
                                    <Dropdown id="ticketPurpose" name='ticketPurpose' value={selectedTicketPurpose} options={ticketPurposeOptions} onChange={changeTicket} filter optionLabel="name" placeholder="Select Ticket Purpose" required></Dropdown>
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="ticketCat">Category</label>
                                    <Dropdown id="ticketCat" name='ticket_category' value={selectedCategoryData} options={categoryData} onChange={changeTicket} filter optionLabel="displayName" placeholder="Select Category" required></Dropdown>
                                </div>
                            </div>
                            <div className='p-col-12'>
                                <div className="p-field">
                                    <label htmlFor="message">Ticket Description</label>
                                    <InputTextarea id="message" name='message' onChange={changeTicket} rows={5} cols={30} placeholder="Type the Description..." required/>
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="attachment" style={{background: '#f7f7f7', padding: '8px', borderRadius: '5px', border: '1px solid #00544d', display: 'inline-block', padding: '6px 12px', cursor: 'pointer'}}><i className="pi pi-upload" style={{ fontSize: '1rem' }}></i>  Select Attachments {`(${ticketPayload?.attachment ? ticketPayload?.attachment[0]?.length : 0} Selected)`}  </label>
                                    <InputText style={{display:'none'}} className="custom-file-input" id="attachment" name="attachment" type="file" placeholder='Attachment' onChange={changeAttach} multiple />
                                </div>
                            </div>
                        </div>
                        </form>
                    </>
                    :
                    <>
                        <center>
                            <h6 className="p-error">No Active Account Found</h6>
                        </center>
                    </>
                    
                }

            </Dialog>
            
        </>
    )
}