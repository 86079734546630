import React, {useEffect, useState} from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Chip } from 'primereact/chip';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { TabPanel } from 'primereact/tabview';
import { TabView } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

//Component
import { TransactionStats } from './TransactionStats';
import { DetailedQrTable } from './DetailedQrTable';
import { DayQrTransactionsChart } from './DayQrTransactionsChart';
// Services
import ApiServices from '../../service/api/ApiServices';
import CommonCal from '../../service/common/CommonCal';

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

const commonCal = new CommonCal();
const apiServices = new ApiServices();

export const QrTransaction = props =>{

    const [allMachines,setAllMachines] = useState([{displayLabel:'All'}])
    // const [allMachines,setAllMachines] = useState(null)
    const [selectedMachine,setSelectedMachine] = useState(null)
    const [selectedDuration, setSelectedDuration] = useState({name: 'Today', code: 'today'});
    const [selectedDurationDates, setSelectedDurationDates] = useState(null);
    const [transStats, setTransStats] = useState(null);
    const [transChartData, setTransChartData] = useState([]);
    const [transTableData, setTransTableData] = useState([]);
    const [dates,setDates] = useState(null)
    const [selectedCustomDate,setSelectedCustomDate] = useState(null)
    const [customFilterDialog, setCustomFilterDialog] = useState(false);
    const [allAccounts, setAllAccounts] = useState([{company: 'All', code: 'all'}])
    const [selectedAccount, setSelectedAccount] = useState({company: 'All', code: 'all'})
    const [loading,setLoading] = useState(true)
    const [activeIndex,setActiveIndex] = useState(0)
    

    // useEffect(()=>{
    //     console.log(props.allMachines)
    //     getFiltered(props.allMachines)
    // },[props.allMachines])

    useEffect(()=>{
        getByUser(props.allAccounts)
    },[props.allAccounts])
    
    const getByUser = (data) =>{
        if(data){

            const response = commonCal.getDates()
            setDates(response)
            setSelectedDurationDates({startDate: response.startToday, endDate: response.endToday}) 
            
            if(initialValue?.userType == 'customer'){

                const accData = data?.filter((x)=>{ return x.userid == initialValue?.userid })
                setAllAccounts(accData)
                setSelectedAccount(accData.length > 0 ? accData[0] : accData)
                getTransactionByUser({"userType": initialValue?.userType ,"userid": initialValue?.userid,date:{startDate: response.startToday, endDate: response.endToday}})

            }else{
                setAllAccounts([...allAccounts,...data])
                getTransactionByUser({date:{startDate: response.startToday, endDate: response.endToday}})    
            }
        }
    }
    
    const getTransactionByUser = async(data) =>{
        setLoading(true)
        const response = await apiServices.transactionsByUser({...data,"detailWise":false});
        console.log(response?.data?.data);
        setTransStats(response?.data?.data[0]?.totalCount[0])
        setTransChartData(response?.data?.data[0]?.dayWise)
        setLoading(false)
    }

    const getFiltered = (data) =>{
        if(data?.length > 0){

            if(initialValue?.userType == 'customer'){

                setAllMachines([...data])     
                setSelectedMachine(data[0])
                const response = commonCal.getDates()
                setDates(response)
                getTransStats({id:data[0]?.id,date:{startDate: response.startToday, endDate: response.endToday}})
                getTransactionStatsByFilter({id:data[0]?.id,date:{startDate: response.startToday, endDate: response.endToday}})
                setSelectedDurationDates({startDate: response.startToday, endDate: response.endToday})    

            }else{
   
                setAllMachines([...allMachines,...data])     
                const response = commonCal.getDates()
                setDates(response)
                getTransStats({date:{startDate: response.startToday, endDate: response.endToday}})
                getTransactionStatsByFilter({date:{startDate: response.startToday, endDate: response.endToday}})

                setSelectedDurationDates({startDate: response.startToday, endDate: response.endToday})    
                setSelectedMachine(allMachines[0])
            }
        }
    }

    const getTransStats = async(data) =>{        
        const response = await apiServices.getTransactrionStats(data)
        console.log(response?.data?.data);
        setTransStats(response?.data?.data[0])
        setLoading(false)
    } 

    const getTransactionStatsByFilter = async(data) => {
        const response = await apiServices.getTransactionStatsByFilter(data)
        // console.log(response.data.data);
        setTransChartData(response?.data?.data)
    }

    const getDetailedTransactions = async(data) => {
        if(data.id){
            const response = await apiServices.getTransactions(data)
            console.log(response.data.data);
            setTransTableData(response?.data?.data)
        }
    }

    const changeAcc = (e) =>{
        console.log(e.value);
        setSelectedAccount(e.value)
        if(e.value?.company == 'All'){
            getTransactionByUser({date:selectedDurationDates})
            setActiveIndex(0)
        }else{
            getTransactionByUser({"userType": e.value.userType ,"userid": e.value.userid,date:selectedDurationDates})
        }
    }
    
    const changeMachine = (e) =>{
        console.log(e.value);
        setSelectedMachine(e.value)

        // if(e.value?.displayLabel == 'All'){
        //     const mchns = allMachines?.map((x)=>{ return x.id;})
        //     const filValue = mchns.filter(item => item)

        //     console.log(filValue);

        //     getTransStats({id:filValue,date:selectedDurationDates})
        //     getTransactionStatsByFilter({id:filValue,date:selectedDurationDates})
        //     getDetailedTransactions({id:filValue,date:selectedDurationDates})
            
        // }else{

            getTransStats({id:e.value.id,date:selectedDurationDates})
            getTransactionStatsByFilter({id:e.value.id,date:selectedDurationDates})
            getDetailedTransactions({id:e.value.id,date:selectedDurationDates})

        // }

    }

    const updateData = (data) => {
            getTransStats(data)
            getTransactionStatsByFilter(data)
            getDetailedTransactions(data)
    }

    // setSelectedAccount(e.value)
        // getTransactionByUser({"userType": selectedAccount.userType ,"userid": selectedAccount.userid,date:selectedDurationDates})
        // getTransactionByUser({"userType": selectedAccount.userType ,"userid": selectedAccount.userid,date:selectedDurationDates})

        const changeDuration = (e) =>{
            e.value.code !== 'custom' && setSelectedDuration(e.value);
            const userData = selectedAccount && {"userType": selectedAccount.userType ,"userid": selectedAccount.userid}
            const isSingleAcc = selectedAccount.company == 'All' ? false : true;

            switch (e.value.code) {
                
                case 'till_date':{
                        isSingleAcc ? getTransactionByUser({...userData}) : getTransactionByUser({})
                        setSelectedDurationDates(null)
                    break;
                }
                case 'today':{
                        const date = {startDate:dates?.startToday,endDate:dates.endToday}
                        isSingleAcc ? getTransactionByUser({...userData,date}) : getTransactionByUser({date})
                        setSelectedDurationDates(date)
                    break;
                }
                case 'yesterday':{
                        const date = {startDate:dates?.YestStart,endDate:dates.EndStart}
                        isSingleAcc ? getTransactionByUser({...userData,date}) : getTransactionByUser({date})
                        setSelectedDurationDates(date)
                    break;
                }
                case 'this_week':{
                        const date = {startDate:dates?.weekstart,endDate:dates.weekEnd}
                        isSingleAcc ? getTransactionByUser({...userData,date}) : getTransactionByUser({date})
                        setSelectedDurationDates(date)
                    break;
                }
                case 'this_month':{
                        const date = {startDate:dates?.monthStart,endDate:dates.endThisMonth}
                        selectedAccount ? getTransactionByUser({...userData,date}) : getTransactionByUser({date})
                        setSelectedDurationDates(date)
                    break;
                }
                case 'last_month':{
                        const date = {startDate:dates?.lastStart,endDate:dates.lastend}
                        isSingleAcc ? getTransactionByUser({...userData,date}) : getTransactionByUser({date})
                        setSelectedDurationDates(date)
                    break;
                }
                case 'custom':
                    setCustomFilterDialog(true)
                break;
            
                default:{
                        const date = {startDate:dates?.startToday,endDate:dates.endToday}
                        setSelectedDurationDates(date)
                    break;
                }
            }
        }


    const changeCustomDate = e =>{
        setSelectedCustomDate({...selectedCustomDate,[e.target.name]:e.target.value})
    }

    const customSubmit = (e) =>{
            e.preventDefault()
            const startt = new Date(selectedCustomDate.start_date).setHours(0, 0, 0, 0);
            const endd = new Date(selectedCustomDate.end_date).setHours(23, 59, 59, 0);
            const date = { startDate: new Date(startt).toISOString(), endDate:new Date(endd).toISOString() };
            // updateData({id:selectedMachine?.id,date:date})
            (selectedAccount?.company == 'All') ? getTransactionByUser({date}) : getTransactionByUser({"userType": selectedAccount.userType ,"userid": selectedAccount.userid,date});
            setSelectedDurationDates(date)
            setCustomFilterDialog(false)
            setSelectedDuration(null)
    }

    const leftToolbarTemplate = () => {
        return (
            <>
                {/* <Chip label={`Total No. Of CTVM : ${allMachines?allMachines?.length:'-'}`}  icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" /> */}
                {/* <Chip label={`Total No. Of Paytm CTVM : ${allMachines ? allMachines?.filter((x)=>{ return x.mchmode && x.mchmode.paymode.online == true}).length :'-'}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />                 */}
            </>
        )
    }


    const rightToolbarTemplate = () => {
        return (
            <>
                <div className="p-inputgroup p-mr-2">
                    <Button className='custom-group-btn' label="ACC" />
                    <Dropdown value={selectedAccount} onChange={(e)=>{changeAcc(e)}} options={allAccounts} optionLabel="company" placeholder="Select Filter" filter
                        className="p-m-0 multiselect-custom" />
                </div>

                {/* <div className="p-inputgroup p-mr-2">
                    <Button className='custom-group-btn' label="CTVM" />
                    <Dropdown value={selectedMachine} onChange={(e)=>{changeMachine(e)}} options={allMachines} optionLabel="displayLabel" placeholder="Select Filter" filter
                        className="p-m-0 multiselect-custom" />
                </div> */}

                <div className="p-inputgroup ">
                    <Button className='custom-group-btn' label="DURATION" />
                    <Dropdown value={selectedDuration} onChange={(e)=>{changeDuration(e)}} options={props.durationOptions} optionLabel="name" placeholder="Custom" filter
                        className="p-m-0 multiselect-custom" />
                </div>
            </>
        )
    }


    return (
        <>
            <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

            <div className="layout-dashboard">
                <div className="p-grid">
                        <div className="p-col-12 p-md-3">
                            <TransactionStats title={'Total QR Transaction'} value={transStats?transStats.total_txn:'0'} icon="pi pi-sliders-h" loading={loading} />
                        </div>
                        <div className="p-col-12 p-md-3">
                            <TransactionStats title={'Total Dispensed'} value={transStats?transStats.succ:'0'} icon="pi pi-sign-out" loading={loading} />
                        </div>
                        <div className="p-col-12 p-md-3">
                            <TransactionStats title={'Total Refundable'} value={transStats?transStats.refund:'0'} icon="pi pi-wallet" loading={loading} />
                        </div>
                        <div className="p-col-12 p-md-3">
                            <TransactionStats title={'Others'} value={transStats?transStats.others:'0'} icon="pi pi-th-large" loading={loading} />
                        </div>
                </div>
            </div>

            <div className="card">

                {
                    // selectedMachine?.displayLabel != 'All' ?
                    selectedAccount?.company != 'All' ?

                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="DAYWISE QR TRANSACTION ON CVES">
                            <DayQrTransactionsChart transChartData={transChartData}/>
                        </TabPanel>
                        <TabPanel header="DETAILED QR TRANSACTION">
                            <DetailedQrTable transTableData={transTableData} getDetailedTransactions={getDetailedTransactions} selectedDurationDates={selectedDurationDates} selectedAccount={selectedAccount}/>  
                        </TabPanel>
                    </TabView>
                    :
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="DAYWISE QR TRANSACTION ON CVES">
                            <DayQrTransactionsChart transChartData={transChartData}/>
                        </TabPanel>
                    </TabView>
                }

            </div>

            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter"  onHide={()=>{setCustomFilterDialog(false)}} modal className="p-fluid">
                <form onSubmit={customSubmit}>
                <div className='p-grid'>
                    <div className='p-col-6'>
                        <label htmlFor="from">From</label>
                        <InputText id="from" type="date" name='start_date' value={selectedCustomDate?.start_date} onChange={changeCustomDate} required autoFocus/>
                    </div>
                    <div className='p-col-6'>
                        <label htmlFor="to">To</label>
                        <InputText id="to" type="date" name='end_date' value={selectedCustomDate?.end_date} onChange={changeCustomDate} required/>
                    </div>
                </div>
                <div className="p-dialog-footer p-mt-4">
                    <button className="p-button p-button p-button-danger" onClick={()=>{setCustomFilterDialog(false)}}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
                    <button className="p-button p-button-success" type="submit"><span className="p-button-icon p-c pi pi-check p-button-icon-left"></span><span className="p-button-label p-c">Submit</span><span className="p-ink"></span></button>
                </div>
                </form>
            </Dialog>


        </>
    )
}