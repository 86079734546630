import React, { useState, useRef, useEffect } from "react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
// components
import { OnBoardMachineDialog } from "./OnBoardMachineDialog";

// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

export const CapsuleOnBoard = (props) =>{

    const [loading,setLoading] = useState(true)
    const [capsuleData,setCapsuleData] = useState(null)
    const [globalFilter, setGlobalFilter] = useState(null)
    const [onBoardDialog, setOnBoardDialog] = useState(false)
    const [machineType, setMachineType] = useState('Capsule')
    const [selectedMachineData, setSelectedMachineData] = useState(null)
    const [deletedMachineData, setDeletedMachineData] = useState(null)
    
    const [deleteDialog, setDeleteDialog] = useState(false)

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(()=>{
        getCapsules()
    },[])

    const getCapsules = async() =>{

        const response = await apiServices.findCapsuleMachines()
        const response1 = await apiServices.getUserReduced();

        let arr1 = []
        response?.data?.data.map((x)=>{
            response1?.data?.data.filter((y)=>{
                if(x.customer == y.userid){
                    arr1.push({...x,accountName:y.name,companyName:y?.billing_details?.company ? y?.billing_details?.company : 'Cherise India Pvt. Ltd.',facilityName:x?.subscription?.facilitydata?.facname ? x.subscription.facilitydata?.facname : 'NA' })
                }
            })
        })

        const onBoardmachines = arr1?.filter((m)=>{ return  m.datastore.stage == "finishedgoods" && m.subscription && (m.subscription.package.site == "demo" || m.subscription?.package.site == "permanent") && m.siteName != ""  })
        setCapsuleData(onBoardmachines)
        setLoading(false)
        console.log(onBoardmachines);
    }

    const exportCSV = () => {dt.current.exportCSV()}

    const openOnBoardDialog = () =>{
        setOnBoardDialog(true)
        setSelectedMachineData(null)
    }

    const editData = (data) => {
        setSelectedMachineData(data)
        setOnBoardDialog(true)
    }

    const openDelete = (data) =>{
        setDeletedMachineData(data)
        setDeleteDialog(true)
    }

    const deleteCapsule = async() =>{
        console.log(deletedMachineData)
        const response = await apiServices.deleteCapsule({id:deletedMachineData.id})
        if(response?.data?.success){
            toast.current.show({ severity: 'success', summary: 'Successful', detail:'Capsule Machine Deleted Successfully', life: 3000 })
        }else{
            toast.current.show({ severity: 'error', summary: 'Failed', detail:'Failed to Delete', life: 3000 })
        }
        setDeleteDialog(false)
        getCapsules()
    }


    const header = (
        <div className="table-header">
            <h6>ONBOARDED CAPSULES</h6>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4'  onClick={exportCSV}/>
                </div>
            </span>
        </div>
    );

    const rightToolbarTemplate = () =>{
        return (
            <><Button className='p-custom-btn' label="ON-BOARD CAPSULE" icon="pi pi-plus-circle" iconPos="left" onClick={openOnBoardDialog}/></>
        )
    } 
    
    const srBodyTemplate = (rowData, props) => (
            <>
                <span className="p-column-title">{props.header}</span>
                {props.rowIndex + 1}
            </>
    )

    const commonBodyTemplate = (rowData,props) => (
            <>
            <span className="p-column-title">{props.header}</span>
                {rowData[props.field]}
            </>
    )

    const actionBodyTemplate = (rowData,props) => (
            <>
                <span className="p-column-title">{props.header}</span>
                <div className="actions" style={{display:"flex"}}>
                    { props.rolePermissions?.editOnboardCapsule && <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-success" aria-label="Edit" onClick={()=>{editData(rowData)}} /> }
                    { props.rolePermissions?.deleteOnboardCapsule && <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-2" aria-label="Delete" onClick={()=>{openDelete(rowData)}} /> }
                </div>
            </>
    )

    const machineProp = {
        onBoardDialog : onBoardDialog, 
        setOnBoardDialog : setOnBoardDialog, 
        machineType:machineType, 
        selectedMachineData:selectedMachineData
    }

    const deleteMchDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-success p-mr-2 p-mb-2" onClick={()=>{setDeleteDialog(false)}} />
            <Button label="Yes, Delete it" icon="pi pi-trash" className="p-button-danger p-mr-2 p-mb-2" onClick={deleteCapsule}/>
        </>
    )


    return (
        <>
            <Toast ref={toast} />
            { props.rolePermissions?.onboardCapsule && <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar> }
            
            <DataTable ref={dt} value={capsuleData} 
                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                globalFilter={globalFilter} emptyMessage="No data found." header={header} exportFilename={`On-Boarded Capsule Machines`}
                loading={loading}
                >
                <Column field="srid" header="Sr.No." body={srBodyTemplate} exportable={false}></Column>
                <Column field="id" header="Capsule Id" body={commonBodyTemplate} sortable></Column>
                <Column field="subid" header="Subscription Id" body={commonBodyTemplate} sortable></Column>
                <Column field="name" header="Name" body={commonBodyTemplate} sortable></Column>
                <Column field="companyName" header="Account Name/Location" body={commonBodyTemplate} sortable></Column>
                <Column field="facilityName" header="Facility" body={commonBodyTemplate} sortable></Column>
                <Column field="srid" header="Actions" body={actionBodyTemplate} exportable={false}></Column>
            </DataTable>

            <OnBoardMachineDialog {...machineProp} />
            {/* <OnBoardMachineDialog onBoardDialog={onBoardDialog} setOnBoardDialog={setOnBoardDialog} machineType={machineType} selectedMachineData={selectedMachineData} /> */}
            
            <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteMchDialogFooter} onHide={()=>{setDeleteDialog(false)}}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br/><br/>
                        <b><span>Are you sure you want to delete capsule?</span></b> <br/><br/>
                        <span>You wont be able to revert.</span> 
                    </center>
                </div>
            </Dialog>    

        </>
    )
}