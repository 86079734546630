import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { Chip } from "primereact/chip";
// Common
import { userTypeDropdown } from "./Constants";
import { CommonBodyForFormattedStrTable, CommonBodyForTable, DateBodyForTable, commonCloseDialogFooter, srBodyTemplate } from "../Common/CommonComponents";
// Services
import ApiServices from "../../service/api/ApiServices";
import { convertDateFn } from "../Common/CommonFunctions";
import { SubAnalyticalStat } from "../NewAnalytics.js/SubAnalyticalStat";
const apiServices = new ApiServices();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ==================================================================================================
// ==================================================================================================

export const AccountMaterialDetails = (props) => {

    const [allMaterialData, setAllMaterialData] = useState(null);
    const [materialData, setMaterialData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilter1, setGlobalFilter1] = useState(null);
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [materialDialog, setMaterialDialog] = useState(false);
    const [selectedUserType, setSelectedUserType] = useState({ name: 'All', code: 'all' })
    const [accMaterialData, setAccMaterialData] = useState(null);
    const [materialLoading, setMaterialLoading] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [materialByBatchDialog, setMaterialByBatchDialog] = useState(false)
    const [selectedBatch, setSelectedBatch] = useState(null)
    const [viewCvesDetailedDialog, setViewCvesDetailedDialog] = useState(false);
    const [materialInfo, setMaterialInfo] = useState(null);
    const [orderDialog, setOrderDialog] = useState(false);
    const [orderLoading, setOrderLoading] = useState(true);
    

    const dt = useRef(null);
    const dt1 = useRef(null);
    const dt2 = useRef(null);

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        setLoading(true)
        // const response = await apiServices.getUsersByCTVM();
        const response = await apiServices.getUsersForBilling();
        const sortedArr = response?.data?.data.sort(function (a, b) {
            if (a.name?.trim() < b.name?.trim()) { return -1; }
            if (a.name?.trim() > b.name?.trim()) { return 1; }
            return 0;
        })
        console.log(sortedArr);
        const newArr = sortedArr?.map((x)=>{ return {...x,company : x.billing_details?.company}})
        const finalArr = newArr?.sort((a, b) => a?.company?.trim() !== b?.company?.trim() ? a?.company?.trim() < b?.company?.trim() ? -1 : 1 : 0)


        if(initialValue?.userType === 'customer'){
            const filteredArr = finalArr?.filter((x)=>{ return x.userid == initialValue?.userid})
            setAllMaterialData(filteredArr)
            setMaterialData(filteredArr)

            if(filteredArr?.length > 0){
                getAccMaterialData(filteredArr[0])
                setSelectedAccount(filteredArr[0])
            }
        }else{
            setAllMaterialData(finalArr)
            setMaterialData(finalArr)
        }
        setLoading(false)
    }

    const exportCSV = () => { dt.current.exportCSV() }
    const materialExportCSV = () => { dt1.current.exportCSV() }
    const materialBatchExportCSV = () => { dt2.current.exportCSV() }

    const changeUserType = (e) => {
        setSelectedUserType(e.value)
        if (e.value.code == 'all') {
            setMaterialData(allMaterialData)
        } else if (e.value.code == 'customer' || e.value.code == 'distributor') {
            const data = allMaterialData?.filter((x) => { if (x.userType == e.value.code) { return x; } })
            setMaterialData(data)
        }
    }

    const openMaterialDialog = async (data) => {
        setAccMaterialData(null)
        setMaterialDialog(true)
        setMaterialLoading(true)
        setSelectedAccount(data)

        // const payloadData = { userType: data?.userType,  userid: data?.userid }
        // const response = await apiServices.getCustomerAssignedBatch(payloadData)
        // console.log(response?.data?.batch);
        // setAccMaterialData(response?.data?.batch)
        getAccMaterialData(data)
        // setMaterialLoading(false)
    }

    const getAccMaterialData = async(data) =>{
        setMaterialLoading(true)
        const payloadData = { userType: data?.userType,  userid: data?.userid }
        const response = await apiServices.getCustomerAssignedBatch(payloadData)
        console.log(response?.data?.batch);
        setAccMaterialData(response?.data?.batch)
        setMaterialLoading(false)
    }


    const viewCVESModal = (rowData) =>{
        console.log(rowData);
        setViewCvesDetailedDialog(true)
        setSelectedAccount(rowData)
    }

    const openMaterialByBatchDialog = (data) => {
        console.log(data);
        setSelectedBatch(data)
        setMaterialByBatchDialog(true)
    }

    const openOrderDetails = (data) =>{
        console.log(data);
        setOrderDialog(true)
        setSelectedBatch(data)
        getOrderDetails({ orderId : data?.batch_data?.orderId })
    }

    const getOrderDetails = async(payload) =>{
        setOrderLoading(true)
        const response = await apiServices.orderDetails(payload);
        if(response?.data?.data?.length > 0){

            console.log(response?.data?.data[0]);

            const materialData = response?.data?.data[0].materialInfo.map((x)=>{
                return {...x, totalQuantity : x.quantity * x.weight }
            })

            console.log(materialData);

            const filData = {...response?.data?.data[0], materialInfo : materialData}

            console.log(filData);

            setMaterialInfo(filData);

            // setMaterialInfo(response?.data?.data[0]);
            setOrderLoading(false)
        }else{
            setOrderLoading(false)
        }
    }

    const hideMaterialDialog = () => {
        setMaterialDialog(false)
        setGlobalFilter1(null)
    }

    const hideBatchMaterialDialog = () => {
        setMaterialByBatchDialog(false)
        setGlobalFilter2(null)
    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                {
                    initialValue?.userType == 'owner' &&
                    <div className="p-inputgroup p-m-1" >
                        <Button className="custom-group-btn" label="USER TYPE" />
                        <Dropdown value={selectedUserType} onChange={changeUserType} options={userTypeDropdown} optionLabel="name" placeholder="Select User Type" filter className="p-mr-2 multiselect-custom" />
                    </div>
                }

            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    {/* <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV}/> */}
                </div>
            </span>
        </div>
    );

    const header1 = (
        <div className="table-header">
            <span className="p-input-icon-right"></span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter1(e.target.value)} placeholder="Search..." />
                    {/* <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={materialExportCSV}/> */}
                </div>
            </span>
        </div>
    );

    const header2 = (
        <div className="table-header">
            <span className="p-input-icon-right"></span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Search..." />
                    {/* <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={materialBatchExportCSV}/> */}
                </div>
            </span>
        </div>
    );

    // const srBodyTemplate = (rowData, props) => (<><span className="p-column-title">Sr.No</span>{props.rowIndex + 1}</>)
    const userTypeBodyTemplate = (data, props) => { return ( (<><span className="p-column-title">{props.header}</span>{data[props.field] == 'customer' ? 'Account' : data[props.field]}</>) )}

    const machineTemplate = (data, props) => {
        return(
            <>
                <span className="p-column-title">{props.header}</span>{data[props.field] ? data[props.field]?.join(', ') : "-"}
            </>
        )
    }

    const namingTemplate = (data, props) => ( <> <span className="p-column-title">{props.header}</span>{data?.name + " " + data?.lastName} </>)
    const mobileBodyForTable = (data, props) => ( <><span className="p-column-title">{props.header}</span>{data[props.field]?.length == 10 ? ('+91' + data[props.field]) : data[props.field]}</>)
    const addressBodyForTable = (data, props) => ( <><span className="p-column-title">{props.header}</span>{data?.addr?.addr ? data?.addr?.addr : "-" }</>)
    const CompanyTemplate = (data, props) => ( <><span className="p-column-title">{props.header}</span>{data?.billing_details?.company}</>)


    const dateBodyTemplate = (data, props) => {
        const conDate = new Date(data[props.field]).toUTCString();
        return (<><span className="p-column-title">{props.header}</span>{conDate}</>)
    }

    const actionBodyTemplate = rowData => (
        <>
            <span className="p-column-title">Action</span>
            <div className="actions" style={{ display: "flex" }}>
                <Button icon="pi pi-eye" title="View Invoices" className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={() => { openMaterialDialog(rowData) }} />
                <Button icon="pi pi-info-circle" title='View CVES Details' className="p-button-rounded p-button-warning p-mr-2 p-mb-2" onClick={()=>{viewCVESModal(rowData)}}/>
            </div>
        </>
    )

    const actionBodyTemplate1 = rowData => (
        <>
            <span className="p-column-title">Action</span>
            <Button icon="pi pi-list" label="Inventory Breakup" title="View Details" className="p-button-rounded p-button-success p-m-1" onClick={() => { openMaterialByBatchDialog(rowData) }} />
            <Button icon="pi pi-bars" label="Order Details" title="Order Details" className="p-button-rounded p-button-warning p-m-1" onClick={() => { openOrderDetails(rowData) }} />
        </>
    )

    const leftToolbarTemplate = () => (

        <>
            <Chip label={`Batch Id : ${selectedBatch?.batch_id}`} icon="pi pi-angle-double-right" className="p-mr-1" />
            <Chip label={`Delivery Date : ${convertDateFn(selectedBatch?.date)}`} icon="pi pi-angle-double-right" className="p-mr-1" />
            {/* <Chip label={`Delivery By : ${selectedBatch?.batch_data?.deliveryBy?.name}`}  icon="pi pi-angle-double-right" className="p-mr-1"/> */}
            <Chip label={`Total Weight : ${selectedBatch?.batch_data?.totalweight} Gms`} icon="pi pi-angle-double-right" className="p-mr-1" />
        </>
    )

    const rightToolbarTemplate = () => (

        <>
            <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={materialBatchExportCSV}/>
        </>
    )

    const batchBodyForTable = (data, props) =>( 
        <> <span className="p-column-title">{props.header}</span>{data.batch_data[props.field] ? data?.batch_data[props.field] : 'NA'} </> 
    )


    const hideCvesDialog = () =>{setViewCvesDetailedDialog(false)}
    const hideOrderDialog = () =>{setOrderDialog(false)}

    const materialDialogFooter = commonCloseDialogFooter('Cancel', hideMaterialDialog)
    const batchMaterialDialogFooter = commonCloseDialogFooter('Cancel', hideBatchMaterialDialog)
    const cvesDialogFooter = commonCloseDialogFooter('Close', hideCvesDialog)
    const orderDialogFooter = commonCloseDialogFooter('Cancel', hideOrderDialog)

    

    // const cvesDialogFooter = (
    //     <>
    //         <Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={()=>{setViewCvesDetailedDialog(false)}} />
    //     </>
    // )
    
    return (
        <>
            <div className="p-grid crud-demo">
                <div className="p-col-12">

                    {
                        initialValue?.userType !== 'customer' &&
                        <div className="card">
                            
                            {/* <div className="p-grid">
                                <SubAnalyticalStat icon="pi pi-bars" header="Refilled Inventory" value={'Hello'} loading={loading} />
                                <SubAnalyticalStat icon="pi pi-bars" header="Delivered Inventory" value={'Hello'} loading={loading} />
                                <SubAnalyticalStat icon="pi pi-bars" header="Used Inventory" value={'Hello'} loading={loading} />
                            </div> */}

                            <DataTable className="datatable-responsive" ref={dt} value={materialData} paginator rows={10} rowsPerPageOptions={[5, 10, 25]} loading={loading} globalFilter={globalFilter} header={header} emptyMessage="No Data found." footer={`In total there are ${materialData?.length ? materialData?.length : 0} accounts.`}
                                exportFilename={`Accounts`} >
                                <Column field="id" header="Sr.No." body={srBodyTemplate} exportable={false}></Column>
                                <Column field="company" header="Account Name" body={CommonBodyForTable} sortable></Column>
                                <Column field="Customer_Account_ID" header="Account ID" body={CommonBodyForTable} sortable></Column>
                                <Column field="name" header="Account User Name" body={namingTemplate} sortable></Column>
                                {/* <Column field="machineId" header="CVES Id" body={machineTemplate} sortable></Column> */}
                                {/* <Column field="userType" header="Account Type" body={userTypeBodyTemplate} sortable></Column> */}
                                <Column field="cno" header="Contact Number" body={mobileBodyForTable} sortable></Column>
                                <Column field="addr" header="Address" body={addressBodyForTable} sortable></Column>
                                <Column header="Actions" body={actionBodyTemplate} ></Column>
                            </DataTable>
                        </div>
                    }

                    {
                        initialValue?.userType === 'customer' && 
                        <div className="card">
                            <div className="p-grid table-demo p-m-2">
                                <DataTable ref={dt1} value={accMaterialData} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover emptyMessage="No Data found." header={header1}
                                    exportFilename={`Refill Details ${selectedAccount?.company}`} globalFilter={globalFilter1} loading={materialLoading}>
                                    <Column field="id" header="Sr.No." body={srBodyTemplate} exportable={false}></Column>
                                    {/* <Column field="batch_id" header="Batch Id" sortable body={CommonBodyForTable} ></Column> */}
                                    {/* <Column field="batch_name" header="Batch Name" sortable body={CommonBodyForTable} ></Column> */}
                                    <Column field="SDid" header="Order Id" sortable body={batchBodyForTable} ></Column>
                                    <Column field="totalweight" header="Total Weight (gms)" sortable body={batchBodyForTable} ></Column>
                                    <Column field="date" header="Date & Time" sortable body={DateBodyForTable} ></Column>
                                    <Column header="Action" body={actionBodyTemplate1} ></Column>
                                </DataTable>
                            </div>
                        </div>
                    }
                    
                </div>
            </div>

            <Dialog visible={materialDialog} style={{ width: "1250px" }} header={`Refill Details (${selectedAccount?.company}) `} modal className="p-fluid" footer={materialDialogFooter} onHide={hideMaterialDialog} maximizable={true} blockScroll={true}>
                <div className="card">
                    <div className="p-grid table-demo p-m-2">
                        <DataTable ref={dt1} value={accMaterialData} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover emptyMessage="No Data found." header={header1}
                            exportFilename={`Refill Details ${selectedAccount?.company}`} globalFilter={globalFilter1} loading={materialLoading}>
                            <Column field="id" header="Sr.No." body={srBodyTemplate} exportable={false} style={{width:'6rem'}}></Column>
                            <Column field="batch_id" header="Batch Id" sortable body={CommonBodyForTable} style={{width:'10rem'}} ></Column>
                            <Column field="batch_name" header="Batch Name" sortable body={CommonBodyForTable} ></Column>
                            <Column field="SDid" header="Order Id" sortable body={batchBodyForTable} ></Column>
                            <Column field="totalweight" header="Total Weight (gms)" sortable body={batchBodyForTable} ></Column>
                            <Column field="date" header="Date & Time" sortable body={DateBodyForTable} ></Column>
                            <Column header="Action" body={actionBodyTemplate1} ></Column>
                        </DataTable>
                    </div>
                </div>
            </Dialog>


            <Dialog visible={materialByBatchDialog} style={{ width: "950px" }} header={`Refill Details By Batch (${selectedAccount?.company})`} modal className="p-fluid" footer={batchMaterialDialogFooter} onHide={hideBatchMaterialDialog} maximizable={true} blockScroll={true}>
                <div className="card">
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>
                    <div className="p-grid table-demo p-m-2">
                        <DataTable ref={dt2} value={selectedBatch?.batch_data?.barcodes} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover emptyMessage="No Data found." header={header2}
                            exportFilename={`Batch Refill Details (${selectedBatch?.batch_id})`} globalFilter={globalFilter2} loading={materialLoading}>
                            <Column field="id" header="Sr.No." body={srBodyTemplate} exportable={false}></Column>
                            <Column field="materialName" header="Material Name" sortable body={CommonBodyForFormattedStrTable} ></Column>
                            <Column field="barcode" header="Barcode" sortable body={CommonBodyForTable} ></Column>
                            <Column field="weight" header="Weight (Gms)" sortable body={CommonBodyForTable} ></Column>
                            <Column field="stage" header="Stage" sortable body={CommonBodyForFormattedStrTable} ></Column>
                        </DataTable>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={viewCvesDetailedDialog} style={{ width: '450px' }} header={`CVES Details`} modal footer={cvesDialogFooter} onHide={()=>{setViewCvesDetailedDialog(false)}} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className="p-grid table-demo">
                <div className="p-col-12">
                <div className="card">
                    {/* <Toast ref={toast} /> */}
                    <DataTable value={selectedAccount?.cves_data} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover
                            globalFilter={globalFilter2} emptyMessage="No CVES Found.">
                        <Column field="srno" header="Sr. No." body={srBodyTemplate} ></Column>
                        <Column field="id" header="CVES ID" body={CommonBodyForTable}></Column>
                        <Column field="subid" header="Subscription ID" body={CommonBodyForTable}></Column>
                    </DataTable>
                </div>
                </div>
                </div>
            </Dialog>

            <Dialog visible={orderDialog} style={{ width: "950px" }} header={`Order Details (Order Id : ${materialInfo?.SDid})`} modal className="p-fluid" footer={orderDialogFooter} onHide={hideOrderDialog} maximizable={true} blockScroll={true}>
                <div className="card">
                
                    <div className="p-grid table-demo p-m-2">
                        <DataTable value={materialInfo?.materialInfo} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover emptyMessage="No Data found." loading={orderLoading}>
                            <Column field="id" header="Sr.No." body={srBodyTemplate} sortable exportable={false}></Column>
                            <Column field="name" header="Material Name"  body={CommonBodyForFormattedStrTable} ></Column>
                            <Column field="Master_sku_name" header="SKU Name"  body={CommonBodyForTable} ></Column>
                            <Column field="quantity" header="Quantity" body={CommonBodyForTable} ></Column>
                            <Column field="weight" header="Weight (gms)"  body={CommonBodyForTable} ></Column>
                            <Column field="totalQuantity" header="Total Weight (gms)"  body={CommonBodyForTable} ></Column>
                        </DataTable>
                    </div>
                </div>
            </Dialog>
            
        </>
    )
}