import React,{useState, useEffect} from "react"
import { TabView } from "primereact/tabview"
import { TabPanel } from "primereact/tabview"
import { Toolbar } from "primereact/toolbar"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { DataView, DataViewLayoutOptions } from "primereact/dataview"
// Components
import { MchWiseTicketChart } from "./MchWiseTicketChart"
import CatWiseTicketChart from "./CatWiseTicketChart"
import DayWiseTicketChart from "./DayWiseTicketChart"
import { SubAnalyticalStat } from "../NewAnalytics.js/SubAnalyticalStat"
import { SubAnalyticalStatForTickets } from "../NewAnalytics.js/SubAnalyticalStat"
// Services
import CommonCal from "../../service/common/CommonCal"
import ApiServices from "../../service/api/ApiServices"
const apiServices = new ApiServices();
const commonCal = new CommonCal();

export const TicketAnalytics = () =>{

    const TimeSelectValues = [
        {name:'All',code:'all'},
        {name:'Today',code:'today'},
        {name:'Yesterday',code:'yesterday'},
        {name:'This Week',code:'this_week'},
        {name:'This Month',code:'this_month'},
        {name:'Last Month',code:'last_month'},
        {name:'Custom',code:'custom'}
    ]

    const [loading,setLoading] = useState(true)
    const [allTickets,setAllTickets] = useState([])
    const [selectedDuration,setSelectedDuration] = useState({name:'This Month',code:'this_month'})
    // const [selectedDuration,setSelectedDuration] = useState({name:'All',code:'all'})
    const [datesData,setDatesData] = useState(null)
    const [selectedDate, setSelectedDate] = useState({})
    const [customFilterDialog, setCustomFilterDialog] = useState(null)
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    const [allTicketsData, setAllTicketsData] = useState(null)
    const [ticketDataForChange, setTicketDataForChange] = useState(null)
    const [ticketsDialog, setTicketsDialog] = useState(false)
    const [layout, setLayout] = useState('list');

    useEffect(()=>{
        // getStats({})
        getDates()
    },[])

    const getDates = async() =>{
        const response = commonCal.getDates()
        setDatesData(response)
        const thisMonth = { startDate: response.monthStart, endDate:response.endThisMonth };
        getStats({ date:thisMonth})
        getAllTickets({date:thisMonth})
        setSelectedDate({ date:thisMonth})
    }

    const getStats = async(data) =>{
        const response = await apiServices.getTicketStats(data);
        setAllTickets(response?.data?.data[0])
        setLoading(false)
    }

    const getAllTickets = async(data) =>{
        const response = await apiServices.getTickets(data)
        console.log(response?.data?.data);
        setAllTicketsData(response?.data?.data)
        setTicketDataForChange(response?.data?.data)
    }

    const changeDuartion = (e)=> {

        e.value.code != 'custom' && setSelectedDuration(e.value)

        const today = { startDate: datesData.startToday, endDate: new Date() };
        const yesterday = { startDate: datesData.YestStart, endDate:datesData.EndStart };
        const thisWeek = { startDate: datesData.weekstart, endDate:datesData.weekEnd };
        const thisMonth = { startDate: datesData.monthStart, endDate:datesData.endThisMonth };
        const lastMonth = { startDate: datesData.lastStart, endDate:datesData.lastend };

        switch (e.value.code) {
            case 'today':
                getStats({ date:today})
                getAllTickets({date:today})
                setSelectedDate({ date:today})
                break;
            case 'yesterday':
                getStats({ date:yesterday})
                getAllTickets({date:yesterday})
                setSelectedDate({ date:yesterday})
                break;
            case 'this_week':
                getStats({ date:thisWeek })
                getAllTickets({ date:thisWeek })
                setSelectedDate({ date:thisWeek})
                break;
            case 'this_month':
                getStats({ date:thisMonth})
                getAllTickets({ date:thisMonth })
                setSelectedDate({ date:thisMonth})
                break;
            case 'last_month':
                getStats({ date:lastMonth})
                getAllTickets({ date:lastMonth })
                setSelectedDate({ date:lastMonth})
                break;
            case 'custom':
                setCustomFilterDialog(true);
                break;
            default:
                getStats({})
                getAllTickets({})
                setSelectedDate({})
                break;
        }
    }

    const hideCustomDialog = () =>{ setCustomFilterDialog(false); }

    const handleSubmit = (e) =>{

        setCustomFilterDialog(false)
        e.preventDefault()

        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        const cust_start = new Date(startt).toISOString();
        const cust_end = new Date(endd).toISOString();

        const date = { startDate: cust_start, endDate:cust_end };
        getStats({date:date})
        getAllTickets({date:date})
        setSelectedDate({ date:date})
        // setSelectedDuration({name:'Custom',code:'custom'})
        setSelectedDuration(null)
    }

    const getFilteredData = (data) =>{
        setTicketsDialog(true)
        if(data == 'all'){
            console.log(allTicketsData);
            setTicketDataForChange(allTicketsData)
        }else{
            const filterData = allTicketsData?.filter((x)=>{
                return x.ticketStatus == data;
            })
            console.log(filterData);
            setTicketDataForChange(filterData)
        }
    }


    const rightToolbarTemplate = () => {
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="Duration" />
                    <Dropdown optionLabel="name" placeholder="Custom" filter className="p-mr-2 multiselect-custom" options={TimeSelectValues} value={selectedDuration} onChange={changeDuartion} />
                </div>
            </>
        )
    }

    const customFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
            <button className="p-button p-button-success" type="submit" form="custom-form" ><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
        </>
    )

    const ticketFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={()=>{setTicketsDialog(false)}}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
        </>
    )

    const itemTemplate = (data, layout) => {
        if (!data) {
            return;
        }
        if (layout === 'list') {
            return dataviewListItem(data);
        }
        // else if (layout === 'grid') {
        //     return dataviewGridItem(data);
        // }
    };

    const dataviewListItem = (data) => {
        return (
            <div className="p-col-12 p-md-12">
                <div className="product-list-item">
                    <div className="product-list-detail">                        
                        <span className={`product-badge status-${data.ticketStatus.toLowerCase().replaceAll(' ', '')}`}>{data.ticketStatus}</span>
                        <div className="product-name p-mt-2">{data?.catergory ? data?.catergory : 'Unknown Category'}</div>
                        <h6 className="p-mt-2">{data?.subCatergory ? data?.subCatergory : 'NA'}</h6>
                        <div className="product-description p-mt-2">{data?.ticketQuery}</div>
                        <span><b>Ticket Id :</b> {data?.ticketId}</span>
                        <hr></hr>
                        <span><b>Account Name :</b> {data?.companyName ? data?.companyName : 'NA' }</span>
                        <div className="p-mt-2"> <b>CVES : </b>  {data?.machineId.toString()} </div>
                    </div>
                    <div className="product-list-action">
                        {/* <span className="product-price">Pricwe</span> */}
                        {/* <Button icon="pi pi-shopping-cart" label="Name" disabled={true}></Button> */}
                        {/* <i className="pi pi-tag product-category-icon"></i><span className="product-category">{data.ticketLevel}</span> */}
                        <span className="p-buttonset">
                            {/* <Button icon="pi pi-eye" onClick={()=>{openDoc(data)}} /> */}
                            {/* <Button icon="pi pi-info-circle" onClick={()=>{openView(data)}} /> */}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    const dataviewHeader = () => ( <span>Total Tickets : {ticketDataForChange?.length}</span> )
    const header = dataviewHeader();

    return (
        <>
            <div className="layout-dashboard">
                <div className='card'>

                <Toolbar className=" p-toolbar" right={rightToolbarTemplate} ></Toolbar>
                <div className="p-grid p-my-5">
                    <div className="p-col-12 p-md-4" onClick={()=>{getFilteredData('all')}} style={{cursor:'pointer'}}>
                        <SubAnalyticalStatForTickets icon="pi pi-tags" header="Total Ticket Count" value={allTickets?.totalTickets ? allTickets?.totalTickets : 0} loading={loading} />
                    </div>
                    <div className="p-col-12 p-md-4" onClick={()=>{getFilteredData('OPEN')}} style={{cursor:'pointer'}}>
                        <SubAnalyticalStatForTickets icon="pi pi-check-circle" header="Total Open Tickets" value={allTickets?.openTickets ? allTickets?.openTickets : 0} loading={loading}  />
                    </div>
                    <div className="p-col-12 p-md-4" onClick={()=>{getFilteredData('CLOSED')}} style={{cursor:'pointer'}}>
                        <SubAnalyticalStatForTickets icon="pi pi-times-circle" header="Total Closed Tickets" value={allTickets?.closeTickets ? allTickets?.closeTickets : 0} loading={loading} />
                    </div>
                    <div className="p-col-12 p-md-4" onClick={()=>{getFilteredData('HOLD')}} style={{cursor:'pointer'}}>
                        <SubAnalyticalStatForTickets icon="pi pi-pause" header="Total Hold Tickets" value={allTickets?.holdTickets ? allTickets?.holdTickets : 0} loading={loading} />
                    </div>
                    <div className="p-col-12 p-md-4" onClick={()=>{getFilteredData('REQUESTED CLOSURE')}} style={{cursor:'pointer'}}>
                        <SubAnalyticalStatForTickets icon="pi pi-times-circle" header="Total Requested to Closure Tickets" value={allTickets?.reqToClosuerTickets ? allTickets?.reqToClosuerTickets : 0} loading={loading} />
                    </div>
                </div>

                <TabView>
                    <TabPanel header="CVES Wise Tickets">
                        <MchWiseTicketChart selectedDate={selectedDate}/>
                    </TabPanel>
                    <TabPanel header="Category Wise Tickets">
                        <CatWiseTicketChart selectedDate={selectedDate}/>
                    </TabPanel>
                    <TabPanel header="Day Wise Tickets">
                        <DayWiseTicketChart selectedDate={selectedDate}/>
                    </TabPanel>
                </TabView>
                </div>
            </div>

            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter" footer={customFooter}  onHide={hideCustomDialog} modal className="p-fluid">
                <form onSubmit={handleSubmit} id="custom-form" className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From</label>
                            <InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => {setCustomStart(e.value) } } required autoFocus/>
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' min={customStart} value={customEnd}  onChange={(e) => {setCustomEnd(e.value)}} required/>
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={ticketsDialog} style={{ width: '750px' }} header="Ticket List" footer={ticketFooter}  onHide={()=>{setTicketsDialog(false)}} modal className="p-fluid" blockScroll={true}>
                <div className="p-grid list-demo">
                    <div className="p-col-12">
                        <div className="card">
                            <DataView value={ticketDataForChange} layout={layout} paginator rows={12} itemTemplate={itemTemplate} header={header}></DataView>
                        </div>
                    </div>
                </div>
            </Dialog>


        </>
    )
}