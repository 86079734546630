import React, { useEffect, useRef, useState } from 'react'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'

import ApiServices from '../../service/api/ApiServices';
import { TableWrapper } from '../../utilComponents/DataTableWrapper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BoolenBodyForTable, CommonBodyForTable, DateBodyForTable, srBodyTemplate } from '../Common/CommonComponents';
import { getFormattedString } from '../Common/CommonFunctions';
import { Dropdown } from 'primereact/dropdown';
import CommonCal from '../../service/common/CommonCal';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

const apiServices =  new ApiServices();
const commonCal =  new CommonCal();

const initialValue = JSON.parse(localStorage.getItem("user"));

export const FonePayQrTransactions = (props) => {

    const [loading, setLoading] = useState(true)
    const [globalFilter, setGlobalFilter] = useState('')
    const [fonePayTransactions, setFonePayTransactions] = useState(null)

    const [allAccounts, setAllAccounts] = useState(null)
    const [selectedAccount, setSelectedAccount] = useState(null)
    const [selectedDuration, setSelectedDuration] = useState(props.durationOptions[0])
    const [allDates, setAllDates] = useState(null)
    const [selectedDurationDates, setSelectedDurationDates] = useState(null)
    
    const [customFilterDialog, setCustomFilterDialog] = useState(false)
    const [selectedCustomDate,setSelectedCustomDate] = useState(null)

    const dt = useRef('')

    useEffect(()=>{
        getAccounts()
        getData({})
        getDates()
    },[])


    const getDates = async() =>{
        const response = commonCal.getDates()
        setAllDates(response)
        // setSelectedDurationDates({startDate: response.startToday, endDate: response.endToday})
        setSelectedDurationDates(null)
    }

    const getAccounts = async() =>{ 
        const response = await apiServices.getFonepayMerchantList({});
        response?.data?.data.unshift({ 'company_name' : 'All'})
        console.log(response?.data?.data);
        setAllAccounts(response?.data?.data)
        setSelectedAccount(response?.data?.data[0])
    }

    const getData = async(data) =>{
        const response = await apiServices.getFonepayTransactionList({ "index": 0,"count": 100, ...data });
        console.log(response?.data?.data);
        setFonePayTransactions(response?.data?.data);
        setLoading(false)
    }

    const changeAccount = (e) =>{
        setSelectedAccount(e.value)
        if(e.value?.company_name == 'All'){
            
            getData({ date : selectedDurationDates })
        }else{
            getData({ customerid : e.value?.userid , date : selectedDurationDates})
        }
    }


    const changeDuration = (e) =>{
        setLoading(true)
        setSelectedDuration(e.value)
        switch (e.value.code) {
            case 'today':{
                const date = {date:{startDate:allDates.startToday,endDate:allDates.endToday} , customerid : selectedAccount?.userid }
                getData(date)
                setSelectedDurationDates(date.date)
                break;
            }
            case 'yesterday':{
                    const date = {date:{startDate:allDates.YestStart,endDate:allDates.EndStart, customerid : selectedAccount?.userid}}
                    getData(date)
                    setSelectedDurationDates(date.date)
                break;
            }
            case 'this_week':{

                    const date = {date:{startDate:allDates.weekstart,endDate:allDates.weekEnd}, customerid : selectedAccount?.userid}
                    getData(date)
                    setSelectedDurationDates(date.date)
                break;
            }
            case 'this_month':{

                    const date = {date:{startDate:allDates.monthStart,endDate:allDates.endThisMonth}, customerid : selectedAccount?.userid}
                    getData(date)
                    setSelectedDurationDates(date.date)
                break;
            }
            case 'last_month':{

                    const date = {date:{startDate:allDates.lastStart,endDate:allDates.lastend}, customerid : selectedAccount?.userid}
                    getData(date)
                    setSelectedDurationDates(date.date)
                break;
            }
            case 'custom':
                setCustomFilterDialog(true)
            break;
        
            default:{
                getData({ customerid : selectedAccount?.userid })
                setSelectedDurationDates(null)
                break;
            }       
        }
    }

    const changeCustomDate = e =>{
        setSelectedCustomDate({...selectedCustomDate,[e.target.name]:e.target.value})
    }

    const customSubmit = (e) =>{
        e.preventDefault()
        const startt = new Date(selectedCustomDate.start_date).setHours(0, 0, 0, 0);
        const endd = new Date(selectedCustomDate.end_date).setHours(23, 59, 59, 0);
        const date = { startDate: new Date(startt).toISOString(), endDate:new Date(endd).toISOString() };
        const data = {customerid : selectedAccount?.userid, date:date}
        getData(data)
        setSelectedDurationDates(data.date)
        setCustomFilterDialog(false)
    }

    const nestedBodyForTable = (data, props) =>{
        const arr = props.field.split('.')
        const status = data[arr[0]]?.[arr[1]];

        return(
            <>
                <span className="p-column-title">{props.header}</span>{status ? getFormattedString(status) : 'Failed'} 
            </>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                {
                    initialValue.userType !== 'customer' &&
                    <div className="p-inputgroup p-mr-2">
                        <Button className='custom-group-btn' label="ACC" />
                        <Dropdown value={selectedAccount} options={allAccounts} onChange={changeAccount} optionLabel="company_name" placeholder="Select Filter" filter
                            className="p-m-0 multiselect-custom" />
                    </div>
                }

                <div className="p-inputgroup ">
                    <Button className='custom-group-btn' label="DURATION" />
                    <Dropdown value={selectedDuration} onChange={changeDuration}  options={props.durationOptions} optionLabel="name" placeholder="Custom" filter
                        className="p-m-0 multiselect-custom" />
                </div>
            </>
        )
    }


    return (
        
        <>
            <TableWrapper>
                <Toolbar className="p-mb-4 p-toolbar"  right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={fonePayTransactions} 
                    dataKey="id" paginator rows={20} rowsPerPageOptions={[5, 10, 20]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Transactions"
                    globalFilter={globalFilter} emptyMessage="No Data found." loading={loading} exportFilename={`Fone Pay Transactions`}>
                    <Column field="id" header="Sr.No." sortable body={srBodyTemplate}  exportable={false}></Column>
                    <Column field='machineId' header="CVES Id" body={CommonBodyForTable}></Column> 
                    <Column field="beverage_name" header="Beverage" sortable body={CommonBodyForTable}></Column>
                    <Column field="orderId" header="Order Id" body={CommonBodyForTable} sortable></Column>
                    <Column field="amount" header="Amount" sortable body={CommonBodyForTable}></Column>
                    <Column field="txndetails.paymentStatus" header="Payment Status" sortable body={nestedBodyForTable}></Column>
                    <Column field="dispensed" header="Dispensed" sortable body={BoolenBodyForTable}></Column>
                    <Column field="createdAt" header="Date & Time" sortable body={DateBodyForTable}></Column>
                </DataTable>
            </TableWrapper>


            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter"  onHide={()=>{setCustomFilterDialog(false)}} modal className="p-fluid">
                <form onSubmit={customSubmit}>
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From</label>
                            <InputText id="from" type="date" name='start_date' value={selectedCustomDate?.start_date} onChange={changeCustomDate} required autoFocus/>
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' value={selectedCustomDate?.end_date} onChange={changeCustomDate} required/>
                        </div>
                    </div>
                    <div className="p-dialog-footer p-mt-4">
                        <button className="p-button p-button p-button-danger" onClick={()=>{setCustomFilterDialog(false)}}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
                        <button className="p-button p-button-success" type="submit"><span className="p-button-icon p-c pi pi-check p-button-icon-left"></span><span className="p-button-label p-c">Submit</span><span className="p-ink"></span></button>
                    </div>
                </form>
            </Dialog>

        </>

    )
}
