import React,{useState, useEffect, useRef} from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices();

export const BulkTab = () => {

    const position = [
        { name: "Inactive Distributors",code: "inactiveDistr"},
        { name: "Sales Manager", code: "Sales Manager" },
        { name: "Sales Customer", code: "Sales Customer"}
    ];

    const [loading,setLoading] = useState(true)
    const [globalFilter, setGlobalFilter] = useState("");
    const [defaultPosition,setDefaultPosition] = useState({ name: "Inactive Distributors",code: "inactiveDistr"})
    const [allUsers,setAllUsers] = useState(null)
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(()=>{
        getData()
    },[])

    const getData = async() =>{

        
    }

    const onPositionChange = async(e) =>{
        setDefaultPosition(e.value)
        setLoading(true)

        if(e.value.code == 'Sales Manager'){

            const response = await apiServices.getUsersByType({userType: "Sales Manager"})
            setAllUsers(response?.data?.data);
            setLoading(false)
            
        }else if(e.value.code == 'Sales Customer'){
            
            const response = await apiServices.getUsersByType({userType: "Sales Customer"})
            setAllUsers(response?.data?.data);
            setLoading(false)

        }else{
            console.log("Pending");
        }
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const srBodyTemplate = (rowData,props) => {
        return ( <><span className="p-column-title">Sr. No.</span>{props.rowIndex + 1}</>)
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Profile</span>
                <img src={ !rowData.image?.data ? "assets/demo/images/user/default-user.png" : rowData.image?.data } alt="profile" className="userImage" />
            </>
        )
    }

    const commonBodyTemplate = (rowData,props) => { return ( <> <span className="p-column-title">{props?.header}</span> {rowData[props.field]} </>)}

    const mbBodyTemplate = (rowData,props) => {

        const mb = rowData[props.field]?.length == 10 ? '+91' + rowData[props.field] : rowData[props.field]
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {mb}
            </>
        )
    }

    const nameBodyTemplate = (rowData,props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {rowData?.name +" " + rowData?.lastName }
            </>
        )
    }

    const positionBodyTemplate = (rowData) => {

        if(rowData.userType === "superman"){
            var userTyped = 'Tapriman'
        }else if(rowData.userType === "customer"){
            var userTyped = 'Account'
        }else if(rowData.userType === "distributor"){
            var userTyped = 'Partner'
        }else if(rowData.userType === "techqc"){
            var userTyped = 'Quality Control Technician'
        }else{
            var userTyped = rowData.userType;
        }
        
        return ( <> <span className="p-column-title">Position</span>{userTyped}</>)
    };

    
    const addressBodyTemplate = (rowData) => {
        return (
        <>
            <span className="p-column-title">Address</span>
            {rowData?.addr?.addr}
            {rowData?.addr?.caddr}
        </>
        );
    };


    const rightToolbarTemplate = () =>{
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="USER TYPE" />
                    <Dropdown className="multiselect-custom" options={position} optionLabel="name" onChange={onPositionChange} filter value={defaultPosition} placeholder="Select User Type" />
                </div>
            </>
        )
    } 


    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                <h6 className="p-mt-3 p-mr-5">Users</h6>
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4'  onClick={exportCSV}/>
                </div>
            </span>
        </div>
    )



    return (

            <>
                <div className="p-grid crud-demo">
                <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={allUsers} dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users" globalFilter={globalFilter}
                        emptyMessage="No Data found." header={header} loading={loading}>
                        <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '6rem' }} ></Column>
                        <Column header="Image" body={imageBodyTemplate} style={{ width: '8rem' }} ></Column>
                        <Column field="name" header="Name" sortable body={nameBodyTemplate} ></Column>
                        <Column field="userid" header="User Id" body={commonBodyTemplate} sortable ></Column>
                        <Column field="position" header="Position" sortable body={positionBodyTemplate} ></Column>
                        <Column field="cno" header="Contact" sortable  body={mbBodyTemplate} ></Column>
                        <Column field="addr" header="Address" body={addressBodyTemplate} sortable ></Column>
                    </DataTable>

                </div> 
                </div>          
                </div>
            </>
    )
}