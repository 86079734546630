import React, { useEffect, useState, useRef } from 'react';
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'

import ApiServices from '../../service/api/ApiServices';
import { TableWrapper } from '../../utilComponents/DataTableWrapper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BoolenBodyForTable, CommonBodyForTable, DateBodyForTable, srBodyTemplate } from '../Common/CommonComponents';
import { getFormattedString } from '../Common/CommonFunctions';
import { Dropdown } from 'primereact/dropdown';
import CommonCal from '../../service/common/CommonCal';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

const apiServices = new ApiServices();
const commonCal = new CommonCal();

const initialValue = JSON.parse(localStorage.getItem("user"));

export const OnlineCvesList = ({ allMachines }) => {
    // console.log("allMachines", allMachines);
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState('');
    const [cvesList, setCvesList] = useState(null);
    const dt = useRef('')
    useEffect(() => {
        const data = allMachines?.filter(x=> x?.mchmode?.canWisePayment == false);
        console.log("data", data);
        setCvesList(data);
        setLoading(false);
    }, [allMachines])

    const exportCSV = () => { dt.current.exportCSV() }


    const siteBodyForTable = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>{data?.subscription?.package?.site == 'permanent' ? 'Commercial' : 'Demo'}
            </>
        )
    }


    const cupCostBodyForTable = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>{data?.subscription?.package?.halfcup}
            </>
        )
    }

    const onlineCostBodyForTable = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>{`${data?.subscription?.package?.ctvmhalfcup}.00`}
            </>
        )
    }

    const header = (
        <div className="table-header">
            <h6 className="p-m-0">Online CVES List</h6>
            <span className="p-input-icon-left">
                <div className="p-inputgroup p-mr-2">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV} />
                </div>
            </span>
        </div>
    );


    return (

        <>
            <TableWrapper>
                <DataTable ref={dt} value={cvesList} header={header}
                    dataKey="id" paginator rows={20} rowsPerPageOptions={[5, 10, 20]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Transactions"
                    globalFilter={globalFilter} emptyMessage="No Data found." loading={loading} exportFilename={`Online Activated CVES List`}>
                    <Column field="id" header="Sr.No." sortable body={srBodyTemplate} exportable={false}></Column>
                    <Column field='id' header="CVES ID" sortable body={CommonBodyForTable}></Column>
                    <Column field="siteName" header="Account Name" sortable body={CommonBodyForTable}></Column>
                    <Column field="brand" header="Brand" body={CommonBodyForTable} sortable></Column>
                    <Column field="operateMode" header="Operate Mode" sortable body={CommonBodyForTable}></Column>
                    <Column field="subscription.package.site" header="Subscription Type" sortable body={siteBodyForTable}></Column>
                    <Column field="subscription.package.halfcup" header="Cup Cost" sortable body={cupCostBodyForTable}></Column>
                    <Column field="subscription.package.ctvmhalfcup" header="Online Cup Cost" sortable body={onlineCostBodyForTable}></Column>
                </DataTable>
            </TableWrapper>

        </>

    )
}

