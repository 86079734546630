import React,{useState, useEffect} from "react";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()
// LocalStorage
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

export const NewMachineOrder = () =>{

    const [encryptedNumber,setEncryptedNumber] = useState(null)

    useEffect(()=>{
        getEncrypt()
    },[])

    const getEncrypt = async() =>{
        const contact = initialValue.cno.length > 10 ? initialValue.cno.substr(-10) : initialValue.cno
        const response = await apiServices.getEncrytedNumber({ cno: Number(contact)});
        setEncryptedNumber(response?.data?.encryptedNumber)        
    }

    
    return(
        <>
            {
                encryptedNumber &&
                <>
                    {/*  TESTING    */}
                    {/* <iframe style={{height:'100%', width:'100%', overflow: 'scroll'}} src={`http://91.205.173.97:6001/?contact=${encryptedNumber}&redirect=MACHINE_SCREEN#/splash`}></iframe> */}
            
                    {/*  MAIN    */}
                    <iframe style={{height:'100%', width:'100%', overflow: 'scroll'}} src={`${process.env.REACT_APP_IFRAME_URL}?contact=${encryptedNumber}&redirect=MACHINE_SCREEN#/splash`}></iframe>
                    {/* <iframe style={{height:'100%', width:'100%', overflow: 'scroll'}} src={`https://app.cheriseglobal.com/?contact=${encryptedNumber}&redirect=MACHINE_SCREEN#/splash`}></iframe> */}    
                </>
            } 
        
        </>
    )
}

// https://app.cheriseglobal.com/?contact=78d760d64d3674d6a14ce6a81c5349bd&redirect=MACHINE_SCREEN#/splash


