import React, { useEffect, useState, useRef } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toolbar } from 'primereact/toolbar'
import { FormWrapper, FullFormWrapper } from '../../utilComponents/Forms'
import { InputText } from 'primereact/inputtext'

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { TableWrapper } from '../../utilComponents/DataTableWrapper'
import { CommonBodyForTable, CommonConfirmationDialog, commonDialogFooter, commonFormDialogFooter, srBodyTemplate } from '../Common/CommonComponents'
import { failureToast, successToast } from '../Common/CommonFunctions'

import ApiServices from '../../service/api/ApiServices'
import { Toast } from 'primereact/toast'
import { Dropdown } from 'primereact/dropdown'
import { dateBodyForTable } from '../Users/ActiveUsers'
const apiServices = new ApiServices();

export const FonePayMerchants = () => {

    const merchantTypeoptions = [
        { name: "Fone Pay", code: "fonepay" },
        { name: "Phone Pay", code: "phonepay" }
    ]

    const [merchantsData, setMerchantsData] = useState(null)
    const [merchantDialog, setMerchantDialog] = useState(false)
    const [merchantPayload, setMerchantPayload] = useState({})
    const [selectedMerchant, setSelectedMerchant] = useState(null)
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false)
    const [selectedMerchantType, setSelectedMerchantType] = useState(merchantTypeoptions[0])

    const [phoneMerchantsData, setPhoneMerchantsData] = useState(null)


    const toast = useRef('')

    useEffect(()=>{
        getMerchantData()
        getPhoneMerchants()
    },[])

    const getMerchantData = async() =>{
        const response = await apiServices.getFoneMerchants()
        console.log(response.data.data);
        setMerchantsData(response?.data?.data)
    }

    const getPhoneMerchants = async() =>{
        const response = await apiServices.getPhoneMerchants()
        const mappedArr = response.data?.data?.map((x)=>{ return {...x, isPhone : true}})
        setPhoneMerchantsData(mappedArr)
    }

    const openAddMerchant = () =>{ 
        setMerchantDialog(true)  
        setSelectedMerchant(null)
        setMerchantPayload({})
    }

    const handleChange = (e) =>{

        if(e.target.name == 'merchant-type'){
            setSelectedMerchantType(e.target.value)
            setMerchantPayload({})
        }else{
            setMerchantPayload({...merchantPayload, [e.target.name] : e.target.value})
        }
    }

    const submitMerchants = async(e) =>{
        e.preventDefault();

        console.log(merchantPayload);

        if(selectedMerchantType.code == 'fonepay'){

            const response = selectedMerchant
            ? await apiServices.updateFoneMerchant(merchantPayload)
            : await apiServices.addFoneMerchant(merchantPayload);

            response?.data.success ? successToast(toast,'Done Successfully') : failureToast(toast, 'Failed');
            setMerchantDialog(false)
            getMerchantData()

        }else{

            const response = selectedMerchant
            ? await apiServices.updatePhoneMerchant(merchantPayload) : await apiServices.addPhoneMerchant(merchantPayload);

            response?.data.success ? successToast(toast,'Done Successfully') : failureToast(toast, 'Failed');
            setMerchantDialog(false)
            getPhoneMerchants()
        }
    }

    const openEdit = (data) =>{
        console.log(data);
        setMerchantDialog(true)
        data?.isPhone ? setSelectedMerchantType(merchantTypeoptions[1]) : setSelectedMerchantType(merchantTypeoptions[0])
        setSelectedMerchant(data)
        setMerchantPayload(data)
    }

    const openDelete = (data) =>{
        setSelectedMerchant(data)
        setOpenDeleteConfirmDialog(true)
    }

    const hideMerchantForm = () =>{ setMerchantDialog(false)}
    const hideDeleteDiaolg = () =>{ setOpenDeleteConfirmDialog(false)}

    const deleteMerchant = async() =>{

        const response = selectedMerchant?.isPhone
        ? await apiServices.deletePhoneMerchant({ _id : selectedMerchant?._id }) : await apiServices.deleteFoneMerchant({ _id : selectedMerchant?._id});
        response?.data.success ? successToast(toast,'Merchant deleted successfully') : failureToast(toast, 'Failed to delete');
        hideDeleteDiaolg()
        selectedMerchant?.isPhone ? getPhoneMerchants() : getMerchantData()
    }

    const rightToolBarTemplate = () =>(
        <Button className='p-custom-btn' label="ADD MERCHANT" icon="pi pi-plus-circle" iconPos="left" onClick={openAddMerchant} />
    )

    const actionBodyForTable = (rowData) =>{
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{display:"flex"}}>
                    <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-warning p-mx-2" aria-label="Edit" onClick={()=>{openEdit(rowData)}} />
                    <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-2" aria-label="Delete" onClick={()=>{ openDelete(rowData)}} />
                </div>
            </>
        )
    }

    const addMerchantDialogFooter = commonFormDialogFooter('Close',hideMerchantForm,'Submit','add-merchant-form')
    const deleteMerchantDialogFooter = commonDialogFooter('Close',hideDeleteDiaolg,'Yes, delete it.',deleteMerchant)

    const DeleteProps = {
        dialogState : openDeleteConfirmDialog,
        dialogFooter : deleteMerchantDialogFooter,
        hideDialog : hideDeleteDiaolg,
        msg : 'Do You Want To Delete this Merchant?'
    }

    return (
        <>
            <Toast ref={toast} position='center'/>
            <TableWrapper>
                <Toolbar left="List of Merchants." right={rightToolBarTemplate}></Toolbar>
            </TableWrapper>

            <TableWrapper>
                <DataTable  value={merchantsData} dataKey="id" className="datatable-responsive" emptyMessage="No data found." header="List of Fonepay Merchants" >
                    <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '8rem' }} exportable={false}></Column>
                    <Column field="company_name" header="Company Name" body={CommonBodyForTable} sortable ></Column>
                    <Column field="fonepay_username" header="User Name" body={CommonBodyForTable} sortable ></Column>
                    <Column field="fonepay_password" header="Password" body={CommonBodyForTable} sortable ></Column>
                    <Column field="fonepay_merchantCode" header="Merchant Code" body={CommonBodyForTable} sortable ></Column>
                    <Column field="fonepay_secret_key" header="Secrete Key" body={CommonBodyForTable} style={{ overflowWrap:'break-word', width: '200px'}} sortable ></Column>
                    <Column field="fonepay_pan" header="Pan" body={CommonBodyForTable} sortable ></Column>
                    <Column field="userid" header="User id" body={CommonBodyForTable} sortable ></Column>
                    <Column header="Action" body={actionBodyForTable} ></Column>
                </DataTable>
            </TableWrapper>

            <TableWrapper>
                <DataTable  value={phoneMerchantsData} dataKey="id" className="datatable-responsive" emptyMessage="No data found." header="List of Phone Pay Merchants" >
                    <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '8rem' }} exportable={false}></Column>
                    <Column field="company_name" header="Company Name" body={CommonBodyForTable} sortable ></Column>
                    <Column field="merchantId" header="Merchant Id" body={CommonBodyForTable} sortable ></Column>
                    <Column field="saltIndex" header="Salt Index" body={CommonBodyForTable} sortable ></Column>
                    <Column field="saltKey" header="Salt Key" body={CommonBodyForTable} sortable ></Column>
                    <Column field="company_name" header="Company Name" body={CommonBodyForTable} sortable ></Column>
                    <Column field="createdAt" header="Created At" body={dateBodyForTable} sortable ></Column>
                    <Column header="Action" body={actionBodyForTable} ></Column>
                </DataTable>
            </TableWrapper>

            <Dialog visible={merchantDialog} style={{ width: '650px' }} header={`${selectedMerchant ? 'Edit' : 'Add'} New Merchant`} modal className="p-fluid" footer={addMerchantDialogFooter} onHide={() => { setMerchantDialog(false) }}>
                <form onSubmit={submitMerchants} id="add-merchant-form" className="p-grid">

                <FullFormWrapper id="merchant-type" label="Merchant Type">
                        <Dropdown id="merchant-type" name='merchant-type' value={selectedMerchantType} onChange={handleChange} options={merchantTypeoptions} optionLabel="name" placeholder="Select Merchant Type" filter disabled={selectedMerchant} ></Dropdown>
                </FullFormWrapper>

                {
                    selectedMerchantType.code == 'fonepay' &&
                    <>
                        <FormWrapper id="username" label="Fonepay Username">
                            <InputText id="username" name='fonepay_username' placeholder='Enter Fonepay Username' onChange={handleChange} defaultValue={selectedMerchant?.fonepay_username || ''} required autoComplete='off' autoFocus />
                        </FormWrapper>
                        <FormWrapper id="password" label="Password">
                            <InputText id="password" name='fonepay_password' placeholder='Enter Password' onChange={handleChange} defaultValue={selectedMerchant?.fonepay_password || ''} required autoComplete='off' />
                        </FormWrapper>
                        <FormWrapper id="fonepay_merchantCode" label="Merchant Code">
                            <InputText id="fonepay_merchantCode" name='fonepay_merchantCode' placeholder='Enter Merchant Code' onChange={handleChange} defaultValue={selectedMerchant?.fonepay_merchantCode || ''} required autoComplete='off' />
                        </FormWrapper>
                        <FormWrapper id="fonepay_secret_key" label="Secret Key">
                            <InputText id="fonepay_secret_key" name='fonepay_secret_key' placeholder='Enter Secret Key' onChange={handleChange} defaultValue={selectedMerchant?.fonepay_secret_key || ''} required autoComplete='off' />
                        </FormWrapper>
                        <FormWrapper id="fonepay_pan" label="Pan id">
                            <InputText id="fonepay_pan" name='fonepay_pan' placeholder='Enter PAN Id' onChange={handleChange} defaultValue={selectedMerchant?.fonepay_pan || ''} required autoComplete='off' />
                        </FormWrapper>
                        <FormWrapper id="userid" label="Fonepay User Id">
                            <InputText id="userid" name='userid' placeholder='Enter User Id' onChange={handleChange} defaultValue={selectedMerchant?.userid || ''} required autoComplete='off' />
                        </FormWrapper>
                        <FormWrapper id="company_name" label="Company Name">
                            <InputText id="company_name" name='company_name' placeholder='Enter Company Name' onChange={handleChange} defaultValue={selectedMerchant?.company_name || ''} required autoComplete='off' />
                        </FormWrapper>
                    </>
                }

                {
                    selectedMerchantType.code == 'phonepay' &&
                    <>
                        <FormWrapper id="merchantId" label="Merchant Id">
                            <InputText id="merchantId" name='merchantId' placeholder='Enter Merchant Id' onChange={handleChange} defaultValue={selectedMerchant?.merchantId || ''} required autoComplete='off' />
                        </FormWrapper>
                        <FormWrapper id="saltKey" label="Salt Key">
                            <InputText id="saltKey" name='saltKey' placeholder='Enter Salt Key' onChange={handleChange} defaultValue={selectedMerchant?.saltKey || ''} required autoComplete='off' />
                        </FormWrapper>
                        <FormWrapper id="saltIndex" label="Salt Index">
                            <InputText id="saltIndex" name='saltIndex' placeholder='Enter Salt Index' onChange={handleChange} defaultValue={selectedMerchant?.saltIndex || ''} required autoComplete='off' />
                        </FormWrapper>
                        <FormWrapper id="userid" label="User Id">
                            <InputText id="userid" name='userid' placeholder='Enter User Id' onChange={handleChange} defaultValue={selectedMerchant?.userid || ''} required autoComplete='off' />
                        </FormWrapper>
                        <FormWrapper id="company_name" label="Company Name">
                            <InputText id="company_name" name='company_name' placeholder='Enter Company Name' onChange={handleChange} defaultValue={selectedMerchant?.company_name || ''} required autoComplete='off' />
                        </FormWrapper>
                    </>
                }
                
                </form>
            </Dialog>

            <CommonConfirmationDialog {...DeleteProps} />

        </>
    )
}
