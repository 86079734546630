import React from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
// import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
// const saved = localStorage.getItem("user");
// const initialValue = JSON.parse(saved);

const AppInlineMenu = (props) => {

    let history = useHistory();
    
    const isSlim = () => {
        return props.menuMode === 'slim';
    }
    
    const isStatic = () => {
        return props.menuMode === 'static';
    }
    
    const isSidebar = () => {
        return props.menuMode === 'sidebar';
    }
    
    const isMobile = () => {
        return window.innerWidth <= 991;
    }
    
    const handleLogout = () =>{
        localStorage.clear();
        window.location.reload();
        history.push('./');    
    }
    

    return (
        <>
            {!isMobile() && (isStatic() || isSlim() || isSidebar()) && <div className={classNames('layout-inline-menu', { 'layout-inline-menu-active': props.activeInlineProfile })}>
                <button className="layout-inline-menu-action p-link" onClick={props.onChangeActiveInlineMenu}>
                    <img src={props.loginUserData?props.loginUserData:'assets/demo/images/user/default-user.png'} alt="avatar" style={{ width: '44px', height: '44px' }} />
                    <span className="layout-inline-menu-text">{props.loginUserName} {props.loginUserLName}</span>
                    <i className="layout-inline-menu-icon pi pi-angle-down"></i>
                </button>
                <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={props.activeInlineProfile} unmountOnExit>
                    <ul className="layout-inline-menu-action-panel">
                        <li className="layout-inline-menu-action-item">
                            <button className="p-link" onClick={handleLogout}>
                                <i className="pi pi-power-off pi-fw"></i>
                                <span>Logout</span>
                            </button>
                        </li>
                        <li className="layout-inline-menu-action-item">
                            <button className="p-link" onClick={props.onRightMenuButtonClick}>
                                <i className="pi pi-cog pi-fw"></i>
                                <span>Setting</span>
                            </button>
                        </li>

                            {/* <Link to="/profile">
                        <li className="layout-inline-menu-action-item p-m-0">
                            <button className="p-link">
                                <i className="pi pi-user pi-fw"></i>
                                <span>Profile</span>
                            </button>

                        </li>
                            </Link> */}
                    </ul>
                </CSSTransition>
            </div>}
        </>
    )
}

export default AppInlineMenu;