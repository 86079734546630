import React, { useState, useEffect, useRef } from "react";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
// Components
import { SubAnalyticalStat } from "../NewAnalytics.js/SubAnalyticalStat";
import { getStickyHeaderDates } from "@fullcalendar/react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// Services
import CommonCal from "../../service/common/CommonCal";
import { managementCheck } from "../../service/admin/superadmin";
import ApiServices from "../../service/api/ApiServices";
import { getFormattedStatForAnalytics } from "../Common/CommonFunctions";
import { MultiSelect } from "primereact/multiselect";

import { brandsOptions } from './Constants';

const apiServices = new ApiServices()
const commonCal = new CommonCal()

const initialValue = JSON.parse(localStorage.getItem("user"));

export const AllAnalysisChart = (props) => {

    const filterByOptions = [
        { name: 'CVES', code: 'ctvm' },
        { name: 'Accounts', code: 'accounts' },
        // { name: 'Timely', code: 'timely' }
    ]

    const durationOptions = [
        { name: 'All', code: 'all' },
        { name: 'Today', code: 'today' },
        { name: 'Yesterday', code: 'yesterday' },
        { name: 'This Week', code: 'this_week' },
        { name: 'This Month', code: 'this_month' },
        { name: 'Last Month', code: 'last_month' },
        { name: 'Last 3 Months', code: 'last_three_month' },
        { name: 'Last 6 Months', code: 'last_six_month' },
        { name: 'Custom', code: 'custom' }
    ];

    const displayMonthlyOptions = [
        { name: 'Default', code: 'default' },
        { name: 'Hourly', code: 'hourly' },
        { name: 'Daily', code: 'daily' },
        { name: 'Weekly', code: 'weekly' }
    ]

    const displayWeekOptions = [
        { name: 'Default', code: 'default' },
        { name: 'Hourly', code: 'hourly' },
        { name: 'Daily', code: 'daily' }
    ]

    const displayTodayOptions = [
        { name: 'Default', code: 'default' },
        { name: 'Hourly', code: 'hourly' }
    ]

    const displayYesterdayOptions = [
        { name: 'Default', code: 'default' },
        { name: 'Hourly', code: 'hourly' }
    ]

    const displayByOptions = [
        { name: 'Default', code: 'default' },
        { name: 'Hourly', code: 'hourly' },
        { name: 'Daily', code: 'daily' },
        { name: 'Weekly', code: 'weekly' },
        { name: 'Monthly', code: 'monthly' },
        { name: 'Yearly', code: 'yearly' },
        { name: 'Last 3 Months', code: 'last_three_months' },
        { name: 'Last 6 Months', code: 'last_six_months' },
        { name: 'Last 12 Months', code: 'last_twelve_months' }
    ]

    const displayManyOptions = [
        { name: 'Default', code: 'default' },
        { name: 'Hourly', code: 'hourly' },
        { name: 'Daily', code: 'daily' },
        { name: 'Weekly', code: 'weekly' },
        { name: 'Monthly', code: 'monthly' }
    ]

    const defaultDisplayBy = { name: 'Default', code: 'default' }

    const [loading, setLoading] = useState(true);
    const [statloading, setStatloading] = useState(true);
    const [xLabels, setXLabels] = useState(null)
    const [dataLabels, setDataLabels] = useState(null)
    const [dataValues, setDataValues] = useState(null)
    const [selectedDuration, setSelectedDuration] = useState({ name: 'Last 6 Months', code: 'last_six_month' })
    const [dates, setDates] = useState(null)

    const [mastarMachines, setMastarMachines] = useState(null)
    const [allMachines, setAllMachines] = useState(null)
    const [allMachineData, setAllMachineData] = useState(null)
    const [selectedMachine, setSelectedMachine] = useState(null)
    const [defaultMachine, setDefaultMachine] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [customDateDialog, setCustomDateDialog] = useState(false)
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');

    const [selectedFilterBy, setSelectedFilterBy] = useState({ name: 'CVES', code: 'ctvm' })

    const [allAccounts, setAllAccounts] = useState([])
    const [selectedAccount, setSelectedAccount] = useState({ label: 'All' })
    const [selectedMasterAccount, setSelectedMasterAccount] = useState({ label: 'All' })

    const [singleAccount, setSingleAccount] = useState(false)
    const [singleCves, setSingleCves] = useState(false)
    const [selectedDisplayBy, setSelectedDisplayBy] = useState({ name: 'Default', code: 'default' })
    const [cupCount, setCupCount] = useState(null)
    const [superStarValue, setSuperStarValue] = useState(null)
    const [bevSuperStarValue, setBevSuperStarValue] = useState(null)
    const [selecteddisplayOptions, setSelecteddisplayOptions] = useState(displayManyOptions)
    const [filteredDate, setFilteredDate] = useState(null)
    const [KAMAccounts, setKAMAccounts] = useState(null)
    const [ownerObject, setOwnerObject] = useState(null)
    const [dataToExport, setDataToExport] = useState(null)

    const [selectedbrand, setSelectedBrand] = useState(brandsOptions)
    const [btnVisibility, setBtnVisibility] = useState(false)
    const [isAccountSelected, setIsAccountSelected] = useState(false)
    const [mchCountForDisplay, setMchCountForDisplay] = useState(null)

    const accountFilterTemplate = { date: null, filter: 'account', value: null }

    const dt = useRef(null)

    useEffect(() => {
        getDates()
        getMachine()
        // misUsageStats()
        // getUserData()
        accountData()
    }, [])


    const accountData = async () => {

        if (initialValue.userType == 'accountmanager') {
            const response1 = await apiServices.getUsersByCTVM();
            const arrData = response1?.data?.data.filter((x) => { return x.userType == 'customer' })
            const filData = arrData.map((x) => {
                return { ...x, label: x?.billing_details?.company ? x?.billing_details?.company : 'CIPL' }
            })
            filData?.unshift({ label: 'All' })
            setAllAccounts(filData)
        } else {
            const response = await apiServices.getActiveUsers();
            const filData = response?.data?.data?.map((x) => {
                return { ...x, label: x?.billing_details?.company ? x?.billing_details?.company : 'CIPL' }
            })

            filData?.unshift({ label: 'All' })
            setAllAccounts(filData)
        }
    }


    const getUserData = async () => {
        setLoading(true)
        const response = await apiServices.getUserReduced()
        const res = response?.data?.data?.filter((pos) => pos.userid == initialValue.userid)
        setOwnerObject(res[0])

        const data = { date: selectedDate, filter: "account", value: "All" }
        misUsageStats(data, res[0])
    }


    const misUsageStats = async (dataOP, userData) => {

        const actresp = await apiServices.getActiveUsers()
        console.log(actresp.data.data);
        const res = await apiServices.getUserReduced()
        const res1 = await apiServices.findMisUsageStats(dataOP)
        const res2 = await apiServices.findMachines()

        const arr = [];
        res2.data.data.forEach((element) => {
            res.data.data.map((x) => {
                if (element.customer === x.userid) {
                    arr.push({ ...element, name: x.name, companyName: x.billing_details ? x.billing_details.company : 'Cherise India Pvt. Ltd.' })
                }
            });
        });

        const arr1 = [];
        arr.forEach((data) => {

            if (userData?.userid === data?.superman?.accountmanager) {
                arr1.push({ ...data })
            }
        });

        var arr2 = [];
        arr1.forEach((data) => {
            res1.data.data[0].filter.forEach((data2) => {
                if (data.subscription.userid === data2._id) {
                    arr2.push({ ...data2, name: data.name });
                }
            })
        })

        const unique = Array.from(new Set(arr2?.map(JSON.stringify))).map(JSON.parse);
        const sorted = unique.sort((a, b) => parseFloat(b.cups) - parseFloat(a.cups));

        const total = sorted?.reduce((accumulator, currentValue) => accumulator + currentValue.cups, 0);
        setCupCount(total)

        console.log(sorted);
        setKAMAccounts(sorted)
        setDataToExport(sorted)

        const label = sorted.map((x) => { return x.name })
        const values = sorted.map((x) => { return x.cups })


        setDataLabels(label)
        setDataValues(values)
        setXLabels('Accounts')

        setSuperStarValue(sorted[0])

        const accArr = [];
        actresp.data.data.forEach((x) => {
            sorted.forEach((y) => {
                if (x.userid == y._id) { accArr.push(x) }
            })
        })

        console.log(accArr);
        accArr?.unshift({ name: 'All' })
        setAllAccounts(accArr)
        setLoading(false)
    }

    const hideCustomDialog = () => {
        setLoading(false)
        setCustomDateDialog(false)
    }

    const getMachine = async () => {
        setLoading(true)
        const filters = { brand: ['TAPRI', 'TKAFFE', 'MERI_TAPRI'] }
        const response = await apiServices.findMachines(filters);

        if (managementCheck()) {
            const ioResponse = await apiServices.getIoMachines();
            var newArr = [...response?.data?.data, ...ioResponse?.data?.data];
        } else {
            var newArr = response?.data?.data ? [...response?.data?.data] : null;
        }

        const response1 = await apiServices.getUserReduced();
        const response2 = await apiServices.getMaterialType();
        const userred = response1?.data?.data.map((x) => { return x.userid })

        console.log(response?.data?.data);

        let arr = []
        newArr?.map((x) => { if (userred?.includes(x.customer)) { arr.push(x) } })

        console.log(arr);

        const result = arr.filter(checkMachine);
        // const kettleResult = arr.filter(checkKettle);

        console.log(result);

        var resultedMachine = result?.map((x) => {
            const customer = x.customer
            const subid = x.subid ? x.subid : 'CIPL';
            const address = response1.data.data.find(o => o.userid === x.customer)
            const newCompny = address?.billing_details?.company || address.name + address.lastName

            return {
                ...x,
                displayLabel: x.id + ' / ' + subid + ' / ' + newCompny,
                address: address?.addr,
                companyName: address?.billing_details?.company
            };
        });

        resultedMachine.unshift({ displayLabel: 'All' });
        console.log(resultedMachine);
        setMastarMachines(resultedMachine)
        setAllMachines(resultedMachine)
        setSelectedMachine(resultedMachine)
        setDefaultMachine(resultedMachine ? resultedMachine[0] : null)
        setSingleCves(resultedMachine ? resultedMachine[0] : null)

        const onBoardmachines = resultedMachine?.filter((m) => { return m?.datastore?.stage == "finishedgoods" && m?.subscription && (m?.subscription?.package?.site == "demo" || m?.subscription?.package?.site == "permanent") && m?.siteName != "" })
        const lastArr = onBoardmachines.filter(checkMachine).map(a => a.id);
        setAllMachineData(lastArr)
        thisMonthData(lastArr)
        setSelectedMachine(lastArr)
        setMchCountForDisplay(lastArr)
        setLoading(false)
    }

    function checkMachine(m) {
        return m?.datastore?.stage == "finishedgoods" && m?.subscription && m.subscription?.package?.site == "permanent" && m.siteName != ""
    }

    function checkKettle(m) {
        return m.datastore.stage == "finishedgoods" && m.subscription && m.subscription?.package?.site == "permanent" && m.siteName != ""
    }

    const getDates = async () => {
        const response = await commonCal.getDates()
        setDates(response)
    }

    const thisMonthData = (result) => {

        const iddata = result;
        const currdate = new Date()
        const firstDay = new Date(currdate.getFullYear(), currdate.getMonth(), 1).setHours(0, 0, 0, 0);
        // const lastDay = new Date(currdate.getFullYear(), currdate.getMonth() + 1, 0).setHours(23, 59, 59, 0);
        const monthStart = new Date(firstDay).toISOString();
        // const endStart = new Date(lastDay).toISOString();
        const nowDate = new Date().toISOString();
        // const date = { startDate: monthStart, endDate: nowDate };
        const lastsix = new Date(currdate.getFullYear(), currdate.getMonth() - 5, 1).setHours(0, 0, 0, 0);
        const lastSixStart = new Date(lastsix).toISOString();

        const date = { startDate: lastSixStart, endDate: nowDate }

        setSelectedDate(date)
        getFilteredDate(date)
        getMachineData({ id: iddata, date });
        getStats({ id: iddata, date })
    }

    const getStats = async (data) => {
        setStatloading(true)
        const response = await apiServices.getUserStats(data)
        setCupCount(response?.data?.data?.usagesStats[0]?.totalCup)
        const superStarCal = response?.data?.data?.brvStats?.sort((a, b) => b.totalCup - a.totalCup);
        setBevSuperStarValue(superStarCal ? superStarCal[0] : null)
        response.data?.success && setStatloading(false)
    }

    const getMachineData = async (data) => {
        setLoading(true)
        const filters = { brand: ['TAPRI', 'TKAFFE', 'MERI_TAPRI'] }
        const response1 = await apiServices.findMachines(filters);
        const response = await apiServices.getCtvmPerformanceStat(data)
        console.log(response1?.data?.data);

        const finalArr = [];
        response?.data?.data.forEach(x => {
            response1?.data?.data.forEach(y => {
                if (x._id == y.id) {
                    finalArr.push({ ...x, accname: x._id + ' (' + y.siteName + ')' })
                }
            });
        });

        console.log(finalArr);

        setDataToExport(finalArr)
        const max = finalArr?.reduce((prev, current) => ((prev.cups > current.cups) ? prev : current), 0)

        console.log(max);
        setSuperStarValue(max)

        setDataLabels(finalArr?.sort((a, b) => Number(b.cups) - Number(a.cups)).map(a => a.accname));
        setDataValues(finalArr?.sort((a, b) => Number(b.cups) - Number(a.cups)).map(a => a.cups));
        setXLabels("CVES's")
        setLoading(false)
    }

    const changeFilterBy = (e) => {
        setBtnVisibility(true)
        setSelectedDisplayBy(defaultDisplayBy)
        setSelectedFilterBy(e.target.value)
        if (e.target.value.code == 'ctvm') {
            setSingleAccount(false)
            // setIsAccountSelected(false)
            // getMachine()
            setSelectedDuration({ name: 'Last 6 Months', code: 'last_six_month' })
        } else if (e.target.value.code == 'accounts') {
            // getAccounts()
        }
    }

    const changeAccount = (e) => {
        setBtnVisibility(true)
        // setLoading(true)
        setSelectedDisplayBy(defaultDisplayBy)
        console.log(e.target.value);
        setSelectedAccount(e.target.value)
        if (e.target.value.label == 'All') {
            const data = { ...accountFilterTemplate, date: selectedDate, value: 'All' }
            // getAccountAnalytic(data)
            // setSingleAccount(false)
        } else {
            const data = { ...accountFilterTemplate, date: selectedDate, value: e.target.value?.userid }
            // getAccountAnalytic(data)
            // setSingleAccount(true)
        }
    }

    const changeBrand = (e) => {
        if (e.value?.length > 0) {
            setBtnVisibility(true)
            setSelectedBrand(e.value)

            console.log(e.value);

            const brands = e.value.map((x) => { return x.code });
            console.log(brands);

            console.log(mastarMachines);

            const mchData = mastarMachines?.filter((x) => {
                return brands.includes(x.brand)
            })

            console.log(mchData);

            const lastArr = mchData.filter(checkMachine).map(a => a.id);
            setSelectedMachine(lastArr)

            mchData.unshift({ displayLabel: 'All' });
            setAllMachines(mchData)
            setDefaultMachine(mchData && mchData[0])
        }
    }

    const filterData = () => {
        setBtnVisibility(false)
        console.log(selectedMachine);

        if (selectedFilterBy?.code == 'ctvm') {
            setIsAccountSelected(false)
            setSingleCves(defaultMachine)
            getMachineData({ date: selectedDate, id: selectedMachine })
            getStats({ date: selectedDate, id: selectedMachine })
            setMchCountForDisplay(selectedMachine)
            // setAllMachineData(selectedMachine)

        } else if (selectedFilterBy?.code == 'accounts') {
            setSelectedMasterAccount(selectedAccount)
            setIsAccountSelected(true)
            getAccountAnalytic({ ...accountFilterTemplate, date: selectedDate, value: selectedAccount?.userid ? selectedAccount?.userid : 'All' })
            selectedAccount?.label == 'All' ? setSingleAccount(false) : setSingleAccount(true);
        }
    }

    const getAccounts = async () => {
        setLoading(false)
        getAccountAnalytic({ ...accountFilterTemplate, date: selectedDate, value: 'All' })
    }

    const getAccountAnalytic = async (data) => {

        setLoading(true)
        const response1 = await apiServices.getMisPerformance(data)
        const response2 = await apiServices.getUserFilter();

        const arr = [];
        response2?.data?.data?.forEach((element) => {
            const userid = element.userid;
            response1?.data?.data?.map((x) => { if (userid == x._id) { arr.push({ ...x, name: element.name }) } });
        });

        let finalArr;

        if (initialValue.userType == 'accountmanager') {

            const accArr = []
            allAccounts.forEach(x => {
                arr.forEach(y => {
                    if (y._id == x.userid) {
                        accArr.push(y)
                    }
                });
            });

            finalArr = accArr;

        } else {
            finalArr = arr;

        }

        const filterData = finalArr.sort((a, b) => Number(b.hcup) - Number(a.hcup))
        const cups = filterData.reduce(function (acc, obj) { return acc + obj.hcup }, 0)
        setCupCount(cups)
        setSuperStarValue(filterData ? filterData[0] : null)
        setDataToExport(filterData)

        setDataLabels(filterData.map(a => a.name));
        setDataValues(filterData.map(a => a.hcup));
        setXLabels('Accounts')
        setLoading(false)
    }

    const changeDisplayBy = (e) => {

        setSelectedDisplayBy(e.target.value)
        const user = { "customer": selectedAccount?.userid, "userType": selectedAccount?.userType }
        let date;

        switch (e.target.value.code) {
            case 'default':
                selectedFilterBy.code == 'ctvm' && getMachineData({ id: selectedMachine, date: selectedDate })

                break;
            case 'hourly':
                selectedFilterBy.code == 'ctvm' && getMonthlyCompData({ id: selectedMachine, date: selectedDate, hourWise: true })
                selectedFilterBy.code == 'accounts' && (singleAccount ? getAccountsData({ ...user, date: selectedDate, "hourWise": true }) : getAccountsData({ date: selectedDate, "hourWise": true }))

                break;
            case 'daily':
                selectedFilterBy.code == 'ctvm' && getDailyConsumptionData({ id: selectedMachine, date: selectedDate })

                break;
            case 'weekly':
                selectedFilterBy.code == 'ctvm' && getMonthlyCompData({ id: selectedMachine, date: selectedDate, weekWise: true })
                selectedFilterBy.code == 'accounts' && (singleAccount ? getAccountsData({ ...user, date: selectedDate, "weekWise": true }) : getAccountsData({ date: selectedDate, "weekWise": true }))
                break;
            case 'monthly':
                selectedFilterBy.code == 'ctvm' && getMonthlyCompData({ id: selectedMachine, date: selectedDate });
                selectedFilterBy.code == 'accounts' && (singleAccount ? getAccountsData({ ...user, date: selectedDate, "monthWise": true }) : getAccountsData({ date: selectedDate, "monthWise": true }))
                break;
            case 'yearly':
                selectedFilterBy.code == 'ctvm' && getMonthlyCompData({ id: selectedMachine, date: selectedDate, yearWise: true })
                selectedFilterBy.code == 'accounts' && (singleAccount ? getAccountsData({ ...user, date: selectedDate, "yearWise": true }) : getAccountsData({ date: selectedDate, "yearWise": true }))
                break;
            case 'last_three_months':
                date = { startDate: dates.last3MonthStartDate, endDate: dates.nowDate }
                selectedFilterBy.code == 'ctvm' && getMonthlyCompData({ id: selectedMachine, date });
                selectedFilterBy.code == 'accounts' && (singleAccount ? getAccountsData({ ...user, date: selectedDate, "monthWise": true }) : getAccountsData({ date: selectedDate, "monthWise": true }))
                break;
            case 'last_six_months':
                date = { startDate: dates.last6MonthStartDate, endDate: dates.nowDate }
                selectedFilterBy.code == 'ctvm' && getMonthlyCompData({ id: selectedMachine, date });
                selectedFilterBy.code == 'accounts' && (singleAccount ? getAccountsData({ ...user, date: selectedDate, "monthWise": true }) : getAccountsData({ date: selectedDate, "monthWise": true }))
                break;
            case 'last_twelve_months':
                date = { startDate: dates.last12MonthStartDate, endDate: dates.nowDate }
                selectedFilterBy.code == 'ctvm' && getMonthlyCompData({ id: selectedMachine, date });
                selectedFilterBy.code == 'accounts' && (singleAccount ? getAccountsData({ ...user, date: selectedDate, "monthWise": true }) : getAccountsData({ date: selectedDate, "monthWise": true }))
                break;
            default:
                break;
        }
    }

    const getAccountsData = async (data) => {

        const monthsArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const response = await apiServices.getAccountWisePerformance(data);

        let label;
        let value;
        let xlabelValue;

        if (data?.hourWise) {

            const add = response?.data?.data.map((x) => { return { ...x, label: `${x.date} - ${x.hour}:00 to ${x.hour + 1}:00 Hr` } })

            const lastArr = add?.sort(function (a, b) {
                var aa = a.date.split('-').reverse().join(),
                    bb = b.date.split('-').reverse().join();
                return aa < bb ? -1 : (aa > bb ? 1 : 0);
            });

            setDataToExport(lastArr)

            label = lastArr?.map(a => a.label);
            value = lastArr?.map(a => a.cups);
            xlabelValue = 'Date & Hour'


        } else if (data?.weekWise) {

            const yy = response?.data?.data.map((x) => { return { ...x, date: `${x.year}-${x.month}`, label: `${monthsArray[x.month - 1]} ${x.year}` } })
            const sortedArr = yy.sort((a, b) => parseFloat(a.date) - parseFloat(b.date));
            setDataToExport(sortedArr)
            label = sortedArr?.map(a => a.label);
            value = sortedArr?.map(a => a.cups);
            xlabelValue = 'Month & Year (Week Wise)'


        } else if (data?.monthWise) {

            console.log(response.data.data);

            // const yy = response?.data?.data.map((x)=>{ return {...x,date:`${x.year}-${x.month}`}})
            // const sortedArr = yy.sort((a, b) => parseFloat(a.date) - parseFloat(b.date));
            // label = sortedArr.map(a => { return `${monthsArray[a.month - 1]} ${a.year}`});
            // value = sortedArr.map(a => a.cups);
            // xlabelValue = 'Month & Year'

        } else if (data?.yearWise) {

        }

        setDataLabels(label)
        setDataValues(value)
        setXLabels(xlabelValue)
    }


    const getMonthlyCompData = async (data) => {

        setLoading(true)
        const monthsArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const response = await apiServices.getMonthWisePerformance(data)
        let label;
        let value;
        let xlabelValue;

        if (data?.weekWise) {

            const yy = response?.data?.data.map((x) => { return { ...x, date: `${x.year}-${x.month}`, label: `${monthsArray[x.month - 1]} ${x.year}` } })
            const sortedArr = yy?.sort((a, b) => parseFloat(a.date) - parseFloat(b.date));
            label = sortedArr?.map(a => a.label);
            value = sortedArr?.map(a => a.cups);
            xlabelValue = 'Month & Year (Week Wise)'

        } else if (data?.yearWise) {

            label = response?.data?.data.map(a => a.year);
            value = response?.data?.data.map(a => a.cups);
            xlabelValue = 'Year'

        } else if (data?.hourWise) {

            const add = response?.data?.data.map((x) => { return { ...x, label: `${x.date} - ${x.hour}:00 to ${x.hour + 1}:00 Hr` } })

            const lastArr = add?.sort(function (a, b) {
                var aa = a.date.split('-').reverse().join(),
                    bb = b.date.split('-').reverse().join();
                return aa < bb ? -1 : (aa > bb ? 1 : 0);
            });

            label = lastArr?.map(a => a.label);
            value = lastArr?.map(a => a.cups);
            xlabelValue = 'Date & Hour'

        } else {

            const yy = response?.data?.data.map((x) => { return { ...x, date: `${x.year}-${x.month}` } })
            const sortedArr = yy.sort((a, b) => parseFloat(a.date) - parseFloat(b.date));
            label = sortedArr.map(a => { return `${monthsArray[a.month - 1]} ${a.year}` });
            value = sortedArr.map(a => a.cups);
            xlabelValue = 'Month & Year'
        }


        setDataLabels(label)
        setDataValues(value)
        setXLabels(xlabelValue)
        setLoading(false)
    }


    const getDailyConsumptionData = async (data) => {
        setLoading(true)
        const response = await apiServices.allMachineConsumption(data)
        const label = response?.data?.data?.map(a => a._id);
        const value = response?.data?.data?.map(a => a.cups);

        setDataLabels(label)
        setDataValues(value)
        setXLabels('Date')
        setLoading(false)
    }


    const changeSelectMachine = (e) => {
        setBtnVisibility(true)
        setSelectedDisplayBy(defaultDisplayBy)
        console.log(e.target.value);
        setDefaultMachine(e.target.value)
        const machineData = e.target.value.displayLabel == 'All' ? allMachineData : [e.target.value?.id];
        const mch = e.target.value.displayLabel == 'All' ? setSelectedMachine(allMachineData) : setSelectedMachine([e.target.value?.id])
        e.target.value.displayLabel == 'All' && setSelectedBrand(brandsOptions)
        // getMachineData({date:selectedDate,id:machineData})
        // getStats({date:selectedDate,id:machineData})
    }

    const changeDuration = (e) => {
        e.target.value.code == 'custom' ? setBtnVisibility(false) : setBtnVisibility(true);
        setSelectedDisplayBy(defaultDisplayBy)
        e.target.value.code != 'custom' && setSelectedDuration(e.target.value);
        // e.target.value.code != 'custom' && setLoading(true)
        const currdate = new Date();
        const nowDate = new Date().toISOString();
        const today = { startDate: dates.startToday, endDate: new Date() };
        const yesterday = { startDate: dates.YestStart, endDate: dates.EndStart };
        const thisWeek = { startDate: dates.weekstart, endDate: dates.weekEnd };
        const thisMonth = { startDate: dates.monthStart, endDate: dates.nowDate };
        const lastMonth = { startDate: dates.lastStart, endDate: dates.lastend };

        const lastMonthStart = new Date(currdate.getFullYear(), currdate.getMonth() - 2, 1).setHours(0, 0, 0, 0);
        const lastStart = new Date(lastMonthStart).toISOString();
        const lastThreeMonths = { startDate: lastStart, endDate: dates.nowDate };
        // const lastThreeMonths = { startDate: lastStart, endDate:nowDate };

        const lastsix = new Date(currdate.getFullYear(), currdate.getMonth() - 5, 1).setHours(0, 0, 0, 0);
        const lastSixStart = new Date(lastsix).toISOString();
        const lastSixMonths = { startDate: lastSixStart, endDate: dates.nowDate };

        switch (e.target.value.code) {
            case 'all':
                setSelecteddisplayOptions(displayByOptions)
                setSelectedDate(null)
                // selectedFilterBy.code == 'ctvm' && getMachineData({id:selectedMachine})
                // selectedFilterBy.code == 'ctvm' && getStats({id:selectedMachine})
                // selectedFilterBy.code == 'accounts' && getAccountAnalytic({...accountFilterTemplate,value:selectedAccount?.userid ? selectedAccount?.userid : 'All' })
                break;
            case 'today':
                setSelectedDate(today)
                setSelecteddisplayOptions(displayTodayOptions)
                // selectedFilterBy.code == 'ctvm' && getMachineData({date:today,id:selectedMachine})
                // selectedFilterBy.code == 'ctvm' && getStats({date:today,id:selectedMachine})
                // selectedFilterBy.code == 'accounts' && getAccountAnalytic({...accountFilterTemplate,date:today,value:selectedAccount?.userid ? selectedAccount?.userid : 'All'})
                break;
            case 'yesterday':
                setSelecteddisplayOptions(displayYesterdayOptions)
                setSelectedDate(yesterday)
                // selectedFilterBy.code == 'ctvm' && getMachineData({date:yesterday,id:selectedMachine})
                // selectedFilterBy.code == 'ctvm' && getStats({date:yesterday,id:selectedMachine})
                // selectedFilterBy.code == 'accounts' && getAccountAnalytic({...accountFilterTemplate,date:yesterday,value:selectedAccount?.userid ? selectedAccount?.userid : 'All'})
                break;
            case 'this_week':
                setSelecteddisplayOptions(displayWeekOptions)
                setSelectedDate(thisWeek)
                getFilteredDate(thisWeek)
                // selectedFilterBy.code == 'ctvm' && getMachineData({date:thisWeek,id:selectedMachine})
                // selectedFilterBy.code == 'ctvm' && getStats({date:thisWeek,id:selectedMachine})
                // selectedFilterBy.code == 'accounts' && getAccountAnalytic({...accountFilterTemplate,date:thisWeek,value:selectedAccount?.userid ? selectedAccount?.userid : 'All'})

                break;
            case 'this_month':
                setSelecteddisplayOptions(displayMonthlyOptions)
                setSelectedDate(thisMonth)
                getFilteredDate(thisMonth)
                // selectedFilterBy.code == 'ctvm' && getMachineData({date:thisMonth,id:selectedMachine})
                // selectedFilterBy.code == 'ctvm' && getStats({date:thisMonth,id:selectedMachine})
                // selectedFilterBy.code == 'accounts' && getAccountAnalytic({...accountFilterTemplate,date:thisMonth,value:selectedAccount?.userid ? selectedAccount?.userid : 'All'})
                break;
            case 'last_month':
                setSelecteddisplayOptions(displayMonthlyOptions)
                setSelectedDate(lastMonth)
                getFilteredDate(lastMonth)
                // selectedFilterBy.code == 'ctvm' && getMachineData({date:lastMonth,id:selectedMachine})
                // selectedFilterBy.code == 'ctvm' && getStats({date:lastMonth,id:selectedMachine})
                // selectedFilterBy.code == 'accounts' && getAccountAnalytic({...accountFilterTemplate,date:lastMonth,value:selectedAccount?.userid ? selectedAccount?.userid : 'All'})
                break;

            case 'last_three_month':
                setSelecteddisplayOptions(displayManyOptions)
                setSelectedDate(lastThreeMonths)
                getFilteredDate(lastThreeMonths)
                // selectedFilterBy.code == 'ctvm' && getMachineData({date:lastThreeMonths,id:selectedMachine})
                // selectedFilterBy.code == 'ctvm' && getStats({date:lastThreeMonths,id:selectedMachine})
                // selectedFilterBy.code == 'accounts' && getAccountAnalytic({...accountFilterTemplate,date:lastThreeMonths,value:selectedAccount?.userid ? selectedAccount?.userid : 'All'})
                break;
            case 'last_six_month':
                setSelecteddisplayOptions(displayManyOptions)
                setSelectedDate(lastSixMonths)
                getFilteredDate(lastSixMonths)
                // selectedFilterBy.code == 'ctvm' && getMachineData({date:lastSixMonths,id:selectedMachine})
                // selectedFilterBy.code == 'ctvm' && getStats({date:lastSixMonths,id:selectedMachine})
                // selectedFilterBy.code == 'accounts' && getAccountAnalytic({...accountFilterTemplate,date:lastSixMonths,value:selectedAccount?.userid ? selectedAccount?.userid : 'All'})
                break;

            case 'custom':
                setCustomStart('')
                setCustomEnd('')
                setCustomDateDialog(true)
                break;
            default:
                setSelecteddisplayOptions(displayManyOptions)
                setSelectedDate(lastSixMonths)
                getFilteredDate(lastSixMonths)
            // selectedFilterBy.code == 'ctvm' && getMachineData({date:thisMonth,id:selectedMachine})
            // selectedFilterBy.code == 'ctvm' && getStats({date:thisMonth,id:selectedMachine})
            // selectedFilterBy.code == 'accounts' && getAccountAnalytic({...accountFilterTemplate,date:thisMonth,value:selectedAccount?.userid ? selectedAccount?.userid : 'All'})
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setCustomDateDialog(false)
        setSelectedDuration({ name: 'Custom', code: 'custom' });
        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        let cust_start = new Date(startt).toISOString();
        let cust_end = new Date(endd).toISOString();
        const date = { startDate: cust_start, endDate: cust_end };
        setSelectedDate(date)
        selectedFilterBy.code == 'ctvm' && getMachineData({ date: date, id: selectedMachine })
        selectedFilterBy.code == 'ctvm' && getStats({ date: date, id: selectedMachine })
        selectedFilterBy.code == 'accounts' && getAccountAnalytic({ ...accountFilterTemplate, date: date, value: selectedAccount?.userid ? selectedAccount?.userid : 'All' })
        getFilteredDate(date)
    }

    const getFilteredDate = (date) => {
        const startDate = new Date(date?.startDate);
        const endDate = new Date(date?.endDate);
        const finalDate = { startDate: startDate.toDateString(), endDate: endDate.toDateString() }
        setFilteredDate(finalDate)
    }

    const getCustomeDate = (e) => {
        console.log(e.target.value);
        setCustomStart(e.target.value)
    }


    const idBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {props.rowIndex + 1}
            </>
        );
    }

    const commonBodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };


    const leftToolbarTemplate = () => (
        <>

            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="FILTER BY" />
                <Dropdown value={selectedFilterBy} onChange={changeFilterBy} options={filterByOptions} optionLabel="name" placeholder="Select Filter By" filter className="p-m-0 multiselect-custom" />
            </div>

            {
                selectedFilterBy?.code == 'ctvm' &&
                <div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' label="BRAND" />
                    <MultiSelect name='brand' value={selectedbrand} onChange={changeBrand} options={brandsOptions} optionLabel="name" display="chip" placeholder="Select Brand" maxSelectedLabels={3} filter />
                </div>
            }

            {
                selectedFilterBy.code == 'ctvm' &&
                <>
                    <div className="p-inputgroup p-m-1">
                        <Button className='custom-group-btn' label={`CVES`} />
                        <Dropdown value={defaultMachine} onChange={changeSelectMachine} options={allMachines} optionLabel="displayLabel" placeholder="Select CVES" filter className="p-m-0 multiselect-custom" />
                    </div>
                </>
            }

            {
                selectedFilterBy.code == 'accounts' &&
                <div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' label="ACCOUNTS" />
                    <Dropdown value={selectedAccount} onChange={changeAccount} options={allAccounts} optionLabel="label" placeholder="Select Account" filter className="p-m-0 multiselect-custom" />
                </div>
            }
        </>
    )

    const rightToolbarTemplate = () => (
        <>
            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="DURATION" />
                <Dropdown value={selectedDuration} onChange={changeDuration} options={durationOptions} optionLabel="name" placeholder="Select Filter" filter
                    className="p-m-0 multiselect-custom" />
            </div>

            <div className="p-inputgroup p-m-1">
                {
                    btnVisibility && <Button icon="pi pi-filter" label='Filter' className="custom-group-btn p-button p-my-2 p-mx-2" onClick={filterData} />
                }
            </div>

        </>
    )

    const customFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
            <button className="p-button p-button-success" type="submit" form="custom-form" ><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
        </>
    )

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const barData = {
        labels: dataLabels,
        datasets: [
            {
                label: 'Cups',
                backgroundColor: '#00544d',
                borderColor: 'rgb(255, 99, 132)',
                data: dataValues,
                borderRadius: 5
            }
        ]
    };

    const barOptions = {
        plugins: {
            legend: {
                // labels: {
                //     color: '#A0A7B5'
                // }
                display: false
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: 'rgba(160, 167, 181, .3)',
                },
                title: {
                    display: true,
                    text: `${xLabels}`
                }
            },

            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: 'rgba(160, 167, 181, .3)',
                },
                title: {
                    display: true,
                    text: 'No of Cups'
                }
            }
        }
    };


    return (

        <>

            {
                loading ?

                    <div className="spinner-div">
                        <center><ProgressSpinner strokeWidth="7" animationDuration='4s' /></center>
                    </div>
                    :

                    <div className="card">
                        <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>


                        {
                            // selectedFilterBy.code == 'ctvm' &&
                            !isAccountSelected &&

                            <>
                                <div className="p-grid p-my-5">
                                    {
                                        singleCves?.displayLabel == 'All' ?
                                            <SubAnalyticalStat icon="pi pi-bars" header="No. of CVES (Commercial)" value={mchCountForDisplay?.length} loading={loading} />
                                            :
                                            <SubAnalyticalStat icon="pi pi-bars" header="Customer Name" value={singleCves?.companyName} loading={loading} />
                                    }
                                    {
                                        singleCves?.displayLabel == 'All' ?
                                            <SubAnalyticalStat header="Cup Consumption Superstar" value={`${superStarValue?.accname ? superStarValue?.accname : 'NA'}`} loading={superStarValue?.accname ? statloading : true} />
                                            :
                                            <SubAnalyticalStat icon="pi pi-star" header="Beverage Superstar" value={`${bevSuperStarValue?._id}`} loading={loading} />
                                    }

                                    <SubAnalyticalStat icon="pi pi-calendar" header={`Cup Consumption (${(selectedDuration.code !== 'today' || selectedDuration.code !== 'yesterday') ? `${filteredDate?.startDate} - ${filteredDate?.endDate}` : selectedDuration.name})`} value={cupCount ? getFormattedStatForAnalytics(cupCount) : '-'} loading={statloading} />
                                </div>
                            </>
                        }

                        {
                            // selectedFilterBy.code == 'accounts' &&
                            isAccountSelected &&
                            <>

                                <div className="p-grid p-my-5">

                                    {
                                        !singleAccount ?
                                            <SubAnalyticalStat icon="pi pi-users" header="Total Accounts" value={allAccounts?.length > 0 ? allAccounts?.length - 1 : '-'} loading={loading} />
                                            :
                                            <SubAnalyticalStat icon="pi pi-users" header="Account Name" value={selectedMasterAccount?.billing_details?.company ? selectedMasterAccount?.billing_details?.company : '-'} loading={loading} />
                                    }

                                    {
                                        !singleAccount ?
                                            <SubAnalyticalStat icon="pi pi-star" header="Superstar" value={superStarValue?.name ? superStarValue?.name : '-'} loading={loading} />
                                            :
                                            <SubAnalyticalStat icon="pi pi-star" header="Target Cups" value={superStarValue?.target_cups ? superStarValue?.target_cups : '-'} loading={loading} />
                                    }

                                    <SubAnalyticalStat icon="pi pi-calendar" header={`Cup Consumption (${selectedDuration.name == 'Custom' ? `${filteredDate.startDate} - ${filteredDate.endDate}` : selectedDuration.name})`} value={cupCount ? getFormattedStatForAnalytics(cupCount) : '-'} loading={loading} />
                                </div>
                            </>
                        }

                        <div className="p-grid">
                            <div className="p-col-10">
                                <h6 className="centerText p-mt-3">Performance Analysis</h6>
                            </div>
                            <div className="p-col-2">
                                {
                                    selectedFilterBy.code == 'ctvm' &&
                                    <div className="p-inputgroup">
                                        <Button className='custom-group-btn' icon="pi pi-eye" />
                                        <Dropdown value={selectedDisplayBy} onChange={changeDisplayBy} options={selecteddisplayOptions} optionLabel="name" placeholder="Select Display" filter className="p-m-0 multiselect-custom" />
                                        <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV} />
                                    </div>
                                }
                            </div>
                        </div>

                        <Chart type="bar" data={barData} options={barOptions} />


                        <DataTable ref={dt} value={dataToExport} dataKey="id" className="datatable-responsive" exportFilename={`Accounts Performance`} style={{ display: 'none' }} >
                            <Column field="id" header="Sr.No." sortable body={idBodyTemplate} exportable={false}></Column>
                            <Column field="accname" header="Account Name" sortable body={commonBodyTemplate}></Column>
                            <Column field="cups" header="Cups" sortable body={commonBodyTemplate}></Column>
                        </DataTable>


                    </div>
            }


            <Dialog visible={customDateDialog} style={{ width: '550px' }} header="Custom Filter" footer={customFooter} onHide={hideCustomDialog} modal className="p-fluid">
                <form onSubmit={handleSubmit} id="custom-form" className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From</label>
                            <InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => { getCustomeDate(e) }} required autoFocus />
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' min={customStart} value={customEnd} onChange={(e) => { setCustomEnd(e.value) }} required />
                        </div>
                    </div>
                </form>
            </Dialog>


        </>

    )
}