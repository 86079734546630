import React, { useState, useEffect, useRef, useReducer, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from 'primereact/button';
import { exportReport } from '../Common/CommonFunctions';
import { NumberCommonBodyForTable, srBodyTemplate } from '../Common/CommonComponents';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { durationOptions } from './Constants';
import CommonCal from '../../service/common/CommonCal';
import ApiServices from '../../service/api/ApiServices';
const apiServices = new ApiServices();
const commonCal = new CommonCal();

// ======================================================================================================
// ======================================================================================================

export const CvesPerformanceByBev = () => {

    const [loading, setLoading] = useState(false)
    const [globalFilter, setGlobalFilter] = useState('');
    const [dates,setDates] = useState(null)
    const [selectedDuration, setSelectedDuration] = useState({ name: 'Today', code: 'today' })
    const [dataReport, setDataReport] = useState([])
    const [allMaterial, setAllMaterial] = useState([])
    const [customDateDialog,setCustomDateDialog] = useState(false)
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    const dataRef = useRef(null)

    useEffect(() => { 
        getDates()
    }, [])

    const getDates = async() =>{
        const response = await commonCal.getDates()
        setDates(response)
        getData({date : { startDate: response.startToday, endDate: new Date() }})
    }

    const getData = async(data) =>{
        setLoading(true)
        const response = await apiServices.canisterWiseConsumption(data);
        console.log(response.data.data);

        for (const obj of response.data.data) {
            obj.TOTAL = 0; 
            for (const key in obj) {
                if (key !== 'id') {
                    obj.TOTAL += obj[key]; 
                }
            }
        }
        const concatenatedArray = response.data.data.map((x)=>{ return {...x, TOTAL : (x.TOTAL / 2)}});
        console.log(concatenatedArray);
        setDataReport(concatenatedArray)
        const keysArray = concatenatedArray.map(obj => Object.keys(obj));
        const allKeys = keysArray.flat().filter((x)=>{ return x !== 'TOTAL' && x !== 'id' });
        const uniqueKeys = ['id','TOTAL',...new Set(allKeys)];
        console.log(uniqueKeys);
        setAllMaterial(uniqueKeys)
        setLoading(false)
    }



    const changeDuration = (e) =>{
        e.target.value.code != 'custom' && setSelectedDuration(e.target.value);
        e.target.value.code != 'custom' && setLoading(true)
        const today = { startDate: dates.startToday, endDate: new Date() };
        const yesterday = { startDate: dates.YestStart, endDate:dates.EndStart };
        const thisWeek = { startDate: dates.weekstart, endDate:dates.weekEnd };
        const thisMonth = { startDate: dates.monthStart, endDate:dates.endThisMonth };
        const lastMonth = { startDate: dates.lastStart, endDate:dates.lastend };

        switch(e.target.value.code) {
            case 'all':
                getData({})
                break;
            case 'today':
                getData({ date : today })
                break;
            case 'yesterday':
                getData({ date : yesterday })
                break;
            case 'this_week':
                getData({ date : thisWeek })
                break;
            case 'this_month':
                getData({ date : thisMonth })
                break;
            case 'last_month':
                getData({ date : lastMonth })
                break;
            case 'custom':
                setCustomDateDialog(true)
                break;
            default:
                getData({})
          }
    }

    const hideCustomDialog = () =>{
        setLoading(false)
        setCustomDateDialog(false)
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
        setCustomDateDialog(false)
        setSelectedDuration({ name: 'Custom', code: 'custom' });
        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        let cust_start = new Date(startt).toISOString();
        let cust_end = new Date(endd).toISOString();
        const date = { startDate: cust_start, endDate:cust_end };
        getData({ date })
    }

    const tableStyle={ overflowX: 'scroll', width:'100%' };

    const leftToolbarTemplate = () =>(
        <>

            {/* <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="Filter By" />
                <Dropdown value={selectedFilterBy} onChange={changeFilterBy} options={filterByOptions} optionLabel="name" placeholder="Select Filter By" filter className="p-m-0 multiselect-custom"/>
            </div>  */}

        </>
    )

    const rightToolbarTemplate = () => (
        <>
            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="DURATION" />
                <Dropdown value={selectedDuration} options={durationOptions} onChange={changeDuration} optionLabel="name" placeholder="Select Duration" filter className="p-m-0 multiselect-custom"/>
            </div>

            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn p-button-rounded p-ml-4' onClick={()=>{exportReport(dataRef)}} icon="pi pi-download"></Button>
            </div>

        </>
    ) 

    const customFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
            <button className="p-button p-button-success" type="submit" form="custom-form" ><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
        </>
    )

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                <h6 className="p-mt-3 p-mr-5">CVES Performance by Beverages</h6>
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </div>
            </span>
        </div>
    )

    return (
        <>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        {
                            loading ?
                            <>
                                <div className="spinner-div">
                                    <center><ProgressSpinner strokeWidth="7" animationDuration='4s' /></center>
                                </div>
                            </>
                            :
                            <>
                                <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>   
                                <DataTable style={tableStyle} className="datatable-responsive"  paginator rows={25} rowsPerPageOptions={[5, 10, 25]} ref={dataRef} 
                                value={dataReport} dataKey="id" exportFilename={`CVES Wise Beverage Consumption Report (${selectedDuration?.name})`} 
                                loading={loading} globalFilter={globalFilter} header={header} >
                                    <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                                    {
                                        allMaterial.map((x)=>(
                                            <Column field={x} header={x == 'id' ? "CVES ID" : x} body={NumberCommonBodyForTable} style={{width : '10rem'}} sortable ></Column>
                                        ))
                                    }

                                </DataTable>
                            </>
                        }

                    </div>
                </div>
            </div>

            <Dialog visible={customDateDialog} style={{ width: '550px' }} header="Custom Filter" footer={customFooter} onHide={hideCustomDialog} modal className="p-fluid">
                <form onSubmit={handleSubmit} id="custom-form" className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From</label>
                            <InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => {setCustomStart(e.target.value)} } required autoFocus/>
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' min={customStart}  value={customEnd} onChange={(e) => {setCustomEnd(e.value)}} required/>
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    )

}
