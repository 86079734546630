import React, {useState, useEffect, useRef, useMemo, memo} from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { DataView, DataViewLayoutOptions } from "primereact/dataview"
import { CommonBodyForTable } from "../Common/CommonComponents";
import { barDataForBarChart } from "../Common/CommonFunctions";
import { barOptionsForBarChart } from "../Common/CommonFunctions";
import { exportReport } from "../Common/CommonFunctions";
// services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices();

const CatWiseTicketChart = props =>{

    const [loading, setLoading] = useState(true)
    const [ticketData,setTicketData] = useState(null)
    const [dataLabels, setDataLabels] = useState([])
    const [dataValues, setDataValues] = useState([])
    const [donoughtDataValues, setDonoughtDataValues] = useState([])
    const [allTicketsData, setAllTicketsData] = useState(null)
    const [ticketDataForChange, setTicketDataForChange] = useState(null)
    const [ticketsDialog, setTicketsDialog] = useState(false)
    const [layout, setLayout] = useState('list');

    const dt = useRef(null);
    const catWiseRef = useRef(null);

    // const colorData = [
    //     "#FF6384","#36A2EB","#FFCE56",
    //     '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', 
    //     '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    //     '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
    //     '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    //     '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
    //     '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    //     '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
    //     '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    //     '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
    //     '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
    // ]

    const colorData = [
        '#E94561','#E6DC8B','#3E3E3E','#564994','#F58604','#00584F','#3A99FB','#AB6532',
        '#F7C59F','#14DFDF','#01AAAA','#35985E','#F50000','#E6DC8B','#AB6532','#2E7BD5',
        '#19E03C','#F9BD21','#FF6B35'
    ]

    useEffect(()=>{
        getInitialise(props.selectedDate)
        // console.log('hellooo');
    },[props.selectedDate])

    // useEffect(() => {
    //     getAllTickets({})
    // }, [])

    const getInitialise = (data) =>{
        if(data){
            const payload = {...data,"catergoryWise": true}
            getData(payload)
            getAllTickets({...data})
        }else{
            getData({ "catergoryWise": true })
            getAllTickets({...data})
        }
    }

    const getData = async(data) =>{
        setLoading(true)        
        const response = await apiServices.ticketPerformance(data);
        const arr = []
        const res = response?.data?.data.map((x)=>{ 
            x._id ? arr.push({...x,_id:x._id}) : arr.push({...x,_id:'Other'})
        })

        const sorted = arr?.sort((a, b) => parseFloat(b.ticketCount) - parseFloat(a.ticketCount));
        setTicketData(sorted)
        const label = sorted.map((x)=>{ return x._id})
        const values = sorted.map((x)=>{ return x.ticketCount})
        console.log(values);
        setDataLabels(label)
        setDataValues(values)
        setLoading(false)

        const sum = values.reduce((a,b) => a+b)
        const a_percentage = values.map(e => (e/sum*100).toFixed(2))
        setDonoughtDataValues(a_percentage)
        console.log(a_percentage)

    }

    const getAllTickets = async(data) =>{
        const response = await apiServices.getTickets(data)
        console.log(response?.data?.data);
        const upData = response?.data?.data.map((x)=>{ return {...x, strMachineId : x.machineId?.toString() } })
        setAllTicketsData(upData)
        setTicketDataForChange(upData)
    }

    const barDataForBarChartHere = (dataLabels,dataValues,datasetLabel, colorData = '#00544d' ) =>{

        return {
            labels: dataLabels,
            datasets: [
                {
                    label: datasetLabel,
                    // backgroundColor: '#00544d',
                    backgroundColor: colorData,
                    borderColor: 'rgb(255, 99, 132)',
                    data: dataValues,
                    borderRadius: 5
                }
            ]
        };
    }
    
    const barOptionsForBarChartHere = (xLabel,yLabel) =>{
    
        return {
            plugins: {
                legend: {
                    // labels: {
                    //     color: '#A0A7B5'
                    // }
                    display:false
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color:  'rgba(160, 167, 181, .3)',
                    },
                    title: {
                        display: true,
                        text: xLabel
                    }
                },
                
                y: {
                    ticks: {
                        color: '#495057',
                        precision: 0
                    },
                    grid: {
                        color:  'rgba(160, 167, 181, .3)',
                    },
                    title: {
                        display: true,
                        text: yLabel
                    }
                }
            },
            onClick: function (e, clickedElement) {
                getRequiredData(clickedElement)
            }
        };
    }

    const getRequiredData = (data) =>{
        console.log(data);
        if(data?.length > 0){
            setTicketsDialog(true)
            const labelIndex = data[0]?.index;
            const category = dataLabels[labelIndex];
            const filteredData = allTicketsData?.filter((x)=>{ return x.catergory == category;})
            const upData = filteredData?.map((x)=>{ return {...x, strMachineId : x.machineId?.toString() } })
            console.log(upData);
            setTicketDataForChange(upData)
        }
    }

    const exportCSV = () =>{ exportReport(dt)}
    const catWiseExportCSV = () =>{ exportReport(catWiseRef)}
    
    const barData = barDataForBarChartHere(dataLabels,dataValues,'No of Tickets',colorData)
    const barOptions = barOptionsForBarChartHere('Issue Categories','No of Tickets')

    // const barData = useMemo(() => barDataForBarChartHere(dataLabels,dataValues,'No of Tickets',colorData), [dataValues]);

    const chartData = {
        labels: dataLabels,
        datasets: [
            {
                    label: "Issues in %",
                    data: donoughtDataValues,
                    backgroundColor: colorData
            }
        ]
    }

    const lightOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }   
        }
    }

    
    const ticketFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={()=>{setTicketsDialog(false)}}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
        </>
    )

    const itemTemplate = (data, layout) => {
        if (!data) {
            return;
        }
        if (layout === 'list') {
            return dataviewListItem(data);
        }
        // else if (layout === 'grid') {
        //     return dataviewGridItem(data);
        // }
    };

    const dataviewListItem = (data) => {
        return (
            <div className="p-col-12 p-md-12">
                <div className="product-list-item">
                    <div className="product-list-detail">                        
                        <span className={`product-badge status-${data.ticketStatus.toLowerCase().replaceAll(' ', '')}`}>{data.ticketStatus}</span>
                        <div className="product-name p-mt-2">{data?.catergory ? data?.catergory : 'Unknown Category'}</div>
                        <h6 className="p-mt-2">{data?.subCatergory ? data?.subCatergory : 'NA'}</h6>
                        <div className="product-description p-mt-2">{data?.ticketQuery}</div>
                        <span><b>Ticket Id :</b> {data?.ticketId}</span>
                        <hr></hr>
                        <span><b>Account Name :</b> {data?.companyName ? data?.companyName : 'NA' }</span>
                        <div className="p-mt-2"> <b>CVES : </b>  {data?.machineId.toString()} </div>
                        <hr></hr>
                        <span><b>Level :</b> {data?.ticketLevel || 'NA' }</span>
                        <span className="p-ml-4"><b>Date :</b> {data?.date || 'NA' }</span>
                    </div>
                    <div className="product-list-action">
                        {/* <span className="product-price">Pricwe</span> */}
                        {/* <Button icon="pi pi-shopping-cart" label="Name" disabled={true}></Button> */}
                        {/* <i className="pi pi-tag product-category-icon"></i><span className="product-category">{data.ticketLevel}</span> */}
                        <span className="p-buttonset">
                            {/* <Button icon="pi pi-eye" onClick={()=>{openDoc(data)}} /> */}
                            {/* <Button icon="pi pi-info-circle" onClick={()=>{openView(data)}} /> */}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    const dataviewHeader = () => {
        return (
            <>
                <div style={{display : 'flex', justifyContent : 'space-between', alignItems:'center'}} >
                    <span>Total Tickets : {ticketDataForChange?.length}</span>
                    <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={catWiseExportCSV} />
                </div>
            </>
        );
    }

    // <div className="p-grid p-nogutter">
                
                {/* <div className="p-col-6" style={{ textAlign: 'left' }}>
                    <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
                </div> */}
                {/* <div className="p-col-6" style={{ textAlign: 'right' }}>
                <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded' onClick={exportCSV}/>
                </div> */}
            // </div>

    const header = dataviewHeader();

    return (
        <>  
            <div className="card widget-visitor-graph">
            <div className="card-header">
                <span></span>
                <div className='p-col-sm-4'>
                    <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={exportCSV} />
                </div>
            </div>

            {
                loading ? 
                <div className='spinner-div'>
                    <center><ProgressSpinner strokeWidth={7} animationDuration='4s'/></center>
                </div>
                :
                <>
                    {
                        (dataValues.length > 0 && dataLabels.length > 0) ?
                        <center>
                            <Chart type="bar" data={barData} options={barOptions}/>
                            {/* <Chart type="doughnut" data={chartData} options={lightOptions} style={{ position: 'relative', width: '40%' }} /> */}
                        </center>
                        :
                        <center><h4 className="p-error p-mt-5">No Data Found</h4></center>
                    }
                </>
            }   
            </div>

            <DataTable ref={dt} value={ticketData} dataKey="id" className="datatable-responsive" emptyMessage="No Data found." exportFilename={`Category Wise Ticket Reports`} style={{display:'none'}} >
                <Column field="_id" header="Category" body={CommonBodyForTable} ></Column>
                <Column field="ticketCount" header="Ticket Count" body={CommonBodyForTable} ></Column>
            </DataTable>

            <Dialog visible={ticketsDialog} style={{ width: '750px' }} header="Ticket List" footer={ticketFooter}  onHide={()=>{setTicketsDialog(false)}} modal className="p-fluid" blockScroll={true}>
                <div className="p-grid list-demo">
                    <div className="p-col-12">
                        <div className="card">
                            <DataView value={ticketDataForChange} layout={layout} paginator rows={12} itemTemplate={itemTemplate} header={header}></DataView>
                        </div>
                    </div>
                </div>

                <DataTable ref={catWiseRef} value={ticketDataForChange} dataKey="id" className="datatable-responsive" emptyMessage="No Data found." exportFilename={`Category Wise Ticket Reports`} style={{display : 'none'}} >
                    <Column field="strMachineId" header="CVES Id" body={CommonBodyForTable} ></Column>
                    <Column field="account_id" header="Account Id" body={CommonBodyForTable} ></Column>
                    <Column field="companyName" header="Account Name" body={CommonBodyForTable} ></Column>
                    <Column field="catergory" header="Category" body={CommonBodyForTable} ></Column>
                    <Column field="subCatergory" header="Sub Category" body={CommonBodyForTable} ></Column>
                    <Column field="ticketId" header="Ticket Id" body={CommonBodyForTable} ></Column>
                    <Column field="ticketLevel" header="Ticket Level" body={CommonBodyForTable} ></Column>
                    <Column field="ticketStatus" header="Ticket Status" body={CommonBodyForTable} ></Column>
                    <Column field="date" header="Date" body={CommonBodyForTable} ></Column>
                    <Column field="expectedClosingTime" header="Closing Time" body={CommonBodyForTable} ></Column>
                    <Column field="Handled_By" header="Handled By" body={CommonBodyForTable} ></Column>
                </DataTable>

            </Dialog>

        </>
    )
}

export default memo(CatWiseTicketChart)