import React, { useEffect, useRef, useState, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
// Common 
import { CommonBodyForFormattedStrTable, CommonBodyForTable, DateBodyForTable, commonCloseDialogFooter, commonDialogFooter } from "../Common/CommonComponents";
import { deliveryModeOptions, orderTypeOptions } from "./Constants";
// Components
import { CommonForDetails } from "../CRM/Leads";
// Services
import ApiServices from "../../service/api/ApiServices";
import { permissionContext } from "../../AppWrapper";
import { convertDateFn, failureToast, successToast } from "../Common/CommonFunctions";
const apiServices = new ApiServices();

// ======================================================================================================
// ======================================================================================================

export const MaterialOrders = ({ wareHouse }) => {
    

    const rolePermissions = useContext(permissionContext);
    const [wareHouseList, setWareHouseList] = useState([]);

    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [onDemandCount, setOnDemandCount] = useState(20)
    const [onDemandIndex, setOnDemandIndex] = useState(0)
    const [sourceData, setSourceData] = useState([])
    const [onDemandId, setOnDemandId] = useState(0)
    const [allData, setAllData] = useState(null)
    const [allSelectedData, setAllSelectedData] = useState(null)
    const [globalFilter, setGlobalFilter] = useState('')
    const [globalFilter1, setGlobalFilter1] = useState('')
    const [globalFilter2, setGlobalFilter2] = useState('')

    const [loading, setLoading] = useState(true)
    const [selectedMaterialOrder, setSelectedMaterialOrder] = useState(null)
    const [infoDialog, setInfoDialog] = useState(false)
    const [fetchOrderDialog, setFetchOrderDialog] = useState(false)
    const [acceptOrderDialog, setAcceptOrderDialog] = useState(false)
    const [mapOrderDialog, setMapOrderDialog] = useState(false)
    const [allMaterials, setAllMaterials] = useState(null)
    const [selectedMaterial, setSelectedMaterial] = useState(null)

    const [batchOptions, setBatchOptions] = useState(null)
    const [selectedBatch, setSelectedBatch] = useState(null)

    const [barcodeOptions, setBarcodeOptions] = useState([])
    const [allBarcodeOptions, setAllBarcodeOptions] = useState([])
    const [selectedBarcodes, setSelectedBarcodes] = useState(null)
    const [filteredSelectedBarcodes, setFilteredSelectedBarcodes] = useState(null)

    const [selectedBarcodeForLast, setSelectedBarcodeForLast] = useState([])
    const [addBarcodeDialog, setAddBarcodeDialog] = useState(false)
    const [totalPackets, setTotalPackets] = useState(null)
    const [selectedDelMode, setSelectedDelMode] = useState(null)
    const [users, setUsers] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)

    const [mappingTemplate, setMappingTemplate] = useState(null)
    const [viewBarcodeDialog, setViewBarcodeDialog] = useState(false)
    const [deleteRefillOrderDialog, setDeleteRefillOrderDialog] = useState(false)
    const [selectedOrderType, setSelectedOrderType] = useState({ name: 'ALL' })

    const toast = useRef(null);


    useEffect(() => {
        const data = { count: 20 }
        getMaterialOrders(data);
    }, [])

    useEffect(()=>{
        setWareHouseList(wareHouse);
    }, [wareHouse])

    const getMaterialOrders = async (data = { count: 20 }) => {
        setLoading(true)
        const response = await apiServices.getMaterialOrders(data)
        console.log(response?.data?.data);
        setSourceData(response?.data?.data)
        setTotalRecords(response?.data?.data.length);
        setAllData(response?.data?.data.slice(first, first + rows));
        setAllSelectedData(response?.data?.data.slice(first, first + rows));
        setLoading(false)
    }

    const openInfo = async(rowData) => {
        rowData = { ...rowData, wareHouseName: 'Pune Plant' }
        if(rowData.wareHouseId){
            const wareHouse = await wareHouseList.find(x => x.userid === rowData.wareHouseId);
            rowData.wareHouseName = wareHouse?.name;
        }
        setSelectedMaterialOrder(rowData);
        seeOrder(rowData)
        setInfoDialog(true);
    }

    const seeOrder = async (data) => {
        if (!data?.viewed) {
            const response = await apiServices.viewOrder(data?.orderId)
            response?.data?.success && getMaterialOrders();
        }
    }

    const fetchOrder = (rowData) => {
        setSelectedMaterialOrder(rowData)
        setFetchOrderDialog(true)
    }

    const hideOrderFetch = () => {
        setFetchOrderDialog(false)
    }

    const fetchingOrder = async () => {
        hideOrderFetch()
        const response = await apiServices.orderFetch({ orderId: selectedMaterialOrder?.orderId });
        response?.data?.success ? successToast(toast, 'Order Fetched Successfully') : failureToast(toast, 'Failed to Fetch')

        setTimeout(() => {
            getMaterialOrders()
        }, 4000);
    }

    const acceptOrder = (rowData) => {
        setSelectedMaterialOrder(rowData)
        setAcceptOrderDialog(true)
    }

    const acceptingOrder = async () => {
        setAcceptOrderDialog(false)
        const response = await apiServices.acceptOrder({ orderId: selectedMaterialOrder?.orderId })
        response?.data?.success ? successToast(toast, 'Order Accepted Successfully') : failureToast(toast, 'Failed to Accept Order');
        getMaterialOrders()
    }

    const mapOrder = (rowData) => {
        console.log(rowData);
        setSelectedMaterialOrder(rowData)
        setMapOrderDialog(true)
        const materials = rowData?.materialInfo?.map((x) => { return { ...x, label: x.name + ' || ' + x.SKUID } })
        setAllMaterials(materials)
        const totalPacketss = rowData?.materialInfo?.reduce((acc, total) => { return acc + total.quantity }, 0)
        // console.log(totalPacketss);
        setTotalPackets(totalPacketss)
    }

    const confirmDelete = (rowData) => {
        console.log(rowData);
        setDeleteRefillOrderDialog(true)
        setSelectedMaterialOrder(rowData)

    }


    const changeInputs = (e) => {

        if (e.target.name == 'materialName') {
            setSelectedMaterial(e.target.value)
            getMaterialBySKU(e.target.value)
        }

        if (e.target.name == 'materialBatch') {
            console.log(e.target.value);
            setSelectedBatch(e.target.value)
            getBarcodeByBatch(e.target.value)
        }

        if (e.target.name == 'barcode') {
            console.log(e.target.value);
            setSelectedBarcodes(e.target.value)
            setFilteredSelectedBarcodes(e.target.value)
            if (selectedMaterial?.quantity == e.target.value?.length) { successToast(toast, 'Count Matched', 1000) }
        }

        if (e.target.name == 'deliveryMode') {

            if (e.target.value.code == 'logistic') {
                setMappingTemplate({ ...mappingTemplate, mode: e.target.value.code, deliveryBy: { comment: "", mode: e.target.value.code }, batchType: "CustomerAssignedBatch", verified: true })

            } else {
                setMappingTemplate({ ...mappingTemplate, mode: e.target.value.code, batchType: "supermanAssignBatch", verified: false })

            }

            setSelectedDelMode(e.target.value)
            e.target.value?.code == 'technoman' && getUsers()
        }

        if (e.target.name == 'deliveryBy') {
            setSelectedUser(e.target.value)
            setMappingTemplate({ ...mappingTemplate, deliveryBy: { "name": e.target.value?.name, "userid": e.target.value?.userid } })
        }

        if (e.target.name == 'comment') {
            setMappingTemplate({ ...mappingTemplate, deliveryBy: { ...mappingTemplate.deliveryBy, comment: e.target.value } })
        }

    }

    const changeAllBarcode = (e) => {


        // if (selectedMaterial?.quantity >= e.target.value?.length){
        //     const slicedArray = barcodeOptions.slice(0, selectedMaterial?.quantity);
        //     console.log(slicedArray);
        //     setSelectedBarcodes(slicedArray)
        //     setFilteredSelectedBarcodes(slicedArray)
        // }

    }


    const getUsers = async () => {
        const payload = { d_id: "self", userType: "superman" }
        const response = await apiServices.getUsersByType(payload)
        setUsers(response?.data?.data)
    }


    const getBarcodeByBatch = async (data) => {
        const response = await apiServices.getBarcodesByBatch({ batch_id: data?.batch_id });
        const arr = [];
        response?.data?.data[0]?.batch_data?.barcodes?.map((x) => {
            if (x.stage == 'added') {
                //  arr.push(...x) 
                arr.push({ ...x, label: x.barcode + " / " + x.batch?.batch_id })
            }
        })

        const dataArr = [...filteredSelectedBarcodes, ...arr]
        const unique = [...new Set(dataArr)];
        setBarcodeOptions(unique)

        const dataAllArr = [...allBarcodeOptions, ...arr]
        const uniqueArr = [...new Set(dataAllArr)];
        setAllBarcodeOptions(uniqueArr)
    }


    const getMaterialBySKU = async (data) => {
        const payload = { materialName: data?.name, sku_id: data?.SKUID }
        const response = await apiServices.getBatchByMaterialSKU(payload)
        const getData = response?.data?.data?.map((x) => { return { ...x, label: x.batch_name + "( " + x.batch_id + " )" } })
        setBatchOptions(getData)
    }


    const openAddBarcode = async (rowData) => {

        console.log(rowData);
        console.log(selectedMaterialOrder);
        setAddBarcodeDialog(true)

        if (selectedMaterialOrder?.wareHouseDelivery) {

            setSelectedMaterial(rowData)
            getMaterialBySKU(rowData)

            console.log(selectedMaterialOrder)

            const data = {
                "wareHouseId": selectedMaterialOrder?.wareHouseId,
                "materialName": rowData?.name,
                "skuId": rowData?.SKUID
            }

            const response = await apiServices.getWareHouseBarcode(data)

            const barcodes = response?.data?.data?.map((x) => {
                if (x?.materialName == rowData?.name && x?.weight == rowData?.weight) {
                    return { ...x, label: x.barcode }
                }
            })

            setBarcodeOptions(barcodes)
            const arr2 = [];
            selectedBarcodeForLast?.filter((x) => { if (x?.materialName == rowData?.name && x?.weight == rowData?.weight) { arr2.push(x) } })
            // selectedBarcodeForLast?.filter((x) => { if (x?.sku?.itemName == rowData?.Master_sku_name) { arr2.push(x) } })
            setSelectedBarcodes(arr2)
            setFilteredSelectedBarcodes(arr2)

        } else {

            setSelectedMaterial(rowData)
            getMaterialBySKU(rowData)

            console.log(allBarcodeOptions);
            console.log(selectedBarcodeForLast);

            const arr = [];
            allBarcodeOptions?.filter((x) => {
                if (x?.materialName == rowData?.name && x?.weight == rowData?.weight) {
                    arr.push(x)
                }
            })

            setBarcodeOptions(arr)

            const arr2 = [];
            selectedBarcodeForLast?.filter((x) => { if (x?.materialName == rowData?.name && x?.weight == rowData?.weight) { arr2.push(x) } })
            setSelectedBarcodes(arr2)
            setFilteredSelectedBarcodes(arr2)
        }
    }





    const openViewBarcodes = (rowData) => {
        console.log(rowData);
        console.log(selectedBarcodeForLast);
        setViewBarcodeDialog(true)
        const arr2 = [];
        selectedBarcodeForLast?.filter((x) => { if (x?.materialName == rowData?.name && x?.weight == rowData?.weight) { arr2.push(x) } })
        console.log(arr2);
        setFilteredSelectedBarcodes(arr2)
    }

    const setDefaultDialog = () => {
        setSelectedBatch(false)
        setSelectedBarcodes(null)
    }

    const hideMapOrder = () => {
        setMapOrderDialog(false)
        setSelectedBarcodeForLast([])
    }

    const okBarcodes = () => {

        const arr1 = [];
        const filteredArr = selectedBarcodeForLast?.filter((x) => {
            if (x.materialName != selectedMaterial?.name || x?.weight != selectedMaterial?.weight) {
                arr1.push(x)
            }
        })

        const arrData = [...arr1, ...selectedBarcodes]
        const unique = [...new Set(arrData)];
        setSelectedBarcodeForLast(unique)
        setAddBarcodeDialog(false)
    }

    const submitMapping = async (e) => {
        e.preventDefault()
        setMapOrderDialog(false)

        const reducedValue = selectedMaterialOrder?.materialInfo?.reduce((sum, cur) => sum + cur.weight * cur.quantity, 0)
        const allBarcodes = selectedBarcodeForLast.map((x) => { return x?.barcode });

        const data = {
            "orderId": selectedMaterialOrder?.orderId,
            "SDid": selectedMaterialOrder?.SDid,
            "accountType": selectedMaterialOrder?.accountType,
            "assignto": {
                "accountType": selectedMaterialOrder?.accountType,
                "name": selectedMaterialOrder?.name,
                "userid": selectedMaterialOrder?.userid
            },
            "totalweight": reducedValue,
            "barcode": allBarcodes,
            "batch_name": selectedMaterialOrder?.batch_name,
            "batchdate": new Date().toISOString().substring(0, 10),
            "orderQty": 0
        }

        const data1 = data.accountType == "distributor" ? { ...data, assignto: { ...data.assignto, "disId": selectedMaterialOrder?.disId } } : { ...data }
        const finaldata = { ...data1, ...mappingTemplate }
        const response = await apiServices.addAssignBatch(finaldata)
        response?.data?.success ? successToast(toast, 'Material Assigned Successfully') : failureToast(toast, 'Failed to Assigned')
        getMaterialOrders()
    }

    const searchOrder = async (data) => {
        const response = await apiServices.getSearchedOrder(data)
        setAllData(response?.data?.data)
        setAllSelectedData(response?.data?.data)
    }

    const changeOrderType = (e) => {
        console.log(e.value)
        // console.log();
        setSelectedOrderType(e.value)
        if (e.value.name == 'ALL') {
            setAllSelectedData(allData)
        } else {

            const filtered = allData.filter((x) => {
                return x.orderType == e.value.name;
            })
            console.log(filtered);

            setAllSelectedData(filtered)
        }
    }

    const header = (
        <div className="table-header">

            <span className="p-input-icon-left">
                <div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' label="ORDER TYPE" />
                    <Dropdown value={selectedOrderType} onChange={changeOrderType} options={orderTypeOptions} optionLabel="name" placeholder="Select" filter
                        className="multiselect-custom" />
                </div>
            </span >

            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => searchOrder(e.target.value)} placeholder="Search..." />
                    {/* <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." /> */}
                    {/* <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4'  onClick={exportCSV}/> */}
                </div>
            </span>
        </div>
    );

    const header1 = (
        <div className="table-header">
            <h5></h5>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter1(e.target.value)} placeholder="Search..." />
                    {/* <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4'  onClick={exportCSV}/> */}
                </div>
            </span>
        </div>
    );


    const barcodeHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Search..." />
                </div>
            </span>
        </div>
    );



    const onPageChange = async (event) => {

        setOnDemandId(event.rows * event.page)
        setTimeout(() => {
            const startIndex = event.first;
            const endIndex = event.first + rows;
            setFirst(startIndex);
            setAllData(sourceData.slice(startIndex, endIndex));
            setAllSelectedData(sourceData.slice(startIndex, endIndex));

        }, 250);

        if ((event.page + 1) % 2 == 0) {

            const data = { count: onDemandCount, index: onDemandIndex + 20 }
            const response = await apiServices.getMaterialOrders(data)
            const arr = response?.data?.data;
            const totalData = [...sourceData, ...arr]

            console.log(totalData);
            setSourceData(totalData);
            setTotalRecords(totalData.length);
            setAllData(totalData.slice(event.first, event.first + rows));
            setAllSelectedData(totalData.slice(event.first, event.first + rows));
            setOnDemandIndex(onDemandIndex + 20)
        }
    }

    const removeRefillOrder = async () => {
        setDeleteRefillOrderDialog(false)
        const response = await apiServices.removeMaterialOrder({ orderId: selectedMaterialOrder?.orderId });
        if (response?.data?.success) {
            successToast(toast, 'Order Deleted Successfully');
            const data = { count: 50 }
            getMaterialOrders(data)
        } else {
            failureToast(toast, 'Failed to Delete Order');
        }
    }


    const onDemandidTemplate = (rowData, props) => (<><span className="p-column-title">Sr.No.</span>{props.rowIndex + 1 + onDemandId}</>)

    const srBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {props.rowIndex + 1}
            </>
        );
    }

    const orderBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {rowData[props.field] ? rowData[props.field] : 'NA'}
                {rowData?.viewed ? null : <Badge value="New" severity="info"></Badge>}
            </>
        )
    }


    const materialBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {rowData[props.field] ? rowData[props.field] : rowData?.name}
            </>
        )
    }

    const batchBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {rowData?.batch ? rowData?.batch[props.field] : 'NA'}
            </>
        )
    }

    const calBodyTemplate = (rowData, props) => {

        console.log(selectedBarcodeForLast);
        const xx = selectedBarcodeForLast?.filter((x) => {
            // return x.materialName == rowData?.name
            return x.materialName == rowData?.name && x.weight == rowData?.weight
        })

        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {xx ? rowData[props.field] - xx?.length : 'NA'}
            </>
        )
    }


    const statusBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {rowData[props.field] ? <i className="pi pi-check p-success" > {rowData?.deliveryId} </i> : <i className="pi pi-times p-error"></i>}
            </>
        )
    }


    const dateBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {rowData[props.field] ? new Date(rowData[props.field])?.toLocaleString() : 'NA'}
            </>
        )
    }

    const fullNameBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {`${rowData?.name} ${rowData?.lastName}`}
            </>
        )
    }

    const matBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {rowData[props.field]?.length}
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{ display: "flex" }}>
                    <Button icon="pi pi-eye" title='View Material Order Details' className="p-button-rounded p-button-info p-m-1" aria-label="Action" onClick={(e) => { openInfo(rowData) }} />

                    {
                        (rowData?.orderFetch && rowData.materialInfo?.length == 0) &&
                        <Button label="No Data" className="p-button-rounded p-button-danger p-m-1" aria-label="Material Not Found" />
                    }

                    {
                        (!rowData?.orderFetch && !rowData?.orderAccept && !rowData?.orderMapped && rolePermissions?.orderFetch) &&
                        <Button title='Actions' label="Order Fetch" className="p-button-rounded p-button-primary p-m-1" aria-label="Order Fetching" onClick={(e) => { fetchOrder(rowData) }} />
                    }

                    {
                        (rowData.materialInfo?.length > 0 && rowData?.orderFetch && !rowData?.orderAccept && !rowData?.orderMapped && rolePermissions?.orderAccept) &&
                        <Button title='Actions' label="Accept Order" className="p-button-rounded p-button-primary p-m-1" aria-label="Accept Order" onClick={(e) => { acceptOrder(rowData) }} />
                    }

                    {
                        (rowData.materialInfo?.length > 0 && rowData?.orderFetch && rowData?.orderAccept && !rowData?.orderMapped && rolePermissions?.orderMap) &&
                        <Button title='Actions' label="Map" className="p-button-rounded p-button-primary p-m-1" aria-label="Map Order" onClick={(e) => { mapOrder(rowData) }} />
                    }

                    {
                        rolePermissions?.rmRefillOrder &&
                        <Button icon="pi pi-trash" title='Delete Invoice' className="p-button-rounded p-button-danger p-m-1" onClick={() => { confirmDelete(rowData) }} />
                    }

                    {/* {
                        rowData?.wareHouseDelivery && rowData?.orderMapped &&
                        <Button icon="pi pi-user" title='Actions' className="p-button-rounded p-button-info p-m-1" aria-label="Action" onClick={null} />
                    } */}
                </div>
            </>
        )
    }

    const addBarcodeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{ display: "flex" }}>
                    <Button icon="pi pi-plus" title='Actions' className="p-button-rounded p-button-primary p-m-1" aria-label="Action" onClick={(e) => { openAddBarcode(rowData) }} />
                    <Button icon="pi pi-eye" title='View' className="p-button-rounded p-button-info p-m-1" aria-label="Action" onClick={(e) => { openViewBarcodes(rowData) }} />
                </div>
            </>
        )
    }

    const hideInfoDialog = () => { setInfoDialog(false) }
    const hideBarcodeDialog = () => { setViewBarcodeDialog(false) }
    const hideAcceptOrderDialog = () => { setAcceptOrderDialog(false) }

    const viewPlacedOrderFooter = commonCloseDialogFooter('Close', hideInfoDialog)
    const viewBarcodeFooter = commonCloseDialogFooter('Close', hideBarcodeDialog)
    const fetchOrderDialogFooter = commonDialogFooter('Cancel', hideOrderFetch, 'Yes, Fetch it.', fetchingOrder)
    const acceptOrderDialogFooter = commonDialogFooter('Cancel', hideAcceptOrderDialog, 'Yes, Accept it.', acceptingOrder)

    const mapOrderDialogFooter = () => {
        return (
            <>
                <Button label="Cancel" icon="pi pi-times" className=" p-button-danger" onClick={() => { hideMapOrder() }} />
                {(selectedBarcodeForLast?.length == totalPackets) && <Button label="Delivery" type="submit" form="add-mapping" icon="pi pi-check" className=" p-button-success" />}
            </>
        )
    }

    const addBDialogFooter = () => {
        return (
            <>
                <Button label="Cancel" icon="pi pi-times" className=" p-button-danger" onClick={() => { setAddBarcodeDialog(false) }} />
                {
                    (filteredSelectedBarcodes?.length == selectedMaterial?.quantity) ?
                        <Button label="Add" icon="pi pi-plus-circle" className=" p-button-success" onClick={() => { okBarcodes() }} />
                        :
                        <Button label={`Selected Barcodes ${filteredSelectedBarcodes?.length} / ${selectedMaterial?.quantity} `} icon="pi pi-plus-circle" className=" p-button-info" onClick={() => { alert('Please Select Required Barcode Count') }} />
                }
            </>
        )
    }

    const deleteRefillOrderDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-success p-mr-2 p-mb-2" onClick={() => { setDeleteRefillOrderDialog(false) }} />
            {rolePermissions?.rmRefillOrder && <Button label="Yes, Delete it." icon="pi pi-check" className="p-button-danger p-mr-2 p-mb-2" onClick={removeRefillOrder} />}
        </>
    )

    return (
        <>
            <Toast ref={toast} position='center' />
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">

                        {/* <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

                        <DataTable value={allSelectedData}
                            dataKey="id" paginator rows={rows} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilter={globalFilter} emptyMessage="No Data found." header={header} loading={loading} totalRecords={totalRecords} lazy first={first} onPage={onPageChange} >

                            <Column field="srid" header="Sr.No." body={onDemandidTemplate} style={{ width: '4rem' }}></Column>
                            {/* <Column field="orderId" header="Order Id" body={orderBodyTemplate} style={{ overflowWrap: 'break-word', width: '12rem' }}></Column> */}
                            <Column field="SDid" header="Sales Order ID" body={CommonBodyForTable} style={{ overflowWrap: 'break-word', width: '12rem' }}></Column>
                            <Column field="batch_name" header="Account Name" body={CommonBodyForTable}  ></Column>
                            <Column field="orderType" header="Order Type" body={CommonBodyForFormattedStrTable} ></Column>
                            <Column field="name" header="User Name" body={fullNameBodyTemplate} ></Column>
                            <Column field="facility_address" header="Facility Address" body={CommonBodyForFormattedStrTable} ></Column>
                            <Column field="materialInfo" header="No of Items" body={matBodyTemplate} ></Column>
                            <Column field="date" header="Order Date & Time" body={DateBodyForTable} ></Column>
                            <Column field="deliveryId" header="is Delivered" body={statusBodyTemplate} ></Column>
                            <Column header="Actions" style={{ width: '20rem' }} body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            <Dialog visible={infoDialog} style={{ width: '850px' }} header={`Material Order Details`} modal footer={viewPlacedOrderFooter} onHide={() => { setInfoDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                    <div className="p-col-12">
                        <div className=" widget-performance">
                            <div className="content">
                                <ul>
                                    <div className='p-grid'>
                                        <CommonForDetails name="Sales Order Id" value={selectedMaterialOrder?.SDid} />
                                        <CommonForDetails name="Order Id" value={selectedMaterialOrder?.orderId} />
                                        <CommonForDetails name="Ordered Date" value={convertDateFn(selectedMaterialOrder?.date)} />
                                        <CommonForDetails name="Account Type" value={selectedMaterialOrder?.accountType} />
                                        <CommonForDetails name="WareHouse Delivery" value={selectedMaterialOrder?.wareHouseDelivery == true ? 'YES' : 'NO'} />
                                        <CommonForDetails name="WareHouse Name" value={selectedMaterialOrder?.wareHouseName} />
                                        <CommonForDetails name="Material Count" value={selectedMaterialOrder?.materialInfo?.length} />
                                        <CommonForDetails name="User Name" value={`${selectedMaterialOrder?.name} ${selectedMaterialOrder?.lastName}`} />
                                        <CommonForDetails name="User Id" value={selectedMaterialOrder?.userid} />
                                    </div>
                                </ul>
                            </div>
                        </div>

                        <div className="p-grid crud-demo">
                            <div className="p-col-12">
                                <div className="card">
                                    <h6>Material Details</h6>
                                    <Toast ref={toast} position='center' />
                                    <DataTable value={selectedMaterialOrder?.materialInfo}
                                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Record"
                                        globalFilter={globalFilter} emptyMessage="No Data found." loading={loading} >
                                        <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '4rem' }}></Column>
                                        <Column field="Master_sku_name" header="Material Name" body={materialBodyTemplate} style={{ overflowWrap: 'break-word', width: '12rem' }}></Column>
                                        <Column field="SKUID" header="SKU Id" body={CommonBodyForTable}></Column>
                                        <Column field="quantity" header="Quantity" body={CommonBodyForTable}  ></Column>
                                        <Column field="weight" header="Weight (Gms)" body={CommonBodyForTable} ></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Dialog>

            <Dialog visible={fetchOrderDialog} style={{ width: '450px' }} header="Confirm" modal footer={fetchOrderDialogFooter} onHide={hideOrderFetch}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-my-3 " style={{ fontSize: '4rem' }} /><br />
                        <b><span>Do You Want To Fetch This Order  ?</span></b>  <br></br>
                        <span className="p-mt-5">You won't be able to revert?</span>
                    </center>
                </div>
            </Dialog>

            <Dialog visible={acceptOrderDialog} style={{ width: '450px' }} header="Confirm" modal footer={acceptOrderDialogFooter} onHide={() => { setAcceptOrderDialog(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-my-3 " style={{ fontSize: '4rem' }} /><br />
                        <b><span>Do You Want To Accept This Order  ?</span></b>  <br></br>
                        <span className="p-mt-5">You won't be able to revert?</span>
                    </center>
                </div>
            </Dialog>

            <Dialog visible={mapOrderDialog} style={{ width: '850px' }} header="Material Order Delivery" modal footer={mapOrderDialogFooter} onHide={() => { hideMapOrder() }} maximizable={true} blockScroll={true}>
                <form onSubmit={submitMapping} id="add-mapping" className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="SDid">Sales Order Id</label>
                                <InputText id="SDid" name='SDid' placeholder="Sales Order Id" value={selectedMaterialOrder?.SDid} disabled />
                            </div>
                        </div>
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="accountType">Sales Channel</label>
                                <InputText id="accountType" name='accountType' placeholder="Sales Channel" value={selectedMaterialOrder?.accountType} disabled />
                            </div>
                        </div>
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="name">Account Name</label>
                                <InputText id="name" name='name' placeholder="Account Name" value={selectedMaterialOrder?.name} disabled />
                            </div>
                        </div>
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="batch_name">Delivery Name</label>
                                <InputText id="batch_name" name='batch_name' placeholder="Delivery Name" value={selectedMaterialOrder?.batch_name} disabled />
                            </div>
                        </div>
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="taskDate">Total Packets</label>
                                <InputText id="taskDate" name='taskdate' placeholder="Total Packets" value={totalPackets} disabled />
                            </div>
                        </div>
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="userId">Delivery Mode</label>
                                <Dropdown id="userId" name="deliveryMode" value={selectedDelMode} onChange={changeInputs} options={deliveryModeOptions} optionLabel="name" placeholder="Delivery Mode" required></Dropdown>
                            </div>
                        </div>

                        {
                            selectedDelMode?.code == 'technoman' &&
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="deby">Delivery By</label>
                                    <Dropdown id="deby" name="deliveryBy" value={selectedUser} onChange={changeInputs} options={users} optionLabel="name" placeholder="Delivery By" required></Dropdown>
                                </div>
                            </div>
                        }

                        {
                            selectedDelMode?.code == 'logistic' &&
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="comment">Comment</label>
                                    <InputText id="comment" name='comment' placeholder="Comment" onChange={changeInputs} required />
                                </div>
                            </div>
                        }

                    </div>
                </form>

                <div className='p-grid'>
                    <div className="p-col-12">
                        <div className="p-grid crud-demo">
                            <div className="p-col-12">
                                <div className="card">
                                    <h6>Material Details</h6>
                                    <DataTable value={selectedMaterialOrder?.materialInfo} dataKey="id" className="datatable-responsive"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Record"
                                        globalFilter={globalFilter1} emptyMessage="No Data found." header={header1} loading={loading} >
                                        <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '4rem' }}></Column>
                                        <Column field="Master_sku_name" header="Material Name" body={materialBodyTemplate} style={{ overflowWrap: 'break-word', width: '12rem' }}></Column>
                                        <Column field="SKUID" header="SKU Id" body={CommonBodyForTable}></Column>
                                        <Column field="quantity" header="Quantity" body={CommonBodyForTable}  ></Column>
                                        <Column field="quantity" header="Barcode Count (Remain)" body={calBodyTemplate} ></Column>
                                        <Column header="Add Barcodes" body={addBarcodeBodyTemplate}></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={addBarcodeDialog} style={{ width: '450px' }} header={`Select Barcodes (${selectedMaterial?.Master_sku_name ? selectedMaterial?.Master_sku_name : selectedMaterial?.name})`} modal footer={addBDialogFooter} onHide={() => { setAddBarcodeDialog(false) }} maximizable={true} blockScroll={true}>
                <div className="p-fluid">
                    <div className='p-grid'>

                        {
                            !selectedMaterialOrder?.wareHouseDelivery &&
                            <div className='p-col-12'>
                                <div className="p-field">
                                    <label htmlFor="userId">Material Batch</label>
                                    <Dropdown id="userId" name="materialBatch" value={selectedBatch} onChange={changeInputs} options={batchOptions} optionLabel="label" placeholder="Material Batch" filter></Dropdown>
                                </div>
                            </div>
                        }

                        <div className='p-col-12'>
                            <div className="p-field">
                                <label htmlFor="barcode">Select Barcode</label>
                                <MultiSelect className="multiselect-custom"
                                    id="barcode"
                                    name="barcode"
                                    optionLabel="label"
                                    placeholder="Select Barcodes"
                                    value={filteredSelectedBarcodes}
                                    options={barcodeOptions}
                                    onChange={changeInputs}
                                    // onSelectAll={changeAllBarcode}
                                    showSelectAll={false}
                                    selectedItemsLabel={`${selectedBarcodes?.length} Barcodes Selected`}
                                    selectionLimit={selectedMaterial?.quantity}
                                    display="chip"
                                    filter
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>


            <Dialog visible={viewBarcodeDialog} style={{ width: '650px' }} header={`Selected Barcode Details`} modal footer={viewBarcodeFooter} onHide={() => { setViewBarcodeDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                    <div className="p-col-12">
                        <div className="p-grid crud-demo">
                            <div className="p-col-12">
                                <div className="card">
                                    <DataTable value={filteredSelectedBarcodes}
                                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Barcodes"
                                        globalFilter={globalFilter2} emptyMessage="No Barcodes found." loading={loading} header={barcodeHeader} >
                                        <Column field="srid" header="Sr.No." body={srBodyTemplate}></Column>
                                        <Column field="batch_name" header="Batch" body={batchBodyTemplate}></Column>
                                        <Column field="barcode" header="Barcode" body={CommonBodyForTable}></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={deleteRefillOrderDialog} style={{ width: '450px' }} header={`Confirm ?`} modal footer={deleteRefillOrderDialogFooter} onHide={() => { setDeleteRefillOrderDialog(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br />
                        <span className='p-mt-4'><b>Are you sure you want to delete refill order?</b></span>
                    </center>
                </div>
            </Dialog>


        </>
    )
}


