import React from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { AllAnalysisChart } from "./AllAnalysisChart";
import { PerformanceByLocation } from "./PerformanceByLocation";
import { OverallPerformance } from "./OverallPerformance";
import { CvesPerformanceByBev } from "./CvesPerformanceByBev";
import { AllComparisonChart } from "./AllComparisonChart";
import { KeyAccountPerformance } from "./KeyAccountPerformance";
import { KeyAccountAnalytics } from "./KeyAccountAnalytics";
import { DayWiseCVESPerformance } from "./DayWiseCVESPerformance";
import { MonthWiseCVESPerformance } from "./MonthWiseCVESPerformance";
import { MonthWiseInstallations } from "./MonthWiseInstallations";
import { MonthWisePullouts } from "./MonthWisePullouts";

export const Performance = () => {

    return (

        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <TabView>
                        <TabPanel header="Day Wise Performance">
                            <DayWiseCVESPerformance />
                        </TabPanel>
                        <TabPanel header="Month Wise Performance">
                            <MonthWiseCVESPerformance />
                        </TabPanel>
                        <TabPanel header="Performance Analysis">
                            <AllAnalysisChart />
                        </TabPanel>
                        <TabPanel header="Performance Analysis (Top 5)">
                            <OverallPerformance />
                        </TabPanel>
                        <TabPanel header="Performance By Location">
                            <PerformanceByLocation />
                        </TabPanel>
                        <TabPanel header="CVES Performance By Beverages">
                            <CvesPerformanceByBev />
                        </TabPanel>
                        <TabPanel header="Month Wise Installations">
                            <MonthWiseInstallations />
                        </TabPanel>
                        <TabPanel header="Month Wise Pullout">
                            <MonthWisePullouts />
                        </TabPanel>

                    </TabView>
                </div>
            </div>
        </div>
    )
}