import React, {useState, useRef} from "react";
import { Chart } from 'primereact/chart';


export const FlavourWiseCupChart = (props) =>{

        const basicData = {

            labels: props.flavourCupL,
            datasets: [
                {
                    label: 'Cup Consumption',
                    data: props.flavourCupC,
                    backgroundColor: props.flavourCupColor,
                    borderRadius: 5
                }
            ]
        };

        const basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    display: false
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    },
                    title: {
                        display: true,
                        text: 'Beverages'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    },
                    title: {
                        display: true,
                        text: 'No. of Cups'
                    }
                }
            }
        };



    return (
        
            <div className="card">
                {
                    props.flavourCupC.length > 0 ? 
                    <>
                        <div className="card-header">
                            <span>Flavour-Wise Cup Consumption Performance Details</span>
                        </div>
                        <div className="graph p-mt-5">
                                <Chart height="600" type="bar" data={basicData} options={basicOptions} />
                        </div>
                    </>
                    :
                    <>
                        <div className="p-mt-5">
                            <center>
                                <span className="p-error "><h5>No Data Found</h5></span>    
                            </center>
                        </div> 
                    </>
                }
            </div>       
    )
}