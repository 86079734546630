import React, { useState,useEffect, useRef} from 'react';
import { Chart } from 'primereact/chart';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import ApiServices from '../../service/api/ApiServices';
import CommonCal from '../../service/common/CommonCal';
const apiServices = new ApiServices();
const commonCal = new CommonCal();

export const EmployeeCupConsumption = (props) => {

    
    const [allEmployeeConsump,setAllEmployeeConsump] = useState([])
    const [employeeConsump,setEmployeeConsump] = useState(null)
    const [label,setLabel] = useState(null)
    const [values,setvalues] = useState(null)
    const [total,setTotal] = useState(null)
    const [selectedPeriod, setSelectedPeriod] = useState({ name: 'All', code:'all' });
    const [selectedCard, setSelectedCard] = useState({rfid : 'All',label : 'All'});
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedMachine, setSelectedMachine] = useState(null)
    const [customFilterDialog, setCustomFilterDialog] = useState(false)
    
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');

    const [cardOptions,setCardOptions] = useState(null)
    const [filterInputTemp,setFilterInputTemp] = useState({})

    const [consumptionByDate, setConsumptionByDate] = useState(null)
    const [datesforReports, setDatesforReports] = useState(null)
    const [allDates, setAllDates] = useState({})
    
    

    const dt = useRef(null);
    const dataReport = useRef(null);
    
    const dlOptions = [
        { name : 'Default', code: 'default'},
        { name : 'Day Wise', code: 'day_wise'}
    ]
    
    const multiPeriodValues = [
        { name: 'All', code:'all' },
        { name: 'Today', code:'today' },
        { name: 'Yesterday', code:'yesterday' },
        { name: 'This Week', code:'this_week' },
        { name: 'This Month', code:'this_month' },
        { name: 'Last Month', code:'last_month' },
        { name: 'Custom', code:'custom' }
    ];

    const chartData = {
        labels: label,
        datasets: [
        {
            type: 'line',
            label: 'Total Cups',
            borderColor: '#00544d',
            borderWidth: 1,
            fill: true,
            tension: .4,
            data: total
        }
        , {
            type: 'bar',
            label: 'Daily Cups',
            backgroundColor: '#00544d',
            data: values,
            borderColor: '#00544d',
            borderWidth: 2
        }]
    };

    const lightOptions = {
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                },
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                },
                title: {
                    display: true,
                    text: 'Cups'
                }
            }
        }
    };

    const filteredChartData = (data) =>{
        const lebel1 = data?.map((m)=>{return m?._id})
        setLabel(lebel1)
        const values1 = data?.map((m)=>{return m?.cups})
        setvalues(values1)
        const total1 = data?.map((m)=>{return m?.total})
        setTotal(total1)
    }

    useEffect(()=>{
        getData(props.mchEmployee)
    },[props.mchEmployee])

    useEffect(() =>{

        console.log(props.employeeConsump);
        setAllEmployeeConsump(props.employeeConsump)
        setEmployeeConsump(props.employeeConsump)
        filteredChartData(props.employeeConsump)
        setSelectedMachine(props.selectedMachine)
        const temp =  {...filterInputTemp,filter: "chart",machineid: [props.selectedMachine?.id]}
        setFilterInputTemp(temp)

    },[props.employeeConsump])

    useEffect(() => {
        getDates()
    }, [])
    
    const getDates = async() =>{
        const response = await commonCal.getDates();
        console.log(response);
        setAllDates(response)
    }

    const getData = (data) =>{
        if(data){

            const modified = data.map((x)=>{ return {...x,label:`${x.name} - ${x.rfid}`}})
            const a = [{rfid : 'All',label : 'All'}]
            const b = modified ? modified : []            
            setCardOptions([...a,...b])
        }
    }

    const exportCSV = () => {
        dt.current.exportCSV();
        // console.log("Pending");
    }

    const exportDayWise = () =>{
        console.log(dataReport.current)
            dataReport.current.exportCSV();

        // setTimeout(() => {
        //     dataReport.current.exportCSV();
            
        // }, 2000);

    }

    const getCardConsumption = async(data) =>{

        const payload = selectedCard?.rfid == 'All' ? { date : data,filter: "report", machineid: [selectedMachine.id]} : { date : data,filter: "report",id: selectedCard?.rfid,machineid: [selectedMachine.id]}; 
        
        const response = await apiServices.getCardConsumptionByDate(payload);

        const arrayy = [];
        for (let index = 0; index < response?.data?.data?.length; index++) {
            const element = response?.data?.data[index];
            const arr1={};
            for (let index1 = 0; index1 < element?.datewise?.length; index1++) {
                const el = element?.datewise[index1];
                arr1[el.date] = el.cups;
            }
            arrayy.push({...element,...arr1})
        }

        setConsumptionByDate(arrayy)

        const arr = []
        response?.data?.data.map((x)=>{
            x.datewise.map((y)=>{
                arr.push(y?.date)
            })
        })

        const dates = new Set(arr);
        const datesForReport = [...dates];

        const datesHere = datesForReport.sort(function (a, b) {
            a = a.toString().split('-');
            b = b.toString().split('-');
            return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
        });

        setDatesforReports(datesHere)
    }


    const changePeriod = (e) =>{
        setSelectedPeriod(e.value)
        
        switch (e.value.code) {

            case 'all':
                var temp = selectedCard ? (selectedCard.rfid == 'All' ? {...filterInputTemp} : {...filterInputTemp, id:selectedCard.rfid})  : {...filterInputTemp}
                props.getEmployeeConFn(temp)
                setSelectedDate(null)
                getCardConsumption({})
                break;
            case 'today':
                var temp = selectedCard ? (selectedCard.rfid == 'All' ?  {...filterInputTemp,date:{ startDate: allDates.startToday, endDate: allDates.endToday }} : {...filterInputTemp,date:{ startDate: allDates.startToday, endDate: allDates.endToday }, id:selectedCard.rfid})  : {...filterInputTemp,date:{ startDate: allDates.startToday, endDate: allDates.endToday }}
                props.getEmployeeConFn(temp)
                setSelectedDate({ startDate: allDates.startToday, endDate: allDates.endToday })
                getCardConsumption({ startDate: allDates.startToday, endDate: allDates.endToday })
                break;
            case 'yesterday':

                var temp = selectedCard ? (selectedCard.rfid == 'All' ? {...filterInputTemp,date:{ startDate: allDates.YestStart, endDate:allDates.EndStart }} : {...filterInputTemp,date:{ startDate: allDates.YestStart, endDate:allDates.EndStart }, id:selectedCard.rfid} )  :  {...filterInputTemp,date:{ startDate: allDates.YestStart, endDate:allDates.EndStart }}
                props.getEmployeeConFn(temp)
                setSelectedDate({ startDate: allDates.YestStart, endDate:allDates.EndStart })
                getCardConsumption({ startDate: allDates.YestStart, endDate:allDates.EndStart })
                break;
            case 'this_week':

                var temp = selectedCard ? (selectedCard.rfid == 'All' ? {...filterInputTemp,date:{ startDate: allDates.weekstart, endDate:allDates.weekEnd }} : {...filterInputTemp,date:{ startDate: allDates.weekstart, endDate:allDates.weekEnd }, id:selectedCard.rfid} )  : {...filterInputTemp,date:{ startDate: allDates.weekstart, endDate:allDates.weekEnd }}
                props.getEmployeeConFn(temp)
                setSelectedDate({ startDate: allDates.weekstart, endDate:allDates.weekEnd })
                getCardConsumption({ startDate: allDates.weekstart, endDate:allDates.weekEnd })

                break;
            case 'this_month':

                var temp = selectedCard ? (selectedCard.rfid == 'All' ? {...filterInputTemp,date:{ startDate: allDates.monthStart, endDate:allDates.endThisMonth }} : {...filterInputTemp,date:{ startDate: allDates.monthStart, endDate:allDates.endThisMonth }, id:selectedCard.rfid})  : {...filterInputTemp,date:{ startDate: allDates.monthStart, endDate:allDates.endThisMonth }}
                props.getEmployeeConFn(temp)
                setSelectedDate({ startDate: allDates.monthStart, endDate:allDates.endThisMonth })
                getCardConsumption({startDate: allDates.monthStart, endDate:allDates.endThisMonth})
                break;
            case 'last_month':
                var temp = selectedCard ? (selectedCard.rfid == 'All' ? {...filterInputTemp,date:{ startDate: allDates.lastStart, endDate:allDates.lastend }} : {...filterInputTemp,date:{ startDate: allDates.lastStart, endDate:allDates.lastend }, id:selectedCard.rfid})  : {...filterInputTemp,date:{ startDate: allDates.lastStart, endDate:allDates.lastend }}
                props.getEmployeeConFn(temp)
                setSelectedDate({ startDate: allDates.lastStart, endDate:allDates.lastend })
                getCardConsumption({ startDate: allDates.lastStart, endDate:allDates.lastend })
                break;
            case 'custom':
                setCustomFilterDialog(true);
                break;
            default:
                var temp = selectedCard ? (selectedCard.rfid == 'All' ? {...filterInputTemp} : {...filterInputTemp, id:selectedCard.rfid})  : {...filterInputTemp}
                props.getEmployeeConFn(temp)
                setSelectedDate(null)
                break;
        }
    }

    const handleSubmit = (e) =>{

        setCustomFilterDialog(false)
        e.preventDefault()
        const start = e.target.start_date.value;
        const end = e.target.end_date.value;
        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        let cust_start = new Date(startt).toISOString();
        let cust_end = new Date(endd).toISOString();
        let temp = selectedCard ? (selectedCard.rfid == 'All' ? {...filterInputTemp,date:{ startDate: cust_start, endDate:cust_end }} : {...filterInputTemp,date:{ startDate: cust_start, endDate:cust_end }, id:selectedCard.rfid}) : {...filterInputTemp,date:{ startDate: cust_start, endDate:cust_end }} 
        props.getEmployeeConFn(temp)
        setSelectedDate({ startDate: cust_start, endDate:cust_end })
        getCardConsumption({ startDate: cust_start, endDate:cust_end })
    }

    const changeCardId = (e) =>{
        setSelectedCard(e.value)
        if(e.value.rfid == 'All'){
            var temp = selectedDate ? {...filterInputTemp,date:selectedDate} : {...filterInputTemp}
        }else{
            var temp = selectedDate ? {...filterInputTemp,id:e.value.rfid,date:selectedDate} : {...filterInputTemp,id:e.value.rfid}
        }
        props.getEmployeeConFn(temp)
    }

    const clickOnDownload = (e) =>{
        e.value.code == 'default' ? exportCSV() : exportDayWise();
    }


    const commonBodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const commonBodyTemplate1 = (data, props) => {

        return (
            <>
                {/* <span className="p-column-title">{props.header}</span> */}
                {data[props.field] ? data[props.field] : 0}                
            </>
        );
    };
   

    const leftToolbarTemplate = () => {
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label='Duration' />
                    <Dropdown value={selectedPeriod} onChange={changePeriod} options={multiPeriodValues} optionLabel="name" placeholder="Select Duration" filter className="multiselect-custom  p-mr-2" />
                </div>

                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label='Card ID' />
                    <Dropdown value={selectedCard} onChange={changeCardId} options={cardOptions} optionLabel="label" placeholder="Select Card Id" filter className="p-m-0 multiselect-custom" />
                </div>
            </>
        )
    }

    const rightToolbarTemplate = () => (
        <>
            {
                selectedPeriod?.code == 'all' ? 
                <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded' onClick={exportCSV}/>
                :
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-download"  />
                    <Dropdown onChange={clickOnDownload} options={dlOptions} optionLabel="name" placeholder="Select" filter className="p-m-0 multiselect-custom" />
                </div>
            }

        </>
    )

    return (
        <>
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-12">
                    <div className="card">
                        
                        {
                            props.flagForToolbar &&  
                            <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                        }
                        
                        {
                            props.employeeConsump?.length > 0  ? 
                            <>
                                <Chart type="bar" data={chartData} options={lightOptions} /> 
                            </> : 
                            <center><h5 className='p-error'>No Data Found</h5></center> 
                        }
                        
                    </div>
                </div>
            </div>

            <DataTable ref={dt} value={employeeConsump} dataKey="id" emptyMessage="No data found." exportFilename={`Employee Consumption Details`} style={{display:'none'}}>
                <Column field="_id" header="Date" sortable body={commonBodyTemplate}></Column>
                <Column field="cups" header="Daily Cups" sortable body={commonBodyTemplate}></Column>
                <Column field="total" header="Total Cup Count" sortable body={commonBodyTemplate}></Column>
            </DataTable>

            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter"  onHide={()=>{setCustomFilterDialog(false);setSelectedPeriod(null)}} modal className="p-fluid">
                <form onSubmit={handleSubmit}>
                    <div className='p-grid'>
                        <div className='p-col-6'>
                                <label htmlFor="from">From</label>
                                <InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => {setCustomStart(e.value) } } required autoFocus/>
                        </div>
                        <div className='p-col-6'>
                                <label htmlFor="to">To</label>
                                <InputText id="to" type="date" name='end_date' value={customEnd} onChange={(e) => {setCustomEnd(e.value)}} required/>
                        </div>
                    </div>
                    <div className="p-dialog-footer p-mt-4">
                        <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={()=>{setCustomFilterDialog(false);setSelectedPeriod(null)}} />
                        <Button label="Submit" type='submit' icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" />
                    </div>
                </form>
            </Dialog>

            <DataTable ref={dataReport} value={consumptionByDate} dataKey="id" emptyMessage="No Data found." exportFilename={`Employee Consumption Details Per Day`} style={{display : 'none'}}  >
                <Column field="name" header="Name" body={commonBodyTemplate}></Column>
                <Column field="machineid"  header="Machine Id" body={commonBodyTemplate}></Column>
                <Column field="cups" header="Total Cups" body={commonBodyTemplate}></Column>
                {
                    datesforReports?.map((x)=>{
                        return (
                            <Column field={x} header={x}  body={commonBodyTemplate1}></Column>
                        )
                    })
                }

            </DataTable>

        </>
                
    );
}
