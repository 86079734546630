
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

export const managementCheck = () =>{

    // Management
    const userArray =  ['usr-4kcz06lggk1dlr2g9','usr-em890ckvkkpd7xgm','usr-4kcz06nslk49vsmk3','usr-em8905j0l7smd4q9','usr-em890j68l849bxm6']
    // management + me
    // const userArray =  ['usr-em890k81kypncp79','usr-4kcz06lggk1dlr2g9','usr-em890ckvkkpd7xgm','usr-4kcz06nslk49vsmk3','usr-em8905j0l7smd4q9','usr-em890j68l849bxm6']
    return userArray.includes(initialValue?.userid) || initialValue?.userType == 'invenstor';

}


// 'usr-em890k81kypncp79'; //Swapnil
// 'usr-4kcz06lggk1dlr2g9'; //HiteshSir
// 'usr-em890ckvkkpd7xgm'; //Parimal Sir
// 'usr-4kcz06nslk49vsmk3'; //Pawan Sir
// 'usr-em8905j0l7smd4q9'; //Gokul
// 'usr-em890j68l849bxm6'; //Yogesh

