import React,{useState, useEffect, useContext} from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
// Components
import { AnalyticalStat } from "./AnalyticalStat";
import { AnalyticalCharts } from "./AnalyticalCharts";
// Services
import CommonCal from '../../service/common/CommonCal';
import { managementCheck } from "../../service/admin/superadmin";
// Context
import { permissionContext } from "../../AppWrapper";
// Service
import ApiServices from "../../service/api/ApiServices"; 
const apiServices = new ApiServices()
const commonCal = new CommonCal()
// Logged User
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);
const google = window.google = window.google ? window.google : {}


export const AnalyticsMain = () =>{

    const rolePermissions = useContext(permissionContext);
    
    // const BevVendingMchOptions = [
    //     {name:'All',code:'All'},
    //     {name:'TAPRI',code:'TAPRI'},
    //     {name:'TKAFFE',code:'TKAFFE'}
    // ]

    const BevVendingMchOptions = [
        {name:'All',code:'All'},
        {name:'TAPRI KIOSK',code:'TAPRI'},
        {name:'TKAFFE KIOSK',code:'TKAFFE'},
        // {name:'TAPRI KETTLE',code:'KETTLE'}
    ]

    const TimeSelectValues = [
        {name:'All',code:'all'},
        {name:'Today',code:'today'},
        {name:'Yesterday',code:'yesterday'},
        {name:'This Week',code:'this_week'},
        {name:'This Month',code:'this_month'},
        {name:'Last Month',code:'last_month'},
        {name:'Custom',code:'custom'}
    ]

    const TimeSelectValuesForManagement = [
        {name:'All',code:'all'},
        {name:'Today',code:'today'},
        {name:'Yesterday',code:'yesterday'},
        {name:'This Week',code:'this_week'},
        {name:'This Month',code:'this_month'},
        // {name:'Last Month',code:'last_month'},
        {name:'Custom',code:'custom'}
    ]

    const TimeSelectValues1 = [
        {name:'All',code:'all'},
        {name:'Today',code:'today'},
        {name:'Yesterday',code:'yesterday'},
        {name:'This Week',code:'this_week'},
        {name:'This Month',code:'this_month'},
        {name:'Last Month',code:'last_month'},
        {name:'Custom',code:'custom'}
    ]

    const customWise = [
        { name:"Month Wise", code : 'monthwise' },
        { name:"Date Wise", code : 'datewise' }
    ]

    const machineTypeOptions = [
        { name:"All", code : 'all' },
        { name:"Demo", code : 'demo' },
        { name:"Commercial", code : 'commercial' }
    ]

    const useStatInput = { id:[],date:{ startDate:"", endDate:""}, beverage:"All" }
    const useStatInput3 = {id:[""],beverage:"All"}

    const [machineselectValue, setMachineselectValue] = useState({displayLabel : 'All', customer : 'All'});
    const [timeselectValue, setTimeselectValue] = useState({name:'This Month',code:'this_month'});
    const [timeselectValueF, setTimeselectValueF] = useState({name:'This Month',code:'this_month'});
    const [resultedMachine,setResultedMachine] = useState([])
    const [filterButton,setFilterButton] = useState(true)
    const [machineData,setMachineData] = useState(null)
    const [machinesForCount,setMachinesForCount] = useState(null)
    const [allMachineData,setAllMachineData] = useState(null)
    const [allMachines,setAllMachines] = useState(null)
    const [allMachineCount,setAllMachineCount] = useState(null)
    const [fMachineData, setFMachineData] = useState([]);
    const [filterMachineStatus, setFilterMachineStatus] = useState(false);
    const [selectSingleMachineF, setSelectSingleMachineF] = useState(false);
    const [selectSingleMachine, setSelectSingleMachine] = useState(false);
    const [customerClicks1, setCustomerClicks1] = useState(false);
    const [userStatParam, setUserStatParam] = useState(null);
    const [paramForDailyCups, setParamForDailyCups] = useState(null);
    const [paramForBevWiseDaily, setParamForBevWiseDaily] = useState(null);

    const [filteredDate,setFilteredDate] = useState(null)
    const [datesData,setDatesData] = useState(null)
    const [customFilterDialog,setCustomFilterDialog] = useState(false)
    const [selectedTimeSlot,setSelectedTimeSlot] = useState('this_month')
    const [customerValue,setCustomerValue] = useState(null)
    const [customValue, setCustomValue] = useState(false);
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    const [onlineData, setOnlineData] = useState(null);
    const [allCupConsumeData,setAllCupConsumeData] = useState()
    const [cupCData, setCupCData] = useState([]);
    const [TimeCData, setTimeCData] = useState([]);
    const [allBeverages,setAllBeverages] = useState(null)
    const [dayWiseCDataSet,setDayWiseCDataSet] = useState(null)
    const [dayWiseCData,setDayWiseCData] = useState(null)
    const [meriTapriMachines,setMeriTapriMachines] = useState(null)
    const [tapriMachineData,setTapriMachineData] = useState({displayLabel : 'All'})
    const [selectedTapri,setSelectedTapri] = useState({displayLabel : 'All'})
    const [allTapriMachines,setAllTapriMachines] = useState([])
    const [totalTapriConsumption,setTotalTapriConsumption] = useState(null)
    const [selectedTimeValue, setSelectedTimeValue] = useState({name:'This Month',code:'this_month'});
    const [tapriFilterButton,setTapriFilterButton] = useState(true)
    const [tapriSuperstar,setTapriSuperstar] = useState(null)
    const [activeTabNumber, setActiveTabNumber] = useState(0);
    const [bevChartData, setBevChartData] = useState(null);
    const [selectedBVMBrand, setSelectedBVMBrand] = useState({name:'All',code:'All'});

    // Custom
    const [customMonthsData, setCustomMonthsData] = useState(null)
    const [customYearsData, SetCustomYearsData] = useState(null)
    const [lastMonthsListDropDown, setLastMonthsListDropDown] = useState([])
    const [customType, setCustomType] = useState(null)
    const [selectedCustomMonth, setSelectedCustomMonth] = useState(null)
    const [selectedkettle, setSelectedkettle] = useState(null)
    const [totalMachineCount, setTotalMachineCount] = useState(null)
    const [custKettleFilterDialog, setCustKettleFilterDialog] = useState(false)
    // const [selectedMachineType, setSelectedMachineType] = useState({ name:"All", code : 'all' })
    const [selectedMachineType, setSelectedMachineType] = useState({ name:"Commercial", code : 'commercial' })

    useEffect(()=>{
        getMachine()
        getMachineTapri()
        getDates() 
        getmachineLatLong(null)
        // getkettleLatLong(null)
        getTest()
    },[])
    
    const getTest = () =>{

        const lastMonthData = [
            { name : "Jan"},
            { name : "Feb"},
            { name : "March"},
            { name : "April"},
            { name : "May"},
            { name : "Jun"},
            { name : "Jul"},
            { name : "Aug"},
            { name : "Sep"},
            { name : "Oct"},
            { name : "Nov"},
            { name : "Dec"}
        ]

        const yearsData = [
            { name : "2022"},
            { name : "2023"}
        ]

        setCustomMonthsData(lastMonthData)
        SetCustomYearsData(yearsData)

        var theMonths = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
        var now = new Date();

        const arrOfValues = [];
        for (var i = 0; i < 12; i++) {
            var future = new Date(now.getFullYear(), now.getMonth() + i, 1);
            var month = theMonths[future.getMonth()];
            var year = future.getFullYear() - 1;
            arrOfValues.push({ name : `${month} ${year}`, code : `${month}-${year}` })
        }

        const customMonths =  arrOfValues.reverse();
        const lastArr = customMonths.map((x,i)=>{ return { ...x, index : i }})
        setLastMonthsListDropDown(lastArr)
    }

    const getCustomDates = (i) =>{
            const currdate = new Date();
            const lastMonthStart = new Date(currdate.getFullYear(), currdate.getMonth()-(i+1), 1).setHours(0, 0, 0, 0);
            const lastMonthEnd = new Date(currdate.getFullYear(), currdate.getMonth()-(i), 0).setHours(23, 59, 59, 0);
            const lastStart = new Date(lastMonthStart).toISOString();
            const lastend = new Date(lastMonthEnd).toISOString();

            console.log({ startDate : lastStart, endDate : lastend })
            return { startDate : lastStart, endDate : lastend }
    }


    

    
    const getOnlineStat = async(machineData,date) =>{

        if(machineData?.mchmode?.paymode?.online == true && machineData?.mchmode?.paymode?.rfid == true){

            const data = {machineid:machineData.id,date}
            const response = await apiServices.empConsumptionCount(data);
            const data1 = {id:machineData.id,date}
            const response1 = await apiServices.getTransactionStats(data1);
            setOnlineData({online:response?.data?.data[0]?.count?response?.data?.data[0]?.count : null,paytm:response1?.data?.data[0]?.succ ? response1?.data?.data[0]?.succ : null})

        }else{
            setOnlineData(null)
        }
    }



    const getmachineLatLong = async (data) => {

        console.log(data)
        const response = await apiServices.getMachineLocations();
        // console.log(response.data.data)
        const response1 = await apiServices.findMachines();
        // const kettleResponse = await apiServices.findMachines({app_cat: "Tapri Kettle"});
        // console.log(kettleResponse.data.data)
        const localArray = response?.data?.data.filter((value) => { return value.location && value.id != null });
        // console.log(localArray)
        const response4 = await apiServices.getBulkMachines();
        // console.log(response4)
        // const xx = response1.data.data.filter((x)=>{ return x.id == 'MT-17'})
        // console.log(xx);

        let newArr;
        if(selectedBVMBrand?.code == 'All'){
            newArr = response1?.data?.data;
        }else if(selectedBVMBrand?.code == 'TAPRI' || selectedBVMBrand?.code == 'TKAFFE'){
            newArr = response1?.data?.data?.filter((x)=>{
                return x.brand == selectedBVMBrand?.code;
            })
        }else{
            newArr = response1?.data?.data;
        }

        console.log(newArr);
    
        var arr = [];
        newArr.forEach((element) => {
            const machineId = element.id;
            localArray?.map((x) => {   
                if(machineId == x.id){
                    arr.push({...x,
                        companyname:element.siteName?element.siteName:'CIPL',
                        facid : element?.subscription?.facilitydata?.facid,
                        facname : element?.subscription?.facilitydata?.facname,
                        accountid: element?.Customer_Account_ID,
                        subid:element?.subid
                    }) 
                }
            });
        });

        console.log(arr);
        
        if(managementCheck()){

            var arr4 = [];
            let i = 450;
            response4?.data?.data.forEach((element) => {
                const defaultdata4 = {
                    location: element.location,
                    id: 'CH-'+i,
                    connectionMode: 'Connected',
                    companyname: element.distributor
                }

                let location;
                if(i == 388 || i == 371 || i == 384 || i == 389 || i == 383 || i == 387 || i == 311 || i == 385 || i == 390){
                    location = { lat: '',lng: ''}
                }else{
                    location = { lat: element.location?.latitude, lng: element.location?.longitude }
                }

                arr4.push({...defaultdata4,location:location}) 
                i++;
            });

            console.log(arr4)
            var arr = [...arr,...arr4];
        }
        
        if(data != null){
            var arr = arr.filter(filterdat);
            function filterdat(machine) {if(machine.id == data){ return machine.id}}

            if(arr.length == 0){
                const mac =  response1.data.data.filter(fil)
                function fil(machine) {if(machine.id == data){ return machine.id}}
                const defaultdata = {
                    location: { lat: 18.6012447, lng: 74.0059544,time: '2021-03-12T09:04:04.282Z'},
                    id:data,
                    connectionMode: mac[0]?.connectionMode,
                    companyname:mac[0]?.siteName
                }
                
                arr.push(defaultdata)
            }
        }

        const centerOf =  {lat: 18.5979754, lng: 74.004489};

        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 4,
            center: centerOf,
            // mapTypeId: 'satellite',
            mapTypeId: google.maps.MapTypeId.HYBRID  
        });

        console.log(arr);

        for (let i = 0; i < arr.length; i++) {
            const img = arr[i].connectionMode == "disConnected" ? '../../assets/demo/images/Icon/offline_location.png' : '../../assets/demo/images/Icon/online_location.png';

            const marker = new google.maps.Marker({
                position : { lat : parseFloat( arr[i].location.lat ), lng : parseFloat( arr[i].location.lng ) },
                icon: img,
                map: map,
                title: 'CVES ID: '+ arr[i].id + '\n' +'Subscription ID: ' + arr[i].subid + '\n' + 'Account ID: ' + (arr[i].accountid ? arr[i].accountid : 'NA') + '\n' + 'Account Name: ' + arr[i].companyname + '\n' + 'Facility ID: ' + arr[i].facid + '\n' + 'Facility Name: ' + arr[i].facname ,
            });
          }
    }

    
    const getkettleLatLong = async (data) => {

        console.log(data)
        // console.log(data)
        const response = await apiServices.getMachineLocations();
        console.log(response.data.data)
        // const response1 = await apiServices.findMachines();
        const response1 = await apiServices.findMachines({app_cat: "Tapri Kettle"});
        // console.log(kettleResponse.data.data)
        const localArray = response?.data?.data.filter((value) => { return value.location && value.id != null });
        console.log(localArray)

        let machinesData;

        if(selectedTapri?.displayLabel == 'Demo'){
            machinesData = response1?.data?.data.filter((m) => { return m.datastore.stage == "finishedgoods" && m.subscription && m.subscription.package.site == "demo" && m.siteName != "" })
        }else if(selectedTapri?.displayLabel == 'Commercial'){
            machinesData = response1?.data?.data.filter((x) => { return x.datastore?.stage == "finishedgoods" && x.subscription?.package.site == "permanent" && x.siteName != "" })
        }else{
            machinesData = response1?.data?.data;
        }
        

        const response4 = await apiServices.getBulkMachines();
        // console.log(response4)
        // const xx = response1.data.data.filter((x)=>{ return x.id == 'MT-17'})
        // console.log(xx);

        var arr = [];
        machinesData.forEach((element) => {
            const machineId = element.id;
            localArray?.map((x) => {   
                if(machineId == x.id){ arr.push({...x,companyname:element.siteName?element.siteName:'CIPL'}) }
            });
        });

        console.log(arr);

        if(managementCheck()){

            var arr4 = [];
            let i = 300;
            response4?.data?.data.forEach((element) => {
                const defaultdata4 = {
                    location: element.location,
                    id: 'CH-'+i,
                    connectionMode: 'Connected',
                    companyname: element.distributor
                }

                let location;
                if(i == 388 || i == 371 || i == 384 || i == 389 || i == 383 || i == 387 || i == 311 || i == 385 || i == 390){
                    location = { lat: '',lng: ''}
                }else{
                    location = { lat: element.location?.latitude, lng: element.location?.longitude }
                }

                arr4.push({...defaultdata4,location:location}) 
                i++;
            });

            console.log(arr4)
            var arr = [...arr,...arr4];
        }
        
        if(data != null){
            var arr = arr.filter(filterdat);
            function filterdat(machine) {if(machine.id == data){ return machine.id}}

            if(arr.length == 0){
                const mac =  response1.data.data.filter(fil)
                function fil(machine) {if(machine.id == data){ return machine.id}}

                const defaultdata = {
                    location:{ lat: 18.6012447, lng: 74.0059544,time: '2021-03-12T09:04:04.282Z' },
                    id:data,
                    connectionMode: mac[0]?.connectionMode,
                    companyname:mac[0]?.siteName
                }

                arr.push(defaultdata)
            }
        }

        const centerOf =  {lat: 18.5979754, lng: 74.004489};

        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 4,
            center: centerOf,
            // mapTypeId: 'satellite',
            mapTypeId: google.maps.MapTypeId.HYBRID  
        });

        console.log(arr);

        for (let i = 0; i < arr.length; i++) {

            const img = arr[i].connectionMode == "disConnected" ?  '../../assets/demo/images/Icon/offline_location.png' : '../../assets/demo/images/Icon/online_location.png';

            const marker = new google.maps.Marker({
                position : { lat : parseFloat( arr[i].location.lat ), lng : parseFloat( arr[i].location.lng )},
                icon: img,
                map: map,
                title: 'CVES Id: '+ arr[i].id + '\n' + 'Account Name: ' + arr[i].companyname,
            });
          }
    }

    const getDates = async() =>{
        const response = commonCal.getDates()
        console.log(response);
        setDatesData(response)
    }

    const getMachineTapri = async() =>{
        const response = await apiServices.findMachines({app_cat: "Tapri Kettle"});
        console.log(response?.data?.data);
        setMeriTapriMachines(response?.data?.data)
        const onBoardmachines = response?.data?.data?.filter((m)=>{ return  m?.datastore?.stage == "finishedgoods" && m?.subscription && (m?.subscription?.package?.site == "demo" || m?.subscription?.package?.site == "permanent") && m?.siteName != ""  })

        const filtered = onBoardmachines.map((x)=>{ return {...x,displayLabel: x.id + ' / ' + x.subid + ' / ' + x.siteName};})
        filtered?.unshift( {displayLabel : 'All'},{displayLabel : 'Demo'},{displayLabel : 'Commercial'})
        setTapriMachineData(filtered);
        
        let result = filtered?.filter(checkMachine).map(a => a?.id);
        setAllTapriMachines(result)
        setTotalMachineCount(result)

        const currdate = new Date()
        const firstDay = new Date(currdate.getFullYear(), currdate.getMonth(), 1).setHours(0, 0, 0, 0);
        const lastDay = new Date(currdate.getFullYear(), currdate.getMonth() + 1, 0).setHours(23, 59, 59, 0);
        const monthStart = new Date(firstDay).toISOString();
        const endStart = new Date(lastDay).toISOString();
        const date = { startDate: monthStart, endDate:endStart };
        // setFilteredDate(date)
        getUsageStatTapri({id : result, date:date})
    }

    const getUsageStatTapri = async(data) =>{
        setFilteredDate(data?.date)
        const response = await apiServices.getUserStatsForMeriTapri(data);
        console.log(response?.data?.data);
        setTotalTapriConsumption(response?.data?.data?.usagesStats[0]?.totalCup)
        const superstar = response?.data?.data?.brvStats?.sort((a, b) => b.totalCup - a.totalCup);
        superstar && setTapriSuperstar(superstar[0]?._id)
        setBevChartData(response?.data?.data?.brvStats)
    }

    const getMachine = async() =>{
        const response = await apiServices.findMachines();

        // const taprimachines = response?.data?.data.filter((x)=>{ return x.app_cat == "Cherise Tapri" } )
        // console.log(taprimachines);
        // setMeriTapriMachines(taprimachines)

        if(managementCheck()){
            const ioResponse = await apiServices.getIoMachines();
            console.log(ioResponse?.data?.data)
            const ioData = ioResponse?.data?.data?.map((x)=>{
                return {...x,companyName : x.siteName};
            })
            console.log(ioData);
            // 21-02-23
            var newArr = [...response?.data?.data,...ioData];
            // var newArr = [...response?.data?.data,...ioResponse?.data?.data];

        }else{
            var newArr = response?.data?.data && [...response?.data?.data];
        }

        const response1 = await apiServices.getUserReduced();
        const response2 = await apiServices.agreementsList();

        let arr1 = []
        newArr?.map((x)=>{
            response1?.data?.data.filter((y)=>{
                if(x.customer == y.userid){
                    // 21-02-2023
                    // arr1.push({...x,accountName:y.name,companyName:y?.billing_details?.company ? y?.billing_details?.company : 'Cherise India Pvt. Ltd.',facilityName:x?.subscription?.facilitydata?.facname ? x.subscription.facilitydata?.facname : 'NA' })
                    arr1.push({...x,accountName:y.name,companyName:y?.billing_details?.company ? y?.billing_details?.company : x.companyName,facilityName:x?.subscription?.facilitydata?.facname ? x.subscription.facilitydata?.facname : 'NA' })
                }
            })
        })

        const arr = []
        arr1.map((x)=>{
            const checkUsername = obj => obj.id == x.id;
            const data = response2?.data?.data.some(checkUsername)
            arr.push({...x,agreementStatus:data})
        })

        console.log(arr);

        const onBoardmachines = arr?.filter((m)=>{ return  m.datastore.stage == "finishedgoods" && m.subscription && (m.subscription.package.site == "demo" || m.subscription?.package.site == "permanent") && m.siteName != ""  })
        const finishedGoodMachines = arr?.filter((m)=>{ return  m.datastore.stage == "finishedgoods" &&  m.siteName == "" })
        
        console.log(onBoardmachines);
        setMachineData(onBoardmachines);

        setMachinesForCount(onBoardmachines)

        let result = onBoardmachines.filter(checkMachine).map(a => a.id);
    
        setAllMachineData(result)
        setAllMachines(result)
        setAllMachineCount(result)
        thisMonthData(result)
        getResultedMachines(onBoardmachines)
        getInitialData(result)
    }

    // const getMachines = async() =>{
    //     const response = await apiServices.findMachines();
    //     if(managementCheck){
    //         const ioResponse = await apiServices.getIoMachines();
    //         console.log(ioResponse?.data?.data);
    //         var newArr = [...response?.data?.data,...ioResponse?.data?.data];
    //     }else{
    //         var newArr = [...response?.data?.data];
    //     }
        
    //     setMachineData(newArr);
    //     let result = newArr.filter(checkMachine).map(a => a.id);
    //     setAllMachineData(result)
    //     setAllMachines(result)
    //     setAllMachineCount(result)
    //     thisMonthData(result)
    //     getResultedMachines(newArr)
    //     getInitialData(result)
    // }

    const getInitialData = (machineData) =>{
        
        const currdate = new Date()
        const firstDay = new Date(currdate.getFullYear(), currdate.getMonth(), 1).setHours(0, 0, 0, 0);
        const lastDay = new Date(currdate.getFullYear(), currdate.getMonth() + 1, 0).setHours(23, 59, 59, 0);
        const monthStart = new Date(firstDay).toISOString();
        const endStart = new Date(lastDay).toISOString();
        const date = { startDate: monthStart, endDate:endStart };

        setFilteredDate(date)
        // setUserStatParam({...useStatInput,id:machineData,date:date},'this_month')
        setUserStatParam( {params:{...useStatInput,id:machineData,date:date},time:'this_month'} )
        setParamForDailyCups({id:machineData, date:date })
        getmachineStats({...useStatInput,id:machineData,date});

    }

    const getResultedMachines = (data) => {

        var res = data?.filter(checkMachine);
        // console.log(res);

        var res = res?.map((x) => {
            const subid = x.subid?x.subid:'CIPL';
            return {...x,displayLabel: x.id + ' / ' + subid + ' / ' + x.companyName};
            // return {...x,displayLabel: x.id + ' / ' + subid + ' / ' + x.siteName};
        });

       if(initialValue?.userType == 'owner' || initialValue?.userType == 'operation'){
            res?.unshift( {displayLabel : 'All',customer : 'All'}, {displayLabel : 'Demo',customer : 'Demo'} , {displayLabel : 'Commercial',customer : 'Commercial'} )
            // res?.unshift( {displayLabel : 'All',customer : 'All'})
            setResultedMachine(res)
        }else{
            res?.unshift( {displayLabel : 'All',customer : 'All'})
            setResultedMachine(res)
        }        
    }

    const thisMonthData = (result) => {

        const iddata = filterMachineStatus ? fMachineData : result;
        const currdate = new Date()
        const firstDay = new Date(currdate.getFullYear(), currdate.getMonth(), 1).setHours(0, 0, 0, 0);
        const lastDay = new Date(currdate.getFullYear(), currdate.getMonth() + 1, 0).setHours(23, 59, 59, 0);
        const monthStart = new Date(firstDay).toISOString();
        const endStart = new Date(lastDay).toISOString();
        const date = { startDate: monthStart, endDate:endStart };
        console.log(date);

        // setFilteredDate(date)
        // getLiveUserStats2({...useStatInput,id:iddata,date})
        // getmachineStats({...useStatInput,id:iddata,date});

    }

    const getmachineStats = async(data) =>{
        const response = await apiServices.getMachineConsumption(data);
        console.log(response?.data?.data);
        const comsumptionChart = response?.data?.data.map((x) => {return {...x,Charttime: new Date(x.time).toLocaleString() }});
        setAllCupConsumeData(comsumptionChart)
        
        let Cupresult = response?.data?.data.map(a => a.cups);
        let Timeresult = response?.data?.data.map(a => new Date(a.time).toLocaleString());
        setCupCData(Cupresult)
        setTimeCData(Timeresult)
    }

    function checkMachine(m) {
        return  m?.datastore?.stage == "finishedgoods" && m?.subscription && (m?.subscription?.package?.site == "demo" || m?.subscription?.package?.site == "permanent") && m?.siteName != ""
    }

    function checkDemoMachine(machine){ 
        return machine?.subscription?.package?.site == 'demo' && machine.siteName != '';
    }

    function checkpermaMachine(machine){ 
        return machine?.subscription?.package?.site == 'permanent' && machine.siteName != '';
    }

    const changeBVMBrand = (e) =>{
        setSelectedBVMBrand(e.value)
        setFilterButton(false)

        // selectedMachineType.code == 'all' 
        // selectedMachineType.code == 'demo' 
        // selectedMachineType.code == 'commercial' 

        if(e.value.code == 'All'){
            setAllMachines(allMachineData)
            const result = machineData.filter(checkMachine);
            getResultedMachines(result)
        }else{

            const data = machineData.filter((x)=>{ return x.brand == e.value.code }).map((a) =>a.id);
            setAllMachines(data)
            const result = machineData.filter(checkMachine).filter((x)=>{ return x.brand == e.value.code });
            getResultedMachines(result)
        }
    }

    const selectMachType = (e) =>{
        setSelectedMachineType(e.value)

        if(selectedBVMBrand?.code == 'All'){

            if(e.value.code == 'all'){
                let resultedDemoMachine = machineData.filter(checkMachine);
                getResultedMachines(resultedDemoMachine)
            }

            if(e.value.code == 'demo'){
                let resultedDemoMachine = machineData.filter(checkDemoMachine);
                getResultedMachines(resultedDemoMachine)
            }

            if(e.value.code == 'commercial'){
                let resultedDemoMachine = machineData.filter(checkpermaMachine);
                getResultedMachines(resultedDemoMachine)
            }

        }else{

            const result = machineData.filter(checkMachine).filter((x)=>{ return x.brand == selectedBVMBrand?.code });

            if(e.value.code == 'all'){
                let resultedDemoMachine = result.filter(checkMachine);
                getResultedMachines(resultedDemoMachine)
            }

            if(e.value.code == 'demo'){
                let resultedDemoMachine = result.filter(checkDemoMachine);
                getResultedMachines(resultedDemoMachine)
            }

            if(e.value.code == 'commercial'){
                let resultedDemoMachine = result.filter(checkpermaMachine);
                getResultedMachines(resultedDemoMachine)
            }

        }
    }

    const SelectmachineFn = async(e) =>{
        console.log(e.value)
        setFilterButton(false)
        setMachineselectValue(e.value);
        setSelectSingleMachineF(true)

        if(e.value.customer == 'All'){

            setAllMachines(allMachineData)
            getResultedMachines(machineData.filter(checkMachine))
        
        }else if(e.value.customer == 'Demo'){

            let resultedDemoMachine = machineData.filter(checkDemoMachine).map(a => a.id);
            setAllMachines(resultedDemoMachine)
            getResultedMachines(machineData.filter(checkDemoMachine))

        }else if(e.value.customer == 'Commercial'){

            let resultedPermaMachine = machineData.filter(checkpermaMachine).map(a => a.id);
            setAllMachines(resultedPermaMachine)
            getResultedMachines(machineData.filter(checkpermaMachine))

        }else{
            setAllMachines([e.value.id])
        }
    }

    const SelectTapriMachine = async(e) =>{
        console.log(e.value);
        setTapriFilterButton(false)
        setSelectedTapri(e.value)
        // if(selectedTimeValue?.code != 'custom'){
        //     setTapriFilterButton(false)
        // }
    }

    const OnChangeTapriTypeValue = async(e) =>{
        console.log(e.value);
        if(e.value.code == 'custom'){
            setCustKettleFilterDialog(true)
        }else{
            setSelectedTimeValue(e.value)
            setTapriFilterButton(false)
        }
    }

    const OnChangeTypeValue = (e) =>{
        setCustomerClicks1(true) 
        if(e.value.code == 'custom' ){
            setFilterButton(true) 
            setCustomFilterDialog(true);
        }else{
            setTimeselectValue(e.value);
            setFilterButton(false) 
        }
    } 
    

    const getdateWiseCupStat = async(data) =>{
        const response = await apiServices.getUsageStatByFilter(data);
        console.log(response.data);
        setAllBeverages(response.data.bevs)

        const dataTemplate = {
            type: 'bar',
            label: '',
            backgroundColor: '#42A5F5',
            data: []
        }

        const bevlength = response?.data?.bevs?.length;
        const arr2 = [];
        for(let k=0;k<bevlength;k++)
        {
            const arr1 = [];
            var materialName = response?.data?.bevs[k]?.materialName;
            var color = response?.data?.bevs[k]?.color;
            for(let i=0;i<response.data.data.length;i++){
                for(let j=0;j<response.data.data.length;j++){
                    if(response.data.data[i]?.data[j]?.name == materialName) {
                    arr1.push(response.data.data[i].data[j].cups)
                    } 
                }
            }
            arr2.push({...dataTemplate,data:arr1,label:materialName,backgroundColor:color})
        }

        console.log(arr2)
        setDayWiseCDataSet(arr2)
        setDayWiseCData(response.data.data);
    }


    const filterData = async() => {

        setFilterButton(true)

        setSelectedTimeSlot(timeselectValue?.code)

        if(timeselectValue?.code != 'custom'){
            setTimeselectValueF(timeselectValue);
            setSelectSingleMachine(selectSingleMachineF)
        }

        setAllMachineCount(allMachines)

        // setMachinesForCount(machineData)

        const today = { startDate: datesData.startToday, endDate: new Date() };
        const yesterday = { startDate: datesData.YestStart, endDate:datesData.EndStart };
        const thisWeek = { startDate: datesData.weekstart, endDate:datesData.weekEnd };
        const thisMonth = { startDate: datesData.monthStart, endDate:datesData.endThisMonth };
        const lastMonth = { startDate: datesData.lastStart, endDate:datesData.lastend };

        if(machineselectValue?.displayLabel == 'All' || machineselectValue?.displayLabel == 'Demo' || machineselectValue?.displayLabel == 'Commercial'){

            setCustomerValue(null)
            getOnlineStat(null)

            activeTabNumber == 0 ? getmachineLatLong(null) : getkettleLatLong(null)
            if(machineselectValue?.displayLabel == 'All'){
                var type = 'all';
                setMachinesForCount(machineData)

            }else if(machineselectValue?.displayLabel == 'Demo'){
                var type = 'demo';
                let resultedD = machineData.filter(checkDemoMachine);
                console.log(resultedD);
                setMachinesForCount(resultedD)

            }else if(machineselectValue?.displayLabel == 'Commercial'){
                var type = 'commercial';
                let resultedC = machineData.filter(checkpermaMachine);
                setMachinesForCount(resultedC)
            }

            if( timeselectValue?.code == "all"){

                setUserStatParam( {params:{...useStatInput3,id:allMachines,type:type},time:'all'} )
                setParamForDailyCups({id: allMachines })
                
            }else if( timeselectValue?.code == "today"){
        
                setFilteredDate(today)
                setUserStatParam( {params:{...useStatInput,id:allMachines,date:today,type:type},time:'today'} )
                setParamForDailyCups({id: allMachines, date:today })
                getmachineStats({...useStatInput,id:allMachines,date:today});

            }else if( timeselectValue?.code == "yesterday"){

                setUserStatParam( {params:{...useStatInput,id:allMachines,date:yesterday,type:type},time:'yesterday'} )
                setParamForDailyCups({id: allMachines, date:yesterday })
                getmachineStats({...useStatInput,id:allMachines,date:yesterday});

            }else if( timeselectValue?.code == "this_week"){

                setFilteredDate(thisWeek)
                setUserStatParam( {params:{...useStatInput,id:allMachines,date:thisWeek,type:type},time:'this_week'} )
                setParamForDailyCups({id: allMachines, date:thisWeek })
                getmachineStats({...useStatInput,id:allMachines,date:thisWeek});

            }else if( timeselectValue?.code == "this_month"){

                setFilteredDate(thisMonth)
                setUserStatParam( {params:{...useStatInput,id:allMachines,date:thisMonth,type:type},time:'this_month'} )
                setParamForDailyCups({id: allMachines, date:thisMonth })
                getmachineStats({...useStatInput,id:allMachines,date:thisMonth});
            }

            else if( timeselectValue?.code == "last_month"){

                setFilteredDate(lastMonth)
                setUserStatParam( {params:{...useStatInput,id:allMachines,date:lastMonth,type:type},time:'last_month'} )
                setParamForDailyCups({id: allMachines, date:lastMonth })
                getmachineStats({...useStatInput,id:allMachines,date:lastMonth});

            }else if( timeselectValue?.code == "custom"){
                
                setCustomFilterDialog(true);
            
            }

        }else if(machineselectValue?.displayLabel != 'All' && machineselectValue?.displayLabel != 'Demo' && machineselectValue?.displayLabel != 'Commercial'){


            const userResponse = await apiServices.userInfo(machineselectValue.customer);
            console.log(userResponse?.data?.data);

            setCustomerValue(userResponse?.data?.data?.billing_details?.company)

            activeTabNumber == 0 ? getmachineLatLong(machineselectValue.id) : getkettleLatLong(machineselectValue.id)
            
            if( timeselectValue?.code == "all"){

                setUserStatParam( {params:{...useStatInput3,id:[machineselectValue.id]},time:'all'} )
                setParamForDailyCups({id: [machineselectValue.id]})
                setParamForBevWiseDaily({filter:'chart',id:machineselectValue.id})
                getmachineStats({...useStatInput3,id:[machineselectValue.id]});
                getdateWiseCupStat({filter:'chart',id:machineselectValue.id})
                
            }else if( timeselectValue?.code == "today"){
            
                setFilteredDate(today)
                getOnlineStat(machineselectValue,today)
                setUserStatParam( {params:{...useStatInput,id:[machineselectValue.id],date:today},time:'today'} )
                setParamForDailyCups({id: [machineselectValue.id], date:today })
                setParamForBevWiseDaily({filter:'chart',id:machineselectValue.id,date:today})
                getmachineStats({...useStatInput,id:[machineselectValue.id],date:today});
                getdateWiseCupStat({date:today,filter:'chart',id:machineselectValue.id})
                
            }else if( timeselectValue?.code == "yesterday"){
        
                setFilteredDate(yesterday)
                getOnlineStat(machineselectValue,yesterday)
                setUserStatParam( {params:{...useStatInput,id:[machineselectValue.id],date:yesterday},time:'yesterday'} )
                setParamForDailyCups({id: [machineselectValue.id], date:yesterday })
                setParamForBevWiseDaily({filter:'chart',id:machineselectValue.id,date:yesterday})
                getmachineStats({...useStatInput,id:[machineselectValue.id],date:yesterday});
                getdateWiseCupStat({date:yesterday,filter:'chart',id:machineselectValue.id})
                
            }else if( timeselectValue?.code == "this_week"){

                setFilteredDate(thisWeek)
                getOnlineStat(machineselectValue,thisWeek)
                setUserStatParam( {params:{...useStatInput,id:[machineselectValue.id],date:thisWeek},time:'this_week'} )
                setParamForDailyCups({id: [machineselectValue.id], date:thisWeek })
                setParamForBevWiseDaily({filter:'chart',id:machineselectValue.id,date:thisWeek})
                getmachineStats({...useStatInput,id:[machineselectValue.id],date:thisWeek});
                getdateWiseCupStat({date:thisWeek,filter:'chart',id:machineselectValue.id})
            
            }else if( timeselectValue?.code == "this_month"){

                setFilteredDate(thisMonth)
                getOnlineStat(machineselectValue,thisMonth)
                setUserStatParam( {params:{...useStatInput,id:[machineselectValue.id],date:thisMonth},time:'this_month'} )
                setParamForDailyCups({id: [machineselectValue.id], date:thisMonth })
                setParamForBevWiseDaily({filter:'chart',id:machineselectValue.id,date:thisMonth})
                getmachineStats({...useStatInput,id:[machineselectValue.id],date:thisMonth});
                getdateWiseCupStat({date:thisMonth,filter:'chart',id:machineselectValue.id})

            }else if( timeselectValue?.code == "last_month"){

                setFilteredDate(lastMonth)
                getOnlineStat(machineselectValue,lastMonth)
                setUserStatParam( {params:{...useStatInput,id:[machineselectValue.id],date:lastMonth},time:'last_month'} )
                setParamForDailyCups({id: [machineselectValue.id], date:lastMonth })
                setParamForBevWiseDaily({filter:'chart',id:machineselectValue.id,date:lastMonth})
                getmachineStats({...useStatInput,id:[machineselectValue.id],date:lastMonth});
                getdateWiseCupStat({date:lastMonth,filter:'chart',id:machineselectValue.id})

            }else if( timeselectValue?.code == "custom"){
                setCustomFilterDialog(true);
            }
        }
    }



    const filterTapriData = async() => {

        setTapriFilterButton(true)
        
        const today = { startDate: datesData.startToday, endDate: new Date() };
        const yesterday = { startDate: datesData.YestStart, endDate:datesData.EndStart };
        const thisWeek = { startDate: datesData.weekstart, endDate:datesData.weekEnd };
        const thisMonth = { startDate: datesData.monthStart, endDate:datesData.endThisMonth };
        const lastMonth = { startDate: datesData.lastStart, endDate:datesData.lastend };

        console.log(selectedTapri)

        if(selectedTapri?.displayLabel == 'All' || selectedTapri?.displayLabel == 'Demo' || selectedTapri?.displayLabel == 'Commercial'){
            setSelectedkettle(null)
            getkettleLatLong(null)

            let selectedMachineData ; 

            if(selectedTapri?.displayLabel == 'All'){
                selectedMachineData = allTapriMachines;
            }else if(selectedTapri?.displayLabel == 'Demo'){
                selectedMachineData = meriTapriMachines?.filter((m)=>{ return  m?.datastore?.stage == "finishedgoods" && m?.subscription && m?.subscription?.package?.site == "demo" && m?.siteName != ""  }).map(a => a?.id);
            }else if(selectedTapri?.displayLabel == 'Commercial'){
                selectedMachineData = meriTapriMachines?.filter((m)=>{ return  m?.datastore?.stage == "finishedgoods" && m?.subscription && m?.subscription?.package?.site == "permanent" && m?.siteName != ""  }).map(a => a?.id);
            }
            
            setTotalMachineCount(selectedMachineData)

            if( selectedTimeValue?.code == "all"){
                getUsageStatTapri({id : selectedMachineData})
                setParamForDailyCups({id: selectedMachineData})

            }else if( selectedTimeValue?.code == "today"){
                getUsageStatTapri({ id:selectedMachineData,date:today })
                setParamForDailyCups({id: selectedMachineData, date:today })

            }else if( selectedTimeValue?.code == "yesterday"){
                getUsageStatTapri({ id:selectedMachineData,date:yesterday })
                setParamForDailyCups({id: selectedMachineData, date:yesterday })

            }else if( selectedTimeValue?.code == "this_week"){
                getUsageStatTapri({ id:selectedMachineData,date:thisWeek })
                setParamForDailyCups({id: selectedMachineData, date:thisWeek })

            }else if( selectedTimeValue?.code == "this_month"){
                getUsageStatTapri({ id:selectedMachineData,date:thisMonth })
                setParamForDailyCups({id: selectedMachineData, date:thisMonth })

            }else if( selectedTimeValue?.code == "last_month"){
                getUsageStatTapri({ id:selectedMachineData,date:lastMonth })
                setParamForDailyCups({id: selectedMachineData, date:lastMonth })

            }else if( selectedTimeValue?.code == "custom"){
                // setCustKettleFilterDialog(true);
                getUsageStatTapri({ id:selectedMachineData, date:filteredDate })
                setParamForDailyCups({id: selectedMachineData, date:filteredDate })
            }

        }else if(selectedTapri?.displayLabel != 'All'  || selectedTapri?.displayLabel != 'Demo' || selectedTapri?.displayLabel != 'Commercial'){

            setSelectedkettle(selectedTapri?.siteName)
            getkettleLatLong(selectedTapri.id)

            if( selectedTimeValue?.code == "all"){
                getUsageStatTapri({ id:[selectedTapri?.id]})
                setParamForDailyCups({id:[selectedTapri?.id]})

            }else if( selectedTimeValue?.code == "today"){
                getUsageStatTapri({ id:[selectedTapri?.id], date:today })
                setParamForDailyCups({id:[selectedTapri?.id], date:today })

            }else if( selectedTimeValue?.code == "yesterday"){
                
                getUsageStatTapri({ id:[selectedTapri?.id], date:yesterday })
                setParamForDailyCups({id:[selectedTapri?.id], date:yesterday })

            }else if( selectedTimeValue?.code == "this_week"){

                getUsageStatTapri({ id:[selectedTapri?.id], date:thisWeek })
                setParamForDailyCups({id:[selectedTapri?.id], date:thisWeek })

            }else if( selectedTimeValue?.code == "this_month"){

                getUsageStatTapri({ id:[selectedTapri?.id], date:thisMonth })
                setParamForDailyCups({id:[selectedTapri?.id], date:thisMonth })

            }else if( selectedTimeValue?.code == "last_month"){
                getUsageStatTapri({ id:[selectedTapri?.id], date:lastMonth })
                setParamForDailyCups({id:[selectedTapri?.id], date:lastMonth })
            
            }else if( selectedTimeValue?.code == "custom"){
                // setCustKettleFilterDialog(true);
                getUsageStatTapri({ id:[selectedTapri?.id], date:filteredDate })
                setParamForDailyCups({id:[selectedTapri?.id], date:filteredDate })
            }
        }
    }

    
    const hideCustomDialog = () =>{
        setCustomFilterDialog(false);
        setCustomType(null)
        // setTimeselectValue({name:'All',code:'all'});
    }

    const hideCustomeKettle = () =>{
        setCustKettleFilterDialog(false)
        setCustomStart('')
        setCustomEnd('')
    }

    
    const handleSubmit = async(e) =>{

        setCustomFilterDialog(false)
        // setTimeselectValue({name:'Custom',code:'custom'});
        setTimeselectValue(null);
        setSelectSingleMachine(selectSingleMachineF)
        setTimeselectValueF(timeselectValue);
        setCustomValue(true)
        e.preventDefault()


        if(customType?.code == 'datewise'){
            const start = e.target.start_date.value;
            const end = e.target.end_date.value;
            const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
            const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
            let cust_start = new Date(startt).toISOString();
            let cust_end = new Date(endd).toISOString();

            var date = { startDate: cust_start, endDate:cust_end };

        }else if(customType?.code == 'monthwise'){

            console.log(selectedCustomMonth)
            var date = getCustomDates(selectedCustomMonth.index);
        }

        console.log(date);

        // selectedCustomMonth

        if(machineselectValue.displayLabel == "All"  || machineselectValue?.displayLabel == 'Demo' || machineselectValue?.displayLabel == 'Commercial'){

            if(machineselectValue?.displayLabel == 'All'){
                var type = 'all'
            }else if(machineselectValue?.displayLabel == 'Demo'){
                var type = 'demo'
            }else if(machineselectValue?.displayLabel == 'Commercial'){
                var type = 'commercial'
            }

            setFilteredDate(date)
            
            // setUserStatParam( {params:{...useStatInput,id:allMachines,date:date,type:type},time:'custom'} )
            setUserStatParam( {params:{...useStatInput,id:allMachines,date:date,type:type,month:selectedCustomMonth?.code},time:'custom'} )
            setParamForDailyCups({id: allMachines, date:date })
            getmachineStats({...useStatInput,id:allMachines,date} );

        }else if(machineselectValue?.displayLabel != 'All' && machineselectValue?.displayLabel != 'Demo' && machineselectValue?.displayLabel != 'Commercial'){

            setFilteredDate(date)
            getOnlineStat(machineselectValue,date)
            // setUserStatParam( {params:{...useStatInput,id:[machineselectValue.id],date:date},time:'custom'} )
            setUserStatParam( {params:{...useStatInput,id:[machineselectValue.id],date:date,month:selectedCustomMonth?.code},time:'custom'} )
            setParamForDailyCups({id: [machineselectValue.id], date:date })
            setParamForBevWiseDaily({filter:'chart',id:machineselectValue.id,date:date})
            getmachineStats({...useStatInput,id:[machineselectValue.id],date} );
            getdateWiseCupStat({date:date,filter:'chart',id:machineselectValue.id})

            const userResponse = await apiServices.userInfo(machineselectValue.customer);
            setCustomerValue(userResponse?.data?.data?.billing_details?.company)
        }

        setDefaults()
    }

    const handleKettleSubmit = async(e) =>{

        e.preventDefault()
        hideCustomeKettle()
        setSelectedTimeValue({name:'Custom',code:'custom'})
        
        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        let cust_start = new Date(startt).toISOString();
        let cust_end = new Date(endd).toISOString();

        var date = { startDate: cust_start, endDate:cust_end };

        console.log(date);

        setFilteredDate(date)

        if(selectedTapri.displayLabel == "All"  || selectedTapri?.displayLabel == 'Demo' || selectedTapri?.displayLabel == 'Commercial'){

            let selectedMachineData; 

            if(selectedTapri?.displayLabel == 'All'){
                selectedMachineData = allTapriMachines;
            }else if(selectedTapri?.displayLabel == 'Demo'){
                selectedMachineData = meriTapriMachines?.filter((m)=>{ return  m?.datastore?.stage == "finishedgoods" && m?.subscription && m?.subscription?.package?.site == "demo" && m?.siteName != ""  }).map(a => a?.id);
            }else if(selectedTapri?.displayLabel == 'Commercial'){
                selectedMachineData = meriTapriMachines?.filter((m)=>{ return  m?.datastore?.stage == "finishedgoods" && m?.subscription && m?.subscription?.package?.site == "permanent" && m?.siteName != ""  }).map(a => a?.id);
            }

            getUsageStatTapri({ id:selectedMachineData,date:date })

        }else if(selectedTapri.displayLabel != "All"  || selectedTapri?.displayLabel != 'Demo' || selectedTapri?.displayLabel != 'Commercial'){
            getUsageStatTapri({ id:[selectedTapri?.id], date:date })
        }
    }


    const setDefaults = () =>{
        setCustomType(null)
        setSelectedCustomMonth(null)
    }

    const onChangeTab = (e) =>{

        setActiveTabNumber(e.index)
        if(e.index == 0){   
            getmachineLatLong(null)
        }else if(e.index == 1){
            getkettleLatLong(null)
            setParamForDailyCups({id:totalMachineCount, date:filteredDate })
        }
    }
    
    // const leftToolbarTemplate = () =>{ return ( <><span><p><b>FILTERS: </b></p></span></>)}

    const leftToolbarTemplate = () =>{ 
        return (
        <>
            <div className="p-inputgroup p-m-1">
                <span><p><b>FILTERS: </b></p></span>
            </div>
        </>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <>

                {
                    rolePermissions?.analyticsBrandFilBtn &&
                    <div className="p-inputgroup p-m-1">
                        <Button className='custom-group-btn' label="Category" />
                        <Dropdown id='select-mc' optionLabel="name" options={BevVendingMchOptions} onChange={changeBVMBrand} value={selectedBVMBrand} placeholder="Select Brand" filter className="p-mr-2 multiselect-custom " />
                    </div>
                }
                
                {/* <div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' label="Type" />
                    <Dropdown id='select-mc' value={selectedMachineType} onChange={selectMachType} options={machineTypeOptions} optionLabel="name" placeholder="Select Type" filter className="p-mr-2 multiselect-custom " />
                </div> */}
                
                <div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' label="CVES" />
                    <Dropdown id='select-mc' value={machineselectValue} onChange={SelectmachineFn} options={resultedMachine} optionLabel="displayLabel" placeholder="Select CVES" filter className="p-mr-2 multiselect-custom " />
                </div>

                <div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' label="Duration" />
                    <Dropdown id='select-fil' value={timeselectValue} onChange={OnChangeTypeValue}  options={managementCheck() ? TimeSelectValuesForManagement : TimeSelectValues} optionLabel="name" placeholder="Custom" filter
                        className="p-m-0 multiselect-custom" />
                </div> 

                <div>
                    { !filterButton && <Button icon="pi pi-filter" label='Filter' className="custom-group-btn p-button p-my-2 p-mx-2" disabled={filterButton} onClick={filterData} /> }
                </div>

            </>
        )
    }

    const rightToolbarTemplate1 = () => {
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="TAPRI KETTLE" />
                    <Dropdown id='select-mc' value={selectedTapri} onChange={SelectTapriMachine} options={tapriMachineData} optionLabel="displayLabel" placeholder="Select Tapri Kettle" filter className="p-mr-2 multiselect-custom " style={{ width : '110%'}} />
                </div>

                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="Duration" />
                    <Dropdown id='select-fil' value={selectedTimeValue} onChange={OnChangeTapriTypeValue}  options={TimeSelectValues1} optionLabel="name" placeholder="Select Time" filter
                        className="p-m-0 multiselect-custom" />
                </div> 

                <div>
                
                    { !tapriFilterButton && <Button icon="pi pi-filter" label='Filter' className="custom-group-btn p-button p-my-2 p-mx-2" disabled={tapriFilterButton} onClick={filterTapriData} /> }
                </div>

            </>
        )
    }


    const customFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
            {
                customType && <button className="p-button p-button-success" type="submit" form="custom-form" ><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
            }
        </>
    )

    const customKettleFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={hideCustomeKettle}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
            <button className="p-button p-button-success" type="submit" form="custom-kettle-form" ><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
        </>
    )


    return (
        <>
            <div className="layout-dashboard">
                <div className='card'>

                    {/* <TabView activeIndex={activeTabNumber} onTabChange={(e) => setActiveTabNumber(e.index)}> */}
                    <TabView activeIndex={activeTabNumber} onTabChange={onChangeTab}>
                        <TabPanel header="CVES">
                        
                            <Toolbar className=" p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                            <AnalyticalStat filteredDate={filteredDate} userStatParam={userStatParam} selectedTimeSlot={selectedTimeSlot} allMachineCount={allMachineCount} customerValue={customerValue} managementCheck={managementCheck()} onlineData={onlineData} machinesForCount={machinesForCount} machineselectValue={machineselectValue}  allMachineData={allMachineData} />

                        </TabPanel>

                        {
                            allTapriMachines.length > 0 ?

                            <TabPanel header="TAPRI KETTLE">
                                <Toolbar className=" p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate1}></Toolbar>
                                <AnalyticalStat filter="meri_tapri" totalMachineCount={totalMachineCount} allTapriMachines={allTapriMachines} selectedkettle={selectedkettle} totalTapriConsumption={totalTapriConsumption} tapriSuperstar={tapriSuperstar}  />
                            </TabPanel>

                            :
                            <TabPanel header="">
                            </TabPanel>
                        }
                        
                    </TabView>


                    <div id="map" style={{height:'400px',width:'100%',borderRadius:'12px',border:'1px solid #06685d',boxShadow:'4px 4px 5px 3px #88888b47'}}></div>
                    
                
                    <AnalyticalCharts activeTabNumber={activeTabNumber} bevChartData={bevChartData} userStatParam={userStatParam} paramForDailyCups={paramForDailyCups} customerValue={customerValue} paramForBevWiseDaily={paramForBevWiseDaily} cupCData={cupCData} TimeCData={TimeCData} allCupConsumeData={allCupConsumeData} allBeverages={allBeverages} getmachineStats={getmachineStats}  />

                </div>
            </div>

            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter" footer={customFooter}  onHide={hideCustomDialog} modal className="p-fluid" maximizable={true} blockScroll={true}>

                <form onSubmit={handleSubmit} id="custom-form" className="p-fluid">
                    <div className='p-grid'>

                        <div className='p-col-12'>
                            <label htmlFor="lasttt">Select Filter Type </label>
                            <Dropdown id='lasttt' optionLabel="name" options={customWise} value={customType} onChange={(e)=>{setCustomType(e.value)}} placeholder="Select Filter Type" filter className="p-mr-2 multiselect-custom " />
                        </div>
                    
                        {
                            customType?.code == 'datewise' &&
                            <>
                                <div className='p-col-6'>
                                    <label htmlFor="from">From </label>
                                    <InputText id="from" type="date" name='start_date' max={new Date().toISOString().slice(0, 10)}  value={customStart} onChange={(e) => {setCustomStart(e.target.value) } } required autoFocus/>
                                </div>
                                <div className='p-col-6'>
                                    <label htmlFor="to">To</label>
                                    <InputText id="to" type="date" name='end_date' min={customStart} max={new Date().toISOString().slice(0, 10)} value={customEnd} onChange={(e) => {setCustomEnd(e.target.value)}} required/>
                                </div>
                            </>

                        }

                        {
                            customType?.code == 'monthwise' && 
                            <>
                                <div className='p-col-12'>
                                    <label htmlFor="lasttt">Select Months </label>
                                    <Dropdown id='lasttt' optionLabel="name" options={lastMonthsListDropDown} value={selectedCustomMonth} onChange={(e)=>{setSelectedCustomMonth(e.value)}} placeholder="Select Last Months" filter className="p-mr-2 multiselect-custom " required />
                                </div>
                            </>
                        }

                    </div>
                </form>
            </Dialog>

            <Dialog visible={custKettleFilterDialog} style={{ width: '550px' }} header="Custom Filter" footer={customKettleFooter}  onHide={hideCustomeKettle} modal className="p-fluid" maximizable={true} blockScroll={true}>

                <form onSubmit={handleKettleSubmit} id="custom-kettle-form" className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From </label>
                            <InputText id="from" type="date" name='start_date' max={new Date().toISOString().slice(0, 10)}  value={customStart} onChange={(e) => {setCustomStart(e.target.value) } } required autoFocus/>
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' min={customStart} max={new Date().toISOString().slice(0, 10)} value={customEnd} onChange={(e) => {setCustomEnd(e.target.value)}} required/>
                        </div>
                    </div>
                </form>
            </Dialog>


            



        </>
    )
}

 
