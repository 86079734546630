import React, {useState,useEffect} from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Login } from '../../pages/Login';

export const Error = (props) => {
    
    const ErrorDialogFooter = (
        <>
            {/* <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={()=>{setDialogVisible(false)}} /> */}
        </>
    )

    return (
        <>
            <Dialog visible={props?.errorDialog?.error} style={{ width: '550px' }} header="Alert" footer={ErrorDialogFooter} modal >
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br/>
                        <b><span>{props?.errorDialog?.msg}</span></b> 
                    </center>
                </div>
            </Dialog>
        </>
    )
}