import React, { useEffect, useState, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { ColorPicker } from "primereact/colorpicker";
import { TabView, TabPanel } from 'primereact/tabview';
// Common
import { CommonForDetails } from "../CRM/Leads";
import { addNewOptions, brandOptions, modeOptions, cansOptions, addDTypeDefault, addDModelDefault, defaultCTVMTemp } from "./Constants";
import { CommonBodyForTable, commonDialogFooter, commonFormDialogFooter, commonCloseDialogFooter, srBodyTemplate } from '../Common/CommonComponents';

// Context
import { permissionContext } from "../../AppWrapper";
// Serveice
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

// ==========================================================================================================
// ==========================================================================================================

export const FgMachines = () =>{
    
    const rolePermissions = useContext(permissionContext);

    const [machineData,setMachineData] = useState([])
    const [globalFilter, setGlobalFilter] = useState('');
    const [loading, setLoading] = useState(true);
    const [selectedAddValue, setSelectedAddValue] = useState(null);
    const [addCatDialog, setAddCatDialog] = useState(false);
    const [addCategoryDialog, setAddCategoryDialog] = useState(false);
    const [allCategories, setAllCategories] = useState([]);
    const [allDeviceTypes, setAllDeviceTypes] = useState([]);
    const [allDeviceModels, setAllDeviceModels] = useState([]);
    const [openDeviceTypeDialog, setOpenDeviceTypeDialog] = useState(false);
    const [openDeviceModelDialog, setOpenDeviceModelDialog] = useState(false);
    const [catPayloadTemplate, setCatPayloadTemplate] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openDeleteCatDialog, setOpenDeleteCatDialog] = useState(false);
    const [addDeviceTypeDialog, setAddDeviceTypeDialog] = useState(false);
    const [addDTypeTemplate, setAddDTypeTemplate] = useState(addDTypeDefault);
    const [openDeleteDTypeDialog, setOpenDeleteDTypeDialog] = useState(false);
    const [selectedDeviceType, setSelectedDeviceType] = useState(null);
    const [addDeviceModelDialog, setAddDeviceModelDialog] = useState(false);
    const [addDModelTemplate, setAddDModelTemplate] = useState(addDModelDefault);
    const [selectedDModel, setSelectedDModel] = useState(null);
    const [openDeleteModelDialog, setOpenDeleteModelDialog] = useState(false);
    // CTVM
    const [selectedCTVM, setSelectedCTVM] = useState(null);
    const [viewCtvmDialog, setViewCtvmDialog] = useState(false);
    const [addCTVMDialog, setAddCTVMDialog] = useState(false);
    const [addCTVMTemplate, setAddCTVMTemplate] = useState(defaultCTVMTemp); 
    const [selectedDeviceModel, setSelectedDeviceModel] = useState(null);
    const [allFilteredDeviceModels, setAllFilteredDeviceModels] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedCans, setSelectedCans] = useState(null);
    const [selectedOpMode, setSelectedOpMode] = useState(null);
    const [openCTVMDeleteDialog, setOpenCTVMDeleteDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(()=>{
        getMachines()
        getAllCategoies()
        getDeviceTypes()
        getDeviceModels()
    },[])


    const getMachines = async() =>{
        const response = await apiServices.getAllERPMachines();
        console.log(response?.data?.data);
        setMachineData(response?.data?.data)
        setLoading(false)
    }

    const getAllCategoies = async() =>{
        const response = await apiServices.getAppCategory()
        console.log(response?.data?.appcat);
        setAllCategories(response?.data?.appcat)
    }

    const getDeviceTypes = async() =>{
        const response = await apiServices.getDeviceTypes()
        setAllDeviceTypes(response?.data?.data)
    }

    const getDeviceModels = async() =>{
        const response = await apiServices.getDeviceModels()
        setAllDeviceModels(response?.data?.data)
    }

    const setDefaultAddDialog = () =>{
        setSelectedCTVM(null)
        setSelectedCategory(null)
        setSelectedDeviceType(null)
        setSelectedDeviceModel(null)
        setSelectedBrand(null)
        setSelectedCans(null)
    }

    const onChangeAdd = (e) =>{
        switch (e.target.value.code) {

            case 'ctvm':
                setDefaultAddDialog()
                setAddCTVMDialog(true)
                break;
            case 'category':
                setAddCategoryDialog(true)
                break;
            case 'deviceType' :
                setOpenDeviceTypeDialog(true)
                break;
            case 'deviceModel' :
                setOpenDeviceModelDialog(true)
                break;
            default:
                break;
        }
    }

    const openAddCategory = () =>{
        setAddCatDialog(true)
        setSelectedCategory(null)
        setCatPayloadTemplate(null)
    }

    const openAddDeviceType = ()=>{
        setAddDeviceTypeDialog(true)
        setAddDTypeTemplate(addDTypeDefault)
        setSelectedCategory(null)
    }

    const openAddDeviceModel = () =>{
        setAddDeviceModelDialog(true)
        setSelectedDModel(null)
    }

    const setCategoryTemp = (e) =>{
        setCatPayloadTemplate({...catPayloadTemplate , [e.target.name] : e.target.value  })
    }

    // Add Category
    const categorySubmit = async(e) =>{
        e.preventDefault();
        setAddCatDialog(false)

        if(selectedCategory){
            const response = await apiServices.updateAppCategory(catPayloadTemplate)
            response?.data?.success ? success('Category Updated Successfully') : failure('Failed to Update')
        }else{
            const response = await apiServices.addAppCategory(catPayloadTemplate)
            response?.data?.success ? success('Category Added Successfully') : failure('Failed to Added')
        }
        getAllCategoies()
    }

    // Edit Category
    const editCategory = (rowData) =>{
        setAddCatDialog(true)
        setSelectedCategory(rowData)
        setCatPayloadTemplate(rowData)
    }

    // Edit Model
    const editModel = (rowData) =>{
        setAddDeviceModelDialog(true)
        setSelectedDModel(rowData)
        setAddDModelTemplate(rowData)
    }

    // Delete Categtory
    const openDeleteAlert = (rowData)=>{
        setSelectedCategory(rowData)
        setOpenDeleteCatDialog(true)
    }

    const deleteCategory = async()=>{
        setOpenDeleteCatDialog(false)
        const data = { app_cat : selectedCategory?.app_cat}
        const response = await apiServices.deleteAppCategory(data);
        response?.data?.success ? success('Category Deleted Successfully') : failure('Failed to Delete')
        getAllCategoies()
        setSelectedCategory(null)
    }

    // Device Type

    const changeDtype = (e) =>{

        if(e.target.name == 'app_cat' ){
            setAddDTypeTemplate({...addDTypeTemplate, [e.target.name] : e.value.app_cat})
            setSelectedCategory(e.value)
        }

        if(e.target.name == 'deviceType'){
            setAddDTypeTemplate({...addDTypeTemplate, [e.target.name] : e.target.value})
        }
    }
    
    const deTypeSubmit = async(e) =>{
        e.preventDefault();
        setAddDeviceTypeDialog(false)
        const response = await apiServices.addDeviceTypes(addDTypeTemplate);
        response?.data?.success ? success('Device Type Added Successfully') : failure('Failed to Add')
        getDeviceTypes()
    }

    // Device Model
    const changeDModel = (e) =>{

        if(e.target.name == 'app_cat' || e.target.name == 'deviceType' ){
            setAddDModelTemplate({...addDModelTemplate, [e.target.name] : e.value[e.target.name]})            
            e.target.name == 'app_cat' && setSelectedCategory(e.value)
            e.target.name == 'deviceType' && setSelectedDeviceType(e.value)
        }

        if(e.target.name == 'deviceModel'){
            setAddDModelTemplate({...addDModelTemplate, [e.target.name] : e.target.value})
        }

        if(e.target.name == 'pricehalf' || e.target.name == 'pricefull'){
            setAddDModelTemplate({...addDModelTemplate, price : {...addDModelTemplate?.price, [e.target.name] : e.target.value } })
        }
    }

    const deModelSubmit =  async(e) =>{
        e.preventDefault();
        hideDeviceMode()

        if(selectedDModel){
            const response = await apiServices.updateDeviceModel(addDModelTemplate);
            response?.data?.success ? success('Device Model Updated Successfully') : failure('Failed to Update')
        }else{    

            const response = await apiServices.addDeviceModel(addDModelTemplate);
            response?.data?.success ? success('Device Model Added Successfully') : failure('Failed to Add')
        }

        getDeviceModels()
    }   

    const hideDeviceMode = () =>{
        setAddDeviceModelDialog(false)
        defaultDModelDialog()
    }

    const defaultDModelDialog = () =>{
        setAddDModelTemplate(addDModelDefault)
        setSelectedCategory(null)
        setSelectedDeviceType(null)
    }

    const openDTypeDeleteDialog = (rowData) =>{
        setSelectedDeviceType(rowData)
        setOpenDeleteDTypeDialog(true)
    }

    const deleteModelAlert = (rowData) =>{
        setOpenDeleteModelDialog(true)
        setSelectedDModel(rowData)
    }

    const deleteDeviceType = async() =>{
        setOpenDeleteDTypeDialog(false)
        const data = {deviceType : selectedDeviceType.deviceType}
        const response = await apiServices.deleteDeviceType(data);
        response?.data?.success ? success('Device Type Deleted Successfully') : failure('Failed to Delete')
        getDeviceTypes()
    }

    const deleteDeviceModel = async() =>{
        setOpenDeleteModelDialog(false)
        const data = { deviceType: selectedDModel.deviceType, deviceModel: selectedDModel.deviceModel }
        const response = await apiServices.deleteDeviceModel(data);
        response?.data?.success ? success('Device Model Deleted Successfully') : failure('Failed to Delete')
        getDeviceModels()
    }

    // CTVM 

    const openViewCTVM = (rowData) =>{
        console.log(rowData);
        setSelectedCTVM(rowData)
        setViewCtvmDialog(true)
    }

    const hideAddCTVMModel = () =>{
        setAddCTVMDialog(false)
    }

    const changeCTVMTemp = (e) =>{

        if(e.target.name == 'color'){
            const colorCode = `#${e.target.value}`;
            setAddCTVMTemplate({...addCTVMTemplate,details: {...addCTVMTemplate?.details,[e.target.name] : colorCode}})
        }

        if(e.target.name == 'app_cat'){
            setSelectedCategory(e.target.value)
            setAddCTVMTemplate({...addCTVMTemplate,[e.target.name] : e.target.value[e.target.name]})
        }

        if(e.target.name == 'type'){
            setSelectedDeviceType(e.target.value)
            const filteredModel = allDeviceModels.filter((x)=>{ return x.deviceType == e.target.value.deviceType; })
            setAllFilteredDeviceModels(filteredModel)
            setAddCTVMTemplate({...addCTVMTemplate,[e.target.name] : e.target.value.deviceType
            })
        }

        if(e.target.name == 'model'){
            setSelectedDeviceModel(e.target.value)
            setAddCTVMTemplate({...addCTVMTemplate,[e.target.name] : e.target.value.deviceModel})
        }

        if(e.target.name == 'brand'){
            setSelectedBrand(e.target.value)
            setAddCTVMTemplate({...addCTVMTemplate,[e.target.name] : e.target.value.code})
        }

        if(e.target.name == 'operateMode'){
            setSelectedOpMode(e.target.value)
            setAddCTVMTemplate({...addCTVMTemplate,[e.target.name] : e.target.value.code})
        }

        if(e.target.name == 'id' || e.target.name == 'passcode' || e.target.name == 'version' ){
            setAddCTVMTemplate({...addCTVMTemplate,[e.target.name] : e.target.value})
        }

        if(e.target.name == 'cans'){
            setSelectedCans(e.target.value)
            setAddCTVMTemplate({...addCTVMTemplate,[e.target.name] : e.target.value.code})
        }

        if(e.target.name == 'simNo' || e.target.name =='network'){
            setAddCTVMTemplate({...addCTVMTemplate,details: {...addCTVMTemplate?.details,[e.target.name] : e.target.value}})
        }
    }

    const addCTVMSubmit =  async(e) =>{
        e.preventDefault();
        hideAddCTVMModel();
        if(selectedCTVM){
            const response = await apiServices.updateMachine(addCTVMTemplate);
            response?.data?.success ? success('CVES Update Successfully') : failure('Failed to Update')
        }else{
            const response = await apiServices.addMachine(addCTVMTemplate);
            response?.data?.success ? success('CVES Added Successfully') : failure('Failed to Add')
        }

        getMachines()
    }

    // Edit ctvm

    const openEditCtvm = (rowData) =>{
        setAddCTVMDialog(true)
        setSelectedCTVM(rowData)
        setAddCTVMTemplate(rowData)
    }

    const openDeleteCTVM = (rowData) =>{
        setSelectedCTVM(rowData)
        setOpenCTVMDeleteDialog(true)
    }

    const deleteCTVM = async() =>{
        setOpenCTVMDeleteDialog(false)
        const data = { id: selectedCTVM?.id }
        const response = await apiServices.deleteMachine(data);
        response?.data?.success ? success('Machine Deleted Successfully') : failure('Failed to Delete')
        getMachines()
    }

    const success = (msg) =>{
        toast.current.show({ severity: 'success', summary: 'Successful', detail:`${msg}`, life: 3000 });
    } 

    const failure = (msg) => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail:`${msg}`, life: 3000 });
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }
    
    const header = (
        <div className="table-header">
            <span className="p-input-icon-right p-mr-2">
                    <h5></h5>                
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4'  onClick={exportCSV}/>
                </div>
            </span>
        </div>
    );


    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{display:"flex"}}>

                    {
                        rolePermissions?.fgCtvmEdit &&
                        <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-warning p-mx-1" aria-label="Edit" onClick={()=>{openEditCtvm(rowData)}}/>
                    }

                    {
                        rolePermissions?.fgCtvmDelete &&
                        <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-2" aria-label="Delete" onClick={()=>{openDeleteCTVM(rowData)}}/>
                    }
                    <Button icon="pi pi-eye" title='View' className="p-button-rounded p-button-info" aria-label="View" onClick={()=>openViewCTVM(rowData)}/>
                </div>
            </>
        );
    }

    const actionCatBodyTemplate = (rowData) =>{
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{display:"flex"}}>
                    <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-warning p-mx-1" aria-label="Edit" onClick={()=>{editCategory(rowData)}}/>
                    <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-2" aria-label="Delete" onClick={()=>{openDeleteAlert(rowData)}}/>
                </div>
            </>
        )
    }

    const actionDTypeBodyTemplate = (rowData) =>{
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{display:"flex"}}>
                    <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-2" aria-label="Delete" onClick={()=>{openDTypeDeleteDialog(rowData)}}/>
                </div>
            </>
        )
    }

    const actionDModelBodyTemplate = (rowData) =>{
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{display:"flex"}}>
                    <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-warning p-mx-1" aria-label="Edit" onClick={()=>{editModel(rowData)}}/>
                    <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-2" aria-label="Delete" onClick={()=>{deleteModelAlert(rowData)}}/>
                </div>
            </>
        )
    }

    const leftToolbarTemplate = () => (
        <>
            <Chip label={`Total CVES : ${machineData ? machineData.length : '-'} `} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
        </>
    )

    const rightToolbarTemplate = () =>{
        return (
            <div className="p-inputgroup">
                <Button className='custom-group-btn' label="ADD NEW" />
                <Dropdown value={selectedAddValue} onChange={onChangeAdd} options={addNewOptions} optionLabel="label" placeholder="Select" filter
                className="p-m-0 multiselect-custom" />
            </div>
        )
    } 

    const rightCategoryToolbarTemplate = () =>{ return ( <><Button className='p-custom-btn' label="Add Category" icon="pi pi-plus-circle" iconPos="left" onClick={openAddCategory}/></>)}
    const rightDeviceTypeToolbarTemplate = () =>{ return ( <Button className='p-custom-btn' label="Add Device Type" icon="pi pi-plus-circle" iconPos="left" onClick={openAddDeviceType}/>) }
    const rightDeviceModelToolbarTemplate = () =>{return (<Button className='p-custom-btn' label="Add Device Model" icon="pi pi-plus-circle" iconPos="left" onClick={openAddDeviceModel}/>) }

    const hideCatDialog = () =>{ setAddCategoryDialog(false) }
    const hideDeviceTypeDialog = () => { setOpenDeviceTypeDialog(false) }
    const hideDeviceModelDialog = () => { setOpenDeviceModelDialog(false) }
    const hideAddCatDialog = () => { setAddCatDialog(false) }
    const hideAddDeviceTypeDialog = () => { setAddDeviceTypeDialog(false) }
    const hideOpenDeleteCatDialog = () => { setOpenDeleteCatDialog(false) }
    const hideOpenDeleteDTypeDialog = () => { setOpenDeleteDTypeDialog(false) }
    const hideOpenDeleteModelDialog = () => { setOpenDeleteModelDialog(false) }
    const hideViewCtvmDialog = () => { setViewCtvmDialog(false) }
    const hideOpenCTVMDeleteDialog = () => { setOpenCTVMDeleteDialog(false) }
        
    const onboardDialogFooter = commonCloseDialogFooter('Close', hideCatDialog)
    const deviceTypeDialogFooter = commonCloseDialogFooter('Close', hideDeviceTypeDialog)
    const deviceModelDialogFooter = commonCloseDialogFooter('Close', hideDeviceModelDialog)
    const addCatDialogFooter = commonFormDialogFooter('Close', hideAddCatDialog, 'Submit', 'add-cat')
    const addDTypeDialogFooter = commonFormDialogFooter('Close', hideAddDeviceTypeDialog, 'Submit', 'add-deType')
    const addDModelDialogFooter = commonFormDialogFooter('Close', hideDeviceMode, 'Submit', 'add-deModel')
    const deleteCatDialogFooter = commonDialogFooter('Close', hideOpenDeleteCatDialog, 'Submit', deleteCategory)
    const deleteDtypeDialogFooter = commonDialogFooter('Close', hideOpenDeleteDTypeDialog, 'Submit', deleteDeviceType)
    const deleteDModelDialogFooter = commonDialogFooter('Close', hideOpenDeleteModelDialog, 'Submit', deleteDeviceModel)    
    const viewCTVMDialogFooter = commonCloseDialogFooter('Close', hideViewCtvmDialog)
    const addCTVMDialogFooter = commonFormDialogFooter('Close', hideAddCTVMModel, 'Submit', 'add-ctvm')
    const deleteCTVMDialogFooter = commonDialogFooter('Close', hideOpenCTVMDeleteDialog, 'Delete', deleteCTVM)    

    return (    
        <>
            <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                <Toast ref={toast} position='center' />
                <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <TabView>
                    <TabPanel header="CVES">
                            <DataTable ref={dt} value={machineData}
                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Machines"
                                globalFilter={globalFilter} emptyMessage="No CTVM found." header={header} loading={loading} >
                                <Column field="srid" header="Sr.No."  body={srBodyTemplate}  exportable={false} ></Column>
                                <Column field="id" header="CVES Id" body={CommonBodyForTable}></Column>
                                <Column field="model" header="Model"  body={CommonBodyForTable}></Column>
                                <Column field="cans" header="No of Cans" body={CommonBodyForTable}  ></Column>
                                <Column header="Actions" body={actionBodyTemplate}></Column>
                            </DataTable>
                    </TabPanel>

                    {/* <TabPanel header="Capsule">
                        Content II
                    </TabPanel>
                    <TabPanel header="Meri Tapri">
                        Content III
                    </TabPanel> */}
                </TabView>

                </div>
            </div>
            </div>


            <Dialog visible={addCategoryDialog} style={{ width: '850px' }} header={`Add New Application Category `} modal className="p-fluid" footer={onboardDialogFooter} onHide={()=>{setAddCategoryDialog(false)}} maximizable={true} blockScroll={true}>  
                <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toolbar className="p-mb-4 p-toolbar"  right={rightCategoryToolbarTemplate}></Toolbar>
                        <DataTable ref={dt} value={allCategories}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Categories"  emptyMessage="No Categories Found." loading={loading} >
                            <Column field="srid" header="Sr.No."  body={srBodyTemplate}  exportable={false} ></Column>
                            <Column field="app_cat" header="CTVM Category" body={CommonBodyForTable}></Column>
                            <Column header="Actions" body={actionCatBodyTemplate}></Column>
                        </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={openDeviceTypeDialog} style={{ width: '850px' }} header={`Device Types`} modal className="p-fluid" footer={deviceTypeDialogFooter} onHide={()=>{setOpenDeviceTypeDialog(false)}} maximizable={true} blockScroll={true}>  
                <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toolbar className="p-mb-4 p-toolbar"  right={rightDeviceTypeToolbarTemplate}></Toolbar>
                        <DataTable ref={dt} value={allDeviceTypes}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Categories"  emptyMessage="No Categories Found." loading={loading} >
                            <Column field="srid" header="Sr.No."  body={srBodyTemplate}  exportable={false} ></Column>
                            <Column field="deviceType" header="Device Type" body={CommonBodyForTable}></Column>
                            <Column header="Actions" body={actionDTypeBodyTemplate}></Column>
                        </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={openDeviceModelDialog} style={{ width: '850px' }} header={`Device Models`} modal className="p-fluid" footer={deviceModelDialogFooter} onHide={()=>{setOpenDeviceModelDialog(false)}} maximizable={true} blockScroll={true}>  
                <div className="p-grid crud-demo">
                    <div className="p-col-12">
                    <div className="card">
                    <Toolbar className="p-mb-4 p-toolbar"  right={rightDeviceModelToolbarTemplate}></Toolbar>
                    <DataTable ref={dt} value={allDeviceModels}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Models"  emptyMessage="No Models Found." loading={loading} >
                        <Column field="srid" header="Sr.No."  body={srBodyTemplate}  exportable={false} ></Column>
                        <Column field="deviceModel" header="Device Type" body={CommonBodyForTable}></Column>
                        <Column header="Actions" body={actionDModelBodyTemplate}></Column>
                    </DataTable>
                    </div>
                    </div>
                </div>
            </Dialog>

            {/* Category */}
            
            <Dialog visible={addCatDialog} style={{ width: '850px' }} header={`${selectedCategory ? 'Update' : 'Add'} Category`} modal className="p-fluid" footer={addCatDialogFooter} onHide={()=>{setAddCatDialog(false)}} maximizable={true} blockScroll={true}>  
                <form onSubmit={categorySubmit} id="add-cat" className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="category-name">Category Name</label>
                        <InputText id="category-name" name='app_cat' placeholder='Category Name' defaultValue={selectedCategory?.app_cat} onChange={(e)=>{setCategoryTemp(e)}} autoComplete="off" autoFocus required />
                    </div>
                </form>
            </Dialog>

            <Dialog visible={openDeleteCatDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCatDialogFooter} onHide={()=>{setOpenDeleteCatDialog(false)}}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br/>
                        <span className='p-mt-2'><b>Are you sure you want to delete the selected category?</b></span>
                    </center>
                </div>
            </Dialog>

            {/* Device Type */}

            <Dialog visible={addDeviceTypeDialog} style={{ width: '850px' }} header={`Add Device Type`} modal className="p-fluid" footer={addDTypeDialogFooter} onHide={()=>{setAddDeviceTypeDialog(false)}} maximizable={true} blockScroll={true}>  
                <form onSubmit={deTypeSubmit} id="add-deType" className="p-fluid">

                    <div className="p-field">
                        <label htmlFor="app-cat">Application Category</label>
                        <Dropdown id="app-cat" name="app_cat" value={selectedCategory} onChange={changeDtype} options={allCategories} optionLabel="app_cat" placeholder="Select Application Category" autoFocus filter required></Dropdown>
                    </div>

                    <div className="p-field">
                        <label htmlFor="device-type">Device Type</label>
                        <InputText id="device-type" name='deviceType' placeholder='Device Type' defaultValue={null} onChange={changeDtype} autoComplete="off" required />
                    </div>

                </form>
            </Dialog>

            <Dialog visible={openDeleteDTypeDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDtypeDialogFooter} onHide={()=>{setOpenDeleteDTypeDialog(false)}}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br/>
                        <span className='p-mt-2'><b>Are you sure you want to delete the selected device type?</b></span>
                    </center>
                </div>
            </Dialog>
        
            {/* Device Model */}

            <Dialog visible={addDeviceModelDialog} style={{ width: '850px' }} header={`${selectedDModel ? 'Update' : 'Add'} Device Model`} modal className="p-fluid" footer={addDModelDialogFooter} onHide={()=>{hideDeviceMode()}} maximizable={true} blockScroll={true}>  
                <form onSubmit={deModelSubmit} id="add-deModel" className="p-fluid">
                <div className='p-grid'>
                    {
                        selectedDModel ? 
                        <>  
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="app-cat">Application Category</label>
                                    <InputText id="app-cat" value={selectedDModel?.app_cat} disabled />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="de-type">Device Type</label>
                                    <InputText id="de-type" value={selectedDModel?.deviceType} disabled />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="device-model">Device Model</label>
                                    <InputText id="device-model" value={selectedDModel?.deviceModel} disabled required />
                                </div>
                            </div>
                        </>

                        :

                        <>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="app-cat">Application Category</label>
                                    <Dropdown id="app-cat" name="app_cat" value={selectedCategory} onChange={changeDModel} options={allCategories} optionLabel="app_cat" placeholder="Select Application Category" autoFocus filter required></Dropdown>
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="de-type">Device Type</label>
                                    <Dropdown id="de-type" name="deviceType" value={selectedDeviceType} onChange={changeDModel} options={allDeviceTypes} optionLabel="deviceType" placeholder="Select Device Type" autoFocus filter required></Dropdown>
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="device-model">Device Model</label>
                                    <InputText id="device-model" name='deviceModel' placeholder='Device Model' defaultValue={null} onChange={changeDModel} autoComplete="off" required />
                                </div>
                            </div>
                        </>
                    }
                    
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="price-Half">Price (Half Cup)</label>
                            <InputText id="price-Half" type="number" name='pricehalf' placeholder='Price' defaultValue={selectedDModel ? selectedDModel?.price?.pricehalf : null} onChange={changeDModel} autoComplete="off" required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="price-Full">Price (Full Cup)</label>
                            <InputText id="price-Full" type="number" name='pricefull' placeholder='Price' defaultValue={selectedDModel ? selectedDModel?.price?.pricefull : null} onChange={changeDModel} autoComplete="off" required />
                        </div>
                    </div>
                </div>
                </form>
            </Dialog>

            <Dialog visible={openDeleteModelDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDModelDialogFooter} onHide={()=>{setOpenDeleteModelDialog(false)}}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br/>
                        <span className='p-mt-2'><b>Are you sure you want to delete the selected device model?</b></span>
                    </center>
                </div>
            </Dialog>
            
            {/* CTVM  */}
                    
            <Dialog visible={viewCtvmDialog} style={{ width: '650px' }} header="View CVES Details" modal footer={viewCTVMDialogFooter} onHide={()=>{setViewCtvmDialog(false)}}>
                <div className='p-grid'>
                    <div className="p-col-12">
                        <div className=" widget-performance">
                            <div className="content">
                                <ul>
                                <div className='p-grid'>
                                    <CommonForDetails name="Application Category" value={selectedCTVM?.app_cat}/>
                                    <CommonForDetails name="Device Type" value={selectedCTVM?.type}/>
                                    <CommonForDetails name="Device Model" value={selectedCTVM?.model}/>
                                    <CommonForDetails name="CVES Id" value={selectedCTVM?.name}/>
                                    <CommonForDetails name="No of Cans" value={selectedCTVM?.cans}/>
                                    <CommonForDetails name="Version" value={selectedCTVM?.version}/>
                                </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>


            <Dialog visible={addCTVMDialog} style={{ width: '850px' }} header={`${selectedCTVM ? 'Update' : 'Add'} CVES ${selectedCTVM ? selectedCTVM?.id : ''}`} modal className="p-fluid" footer={addCTVMDialogFooter} onHide={()=>{hideAddCTVMModel()}} maximizable={true} blockScroll={true}>  
                <form onSubmit={addCTVMSubmit} id="add-ctvm" className="p-fluid">
                <TabView >
                    <TabPanel header="CVES">
                            <div className="p-field">
                                <label htmlFor="color-in">CVES Color</label><br/>
                                <ColorPicker id="color-in" name="color" defaultColor={selectedCTVM ? selectedCTVM.details?.color : '#ffffff'} onChange={changeCTVMTemp}  />
                            </div>
                            <div className='p-grid'>
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="app-cat">Application Category</label><br/>
                                        { selectedCTVM ? <InputText value={selectedCTVM?.app_cat} disabled /> : <Dropdown id="app-cat" name="app_cat" value={selectedCategory} onChange={changeCTVMTemp} options={allCategories} optionLabel="app_cat" placeholder="Select Application Category"  filter required></Dropdown> }
                                    </div>
                                </div>
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="de-type">Device Type</label>
                                        { selectedCTVM ? <InputText value={selectedCTVM?.type} disabled /> : <Dropdown id="de-type" name="type" value={selectedDeviceType} onChange={changeCTVMTemp} options={allDeviceTypes} optionLabel="deviceType" placeholder="Select Device Type"  filter required></Dropdown>}
                                    </div>
                                </div>
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="device-model">Device Model</label>
                                        { selectedCTVM ? <InputText value={selectedCTVM?.model} disabled /> : <Dropdown id="device-model" name="model" value={selectedDeviceModel} onChange={changeCTVMTemp} options={allFilteredDeviceModels} optionLabel="deviceModel" placeholder="Select Device Model"  filter required></Dropdown> }
                                    </div>
                                </div>
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="brand">Brand</label>
                                        { selectedCTVM ? <InputText value={selectedCTVM?.brand} disabled /> : <Dropdown id="brand" name="brand" value={selectedBrand} onChange={changeCTVMTemp} options={brandOptions} optionLabel="label" placeholder="Select Brand"  filter required></Dropdown>}
                                    </div>
                                </div>
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="operateMode">Operate Mode</label>
                                        { selectedCTVM ? <InputText value={selectedCTVM?.operateMode} disabled /> : <Dropdown id="operateMode" name="operateMode" value={selectedOpMode} onChange={changeCTVMTemp} options={modeOptions} optionLabel="name" placeholder="Select Operate Mode"  filter required></Dropdown>}
                                    </div>
                                </div>
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="ctvm-id">CVES Id</label>
                                        { selectedCTVM ? <InputText value={selectedCTVM?.id} disabled /> : <InputText id="ctvm-id" name='id' placeholder='CVES Id' onChange={changeCTVMTemp} autoComplete="off" required /> }
                                    </div>
                                </div>
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="cans">No of Cans</label>
                                        { selectedCTVM ? <InputText value={selectedCTVM?.cans} disabled /> : <Dropdown id="cans" name="cans" value={selectedCans} onChange={changeCTVMTemp} options={cansOptions} optionLabel="label" placeholder="Select Cans"  filter required></Dropdown> }
                                    </div>
                                </div>
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="passcode">Passcode</label>
                                        { selectedCTVM ? <InputText value={selectedCTVM?.passcode} disabled /> : <InputText id="passcode" name='passcode' placeholder='Passcode' onChange={changeCTVMTemp} autoComplete="off" required />}
                                    </div>
                                </div>
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="version">Version</label>
                                        <InputText id="version" name='version' placeholder='Version' defaultValue={selectedCTVM ? selectedCTVM?.version : ''} onChange={changeCTVMTemp} autoComplete="off" required />
                                    </div>
                                </div>
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="simcard">Sim Card Number</label>
                                        <InputText id="simcard" name='simNo' placeholder='Sim Card Number' defaultValue={selectedCTVM ? selectedCTVM?.details?.simNo : ''} onChange={changeCTVMTemp} autoComplete="off" required />
                                    </div>
                                </div>
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="network">Network Provider</label>
                                        <InputText id="network" name='network' placeholder='Eg. Airtel' defaultValue={selectedCTVM ? selectedCTVM?.details?.network : ''} onChange={changeCTVMTemp} autoComplete="off" required />
                                    </div>
                                </div>
                            </div>
                    </TabPanel>
                </TabView>
                </form>
            </Dialog>

            <Dialog visible={openCTVMDeleteDialog} style={{ width: '450px' }} header={`Confirm (${selectedCTVM?.id})`}  modal footer={deleteCTVMDialogFooter} onHide={()=>{setOpenCTVMDeleteDialog(false)}}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br/>
                        <span className='p-mt-2'><b>Are you sure you want to delete the selected CTVM?</b></span>
                    </center>
                </div>
            </Dialog>
        </>
    )
}



