import React, { useEffect, useState } from 'react'
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { CommonForDetails } from '../CRM/Leads';

import ApiServices from '../../service/api/ApiServices';
const apiServices = new ApiServices();

// ===========================================================
// ===========================================================

export const FogOverview = () => {

  const [allTasks, setAllTasks] = useState([])
  const [selectedTasks, setSelectedTasks] = useState(null)
  const [layout, setLayout] = useState('list');
  const [taskDetailedDialog, setTaskDetailedDialog] = useState(false)
  

  useEffect(()=>{
    getData();
  },[])

  const getData = async() =>{
    const response = await apiServices.getFogTasks({});
    console.log(response?.data?.data);
    setAllTasks(response?.data?.data)
  }

	const itemTemplate = (data, layout) => {
		if (!data) {
			return;
		}
		if (layout === 'list') {
			return dataviewListItem(data);
		}
		else if (layout === 'grid') {
			return dataviewGridItem(data);
		}
	};

	const openDoc = (data) =>{
		setSelectedTasks(data)
        setTaskDetailedDialog(true)
	}

	const dataviewHeader = () => {
        return (

            <div className="p-grid p-nogutter">
                <div className="p-col-6" style={{ textAlign: 'left' }}>
                    <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
                </div>
                <div className="p-col-6" style={{ textAlign: 'right' }}>
                {/* <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded' onClick={exportCSV}/> */}
                </div>
            </div>
        );
    }

	const dataviewListItem = (data) => {
        return (
            <div className="p-col-12 p-md-12">
                <div className="product-list-item">
                    <div className="product-list-detail">                        
                        <span className={`product-badge status-closed`}>{data?.task_status}</span>
                        <div className="product-name p-mt-2">{data?.task_category}</div>
						
                        <h6 className="p-mt-2">{data?.facility_name}</h6>
                        <h6 className="p-mt-2">{data?.createdAt}</h6>
                        <div className="product-description p-mt-2">{data?.task_discription}</div>
                        {/* <span><b>Ticket Id :</b> {data?.ticketId}</span> */}
                        <hr></hr>
                        <span><b>Ticket Id :</b> {data?.ticket_id ? data?.ticket_id : '-' }</span>
                    </div>
                    <div className="product-list-action">
                        {/* <span className="product-price">Pricwe</span> */}
                        {/* <Button icon="pi pi-shopping-cart" label="Name" disabled={true}></Button> */}
                        {/* <i className="pi pi-tag product-category-icon"></i><span className="product-category">{data.ticketLevel}</span> */}
                        <span className="p-buttonset">
                            <Button icon="pi pi-eye" onClick={()=>{openDoc(data)}} />
                            {/* <Button icon="pi pi-info-circle" onClick={()=>{openView(data)}} /> */}
                        </span>
                    </div>
                </div>
            </div>
        );
    }


    const dataviewGridItem = (data) => {
        return (
            <div className="p-col-12 p-md-4">
                <div className="product-grid-item card">
                    <div className="product-grid-item-top">
                        <div>
                            <i className="pi pi-tag product-category-icon"></i>
                            {/* <span className="product-category"> {data.ticketLevel}</span> */}
                        </div>                        
                        <span className={`product-badge status-closed`}>{data.task_status}</span>
                    </div>
                    <div className="product-grid-item-content">
                        {/* <div className="product-name">{data?.ticketId}</div> */}
                        <div className="product-name p-mt-2">{data?.task_category}</div>
                        <h6 className="p-m-2">{data?.facility_name}</h6>
                        <div className="p-mt-2"> CTVM : {data?.ctvm_id?.toString()} </div>
                        <div className="p-mt-2 product-description">{data?.task_discription}</div>
                        {/* <div className="p-mt-2"><small>Date : {data?.date} </small></div> */}
                    </div>
                    <div className="product-grid-item-bottom">
                        <span><b>Ticket Id :</b> {data?.ticket_id}</span>
                        {/* <Button icon="pi pi-eye" onClick={()=>{openView(data)}}></Button> */}

                        <span className="p-buttonset">
                            <Button className='custom-group-btn p-button-rounded' icon="pi pi-eye" onClick={()=>{openDoc(data)}} />
                            {/* <Button icon="pi pi-info-circle" onClick={()=>{openView(data)}} /> */}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

	const viewTaskFooter = ( <><Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={()=>{setTaskDetailedDialog(false)}} /> </> )

	const header = dataviewHeader();

	return (
		<>
		{/* <div>FogOverview</div> */}

		<div className="p-grid list-demo">
			<div className="p-col-12">
				<div className="card">
					<DataView value={allTasks} footer={`Total : ${allTasks?.length}`} layout={layout} paginator rows={12} itemTemplate={itemTemplate} header={header}></DataView>
				</div>
			</div>
		</div>

		<Dialog visible={taskDetailedDialog} style={{ width: '650px' }} header={`Task Details (${selectedTasks?.ticket_id})`} modal footer={viewTaskFooter}  onHide={()=>{setTaskDetailedDialog(false)}} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                    <div className="p-col-12">
                    <div className=" widget-performance">
                    <div className="content">
                        <ul>
                            <div className='p-grid'>
                                <CommonForDetails name="Company Name" value={selectedTasks?.company_name} />
                                <CommonForDetails name="CTVM Id's" value={selectedTasks?.ctvm_id} />
                                <CommonForDetails name="Customer UserId" value={selectedTasks?.customer_userid} />
                                <CommonForDetails name="Facility Id" value={selectedTasks?.facility_id} />
                                <CommonForDetails name="Facility Name" value={selectedTasks?.facility_name} />
                                <CommonForDetails name="Task Category" value={selectedTasks?.task_category} />
                                <CommonForDetails name="Task Date" value={selectedTasks?.task_date} />
                                <CommonForDetails name="Task Description" value={selectedTasks?.task_discription} />
                                <CommonForDetails name="Task final closed time" value={selectedTasks?.task_final_closed_time} />
                                <CommonForDetails name="Task Id" value={selectedTasks?.task_id} />
                                <CommonForDetails name="Task Status" value={selectedTasks?.task_status} />
                                <CommonForDetails name="Task Type" value={selectedTasks?.task_type} />
                                <CommonForDetails name="Task Unhold Time" value={selectedTasks?.task_unhold_time} />
                                <CommonForDetails name="Technoman UserId" value={selectedTasks?.technoman_userid} />
                                <CommonForDetails name="Ticket Id" value={selectedTasks?.ticket_id} />
                            </div>
                        </ul>
                    </div>
                    </div>
                    </div>
                </div>
            </Dialog>

		</>
	)
}
