import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

export const FormulationDialog = props =>{

    const gender = [
        { name : 'Mr.', code : 'Mr.' },
        { name : 'Mrs.', code : 'Mrs.' }
    ]

    const zoneItems = [
        {name : 'East', code: 'east'},
        {name : 'West', code: 'west'},
        {name : 'South', code: 'south'},
        {name : 'North', code: 'north'}
    ]


    const [country,setCountry] = useState(null)
    const [state,setState] = useState(null)
    const [city,setCity] = useState(null)
    const [selectedCountry,setSelectedCountry] = useState(null)
    const [selectedState,setSelectedState] = useState(null)
    const [selectedCity,setSelectedCity] = useState(null)
    const [cnoVerify,setCnoVerify] = useState(null)
    const [contactNum,setContactNum] = useState(null)
    const [userData,setUserData] = useState(null)
    const [selectedZone,setSelectedZone] = useState(null)
    const [selectedGender,setSelectedGender] = useState({ name : 'Mr.', code : 'Mr.' })
    
    const toast = useRef(null);

    useEffect(()=>{
        getUserData(props.editFormulation)
        // getCountry()
    },[props.editFormulation])


    
    useEffect(()=> {
        setCountry(props.countries)
    }, [props.countries])

    const getUserData = async(data1) =>{
        if(data1){

            const data = data1?.prefix ? data1 : {...data1,prefix:"Mr."};

            setUserData(data)
            setContactNum(data?.cno)
            if(props.selectedUserType == 'formulation' || props.selectedUserType == 'tech' || props.selectedUserType == 'production' || props.selectedUserType == 'wareHouse' || props.selectedUserType == 'techqc' ){

                data?.prefix == 'Mrs.' ? setSelectedGender({ name : 'Mrs.', code : 'Mrs.' }) : setSelectedGender({ name : 'Mr.', code : 'Mr.' })

                setSelectedCountry(data?.addr?.country)
                const res = await apiServices.getState({ country_id: data?.addr?.country.id });
                setState(res.data);
                setSelectedState(data?.addr?.state)
                const res1 = await apiServices.getCity({ state_id: data?.addr?.state.id });
                setCity(res1.data);
                setSelectedCity(data?.addr?.city)

            }else if(props.selectedUserType == 'marketmanager'){

                setSelectedCountry(data?.userdata?.country)
                const res = await apiServices.getState({ country_id: data?.userdata?.country.id });
                setState(res.data);
                setSelectedState(data?.userdata?.state)
                const res1 = await apiServices.getCity({ state_id: data?.userdata?.state.id });
                setCity(res1.data);
                setSelectedCity(data?.userdata?.city)

                if(data?.userdata?.zone == 'east'){
                    setSelectedZone({name : 'East', code: 'east'})
                }else if(data?.userdata?.zone == 'west'){
                    setSelectedZone({name : 'West', code: 'west'})
                }else if(data?.userdata?.zone == 'south'){
                    setSelectedZone({name : 'South', code: 'south'})
                }else if(data?.userdata?.zone == 'north'){
                    setSelectedZone({name : 'North', code: 'north'})
                }

            }
            
        }
    }   

    // const getCountry = async() =>{
    //     const res = await apiServices.getCountry();
    //     setCountry(res?.data)
    // }

    const onFormulationSubmit = async(e) =>{
        e.preventDefault();
        const response = await apiServices.updateUser(userData)

        console.log(userData);

        if(response.data.success){
            toast.current.show({ severity: 'success', summary: 'Successful', detail:'User Updated Successfully', life: 3000 });
        }else{
            toast.current.show({ severity: 'error', summary: 'Failed', detail:'Failed to Update', life: 3000 });
        }
        props.setOpenFormulationDialog(false)
        props.getUser()
        props.setLoading(true)
    }

    const callVerification = async(data) =>{
        if(contactNum != data){
            const res = await apiServices.verifyUser({cno: data});
            res?.data?.success ? setCnoVerify(true) : setCnoVerify(false)
        }else{
            setCnoVerify(false)
        }
    }

    const changeData = (e) =>{

        if(e.target.name == 'addr'){
            setUserData({...userData,addr:{...userData.addr,[e.target.name]:e.target.value}})
        }else if(e.target.name == 'cno'){

            if(e.target.value.length == 10){
                const contact = '+91' + e.target.value;
                callVerification(contact)
                setUserData({...userData,[e.target.name]:contact})
            }else if(e.target.value.length > 10){
                callVerification(e.target.value)
                setUserData({...userData,[e.target.name]:e.target.value})
            }else{
                setCnoVerify(false)
            }

        }else if(e.target.name == 'zone'){
            setSelectedZone(e.value)
            setUserData({...userData,userdata:{...userData.userdata,[e.target.name]:e.value.code}})
        }else if(e.target.name == 'prefix'){
            setSelectedGender(e.target.value)
            setUserData({...userData,[e.target.name]:e.target.value.code})
        }else{
            setUserData({...userData,[e.target.name]:e.target.value})
        }    
    }

    const changeCountry = async(e) =>{
        setSelectedCountry(e.target.value)
        const res = await apiServices.getState({ country_id: e.target.value.id });
        setState(res.data);
        setSelectedState(null)
        setSelectedCity(null)
        changingLoc(e)
    }

    const changeState = async(e) =>{
        setSelectedState(e.target.value)
        const res1 = await apiServices.getCity({ state_id: e.target.value.id });
        setCity(res1.data)
        changingLoc(e)
    }

    const changeCity = (e) =>{
        setSelectedCity(e.target.value)
        changingLoc(e)
    }

    const changingLoc = e =>{
        if(props.selectedUserType == 'formulation' || props.selectedUserType == 'tech' || props.selectedUserType == 'production'){
            setUserData({...userData,addr:{...userData.addr,[e.target.name]:e.target.value}})
        }else if(props.selectedUserType == 'marketmanager'){
            setUserData({...userData,userdata:{...userData.userdata,[e.target.name]:e.target.value}})
        }
    }   

    const formulationDialogFooter =(
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={()=>{props.setOpenFormulationDialog(false)}} />
            <Button label="Submit" type="submit" form='cust-form' icon="pi pi-check" className="p-button-success" disabled={cnoVerify} />
        </>
    )
    
    return (

        <>
            <Toast ref={toast} position='center' />
            <Dialog visible={props.openFormulationDialog} style={{ width: "750px" }} header={`Update ${props.selectedUserType == 'formulation' ? 'Formulation' : ''} ${props.selectedUserType == 'marketmanager' ? 'Market Manager' : ''} ${props.selectedUserType == 'tech' ? 'Technical User' : ''} ${props.selectedUserType == 'production' ? 'Production' : ''} ${props.selectedUserType == 'owner' ? 'Owner' : ''}`} modal footer={formulationDialogFooter} onHide={()=>{props.setOpenFormulationDialog(false)}} className="p-fluid"  maximizable={true} blockScroll={true} >
            <form onSubmit={onFormulationSubmit} id="cust-form" className="p-fluid">
            <h5>Account Details</h5>
            <div className="p-grid">
                <div className="p-col-12 p-md-12">
                <div className="p-fluid p-formgrid p-grid">
                    
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="prefix">Mr/Mrs</label>
                        <Dropdown id="prefix" name="prefix" filter optionLabel="name" placeholder="Select Prefix" value={selectedGender} options={gender} onChange={changeData} required ></Dropdown>
                    </div>

                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="name">First Name</label>
                        <InputText id="name" name="name" type="text" placeholder="First Name" defaultValue={userData?.name} onChange={changeData} required/>
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="last-name">Last Name</label>
                        <InputText id="last-name" name="lastName" type="text" placeholder="Last Name" defaultValue={userData?.lastName} onChange={changeData} required/>
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="email-id">Email Id</label>
                        <InputText id="email-id" name="email" type="text" placeholder="Email ID" defaultValue={userData?.email} onChange={changeData} required/>
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="contact">Contact Number</label>
                        <InputText id="contact" name="cno" type="text" minLength={10} maxLength={13}  placeholder="Contact Number" defaultValue={userData?.cno} onChange={changeData} required/>  
                        { cnoVerify && <small className="p-error block">Number Already Exists.</small> }             
                    </div>

                    {
                        props.selectedUserType == 'marketmanager' &&
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="zone">Zone</label>
                            <Dropdown id="zone" name="zone" filter optionLabel="name" placeholder="Select Zone" value={selectedZone} options={zoneItems} onChange={changeData} required ></Dropdown>
                        </div>
                    }
                    
                    <div className="p-field p-col-12">
                        <label htmlFor="address">Address</label>
                        <InputTextarea id="address" name="addr" rows="2" placeholder="Address" defaultValue={userData?.addr?.addr} onChange={changeData} required/>
                    </div>

                    {

                        props.selectedUserType != 'owner' &&
                        <>

                        {/* <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="country">Country</label>
                            <InputText id="country" name="country" type="text" placeholder="Select Country" defaultValue={userData?.addr?.country?.name} disabled/>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="dstate">State</label>
                            <InputText id="dstate" name="state" type="text" placeholder="Select State" defaultValue={userData?.addr?.state?.name} disabled/>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="dcity">City</label>
                            <InputText id="dcity" name="city" type="text" placeholder="Select City" defaultValue={userData?.addr?.city?.name} disabled/>
                        </div> */}


                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="country">Country</label>
                            <Dropdown name="country" filter optionLabel="name" placeholder="Select Country" value={selectedCountry} options={country} onChange={changeCountry} required ></Dropdown>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="dstate">State</label>

                            <Dropdown id="dstate" name="state"  optionLabel="name" filter placeholder="Select State" value={selectedState} options={state} onChange={changeState} required></Dropdown>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="dcity">City</label>
                            <Dropdown id="dcity" name="city" optionLabel="name" filter placeholder="Select City" value={selectedCity} options={city} onChange={changeCity} required></Dropdown>
                        </div>

                        </>
                    }

                </div>
                </div>
            </div>
            </form>
            </Dialog>
        </>
    )
}