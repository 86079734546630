import React, {useState,useEffect} from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
// Service
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

export const DemoStats = () =>{

    const [ioStats,setIoStats] = useState(null)
    const [ioTemplate,setIoTemplate] = useState(null)

    useEffect(()=>{
        getData()
    },[])

    const getData = async() =>{
        const ioStatsResponse = await apiServices.getIoStats();
        console.log(ioStatsResponse.data.data);
        setIoStats(ioStatsResponse.data.data)
    }

    const changeData = (e) =>{
        console.log(e.target.name);
        console.log(e.target.value);
        setIoTemplate({...ioTemplate,[e.target.name]:parseInt(e.target.value)})
    }

    const submitDemoValues = async(e) =>{
        e.preventDefault()
        const data = {...ioStats,...ioTemplate}
        console.log(data);

        alert('Pending due to api')


    }

    return(
        <>
            <div className="p-card p-p-4">
                <div className="p-fluid">
                <form onSubmit={submitDemoValues} id="demo-form" >
                    <div className="p-grid">
                        <div className="p-col-6">
                            <div className="p-field">
                            <label htmlFor="accounts">Total Accounts</label>
                            <InputText id="accounts" name='accountCount' placeholder='Total Accounts' defaultValue={ioStats?.accountCount} onChange={changeData} min={0} autoFocus required />
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="p-field">
                            <label htmlFor="distributors">Total Distributors</label>
                            <InputText id="distributors" name='partnerCount' placeholder='Total Distributors' defaultValue={ioStats?.partnerCount} onChange={changeData} min={0}  required />
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="p-field">
                            <label htmlFor="dist-ctvm">Total Distributor's CTVM</label>
                            <InputText id="dist-ctvm" name='partnerCTVMCount' placeholder='Total Distributors CTVM' defaultValue={ioStats?.partnerCTVMCount} onChange={changeData} min={0}  required />
                            </div>
                        </div>
                    </div>
                </form>
                </div> 

                <Button label="Save" icon="pi pi-check" type='submit' form='demo-form' className="p-button-primary p-mr-2 p-mb-2" />

            </div>

        </>
    )
}