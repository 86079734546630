export const durationOptions = [
    {name:'All',code:'all'},
    {name:'Today',code:'today'},
    {name:'Yesterday',code:'yesterday'},
    {name:'This Week',code:'this_week'},
    {name:'This Month',code:'this_month'},
    {name:'Last Month',code:'last_month'},
    {name:'Custom',code:'custom'}
]

export const viewOption = [
    {name:'Day Wise', code:'daywise'},
    {name:'Machine Wise', code:'machinewise'},
    {name:'Account Wise', code:'accountWise'}
]

export const subscriptionOptions = [
    {name:'All', code:'all'},
    {name:'Demo', code:'demo'},
    {name:'Commercial', code:'permanent'}
]