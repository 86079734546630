import React, {memo, useEffect, useRef, useState} from "react";
import { Chart } from "primereact/chart";
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from 'primereact/button';

// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

const BeverageWiseDailyChart = (props) => {
    
    const [allData, setAllData] = useState(null)
    const [loading,setLoading] = useState(true)
    const [allBeverages,setAllBeverages] = useState(null)
    const [dayWiseCDataSet,setDayWiseCDataSet] = useState(null)
    const [dayWiseCData,setDayWiseCData] = useState(null)
    const [dayWiseLabelData,setDayWiseLabelData] = useState(null)

    const [dataForChart,setDataForChart] = useState(null)
    const [inputValues,setInputValues] = useState(null)
    const [startDate,setStartDate] = useState(null)
    
    const dt = useRef(null);
    
    useEffect(()=>{
        getData(props.paramForBevWiseDaily)
    },[props.paramForBevWiseDaily])

    const getData = async(data) =>{

        if(data){

            setInputValues(data)
            const datett = `${new Date(data?.date?.startDate)?.toDateString()} - ${new Date(data?.date?.endDate)?.toDateString()}`;
            setStartDate( data?.date?.startDate ?  datett : 'All Time')
            setLoading(true)
            const response = await apiServices.getUsageStatByFilter(data);
            setAllBeverages(response.data.bevs)

            const dataTemplate = { type: 'bar', label: '', backgroundColor: '#42A5F5', data: [] }
            const bevlength = response?.data?.bevs?.length;
            
            const arr2 = [];

            for(let k=0;k<bevlength;k++)
            {
                const arr1 = [];
                var materialName = response?.data?.bevs[k]?.materialName;
                var color = response?.data?.bevs[k]?.color;
                for(let i=0;i<response.data.data.length;i++){
                    const check = response.data.data[i].data.find( vendor => vendor['name'] === materialName );
                    if(check){
                        arr1.push(check.cups)
                    }else{
                        arr1.push(0)
                    }
                }

                arr2.push({...dataTemplate,data:arr1,label:materialName,backgroundColor:color})
            }

            setAllData(response.data.data)
           
            const arrrrr = []
            const ddddd = response.data.data.map((x)=>{
                     arrrrr.push({...x,
                        [x.data[0]?.name]: x.data[0]?.cups ? x.data[0]?.cups : 0 , 
                        [x.data[1]?.name]: x.data[1]?.cups ? x.data[1]?.cups : 0 , 
                        [x.data[2]?.name]: x.data[2]?.cups ? x.data[2]?.cups : 0 ,
                        [x.data[3]?.name]: x.data[3]?.cups ? x.data[3]?.cups : 0 ,
                        [x.data[4]?.name]: x.data[4]?.cups ? x.data[4]?.cups : 0 ,
                        [x.data[5]?.name]: x.data[5]?.cups ? x.data[5]?.cups : 0 ,
                        [x.data[6]?.name]: x.data[6]?.cups ? x.data[6]?.cups : 0 ,
                    })  
            })

            setDataForChart(arrrrr)
            setDayWiseCDataSet(arr2)
            setDayWiseCData(response.data.data);
            setDayWiseLabelData(response.data.data.map(a => a._id))             
            setLoading(false)
        }
    }

    const fieldTemplate = (rowData, props) => {
        return ( <> <span className="p-column-title">{props.header}</span>{rowData[props.field]} </> );
    }

    const bevFieldTemplate = (rowData, props) => {
        return ( <> <span className="p-column-title">{props.header}</span>{rowData[props.field] ? rowData[props.field] : '-' } </> );
    }


    const stackedData = {
        labels: dayWiseLabelData,
        datasets: dayWiseCDataSet,
        borderRadius: 5,
    };

    const stackedOptions = {
        maintainAspectRatio: false,
        aspectRatio: .5,
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                },
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                },
                title: {
                    display: true,
                    text: 'Beverages'
                }
            }
        }
    };
    

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    return (
        <>
            <div className="p-col-12">
            <div className="card widget-visitor-graph">
                <div className="card-header">
                    <span></span>
                    <div className='p-col-sm-4'>
                        <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-primary p-ml-2 p-mb-2" onClick={exportCSV} />
                    </div>
                </div>

                {
                    loading ? 
                    <div className='spinner-div'>
                        <center><ProgressSpinner strokeWidth={7} animationDuration='4s'/></center>
                    </div>
                    :   
                    <div className="graph">
                        <Chart type="bar" data={stackedData} options={stackedOptions} refresh />
                    </div>
                }

                
            </div>
            </div>  

            <DataTable style={{display : 'none'}}  ref={dt} value={dataForChart} dataKey="id" exportFilename={`Flavor-Wise Daily Cup Consumption of ${inputValues?.id} (${startDate})  `}  className="datatable-responsive" emptyMessage="No data found.">
                <Column field="_id" header="Date"  body={fieldTemplate}></Column>
                <Column field={dayWiseCDataSet && dayWiseCDataSet[0]?.label} header={dayWiseCDataSet && dayWiseCDataSet[0]?.label}  body={bevFieldTemplate}></Column>
                <Column field={dayWiseCDataSet && dayWiseCDataSet[1]?.label} header={dayWiseCDataSet && dayWiseCDataSet[1]?.label}  body={bevFieldTemplate}></Column>
                <Column field={dayWiseCDataSet && dayWiseCDataSet[2]?.label} header={dayWiseCDataSet && dayWiseCDataSet[2]?.label}  body={bevFieldTemplate}></Column>
                <Column field={dayWiseCDataSet && dayWiseCDataSet[3]?.label} header={dayWiseCDataSet && dayWiseCDataSet[3]?.label}  body={bevFieldTemplate}></Column>
                <Column field={dayWiseCDataSet && dayWiseCDataSet[4]?.label} header={dayWiseCDataSet && dayWiseCDataSet[4]?.label}  body={bevFieldTemplate}></Column>
                <Column field={dayWiseCDataSet && dayWiseCDataSet[5]?.label} header={dayWiseCDataSet && dayWiseCDataSet[5]?.label}  body={bevFieldTemplate}></Column>
                <Column field={dayWiseCDataSet && dayWiseCDataSet[6]?.label} header={dayWiseCDataSet && dayWiseCDataSet[6]?.label}  body={bevFieldTemplate}></Column>
            </DataTable> 

        </>
    )

}

export default memo(BeverageWiseDailyChart);