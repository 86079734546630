import React, {useState, useRef} from "react";
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


export const AccountWiseTargetChart = (props) =>{
    
    const dt = useRef(null);
    const [filterType, setFilterType] = useState({ name: 'All', code:'all'});
    const [min, setMin] = useState(null);
    const [max, setMax] = useState(null);

    const arr = props.filteredArray;
    const arr1 = props.filteredMISArray;

    function checkFilter1(i) {return i.ratio < max }
    function checkFilter2(i) {return i.ratio > min && i.ratio < max }
    function checkFilter3(i) {return i.ratio > min && i.ratio < max }
    function checkFilter4(i) {return i.ratio > min  }

    if(props.accType.code == 'account'){

        if(filterType.code == 'all'){
            var label = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).map(a => a.name);
            var values = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).map(a => a.ratio);
            var dataValues = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio));
        }else if(filterType.code == 'poor'){
            var label = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter1).map(a => a.name);
            var values = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter1).map(a => a.ratio);
            var dataValues = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter1);
        }else if(filterType.code == 'better'){
            var label = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter2).map(a => a.name);
            var values = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter2).map(a => a.ratio);
            var dataValues = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter2);
        }else if(filterType.code == 'good'){
            var label = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter3).map(a => a.name);
            var values = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter3).map(a => a.ratio);
            var dataValues = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter3);
        }else if(filterType.code == 'very_good'){
            var label = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter4).map(a => a.name);
            var values = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter4).map(a => a.ratio);
            var dataValues = arr?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter4);
        }

    }else if(props.accType.code == 'subscription'){

        if(filterType.code == 'all'){
            var label = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).map(a=>a.name);
            var values = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).map(a=>a.ratio);
            var dataValues = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio));
        }else if(filterType.code == 'poor'){
            var label = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter1).map(a => a.name);
            var values = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter1).map(a => a.ratio);
            var dataValues = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter1);
        }else if(filterType.code == 'better'){
            var label = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter2).map(a => a.name);
            var values = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter2).map(a => a.ratio);
            var dataValues = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter2);
        }else if(filterType.code == 'good'){
            var label = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter3).map(a => a.name);
            var values = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter3).map(a => a.ratio);
            var dataValues = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter3);
        }else if(filterType.code == 'very_good'){
            var label = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter4).map(a => a.name);
            var values = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter4).map(a => a.ratio);
            var dataValues = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(checkFilter4);
        }

    }else if(props.accType.code == 'facility'){

        if(filterType.code == 'all'){
            var label = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null}).map(a=>a.name);
            var values = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null}).map(a=>a.ratio);
            var dataValues = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null});
        }else if(filterType.code == 'poor'){
            var label = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null}).filter(checkFilter1).map(a=>a.name);
            var values = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null}).filter(checkFilter1).map(a=>a.ratio);
            var dataValues = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null}).filter(checkFilter1);
        }else if(filterType.code == 'better'){
            var label = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null}).filter(checkFilter2).map(a=>a.name);
            var values = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null}).filter(checkFilter2).map(a=>a.ratio);
            var dataValues = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null}).filter(checkFilter2);
        }else if(filterType.code == 'good'){
            var label = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null}).filter(checkFilter3).map(a=>a.name);
            var values = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null}).filter(checkFilter3).map(a=>a.ratio);
            var dataValues = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null}).filter(checkFilter3);
        }else if(filterType.code == 'very_good'){
            var label = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null}).filter(checkFilter4).map(a=>a.name);
            var values = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null}).filter(checkFilter4).map(a=>a.ratio);
            var dataValues = arr1?.sort((a, b) => Number(b.ratio) - Number(a.ratio)).filter(e => {return e.name != null}).filter(checkFilter4);
        }

    }

    const filterTypes = [
        { name: 'All', code:'all'  },
        { name: 'Poor (<50)', code:'poor' },
        { name: 'Better (50 - 150)', code:'better' },
        { name: 'Good (150 - 250)', code:'good' },
        { name: 'Very Good (>250)', code:'very_good' }
    ];

    const onChangeFilterType = (e) =>{
        setFilterType(e.value)

        if(e.value.code == 'poor'){
            setMax(50)
        }else if(e.value.code == 'better'){
            setMin(50)
            setMax(150)
        }else if(e.value.code == 'good'){
            setMin(150)
            setMax(250)
        }else if(e.value.code == 'very_good'){
            setMin(250)
        }
    }

    const barData = {
        // labels: ['Topworth Pipes & Tubes Pvt Ltd', 'NMDC data center Pvt ltd', 'Tata Motors(Rudra Motors)', 'SKECHERS South Asia India Pvt Ltd.', 'Grauer & Weil (India) Limited', 'Devki Motors LLP', 'Ador Digatron Pvt. Ltd'],
        labels: label,
        datasets: [
            {
                label: 'Ratio in %',
                backgroundColor: '#00544D',
                borderColor: 'rgb(255, 99, 132)',
                data: values,
                borderRadius: 5
            }
        ]
    };

    const barOptions = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                },
                title: {
                    display: true,
                    text: 'Accounts'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                },
                title: {
                    display: true,
                    text: 'Ratio'
                }
            }
        }
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const fieldTemplate = (rowData, props) => (<><span className="p-column-title">{props.header}</span>{rowData[props.field]}</>)

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <span>{props.accType.case}-Wise Target Performance Details</span>
                    <div className='p-col-sm-4'>
                        <div className="p-inputgroup">
                            <Button className='custom-group-btn' label="Filter Type" />
                            <Dropdown value={filterType} onChange={onChangeFilterType} options={filterTypes} optionLabel="name" placeholder="Select Filter Type" filter className="p-m-0 multiselect-custom" />
                            <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-danger p-ml-4 p-mt-2" onClick={exportCSV} />
                        </div>
                    </div>
                </div>
                <div className="graph p-mt-5">
                    <Chart type="bar" data={barData} options={barOptions} />
                </div>
            </div>

            <DataTable style={{display:'none'}} ref={dt} value={dataValues} dataKey="id" exportFilename={ `${props.accType.case}-Wise Target Performance Details`}  className="datatable-responsive" emptyMessage="No data found.">
                <Column field="name" header="Name"  body={fieldTemplate}></Column>
                <Column field="ratio" header="Ratio" body={fieldTemplate}></Column>
            </DataTable>
        </>
    )
}