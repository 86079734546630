import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SplitButton } from "primereact/splitbutton";
import { Checkbox } from "primereact/checkbox";
import { InputSwitch } from "primereact/inputswitch";
import { Toast } from "primereact/toast";
// Component
import { AddLead } from "../Users/AddLead";
import { AddFacility } from "../Users/AddFacility";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

export const OnBoardMachineDialog = (props) =>{

    
    let paidBeverageSetting = {
        rfid: false,
        nfc: false,
        fingerprint: false,
        qr: false,
        onlinePayment: false,
        all: false
    }

    let supermans = {
        accountmanager: "",
        marketmanager: "",
    }

    const [onBoardDialog,setOnBoardDialog] = useState(false)
    const [subcatItem, setSubcatItem] = useState({ name: 'Commercial', code: 'Commercial' });
    const [machine,setMachine] = useState(null)
    const [demoDialog,setDemoDialog] = useState(false)
    const [commercialDialog,setCommercialDialog] = useState(true)
    
    const [onboardTemp,setOnboardTemp] = useState(null)
    const [demoPurposeItem,setDemoPurposeItem] = useState(null)
    const [onboardPkgTem, setOnboardPkgTemp] = useState(null)
    const [selectedCustomer,setSelectedCustomer] = useState(null)
    const [account,setAccount] = useState(null)
    const [accounts,setAccounts] = useState(null)
    const [leads,setLeads] = useState(null)
    const [allLeads,setAllLeads] = useState(null)
    const [selectedAccount,setSelectedAccount] = useState(null)
    const [selectedLeads,setSelectedLeads] = useState(null)
    const [company, setCompany] = useState(null);
    const [ownerShipDetails, setOwnerShipDetails] = useState(null);
    const [selectedAcc, setSelectedAcc] = useState(null);
    const [selectedLds, setSelectedLds] = useState(null);
    
    const [dispensingItem, setDispensingItem] = useState(null);
    const [facility, setFacility] = useState([{facname:'none'}]);
    const [facilityItem, setFacilityItem] = useState(null);
    const [paidOptions, setPaidOptions] = useState(paidBeverageSetting);
    const [onlineRfidStatus, setOnlineRfidStatus] = useState(false);
    const [paymentValue, setPaymentValue ] = useState([{ name: 'Default', code:'default' }])
    const [paytm, setPaytm] = useState(null);
    const [selectedrechargeOp, setSelectedrechargeOp] = useState(null);
    const [prizing, setPrizing] = useState(null);
    const [packageItem, setPackageItem] = useState(null);  
    const [maintainenceItem, setMaintainenceItem] = useState(null);
    const [mainTermItem, setMainTermItem] = useState(null);
    const [depositeType, setDepositeType] = useState(null);
    const [billingPattern, setBillingPattern] = useState(null);
    const [invoiceCycle, setInvoiceCycle] = useState(null);

    const [selectedMuser, setSelectedMuser] = useState(null);
    const [keyAccManagers,setKeyAccManagers] = useState(null);
    const [onboardSupTem, setOnboardSupTemp] = useState(supermans);
    const [marketingUsers,setMarketingUsers] = useState(null);
    const [selectedKAM, setSelectedKAM] = useState(null);
    const [taprimans,setTaprimans] = useState(null);
    const [selectedTapriman, setSelectedTapriman] = useState(null);
    const [userReduced, setUserReduced] = useState(null);
    const [capsuleMachines, setCapsuleMachines] = useState(null);
    const [facAddTemplate, setFacAddTemplate] = useState(null);
    const [fgMachines, setFgMachines] = useState([]);
    const [leadDialog, setLeadDialog] = useState(false);
    const [facilityDialog, setFacilityDialog] = useState(false);
    const [editPaymode, setEditPaymode ] = useState(null)
    const [formAction, setFormAction] = useState(false)
    
    const toast = useRef(null);
    
    // Temp
    const [editMachineData, setEditMachineData] = useState(null)
    

    

    const subCategoryItems = [
        { name: 'Demo', code: 'Demo' },
        { name: 'Commercial', code: 'Commercial' }
    ]

    const demoPurposeDropdownItems = [
        { name: 'General Lead', code : 'generalLead' },
        { name: 'Invester Pitch', code : 'investerPitch' },
        { name: 'Special event', code : 'specialEvent' }
    ]

    const customerTypeOptions = [
        { name: 'Account'},
        { name: 'Lead' }
    ]

    const despensingOptionDropdownList = [
        { name: 'Free', code:'free' },
        { name: 'Paid', code:'paid'}
    ]

    const cupRechargeDropdownList = [
        { name: 'Postpaid', code:'postpaid' },
        { name: 'Prepaid', code:'prepaid'}
    ]

    const packageDropdownList = [
        { name:"Maitenance", code:"maintenance"},
        { name:"Deposite", code:"deposit"},
        { name:"Both", code:"maintenance/deposit"},
        { name:"None", code:"none"}
    ]

    
    const maintenancePackages = [
        { name: 'Monthly',code:'monthly'},
        { name: 'Quaterly',code:'quaterly'},
        { name: 'HalfYearly',code:'halfyearly'},
        { name: 'Yearly',code:'yearly'}
    ]

    const maintainenceterms = [
        { name: 'Postpaid', code:'postpaid'},
        { name: 'Prepaid', code:'prepaid'}
    ]

    const typeOfDeposite = [
        { name: 'Refundable', code:'refundable'},
        { name: 'Non Refundable', code:'nonrefundable'}
    ]

    const billingPatternOptions = [
        { name: 'Pre Consumption', code:'preconsumption' },
        { name: 'Post Consumption', code:'postconsumption' }
    ]

    const invoiceCycleOptions = [
        { name: 'Every Month End', code:'month_end' },
        { name: 'Monthly (30 Days)', code:'monthly' },
        { name: 'Weekly (7 Days)', code:'weekly' }
    ]



    useEffect(()=>{
        setOnBoardDialog(props.onBoardDialog)
    },[props.onBoardDialog])

    useEffect(()=>{
        getMachines()
        getUser()
        getLeads()
    },[])

    useEffect(()=>{
        setFgMachines(props.fgMachines)
        console.log(props.fgMachines);
    },[props.fgMachines])


    useEffect(()=>{
        
        if(props.selectedMachineData){

            if(props.machineType == 'Capsule'){
                editmachine(props.selectedMachineData)
                setFormAction(true)
            }else if(props.machineType == 'orderMachine'){
                editOrderMachine(props.selectedMachineData)
                setFormAction(true)
            }else{
                editmachine(props.selectedMachineData)
                setFormAction(true)
            }

        }else{
            setEditMachineData(null)
            setFormAction(false)
        }

    },[props.selectedMachineData])
    

    const items = [
        {
            label: 'Organized',
            command: async() => {
                setLeadDialog(true)
            }
        },
        // {
        //     label: 'UnOrganized',
        //     command: () => {alert("hell0 o")}
        // }
    ];

    const success = (msg) =>{
        toast.current.show({ severity: 'success', summary: 'Successful', detail:`${msg}`, life: 3000 });
    } 

    const failure = (msg) => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail:`${msg}`, life: 3000 });
    }


    const editmachine = async(machine) =>{

        setEditMachineData(null)
        setFormAction(true)
        console.log(machine.mchmode?.paymode);
        setEditPaymode(machine.mchmode?.paymode)
        const cust = userReduced?.filter(val => val.userid == machine.customer)
        console.log(cust);
        const facRes = await apiServices.getFacility({userid: cust[0]?.userid});
        setFacility([{facname:'None'},...facRes.data.data])

        setEditMachineData({...machine,acc_name:cust[0]?.name})
        // console.log(machine);
        machine?.subscription?.package?.site == "permanent"?setSubcatItem({ name: 'Commercial', code: 'Commercial' }):setSubcatItem({ name: 'Demo', code: 'Demo' })

        const response = await apiServices.getUsersByType({"d_id": ["self"],"userType":"marketmanager"});
        var mark_user =  response?.data?.data.map((x) => {return {...x,fullName: x.name + ' '  + x.lastName}})

        const selectedMa = mark_user.filter((v)=>{return v.userid == machine?.superman?.marketmanager})
        setMarketingUsers(mark_user)
        setSelectedMuser(selectedMa[0])

        const response1 = await apiServices.getUsersByType({marketmanager: mark_user[0].userid,userType: "accountmanager"});
        setKeyAccManagers(response1.data.data)
        const selectedMa1 = response1.data.data.filter((v)=>{return v.userid == machine?.superman?.accountmanager})
        setSelectedKAM(selectedMa1[0])

        const response2 = await apiServices.getUsersByType({accountmanager: selectedMa1[0]?.userid,userType: "superman"});
        const tapri =  response2?.data?.data.map((x) => {return {...x,fullName: x.name + ' '  + x.lastName}});
        setTaprimans(tapri)
        const selectedTap = tapri.filter((v)=>{return v.userid == machine?.superman?.superman?.userid})
        console.log(selectedTap);

        setSelectedTapriman(selectedTap[0])

        machine?.mchmode?.paymode?.free?setDispensingItem({ name: 'Free', code:'free' }):setDispensingItem({ name: 'Paid', code:'paid'})
        machine?.subscription?.package?.paymentterms == 'postpaid'?setSelectedrechargeOp({ name: 'Postpaid', code:'postpaid'}):setSelectedrechargeOp({ name: 'Prepaid', code:'prepaid'})
        const pkg = packageDropdownList.filter((m)=>{return m.code == machine?.subscription?.package?.rentaloption })
        setPackageItem(pkg[0])
        const deposit = typeOfDeposite.filter((m)=>{return m.code == machine?.subscription?.package?.deposit })
        setDepositeType(deposit[0])
        const billingPattern = billingPatternOptions.filter((m)=>{return m.code == machine?.subscription?.package?.billing_pattern })
        setBillingPattern(billingPattern[0])

        const response3 = await apiServices.getModelPricing({model: machine.model,type: machine.type});
        console.log(response3);
        setPrizing(response3?.data?.data)
    }


    const editOrderMachine = async(data) =>{

        console.log(data);
        setEditMachineData(data)
        data.subscriptionType == "permanent"?setSubcatItem({ name: 'Commercial', code: 'Commercial' }):setSubcatItem({ name: 'Demo', code: 'Demo' })

        setEditMachineData({...editMachineData,siteName:data.subscriptionName})
        
        if(data?.leadData?.length > 0){
            setSelectedCustomer({ name: 'Lead' })
            setLeads(true)
            const leadData = allLeads.filter((x)=>{return x.userid == data?.leadData[0]?.userid})
            setSelectedLeads(leadData ? leadData[0] : null)
        } 
        
        if(data?.userData?.length > 0){

            setSelectedCustomer({ name: 'Account'})
            setAccount(true)
            const id = data.userData[0].userid
            const response1 = await apiServices.getUserReduced();
            const acco = response1.data.data.filter(val => val.userType == 'customer');
            setAccounts(acco)
            const acc = acco.filter((x)=>{return id == x.userid})  
            setSelectedAccount(acc ? acc[0] : null)
            console.log(acc[0]);


            console.log(data);

            // let arr = []
            // for (let i = 0; i < acco.length; i++) {            
                // let temp = data.userData[i].userid
                // let temp = data.filter((value) => { return value.customer == acco[i].userid });
                // console.log(temp);
            //     let index = temp.findIndex((value) => { return value.subscription.package.site == "permanent" })
            //     if (index < 0 && temp.length > 0){
            //         let customer = {...accounts[i], value: accounts[i].userid, label: accounts[i].name + "(Demo)" };
            //         arr.push(customer)
            //     }else if (index >= 0){
            //         let customer = {...accounts[i], value: accounts[i].userid, label: accounts[i].name };
            //         arr.push(customer)
            //     }
            // }
            // const userData = 

        }

        
        // 

        


        // userType

        // setSelectedCustomer
        // editMachineData
        // 

    }


    const getMachines = async() =>{

        if(props.machineType != 'orderMachine'){
            
            const response = await apiServices.findCapsuleMachines()
            const data = response?.data?.data;
            const onBoardmachines = data?.filter((m)=>{ return  m.datastore.stage == "finishedgoods" && m.subscription && (m.subscription.package.site == "demo" || m.subscription?.package.site == "permanent") && m.siteName != ""  })
            const finishedGoodMachines = data?.filter((m)=>{ return  m.datastore.stage == "finishedgoods" &&  m.siteName == "" })
            setFgMachines(finishedGoodMachines)
            setCapsuleMachines(onBoardmachines)
            const response1 = await apiServices.getUserReduced();
            setUserReduced(response1?.data?.data)
        }
    }
    
    const getUser = async() =>{
        const response = await apiServices.getUsersByType({"d_id": ["self"],"userType":"marketmanager"});
        const mark_user =  response?.data?.data.map((x) => {return {...x,fullName: x.name + ' '  + x.lastName}});
        setMarketingUsers(mark_user);
    }

    const getLeads = async() =>{
        const response = await apiServices.getCustomerData1();
        setAllLeads(response?.data?.data)
    }

    const hideDialog = () =>{
        props.setOnBoardDialog(false)
        setOnBoardDialog(false)
    }


    const catergorySelection = (e) =>{
        setSubcatItem(e.value);
        setMachine(null)
        setDemoDialog(!demoDialog);
        setCommercialDialog(!commercialDialog);
    }

    const onBoardMain = (e) =>{
        let oneTemplate = {...onboardTemp,[e.target.name] : e.target.value}
        setOnboardTemp(oneTemplate) 
    } 

    const demoChange = (e) =>{
        setOnboardPkgTemp({...onboardPkgTem,[e.target.name]:e.target.value})
    }
    
    const demoPurposeChange = (e) =>{
        setDemoPurposeItem(e.value)
        setOnboardPkgTemp({...onboardPkgTem,[e.target.name]:e.target.value.code})
    }

    const selectMachine = async(e) =>{
        setMachine(e.value)
    }

    const changeCustomer = async(e) =>{
        setSelectedCustomer(e.value)

        if(e.value.name == 'Account'){
            setAccount(true)
            setLeads(false)
            console.log(userReduced);
            console.log(capsuleMachines)
            const accounts = userReduced.filter(val => val.userType == 'customer');
            let arr = []
            for (let i = 0; i < accounts.length; i++) {            
                let temp = capsuleMachines.filter((value) => { return value.customer == accounts[i].userid });
                let index = temp.findIndex((value) => { return value.subscription.package.site == "permanent" })
                if (index < 0 && temp.length > 0){
                    let customer = {...accounts[i], value: accounts[i].userid, label: accounts[i].name + "(Demo)" };
                    arr.push(customer)
                }else if (index >= 0){
                    let customer = {...accounts[i], value: accounts[i].userid, label: accounts[i].name };
                    arr.push(customer)
                }
            }

            setAccounts(arr)
            setOnboardTemp({...onboardTemp,lead:false}) 

        }else if(e.value.name == 'Lead'){
            getLeads()
            setLeads(true)
            setAccount(false)
            setOnboardTemp({...onboardTemp,lead:true}) 
        }
    }

    const getFac = async(data) =>{
        const response = await apiServices.getFacility({userid: data});
        setFacility([{facname:'None'},...response.data.data])
    }

    const changeAccountD = async(e) =>{
        let temp = accounts.filter((value) => { return value.userid == e.value });

        console.log(temp[0]?.userid);
        setSelectedAccount(e.value)
        setSelectedAcc(true)
        setCompany(temp[0])
        getFac(e.value)
        setOnboardTemp({...onboardTemp,customer:temp[0]?.userid})
        const response1 = await apiServices.getPaytmAcc({account: temp[0]?.userid});
        setPaymentValue([...paymentValue,...response1.data.data])
        // Ownership 
        const response2 = await apiServices.getuserById({userid:temp[0]?.userid})
        // console.log(response2.data.data);
        setOwnerShipDetails(response2.data?.data[0])
    }

    const changeCom = (e) =>{
        let oneTemplate = {...onboardTemp,billing_details:{[e.target.name] : e.target.value}}
        setOnboardTemp(oneTemplate) 
    }

    const changeLead = (e) =>{
        setSelectedLeads(e.value);
        setSelectedLds(true);
        setOnboardTemp({...onboardTemp,customer:e.value?.userid})
    }
 
    const changeAddFac = (e) =>{

        const data = e.value;
        console.log(data);
        setFacilityItem(data)

        if(data.facname == 'none'){
                const facility = [{address: {}, billing_default: {}, billing_details: {}, admin_default: {}, admins: []}]
                setFacAddTemplate(facility)
        }else{

            const facility = [{
                facname: data.facname,
                address: {
                address: data.address.address,
                country: typeof data.address.country == 'string' ? JSON.parse(data.address.country) : data.address.country,
                state: typeof data.address.state == 'string' ? JSON.parse(data.address.state) : data.address.state,
                city: typeof data.address.city == 'string' ? JSON.parse(data.address.city) : data.address.city
                },

                billing_default: data.billing_default,
                billing_details: {
                locality: data.billing_details.locality,
                gstnumber: data.billing_details.gstNumber,
                cmpname: data.billing_details.company,
                addr: data.billing_details.addr,
                country: typeof data.billing_details.country == 'string' ? JSON.parse(data.billing_details.country) : data.billing_details.country,
                state: typeof data.billing_details.state == 'string' ? JSON.parse(data.billing_details.state) : data.billing_details.state,
                city: typeof data.billing_details.city == 'string' ? JSON.parse(data.billing_details.city) : data.billing_details.city
                },
                admin_default: data.admin_default,
                admins: data.admins
            }]

            setFacAddTemplate(facility)

        }
    }


    const handlePaid = (e) =>{
        let paidTemplate = {...paidOptions,[e.target.name] : e.target.checked,free:false,online:true}
        setPaidOptions(paidTemplate)
        if(e.target.name == 'all'){
            let paidTemplate = {...paidOptions,rfid: e.target.checked,nfc: false,fingerprint: e.target.checked,qr: e.target.checked,onlinePayment: e.target.checked,all:e.target.checked}
            setPaidOptions(paidTemplate)
        }
    }

    const changeCup = (e) =>{
        setOnboardPkgTemp({...onboardPkgTem,fullcup:e.target.value,halfcup:e.target.value})
    }

    const changeMarketer = async(e) =>{
        setSelectedMuser(e.value)
        const response = await apiServices.getUsersByType({marketmanager: e.value.userid,userType: "accountmanager"});
        setKeyAccManagers(response.data.data)
        setOnboardSupTemp({...onboardSupTem,marketmanager:e.value.userid})
    }

    const changeKeyAM = async(e) =>{
        console.log(e.value);
        setSelectedKAM(e.value)
        const response = await apiServices.getUsersByType({accountmanager: e.value.userid,userType: "superman"});
        const tapri =  response?.data?.data.map((x) => {return {...x,fullName: x.name + ' '  + x.lastName}});
        console.log(tapri);
        setTaprimans(tapri)
        setOnboardSupTemp({...onboardSupTem,accountmanager:e.value.userid}) 
    }

    const changeTapri = async(e) =>{
        setSelectedTapriman(e.value)
        setOnboardSupTemp({...onboardSupTem,superman:{
            name: e.value.name,
            cno: e.value.cno,
            userid: e.value.userid
        }})
    }

    const handleDespensing = (e) =>{
        setDispensingItem(e.value)
        setPaidOptions({...paidBeverageSetting,free:true})
    }

    const handleOnlineRfid = (e) =>{
        setOnlineRfidStatus(e.value)
        console.log(e.target.name);
        console.log(e.value);
        let paidTemplate = {...paidOptions,[e.target.name] : e.value}
        setPaidOptions(paidTemplate)
    }

    const setRechargeOption = (e) =>{
        setSelectedrechargeOp(e.value)
        setOnboardPkgTemp({...onboardPkgTem,paymentterms:e.value.code})
    }

    const cvesChangeCup = (e) =>{
        setOnboardPkgTemp({...onboardPkgTem,ctvmfullcup:e.target.value,ctvmhalfcup:e.target.value})
    }

    const targetCups = (e) =>{
        setOnboardPkgTemp({...onboardPkgTem,target_cups:Number(e.target.value)})
    }

    const setPackage = (e) =>{
        console.log(e.value);
        setPackageItem(e.value)
        setOnboardPkgTemp({...onboardPkgTem,rentaloption:e.value.code})
    }


    const setMantainance = (e) =>{

        setMaintainenceItem(e.value)
        
        if(e.target.value.code == 'monthly'){
            setOnboardPkgTemp({...onboardPkgTem,maintenance:{[e.target.name] : e.target.value.code,duration: 30}})
        }else if(e.target.value.code == 'quaterly'){
            setOnboardPkgTemp({...onboardPkgTem,maintenance:{[e.target.name] : e.target.value.code,duration: 90}})
        }else if(e.target.value.code == 'halfyearly'){
            setOnboardPkgTemp({...onboardPkgTem,maintenance:{[e.target.name] : e.target.value.code,duration: 180}})
        }else if(e.target.value.code == 'yearly'){  
            setOnboardPkgTemp({...onboardPkgTem,maintenance:{[e.target.name] : e.target.value.code,duration: 360}})        
        }        
    }

    const maintCost = (e) =>{
        setOnboardPkgTemp({...onboardPkgTem,maintenance:{...onboardPkgTem.maintenance,[e.target.name] : e.target.value}})
    }

    const setMainTermItems = (e) =>{
        console.log(onboardPkgTem);
        setMainTermItem(e.value)
        setOnboardPkgTemp({...onboardPkgTem,maintenance:{...onboardPkgTem.maintenance,[e.target.name] : e.target.value.code}})
    }

    const changeDeposit = (e) =>{
        setDepositeType(e.value)
        setOnboardPkgTemp({...onboardPkgTem,deposit:e.value.code})
    }

    const setFrontDeposit = (e) =>{
        // console.log(e.target.value);
        setOnboardPkgTemp({...onboardPkgTem,[e.target.name]:e.target.value})
    }

    const setBillingPatternFn = (e) =>{
        console.log(e.value.code);
        setBillingPattern(e.value)
        setOnboardPkgTemp({...onboardPkgTem,billing_pattern:e.value.code})
    }

    const setInvoiceCycleFn = (e) =>{
        // console.log(e.value)
        setInvoiceCycle(e.value)
        setOnboardPkgTemp({...onboardPkgTem,invoiceCycle:e.value.code})
    }

    const setExtPeriod = (e) =>{
        setOnboardPkgTemp({...onboardPkgTem,[e.target.name]:parseInt(e.target.value)})
    }

    const openFacility = async() =>{
        setFacilityDialog(true)
        // const response = await apiServices.getCountry();
        // console.log(response.data);
        // setCountry(response.data)
    }
  


    const onboardSubmit = async(e) =>{
        e.preventDefault();

        if(formAction){
            alert('Update is Pending because of testing issues')
        }else{

            const main = {...onboardTemp,machine:machine?.id,d_id:["self"]};
            const facilitydata = facilityItem?.facname == 'None' ? {facname: 'none'} : {facid: facilityItem?.facid,facname: facilityItem?.facname};
            const mchmode = {...machine?.mchmode,paymode:paidOptions}
            const data1 = {...main,facilitydata:facilitydata,mchmode:mchmode,package:onboardPkgTem,superman:onboardSupTem}
            const data2 = paytm ? {...data1,paytm:paytm} : {...data1}
            const data = selectedCustomer.name == 'Lead' ? {...data2,facility:facAddTemplate} : {...data2} 
            console.log(data);
            if(props.machineType == 'Capsule'){

                const response = await apiServices.newCapsuleMachineSite(data);
                response.data.success ? success('Capsule Added Successfully') : failure('Failed to Add')

            }else{

                const response = await apiServices.addNewSite(data);
                response.data.type =="success" ? success('CVES Added Successfully') : failure('Failed to Add')
            }

        }   

        setOnBoardDialog(false)
        getMachines();
    }




    const onboardDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger" onClick={hideDialog} />
            {
                (fgMachines?.length > 0) &&
                <Button label="Submit" type="submit" form='my-form' icon="pi pi-plus" className="p-button-success"/>
            }
        </>
    )

    return (

        <>

        <Toast ref={toast} />

        {/* Hello */}

        <Dialog visible={onBoardDialog} style={{ width: '850px' }} header={`${formAction ? 'Edit' : 'Add '} On-Board ${props.machineType == 'Capsule' ? 'Capsule' : 'CTVM'} Machine`} modal className="p-fluid" footer={onboardDialogFooter} onHide={hideDialog} maximizable={true} blockScroll={true}>  
            
            {
                (fgMachines?.length > 0) ?
         
                <form onSubmit={onboardSubmit} id="my-form" className="p-fluid">
                <h5 className='p-mt-2'>SUBSCRIPTION DETAILS</h5>

                <div className="p-field">
                <label htmlFor="subscription-category">Subscription Category</label>
                <Dropdown id="subscription-category" name='site' value={subcatItem} onChange={catergorySelection} autoFocus options={subCategoryItems} optionLabel="name" placeholder="Select Subscription Category" disabled={formAction} ></Dropdown>
                </div>

                {
                demoDialog && 
                <>
                <div className='p-grid'>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="demo-site">Demo Site</label>
                            <InputText id="demo-site" name='siteName' placeholder='Demo site' defaultValue={formAction?editMachineData?.siteName:''} onChange={onBoardMain}  />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="demo-site-location">Demo Site Location</label>
                            <InputText id="demo-site-location" name='demositelocation' onChange={demoChange} placeholder='Demo Site Location'  />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="demo-purpose">Demo Purpose</label>
                            <Dropdown id="demo-purpose" name='demopurpose' value={demoPurposeItem} onChange={demoPurposeChange} options={demoPurposeDropdownItems} optionLabel="name" placeholder="Select Demo Purpose"></Dropdown>
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="demo-duration">Demo Duration(No of Days)</label>
                            <InputText id="demo-duration" name='demoduration' onChange={demoChange} placeholder='Demo Duration' type='number' min={1}  />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="demo-consumption">Cup Consumption Limit(per day)</label>
                            <InputText id="demo-consumption" name='expcupconsumption' onChange={demoChange} placeholder='Cup Consumption Limit' type='number' min={0}  />
                        </div>
                    </div>

                </div>
                </>   
                }

                {
                commercialDialog &&   

                <div className="p-field">
                    <label htmlFor="sub-name">Subscription Name</label>
                    <InputText id="sub-name" name='siteName' defaultValue={formAction? editMachineData?.siteName:''} onChange={onBoardMain} placeholder='Subscription Name' tooltip="Subscription Name"/>
                </div>

                }

                {
                (demoDialog || commercialDialog) && 
                <>

                    <h5 className='p-mt-2'>{props.machineType == 'Capsule' ? 'CAPSULE' : 'CVES'} DETAILS</h5>

                        {
                            (props.machineType == 'Capsule' && editMachineData == null) &&
                            
                            <div className="p-field">
                                <label htmlFor="CVES-id">Select Capsule </label>
                                <Dropdown className="p-m-0 multiselect-custom" id="CVES-id" value={machine} onChange={selectMachine} options={fgMachines} optionLabel="id" placeholder="Select Capsule" disabled={formAction} filter required></Dropdown>
                            </div>
                        }

                        {
                            (props.machineType == 'Capsule' && editMachineData != null) &&
                            
                            <div className="p-field">
                                <label htmlFor="CVES-id">Select Capsule </label>
                                <InputText id="CVES-name" defaultValue={editMachineData?.id} placeholder='Capsule ID' disabled={formAction}  />
                            </div>
                        }




                        {
                            (!formAction && props.machineType != 'Capsule') &&
                            <div className="p-field">
                                <label htmlFor="CVES-id">Select CVES </label>
                                <Dropdown className="p-m-0 multiselect-custom" id="CVES-id" value={machine} onChange={selectMachine} options={fgMachines} optionLabel="id" placeholder="Select CVES" disabled={formAction} filter required></Dropdown>
                            </div>
                        }

                        {
                            (formAction && props.machineType != 'Capsule') &&
                            <div className="p-field">
                                <label htmlFor="CVES-name">CVES ID</label>
                                <InputText id="CVES-name" defaultValue={formAction?editMachineData?.id:''} placeholder='CVES ID' disabled={formAction}  />
                            </div>
                        }
                    
                        <h5 className='p-mt-2'>SERVICE TEAM DETAILS</h5>

                        <div className='p-grid'>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="sm-id">Sales and Marketing Manager</label>
                                <Dropdown id="sm-id" optionLabel="fullName" value={selectedMuser} onChange={changeMarketer} options={marketingUsers} placeholder="Select Sales and Marketing Manager" disabled={formAction} filter></Dropdown>
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="km-id">Key Account Manager</label>
                                <Dropdown id="km-id"  optionLabel="name" value={selectedKAM} onChange={changeKeyAM} options={keyAccManagers} placeholder="Select Key Account Manager" disabled={formAction} filter></Dropdown>
                            </div>
                        </div>

                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="tapariman-id">Tapariman</label>
                                <Dropdown id="tapariman-id" optionLabel="fullName" value={selectedTapriman} onChange={changeTapri} options={taprimans} placeholder="Select Tapariman" disabled={formAction} filter></Dropdown>
                            </div>
                        </div>

                        </div>

                        <h5 className='p-mt-2'>ACCOUNT DETAILS</h5>

                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <div className="p-field">
                                    <label htmlFor="ct-id">Customer Type</label>
                                    <Dropdown id="ct-id" value={selectedCustomer} onChange={changeCustomer} options={customerTypeOptions} optionLabel="name" placeholder="Select Customer Type"></Dropdown>
                                </div>
                            </div>
                        </div>


                        {
                            account &&
                            <>
                            <div className="p-field">
                            <label htmlFor="acc-id">Account</label>
                            <Dropdown id="acc-id" value={selectedAccount} onChange={changeAccountD} options={accounts} optionLabel="label" placeholder="Select Account" disabled={formAction} filter></Dropdown>
                            </div>
                            
                            <div className="p-field">
                                <label htmlFor="company-name">Comapny Name</label>
                                <InputText id="company-name" value={company?company.billing_details?.company:''} placeholder='Company Name' disabled  />
                            </div>

                            {
                                selectedAccount != null &&
                                <div className="p-field">
                                    <label htmlFor="cves-own">CVES Ownership</label>
                                    <InputText id="cves-own" value={ownerShipDetails?.distributor_data ? 'Distributor' : 'CIPL'} placeholder='CVES Ownership' disabled  />
                                </div>
                            }
                            
                            {
                                (selectedAccount != null && ownerShipDetails?.distributor_data) &&
                                <>
                                    <div className='p-grid'>

                                    <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="dist-name">Distributor's Name</label>
                                        <InputText id="dist-name" value={ownerShipDetails?.distributor_data?.name} placeholder='Distributors Name' disabled  />
                                    </div>
                                    </div>
                                    <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="dist-type">Distributor's Type</label>
                                        <InputText id="dist-type" value={ownerShipDetails?.distributor_data.userRole} placeholder='Distributors Type' disabled  />
                                    </div>
                                    </div>
                                    </div>
                                </>
                            }


                    

                            </>
                        }


                        {
                            leads &&                                
                            <>
                            <div className='p-grid'>
                                <div className='p-col-12'>
                                    <div className="p-field">
                                        <label htmlFor="company-name">Comapny Name</label>
                                        <InputText id="company-name"  placeholder='Company Name' name='company' onChange={changeCom} />
                                    </div>
                                </div>
                                <div className='p-col-9'>
                                    <div className="p-field">
                                        <label htmlFor="lead-id">Leads</label>
                                        <Dropdown id="lead-id" value={selectedLeads} onChange={changeLead} options={allLeads} optionLabel="name" placeholder="Select Lead" filter></Dropdown>
                                    </div>
                                </div>
                                <div className='p-col-3'>
                                    <div className="p-field">
                                        <label htmlFor="add-lead"></label>
                                        <SplitButton label="Add Lead"  icon="pi pi-user-plus" model={items} className="p-button-outlined  p-mt-3 p-mb-2"></SplitButton>
                                    </div>
                                </div>
                            </div>
                            </>
                        }
                        
                        <div className='p-grid'>
                        <div className='p-col-9'>
                        <div className="p-field">
                            <label htmlFor="facility-id">Facility Name</label>
                            <Dropdown id="facility-id"  value={facilityItem}  options={facility} onChange={changeAddFac} optionLabel="facname" placeholder="Select Facility"></Dropdown>
                        </div>
                        </div>
                        <div className='p-col-3'>
                        <div className="p-field">
                            <label htmlFor="facility-id"></label>
                            {
                                <Button type="button" id='facility-id' label="Add Facility" icon="pi pi-user-plus" disabled={!((account && selectedAcc) || (leads && selectedLds))} className=" p-button p-mr-2 p-mt-3" onClick={openFacility} />
                            }
                        </div>
                        </div>
                        </div>

                        <div className="p-field">
                        <label htmlFor="do-id">Dispensing Option</label>
                        <Dropdown id="do-id" value={dispensingItem} onChange={handleDespensing} options={despensingOptionDropdownList} optionLabel="name" placeholder="Select Despensing Option"></Dropdown>
                        </div>

                        {
                            dispensingItem?.code == 'paid' &&
                            <div className="p-col-12">
                            <div className="p-field">
                            <label htmlFor="bev-set">Beverage Setting</label>
                                <div className='p-grid'>
                                <div className="p-col-12 p-md-2">
                                        <div className="p-field-checkbox">
                                            <Checkbox name="rfid" checked={paidOptions.rfid} onChange={handlePaid} />
                                            <label>RFID</label>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <div className="p-field-checkbox">
                                            <Checkbox name="nfc" checked={paidOptions.nfc} onChange={handlePaid} />
                                            <label>NFC</label>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <div className="p-field-checkbox">
                                            <Checkbox name="fingerprint" checked={paidOptions.fingerprint} onChange={handlePaid} />
                                            <label>Fingerprint</label>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <div className="p-field-checkbox">
                                            <Checkbox name="qr" checked={paidOptions.qr} onChange={handlePaid} />
                                            <label>QR</label>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <div className="p-field-checkbox">
                                            <Checkbox name="onlinePayment" checked={paidOptions.onlinePayment} onChange={handlePaid} />
                                            <label>Online Payment</label>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <div className="p-field-checkbox">
                                            <Checkbox name="all" checked={paidOptions.all} onChange={handlePaid} />
                                            <label>All</label>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            (paidOptions?.rfid || paidOptions?.all) &&

                            <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="online-rfid">Online RFID</label><br/>
                                    <InputSwitch id='online-rfid' name='onlineRfid' checked={onlineRfidStatus} onChange={handleOnlineRfid} /> 
                                </div>
                            </div>
                        }

                        {
                            (paidOptions?.onlinePayment || paidOptions?.all) &&
                            <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="paytm">Paytm</label><br/>
                                    <Dropdown id="paytm" value={paytm} onChange={(e)=>{setPaytm(e.value)}} options={paymentValue} optionLabel="name" placeholder="Paytm"></Dropdown> 
                                </div>
                            </div>
                        }
                </>
                }

                {
                (demoDialog || commercialDialog) && 

                <>
                <h5 className='p-mt-5'>CUPAGE DETAILS</h5>
                <div className="p-field">
                    <label htmlFor="cup-recharge-id">Cup Recharge Option</label>
                    <Dropdown id="cup-recharge-id" value={selectedrechargeOp} onChange={setRechargeOption} options={cupRechargeDropdownList} optionLabel="name" placeholder="Cup Recharge Option"></Dropdown>
                </div>
                {
                    selectedrechargeOp?.name == 'Prepaid' &&
                    <div className="p-field">
                        <label htmlFor="cup-count">No of Cups</label>
                        <InputText id="cup-count" placeholder='Cup Count' defaultValue="" type="number" min="0"  />
                    </div>
                }

                <div className='p-grid'>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="cup-cost">Cup Cost (INR)</label>
                        <InputText id="cup-cost" placeholder='Cup Cost' onChange={changeCup} defaultValue={prizing?prizing?.cupprice?.pricehalf:''} type="number" min="0" step="0.1" />
                    </div>

                    </div>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="CVES-cup-cost">CVES Cup Cost (INR)</label>
                        <InputText id="CVES-cup-cost" placeholder='CVES Cup Cost (INR)' onChange={cvesChangeCup} step="0.1" defaultValue={formAction?editMachineData?.subscription?.package?.ctvmfullcup:''} type="number" min="0"  />
                    </div> 
                    </div>
                </div>

                </>
                }

                {
                commercialDialog && 

                <>
                <h5 className='p-mt-2'>ANALYTICS DETAILS</h5>
                <div className="p-field">
                    <label htmlFor="conssumption-target">Consumption Target (Cups)</label>
                    <InputText id="conssumption-target" placeholder='Consumption Target (Cups)' onChange={targetCups} defaultValue={formAction?editMachineData?.subscription?.package?.target_cups:''} type="number" min="0"  />
                </div>

                <div className='package-div'>
                    <h5 className='p-mt-2'>PACKAGE DETAILS</h5>
                    <div className="p-field">
                        <label htmlFor="tapri-package">Tapri Package</label>
                        <Dropdown id="tapri-package" value={packageItem} onChange={setPackage} options={packageDropdownList} optionLabel="name" placeholder="Select Tapri Package" disabled={formAction}></Dropdown>
                    </div>
                    {
                        (packageItem?.code == 'maintenance' || packageItem?.code == 'maintenance/deposit') &&
                        <>
                        <div className="p-field">
                            <label htmlFor="maintenence">Maitenance Plan</label>
                            <Dropdown id="maintenence" name='packageName' value={maintainenceItem} onChange={setMantainance} options={maintenancePackages} optionLabel="name" placeholder="Select Maintenance Plan"></Dropdown>
                            {/* prizing?.packages */}
                        </div>
                        <div className="p-field">
                            <label htmlFor="maintenence-term">Payment Maintenance Term</label>
                            <Dropdown id="maintenence-term" name='paymentterms' value={mainTermItem} onChange={setMainTermItems} options={maintainenceterms} optionLabel="name" placeholder="Select Maintenance Plan"></Dropdown>
                        </div>
                        <div className="p-field">
                            <label htmlFor="maintenance-amount">Maintenance Amount (INR)</label>
                            <InputText id="maintenance-amount" name='cost' onChange={maintCost} placeholder='Maintenance Amount (INR)' type="number" min="0"  />
                        </div>
                        </>
                    }

                    {
                        (packageItem?.code == 'deposit'  || packageItem?.code == 'maintenance/deposit') &&
                        <>
                        <div className="p-field">
                            <label htmlFor="type-of-dept">Type of Deposite</label>
                            <Dropdown id="type-of-dept" value={depositeType} onChange={changeDeposit} options={typeOfDeposite} optionLabel="name" placeholder="Select Deposite Plan"></Dropdown>
                        </div>
                        <div className="p-field">
                            <label htmlFor="upfront-dept">Upfront Deposite (INR)</label>
                            <InputText id="upfront-dept" placeholder='Upfront Deposite (INR)' name='depositamt' onChange={setFrontDeposit} type="number" min="0"  />
                        </div>
                        </>
                    }

                </div>
                </>
                }


                {
                (demoDialog || commercialDialog) && 

                <>
                <h5 className='p-mt-2'>BILLING DETAILS</h5>
                <div className="p-field">
                <label htmlFor="billing-pattern">Billing Pattern</label>
                <Dropdown id="billing-pattern" value={billingPattern} onChange={setBillingPatternFn} options={billingPatternOptions} optionLabel="name" placeholder="Select Billing Pattern" disabled={formAction}></Dropdown>
                </div>
                {
                (billingPattern?.code == 'postconsumption') && 
                <div className="p-field">
                    <label htmlFor="invoice-cycle">Invoice Cycle</label>
                    <Dropdown id="invoice-cycle" name='invoiceCycle' value={invoiceCycle} onChange={setInvoiceCycleFn} options={invoiceCycleOptions} optionLabel="name" placeholder="Select Invoice Cycle"></Dropdown>
                </div>
                }
                </>
                }

                {
                (commercialDialog) && 
                <>
                    <div className="p-field">
                        <label htmlFor="extension-period">Extension Period</label>
                        <InputText id="extension-period" name='extperiod' placeholder='Extension Period' onChange={setExtPeriod} type="number" min="0" defaultValue={prizing?.packages[0]?.extperiod} />
                    </div>
                </>
                }

                </form>
                :
                <center>
                    <span className='p-error'><b>No Capsule Available for On-board</b></span>
                </center>

            }
        </Dialog>


        <AddLead leadDialog={leadDialog} setLeadDialog={setLeadDialog} getLeads={getLeads} />

        <AddFacility facilityDialog={facilityDialog} setFacilityDialog={setFacilityDialog} selectedAccount={selectedAccount} getMachines={getMachines} getFac={getFac}/>

        </>
    )
}