import React from 'react';
import { TabView } from 'primereact/tabview';
import { TabPanel } from 'primereact/tabview';
import { LevelChart } from './LevelChart';
import { KettleSensor } from './KettleSensor';
import { HeaterMaintenance } from './HeaterMaintenance';

export const CVESPredictiveMaintenance = () => {
    return (
        <>
            <div className="card">
                <TabView>
                    <TabPanel header="Sensor Failures">
                        <KettleSensor/>
                    </TabPanel>
                    <TabPanel header="Heater Failures">
                        <HeaterMaintenance/>
                    </TabPanel>
                </TabView>
            </div>
        </>
    )
}
