import React, { useState, useEffect } from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
// Components
import { CtvmOnBoard } from "./CtvmOnBoard";
import { CapsuleOnBoard } from "./CapsuleOnBoard";
import { useDynamicPageTitle } from "../Common/CommonComponents";

export const CvesOnBoard = () =>{

    return (
        <>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                <div className="card">
                    <CtvmOnBoard/>

                    {/* <TabView> */}
                    {/* <TabPanel header="CTVM ONBOARD">
                        <CtvmOnBoard rolePermissions={rolePermissions} />
                    </TabPanel> */}

                    {/* {
                        rolePermissions?.capsuleOnboardTab ?

                        <TabPanel header="CAPSULE ONBOARD">
                            <CapsuleOnBoard rolePermissions={rolePermissions}/>
                        </TabPanel>
                        :
                        <TabPanel header=""></TabPanel>

                    } */}

                    
                    {/* </TabView> */}
                </div>
                </div>
            </div> 
        </>

    )
}