import React from 'react';
import { TabView } from 'primereact/tabview';
import { TabPanel } from 'primereact/tabview';
import { RefillOrderTracker } from './RefillOrderTracker';
import { CvesOrderTracker } from './CvesOrderTracker';

export const OrderMaster = () => {
    return (
        <>
            <div className="card">
                <TabView>
                    <TabPanel header="ZOHO ORDER TRACKER">
                        <iframe style={{height:'1000px', width:'100%', overflow: 'scroll'}} src={'https://analytics.zoho.com/open-view/2032292000007635183'}></iframe>
                    </TabPanel>
                    <TabPanel header="REFILL ORDER TRACKER">
                        <RefillOrderTracker/>
                    </TabPanel>
                    {/* <TabPanel header="CVES ORDER TRACKER">
                        <CvesOrderTracker/>
                    </TabPanel> */}
                    
                </TabView>
            </div>
        </>
    )
}
