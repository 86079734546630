import React, { lazy, Suspense } from 'react';
// import { CtvmList } from './CtvmList';
import { LoadingComponent } from '../Common/CommonComponents';

const CtvmList = lazy(() =>
    import('./CtvmList').then(module => ({
        default: module.CtvmList
    }))
);

export const CvesManager = () => {

    return (
        <>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                <div className="card">
                    <Suspense fallback={<LoadingComponent/>} >
                        <CtvmList/>
                    </Suspense>
                </div>
                </div>
            </div> 
        </>
    );
}





{/* <TabView> */}
    {/* <TabPanel header="CTVM LIST">
        <CtvmList rolePermissions={rolePermissions}/>
    </TabPanel> */}
    {/* <TabPanel header="CAPSULE LIST">
        <CapsuleList rolePermissions={rolePermissions}/>
    </TabPanel> */}
    {/* <TabPanel header="BULK MACHINE LIST">
        <BulkMachine rolePermissions={rolePermissions}/>
    </TabPanel> */}
{/* </TabView> */}