import React, {useState,useEffect} from 'react';
import AppCodeHighlight from '../../AppCodeHighlight';
import ChangeLog from '../../service/ChangeLog';

export const VersionControl = () => {

    const [logList, setLogList] = useState([]);

    useEffect(() => {
        const log =  new ChangeLog();
        log.getLogs().then((logList) => setLogList(logList));

    }, []);


    return (

        <>

        <div className="p-grid">
            <div className="p-col-12">

            <div className="card docs no-margin">
                    <h4>Version Control</h4>
                    {/* <p>{logList[0].version_name}</p> */}
            </div>

            {
                logList.length ? logList.map((logg, i) => (

                    <div className="card docs no-margin" key={i}>
                        <h4>{logg.version_name}</h4>
                        <p>{logg.date}</p>

                        <p>Following changes are done from the previous version.</p>
                        <AppCodeHighlight>
                            {logg.changes}
                        </AppCodeHighlight>
                    </div>

                
                )) : <p>No Versions Log Found</p>
            }

            </div>
        </div>

        </>

        

        // <div className="p-grid">
        //     <div className="p-col-12">

                


                
        //     </div>
        // </div>


    )
}
