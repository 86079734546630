import React from 'react'

export default function salesStatComp(props) {

		return (
			<div className="p-col-12 p-md-6">
				<div className="card widget-overview-box widget-overview-box-1">
					<span className="overview-title">
					{props.header}
					</span>
					<div className="p-d-flex p-jc-between">
						<div className="overview-detail p-d-flex p-jc-between">
							<div className="overview-texts">
							{
							
							props.loading ? 
							<b><i className="pi pi-spin pi-spinner" /></b> : 
							<b>{props.value}</b>
							
							}

							{/* <b>{props.value?props.value:'-'}</b>  */}
							</div>                               
						</div>
					</div>
					<i className={`${props.icon} side-icon`} style={{ fontSize: '2rem' }}></i>
				</div>
			</div>
		)
}
