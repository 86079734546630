import React, { useEffect, useRef, useState } from 'react'
import { TableWrapper } from '../../utilComponents/DataTableWrapper'
import { Toolbar } from 'primereact/toolbar'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { CommonBodyForTable, srBodyTemplate } from '../Common/CommonComponents'

import ApiServices from '../../service/api/ApiServices';
import CommonCal from '../../service/common/CommonCal'
const apiServices = new ApiServices();
const commonCal = new CommonCal();

const initialValue = JSON.parse(localStorage.getItem("user"));

export const PhonePayTransaction = (props) => {

    const [loading, setLoading] = useState(true)
    const [globalFilter, setGlobalFilter] = useState('')
    const [phonePayTrans, setPhonePayTrans] = useState(null)
    const [selectedDuration, setSelectedDuration] = useState(props.durationOptions[0])
    const [customFilterDialog, setCustomFilterDialog] = useState(false)

    const [allMachines,setAllMachines] = useState([{displayLabel:'All'}])
    const [selectedMachine,setSelectedMachine] = useState({displayLabel:'All'})

    const [allAccounts, setAllAccounts] = useState([{company: 'All', code: 'all'}])
    const [selectedAccount, setSelectedAccount] = useState({company: 'All', code: 'all'})
    const [allDates, setAllDates] = useState(null)
    const [selectedDurationDates, setSelectedDurationDates] = useState(null)

    const dt = useRef('')

    useEffect(() => {
        getDates()
        getTransactions({})
    }, [])

    useEffect(()=>{
        // console.log(props.allAccounts)
        getAccounts(props.allMachines)
    },[props.allMachines])

    const getAccounts = (data) =>{
        if(data){
            setAllMachines([...allMachines,...data])
        }
    }
    
    const getDates = async() =>{
        const response = commonCal.getDates()
        setAllDates(response)
        // setSelectedDurationDates({startDate: response.startToday, endDate: response.endToday})
        setSelectedDurationDates(null)
    }

    const getTransactions = async(data) =>{
        const response = await apiServices.getPhonePayTransactions(data);
        console.log(response?.data?.data);
        setPhonePayTrans(response?.data?.data)
        setLoading(false)
    }

    const changeDuration = (e) =>{
        setLoading(true)
        setSelectedDuration(e.value)
        switch (e.value.code) {
            case 'today':{
                
                const date = {date:{startDate:allDates.startToday,endDate:allDates.endToday} , machineId : selectedMachine?.id }
                getTransactions(date)
                setSelectedDurationDates(date.date)
                break;
            }
            case 'yesterday':{
                    const date = {date:{startDate:allDates.YestStart,endDate:allDates.EndStart, machineId : selectedMachine?.id}}
                    getTransactions(date)
                    setSelectedDurationDates(date.date)
                break;
            }
            case 'this_week':{

                    const date = {date:{startDate:allDates.weekstart,endDate:allDates.weekEnd}, machineId : selectedMachine?.id}
                    getTransactions(date)
                    setSelectedDurationDates(date.date)
                break;
            }
            case 'this_month':{

                    const date = {date:{startDate:allDates.monthStart,endDate:allDates.endThisMonth}, machineId : selectedMachine?.id}
                    getTransactions(date)
                    setSelectedDurationDates(date.date)
                break;
            }
            case 'last_month':{

                    const date = {date:{startDate:allDates.lastStart,endDate:allDates.lastend}, machineId : selectedMachine?.id}
                    getTransactions(date)
                    setSelectedDurationDates(date.date)
                break;
            }
            case 'custom':
                setCustomFilterDialog(true)
            break;
        
            default:{
                getTransactions({ machineId : selectedMachine?.id })
                setSelectedDurationDates(null)
                break;
            }       
        }
    }

    const changeAccount = (e) =>{
        setSelectedMachine(e.value)
        if(e.value?.displayLabel == 'All'){
            getTransactions({ date : selectedDurationDates })
        }else{
            getTransactions({ machineId : e.value?.id , date : selectedDurationDates})
        }
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                {
                    initialValue.userType !== 'customer' &&
                    <div className="p-inputgroup p-mr-2">
                        <Button className='custom-group-btn' label="ACC" />
                        <Dropdown value={selectedMachine} options={allMachines} onChange={changeAccount} optionLabel="displayLabel" placeholder="Select Filter" filter
                            className="p-m-0 multiselect-custom" />
                    </div>
                }

                <div className="p-inputgroup ">
                    <Button className='custom-group-btn' label="DURATION" />
                    <Dropdown value={selectedDuration} onChange={changeDuration}  options={props.durationOptions} optionLabel="name" placeholder="Custom" filter
                        className="p-m-0 multiselect-custom" />
                </div>
            </>
        )
    }

    return (
        <>
            <TableWrapper>
                <Toolbar className="p-mb-4 p-toolbar"  right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={phonePayTrans} 
                    dataKey="id" paginator rows={20} rowsPerPageOptions={[5, 10, 20]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Transactions"
                    globalFilter={globalFilter} emptyMessage="No Data found." loading={loading} exportFilename={`Phone Pay Transactions`}>
                    <Column field="id" header="Sr.No." sortable body={srBodyTemplate}  exportable={false}></Column>
                    <Column field='machineId' header="CVES Id" body={CommonBodyForTable}></Column> 
                    <Column field="beverage_name" header="Beverage" sortable body={CommonBodyForTable}></Column>
                    <Column field="orderId" header="Order Id" body={CommonBodyForTable} sortable></Column>
                    <Column field="amount" header="Amount" sortable body={CommonBodyForTable}></Column>
                    {/* <Column field="txndetails.paymentStatus" header="Payment Status" sortable body={nestedBodyForTable}></Column> */}
                    {/* <Column field="dispensed" header="Dispensed" sortable body={BoolenBodyForTable}></Column> */}
                    {/* <Column field="createdAt" header="Date & Time" sortable body={DateBodyForTable}></Column> */}
                </DataTable>
            </TableWrapper>
        </>
    )
}
