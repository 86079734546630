import React from 'react'
import { LevelChart } from './LevelChart'

export const CvesAuditSystem = () => {
    return (
        <>  
            <div className='card'>
                <LevelChart/>
            </div>
        </>
    )
}
