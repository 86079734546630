import React, {useState,useEffect} from "react";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { TabPanel } from "primereact/tabview";
import { TabView } from "primereact/tabview";
import { managementCheck } from "../../service/admin/superadmin";
// Services
import CommonCal from "../../service/common/CommonCal";
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()
const commonCal = new CommonCal()

export const AllComparisonChart = (props) =>{

    
    const [loading,setLoading] = useState(true)
    const [dataLabels,setDataLabels] = useState(null)
    const [dataValues,setDataValues] = useState(null)
    const [selectedDuration,setSelectedDuration] = useState({ name: 'Last 3 Months', code: 'last_three_month' })
    const [dates,setDates] = useState(null)
    const [allMachines,setAllMachines] = useState(null)
    const [allMachineData,setAllMachineData] = useState(null)
    const [selectedMachine,setSelectedMachine] = useState(null)
    const [defaultMachine,setDefaultMachine] = useState(null)
    const [selectedDate,setSelectedDate] = useState(null)
    const [customDateDialog,setCustomDateDialog] = useState(false)
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');

    // const durationOptions = [
    //     { name: 'Today', code: 'today' },
    //     { name: 'Yesterday', code: 'yesterday' },
    //     { name: 'This Week', code: 'this_week' },
    //     { name: 'This Month', code: 'this_month' },
    //     { name: 'Last Month', code: 'last_month' },
    //     { name: 'Custom', code: 'custom' }
    // ];

    const durationOptions = [
        { name: 'Last 3 Months', code: 'last_three_month' },
        // { name: 'Last Month', code: 'this_month' },
        { name: 'Last 6 Months', code: 'last_six_month' },
        { name: 'Last 12 Months', code: 'last_twelve_months' }
    ];

    useEffect(()=>{
        getMachine()
        getDates()
    },[])

    const hideCustomDialog = () =>{
        setCustomDateDialog(false)
    }

    const getMachine = async() =>{

        const response = await apiServices.findMachines();

        if(managementCheck()){
            const ioResponse = await apiServices.getIoMachines();
            var newArr = [...response?.data?.data,...ioResponse?.data?.data];
        }else{
            var newArr = [...response?.data?.data];
        }
    
        const response1 = await apiServices.getUserReduced();
        const response2 = await apiServices.getMaterialType();
        const userred = response1?.data?.data.map((x)=>{return x.userid})

        let arr = []
        newArr.map((x)=>{if(userred?.includes(x.customer)){arr.push(x)}})

        const result = arr.filter(checkMachine);

        var resultedMachine =  result.map((x) => {
            const customer = x.customer
            const subid = x.subid?x.subid:'CIPL';
            const address = response1.data.data.find(o => o.userid === x.customer) 
            const newCompny = address?.billing_details?.company || address.name + address.lastName

            return {
                ...x,
                displayLabel: x.id + ' / ' + subid + ' / ' + newCompny  ,
                address : address?.addr,
                companyName : address?.billing_details?.company
            };
        });

        resultedMachine.unshift({displayLabel:'All'});
        setAllMachines(resultedMachine)
        setSelectedMachine(resultedMachine)
        setDefaultMachine(resultedMachine ? resultedMachine[0] : null)

        const onBoardmachines = resultedMachine?.filter((m)=>{ return  m?.datastore?.stage == "finishedgoods" && m?.subscription && (m?.subscription?.package?.site == "demo" || m?.subscription?.package?.site == "permanent") && m?.siteName != ""  })
        const lastArr = onBoardmachines.filter(checkMachine).map(a => a.id);
        setAllMachineData(lastArr)
        thisThreeMonthData(lastArr)
        setSelectedMachine(lastArr)
    }

    function checkMachine(m) {
        return  m.datastore.stage == "finishedgoods" && m.subscription && (m.subscription.package.site == "demo" || m.subscription?.package.site == "permanent") && m.siteName != ""
    }

    const getDates = async() =>{
        const response = await commonCal.getDates()
        setDates(response)
    }

    const thisThreeMonthData = (result) => {

        const iddata = result;
        const currdate = new Date();
        const nowDate = new Date().toISOString();

        const lastMonthStart = new Date(currdate.getFullYear(), currdate.getMonth()-3, 1).setHours(0, 0, 0, 0);
        const lastStart = new Date(lastMonthStart).toISOString();
        const date = { startDate: lastStart, endDate:nowDate };

        setSelectedDate(date)
        getMachineData({id:iddata,date});
    }

    const getMachineData = async(data) =>{
        const response = await apiServices.getMonthWisePerformance(data)
        console.log(response?.data?.data)
        const monthsArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const yy = response?.data?.data.map((x)=>{ return {...x,date:`${x.year}-${x.month}`}})
        const sortedArr = yy.sort((a, b) => parseFloat(a.date) - parseFloat(b.date));
        // const sortedArr = response?.data?.data.sort((a, b) => parseFloat(a.month) - parseFloat(b.month));
        const label = sortedArr.map(a => { return `${monthsArray[a.month - 1]} ${a.year}`});
        const value = sortedArr.map(a => a.cups);
        setDataLabels(label)
        setDataValues(value)
        setLoading(false)
    }

    const barData = {
        labels: dataLabels,
        datasets: [
            {
                label: 'Cups',
                backgroundColor: '#00544d',
                borderColor: 'rgb(255, 99, 132)',
                data: dataValues,
                borderRadius: 5
            }
        ]
    };

    const barOptions = {
        plugins: {
            legend: {
                // labels: {
                //     color: '#A0A7B5'
                // }
                display:false
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                }
            }
        }
    };



    const chartData = {
        labels: dataLabels,
        datasets: [
            {
                data: dataValues,
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A",
                    "#FFA726",
                    "#75bef8",
                    "#357b38",
                    "#b08620",
                    "#008494",
                    "#ed4981",
                    "#0F8BFD",
                    "#676B89"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784",
                    "#FFB74D",
                    "#75bef8",
                    "#357b38",
                    "#b08620",
                    "#008494",
                    "#ed4981",
                    "#0F8BFD",
                    "#676B89"
                ]
            }
        ]
    }

    const lightOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    }


    const selectMachine = (e) =>{
        setLoading(true)
        console.log(e.target.value);
        setDefaultMachine(e.target.value)
        const machineData = e.target.value.displayLabel == 'All' ? allMachineData : [e.target.value?.id];
        const mch  = e.target.value.displayLabel == 'All' ? setSelectedMachine(allMachineData) : setSelectedMachine([e.target.value?.id])
        getMachineData({date:selectedDate,id:machineData})
    }

    const changeDuration = (e) =>{

        setLoading(true)
        setSelectedDuration(e.target.value)

        const currdate = new Date();
        const nowDate = new Date().toISOString();

        switch(e.target.value.code) {
            case 'last_three_month':

                const lastMonthStart = new Date(currdate.getFullYear(), currdate.getMonth()-3, 1).setHours(0, 0, 0, 0);
                const lastStart = new Date(lastMonthStart).toISOString();
                const date = { startDate: lastStart, endDate:nowDate };

                setSelectedDate(date)
                getMachineData({date:date,id:selectedMachine})
                break;
            case 'last_six_month':
                const lastsix = new Date(currdate.getFullYear(), currdate.getMonth()-6, 1).setHours(0, 0, 0, 0);
                const lastSixStart = new Date(lastsix).toISOString();
                const sixDate = { startDate: lastSixStart, endDate:nowDate };

                setSelectedDate(sixDate)
                getMachineData({date:sixDate,id:selectedMachine})
                break;
            case 'last_twelve_months':

                const lastTwelve = new Date(currdate.getFullYear(), currdate.getMonth()-12, 1).setHours(0, 0, 0, 0);
                const lastTwelveStart = new Date(lastTwelve).toISOString();
                const twelveDate = { startDate: lastTwelveStart, endDate:nowDate };

                setSelectedDate(twelveDate)
                getMachineData({date:twelveDate,id:selectedMachine})

                break;
            case 'custom':
                setCustomDateDialog(true)
                break;
            default:
                const defaultD = new Date(currdate.getFullYear(), currdate.getMonth()-3, 1).setHours(0, 0, 0, 0);
                const defaultDStart = new Date(defaultD).toISOString();
                const defaultSate = { startDate: defaultDStart, endDate:nowDate };

                setSelectedDate(defaultSate)
                getMachineData({date:defaultSate,id:selectedMachine})
          }
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
        setCustomDateDialog(false)
        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        let cust_start = new Date(startt).toISOString();
        let cust_end = new Date(endd).toISOString();
        const date = { startDate: cust_start, endDate:cust_end };
        getMachineData({date:date,id:selectedMachine})
    }
    
    const rightToolbarTemplate = () => (
        <>
            <div className="p-inputgroup ">
                <Button className='custom-group-btn' label="DURATION" />
                <Dropdown value={selectedDuration} onChange={changeDuration} options={durationOptions} optionLabel="name" placeholder="Select Filter" filter
                    className="p-m-0 multiselect-custom" />
            </div>
        </>
    ) 

    const leftToolbarTemplate = () =>(
        <>
            <div className="p-inputgroup">
                <Button className='custom-group-btn' label="CTVM" />
                <Dropdown  value={defaultMachine} onChange={selectMachine} options={allMachines} optionLabel="displayLabel" placeholder="Select Machine Id" filter className="p-m-0 multiselect-custom"/>
            </div>    
        </>
    )


    const customFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={null}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
            <button className="p-button p-button-success" type="submit" form="custom-form" ><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
        </>
    )
    

    return(

        <>

            <div className="card"> 

                { !loading && <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> }   
            

                { 
                    loading ? 

                    <div className="spinner-div">
                        <center><ProgressSpinner strokeWidth="7" animationDuration='4s' /></center>
                    </div>

                    :

                    <TabView>
                        <TabPanel header="Bar Chart">
                                <div className="card">        
                                    <Chart type="bar" data={barData} options={barOptions} />
                                </div>                  
                        </TabPanel> 
                        <TabPanel header="Pie Chart">
                                <div className="card flex justify-content-center">
                                    <center>
                                        <Chart type="pie" data={chartData} options={lightOptions} style={{ position: 'relative', width: '40%' }} />
                                    </center>
                                </div>
                        </TabPanel> 
                    </TabView>
                }

            </div>


            <Dialog visible={customDateDialog} style={{ width: '550px' }} header="Custom Filter" footer={customFooter} onHide={hideCustomDialog} modal className="p-fluid">
                <form onSubmit={handleSubmit} id="custom-form" className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From</label>
                            <InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => {setCustomStart(e.value) } } required autoFocus/>
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' value={customEnd} onChange={(e) => {setCustomEnd(e.value)}} required/>
                        </div>
                    </div>
                </form>
            </Dialog>

        </>
    
    )
}