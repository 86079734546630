import React, { useState, useEffect, useRef } from 'react'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'

// Services
import ApiServices from "../../service/api/ApiServices";
import { MultiSelect } from 'primereact/multiselect'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { CommonBodyForTable, DateBodyForTable, srBodyTemplate } from '../Common/CommonComponents'
import { Toast } from 'primereact/toast'
import { failureToast, successToast } from '../Common/CommonFunctions'
const apiServices = new ApiServices();

// ==================================================================================================================================
// ==================================================================================================================================

export const SalesTargets = () => {

    const targetTypeOptions = [
        { name : 'Machine Installation', code : 'INSTALLATION'},
        { name : 'Cup Consumption', code : 'CONSUMPTION'}
    ]

    const brandOptions = [
        { name : 'TAPRI KIOSK', code : 'TAPRI'},
        { name : 'TKAFFE KIOSK', code : 'TKAFFE'},
        { name : 'TAPRI KETTLE', code : 'MERI_TAPRI'}
    ]

    const [loading, setLoading] = useState(true)
    const [salesTargetData, setSalesTargetData] = useState(null)
    const [salesDialog, setSalesDialog] = useState(false)
    const [allUsers, setAllUsers] = useState(null)
    const [selectedBrand, setSelectedBrand] = useState(null)
    const [targetPayload, setTargetPayload] = useState({})
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedType, setSelectedType] = useState(null)
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);

    useEffect(() => {
        getData();
        getSalesTarget();
    }, [])

    const getData = async() =>{
        const response = await apiServices.getUsersByType({ userType: "accountmanager" })
        console.log(response?.data?.data);
        const data = response?.data?.data.map((x)=>{ return {...x, fullName : x.name + " " + x.lastName}})  
        setAllUsers(data)
    }

    const getSalesTarget = async() =>{
        const response = await apiServices.getSalesTarget({});
        console.log(response?.data?.data);
        setSalesTargetData(response?.data?.data);
        setLoading(false)
    }

    const openSalesDialog = () =>{
        setSalesDialog(true)
    }

    const hideSalesDialog = () =>{
        setSalesDialog(false)
        setSelectedBrand(null)
        setTargetPayload({})
        setSelectedUser(null)
        setSelectedType(null)
    }

    const changeTarget = (e) =>{
        if(e.target.name === "sales"){
            console.log(e.target.value);
            setSelectedUser(e.target.value)
            setTargetPayload({
                ...targetPayload, 
                name : e.target.value?.name,
                last_name : e.target.value?.lastName,
                userType : e.target.value?.userType, 
                userid : e.target.value?.userid
            })
        }

        if(e.target.name === "type"){
            setSelectedType(e.target.value)
            setTargetPayload({...targetPayload, [e.target.name] : e.target.value?.code })
        }

        if(e.target.name === "brand"){
            setSelectedBrand(e.target.value)
            const data = e.target.value?.map((x)=>{ return x.code });
            setTargetPayload({...targetPayload, [e.target.name] : data })
        }

        if(e.target.name === "target_value"){
            setTargetPayload({...targetPayload, [e.target.name] : e.target.value })
        }

        if(e.target.name === "startDate" || e.target.name === "endDate"){
            const startt = new Date(e.target.value).setHours(0, 0, 0, 0);
            const startDate = new Date(startt).toISOString();
            setTargetPayload({...targetPayload, [e.target.name] : startDate })
        }

        if(e.target.name === "endDate"){
            const endd = new Date(e.target.value).setHours(23, 59, 59, 0);
            const enddate = new Date(endd).toISOString();
            setTargetPayload({...targetPayload, [e.target.name] : enddate })
        }
    }

    const submitTarget = async(e) =>{
        e.preventDefault();
        console.log(targetPayload);
        const response = await apiServices.createSalesTarget(targetPayload);
        console.log(response?.data);
        if(response?.data?.success){
            successToast(toast, 'Target Added Successfully')
            hideSalesDialog()
            getSalesTarget()
        }else{
            failureToast(toast, response?.data?.error)
        }
    }

    const rightToolbarTemplate = () =>(
        <Button className='p-custom-btn' label="Add Sales Manager's Targets" icon="pi pi-plus-circle" iconPos="left" onClick={openSalesDialog} />
    )

    const addTargetDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideSalesDialog} />
            <Button label="Submit" type='submit' form='add-target-form' icon="pi pi-check" className=" p-button-success p-mr-2 p-mb-2" />
        </>
    );

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                <h6>Sales Manager Target's</h6>
            </span>            
            <span className="p-input-icon-left">
                <div className="p-inputgroup p-ml-1">
                    <Button className="custom-group-btn " icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </div>
            </span>
        </div>
    );

    return (
        <>  
            <Toast ref={toast} position='center' />
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <Toolbar right={rightToolbarTemplate} />
                    <div className="card">
                        <DataTable  value={salesTargetData}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            emptyMessage="No Records found." loading={loading} header={header} globalFilter={globalFilter}>
                            <Column field="srid" header="Sr.No." body={srBodyTemplate}  exportable={false} ></Column>
                            <Column field="name" header="Name" body={CommonBodyForTable}></Column>
                            <Column field="last_name" header="last Name" body={CommonBodyForTable}></Column>
                            <Column field="userid" header="User Id" body={CommonBodyForTable}></Column>
                            <Column field="type" header="Target Type" body={CommonBodyForTable}></Column>
                            <Column field="target_value" header="Target" body={CommonBodyForTable}></Column>
                            <Column field="startDate" header="Start Date" body={DateBodyForTable}></Column>
                            <Column field="endDate" header="End Date" body={DateBodyForTable}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>


            <Dialog visible={salesDialog} style={{ width: '650px' }} header="Add Sales Manager's Target" modal className="p-fluid" footer={addTargetDialogFooter} onHide={hideSalesDialog}>
                <form onSubmit={submitTarget} id="add-target-form" className="p-grid">
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="acc-id">Select Sales Manager</label>
                            <Dropdown id="acc-id" name='sales' options={allUsers} value={selectedUser} onChange={changeTarget} optionLabel="fullName" placeholder="Select Sales Manager" required></Dropdown>
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="target-type">Select Target Type</label>
                            <Dropdown id="target-type" name='type' options={targetTypeOptions} value={selectedType} onChange={changeTarget} optionLabel="name" placeholder="Select Target Type" required></Dropdown>
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="brand">Brand</label>
                            <MultiSelect name='brand' value={selectedBrand} options={brandOptions} onChange={changeTarget} optionLabel="name" display="chip" placeholder="Select Brand" maxSelectedLabels={3} filter  />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="target_value">Target</label>
                            <InputText id="target_value" type='number' name='target_value' placeholder='Target' min={0} onChange={changeTarget} autoComplete="off"/>
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="start-date">Start Date</label>
                            <InputText id="start-date" type='date' name='startDate' placeholder='Start Date' onChange={changeTarget} autoComplete="off"/>
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="end-date">End Date</label>
                            <InputText id="end-date" type='date' name='endDate' placeholder='End Date' onChange={changeTarget} autoComplete="off"/>
                        </div>
                    </div>
                </form>
            </Dialog>

        </>
    )
}
