import React, {useState, useEffect, useRef, useContext} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
// 
import { permissionContext } from "../../AppWrapper";
// components
import { AddVirtualCards } from "./AddVirtualCards";
// Services
import ApiServices from "../../service/api/ApiServices";
import { failureToast, successToast } from "../Common/CommonFunctions";
const apiServices = new ApiServices()

export const VirtualCards = (props) =>{

    const rolePermissions = useContext(permissionContext)
    
    const [loading,setLoading] = useState(true)
    const [cardsData,setCardsData] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [deleteCardDialog, setDeleteCardDialog] = useState(false);
    const [virtualCardDialog, setVirtualCardDialog] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [virtualCardMach, setVirtualCardMach] = useState([]);
    const [selectedVirtualMach, setSelectedVirtualMach] = useState([]);
    const dt = useRef(null);
    const toast = useRef(null);

    useEffect(()=>{
        getVirtualMachines()
    },[])

    const getVirtualMachines = async() =>{

        const response = await apiServices.findMachines();
        const response1 = await apiServices.getUserReduced();

        const userred = response1?.data?.data?.map((x)=>{return x.userid})
        let arr = []
        response?.data?.data.map((x)=>{if(userred.includes(x.customer)){arr.push(x)}})
        const result = arr.filter((m)=>{return m.siteName != ''});
        var resultedMachine =  result.map((x) => {
            const subid = x.subid?x.subid:'CIPL';
            const address = response1.data.data.find(o => o.userid === x.customer)
            return {...x,displayLabel: x.id + ' / ' + subid + ' / ' + address?.billing_details?.company ,address : address?.addr}
        });

        const virtual = resultedMachine.filter((x)=>{if(x?.mchmode?.paymode?.password){return x;}})
        setVirtualCardMach(virtual)
        setSelectedVirtualMach(virtual[0])
        console.log(virtual);
        getVirtualCards(virtual[0])
    }

    const getVirtualCards = async(data) =>{
        const response = await apiServices.getVirtualCards({machineId: data?.id})
        console.log(response?.data?.doc);
        setCardsData(response?.data?.doc)
        setLoading(false)
    }

    const openDelete = (rowData) =>{ 
        setSelectedCard(rowData)
        setDeleteCardDialog(true) 
    }

    const openEdit = (rowData) =>{
        console.log(rowData);
        setSelectedCard(rowData)
        setVirtualCardDialog(true)
    }

    const deleteAction = async() =>{ 
        console.log(selectedCard)
        const response = await apiServices.deleteVirtualCards({cno:selectedCard?.cno});
        response?.data?.success ? successToast(toast,'Card deleted successfully') : failureToast(toast,'Failed to delete');
        // response?.data?.success ? props.success("Card deleted successfully") : props.failure("Failed to delete")
        hideCardDialog()
        getVirtualCards(selectedVirtualMach)
    }

    const hideCardDialog = () =>{ setDeleteCardDialog(false) }

    const exportCSV = () =>{
        console.log('Hello');
    }

    const changeVirtualMach = (e) =>{
        getVirtualCards(e.value)
        console.log(e.value);
        setSelectedVirtualMach(e.value)
    }

    const openVirtualCard = () =>{
        setVirtualCardDialog(true)
    }

    const header = (data) =>{
        return (
            <div className="table-header">
                <h6 className="p-mt-3">Virtual Cards of {data?.id} </h6>
                <span className="p-input-icon-left">
                    <div className="p-inputgroup">
                        <Button className='custom-group-btn' icon="pi pi-search" />
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                        <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4'  onClick={exportCSV}/>
                    </div>
                </span>
            </div>
        )
    }

    const idBodyTemplate = (rowData, props) => {return (<><span className="p-column-title">{props.header}</span>{props.rowIndex + 1}</>)}
    const commonBodyTemplate = (data, props) => {return (<><span className="p-column-title">{props.header}</span>{data[props.field]}</>)}

    const cupsBodyTemplate = (data, props) => { 
        // console.log(data[props.field]?.full);
        return (
            <><span className="p-column-title">{props.header}</span>{typeof(data[props.field]?.full) === 'object' ? data[props.field]?.full?.full : data[props.field]?.full}</>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions">
                    { rolePermissions?.editVirtualCard && <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-success  p-mr-2 p-mb-2" onClick={()=>{openEdit(rowData)}} /> }
                    { rolePermissions?.deleteVirtualCard && <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger  p-mr-2 p-mb-2" onClick={()=>{openDelete(rowData)}} /> }
                </div>
            </>
        )
    }

    const vCardFooter = (
        <>
            <Button label="Yes, Delete it" icon="pi pi-check" className=" p-button-danger p-mr-2 p-mb-2" onClick={deleteAction} />
            <Button label="No" icon="pi pi-times" className=" p-button-success p-mr-2 p-mb-2"  onClick={hideCardDialog} />
        </>
    )

    const leftToolbarTemplate1 = () =>{
        return (
            <div className="p-inputgroup" style={{maxWidth:'70%'}}>
                <Button className='custom-group-btn' label="CTVM" />
                <Dropdown id='select-access' value={selectedVirtualMach} options={virtualCardMach} onChange={changeVirtualMach} optionLabel="displayLabel" placeholder="Select CVES" filter className="p-mr-2 multiselect-custom" />
            </div>
        )
    }

    const rightVirtualToolbarTemplate = () =>{ 
        return ( 
        (rolePermissions?.addVirtualCard && virtualCardMach?.length > 0) &&
        <Button label="Add Virtual Cards" icon="pi pi-plus-circle" className="p-custom-btn p-mt-1" onClick={openVirtualCard} /> 
    )}

    return (
        <>

            <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate1} right={rightVirtualToolbarTemplate} ></Toolbar>

            <div className="p-grid crud-demo">
                <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} position="center" />
                        <DataTable ref={dt} value={cardsData} dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Cards"
                            globalFilter={globalFilter} emptyMessage="No data found." header={header(selectedVirtualMach)} exportFilename={`Virtual Card Details ${selectedVirtualMach ? selectedVirtualMach.id : null}`} loading={loading}>
                            <Column field="id" header="Sr.No." sortable body={idBodyTemplate} exportable={false}></Column>
                            <Column field="name" header="Name" sortable body={commonBodyTemplate}></Column>
                            {/* <Column field="machineId" header="CTVM Id" sortable body={commonBodyTemplate}></Column> */}
                            <Column field="renewalPlan" header="Renewal Plan" sortable body={commonBodyTemplate}></Column>
                            <Column field="cups" header="Cup Count" sortable body={cupsBodyTemplate}></Column>
                            <Column header="Action" body={actionBodyTemplate} exportable={false}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            <Dialog visible={deleteCardDialog} style={{ width: '450px' }} header="Confirm" modal footer={vCardFooter} onHide={hideCardDialog}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br/><br/>
                        <b><span>Are you sure you want to delete this card?</span></b> <br/><br/>
                    </center>
                </div>
            </Dialog>            

            <AddVirtualCards getVirtualCards={getVirtualCards} selectedVirtualMach={selectedVirtualMach} virtualCardDialog={virtualCardDialog} setVirtualCardDialog={setVirtualCardDialog} selectedCard={selectedCard} virtualCardMach={props.virtualCardMach} />
 
        </>
    )
} 