import React, { useEffect, useState } from "react"; 
import { Chart } from "primereact/chart";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
// Services
import CommonCal from "../../service/common/CommonCal";
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()
const commonCal = new CommonCal()

export const KeyAccountAnalytics = () =>{

    const wiseTypeOptions = [
        { name: "CTVM's wise"}
        // { name: 'Consumption Wise' }
    ]

    const durationOptions = [
        { name: "All", code:"all"},
        { name: "This Week", code:"this_week"},
        { name: "This Month", code:"this_month"},
        { name: "Last Month", code:"last_month"},
        { name: "Custom", code:"custom"}
    ]

    const [dataLabels, setDataLabels] = useState(null)
    const [dataValues, setDataValues] = useState(null)
    const [selectedFilterType,setSelectedFilterType] = useState(wiseTypeOptions[0])
    const [kamData, setKamData] = useState(null)
    const [selectedDuration,setSelectedDuration] = useState({ name: "All", code:"all"})
    const [allDates,setAllDates] = useState(null)
    const [customFilterDialog,setCustomFilterDialog] = useState(false)
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    

    useEffect(()=>{
        getKamStats()
        getDates()
    },[])

    const getDates = async() =>{
        const response = await commonCal.getDates();
        console.log(response);
        setAllDates(response)
    }

    const getKamStats = async() =>{

        // const res = await apiServices.getUserReduced()
        // const accmanager = res.data.data.filter((x)=>{ return x.userType == "accountmanager"})
        
        const res = await apiServices.getUsersByType({ userType: 'accountmanager' })
        const res1 = await apiServices.findMachines()
        const accmanager = res.data.data;
        console.log(accmanager);

        const arrvalue = [];
        accmanager.map((x)=>{
            const xx = res1.data.data.filter((y)=>{ 
                return y?.superman?.accountmanager == x.userid && y?.subscription?.package?.site == 'permanent' && y.siteName != '';
            })
            arrvalue.push({...x,totalMachines:xx.length,customerId:xx})
        })

        const final = arrvalue.sort(function(a, b){return b.totalMachines - a.totalMachines}).filter((x)=>{return x.totalMachines > 0;});

        console.log(final);

        setKamData(final)
        setDataLabels(final.map((x)=>{return x.name + " " + x.lastName}))
        setDataValues(final.map((x)=>{return x.totalMachines}))
    }

    const changeFilter = (e) =>{
        setSelectedFilterType(e.target.value)
        getMisStats()
    }
    
    const getMisStats = async() =>{
        
        const data = {date:{startDate: "2022-10-31T18:30:00.000Z", endDate: "2022-11-07T10:00:08.143Z"}, filter: "account", value: "All"}
        const res2 = await apiServices.getMisPerformance(data)
        console.log(res2);
        console.log(kamData);
    }

    const changeDuration = (e) =>{
        setSelectedDuration(e.target.value)
        switch (e.target.value.code) {
            case 'all':
                getKamStats()
                break;
            case 'this_week':
                filterData({startDate : allDates.weekstart, endDate:allDates.weekEnd})
                break;
            case 'this_month':
                filterData({startDate : allDates.monthStart, endDate:allDates.EndStart})
                break;
            case 'last_month':
                filterData({startDate : allDates.lastStart, endDate:allDates.lastend})
                break;
            case 'custom':
                setCustomFilterDialog(true)
                break;
            default:
                getKamStats()
                break;
        }
    }


    const handleSubmit = (e) =>{
        e.preventDefault()
        setCustomFilterDialog(false)
        const start = e.target.start_date.value;
        const end = e.target.end_date.value;
        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        let cust_start = new Date(startt).toISOString();
        let cust_end = new Date(endd).toISOString();
        const date = { startDate: cust_start, endDate:cust_end };
        filterData(date)
    }



    const filterData = (param) =>{

        const filteredData = kamData.map((x)=>{
            const machines =  x.customerId;
            const data = machines.filter((y)=>{ 
                const filterState = y?.subscription ? (y?.subscription?.startDate ? true : false) : false;
                if(filterState){
                    return y?.subscription?.startDate >= param?.startDate && y?.subscription?.startDate <= param?.endDate && y?.subscription?.package?.site == 'permanent' && y.siteName != '';
                }
            })
            
            return {...x,customerId:data,totalMachines:data.length}
        })

        const final = filteredData.sort(function(a, b){return b.totalMachines - a.totalMachines});
        setDataLabels(final.map((x)=>{return x.name}))
        setDataValues(final.map((x)=>{return x.totalMachines}))
    }


    const hideCustomDialog = () =>{
        setCustomFilterDialog(false);
        setSelectedDuration({ name: "All", code:"all"});
    }


    const barData = {
        labels: dataLabels,
        datasets: [
            {
                label: "CTVM's",
                backgroundColor: '#00544d',
                borderColor: 'rgb(255, 99, 132)',
                data: dataValues,
                borderRadius: 5
            }
        ]
    };

    const barOptions = {
        plugins: {
            legend: {
                display:false
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                },
                title: {
                    display: true,
                    text: 'KAM'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                },
                title: {
                    display: true,
                    text: "No of CVES's"
                }
            }
        }
    };


    const leftToolbarTemplate = () => (
        <>  

            <div className="p-inputgroup ">
                <Button className='custom-group-btn' label="DURATION" />
                <Dropdown value={selectedDuration} onChange={changeDuration} options={durationOptions} optionLabel="name" placeholder="Select Duration" filter
                    className="p-m-0 multiselect-custom" />
            </div>

            {/* <div className="p-inputgroup ">
                <Button className='custom-group-btn' label="Filter" />
                <Dropdown value={selectedFilterType} onChange={changeFilter} options={wiseTypeOptions} optionLabel="name" placeholder="Select Filter" filter
                    className="p-m-0 multiselect-custom" />
            </div> */}
        </>
    ) 
    


    return (
        <>  
            <Toolbar right={leftToolbarTemplate}></Toolbar>
            <div className="card">            
                {/* <h6 className="centerText"></h6> */}
                <Chart type="bar" data={barData} options={barOptions} />
            </div>


            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter"  onHide={hideCustomDialog} modal className="p-fluid">
                <form onSubmit={handleSubmit}>
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From</label>
                            <InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => {setCustomStart(e.value) } } required autoFocus/>
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' value={customEnd} onChange={(e) => {setCustomEnd(e.value)}} required/>
                        </div>
                    </div>
                    <div className="p-dialog-footer p-mt-4">
                        <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
                        <button className="p-button p-button-success" type="submit"><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
                    </div>
                </form>
            </Dialog>
            
        </>
    )

}