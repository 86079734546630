import React from 'react';
import { DataView } from 'primereact/dataview';
import { useState, useEffect, useRef } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { successToast, failureToast, infoToast, copyData } from '../Common/CommonFunctions';
// Services
import ApiServices from '../../service/api/ApiServices';
const apiServices = new ApiServices();

// =============================================================================
// =============================================================================

export const KettleProducts = (props) => {

    const [layout, setLayout] = useState('grid');
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [loading, setLoading] = useState(false);
    const [beverageProducts, setBeverageProducts] = useState(null);
    const [inCartProducts, setInCartProducts] = useState(null);
    const [totalCups, setTotalCups] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [reviewOrderFlag, setReviewOrderFlag] = useState(true);
    const [orderPlaced, setOrderPlaced] = useState(false);
    const [orderSummary, setOrderSummary] = useState(null);
    const [paymentType, setPaymentType] = useState('LINK');
    const [proceedClicked, setProceedClicked] = useState(false);
    const [successDialog, setSuccessDialog] = useState(false);
    const [placedOrderData, setPlacedOrderData] = useState(null);

    const toast = useRef(null);

    useEffect(() => {
        getData()
    }, [props.registeredUser])

    const getData = async () => {
        console.log(props.registeredUser);

        if (props.registeredUser) {
            const payload = { cup_price: props.registeredUser?.cup_price || '4.24' }
            const response = await apiServices.getKettleRefill(payload);
            console.log(response?.data?.response_obj);
            const mappedArr = response?.data?.response_obj.map(v => ({ ...v, cartQuantity: 0 }))
            setBeverageProducts(mappedArr)
        }
    }

    const changeCartValue = (e, data) => {
        const QuantityValue = e.value > 999 ? 0 : e.value;
        const arr = [];
        beverageProducts.forEach((x) => { x._id == data._id ? arr.push({ ...x, cartQuantity: QuantityValue }) : arr.push({ ...x }) });
        console.log(arr);
        setInCartProducts(arr);
        setBeverageProducts(arr);

        const conclude = arr.filter((x) => { if (x.cartQuantity > 0) { return x; } });
        const totalC = conclude.map((x) => { return x.cartQuantity * x.cups_count }).reduce(function (previousValue, currentValue) { return previousValue + currentValue; }, 0);
        const sum = conclude.map((x) => { return x.cartQuantity * x.price }).reduce(function (previousValue, currentValue) { return previousValue + currentValue; }, 0).toFixed(2);
        setTotalCups(totalC);
        setTotalPrice(sum)
    }

    const reviewOrder = () => {
        const added = beverageProducts.filter((x) => { if (x.cartQuantity > 0) { return x; } })
        setInCartProducts(added)
        setReviewOrderFlag(false)
        window.scrollTo(0, 0)
    }

    const changeCartValueFromCart = (e, data) => {
        console.log(data);

        const QuantityValue = e.value > 999 ? 0 : e.value;
        console.log(QuantityValue);
        const arr = [];

        inCartProducts.forEach((x) => { x._id === data._id ? arr.push({ ...x, cartQuantity: QuantityValue }) : arr.push({ ...x }) });
        setInCartProducts(arr);

        console.log(inCartProducts);
        console.log(arr);

        const conclude = arr.filter((x) => { if (x.cartQuantity > 0) { return x; } });
        console.log(conclude);

        setInCartProducts(conclude);

        const arr2 = [];
        beverageProducts.forEach((x) => { x._id == data._id ? arr2.push({ ...x, cartQuantity: QuantityValue }) : arr2.push({ ...x }) });
        setBeverageProducts(arr2);


        if (conclude?.length > 0) {
            const totalC = conclude.map((x) => { return x.cartQuantity * x.cups_count }).reduce(function (previousValue, currentValue) { return previousValue + currentValue; }, 0);
            const sum = conclude.map((x) => { return x.cartQuantity * x.price }).reduce(function (previousValue, currentValue) { return previousValue + currentValue; }, 0).toFixed(2);
            setTotalCups(totalC);
            setTotalPrice(sum);
        } else {
            setReviewOrderFlag(true);
            setTotalCups(0);
            setTotalPrice(0);
        }


    }

    const confirmOrder = async () => {
        setOrderPlaced(true);
        const filData = inCartProducts.filter((x) => { return x.cartQuantity > 0 })
        const mapped = filData.map((x) => { return { "zoho_id": x.zoho_id, "qty": x.cartQuantity } })
        const payload = { cart: mapped, cup_price: props.registeredUser?.cup_price };
        const response = await apiServices.getKettleCartSummary(payload);
        setOrderSummary(response?.data?.response_obj)
        console.log('Customer Data === > ', props.registeredUser);
    }

    const proceedWithOrder = async () => {
        setProceedClicked(true)
        console.log(props.registeredUser);
        const filData = inCartProducts.filter((x) => { return x.cartQuantity > 0 })
        const mapped = filData.map((x) => { return { "zoho_id": x.zoho_id, "qty": x.cartQuantity } })

        const payload = {
            cart: mapped,
            // lat: props.latLong?.currentLatitude ? props.latLong?.currentLatitude : 18.6014092, 
            // lng: props.latLong?.currentLongitude ? props.latLong?.currentLongitude : 74.0058571, 
            payment_mode: paymentType,
            // fsaai: props.registeredUser?.fsaai, 
            gst: props.registeredUser?.gst,
            shipping_address: props.registeredUser?.shipping_address,
            shipping_state: props.registeredUser?.shipping_state,
            shipping_city: props.registeredUser?.shipping_city,
            shipping_pincode: props.registeredUser?.shipping_pincode,
            business_name: props.registeredUser?.business_name,
            business_registration: props.registeredUser?.business_registration,
            business_address: props.registeredUser?.business_address,
            business_state: props.registeredUser?.business_state,
            business_city: props.registeredUser?.business_city,
            business_pincode: props.registeredUser?.business_pincode,
            user_id: props.registeredUser?._id,
            cup_price: props.registeredUser?.cup_price,
            billing_type: props.registeredUser?.billing_type,
            rental_plan: props.registeredUser?.rental_plan,
            subscription_type: props.registeredUser?.subscription_type,
            rent_amount: props.registeredUser?.rent_amount,
            latitude: props.registeredUser?.latitude,
            longitude: props.registeredUser?.longitude,
            packet_cost: props.registeredUser?.packet_cost,

            email: props.registeredUser?.email,
            facility_name: props.registeredUser?.facility_name,
            legal_entity_name: props.registeredUser?.legal_entity_name,
            pan: props.registeredUser?.pan,
            payment_terms: props.registeredUser?.payment_terms,
            refundable_deposit: props.registeredUser?.refundable_deposit,
            signing_authority_designation: props.registeredUser?.signing_authority_designation,
            signing_authority_email: props.registeredUser?.signing_authority_email,
            signing_authority_mobile: props.registeredUser?.signing_authority_mobile,
            signing_authority_person_last_name: props.registeredUser?.signing_authority_person_last_name,
            signing_authority_person_name: props.registeredUser?.signing_authority_person_name,
            signing_authority_prefix: props.registeredUser?.signing_authority_prefix,
            speciality_cost: props.registeredUser?.speciality_cost,
        }

        console.log('Order Payload', payload);

        const response = await apiServices.saveKettleOrder(payload);

        if (response?.data?.response_code === 1) {
            setSuccessDialog(true)
            setPlacedOrderData(response?.data?.response_obj)
        } else if (response?.data?.response_code === 0) {
            failureToast(toast, 'Failed to place order');
            setProceedClicked(false);
        } else {
            failureToast(toast, 'Something went wrong');
            setProceedClicked(false)
        }
    }

    const hideSuccessDialog = () => {
        setSuccessDialog(false)
        setPlacedOrderData(null)
        setReviewOrderFlag(true)
        setOrderPlaced(false)
        props.setIsRegistered(false)
        props.setIsProceeded(false)
    }

    const onclickThis = (e) => {
        const copyText = placedOrderData?.razorpay_url;
        copyData(copyText);
        infoToast(toast, 'Link Copied to Clipboad', 'Copied');
    }

    const backFromReviewOrder = () => { setReviewOrderFlag(true) }

    const backFromPlaceOrder = () => {
        setOrderPlaced(false)
        // setOrderSummary(mainOrderSummary)
    }

    const dataviewGridItem = (data) => {
        return (
            <div className="p-col-12 p-md-3">
                <div className="product-grid-item card">
                    <div className="product-grid-item-top">
                        <div>
                            <i className="pi pi-tag product-category-icon"></i>
                            <span className="product-category">{data?.brand}</span>
                        </div>
                    </div>
                    <div className="product-grid-item-content p-mb-2">
                        <img src={data.image} alt={data.title} style={{ boxShadow: 'none', margin: '0px' }} />
                        <div className="product-name">{data.title} {`(${data?.cups_count} Cups)`} </div>
                    </div>

                    <div className='product-grid-item-bottom'>
                        <div className='p-col-7'>
                            <span className="product-price">₹{Number.isInteger(data.price) ? data.price : data?.price?.toFixed(2)}
                                {/* <small> {props.productPayload?.type === 'Cupwise' && `(₹${props.productPayload?.price}/Cup)`} </small> */}
                                <small>{` (₹${data?.price_per_cup}/Cup)`}</small>
                            </span>
                        </div>
                        <div className='p-col-5'>
                            <div className="grid p-fluid">
                                <div className="col-12">
                                    <InputNumber inputStyle={{ textAlign: 'center' }} inputId="horizontal" value={data.cartQuantity} onChange={(e) => { changeCartValue(e, data) }} showButtons buttonLayout="horizontal" min={0} max={999}
                                        decrementButtonClassName="p-button-primary" incrementButtonClassName="p-button-primary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const dataviewGridItemForCart = (data) => {

        if (data.cartQuantity > 0) {
            return (
                <div className="p-col-12 p-md-3" key={data?._id} >
                    <div className="product-grid-item card">
                        <div className="product-grid-item-top">
                            <div>
                                <i className="pi pi-tag product-category-icon"></i>
                                {/* <span className="product-category">{data.category}</span> */}
                                <span className="product-category">{data?.brand}</span>
                            </div>
                            {/* <span className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}>{data.inventoryStatus}</span> */}
                        </div>
                        <div className="product-grid-item-content">
                            <img src={data.image} alt={data.title} style={{ boxShadow: 'none', margin: '0px' }} />
                            <div className="product-name">{data.title} {`(${data?.cups_count} Cups)`} </div>
                            {/* <div className="product-description">{data.description}</div> */}
                            {/* <div className="product-name">{data.description} - {`(${data?.cups_count} Cups)`} </div> */}
                        </div>

                        <div className='product-grid-item-bottom'>
                            <div className='p-col-7'>
                                <span className="product-price">₹{Number.isInteger(data.price) ? data.price : data?.price?.toFixed(2)} <small> {props.productPayload?.type == 'Cupwise' && `(₹${props.productPayload?.price}/Cup)`}</small> </span>
                                {/* <span className="product-price">₹{data.price}</span> */}
                            </div>

                            <div className='p-col-5'>
                                <div className="grid p-fluid">
                                    <div className="col-12">
                                        <InputNumber inputStyle={{ textAlign: 'center' }} inputId="horizontal" value={data.cartQuantity} onChange={(e) => { changeCartValueFromCart(e, data) }} showButtons buttonLayout="horizontal" min={0} max={999}
                                            decrementButtonClassName="p-button-primary" incrementButtonClassName="p-button-primary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }

    };

    const productItemTemplate = (data, layout) => { return dataviewGridItem(data) };
    const productItemTemplateForCart = (data, layout) => { return dataviewGridItemForCart(data) };

    const successDialogFooter = () => (<> <Button label="OK" icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" onClick={hideSuccessDialog} /></>)

    const leftToolbarTemplate = () => {
        return (
            <>
                {/* { (!orderPlaced && reviewOrderFlag) && <Button className='p-custom-btn' label="Go Back" icon="pi pi-backward" iconPos="left" onClick={() => { props.setConfirmSelection(false) }}/> } */}
                {(!orderPlaced && !reviewOrderFlag) && <Button className='p-custom-btn' label="Go Back" icon="pi pi-backward" iconPos="left" onClick={backFromReviewOrder} />}
                {orderPlaced && <Button className='p-custom-btn' label="Go Back" icon="pi pi-backward" iconPos="left" onClick={backFromPlaceOrder} />}
            </>
        )
    }

    return (

        <>
            <Toast ref={toast} position='center' />
            <div className="p-grid list-demo">

                {
                    !orderPlaced &&
                    <div className='p-col-12'>

                        {
                            reviewOrderFlag ?
                                <div className="card">
                                    <h5>Kettle Material Products </h5>
                                    <DataView value={beverageProducts} layout={layout} paginator rows={8} sortOrder={sortOrder}
                                        sortField={sortField} itemTemplate={productItemTemplate} loading={loading} ></DataView>
                                </div>
                                :
                                <>
                                    <div className='card'> <Toolbar className="p-toolbar" left={leftToolbarTemplate}></Toolbar></div>
                                    <div className="card">
                                        <h5>Material Products ({`${inCartProducts?.length} Products`}) </h5>
                                        <DataView value={inCartProducts}
                                            layout={layout} paginator rows={8}
                                            sortOrder={sortOrder} sortField={sortField}
                                            itemTemplate={productItemTemplateForCart}
                                            loading={loading}
                                        ></DataView>
                                    </div>
                                </>
                        }

                        {
                            ((totalCups != 0) && reviewOrderFlag) &&
                            <div className='card'>
                                <h6>Beverages</h6>
                                <h6>{totalCups} Cups | ₹ {totalPrice} </h6>
                                {(totalCups > 0) && <Button className='p-custom-btn p-button-lg p-col-12 p-mt-2' icon="pi pi-arrow-right" iconPos="right" label='REVIEW ORDER' onClick={reviewOrder} ></Button>}
                            </div>
                        }

                        {
                            ((totalCups != 0) && !reviewOrderFlag) &&
                            <div className='card'>
                                <h6>Beverages</h6>
                                <h6>{inCartProducts?.reduce((accumulator, current) => accumulator + Number(current?.cartQuantity), 0)} Packets |  {totalCups} Cups | ₹ {totalPrice} </h6>
                                {(totalCups > 0) && <Button className='p-custom-btn p-button-lg p-col-12 p-mt-2' icon="pi pi-arrow-right" iconPos="right" label='CONFIRM ORDER' onClick={confirmOrder}></Button>}
                            </div>
                        }

                    </div>
                }

                {
                    orderPlaced &&
                    <>
                        <div className='p-col-12'>
                            <div className='card'>
                                <Toolbar className="p-toolbar" left={leftToolbarTemplate}></Toolbar>
                            </div>
                            {/* <Button icon="pi pi-arrow-left" label='Go Back' className="p-button-rounded p-button-outlined p-button-danger p-mb-2" aria-label="Back" onClick={backFromPlaceOrder} /> */}
                            <div className='card p-m-2'>
                                <div className='p-grid'>
                                    <div className='p-col-5'>
                                        <div className='card p-m-0'>
                                            <h5 className='p-mt-2'>Delivered to : </h5>
                                            <p><b>Name :</b> {props.registeredUser?.name} {props.registeredUser?.last_name}</p>
                                            <p><b>Contact :</b> {props.registeredUser?.contact}</p>
                                            <h5>Address : </h5>
                                            {/* <p><b>Account Name :</b
                                            > {addressForCheckout?.company_name}</p> */}
                                            <p>{props.registeredUser?.address || props.registeredUser?.shipping_address}, {props.registeredUser?.shipping_state}, {props.registeredUser?.city || props.registeredUser?.shipping_city}, {props.registeredUser?.shipping_pincode} </p>
                                            <hr></hr>
                                            <h5>Bussiness Address : </h5>
                                            <p>{props.registeredUser?.business_name}</p>
                                            <p>{props.registeredUser?.business_address}, {props.registeredUser?.business_state},{props.registeredUser?.business_city}, {props.registeredUser?.business_pincode}</p>
                                            {/* <p>GST Number : {addressForCheckout?.billing_address?.gst_no}</p> */}
                                            <hr></hr>
                                        </div>
                                    </div>

                                    <Divider layout="vertical" />
                                    <div className='p-col-6'>
                                        <h5>Order Summary</h5>
                                        <div id="invoice-content">
                                            <div className="invoice p-p-0">
                                                <div className="invoice-summary">
                                                    <table>
                                                        <tbody>
                                                            <tr> <td>Discount:</td> <td>₹{Number(orderSummary?.amount?.discount).toFixed(2)}</td> </tr>
                                                            <tr> <td>Sub Total:</td> <td>₹{Number(orderSummary?.amount?.subtotal)?.toFixed(2)}</td></tr>
                                                            <tr> <td>Taxes (GST):</td> <td>₹{Number(orderSummary?.amount?.tax)?.toFixed(2)}</td> </tr>
                                                        </tbody>
                                                        <tbody>
                                                            <tr> <td><b>Total Payable Amount:</b> </td> <td><b>₹{Number(orderSummary?.amount?.total)?.toFixed(2)}</b></td> </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <h5>PAYMENT MODE</h5>
                                        <div className='card'>
                                            <div className="field-radiobutton p-ml-0 p-mb-4">
                                                <RadioButton inputId="OnlinePay" name="paymentMethod" value="COD" onChange={(e) => setPaymentType(e.value)} checked={paymentType == 'COD'} />
                                                <label htmlFor="OnlinePay"> Bank Transfer </label>
                                            </div>
                                            <div className="field-radiobutton p-ml-0 p-mb-4">
                                                <RadioButton inputId="OnlinePay" name="paymentMethod" value="LINK" onChange={(e) => setPaymentType(e.value)} checked={paymentType == 'LINK'} />
                                                <label htmlFor="OnlinePay"> Online Payment (Get Payment Link on email and SMS) </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br></br>

                                {<Button className='p-custom-btn p-button-lg p-col-12 p-mt-2' icon={proceedClicked ? 'pi pi-spin pi-spinner' : 'pi pi-arrow-right'} iconPos="right" label='PROCEED' onClick={proceedWithOrder} disabled={proceedClicked} ></Button>}

                            </div>
                        </div>
                    </>
                }

            </div>


            <Dialog header="Success" visible={successDialog} style={{ width: '30vw' }} onHide={hideSuccessDialog} footer={successDialogFooter}>
                <center>
                    <i className="pi pi-check-circle" style={{ fontSize: '8rem', color: "green" }}></i>
                    <h3>Order Placed</h3>
                    <h6>Order Id : {placedOrderData?.order_no} </h6>
                    {
                        placedOrderData?.razorpay_url &&
                        <h6>Payment Link : <br></br> <a href={placedOrderData?.razorpay_url} target='_blank'>{placedOrderData?.razorpay_url}</a>  <i className="p-m-1 pi pi-copy" style={{ cursor: 'pointer' }} onClick={onclickThis} ></i>  </h6>
                    }
                </center>
            </Dialog>

        </>
    )
}
