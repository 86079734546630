import React, {useEffect, useState} from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { InventoryStats } from "./InventoryStats";
import { InventoryMaterialStatus } from "./InventoryMaterialStatus";
import { BeverageInventory } from "./BeverageInventory";
// Common
import { dateFilterItems, viewFilterItems, inventoryFilterItems } from "./Constants";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices();

// =====================================================================================================
// =====================================================================================================

export const CustomerInventory = () =>{

    const [loading1,setLoading1] = useState(null)
    const [selectedFilterItems,setSelectedFilterItems] = useState({ name: 'Packets', code: 'packets' });
    const [selectedDateFilter,setSelectedDateFilter] = useState({ name: 'Till Date', code: 'till_date' });
    const [selectedInventory,setSelectedInventory] = useState({ name: "Total Inventory", code: "total_inventory" });

    const [defaultMachine, setDefaultMachine] = useState([]);
    const [allMachines, setAllMachines] = useState();

    useEffect(()=>{ getMachine() },[])

    const getMachine = async () => {

        const response = await apiServices.findMachines();
        console.log(response.data.data);
        const response1 = await apiServices.getUserReduced();
        const userred = response1?.data?.data.map((x) => { return x.userid })
        let arr = []
        response?.data?.data.map((x) => { if (userred.includes(x.customer)) { arr.push(x) } })
        const result = arr.filter((m)=>{return m.siteName !== ""});
        
        var resultedMachine = result.map((x) => {
            const customer = x.customer
            const subid = x.subid ? x.subid : 'CIPL';
            const address = response1.data.data.find(o => o.userid === x.customer)
            return {
                ...x,
                displayLabel: x.id + ' / ' + subid + ' / ' + address?.billing_details?.company,
                address: address?.addr
            }
        });
    
        resultedMachine.unshift( {displayLabel : 'All',customer : 'All'} )
        setAllMachines(resultedMachine);
        setDefaultMachine(resultedMachine[0])
    }

    const selectedMachine = async (e) => {
        setDefaultMachine(e.value)
        // if(e.value.displayLabel == 'All'){
        //     setSelectedMach(false)
        //     selectedDate ? getPostInvoice({date:selectedDate,userType: initialValue.userType,userid:initialValue.userid},false,null) : getPostInvoice({userType: initialValue.userType,userid:initialValue.userid},false,null)
        // }else{
        //     console.log("Machine Selected");
        //     setSelectedMach(true)
        //     selectedDate ? getPostInvoice({date:selectedDate,customerid: e.value.customer,userType: initialValue.userType},true,e.value) : getPostInvoice({customerid: e.value.customer,userType: initialValue.userType},true,e.value)
        // }
    };


    const leftToolbarTemplate = () => {
		return (
			<>
				<div className="p-grid">
					<div className="p-col-sm-6">
						<div className="p-inputgroup p-mt-2" >
							<Button className="custom-group-btn" label="CVES" />
							<Dropdown value={defaultMachine} onChange={selectedMachine} options={allMachines} optionLabel="displayLabel" placeholder="Select CTVM Id" filter className="p-mr-2 multiselect-custom" />
						</div>
					</div>
					<div className="p-col-sm-6">
						<div className="p-inputgroup p-mt-2" >
							<Button className="custom-group-btn" label="Duration" />
							<Dropdown value={selectedDateFilter} options={dateFilterItems} optionLabel="name" placeholder="Select Date Filter" filter className="p-mr-2 multiselect-custom" />
						</div>
					</div>
				</div>
			</>
		)
	};


    const rightToolbarTemplate = () => {
		return (
			<>
				<div className="p-grid">
					<div className="p-col-sm-6" >
					<div className="p-inputgroup p-mt-2">
						<Button className="custom-group-btn" label="View" />
						<Dropdown value={selectedFilterItems} options={viewFilterItems} optionLabel="name" placeholder="Select View Item" filter className="p-mr-2 multiselect-custom" />
					</div>
					</div>

					<div className="p-col-sm-6">
					<div className="p-inputgroup p-mt-2" >
						<Button className="custom-group-btn" label="Inventory" />
						<Dropdown value={selectedInventory} options={inventoryFilterItems} optionLabel="name" placeholder="Select Inventory Type" filter className="p-mr-2 multiselect-custom" />
					</div>
					</div>
				</div>
			</>
		)
	};


    return (
        <>
            <div className="card">
				<Toolbar className="p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>
			</div>

            <div className="card">
                <div className="p-grid">
                    <div className="p-col-12 p-md-4">
                        <InventoryStats header="Total Material Delivered" value={'0'} icon="pi pi-send" loading1={loading1} />
                    </div>
                    <div className="p-col-12 p-md-4">
                        <InventoryStats header="Total Material Loaded" value={'0'} icon="pi pi-inbox" loading1={loading1}/>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <InventoryStats header="Total Delivered Used"  value={'0'} icon="pi pi-table" loading1={loading1}/>
                    </div>
                </div>
            </div>

            <div className="card">
                <TabView >
                    <TabPanel header="BEVERAGE INVENTORY AT ACCOUNT FACILITY">
                        {/* <BeverageInventory allMachines={allMachines} selectedFilterItems={selectedFilterItems} selectedMach={selectedMach} defaultMachine={defaultMachine} invetoryData={invetoryData} loading1={loading1}/> */}
                    </TabPanel>
                    <TabPanel header="MATERIAL DELIVERY STATUS">
                        <InventoryMaterialStatus/>
                    </TabPanel>
                </TabView>
            </div>


        
        </>
    )
}