

export const FormWrapper = (props) =>{
    return (
        <div className='p-col-6'>
            <div className="p-field">
                <label htmlFor={props.id}>{props.label}</label>
                {props.children}
            </div>
        </div>
    )
}

export const FullFormWrapper = (props) =>{
    return (
        <div className='p-col-12'>
            <div className="p-field">
                <label htmlFor={props.id}>{props.label}</label>
                {props.children}
            </div>
        </div>
    )
}
