import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Chip } from "primereact/chip";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
// Common
import { materialStageValues } from "./Constants";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// =====================================================================================================
// =====================================================================================================

export const InventoryMaterialStatus = (props) => {

    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilter1, setGlobalFilter1] = useState(null);
    const [materialDialog, setMaterialDialog] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState(false);
    const [selectedDropMaterial, setSelectedDropMaterial] = useState({ name: 'All', code:'all' });
    const [allBarcodes, setAllBarcodes] = useState(null);
    const [filterBarcodes, setFilterBarcodes] = useState(null);
    const [assignedBatch,setAssignedBatch] = useState(null);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [first, setFirst] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const [onDemandCount,setOnDemandCount] = useState(50)
    const [onDemandId,setOnDemandId] = useState(0)

    const onDemandidTemplate = (rowData,props) => (<><span className="p-column-title">Sr.No.</span>{props.rowIndex + 1 + onDemandId}</>) 
    const idTemplate = (rowData,props) => (<><span className="p-column-title">Sr.No.</span>{props.rowIndex + 1}</>)
    const barcodeBodyTemplate = (rowData,props) => (<><span className="p-column-title">{props.header}</span>{rowData.batch_data[props.field].length}</>)
    const assignedToBodyTemplate = (rowData,props) => (<><span className="p-column-title">{props.header}</span>{rowData?.batch_data[props.field]?.name}</>)
    const materialBodyTemplate = (rowData,props) => (<><span className="p-column-title">{props.header}</span>{rowData[props.field]}</>)

    const dateBodyTemplate = (data,props) =>{
        var date = new Date(data[props.field]);
        const convertedDate = date?.toISOString().substring(0, 10);
        return ( <> <span className="p-column-title">{props.header}</span> {convertedDate} </> )
    }

    const actionBodyTemplate = rowData => (
        <>
            <span className="p-column-title">Actions</span>
            <div className="actions" style={{display:"flex"}}>
                <Button icon="pi pi-eye" title='View Material Delivery Details' className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={()=>{viewMatDelivery(rowData)}}/>
            </div>
        </>
    )

    // const assignedByBodyTemplate = (rowData,props) => {
    //     console.log(rowData);
    //     return (
    //         <><span className="p-column-title">{props.header}</span>{rowData[props.field] ? rowData[props.field] : 'NA'}</>
    //         )
    // } 

    const materialDialogFooter = (<><Button label="Close" icon="pi pi-times" className="p-button-danger" onClick={()=>{setMaterialDialog(false)}} /></>);
    
    // const exportCSV = () =>{ dt.current.exportCSV() }

    const viewMatDelivery = (rowData) =>{
        setSelectedMaterial(rowData)
        setAllBarcodes(rowData?.batch_data?.barcodes)
        setFilterBarcodes(rowData?.batch_data?.barcodes)
        setMaterialDialog(true)
    }

    const onChangeMaterialStage = (e) =>{
        console.log(e.target.value)
        setSelectedDropMaterial(e.target.value)
        
        switch(e.target.value.code) {
            case 'added':
                var filtered = allBarcodes.filter((value) => { return value.stage == 'added' });
                setFilterBarcodes(filtered)
            break;
            case 'assigned':
                var filtered = allBarcodes.filter((value) => { return value.stage == 'assigned' });
                setFilterBarcodes(filtered)
            break;
            case 'loaded':
                var filtered = allBarcodes.filter((value) => { return value.stage == 'loaded' });
                setFilterBarcodes(filtered)
            break;
            case 'delivered':
                var filtered = allBarcodes.filter((value) => { return value.stage == 'delivered' });
                setFilterBarcodes(filtered)
            break;
            case 'used':
                var filtered = allBarcodes.filter((value) => { return value.stage == 'used' });
                setFilterBarcodes(filtered)
            break;
            default:
                setFilterBarcodes(allBarcodes)

        }
    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right"></span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    {/* <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV}/> */}
                </div>
            </span>
        </div>
    );

    const header1 = (
        <div className="p-grid">
        
        <div className="p-col-4">
            <div className="p-inputgroup ">
                <Button className='custom-group-btn' label="MATERIAL STAGE" />
                <Dropdown onChange={onChangeMaterialStage} value={selectedDropMaterial} options={materialStageValues} optionLabel="name" placeholder="Select Filter" filter
                className="p-m-0 multiselect-custom" />
            </div>
        </div>
        <div className="p-col-4"></div>
        <div className="p-col-4">
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter1(e.target.value)} placeholder="Search..." />
                </div>
            </span>
        </div>
        </div>
    );

    const getBatches = async() =>{

        if(initialValue.userType == 'customer'){
            const data = {barcodeDetail: true,userid:initialValue.userid}
            var response = await apiServices.getInventoryAssignedBatch(data);
            const response1 = await apiServices.getUserReduced();

            let arr = [];
            response.data.data.map((x)=>{
                const data = response1.data.data.filter((y)=>{
                    if(x.batch_data.assignby == y.userid){
                        return y;
                    }
                })
                arr.push({...x,assignByName:data[0]?.name})
            })

            setDataSource(arr);
            setTotalRecords(arr.length);
            setAssignedBatch(arr.slice(0, rows))
            setLoading(false)  

        }else if(initialValue.userType == 'owner'){
            const data = {count: onDemandCount,index: 0,userType: initialValue.userType}
            var response = await apiServices.getAssignedBatch(data);

            setDataSource(response.data.batch);
            setTotalRecords(response.data.batch.length);
            setAssignedBatch(response.data.batch.slice(0, rows))
            setLoading(false)  
        }
    }

    useEffect(()=>{getBatches()},[])

    const onPage1 = async(event) => {
        setLoading(true);
        setOnDemandId(event.rows * event.page)
        setTimeout(() => {
            const startIndex = event.first;
            const endIndex = event.first + rows;
            setFirst(startIndex);
            setAssignedBatch(dataSource.slice(startIndex, endIndex));
            setLoading(false);
        }, 250);

        if((event.page + 1) % 5 == 0){
            const data = {count: onDemandCount + 50,index: 0,userType: initialValue.userType}
            const response = await apiServices.getAssignedBatch(data);
            console.log(response.data.batch);
            setDataSource(response.data.batch);
            setTotalRecords(response.data.batch.length);
            setAssignedBatch(response.data.batch.slice(0, rows))
            setOnDemandCount(onDemandCount + 50)
        }
    }

    return (
        <>  
            <div className="p-grid crud-demo">
            <div className="p-col-12">
            <div className="card">

            <DataTable className="datatable-responsive" ref={dt} value={assignedBatch} paginator rows={rows} totalRecords={totalRecords} lazy first={first} onPage={onPage1} loading={loading} globalFilter={globalFilter} header={header} >
                <Column field="srid" header="Sr.No." body={onDemandidTemplate}></Column>
                {/* <Column field="assignByName" header="Assigned By" sortable body={assignedByBodyTemplate}></Column> */}
                <Column field="assignto" header="Assigned to" sortable body={assignedToBodyTemplate}></Column>
                <Column field="barcodes" header="Total Barcode" sortable body={barcodeBodyTemplate} ></Column>
                <Column field="date" header="Delivery Date" sortable body={dateBodyTemplate} ></Column>
                <Column header="Actions" body={actionBodyTemplate}></Column>
            </DataTable>

            <Dialog visible={materialDialog} style={{ width: "850px" }} header={`Material Delivery Details(${selectedMaterial?.batch_data?.assignto?.name})`} modal className="p-fluid" footer={materialDialogFooter} onHide={()=>{setMaterialDialog(false)}} maximizable={true} blockScroll={true}>
        
                <div className="p-grid p-mb-4">
                    <div className="p-col-6">
                        <Chip label={`Delivery Id : ${selectedMaterial?.batch_id} `} icon="pi pi-angle-double-right"  className="p-mr-2 p-mb-2"/>
                    </div>
                    <div className="p-col-6">
                        <Chip label={`Delivery Date : ${selectedMaterial?.date? new Date(selectedMaterial?.date)?.toISOString()?.substring(0, 10):'-'}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2"/>
                    </div>
                    <div className="p-col-6">
                        <Chip label={`Total Weight : ${selectedMaterial?.batch_data?.totalweight} `} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                    </div>
                    <div className="p-col-6">
                        <Chip label={`Assigned By : ${selectedMaterial?.assignByName ? selectedMaterial?.assignByName : 'NA'}`}  icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2"/>
                        {/* <Chip label={`Delivery By : ${selectedMaterial?.batch_data?.deliveryBy?.mode}`}  icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2"/> */}
                    </div>
                </div>

                <div className="p-grid crud-demo">
                <DataTable value={filterBarcodes} paginator className="datatable-responsive" rows={10} dataKey="id" rowHover globalFilter={globalFilter1} emptyMessage="No Data found." header={header1} >
                    <Column field="srNo" header="Sr.No." sortable body={idTemplate} ></Column>
                    <Column field="materialName" header="Material Name" sortable body={materialBodyTemplate} ></Column>
                    <Column field="barcode" header="Barcode" sortable body={materialBodyTemplate} ></Column>
                    <Column field="weight" header="Weight" sortable body={materialBodyTemplate} ></Column>
                    <Column field="stage" header="Stage" sortable body={materialBodyTemplate} ></Column>
                </DataTable> 
                </div>

            </Dialog> 

            </div>
            </div>
            </div>
        </>
    )

}