import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
// Common
import { exportReport } from "../Common/CommonFunctions";
import { CommonBodyForTable, commonFormDialogFooter } from "../Common/CommonComponents";
// Service
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

// ======================================================================================================
// ======================================================================================================

export const VendorContacts = () =>{

    const [loading, setLoading] = useState(false)
    const [contactData, setContactData] = useState(null)
    const [globalFilter, setGlobalFilter] = useState('')
    const [vendorDialog, setVendorDialog] = useState(false)
    const [selectedVendor, setSelectedVendor] = useState(null)
    const toast = useRef('');
    const dt = useRef('');

    useEffect(()=>{
        alert('Few Things are pending here....!!!')
        getData()
    },[])

    const getData = async() =>{
        const response = await apiServices.getcontacts()
        console.log(response?.data?.data);
        setContactData(response?.data?.data)
    }

    const exportCSV = () => { exportReport(dt) }

    const openVendor = () =>{
        setVendorDialog(true)
        setSelectedVendor(null)
    } 

    const openEditVendor = (rowData) =>{
        setVendorDialog(true)
        setSelectedVendor(rowData)
    }

    const onSubmitVendor = (e) =>{
        e.preventDefault()
        alert('Pending')
    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                <h6 className="p-mt-3 p-mr-5">Vendors</h6>
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4'  onClick={exportCSV}/>
                </div>
            </span>
        </div>
    )

    const srBodyTemplate = (rowData,props) => (<><span className="p-column-title">Sr.No</span>{props.rowIndex + 1}</>)
    const commonBodyTemplat = (rowData, props) =>(<><span className="p-column-title">{props.header}</span>{rowData.company_details[props.field]}</>)

    const actionBodyTemplate = (rowData) =>(
        <>
            <span className="p-column-title">Action</span>
            <Button icon="pi pi-pencil" title="Edit" className="p-button-rounded p-button-success p-mr-2" onClick={()=>{openEditVendor(rowData)}}/>
            <Button icon="pi pi-trash" title="Delete" className="p-button-rounded p-button-danger" onClick={()=>{alert('Pending')}}/>
        </>
    )

    const rightToolbarTemplate = () =>{
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-plus" label="ADD NEW" onClick={openVendor} />
                </div>
            </>
        )
    }

    const hideVendorDialog = () => { setVendorDialog(false) }
    const vendorDialogFooter = commonFormDialogFooter('Cancel', hideVendorDialog, 'Submit', 'vendor-form' )

    return (
        <>
            <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                <Toast ref={toast} position='center' />
                <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={contactData} dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users" globalFilter={globalFilter}
                    exportFilename={`Vendors Details`}
                    emptyMessage="No Data found." header={header} loading={loading}>
                    <Column field="srid" header="Sr.No." body={srBodyTemplate} exportable={false} style={{ width: '6rem' }} ></Column>
                    <Column field="fname" header="Name" body={commonBodyTemplat} exportable={false} sortable  ></Column>
                    <Column field="company_name" header="Comapny Name" body={CommonBodyForTable} sortable ></Column>
                    <Column field="company_mail" header="Email" body={CommonBodyForTable} sortable ></Column>
                    <Column field="cmobile" header="Phone Number" body={commonBodyTemplat} sortable ></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
                </div>
            </div>
            </div>


            <Dialog visible={vendorDialog} style={{ width: "60vw" }} header={`${selectedVendor ? 'Update' : 'Add'} Vendor`} modal footer={vendorDialogFooter} onHide={()=>{setVendorDialog(false)}} className="p-fluid" maximizable={true} blockScroll={true}>
                <form onSubmit={onSubmitVendor} id="vendor-form" className="p-fluid">
                    <div className="p-grid crud-demo">
                    <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="prefix"> Prefix </label>
                                <Dropdown id="prefix" value={null} onChange={null} options={null}
                                optionLabel="name"placeholder="Select Prefix"  ></Dropdown>
                            </div>
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="fname"> First Name </label>
                                <InputText id="fname" placeholder="First Name" />
                            </div>
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="lname"> Last Name</label>
                                <InputText id="lname" placeholder="Last Name" />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="cname"> Company Name</label>
                                <InputText id="cname" placeholder="Company Name" />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="dname"> Contact Display Name</label>
                                <InputText id="dname" placeholder="Contact Display Name" />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="email"> Contact Email</label>
                                <InputText id="email" type="email" placeholder="Contact Email" />
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="phone"> Work Phone</label>
                                <InputText id="phone" placeholder="Work Phone" />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="mob"> Mobile Number</label>
                                <InputText id="mob" placeholder="Mobile Number" />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="skype"> Skype Name/ Number</label>
                                <InputText id="skype" placeholder="Skype Name/ Number" />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="designation"> Designation</label>
                                <InputText id="designation" placeholder="Designation" />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="dept"> Department</label>
                                <InputText id="dept" placeholder="Department" />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="website"> Website</label>
                                <InputText id="website" placeholder="Website" />
                            </div>
                        </div>
                        </div>
                    </div>

                    <hr></hr>

                        <TabView>
                            <TabPanel header="Tax And Payment Details">
                                
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12">
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="gstTreat"> GST Treatment </label>
                                            <Dropdown id="gstTreat" value={null} onChange={null} options={null}
                                            optionLabel="name" placeholder="Select GST Treatment"  ></Dropdown>
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="gstTreat"> Place of Supply </label>
                                            <Dropdown id="gstTreat" value={null} onChange={null} options={null}
                                            optionLabel="name" placeholder="Select Place of Supply"  ></Dropdown>
                                        </div>
                                        
                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="gstTreat"> Currency </label>
                                            <Dropdown id="gstTreat" value={null} onChange={null} options={null}
                                            optionLabel="name" placeholder="Select Currency"  ></Dropdown>
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="gstTreat"> Payment Terms </label>
                                            <Dropdown id="gstTreat" value={null} onChange={null} options={null}
                                            optionLabel="name" placeholder="Select Payment Terms"  ></Dropdown>
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="facebook"> Facebook </label>
                                            <InputText id="facebook" type="url" placeholder="Facebook" />
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="Twitter"> Twitter </label>
                                            <InputText id="Twitter" type="url" placeholder="Twitter" />
                                        </div>

                                    </div>
                                    </div>
                                    
                                </div>

                            </TabPanel>
                            <TabPanel header="Address">
                                
                                <div className="p-grid">
                                    <h5 className="p-mt-4 p-ml-2">Billing Address</h5>
                                    <div className="p-col-12 p-md-12">
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-12">
                                            <label htmlFor="attention"> Attention </label>
                                            <InputText id="attention" placeholder="Attention" />
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="add1"> Address </label>
                                            <InputText id="add1" placeholder="Street 1" />                                            
                                        </div>
                                        
                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="add2"> Address </label>
                                            <InputText id="add2" placeholder="Street 2" />

                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="gstTreat"> City </label>
                                            <InputText id="add2" placeholder="City" />
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="State"> State </label>
                                            <InputText id="State" placeholder="State" />
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="ZipCode"> Zip Code </label>
                                            <InputText id="ZipCode" type="url" placeholder="Zip Code" />
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="Country"> Country </label>
                                            <Dropdown id="Country" value={null} onChange={null} options={null}
                                            optionLabel="name"placeholder="Select Country"  ></Dropdown>
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="fax"> Fax </label>
                                            <InputText id="fax" type="url" placeholder="Fax" />
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="Phone"> Phone </label>
                                            <InputText id="Phone" type="url" placeholder="Phone" />
                                        </div>
                                    </div>
                                    </div>
                                    
                                </div>

                                <div className="p-grid">
                                    <h5 className="p-mt-4 p-ml-2">Shipping Address</h5>
                                    <div className="p-col-12 p-md-12">
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-12">
                                            <label htmlFor="attention"> Attention </label>
                                            <InputText id="attention" placeholder="Attention" />
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="add1"> Address </label>
                                            <InputText id="add1" placeholder="Street 1" />                                            
                                        </div>
                                        
                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="add2"> Address </label>
                                            <InputText id="add2" placeholder="Street 2" />

                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="gstTreat"> City </label>
                                            <InputText id="add2" placeholder="City" />
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="State"> State </label>
                                            <InputText id="State" placeholder="State" />
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="ZipCode"> Zip Code </label>
                                            <InputText id="ZipCode" type="url" placeholder="Zip Code" />
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="Country"> Country </label>
                                            <Dropdown id="Country" value={null} onChange={null} options={null}
                                            optionLabel="name"placeholder="Select Country"  ></Dropdown>
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="fax"> Fax </label>
                                            <InputText id="fax" type="url" placeholder="Fax" />
                                        </div>

                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="Phone"> Phone </label>
                                            <InputText id="Phone" type="url" placeholder="Phone" />
                                        </div>
                                    </div>
                                    </div>
                                    
                                </div>

                            </TabPanel>
                            <TabPanel header="Custom Fields">
                                <center>
                                    <p>Start adding custom fields for your contacts by going to More Settings PreferencesContacts.You can add as many as Ten extra fields, as well as refine the address format of your contacts from there.</p>
                                </center>
                            </TabPanel>
                            <TabPanel header="Reporting Tags">
                                <center>
                                    <p>There are no active reporting tags or you haven't created a reporting tag yet. You can create/edit reporting tags under settings in Zoho Books.</p>
                                </center>
                            </TabPanel>
                            <TabPanel header="Notes">

                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12">
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-12">
                                            <label htmlFor="Notes"> Notes <small>(For Internal Use)</small> </label>
                                            <InputTextarea rows={5} cols={30} defaultValue={''} onChange={null} autoResize />
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                
                            </TabPanel>
                        </TabView>

                    </div>
                    </div>
                </form>
            </Dialog>
                            
        </>
    )
}