import React from 'react';

const AppFooter = (props) => {

    return (
        <div className="layout-footer">
            <div className="footer-logo-container">
                {/* <img id="footer-logo" src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} alt="atlantis-layout" />
                <span className="app-name">ATLANTIS</span> */}

                {/* <Link to="/change-log">V{props.version?props.version[0].version_name:''}</Link> */}
                
                <span className="copyright">&#169; CIPL {new Date().getFullYear()} </span>
            </div>
        </div>

    )
}

export default AppFooter;