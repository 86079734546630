import React, {useEffect, useState, useRef} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { srBodyTemplate, CommonBodyForTable } from '../Common/CommonComponents';
import ApiServices from '../../service/api/ApiServices';
const apiServices = new ApiServices();
// ================================================================================================
// ================================================================================================

export const ProductMasterNewTab = () => {

	const [loading, setLoading] = useState(true);
	const [globalFilter, setGlobalFilter] = useState('');
	const [productMaster, setProductMaster] = useState([])
	const dt = useRef(null);

	useEffect(() => {
		getData()
	}, [])

	const getData = async() =>{
		const response = await apiServices.getProductMaster({});
		console.log(response?.data?.data);
		setProductMaster(response?.data?.data);
		setLoading(false)
	}

	return (
		<>
			<DataTable ref={dt} value={productMaster}
                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Products"
                globalFilter={globalFilter} emptyMessage="No Products Found." loading={loading} >
                <Column field="srid" header="Sr.No." body={srBodyTemplate} exportable={false} style={{ width: '4rem' }} ></Column>
                <Column field="materialName" header="Beverage Name" body={CommonBodyForTable}></Column>
                <Column field="itemName" header="Item Name" body={CommonBodyForTable}></Column>
                <Column field="materialNature" header="Nature" body={CommonBodyForTable}></Column>
                <Column field="weight" header="Weight (Gms)" body={CommonBodyForTable}></Column>
                <Column field="brand" header="Brand" body={CommonBodyForTable}></Column>
                <Column field="status" header="Status" body={CommonBodyForTable}></Column>
                {/* <Column header="Actions" body={actionBodyTemplate}></Column> */}
            </DataTable>
		</>
	)
}
