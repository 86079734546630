import React, {useEffect, useState} from 'react'
import { SubAnalyticalStatForTickets } from '../NewAnalytics.js/SubAnalyticalStat';
// Services
import ApiServices from '../../service/api/ApiServices'
const apiServices = new ApiServices();
// ===========================================================================

export const CvesOrderTracker = () => {

    const [loading, setLoading] = useState(true);
    const [orderStats, setOrderStats] = useState(null);

    useEffect(() => { getData({})}, [])

    const getData = async(data) =>{
        const response = await apiServices.cvesOrderStats(data);
        setOrderStats(response?.data?.response_obj);
        setLoading(false)
    }

    return (
        <>
            <div className="p-grid crud-demo">    
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Total Orders" value={orderStats?.TOTAL_ORDERS} loading={loading} />            
                </div>
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Placed Orders" value={orderStats?.ORDER_PLACED} loading={loading} />            
                </div>
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Confirmed Orders" value={orderStats?.ORDER_CONFIRMED} loading={loading} />            
                </div>
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Delivered Orders" value={orderStats?.ORDER_DELIVERED} loading={loading} />            
                </div>
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Dispatched Orders" value={orderStats?.ORDER_DISPATCHED} loading={loading} />            
                </div>
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Installed CVES" value={orderStats?.MACHINE_INSTALLED} loading={loading} />            
                </div>
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Total Agreement Signed" value={orderStats?.AGREEMENT_SIGNED} loading={loading} />            
                </div>
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Payment Confirmed" value={orderStats?.PAYMENT_CONFIRMED} loading={loading} />            
                </div>
            </div>
        </>
    )
}
