import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';

const AppBreadcrumb = (props) => {

    const [nowTime, setNowTime] = useState();
    const location = useLocation()

    const getTime = () =>{
        const dateVariable = new Date().toLocaleString("hi-IN");
        setNowTime(dateVariable)
    }

    // useEffect(()=>{
    //     setInterval(getTime, 1000)
    // },[])

    const activeRoute = props.routes.filter(route => {
        return route.label.replace(/\s/g, '').toLowerCase() === location.pathname.toLowerCase().replace(/\s/g, '').slice(1)
    })

    let items;

    if (location.pathname === '/') {
        items = [{ label: 'Dashboard' }, { label: '' }]
    }
    else if (!activeRoute.length) {
        items = [{ label: '' }, { label: '' }];
    }
    else {
        items = [
            { label: activeRoute[0].parent },
            { label: activeRoute[0].label }
        ]
    }

    const isStatic = () => {
        return props.menuMode === 'static';
    }

    // const home = { icon: 'pi pi-home', url: 'https://www.primefaces.org/primereact/showcase' }

    const changeTheme = (e) =>{
        if(e.value){
            props.changeColorScheme('dark')
        }else{
            props.changeColorScheme('light')
        }
    }

    return (

        <div className="layout-breadcrumb-container">
            <div className="layout-breadcrumb-left-items">
                {isStatic() && <button className="menu-button p-link" onClick={props.onMenuButtonClick} >
                    <i className="pi pi-bars"></i>
                </button>}
                
                {/* <button href="#" className="menu-pin p-link" onClick={props.onToggleMenu}>
                    {isOverlay() && <span className="pi pi-times"></span>}
                    {isSidebar() && !props.sidebarStatic && props.pinActive && <span className="pi pi-unlock"></span>}
                    {isSidebar() && props.sidebarStatic && props.pinActive && <span className="pi pi-lock"></span>}
                </button> */}

                <BreadCrumb model={items} className="layout-breadcrumb" />

            </div>
            <div className="layout-breadcrumb-right-items">
                {/* <button tabIndex="0" className="search-icon p-link" onClick={props.breadcrumbClick}>
                    <i className="pi pi-search"></i>
                </button> */}
                {/* <div className={classNames('search-wrapper', { 'active-search-wrapper': props.searchActive })} >
                </div> */}

                {/* <span className="p-input-icon-left p-mt-2 p-mx-3"><b>{nowTime}</b></span> */}

                <span className='p-mt-2 p-mx-3' >
                    <InputSwitch checked={props.colorScheme === 'dark' ? true : false } onChange={(e)=>{changeTheme(e)}} />
                </span>

                <span className="layout-rightmenu-button-desktop">
                    <Button label="Profile" icon="pi pi-user" className="layout-rightmenu-button" onClick={props.onRightMenuButtonClick}></Button>
                </span>

                <span className="layout-rightmenu-button-mobile">
                    <Button icon="pi pi-bookmark" className="layout-rightmenu-button" onClick={props.onRightMenuButtonClick}></Button>
                </span>
            </div>
        </div>
    )
}

export default AppBreadcrumb;