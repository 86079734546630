import React from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Timeline } from 'primereact/timeline';


export const TrackOrder = () => {

    const customEvents = [
        {
            status: 'Order Placed',
            date: '15/10/2020 10:30',
            icon: 'pi pi-shopping-cart',
            color: '#9C27B0',
            image: 'game-controller.jpg',
            description: 'Your order for machine was placed successfully'
        },
        {   
            status: 'Order Accepted',
            date: '15/10/2020 14:00',
            icon: 'pi pi-cog', 
            color: '#673AB7' ,
            description: 'Your order has been accepted'
        },
        {   
            status: 'Order Dispatched', 
            date: '15/10/2020 16:15', 
            icon: 'pi pi-envelope', 
            color: '#FF9800' ,
            description: 'Your order has been dispatched'

        },
        { 
            status: 'Order Delivered', 
            date: '16/10/2020 10:00', 
            icon: 'pi pi-check', 
            color: '#607D8B',
            description: 'Your machine has been delivered successfully. Our technoman will visit for installation.'

        }
    ];


    const customizedContent = (item) => {


        return (
            <Card title={item.status} subTitle={item.date}>
                {/* { item.image && <img src={`assets/demo/images/product/${item.image}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.name} width={200} className="p-shadow-2" />} */}
                <p>{item.description}</p>
                {/* <Button label="Read more" className="p-button-text"></Button> */}
            </Card>
        );
    };

    const customizedMarker = (item) => {
        return (
            <span className="custom-marker p-shadow-2" style={{ backgroundColor: item.color }}>
                <i className={classNames('marker-icon', item.icon)}></i>
            </span>
        );
    };

    return <div className="p-grid timeline-demo">
        <div className="p-col-12">
            <div className="card">
                <Timeline value={customEvents} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
            </div>
        </div>
    </div>
}