
export const TableWrapper = (props) =>{
    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    {props.children}
                </div>
            </div>
        </div>
    )
}
