import { useEffect, useState, useRef, memo } from "react"
import { Button } from "primereact/button"
import { Chart } from "primereact/chart"
import { ProgressSpinner } from "primereact/progressspinner"
import { exportReport } from "../Common/CommonFunctions"
import { barDataForBarChart } from "../Common/CommonFunctions"
import { barOptionsForBarChart } from "../Common/CommonFunctions"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { CommonBodyForTable } from "../Common/CommonComponents"
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices();

const DayWiseTicketChart = (props) =>{

    const [loading, setLoading] = useState(true)
    const [ticketData,setTicketData] = useState(null)
    const [dataLabels, setDataLabels] = useState([])
    const [dataValues, setDataValues] = useState([])

    const dt = useRef(null);


    useEffect(()=>{
        getInitialise(props.selectedDate)
    },[props.selectedDate])

    const getInitialise = (data) =>{
        if(data){
            const payload = {...data,"dayWise": true}
            getData(payload)
        }else{
            getData({ "dayWise": true })
        }
    }

    const getData = async(data) =>{
        setLoading(true)
        const response = await apiServices.ticketPerformance(data);
        const arr = []
        const res = response?.data?.data.map((x)=>{ 
            x._id ? arr.push({...x,_id:x._id}) : arr.push({...x,_id:'Other'})
        })

        // const sorted = arr?.sort((a, b) => parseFloat(b.ticketCount) - parseFloat(a.ticketCount));
        const sorted = arr;
        console.log(sorted);
        setTicketData(sorted)
        const label = sorted.map((x)=>{ return x._id})
        const values = sorted.map((x)=>{ return x.ticketCount})
        setDataLabels(label)
        setDataValues(values)
        setLoading(false)
    }

    const exportCSV = () =>{ exportReport(dt)}
    const barData = barDataForBarChart(dataLabels,dataValues,'No of Tickets')
    const barOptions = barOptionsForBarChart('Date','No of Tickets')

    const documentStyle = getComputedStyle(document.documentElement);
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');

    const lineData = {
        labels: dataLabels,
        datasets: [
            {
                label: 'No of Tickets',
                data: dataValues,
                fill: true,
                borderColor: '#D21111',
                tension: 0.4,
                backgroundColor: '#FC9E9D'
            }
        ]
    };

    const lineOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                display:false
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: 'rgba(160, 167, 181, .3)'
                },
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: 'rgba(160, 167, 181, .3)'
                },
                title: {
                    display: true,
                    text: 'No. of Tickets'
                }
            }
        }
    };

    return (
        <>
            <div className="card widget-visitor-graph">
            <div className="card-header">
                <span></span>
                <div className='p-col-sm-4'>
                    <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={exportCSV} />
                </div>
            </div>

            {
                loading ? 
                <div className='spinner-div'>
                    <center><ProgressSpinner strokeWidth={7} animationDuration='4s'/></center>
                </div>
                :
                <>
                    {
                        (dataValues.length > 0 && dataLabels.length > 0) ?
                        <center>
                            {/* <Chart type="bar" data={barData} options={barOptions}/> */}
                            <Chart type="line" data={lineData} options={lineOptions} />
                            {/* <Chart type="doughnut" data={chartData} options={lightOptions} style={{ position: 'relative', width: '40%' }} /> */}
                        </center>
                        :
                        <center><h4 className="p-error p-mt-5">No Data Found</h4></center>
                    }
                </>
            }
            </div>

            <DataTable ref={dt} value={ticketData} dataKey="id" className="datatable-responsive" emptyMessage="No Data found." exportFilename={`Date Wise Ticket Reports`} style={{display:'none'}} >
                <Column field="_id" header="Date" body={CommonBodyForTable} ></Column>
                <Column field="ticketCount" header="Ticket Count" body={CommonBodyForTable} ></Column>
            </DataTable>


        </>
    )
}


export default memo(DayWiseTicketChart);