import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
// Components
import { Products } from "./Products";
// Common
import { commonDialogFooter } from "../Common/CommonComponents";
import { machineData } from "./Constants";
// Services
import ApiServices from "../../service/api/ApiServices";
import { successToast } from "../Common/CommonFunctions";
const apiServices = new ApiServices()
// LocalStorage
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ==============================================================================================
// ==============================================================================================

export const RefillOrderDash = () =>{

    const [facilityData,setFacilityData] = useState(null);
    const [selectedFacility,setSelectedFacility] = useState(null);
    const [openSelectionDialog,setOpenSelectionDialog] = useState(false);
    const [accounts,setAccounts] = useState([]);
    const [selectedAccount,setSelectedAccount] = useState(null);
    const [comfirmSelection,setConfirmSelection] = useState(false);
    const [productPayload,setProductPayload] = useState({});
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [brandAnabled, setBrandAnabled] = useState([]);
    const toast = useRef(null);

    useEffect(()=>{
        // getfacilities()
        // getAccounts()
        getMachineFilter()
    },[])

    const getMachineFilter = () =>{

        if(initialValue?.userType === 'customer'){

            const arr=[];

            initialValue?.brand?.map((x)=>{
                console.log(x);
                const data = machineData.filter((y)=>{ return y.brand === x });
                if(data?.length > 0){
                    arr.push(data[0]);                    
                }
            })

            setBrandAnabled(arr)

        }else{
            setBrandAnabled(machineData)
        }
    }

    const getAccounts = async(param) =>{
        if(initialValue?.userType === "customer"){

            const response = await apiServices.getuserById({userid : initialValue?.userid});
            const arr = response?.data?.data?.map((x)=>{ return {...x, cno : x?.cno?.replace("+91","")} })
            console.log('response', response);
            arr?.length > 0 &&  setSelectedAccount(arr[0])
            getfacilities(initialValue?.userid)

        }else{

            const data = { 
                "userid": initialValue?.userid, 
                "d_id": initialValue?.d_id, 
                "userType": initialValue?.userType, 
                "brand": [param?.brand], 
                "index": 0, 
                "count": 300 
            }

            const response = await apiServices.activeCVESUsers(data);
            const dropdownForAcc = response?.data?.data.map((x)=>{ 
                return {
                    ...x,
                    compnyName:x.billing_details?.company, 
                    dropDownLabel : `${x.billing_details?.company} / ${x.name} ${x.lastName} / ${x.cno}` 
                } 
            })

            const sortedArr =  dropdownForAcc.sort((a, b) => a.compnyName.trim() !== b.compnyName.trim() ? a.compnyName.trim() < b.compnyName.trim() ? -1 : 1 : 0);
            setAccounts(sortedArr)
        }
    }

    const getfacilities = async(userid) =>{
        const response = await apiServices.getFacility({userid: userid});
        console.log(response?.data?.data);
        setFacilityData(response?.data?.data)
        response?.data?.data?.length === 1 ? setSelectedFacility(response?.data?.data[0]) : setSelectedFacility(null);
    }

    const changeAccount = (e) => {
        console.log(e.value);
        setSelectedAccount(e.value)
        getfacilities(e.value.userid)
    }

    const changeFacility = (e) =>{ 
        console.log(e.target.value);
        setSelectedFacility(e.target.value) 
    }

    const comfirmedSelection = () =>{
        setOpenSelectionDialog(false)
        setConfirmSelection(true)

        const payload = { 
            "brand":"CHERISE", 
            "offset": selectedAccount?.beverages_offset, 
            "price_per_cup": selectedAccount?.cup_cost, 
            "userid": selectedAccount?.userid, 
            "facid": selectedFacility?.facid,
            "billing_pattern": selectedAccount?.billing_pattern,
            "packet_cost": selectedAccount?.packet_cost,
            "speciality_rate": selectedAccount?.speciality_cost && Number(selectedAccount?.speciality_cost) != 0 ? selectedAccount?.speciality_cost: selectedAccount?.cup_cost
        }

        setProductPayload(payload)
    }

    const undoChanges = () =>{
        setConfirmSelection(false)
        setSelectedAccount(null)
        setSelectedFacility(null)
    } 

    const openDialog = (data) =>{
        // console.log(data?.brand);
        setSelectedBrand(data?.brand)
        if(data?.brand === 'TAPRI' || data?.brand === 'TKAFFE'){
            console.log(data);
            getAccounts(data)
            setDefaultDialog()
            setOpenSelectionDialog(true)
        }else if(data?.brand === 'MERI_TAPRI'){
            setOpenSelectionDialog(true)
            getAccounts(data)
        }else{
            successToast(toast,'refill order for this is not available', 'Coming Soon');
        }
        hideDialogsValue()
    }

    const hideDialogsValue = () =>{
        setSelectedAccount(null)
        setSelectedFacility(null)
        setFacilityData(null)
    }

    const setDefaultDialog = () =>{
        setOpenSelectionDialog(false)
        setSelectedAccount(null)
        setSelectedFacility(null)
        setFacilityData(null)
    }

    const header = (data) =>{ return ( <center> <img className="p-m-4" alt="Card" src={`assets/demo/images/Icon/${data.img}`} style={{width:'45%'}} /> </center> )} 
    const footer = (data) =>{ return ( <div className="flex flex-wrap justify-content-end gap-2"> <center> <h5>{data.name}</h5> </center> </div> ) } 
    const selectionDialogFooter = commonDialogFooter('Cancel',setDefaultDialog,'OK',comfirmedSelection, selectedFacility)

    return (
        <>
            <Toast ref={toast} position='center' />
            {
                (!comfirmSelection) &&
                <div className="card">
                    <h5 className="p-m-3">Select Category</h5>
                    <div className="card" >
                    <div className="p-grid" >
                        {
                            brandAnabled.map((x,i)=>{
                                return (
                                    <div className="p-col-12 p-md-3" key={i} style={{cursor: 'pointer'}}>
                                        <div onClick={()=>{openDialog(x)}}> <Card header={header(x)} footer={footer(x)} className="md:w-25rem" style={{background : '#00544d12', borderRadius : '15px'}}></Card> </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    </div>
                </div>
            }
           
            { comfirmSelection && <Products selectedBrand={selectedBrand} selectedFacility={selectedFacility} selectedAccount={selectedAccount} productPayload={productPayload} setConfirmSelection={setConfirmSelection} /> }

            {/* <Button className="p-ml-3" icon="pi pi-undo" label="Go Back" severity="warning" text onClick={undoChanges} /> */}

            <Dialog visible={openSelectionDialog} style={{ width: '650px' }} header={ initialValue?.userType == "customer" ? "Select Facility" : "Select Account And Facility"} modal footer={selectionDialogFooter} onHide={setDefaultDialog} className='p-fluid' blockScroll={true}>
                {
                    initialValue?.userType != "customer" &&
                    <div className="p-field">
                        <label htmlFor="acc">Account</label>
                        <Dropdown id="acc" value={selectedAccount} onChange={changeAccount} autoFocus options={accounts} optionLabel="dropDownLabel" placeholder="Select Account" filter></Dropdown>
                    </div>
                }
                <div className="p-field">
                    <label htmlFor="fac">Facility</label>
                    <Dropdown id="fac" value={selectedFacility} onChange={changeFacility} options={facilityData} optionLabel="facname" placeholder="Select Facility" filter className="multiselect-custom" />
                </div>
                { selectedFacility && <span> <p> <b>Facility Address</b> : {selectedFacility?.address?.address}</p></span> }
            </Dialog>
            
        </>
    )
}

