import React, {useEffect, useRef, useState} from 'react'
import { Chart } from 'primereact/chart';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Chip } from 'primereact/chip';
import { DateBodyForTable, commonCloseDialogFooter } from '../Common/CommonComponents';
import { srBodyTemplate, CommonBodyForTable } from '../Common/CommonComponents';
import { durationOptions } from './Constant';

// ========================================================================================
import ApiServices from '../../service/api/ApiServices'
import CommonCal from '../../service/common/CommonCal';
import { exportReport } from '../Common/CommonFunctions';
const apiServices = new ApiServices();
const commonCal = new CommonCal();

// =========================================================================================
// =========================================================================================

export const HeaterMaintenance = () => {

    const [loading, setLoading] = useState(true)
    const [globalFilter, setGlobalFilter] = useState("");
    const [sensorLoading, setSensorLoading] = useState(true)
    const [selectedMch, setSelectedMch] = useState({ displayName : 'All'})
    const [machineOptions, setMachineOptions] = useState(null)

    const [chartLabels, setChartLabels] = useState([])
    const [innerSensorValues, setInnerSensorValues] = useState([])
    const [outerSensorValues, setOuterSensorValues] = useState([])

    const [sensorDialog, setSensorDialog] = useState(false)
    const [selectedSensorBar, setSelectedSensorBar] = useState(null)
    const [sensorData, setSensorData] = useState(null)
    const [selectedDuration, setSelectedDuration] = useState({name:'All',code:'all'})
    const [datesData, setDatesData] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [isInner, setIsInner] = useState(false)

    const [dataForChart, setDataForChart] = useState(null)
    const [customFilterDialog, setCustomFilterDialog] = useState(false)
	const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    const [statData, setStatData] = useState({});
    const dt = useRef(null);
    const dataReport = useRef(null);

    useEffect(() => {
        getMachines()
        getDates()
    }, [])

    const getDates = () =>{
        const response = commonCal.getDates()
        setDatesData(response)
    }

    const getMachines = async() =>{
        const payload = {  }
        const response = await apiServices.findMachines(payload);
        console.log(response?.data?.data);

        const filData = response?.data?.data?.map((x)=>{
            const id = x?.id;
            const subId = x?.subid;
            const siteName = x.siteName
            return {...x, displayName : `${id}/${subId ? subId : 'NA'}/${siteName ? siteName : 'CIPL' }`}
        })

        // const result = filData.filter((machine)=>{ return machine.siteName !== ''; });
        const result = filData.map((machine)=>{ return {...machine, siteName : machine.siteName === '' ? 'CIPL' : machine.siteName }});
        const options = [{ displayName : 'All'},...result];

        setMachineOptions(options)

        if(response?.data?.data?.length > 0){
            getData({})
            // setSelectedMch(response?.data?.data[0])
        }
    }

    const getData = async(data) =>{
        setLoading(true)
        const payload = data;
        const response = await apiServices.getHeaterChartData(payload);
        console.log(response?.data?.data);

        if(response?.data?.data?.length > 0){
            
            const newInner = response?.data?.data?.map((x)=>{ return { id : x._id, innerCount : x.count } })

            const dateLabels = [...newInner]
            const mapppedDates = dateLabels.map((x)=>{ return x.id});
            const uniqueDates = [...new Set(mapppedDates)];

            const concatenatedArray = dateLabels.reduce((acc, obj) => {
            const existingObj = acc.find(item => item.id === obj.id);
                if (existingObj) {
                    Object.assign(existingObj, obj);
                } else {
                    acc.push(obj);
                }
                return acc;
            }, []);

            console.log(concatenatedArray);
            setDataForChart(concatenatedArray)

            const innerValues = concatenatedArray?.map((x)=>{ return x.innerCount || 0 })

            setChartLabels(uniqueDates)
            setInnerSensorValues(innerValues)
            getStatsData(concatenatedArray)
        }else{
            setDataForChart(null)
            setChartLabels([])
            setInnerSensorValues([])
        }
        setLoading(false)
    }

    const getStatsData = (data) =>{
        if(data){
            console.log(data);
            const inner = data?.reduce((acc, curr) =>{ return (curr.innerCount || 0) + acc }, 0);
            setStatData( { inner } )
        }
    }


    const changeMachine = (e) =>{
        setSelectedMch(e.value)
        // e.value.displayName === 'All' ? getData() : getData({ id : e.value?.id });
        if(e.value.displayName === 'All'){
            selectedDate ? getData({}) : getData(selectedDate);
        }else{
            selectedDate ? getData({ id : e.value?.id,...selectedDate}) : getData({ id : e.value?.id});
        }
    }

    const changeDuration = (e)=> { 

        e.value.code !== 'custom' && setSelectedDuration(e.value)
        const today = { startDate: datesData.startToday, endDate: datesData.endToday };
        const yesterday = { startDate: datesData.YestStart, endDate:datesData.EndStart };
        const thisWeek = { startDate: datesData.weekstart, endDate:datesData.weekEnd };
        const thisMonth = { startDate: datesData.monthStart, endDate:datesData.endThisMonth };
        const lastMonth = { startDate: datesData.lastStart, endDate:datesData.lastend };
        
        switch (e.value.code) {
            case 'today':
                setSelectedDate({ date:today})
                selectedMch?.displayName === 'All' ? getData({ date:today}) : getData({ id : selectedMch.id, date:today });
                break;
            case 'yesterday':
                setSelectedDate({ date:yesterday})
                selectedMch?.displayName === 'All' ? getData({ date:yesterday}) : getData({ id : selectedMch.id, date:yesterday });
                break;
            case 'this_week':
                setSelectedDate({ date:thisWeek})
                selectedMch?.displayName === 'All' ? getData({ date:thisWeek}) : getData({ id : selectedMch.id, date:thisWeek });
                break;
            case 'this_month':
                setSelectedDate({ date:thisMonth})
                selectedMch?.displayName === 'All' ? getData({ date:thisMonth}) : getData({ id : selectedMch.id, date:thisMonth });
                break;
            case 'last_month':
                setSelectedDate({ date:lastMonth})
                selectedMch?.displayName === 'All' ? getData({ date:lastMonth}) : getData({ id : selectedMch.id, date:lastMonth });
                break;
            case 'custom':
                setCustomFilterDialog(true);
                break;
            default:
                setSelectedDate(null)
                selectedMch?.displayName === 'All' ? getData({}) : getData({ id : selectedMch.id })
                break;
        }

    }

    const getRequiredData = (data) =>{
        const date = chartLabels[data[0]?.index];
        
       

        if(date){
            setSensorDialog(true)
            setSelectedSensorBar(date)
            console.log(date);
            const darr = date.split("-");
            const first = new Date(parseInt(darr[2]),parseInt(darr[1])-1,parseInt(darr[0]));
            const firstDate = first.toISOString()
            const newLast = new Date(parseInt(darr[2]),parseInt(darr[1])-1,parseInt(darr[0])).setHours(23, 59, 59, 0);
            const lastDate = new Date(newLast).toISOString();

            console.log(`${firstDate} And ${lastDate}`);

            const payloadData = {
                "date": { "startDate": firstDate, "endDate": lastDate },
                "id": selectedMch?.displayName === 'All' ? null : selectedMch.id
            }

            getSensorDetails(payloadData)
        }
    }

    const getSensorDetails = async(payload) =>{
        setSensorLoading(true)
        const response = await apiServices.getDetailedHeater(payload);
        console.log(response?.data?.data);
        setSensorData(response?.data?.data);
        setSensorLoading(false)
    }

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    const stackedData = {
        labels: chartLabels,
        datasets: [
            {
                type: 'bar',
                label: 'Heater Failure',
                backgroundColor: '#07564E',
                data: innerSensorValues,
                borderRadius: 5
            }
        ]
    };
    const stackedOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                },
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                },
                title: {
                    display: true,
                    text: "No. of Sensor failure's"
                }
            }
        },
        onClick: function (e, clickedElement){
            getRequiredData(clickedElement)
        }
    };

    const leftToolbarTemplate = () =>{
        return (
            <div className='p-grid p-mt-1' style={{display : 'flex'}}>
                <div className='p-col-sm-4'>
                    <Chip label={`Total Heater Failure : ${statData?.inner || '-'}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                </div>
            </div>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="CVES" />
                    <Dropdown id='select-mc' value={selectedMch} onChange={changeMachine} options={machineOptions} optionLabel="displayName" placeholder="Select CVES" filter className="p-mr-2 multiselect-custom " />
                </div>

                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="Duration" />
                    <Dropdown id='select-duration' value={selectedDuration} onChange={changeDuration} options={durationOptions} optionLabel="name" placeholder="Custom" filter className="p-mr-2 multiselect-custom " />
                </div>

                <div className="p-inputgroup">
                    <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mx-2' onClick={exportCSVMaster}/>
                </div>

            </>
        )
    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                {/* <h6 className="p-mt-3 p-mr-5">{`${isInner ? 'Inner' : 'Outer'} Sensor Failure Data`}</h6> */}
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    {/* <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV}/> */}
                </div>
            </span>
        </div>
    )

    
    const exportCSVMaster = () =>{ exportReport(dataReport) }
    const exportCSV = () => { exportReport(dt) }
    const hideDetailed = () =>{ setSensorDialog(false) }
    const sensorFooter = commonCloseDialogFooter('Close', hideDetailed)

    const handleSubmit = async(e) =>{
        setCustomFilterDialog(false)
        e.preventDefault()

		const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
		const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
		const cust_start = new Date(startt).toISOString();
		const cust_end = new Date(endd).toISOString();
		const date = { startDate: cust_start, endDate:cust_end };
		setSelectedDate(date)
        console.log(date);
        // setDateAndGetData(date)
        selectedMch?.displayName === 'All' ? getData({ date:date}) : getData({ id : selectedMch.id, date:date })
        setSelectedDuration(null)
    }

    const hideCustomDialog = () =>{
        setCustomFilterDialog(false);
		setCustomStart('')
		setCustomEnd('')
    }

    const customFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
            <button className="p-button p-button-success" type="submit" form="custom-form" ><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
        </>
    )

    const CommonBodyForTable1 = (data, props) =>{

        let value;
        if(data[props.field] == 'permanent'){
            value = 'Commercial'
        }else if(data[props.field] == 'demo'){
            value = 'Demo'
        }else{
            value = data[props.field];
        }

        return ( 
            <> 
                <span className="p-column-title">{props.header}</span>{value ? value : 'NA'} 
            </> 
        )
    }

    return (
        <>  
            <div className='card'>
                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            </div>
            {
                loading ?
                <div className='p-mt-5'>
                    <center>
                        <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
                    </center>
                </div>
                :
                <>
                    <Chart type="line" data={stackedData} options={stackedOptions} height='550' />
                </>
            }

            <DataTable ref={dataReport} value={dataForChart} dataKey="id" exportFilename={`Heater Failure Maintenance Report`} style={{display : 'none'}} >
                <Column field="id" header="Date" body={CommonBodyForTable} sortable ></Column>
                <Column field="innerCount" header="Heater Failure Count" body={CommonBodyForTable} sortable ></Column>
                {/* <Column field="outerCount" header="Outer Sensor" body={CommonBodyForTable} sortable ></Column> */}
            </DataTable>

            <Dialog className="p-fluid" header={`Heater Failure Detailed (${selectedSensorBar})`} visible={sensorDialog} style={{ width: '40vw' }} footer={sensorFooter} onHide={hideDetailed} maximizable={true} blockScroll={true}>
                <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                    <DataTable ref={dt} value={sensorData}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                        emptyMessage="No data found." exportFilename={`Records`} loading={sensorLoading} globalFilter={globalFilter} header={header} >
                        <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                        <Column field="id" header="CVES Id" body={CommonBodyForTable} sortable ></Column>
                        <Column field="subid" header="Sub Id" body={CommonBodyForTable} sortable ></Column>
                        {/* <Column field="subscription_type" header="Subscription Type" body={CommonBodyForTable1} sortable ></Column> */}
                        <Column field="createdAt" header="Date & Time" body={DateBodyForTable} sortable ></Column>
                    </DataTable>
                    </div>
                </div>
                </div>
            </Dialog>

            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter" footer={customFooter}  onHide={hideCustomDialog} modal className="p-fluid" maximizable={true} blockScroll={true}>
                <form onSubmit={handleSubmit} id="custom-form" className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From </label>
                            <InputText id="from" type="date" name='start_date'  value={customStart} onChange={(e) => {setCustomStart(e.target.value) } } required autoFocus/>
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' min={customStart} value={customEnd} onChange={(e) => {setCustomEnd(e.target.value)}} required/>
                        </div>
                    </div>
                </form>
            </Dialog>

        </>

       
    )
}
