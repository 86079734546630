import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Chip } from 'primereact/chip';
// Components
import { TransactionStats } from './TransactionStats';
// Service
import ApiServices from '../../service/api/ApiServices';
import CommonCal from '../../service/common/CommonCal';
import { DateBodyForTable } from '../Common/CommonComponents';

const apiServices = new ApiServices();
const commonCal = new CommonCal();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

export const RfidTransaction = props => {

    const [loading, setLoading] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState({ name: 'Today', code: 'today' });
    const [selectedDurationDates, setSelectedDurationDates] = useState(null);
    const [allMachine, setAllMachine] = useState([{ displayLabel: 'All' }]);
    const [dates, setDates] = useState(null);
    const [customFilterDialog, setCustomFilterDialog] = useState(false);
    const [selectedCustomDate, setSelectedCustomDate] = useState(null)
    const [rfidTransData, setRfidTransData] = useState([])
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        getInit(props.allMachines)
    }, [props.allMachines])


    const getData = async (data) => {
        console.log(data);
        const response = commonCal.getDates()
        setDates(response)
        // setSelectedMachine(allMachine[0])
        setSelectedDurationDates({ startDate: response.startToday, endDate: response.endToday })

        if (data) {
            setSelectedMachine(data)
            getEmployeeCon({ machineid: data?.id, date: { startDate: response.startToday, endDate: response.endToday } })
        } else {
            setSelectedMachine(allMachine[0])
            getEmployeeCon({ date: { startDate: response.startToday, endDate: response.endToday } })
        }

    }

    const getEmployeeCon = async (data) => {
        console.log(data);
        const response = await apiServices.employeeConsumption(data);
        const arr = [];
        response?.data?.data?.map((x) => {
            const data1 = {
                ...x,
                EmployeeName: x.emp.name,
                AssignedCupCount: x.emp.cup_count.halfcup,
                cupsCount: x.cups.half,
                // date:new Date(x.date).toLocaleString()
            }
            arr.push(data1)
        })



        setRfidTransData(arr)
        setLoading(false)
    }

    // useEffect(()=>{
    //     props.allMachines && setAllMachine([...allMachine,...props.allMachines])
    //     getData(props?.allMachines?.length > 1 ? props?.allMachines[0] : setLoading(false))
    // },[props.allMachines])


    const getInit = (data) => {
        console.log(data);
        if (data?.length > 0) {

            if (initialValue?.userType === 'customer' || initialValue?.userType === 'facility' || initialValue?.userType === 'distributor') {
                setAllMachine([...data])
                getData(data[0])
            } else {
                setAllMachine([...allMachine, ...data])
                getData()
            }
        }
    }

    const changeMachine = (e) => {
        setLoading(true)
        setSelectedMachine(e.value)
        getEmployeeCon({ machineid: e.value.id, date: selectedDurationDates })
    }

    const changeCustomDate = e => {
        setSelectedCustomDate({ ...selectedCustomDate, [e.target.name]: e.target.value })
    }

    const customSubmit = (e) => {
        e.preventDefault()
        console.log("dunm");
        const startt = new Date(selectedCustomDate.start_date).setHours(0, 0, 0, 0);
        const endd = new Date(selectedCustomDate.end_date).setHours(23, 59, 59, 0);
        const date = { startDate: new Date(startt).toISOString(), endDate: new Date(endd).toISOString() };
        const data = { machineid: selectedMachine?.id, date }
        console.log(data);
        getEmployeeCon(data)
        setSelectedDurationDates(date)
        setCustomFilterDialog(false)
        setSelectedDuration(null)
    }

    const clearCustom = () => {
        setCustomFilterDialog(false);
        setSelectedCustomDate(null)
    }

    const changeDuration = (e) => {
        setLoading(true);
        e.value.code !== 'custom' && setSelectedDuration(e.value);

        switch (e.value.code) {

            case 'till_date': {
                const data = { machineid: selectedMachine?.id }
                getEmployeeCon(data)
                setSelectedDurationDates(null)
                break;
            }
            case 'today': {
                const data = { machineid: selectedMachine?.id, date: { startDate: dates.startToday, endDate: dates.endToday } }
                getEmployeeCon(data)
                setSelectedDurationDates(data.date)
                break;
            }
            case 'yesterday': {
                const data = { machineid: selectedMachine?.id, date: { startDate: dates.YestStart, endDate: dates.EndStart } }
                getEmployeeCon(data)
                setSelectedDurationDates(data.date)
                break;
            }
            case 'this_week': {
                const data = { machineid: selectedMachine?.id, date: { startDate: dates.weekstart, endDate: dates.weekEnd } }
                getEmployeeCon(data)
                setSelectedDurationDates(data.date)
                break;
            }
            case 'this_month': {
                const data = { machineid: selectedMachine?.id, date: { startDate: dates.monthStart, endDate: dates.endThisMonth } }
                getEmployeeCon(data)
                setSelectedDurationDates(data.date)
                break;
            }
            case 'last_month': {
                const data = { machineid: selectedMachine?.id, date: { startDate: dates.lastStart, endDate: dates.lastend } }
                getEmployeeCon(data)
                setSelectedDurationDates(data.date)
                break;
            }
            case 'custom':
                setCustomFilterDialog(true)
                break;

            default: {
                const data = { machineid: selectedMachine?.id, date: { startDate: dates.startToday, endDate: dates.endToday } }
                getEmployeeCon(data)
                setSelectedDurationDates(data.date)
                break;
            }
        }
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    // const leftToolbarTemplate = () => (<><Chip label={`Total No. Of Rfid CTVM : ${allMachine ? allMachine?.filter((x)=>{ return x.mchmode && x.mchmode.paymode.rfid == true}).length :'-'}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" /></>)
    const rightToolbarTemplate = () => (
        <>
            <div className="p-inputgroup p-mr-2">
                <Button className='custom-group-btn' label="CVES" />
                <Dropdown value={selectedMachine} onChange={changeMachine} options={allMachine} optionLabel="displayLabel" placeholder="Select CVES" filter
                    className="p-m-0 multiselect-custom" />
            </div>
            <div className="p-inputgroup ">
                <Button className='custom-group-btn' label="DURATION" />
                <Dropdown value={selectedDuration} onChange={changeDuration} options={props.durationOptions} optionLabel="name" placeholder="Custom" filter
                    className="p-m-0 multiselect-custom" />
            </div>
        </>
    )

    const srBodyTemplate = (rowData, props) => (<><span className="p-column-title">{props.header}</span>{props.rowIndex + 1}</>)
    const commonBodyTemplate = (rowData, props) => (<><span className="p-column-title">{props.header}</span>{rowData[props.field]}</>)

    const header = (
        <div className="table-header">
            <h6 className="p-m-0">RFID Transactions</h6>
            <span className="p-input-icon-left">
                <div className="p-inputgroup p-mr-2">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV} />
                </div>
            </span>
        </div>
    );

    return (

        <>
            {/* <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card"> */}
            <Toast ref={toast} />
            <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar>

            <div className="layout-dashboard">
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        {
                            (selectedMachine?.displayLabel == 'All') ?
                                <TransactionStats title={`Total CVES's`} value={allMachine ? allMachine?.filter((x) => { return x.mchmode && x.mchmode.paymode.rfid == true }).length : '-'} icon="pi pi-copy" loading={loading} />
                                :
                                <TransactionStats title={`CVES`} value={selectedMachine?.displayLabel} icon="pi pi-sliders-h" loading={loading} />
                        }
                    </div>
                    <div className="p-col-12 p-md-6">
                        <TransactionStats title={'Total RFID Transaction'} value={rfidTransData?.length} icon="pi pi-sliders-h" loading={loading} />
                    </div>
                </div>
            </div>

            <DataTable ref={dt} value={rfidTransData} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id" paginator rows={20} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Cup Consumptions"
                globalFilter={globalFilter} emptyMessage="No Data found." header={header} loading={loading} exportFilename={`RFID Transactions (${selectedMachine?.id})`}>
                <Column field="id" header="Sr.No." sortable body={srBodyTemplate} exportable={false}></Column>
                <Column field='EmployeeName' header="Employee Name" body={commonBodyTemplate}></Column>
                {(selectedMachine?.displayLabel == 'All') && <Column field='machineid' header="CVES Id" body={commonBodyTemplate}></Column>}
                <Column field="id" header="RFID Number" sortable body={commonBodyTemplate}></Column>
                <Column field="AssignedCupCount" header="Assign Cup Count" body={commonBodyTemplate} sortable></Column>
                <Column field="cupsCount" header="Cups" sortable body={commonBodyTemplate}></Column>
                <Column field="date" header="Date & Time" sortable body={DateBodyForTable}></Column>
            </DataTable>
            {/* </div>
            </div>
        </div> */}


            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter" onHide={() => { clearCustom() }} modal className="p-fluid">
                <form onSubmit={customSubmit}>
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From</label>
                            <InputText id="from" type="date" name='start_date' value={selectedCustomDate?.start_date} onChange={changeCustomDate} required autoFocus />
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' value={selectedCustomDate?.end_date} onChange={changeCustomDate} required />
                        </div>
                    </div>
                    <div className="p-dialog-footer p-mt-4">
                        <button className="p-button p-button p-button-danger" onClick={() => { clearCustom() }}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
                        <button className="p-button p-button-success" type="submit"><span className="p-button-icon p-c pi pi-check p-button-icon-left"></span><span className="p-button-label p-c">Submit</span><span className="p-ink"></span></button>
                    </div>
                </form>
            </Dialog>
        </>
    );
}
