import React, { useEffect, useState, useRef, useContext } from 'react';
import { Carousel } from 'primereact/carousel';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Slider } from 'primereact/slider';
import { TabView, TabPanel } from 'primereact/tabview';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Tag } from 'primereact/tag';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from '@fortawesome/free-solid-svg-icons';
// Components
import { commonCloseDialogFooter, commonDialogFooter } from '../Common/CommonComponents';
import { commonFormDialogFooter } from '../Common/CommonComponents';
import { OverviewSkeleton } from '../Common/CommonComponents';
import { srBodyTemplate, DateBodyForTable } from '../Common/CommonComponents';
import { CustomerAgreementDialog } from './CustomerAgreementDialog';
import { kettleCommandConfirmation, kettleConfigurationDefaults, manualUnlockReasons, technicalUnlockReasons, unlockCategory, unlockReasons } from './Constants';
import { CommonForDetails } from '../CRM/Leads';
import { findDuplicates } from '../Common/CommonFunctions';
// Services
import ApiServices from '../../service/api/ApiServices';
import { managementCheck } from '../../service/admin/superadmin';
// Socket
import io from "socket.io-client";
// Context
import { permissionContext } from '../../AppWrapper';
import { failureToast, getFormattedString, successToast } from '../Common/CommonFunctions';
import { InputTextarea } from 'primereact/inputtextarea';
import { TableWrapper } from '../../utilComponents/DataTableWrapper';
import { FullFormWrapper } from '../../utilComponents/Forms';
import { PanelOverview } from './PanelOverview';
import { OverviewActions } from './OverviewActions';

const ENDPOINT = process.env.REACT_APP_IOT;
const socket = io(ENDPOINT);
const apiServices = new ApiServices();
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ==========================================================================================
// ======================== OVERVIEW COMPONENT ========================================
// ==========================================================================================

export const Overview = (props) => {

    const rolePermissions = useContext(permissionContext);

    const [loading, setLoading] = useState(true);
    const [beverages, setBeverages] = useState([]);
    // const [headerImage, setHeaderImage] = useState([]);
    const [allMachines, setAllMachines] = useState(null);
    const [defaultMachine, setDefaultMachine] = useState([]);
    const [selectedMachineData, setSelectedMachineData] = useState([]);
    const [selectedMachineStat, setSelectedMachineStat] = useState([]);
    const [selectedModalData, setSelectedModalData] = useState([]);
    const [editDialog, setEditDialog] = useState(false);
    const [settingDialog, setSettingDialog] = useState(false);
    const [lockDialog, setLockDialog] = useState(false);
    const [barcodeDialog, setBarcodeDialog] = useState(false);
    const [calibrationData, setCalibrationData] = useState(null);
    const [lockBtn, setLockBtn] = useState(true);
    const [levelCBtn, setLevelCBtn] = useState(false);
    const [updatedLevel, setUpdatedLevel] = useState(null);
    const [refillDialog, setRefillDialog] = useState(false);
    const [dropdownItem, setDropdownItem] = useState(null);
    const [shoppeeDialog, setShoppeeDialog] = useState(false);
    const [barcodeTemplate, setBarcodeTemplate] = useState(null);
    const [machineAgreements, setMachineAgreements] = useState([]);
    const [motorRangeValue, setMotorRangeValue] = useState(100);
    const [selectedCategory, setSelectedCategory] = useState({ name: "CTVM", code: "Beverage Vending Machine" })
    const [canMaintenanceDialog, setCanMaintenanceDialog] = useState(false)
    const [userInfo, setUserInfo] = useState({ agreement_signed: true });

    // const [newArrayToTest,setNewArrayToTest] = useState(newArr);
    // Test
    const [dataHere, setDataHere] = useState(null);
    const [kettleCommandDialog, setKettleCommandDialog] = useState(false);
    const [commandKey, setCommandKey] = useState('');
    const [kettleCommand, setKettleCommand] = useState({});
    const [isStarted, setIsStarted] = useState(false);
    const [kettleConfigDialog, setKettleConfigDialog] = useState(false);
    const [kettleConfigObj, setKettleConfigObj] = useState(kettleConfigurationDefaults);
    const [creditData, setCreditData] = useState(null);
    const [isAllEmpty, setIsAllEmpty] = useState(false);
    const [isStandard, setIsStandard] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [viewToolTip, setViewToolTip] = useState(false)
    const [selectedCanister, setSelectedCanister] = useState(null)
    const [barcodeData, setBarcodeData] = useState(null)
    const [unlockReason, setUnlockReason] = useState('')
    const [consumptionClickDialog, setConsumptionClickDialog] = useState(false)
    const [consumptionClickPayload, setConsumptionClickPayload] = useState({})
    const [selectedUnlockCat, setSelectedUnlockCat] = useState(null)
    const [selectedReasons, setSelectedReasons] = useState(null);
    const [materialType, setMaterialType] = useState([]);

    const toast = useRef(null);
    const msgs = useRef(null);
    const op = useRef(null);

    const itemOptionTemplate = (option) => {
        return (
            <div className="country-item" style={{ display: 'flex' }}>
                <i className={`${option?.connectionMode !== "disConnected" ? 'pi pi-chevron-circle-up' : 'pi pi-chevron-circle-down'} p-mr-2 p-mt-1`}></i>
                <div>{option.displayLabel}</div>
            </div>
        );
    }


    const headerImage = [
        { "id": "4", "image": "9.png" },
        { "id": "2", "image": "7.png" },
        { "id": "3", "image": "8.png" },
        { "id": "1", "image": "6.png" },
        // { "id":"5", "image":"1.png" },
        // { "id":"6", "image":"2.png" },
        // { "id":"7", "image":"3.png" },
        // { "id":"8", "image":"4.png" },
        // { "id":"9", "image":"5.png" },
        // { "id":"1", "image":"6.png" }
    ]

    const carouselResponsiveOptions = [
        { breakpoint: "1024px", numVisible: 3, numScroll: 3 },
        { breakpoint: "768px", numVisible: 1, numScroll: 1 },
        { breakpoint: "560px", numVisible: 1, numScroll: 1, }
    ];

    const topResponsiveOptions = [
        { breakpoint: "1024px", numVisible: 1, numScroll: 1 },
        { breakpoint: "768px", numVisible: 1, numScroll: 1 },
        { breakpoint: "560px", numVisible: 1, numScroll: 1 }
    ];

    const defaultDropdownList = [{ name: 'Option 1' }, { name: 'Option 2' }];

    const allCategories = [
        { name: "CTVM", code: "Beverage Vending Machine" },
        { name: "TAPRI KETTLE", code: "Tapri Kettle" }
    ]

    function checkMachine(machine) {
        return machine.siteName !== '';
    }

    const getMachine = async (payload) => {

        if (managementCheck()) {
            const adminPayload = { app_cat: "Beverage Vending Machine" }
            var response = await apiServices.findMachines(adminPayload);
        } else {
            if(initialValue.userid == 'usr-em890h5mlrfz9pwk' && !payload){
                payload = { app_cat: 'Tapri Kettle' }
                setSelectedCategory({ name: "TAPRI KETTLE", code: "Tapri Kettle" })
            }   
            var response = await apiServices.findMachines(payload);
        }


        let newArr;

        if (managementCheck()) {
            const ioResponse = await apiServices.getIoMachines();
            console.log(ioResponse?.data?.data.length);
            newArr = [...response?.data?.data, ...ioResponse?.data?.data];
        } else {
            newArr = response?.data?.data ? [...response?.data?.data] : null;
        }

        const response1 = await apiServices.getUserReduced();
        

        const userred = response1?.data?.data.map((x) => { return x.userid })

        let arr = []
        newArr?.map((x) => { if (userred?.includes(x.customer)) { arr.push(x) } })

        const result = arr.filter(checkMachine);

        const resultedMachine = result.map((x) => {
            const subid = x.subid ? x.subid : 'CIPL';
            const address = response1.data.data.find(o => o.userid === x.customer)
            const newCompny = address?.billing_details?.company || `${address.name} ${address.lastName}`

            return {
                ...x,
                displayLabel: `${x.id}/ ${x.subid} / ${newCompny}`,
                address: address?.addr,
                cno: address?.cno,
                companyName: address?.billing_details?.company
            };
        });


        const resultedMachine1 = resultedMachine[0]?.stats.map((x) => {

            let value = Math.round(x.level ? x.level : 0);
            if (value > 0 && value <= 10) {
                value = 10;
            } else if (value > 10 && value <= 25) {
                value = 25;
            } else if (value > 25 && value <= 50) {
                value = 50;
            } else if (value > 50 && value <= 75) {
                value = 75;
            } else if (value > 75) {
                value = 100;
            }

            const materialTypes = materialType.find((i) => i.materialName === x.name)
            return {
                ...x,
                ...materialTypes,
                newLevel: value
            }
        })

        console.log(resultedMachine);

        setAllMachines(resultedMachine)
        setDefaultMachine(resultedMachine[0])
        setSelectedMachineData(resultedMachine[0])
        setSelectedMachineStat(resultedMachine1)
     

        if (resultedMachine[0]?.cans > 3) {
            const response3 = await apiServices.getCalibrationData({ id: resultedMachine[0].id });
            setCalibrationData(response3?.data?.data)
        }
        setLoading(false)

        const isEveryCanisterEmpty = resultedMachine1?.every(({ level }) => level <= 10 || level == undefined);
        setIsAllEmpty(isEveryCanisterEmpty)
    }

    const getMaterialType = async () => {
        const response = await apiServices.getMaterialColor();
        console.log('Material Color =>', response);
        setMaterialType(response?.data?.data);
    }

    // const getCreditLimits = async (data) => {
    //     setCreditData(null)
    //     const contact = data?.cno?.replace('+91', '');
    //     const response = await apiServices?.getCreditLimit({ "contact": contact });
    //     setCreditData(response?.data?.response_obj);
    // }

    const getSocketData = (data) => {

        socket.on("live_status/", data1 => {

            console.log("========Start Update==========");

            console.log(data1);

            const resultedMachine = data.map((x) => {
                if (x.id == data1.c_id) {
                    let vv = { ...x, connectionMode: data1.connection }
                    return vv;
                } else {
                    let vv = { ...x }
                    return vv;
                }
            })

            console.log(resultedMachine);

            setAllMachines(resultedMachine)

            console.log("========End Update==========");

        })
    }



    const getSocket = (resultedMachine2, response2) => {

        socket.on("live_status/", data1 => {

            console.log(data1);

            const resultedMachine = resultedMachine2.map((x) => {
                if (x.id == data1.c_id) {
                    let vv = { ...x, connectionMode: data1.connection }
                    return vv;
                } else {
                    let vv = { ...x }
                    return vv;
                }
            })

            console.log(resultedMachine);
            setLoading(false)
        })
    }
    

    useEffect(() => {
        getMachine();
        getMaterialType()
    }, []);




    const getUserData = async () => {
        setLoading(true)
        const response = await apiServices.userInfo(initialValue.userid);
        console.log(response?.data?.data)
        setUserInfo(response?.data?.data)
        setLoading(false)
    }


    const getSockettt = (data) => {

        let newData = data;
        socket.on("live_status/", data1 => {

            console.log(data1);

            for (let i = 0; i < newData.length; i++) {
                if (newData[i].id == data1.c_id) {
                    newData[i].connectionMode = data1.connection;
                }
            }



            setDataHere(null)
        })
    }


    const topHeaderTemplate = (img) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="p-mb-3">
                        <img style={{ width: '100%', height: '50%', borderRadius: '12px' }} src={`assets/demo/images/Icon/new-header/${img.image || '1.png'}`} className="product-image" />
                        {/* <img  src='assets/demo/images/Icon/dashboard-head.jpg' alt='dashboard-header' className="product-image" /> */}
                    </div>
                </div>
            </div>
        )
    }

    const imageClicked = () => { setRefillDialog(true) }
    const hideRefillDialog = () => { setRefillDialog(false) }
    const hideEditDialog = () => { setEditDialog(false) }
    const hideSettingDialog = () => { setSettingDialog(false) }
    const hideLockDialog = () => {
        setLockDialog(false)
        setUnlockReason('')
        setSelectedUnlockCat(null)
        setSelectedReasons(null)
    }
    const hideBarcodeDialog = () => { setBarcodeDialog(false) }
    const hideCanMainDialog = () => { setCanMaintenanceDialog(false) }
    const hideConfigDialog = () => {
        setKettleConfigDialog(false)
        setKettleConfigObj(kettleConfigurationDefaults)
    }
    const hideToolkitDialog = () => { setViewToolTip(false) }
    const hideConsumptionDialog = () => {
        setConsumptionClickDialog(false)
        setConsumptionClickPayload({})
    }


    const fnUnlockCanister = async () => {

        const payload = { "canid": selectedModalData?.can_id, "id": selectedMachineData?.id, "category": selectedUnlockCat?.name, "comment": unlockReason?.reason }
        const response = await apiServices.unlockCanister(payload);
        setLockDialog(false)
        setLockBtn(false)
        setTimeout(() => { setLockBtn(true) }, 5000);
        hideLockDialog()
    }

    const changeTab = (e) => {
        console.log(e.index);
        setActiveIndex(e.index);
        setLevelCBtn(false);
    }

    // addd
    const fnCanlevel = async () => {
        setEditDialog(false)
        setLevelCBtn(false)

        console.log(updatedLevel);

        console.log((selectedMachineData?.mchmode?.levelCalibrate || 175) + selectedModalData?.level * 10);

        const data = {
            canid: selectedModalData?.can_id,
            id: selectedMachineData?.id,
            name: selectedModalData?.name,
            level: Number(updatedLevel),
            calibrate_type: isStandard ? 'STANDARD_CALIBRATE' : 'CRITICAL_CALIBRATE'
        }

        console.log(data);

        const response = await apiServices.setCanisterLevel(data);
        if (response?.data?.success) {

            successToast(toast, 'Level calibration updated successfully')

            const arr = []
            selectedMachineStat.filter((x) => {
                if (x.can_id == selectedModalData?.can_id) {
                    arr.push({ ...x, level: Number(updatedLevel) / 10 })
                } else {
                    arr.push(x)
                }
            })
            setSelectedMachineStat(arr)
        } else {
            failureToast(toast, 'Failed to update')
        }

    }

    const setCalibration = async () => {
        const data = { canid: selectedModalData?.can_id, id: selectedMachineData?.id, value: motorRangeValue }
        console.log(data);
        const response = await apiServices.setCalibrationData(data);
        response?.data?.success ? successToast(toast, 'Premix Motor Calibration updated successfully') : failureToast(toast, 'Failed to update')
        setSettingDialog(false)
    }

    const changeBarcode = (e) => { setBarcodeTemplate({ [e.target.name]: e.target.value }) }

    const setBarcodeRefill = async (e) => {
        e.preventDefault();
        const data = { ...barcodeTemplate, canid: selectedModalData?.can_id, id: selectedMachineData?.id }
        const response = await apiServices.validateBarcode(data);
        (response?.data?.sucess) ? successToast(toast, 'Barcode refill updated successfully') : failureToast(toast, 'Invalid Barcode')
        setBarcodeDialog(false)
    }

    const removeMaintenance = async () => {
        const data = { canId: selectedModalData?.can_id, id: selectedMachineData?.id }
        const response = await apiServices.removeCanisterMaintenance(data);
        response?.data?.success ? successToast(toast, 'Canister Maitenance Removed Successfully') : failureToast(toast, 'Failed to Remove');
        hideCanMainDialog();
    }

    const submitKettleConfig = (e) => {
        e.preventDefault();
        const payload = { ...kettleConfigObj, "id": selectedMachineData?.id }
        executeKettleCommand(payload);
        getMachine({ app_cat: selectedCategory.code })
        hideConfigDialog()
    }

    const submitClicks = async (e) => {
        e.preventDefault();
        const payload = { "id": selectedMachineData?.id, "canid": selectedModalData?.can_id, ...consumptionClickPayload }
        const response = await apiServices.updateConsumptionClicks(payload)
        response?.data?.success ? successToast(toast, 'Consumption Clicks Modified Successfully.') : failureToast(toast, 'Failed to Modify.');
        hideConsumptionDialog();
    }

    const changeCategory = (e) => {
        setSelectedCategory(e.value)
        const payload = { app_cat: [e.value.code] }
        getMachine(payload)
    }

    const changeCategoryManual = (category) => {
        const payload = { app_cat: category }
        getMachine(payload)
    }

    const changeConsumption = (e) => {
        if (e.target.name === 'clicks_count' || e.target.name === 'comment') {
            setConsumptionClickPayload({ ...consumptionClickPayload, [e.target.name]: e.target.value })
        }
    }

    const selectedMachine = async (e) => {
        console.log(e.value);
        setLoading(true)
        msgs?.current?.clear()
        setDefaultMachine(e.value)
        setSelectedMachineData(e.value)
        // const response2 = await apiServices.getMaterialColor();

        const resultedMachine1 = e.value.stats.map((x) => {

            let value = Math.round(x.level ? x.level : 0);

            if (value > 0 && value <= 10) {
                value = 10;
            } else if (value > 10 && value <= 25) {
                value = 25;
            } else if (value > 25 && value <= 50) {
                value = 50;
            } else if (value > 50 && value <= 75) {
                value = 75;
            } else if (value > 75) {
                value = 100;
            }

            const materialTypes = materialType.find((i) => i.materialName == x.name)
            // console.log(materialTypes)
            return { ...x, ...materialTypes, newLevel: value }
        })

        console.log(resultedMachine1)

        setSelectedMachineStat(resultedMachine1)

        if (e.value.cans > 3) {
            const response3 = await apiServices.getCalibrationData({ id: e.value.id });
            setCalibrationData(response3?.data?.data?.calibrate)
        }
        // getAgreement(e.value)
        getLiveData(e.value)
        // getCreditLimits(e.value)

        // e.value.id
        // const x = {...e.value,connectionMode:'Hello'}
        // console.log(x);

        socket.on("live_status/", data => {
            // console.log(data)
            if (e.value.id == data.c_id) {
                console.log("====================================================");
                const x = { ...e.value, connectionMode: data.connection }
                setDefaultMachine(x)
                setSelectedMachineData(x)
            }
        })

        setLoading(false)

        // const isEveryCanisterEmpty = resultedMachine1.every( ({level}) => level === resultedMachine1[0].level); 
        // const isEveryCanisterEmpty = resultedMachine1.every( ({newLevel}) => newLevel <= 10); 
        const isEveryCanisterEmpty = resultedMachine1?.every(({ level }) => level <= 10 || level == undefined);
        setIsAllEmpty(isEveryCanisterEmpty)
    }

    // const getAgreement = async (data) => {

    //     if (data && rolePermissions?.agreementAlert) {

    //         const data1 = { userid: data?.customer }
    //         const response = await apiServices.getAgreements(data1);

    //         response.data.data = await response.data.data.map((value) => {
    //             return {
    //                 ...value,
    //                 siteName: data.siteName
    //             }
    //         })
    //         const resp = await apiServices.getAgreeComments(data1);
    //         const arr = resp?.data?.data;
    //         const comment = arr[arr?.length - 1]?.agreement_comment;

    //         if (response.data.data.length == 0) {
    //             const result = await apiServices.getAgreements({ index: 0, count: 1 });
    //             result.data.data = await result.data.data.map((value) => {
    //                 return {
    //                     ...value,
    //                     siteName: data.siteName
    //                 }
    //             })
    //             setMachineAgreements(result?.data?.data);
    //         } else {
    //             setMachineAgreements(response?.data?.data);
    //         }

    //         let message;
    //         if (comment) {
    //             message = (data?.companyName || data?.siteName) + "'s Agreement for " + data.subid + ' is not signed/uploaded due to ' + comment + '. Please contact Cherise tech operation team [ Contact : +91 7373900500 ].';
    //         } else {
    //             message = (data?.companyName || data?.siteName) + "'s Agreement for " + data.subid + ' is not signed/uploaded. Please contact Cherise tech operation team [ Contact : +91 7373900500 ].';
    //         }

    //         if (response?.data?.data?.length == 0) {
    //             if (data.datastore.stage == "finishedgoods" && data.subscription && data.subscription.package.site == "demo" && data.siteName != "") {
    //             } else {
    //                 msgs?.current?.show([{ severity: 'error', detail: message, sticky: true, closable: false }])
    //             }
    //         }
    //     }
    // }



    const getLiveData = async (data) => {
        const response = await apiServices.getLiveData({ id: data?.id });
        console.log(response);
    }

    const commanModal = (value) => {
        console.log(value);
        // const value = e.target.id;
        const modelData = selectedMachineStat.find(o => o.can_id == value);
        console.log(modelData)
        setSelectedModalData(modelData)
    }

    const openEditModal = (data) => {
        setLevelCBtn(false);
        setEditDialog(true);
        commanModal(data.can_id);
        setActiveIndex(0);
    }

    const openSettingModal = async (data) => {
        setSettingDialog(true)
        commanModal(data.can_id)
        const response3 = await apiServices.getCalibrationData({ id: data.id });
        const canId = data.can_id;

        console.log(canId);
        console.log(response3?.data?.data);

        if (canId == 1) {
            var calibrationValue = response3?.data?.data?.calibrate.one
        } else if (canId == 2) {
            var calibrationValue = response3?.data?.data?.calibrate.two
        } else if (canId == 3) {
            var calibrationValue = response3?.data?.data?.calibrate.three
        } else if (canId == 4) {
            var calibrationValue = response3?.data?.data?.calibrate.four
        }

        setMotorRangeValue(calibrationValue)
        // setCalibrationData(calibrationValue)
    }

    const openLockModal = (data) => {
        setLockDialog(true)
        commanModal(data.can_id)
    }

    const openBarcodeModal = (data) => {
        setBarcodeDialog(true)
        commanModal(data.can_id)
    }

    const openRmCanisterModal = (data) => {
        setCanMaintenanceDialog(true)
        commanModal(data.can_id)
    }

    const openClicksModal = (data) => {
        setConsumptionClickDialog(true)
        commanModal(data.can_id)
    }


    const onUpdateLevelstd = (e) => {

        const value = (selectedMachineData?.mchmode?.levelCalibrate || 175) + selectedModalData?.level * 10;
        console.log(value <= Number(e.target.value));

        if (value > Number(e.target.value)) {

            setUpdatedLevel(e.target.value);
            (e.target.value > 1300 || e.target.value == "") ? setLevelCBtn(false) : setLevelCBtn(true)
            setIsStandard(true)
        } else {
            setLevelCBtn(false)
        }

        // setUpdatedLevel(e.target.value);
        // (e.target.value > 1300 || e.target.value == "") ? setLevelCBtn(false) : setLevelCBtn(true)
        // setIsStandard(true)
    }

    const onUpdateLevelcri = (e) => {
        setUpdatedLevel(e.target.value);
        (e.target.value > 1300 || e.target.value == "") ? setLevelCBtn(false) : setLevelCBtn(true)
        setIsStandard(false)
    }

    const showAgreements = async (rowData) => {
        console.log(rowData);
        const response = await apiServices.sendNewAgreement(rowData);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const downloadURL = window.URL.createObjectURL(blob);
        window.open(downloadURL, '_blank');
    }

    const openCommandDialog = (rowData, command) => {
        setKettleCommandDialog(true)
        setCommandKey(command)
        // setSelectedMachineForM(rowData)
    }

    const openCommandConfig = () => {
        console.log(selectedMachineData);
        setKettleConfigDialog(true)

        const data = {
            ...kettleConfigObj,
            kettle_disp_time: selectedMachineData?.mchmode?.kettle_disp_time ? Number(selectedMachineData?.mchmode?.kettle_disp_time) : kettleConfigObj?.kettle_disp_time,
            kettle_htr_off1: selectedMachineData?.mchmode?.kettle_htr_off1 ? Number(selectedMachineData?.mchmode?.kettle_htr_off1) : kettleConfigObj?.kettle_htr_off1,
            kettle_htr_off2: selectedMachineData?.mchmode?.kettle_htr_off2 ? Number(selectedMachineData?.mchmode?.kettle_htr_off2) : kettleConfigObj?.kettle_htr_off2,
            kettle_htr_off3: selectedMachineData?.mchmode?.kettle_htr_off3 ? Number(selectedMachineData?.mchmode?.kettle_htr_off3) : kettleConfigObj?.kettle_htr_off3,
            kettle_htr_off4: selectedMachineData?.mchmode?.kettle_htr_off4 ? Number(selectedMachineData?.mchmode?.kettle_htr_off4) : kettleConfigObj?.kettle_htr_off4,
            kettle_htr_mode: selectedMachineData?.mchmode?.kettle_htr_mode ? Number(selectedMachineData?.mchmode?.kettle_htr_mode) : kettleConfigObj?.kettle_htr_mode,
            kettle_valve_calfact: selectedMachineData?.mchmode?.kettle_valve_calfact ? Number(selectedMachineData?.mchmode?.kettle_valve_calfact) : kettleConfigObj?.kettle_valve_calfact,
            kettle_set_cup_count: selectedMachineData?.mchmode?.kettle_set_cup_count ? Number(selectedMachineData?.mchmode?.kettle_set_cup_count) : kettleConfigObj?.kettle_set_cup_count,
            kettle_disp_selection_flg: selectedMachineData?.mchmode?.kettle_disp_selection_flg ? Number(selectedMachineData?.mchmode?.kettle_disp_selection_flg) : kettleConfigObj?.kettle_disp_selection_flg,
            kettle_door_unlock_attempts: selectedMachineData?.mchmode?.kettle_door_unlock_attempts ? Number(selectedMachineData?.mchmode?.kettle_door_unlock_attempts) : kettleConfigObj?.kettle_door_unlock_attempts,
            kettle_cup_count_buffer: selectedMachineData?.mchmode?.kettle_cup_count_buffer ? Number(selectedMachineData?.mchmode?.kettle_cup_count_buffer) : kettleConfigObj?.kettle_cup_count_buffer,
            kettle_stirrer_on_time: selectedMachineData?.mchmode?.kettle_stirrer_on_time ? Number(selectedMachineData?.mchmode?.kettle_stirrer_on_time) : kettleConfigObj?.kettle_stirrer_on_time,
            kettle_stirrer_off_time: selectedMachineData?.mchmode?.kettle_stirrer_off_time ? Number(selectedMachineData?.mchmode?.kettle_stirrer_off_time) : kettleConfigObj?.kettle_stirrer_off_time,
            kettle_stirrer_refill_on_time: selectedMachineData?.mchmode?.kettle_stirrer_refill_on_time ? Number(selectedMachineData?.mchmode?.kettle_stirrer_refill_on_time) : kettleConfigObj?.kettle_stirrer_refill_on_time,
            kettle_online_refill_mode: selectedMachineData?.mchmode?.kettle_online_refill_mode ? Number(selectedMachineData?.mchmode?.kettle_online_refill_mode) : kettleConfigObj?.kettle_online_refill_mode,
            kettle_stage_mode: selectedMachineData?.mchmode?.kettle_stage_mode ? Number(selectedMachineData?.mchmode?.kettle_stage_mode) : kettleConfigObj?.kettle_stage_mode,
            kettle_refill_tea_water: selectedMachineData?.mchmode?.kettle_refill_tea_water ? Number(selectedMachineData?.mchmode?.kettle_refill_tea_water) : kettleConfigObj?.kettle_refill_tea_water,
        }

        setKettleConfigObj(data);
    }

    const hideKettleCommandDialog = () => {
        setKettleCommandDialog(false)
        setKettleCommand({})
        setIsStarted(false)
    }

    const kettleCommandSubmit = (value) => {
        setIsStarted(value == 'start' ? true : false);
        const payload = { "id": selectedMachineData?.id, [commandKey]: value == 'start' ? true : false }
        executeKettleCommand(payload)
    }

    const executeKettleCommand = async (data) => {
        const response = await apiServices.getKettleExcecution(data);
        response?.data?.success ? successToast(toast, 'Command Executed Successfully') : failureToast(toast, 'Failed to Execute');
    }

    const changeKettleConfig = (e) => {
        const data = { ...kettleConfigObj, [e.target.name]: Number(e.target.value) }
        console.log(data);
        setKettleConfigObj(data);
    }

    const openTooltip = (data) => {
        console.log(data);
        setSelectedCanister(data);
        setViewToolTip(true);
        getBarcodeDetails(data);
    }

    const getBarcodeDetails = async (data) => {

        const payload = {
            "id": selectedMachineData?.id,
            "materialName": data?.name,
            "subid": selectedMachineData?.subid,
        }

        const response = await apiServices.getBarcodeData(payload);
        console.log(response?.data?.data);
        setBarcodeData(response?.data?.data)
    }

    const changeUnlockCategory = (e) => {
        setSelectedUnlockCat(e.target.value)
        setUnlockReason(null)
        if (e.target.value.code === 'operational') {
            setSelectedReasons(manualUnlockReasons)
        } else if (e.target.value.code === 'technical') {
            setSelectedReasons(technicalUnlockReasons)
        }
    }

    let clickCount = 0;
    const showAlert = () => {
        clickCount++;
        if (clickCount === 5) {
            const duplicates = findDuplicates(allMachines, 'id')
            alert(`Machines are : ${duplicates}`)
            clickCount = 0;
        }
    }

    // addd

    const carouselKettleItemTemplate = (product) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="p-mb-3">
                        <p><b>BEVERAGE DETAILS</b></p>
                    </div>

                    {
                        rolePermissions?.kettleCommandsOnOverview &&
                        <div className="car-buttons p-my-5">
                            {
                                // product?.level < 30 && 
                                <>
                                    <Button icon="pi pi-bars" title='Barcode Refill' className="p-button-rounded p-button-help p-m-1" aria-label="Refill" onClick={() => { openBarcodeModal(product) }} />
                                    <Button icon="pi pi-sitemap" title='Kettle Stirrer' className="p-button-rounded p-button-primary  p-m-1" aria-label="Kettle Stirrer" onClick={(e) => openCommandDialog(product, 'kettle_stirrer')} />
                                    <Button icon="pi pi-sliders-v" title='Kettle Tea Motor' className="p-button-rounded p-button-help  p-m-1" aria-label="Kettle Tea Motor" onClick={(e) => openCommandDialog(product, 'kettle_tea_motor')} />
                                    <Button icon="pi pi-sort" title='Kettle Valve' className="p-button-rounded p-button-warning  p-m-1" aria-label="Kettle Valve" onClick={(e) => openCommandDialog(product, 'kettle_valve')} />
                                    <Button icon="pi pi-power-off" title='Kettle Machine Mode' className="p-button-rounded p-button-info  p-m-1" aria-label="Kettle Machine Mode" onClick={(e) => openCommandDialog(product, 'kettle_machine_mode')} />
                                    <Button icon="pi pi-print" title='Kettle Heater' className="p-button-rounded p-button-danger  p-m-1" aria-label="Kettle Heater" onClick={(e) => openCommandDialog(product, 'kettle_heater')} />
                                    <Button icon="pi pi-sort-amount-up" title='Set Temperature' className="p-button-rounded p-button-success  p-m-1" aria-label="Set Temperature" onClick={(e) => openCommandDialog(product, 'kettle_set_temp')} />
                                    <Button icon="pi pi-cog" title='Configuration' className="p-button-rounded p-button-success  p-m-1" aria-label="Set Temperature" onClick={() => openCommandConfig(product)} />
                                </>
                            }
                        </div>
                    }

                    {
                        (selectedMachineData?.maintaince?.kettle_inner_sensor && !selectedMachineData?.maintaince?.kettle_outer_sensor) &&
                        <center>
                            <Message className='p-m-1' severity="error" text="System Alert: Inner Sensor Failure." style={{ width: '40vw' }} />
                        </center>
                    }

                    {
                        (!selectedMachineData?.maintaince?.kettle_inner_sensor && selectedMachineData?.maintaince?.kettle_outer_sensor) &&
                        <center>
                            <Message className='p-m-1' severity="error" text="System Alert: Outer Sensor Failure." style={{ width: '40vw' }} />
                        </center>
                    }

                    {
                        (selectedMachineData?.maintaince?.kettle_inner_sensor && selectedMachineData?.maintaince?.kettle_outer_sensor) &&
                        <center>
                            <Message className='p-m-1' severity="error" text="System Alert: Both Inner and Outer Sensors Have Failed." style={{ width: '40vw' }} />
                        </center>
                    }



                    <div className='card'  >
                        <div className='p-grid'>
                            <div className='p-col-12 p-md-6'>
                                <div className='card'>
                                    <center><img src={`${ENDPOINT}/bevimg?id=${product.name}`} alt={product.name} className="product-image" /></center>
                                </div>
                            </div>
                            <div className='p-col-12 p-md-6'>
                                <div className='card'>
                                    <center><img src={`${ENDPOINT}/canimg?level=${product.level}&type=${product.materialType}`} alt={product.name} className="canister-image" /></center>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h6 className="p-mt-2 p-mb-3">   Beverage Level :  {initialValue.userType == 'owner' ? (product.level >= 100 ? 100 : (product.level || product?.newLevel)) : (product.newLevel == 0 ? 10 : product.newLevel)}%</h6>
                </div>
            </div>
        );
    };

    const carouselItemTemplate = (product) => {
        return (

            <div className="product-item">
                {/* <div className={isAllEmpty && `p-mx-5 p-mb-2`} style={{height : '4rem'}}>
                    {
                        (!isAllEmpty && (!product?.level || product?.level <= 10)) &&
                        <Message severity="info" text="Manually Calibrated" />
                    }
                </div> */}

                <div className="product-item-content">
                    <div className="p-mb-3">
                        <p><b>CANISTER {product.can_id}</b></p>
                        {
                            // (selectedMachineStat.length == 4 && selectedMachineData.connectionMode!='disConnected') &&
                            (selectedMachineData?.connectionMode != 'disConnected' && rolePermissions?.overviewAct) &&

                            <div className="car-buttons p-my-5">
                                <Button icon="pi pi-pencil" title='Update Level Calibration' className="p-button-rounded p-button-success" aria-label="Update" onClick={() => { openEditModal(product) }} />
                                <Button icon="pi pi-cog" title='Set Motor Calibration' className="p-button-rounded p-button-info p-mx-2" aria-label="Setting" disabled={selectedMachineData?.passcode == "cherees123" ? true : false} onClick={() => { openSettingModal(product) }} />
                                {lockBtn && <Button icon="pi pi-unlock" title='Canister Unlock' className="p-button-rounded p-button-danger" aria-label="Unlock" onClick={() => { openLockModal(product) }} />}
                                {product?.level < 30 && <Button icon="pi pi-bars" title='Barcode Refill' className="p-button-rounded p-button-help p-mx-2" aria-label="Refill" onClick={() => { openBarcodeModal(product) }} />}
                                <Button icon="pi pi-times" title='Remove canister Maintenance' className="p-button-rounded p-button-warning p-ml-2" aria-label="Canister Maintenance" onClick={() => { openRmCanisterModal(product) }} />
                                {rolePermissions?.overviewActClick && <Button icon="pi pi-chevron-up" title='Consumption Clicks' className="p-button-rounded p-button-info p-mx-2" aria-label="Consumption Clicks" onClick={() => { openClicksModal(product) }} />}
                            </div>
                        }

                        <img src={`${ENDPOINT}/bevimg?id=${product.name}`} alt={product.name} className="product-image" />
                    </div>

                    <div className="p-mb-4 p-mt-4">
                        <img src={`${ENDPOINT}/canimg?level=${product.level}&type=${product.materialType}`} alt={product.name} className="canister-image" />
                        {/* https://platform.cherisetapri.com:4002/canimg?level=50&type=Coffee */}
                        {/* <img src={`assets/demo/images/canister/can-40-${props.colorScheme === 'light' ? 'light' : 'dark'}.png`} alt={product.name} className="canister-image" /> */}
                        {/* <img src={`assets/demo/images/canister/${product.canister}-${props.colorScheme === 'light' ? 'light' : 'dark'}.png`} alt={product.name} className="canister-image" /> */}
                    </div>

                    {
                        (!product?.level || product?.level <= 10) &&
                        <i className="pi pi-info-circle custom-tooltip-btn" style={{ fontSize: '1.5rem', cursor: 'pointer' }} onClick={() => openTooltip(product)} ></i>
                    }

                    <h6 className="p-mt-2 p-mb-3">Beverage Level :  {initialValue.userType === 'owner' ? (product.level >= 100 ? 100 : (product.level || 0)) : (product.newLevel == 0 ? 10 : product.newLevel)}%</h6>
                </div>
            </div>
        );
    };

    const UpcommingRefillTemplate = (product) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="p-mb-3">
                        <img src={`assets/demo/images/Beverages/${product.image}`} alt={product.name} className="product-image" />
                    </div>
                </div>
            </div>
        );
    };

    const NextUpcommingRefillTemplate = (product) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="p-mb-3">
                        <img src={product.image} onClick={imageClicked} alt={product.name} className="Beverage-list product-image" />
                    </div>
                </div>
            </div>
        );
    };

    const deleteRefillDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideRefillDialog} />
            <Button label="Submit" icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" />
        </>
    );

    const barcodeDialogFooter = commonFormDialogFooter('Close', hideBarcodeDialog, 'Set', 'barcode-form')
    const settingDialogFooter = commonDialogFooter('Close', hideSettingDialog, 'Set', setCalibration)
    const settingLockFooter = commonDialogFooter('Close', hideLockDialog, 'Unlock', fnUnlockCanister, (unlockReason !== null && unlockReason !== '' && unlockReason !== undefined))
    const editDialogFooter = commonDialogFooter('Close', hideEditDialog, 'Set', fnCanlevel, levelCBtn)
    const canMainDialogFooter = commonDialogFooter('Close', hideCanMainDialog, 'Yes', removeMaintenance)
    const configDialogFooter = commonFormDialogFooter('Close', hideConfigDialog, 'Submit', 'config-form')
    const toolkitFooter = commonCloseDialogFooter('Close', hideToolkitDialog)
    const clickDialogFooter = commonFormDialogFooter('Close', hideConsumptionDialog, 'Submit', 'clicks-form')



    const panelheader = (options) => {
        const className = `${options.className} p-grid `;
        return <div className={className}>
            <div className='input-group p-grid p-md-8'>

               { initialValue?.userType != 'invenstor' && <div className="p-inputgroup p-md-2">
                    <Button className="p-button-primary" icon="pi pi-list" />
                    <Dropdown value={selectedCategory} onChange={changeCategory} options={allCategories} optionLabel="name" placeholder="Select Category" filter className="multiselect-custom block" disabled={managementCheck()} />
                </div>}

                <div className="p-inputgroup p-md-5">
                    <Button className={defaultMachine?.connectionMode != "disConnected" ? 'p-button-success' : 'p-button-danger'} label="CVES" onClick={showAlert} />
                    <Dropdown value={defaultMachine} onChange={selectedMachine} options={allMachines} optionLabel="displayLabel" placeholder="Select CVES" filter className={`${defaultMachine?.connectionMode != "disConnected" ? 'p-valid' : 'p-invalid'} multiselect-custom block`} itemTemplate={itemOptionTemplate} />
                </div>

               { initialValue?.userType != 'invenstor' && <div className='p-mt-4'>
                    {
                        <h5 className="font-bold p-error p-mb-4">{defaultMachine?.subscription?.package?.site == 'permanent' ? "Commercial Onboarded" : "Demo Onboarded"}</h5>
                    }
                </div> }

            </div>
            <div className='toggle-element'>
                    {options.togglerElement}
            </div>

        </div>
    }

    const fileBodyForTable = (rowData, props) => (<> <span className="p-column-title">{props.header}</span> {rowData.File.filename} </>)

    const actionBodyTemplate = (rowData) => (
        <>
            <span className="p-column-title">Actions</span>
            <Button icon="pi pi-eye" title='View Agreement' label='View Agreement' className="p-button-rounded p-button-primary" aria-label="View Order" onClick={() => { showAgreements(rowData) }} />
        </>
    )

    const kettleCommandDialogFooter = () => (
        <>
            <Button label='Close' icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideKettleCommandDialog} />
            {
                isStarted ?
                    <Button label='Stop' icon="pi pi-pause" className="p-button-danger p-mr-2 p-mb-2" onClick={() => { kettleCommandSubmit('stop') }} />
                    :
                    <Button label='Start' icon="pi pi-forward" className="p-button-success p-mr-2 p-mb-2" onClick={() => { kettleCommandSubmit('start') }} />
            }
        </>
    );

    return (
        <>

            <Toast ref={toast} position="center" />

            {
                (userInfo?.agreement_signed) ?
                    <>
                        <div className="card p-mb-0">

                            {
                                allMachines ?
                                    <Panel headerTemplate={panelheader} toggleable
                                        collapsed={!rolePermissions?.overviewActionsTab}
                                    >
                                        <PanelOverview
                                            defaultMachine={defaultMachine}
                                            creditData={creditData}
                                            machineAgreements={machineAgreements}
                                            multiple={rolePermissions?.overviewActionsTab}
                                        />

                                    </Panel>
                                    :
                                    <Skeleton height="5rem" className="mb-2"></Skeleton>
                            }

                            {/* <Messages ref={msgs} /> */}
                        </div>

                        <div className="card" style={{ padding: "0px 16px" }}>

                            {
                                rolePermissions?.overviewActionsTab ?
                                    <OverviewActions selectedMachine={defaultMachine} />
                                    :
                                    <Carousel
                                        value={headerImage}
                                        numVisible={1}
                                        numScroll={1}
                                        autoplayInterval={3000}
                                        responsiveOptions={topResponsiveOptions}
                                        itemTemplate={topHeaderTemplate}
                                    ></Carousel>


                            }
                        </div>

                        <div className="p-grid p-fluid media-demo">
                            <div className="p-col-12">
                                <div className="card">
                                    <h5 className='overview-head'>Beverages Currently On-Board </h5>
                                    <center><small className='p-error p-mb-4'>{defaultMachine?.connectionMode !== "disConnected" ? '' : <h6>System is currently offline</h6>}</small> </center>
                                    {
                                        isAllEmpty &&
                                        <>
                                            <div className='card' style={{ margin: 0 }}>
                                                <div className='p-mx-5 p-mt-2'>
                                                    <Message severity="info" text="Newly Onboarded" />
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {
                                        loading ?
                                            <div className='p-mt-5'>
                                                <center><ProgressSpinner strokeWidth={7} animationDuration='4s' /></center>
                                            </div>
                                            :
                                            <>
                                                {
                                                    (selectedMachineStat?.length === 1) &&
                                                    <Carousel value={selectedMachineStat} numVisible={1} numScroll={1} responsiveOptions={carouselResponsiveOptions} itemTemplate={carouselKettleItemTemplate}></Carousel>
                                                }

                                                {
                                                    (selectedMachineStat?.length === 2 || selectedMachineStat?.length === 3) &&
                                                    <Carousel value={selectedMachineStat} numVisible={3} numScroll={3} responsiveOptions={carouselResponsiveOptions} itemTemplate={carouselItemTemplate}></Carousel>
                                                }
                                                {
                                                    selectedMachineStat?.length >= 4 &&
                                                    <Carousel value={selectedMachineStat} numVisible={4} numScroll={3} responsiveOptions={carouselResponsiveOptions} itemTemplate={carouselItemTemplate}></Carousel>
                                                }
                                            </>
                                    }
                                </div>
                            </div>

                         

                           { initialValue?.userType != 'invenstor' && <div className='p-col-12'>
                                <div className='card'>
                                  
                                    <h5 className='overview-head'>Browse The Tapri Shop <a href='/#/refill-orders' target="_blank"><img className='shoppee-img' src='assets/demo/images/Icon/TapriShoppeButton.png' alt='Shoppee'></img></a> To Select Your Upcoming Refill</h5>
                                </div>
                            </div>}

                            {
                                shoppeeDialog &&
                                <div className="p-col-12">
                                    <div className="card">
                                        <h5 className='overview-head'>Select Beverage For Next Refilling</h5>
                                        <Carousel value={beverages} numVisible={3} numScroll={3} responsiveOptions={carouselResponsiveOptions} itemTemplate={NextUpcommingRefillTemplate}></Carousel>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                    :
                    <>
                        <CustomerAgreementDialog initialValue={userInfo} getUserData={getUserData} />
                    </>

            }

            <Dialog visible={refillDialog} style={{ width: '450px' }} header="Select canister for next refill" modal footer={deleteRefillDialogFooter} onHide={hideRefillDialog} className='p-fluid' blockScroll={true}>
                <div className="p-field">
                    <label htmlFor="subscription-category">Canister</label>
                    <Dropdown id="subscription-category" value={dropdownItem} onChange={(e) => setDropdownItem(e.value)} autoFocus options={defaultDropdownList} optionLabel="name" placeholder="Select Canister"></Dropdown>
                </div>
            </Dialog>

            <Dialog visible={editDialog} style={{ width: '550px' }} header="Edit Level Calibration" modal className="p-fluid" footer={editDialogFooter} onHide={hideEditDialog} blockScroll={true}>

                <center><h5 className='p-mt-2'>{selectedMachineData?.id} / CANISTER {selectedModalData?.can_id}</h5></center>

                <Message className='p-mb-4' severity="warn" text="Warning: All actions are system-monitored and recorded." />


                <TabView activeIndex={activeIndex} onTabChange={(e) => changeTab(e)} >
                    <TabPanel header="Level Calibration (Standard)">

                        {/* <Message className='p-m-2' severity="warn" text="Warning: All actions are system-monitored and recorded." /> */}

                        <div className="p-field">
                            <label htmlFor="demo-site">Beverage Name</label>
                            <InputText id="demo-site" placeholder='Beverage' value={selectedModalData?.name} disabled required />
                        </div>
                        <div className="p-field">
                            <label htmlFor="demo-site-location">Current Level (Gms)</label>
                            <InputText id="demo-site-location" placeholder='Current Level' value={selectedModalData?.level * 10 || 0} disabled required />
                        </div>
                        <div className="p-field">
                            <label htmlFor="demo-site-location">Update Level (Gms) </label>
                            {/* selectedModalData?.level * 10 */}
                            {/* selectedMachineData?.mchmode?.levelCalibrate || 175 */}
                            {/* <InputText id="demo-site-location" type="number" placeholder='Update Level (Gms)' onChange={(e) => onUpdateLevel(e)} defaultValue={selectedMachineData?.mchmode?.levelCalibrate || 175} required /> */}
                            <InputText id="demo-site-location" type="number" placeholder='Update Level (Gms)' min={200} max={((selectedMachineData?.mchmode?.levelCalibrate || 175) + selectedModalData?.level * 10)} onChange={(e) => onUpdateLevelstd(e)} defaultValue={selectedModalData?.level * 10} required />
                            <small className='p-error'>Max: {(selectedMachineData?.mchmode?.levelCalibrate || 175) + selectedModalData?.level * 10}</small>
                        </div>
                    </TabPanel>
                    <TabPanel header="Master Calibration (Critical)">
                        <div className="p-field">
                            <label htmlFor="demo-site">Beverage Name</label>
                            <InputText id="demo-site" placeholder='Beverage' value={selectedModalData?.name} disabled required />
                        </div>
                        <div className="p-field">
                            <label htmlFor="demo-site-location">Current Level (Gms)</label>
                            <InputText id="demo-site-location" placeholder='Current Level' value={selectedModalData?.level * 10 || 0} disabled required />
                        </div>
                        <div className="p-field">
                            <label htmlFor="demo-site-location">Update Level (Gms)</label>
                            <InputText id="demo-site-location" type="number" min={200} max={1300} placeholder='Update Level (Gms)' onChange={(e) => onUpdateLevelcri(e)} defaultValue={selectedModalData?.level * 10} required />
                        </div>
                    </TabPanel>
                </TabView>

            </Dialog>

            <Dialog visible={settingDialog} style={{ width: '550px' }} header="Premix Motor Calibration" modal className="p-fluid" footer={settingDialogFooter} onHide={hideSettingDialog} blockScroll={true}>
                <center><h5 className='p-mt-2'>{selectedMachineData?.id} / CANISTER {selectedModalData?.can_id}</h5></center>
                <div className="p-field">
                    <label htmlFor="demo-site">Beverage Name</label>
                    <InputText id="demo-site" placeholder='Beverage' value={selectedModalData?.name} disabled required />
                </div>
                <div className="p-field col-12 md:col-3">
                    <label>Motor Range : {motorRangeValue}</label>
                    <Slider min={100} max={130} value={motorRangeValue} onChange={(e) => setMotorRangeValue(e.value)} />
                </div>

                <hr className='p-my-5'></hr>
                <center>
                    <Tag className='p-mb-2' icon="pi pi-minus-circle" severity="danger" value="INDICATIONS"></Tag>
                    <div className='p-grid'>
                        <div className='p-col-6'> <b><i className="pi pi-arrow-up"></i> Calibration Number</b> </div>
                        <div className='p-col-6'> <b><i className="pi pi-arrow-up"></i> Premix Quantity</b></div>
                        <div className='p-col-6'> <b><i className="pi pi-arrow-down"></i> Calibration Number</b> </div>
                        <div className='p-col-6'> <b><i className="pi pi-arrow-down"></i> Premix Quantity</b></div>
                    </div>
                </center>
            </Dialog>

            <Dialog visible={lockDialog} style={{ width: '550px' }} header="Canister Unlock" modal className="p-fluid" footer={settingLockFooter} onHide={hideLockDialog} blockScroll={true}>
                <center><h5 className='p-mt-2'>{selectedMachineData?.id} / CANISTER {selectedModalData?.can_id}</h5></center>
                <div className="p-field">
                    <label htmlFor="comment">Unlock Category</label>
                    <Dropdown options={unlockCategory} onChange={changeUnlockCategory} value={selectedUnlockCat} optionLabel="name" placeholder="Select Unlock Category" filter className="multiselect-custom block" required />
                </div>
                <div className="p-field">
                    <label htmlFor="comment">Unlock Reason</label>
                    <Dropdown options={selectedReasons} onChange={(e) => { setUnlockReason(e.target.value) }} value={unlockReason} optionLabel="reason" placeholder="Select Reason for unlock" filter className="multiselect-custom block" required />
                </div>
            </Dialog>

            <Dialog visible={barcodeDialog} style={{ width: '550px' }} header="Barcode Refill" modal className="p-fluid" footer={barcodeDialogFooter} onHide={hideBarcodeDialog} blockScroll={true}>
                <center><h5 className='p-mt-2'>{selectedMachineData?.id} / CANISTER {selectedModalData?.can_id}</h5></center>
                <form onSubmit={setBarcodeRefill} id="barcode-form" >
                    <div className="p-field">
                        <label htmlFor="barcode-no">Barcode Number</label>
                        <InputText id="barcode-no" name='barcode' placeholder='Enter Barcode Number' maxLength={8} onChange={changeBarcode} autoFocus required />
                    </div>
                </form>
            </Dialog>

            <Dialog visible={canMaintenanceDialog} style={{ width: '550px' }} header="Remove Canister Maintenance" modal className="p-fluid" footer={canMainDialogFooter} onHide={hideCanMainDialog} blockScroll={true}>
                <center><h5 className='p-mt-2'>{selectedMachineData?.id} / CANISTER {selectedModalData?.can_id}</h5></center>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-my-3 " style={{ fontSize: '4rem' }} /><br />
                        <b><span>Do You Want To Remove Canister Maintenance?</span></b>  <br></br>
                    </center>
                </div>
            </Dialog>

            <Dialog visible={kettleCommandDialog} style={{ width: '450px' }} header="Confirm" modal footer={kettleCommandDialogFooter} onHide={hideKettleCommandDialog} blockScroll={true}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br />
                        <b><span>{kettleCommandConfirmation[commandKey]}</span></b>
                    </center>
                </div>
            </Dialog>

            <Dialog visible={kettleConfigDialog} style={{ width: '750px' }} header="Kettle Configuration" modal className="p-fluid" footer={configDialogFooter} onHide={hideConfigDialog} blockScroll={true}>
                <form onSubmit={submitKettleConfig} id="config-form" className="p-grid">
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_disp_time">kettle_disp_time</label>
                            <InputText type="number" id="kettle_disp_time" name='kettle_disp_time' placeholder='kettle_disp_time' min={1} max={5} defaultValue={kettleConfigObj?.kettle_disp_time} step={0.1} onChange={changeKettleConfig} autoFocus required />
                            {/* <p className='p-error'><small>Hello how</small></p> */}
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_htr_off1">kettle_htr_off1</label>
                            <InputText type="number" id="kettle_htr_off1" name='kettle_htr_off1' placeholder='kettle_htr_off' min={1} max={15} defaultValue={kettleConfigObj?.kettle_htr_off1} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_htr_off2">kettle_htr_off2</label>
                            <InputText type="number" id="kettle_htr_off2" name='kettle_htr_off2' placeholder='kettle_htr_off2' min={5} max={50} defaultValue={kettleConfigObj?.kettle_htr_off2} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_htr_off3">kettle_htr_off3</label>
                            <InputText type="number" id="kettle_htr_off3" name='kettle_htr_off3' placeholder='kettle_htr_off3' min={5} max={50} defaultValue={kettleConfigObj?.kettle_htr_off3} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_htr_off4">kettle_htr_off4</label>
                            <InputText type="number" id="kettle_htr_off4" name='kettle_htr_off4' placeholder='kettle_htr_off4' min={20} max={50} defaultValue={kettleConfigObj?.kettle_htr_off4} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_htr_mode">kettle_htr_mode</label>
                            <InputText type="number" id="kettle_htr_mode" name='kettle_htr_mode' placeholder='kettle_htr_mode' min={1} max={4} defaultValue={kettleConfigObj?.kettle_htr_mode} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_valve_calfact">kettle_valve_calfact</label>
                            <InputText type="number" id="kettle_valve_calfact" name='kettle_valve_calfact' placeholder='kettle_valve_calfact' min={0} max={5} step={0.00001} defaultValue={kettleConfigObj?.kettle_valve_calfact} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_set_cup_count">kettle_set_cup_count</label>
                            <InputText type="number" id="kettle_set_cup_count" name='kettle_set_cup_count' placeholder='kettle_set_cup_count' min={1} max={100} defaultValue={kettleConfigObj?.kettle_set_cup_count} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_disp_selection_flg">kettle_disp_selection_flg</label>
                            <InputText type="number" id="kettle_disp_selection_flg" name='kettle_disp_selection_flg' placeholder='kettle_disp_selection_flg' min={0} max={1} defaultValue={kettleConfigObj?.kettle_disp_selection_flg} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_door_unlock_attempts">kettle_door_unlock_attempts</label>
                            <InputText type="number" id="kettle_door_unlock_attempts" name='kettle_door_unlock_attempts' placeholder='kettle_door_unlock_attempts' min={1} max={9} defaultValue={kettleConfigObj?.kettle_door_unlock_attempts} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_cup_count_buffer">kettle_cup_count_buffer</label>
                            <InputText type="number" id="kettle_cup_count_buffer" name='kettle_cup_count_buffer' placeholder='kettle_cup_count_buffer' min={1} max={50} defaultValue={kettleConfigObj?.kettle_cup_count_buffer} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_stirrer_on_time">kettle_stirrer_on_time</label>
                            <InputText type="number" id="kettle_stirrer_on_time" name='kettle_stirrer_on_time' placeholder='kettle_stirrer_on_time' min={1} max={100} defaultValue={kettleConfigObj?.kettle_stirrer_on_time} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_stirrer_off_time">kettle_stirrer_off_time</label>
                            <InputText type="number" id="kettle_stirrer_off_time" name='kettle_stirrer_off_time' placeholder='kettle_stirrer_off_time' min={1} max={100} defaultValue={kettleConfigObj?.kettle_stirrer_off_time} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_stirrer_refill_on_time">kettle_stirrer_refill_on_time</label>
                            <InputText type="number" id="kettle_stirrer_refill_on_time" name='kettle_stirrer_refill_on_time' placeholder='kettle_stirrer_refill_on_time' min={1} max={100} defaultValue={kettleConfigObj?.kettle_stirrer_refill_on_time} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_online_refill_mode">kettle_online_refill_mode</label>
                            <InputText type="number" id="kettle_online_refill_mode" name='kettle_online_refill_mode' placeholder='kettle_online_refill_mode' min={0} max={1} defaultValue={kettleConfigObj?.kettle_online_refill_mode} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_stage_mode">kettle_stage_mode</label>
                            <InputText type="number" id="kettle_stage_mode" name='kettle_stage_mode' placeholder='kettle_stage_mode' min={0} max={2} defaultValue={kettleConfigObj?.kettle_stage_mode} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                    <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="kettle_refill_tea_water">kettle_refill_tea_water</label>
                            <InputText type="number" id="kettle_refill_tea_water" name='kettle_refill_tea_water' placeholder='kettle_refill_tea_water' min={0} max={1} defaultValue={kettleConfigObj?.kettle_refill_tea_water} onChange={changeKettleConfig} required />
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog className="p-fluid" header="Canister Details" visible={viewToolTip} style={{ width: '30vw' }} footer={toolkitFooter} onHide={hideToolkitDialog}>

                <div className='p-grid'>
                    <div className="p-col-12">
                        {/* <div className={isAllEmpty && `p-mx-5 p-mb-2`}>
                        { (!isAllEmpty && (!selectedCanister?.level || selectedCanister?.level <= 10)) && <Message severity="info" text="Manually Calibrated" /> }
                    </div>
                     */}
                        <div className=" widget-performance">
                            <div className="content">
                                <ul>
                                    <div className='p-grid'>
                                        {/* <CommonForDetails name="Material Name" value={selectedCanister?.name || 'NA'} /> */}
                                        {/* <CommonForDetails name="Beverage Level" value={selectedCanister?.newLevel || 'NA'} /> */}
                                        <CommonForDetails name="Latest Barcode" value={barcodeData?.barcode || 'NA'} />
                                        <CommonForDetails name="Stage" value={getFormattedString(barcodeData?.stage) || 'NA'} />

                                        <div className='p-col-12'>
                                            <li className="person-item p-p-0">
                                                <div className="person-info">
                                                    <div className="amount">Note</div>
                                                    <div className="name p-mt-1">{(!selectedCanister?.level || selectedCanister?.level <= 10) ? 'Manually Calibrated' : 'None'}</div>
                                                </div>
                                            </li>
                                        </div>

                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={consumptionClickDialog} style={{ width: '550px' }} header="Update Consumption Click" modal className="p-fluid" footer={clickDialogFooter} onHide={hideConsumptionDialog} blockScroll={true}>
                <h6>{selectedMachineData?.id} / CANISTER {selectedModalData?.can_id}</h6>
                <form onSubmit={submitClicks} id="clicks-form" className="p-grid">
                    <FullFormWrapper id="clicks_count" label="Clicks Count">
                        <InputText type="number" id="clicks_count" name='clicks_count' onChange={changeConsumption} placeholder='Clicks Count' min={1} max={150} autoFocus required />
                    </FullFormWrapper>
                    <FullFormWrapper id="clickcomment" label="Comment">
                        <InputTextarea id='clickcomment' name='comment' rows={5} cols={30} onChange={changeConsumption} placeholder='Write a comment here..' required />
                    </FullFormWrapper>
                </form>
            </Dialog>


        </>
    );
}







