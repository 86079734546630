
import React,{useState, useEffect, useRef} from "react"
import { Chart } from "primereact/chart"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { ProgressSpinner } from "primereact/progressspinner"
import { Button } from "primereact/button"
import { CommonBodyForTable } from "../Common/CommonComponents"
import { barDataForBarChart } from "../Common/CommonFunctions"
import { barOptionsForBarChart } from "../Common/CommonFunctions"
import { exportReport } from "../Common/CommonFunctions"
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices();

export const MchWiseTicketChart = props =>{

    const [loading, setLoading] = useState(true)
    const [ticketData,setTicketData] = useState(null)
    const [dataLabels, setDataLabels] = useState([])
    const [dataValues, setDataValues] = useState([])

    const [barData, setBarData] = useState(null);
    const [barOptions, setBarOptions] = useState(null);

    const dt = useRef(null);

    useEffect(()=>{
        setTimeout(() => { getInitialise(props.selectedDate) }, 2000);
    },[props.selectedDate])
    
    const getInitialise = (data) =>{
        console.log(data);
        data ? getData(data) : getData({});
    }


    const getData = async(data) =>{
        setLoading(true)
        const response = await apiServices.ticketPerformance(data);
        console.log(response?.data?.data);
        const res = response?.data?.data.map((x)=>{ return {...x,_id:x?._id.toString()}})
        const sorted = res.sort((a, b) => parseFloat(b.ticketCount) - parseFloat(a.ticketCount));
        setTicketData(sorted)
        const label = sorted.map((x)=>{ return x._id})
        const values = sorted.map((x)=>{ return x.ticketCount})
        setDataLabels(label)
        setDataValues(values)
        getChart(label,values)
        setLoading(false)
    }

    const getChart = (dataLabels,dataValues) =>{
        const barData1 = barDataForBarChart(dataLabels,dataValues,'No of Tickets')
        const barOptions1 = barOptionsForBarChart('CVES','No of Tickets')

        setBarData(barData1)
        setBarOptions(barOptions1)
    }

    // const barData = barDataForBarChart(dataLabels,dataValues,'No of Tickets')
    // const barOptions = barOptionsForBarChart('CVES','No of Tickets')

    const exportCSV = () =>{ exportReport(dt)} 

    const chartData = {
        datasets: [{
            data: dataValues,
            backgroundColor: [
                "#42A5F5","#66BB6A","#FFA726","#26C6DA","#7E57C2",
                '#66664D', '#991AFF', '#E666FF', '#4DB3FF','#1AB399',
                '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
                '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
                '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
                '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
            ],
            label: 'My dataset'
        }],
        labels: dataLabels
    }

    const lightOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            r: {
                grid: {
                    color: '#ebedef'
                }
            }
        }
    }

    return (
        <>
            <div className="card widget-visitor-graph">
            <div className="card-header">
                <span></span>
                <div className='p-col-sm-4'>
                    <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={exportCSV} />
                </div>
            </div>

            {
                loading ? 
                <div className='spinner-div'>
                    <center><ProgressSpinner strokeWidth={7} animationDuration='4s'/></center>
                </div>
                :
                <>
                    {
                        (dataValues.length > 0 && dataLabels.length > 0) ?
                        
                        <center>
                            <Chart type="bar" data={barData} options={barOptions} />
                            {/* <Chart type="polarArea" data={chartData} options={lightOptions} style={{ position: 'relative', width :'40%' }} /> */}
                        </center>
                        :
                        <center><h4 className="p-error p-mt-5">No Data Found</h4></center>
                        
                    }
                </>
            }

            </div>

            <DataTable ref={dt} value={ticketData} dataKey="id" className="datatable-responsive" emptyMessage="No Data found." exportFilename={`Machine Wise Ticket Reports`} style={{display:'none'}} >
                <Column field="_id" header="Machine Id" body={CommonBodyForTable} ></Column>
                <Column field="ticketCount" header="Ticket Count" body={CommonBodyForTable} ></Column>
            </DataTable>


        </>
    )
}
