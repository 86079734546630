import React, {useState, useEffect, useRef} from 'react';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { srBodyTemplate, CommonBodyForTable, statusBodyTemplate, CommonBodyForFormattedStrTable, DateBodyForTable } from '../Common/CommonComponents';
import ApiServices from '../../service/api/ApiServices';
import { failureToast, successToast } from '../Common/CommonFunctions';

const apiServices = new ApiServices();
// =======================================================================================================
// =======================================================================================================

export const NotificationManager = () => {

    const addType = [
        { name : 'Category' , code : 'category'},
        { name : 'Sub Category' , code : 'subcategory'}
    ]

    const [loading, setLoading] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [settingDialog, setSettingDialog] = useState(false);
    const [settingCategories, setSettingCategories] = useState(null);
    const [checked, setChecked] = useState(true);
    const [categories, setCategories] = useState([]);
    const [addCatDialog, setAddCatDialog] = useState(false);
    const [categoryName, setCategoryName] = useState(null);
    const [subCategoryName, setSubCategoryName] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedcategory, setSelectedcategory] = useState(null);

    const [subCategoryDialog, setSubCategoryDialog] = useState(false);
    const [subOfCategory, setSubOfCategory] = useState(null);

    const toast = useRef(null);

    useEffect(() => {
        getData()
    }, [])

    const getData = async() =>{
        const response = await apiServices.getNotCategories({});
        console.log(response);
        setCategories(response?.data?.response_obj)
    }

    const createCategory = () =>{
		console.log('Clicked');
        setAddCatDialog(true)
	}

    const hideCatDialog = () =>{
        setAddCatDialog(false);
        setCategoryName(null);
        setSubCategoryName(null);
        setSelectedType(null);
        setSelectedcategory(null);
    }

    const submitCat = async(e) =>{
        e.preventDefault();
        console.log(selectedType?.code);
        if(selectedType?.code === 'category'){
            const response = await apiServices.addNotCategories({ "category": categoryName });
            response?.data?.response_code === 1 ? successToast(toast, 'Category Added Successfully') : failureToast(toast, 'Failed to add');
            getData()
        }else if(selectedType?.code === 'subcategory'){
            const payload = { "category": selectedcategory?.category, "subcategory": subCategoryName }
            const response = await apiServices.addNotSubCategories(payload);
            response?.data?.response_code === 1 ? successToast(toast, 'Sub Category Added Successfully') : failureToast(toast, 'Failed to add');
        }

        hideCatDialog()
    }

    const openSubDialog = (e, rowData)=> {
        setSubCategoryDialog(true)
        getSubCategories(rowData)
    }

    const getSubCategories = async(rowData) =>{
        const payload = { "category": rowData?.category }
        const response = await apiServices.getSubCategories(payload);
        console.log(response?.data?.response_obj);
        setSubOfCategory(response?.data?.response_obj)
    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                <h6 className="p-mt-3 p-mr-5">Categories</h6>
                {/* <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="Account" />
                    <Dropdown value={null} options={accounts} optionLabel="company" placeholder="Select Account" filter className="p-mr-2 multiselect-custom" />
                </div> */}
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </div>
            </span>
        </div>
    )


    const viewSubCategory = (rowData, props) =>{
        return (
            <>
                <span className="p-column-title">Actions</span>
                <Button icon="pi pi-cog" title="View Subcategory" className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={(e) => openSubDialog(e, rowData)}/>
            </>
        )
    }

	const rightToolbarTemplate = () => {
        return (
            <Button label="Create Category / Sub Category" icon="pi pi-plus" className="p-button-rounded p-custom-btn p-mr-2 p-mb-2" onClick={()=>{createCategory()}} /> 
	)}

    const catDialogFooter = () => (
        <>
            <Button label={'Close'} icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideCatDialog}/>
            <Button label={'Submit'} icon="pi pi-check" type='submit' form={'cat-form'} className="p-button-success p-mr-2 p-mb-2" />
        </>
    );

    const subCatFooterDialog = () =>(
        <>
            <Button label={'Close'} icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={()=>setSubCategoryDialog(false)}/>
        </>
    )
        
    return (
        <>
            <Toast ref={toast} position='center'/>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                    <Toolbar className="p-toolbar" right={rightToolbarTemplate}></Toolbar>
                    <DataTable value={categories}
                        dataKey="id" paginator rows={15} rowsPerPageOptions={[5, 10, 15, 20]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Categories"
                        globalFilter={globalFilter} emptyMessage="No Categories found." header={header} loading={loading} >
                        <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '6rem' }}></Column>
                        <Column field="category" header="Category" body={CommonBodyForTable}  ></Column>
                        <Column field="createdAt" header="Created Date & Time" body={DateBodyForTable} ></Column>
                        {/* <Column field="status" header="Active/Inactive" body={actionCheckedBodyTemplate}></Column> */}
                        <Column field="status" header="View Subcategory" body={viewSubCategory}></Column>
                    </DataTable>
                    </div>
                </div>
            </div>

            <Dialog visible={addCatDialog} style={{ width: '650px' }} header="Add Notification Category / Sub Category" modal className="p-fluid" footer={catDialogFooter} onHide={hideCatDialog} maximizable={true} blockScroll={true} >
                <form onSubmit={submitCat} id="cat-form">
                    <div className='p-grid'>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label htmlFor="type">Select Type</label>
                                <Dropdown id="type" value={selectedType} onChange={(e)=>{setSelectedType(e.target.value)}} filter options={addType} optionLabel="name" placeholder="Select Type"></Dropdown>
                            </div>
                        </div>

                        {
                            selectedType?.code === 'category' &&
                            <div className='p-col-12'>
                                <div className="p-field">
                                    <label htmlFor="category">Category Name</label>
                                    <InputText id="category" name='categoryName' placeholder='Category Name' defaultValue={categoryName} onChange={(e)=>{setCategoryName(e.target.value)}} autoComplete='off' required/>
                                </div>
                            </div>
                        }

                        {
                            selectedType?.code === 'subcategory' &&
                            <>    
                                <div className='p-col-12'>
                                    <div className="p-field">
                                        <label htmlFor="categoryL">Select Category</label>
                                        <Dropdown id="categoryL" value={selectedcategory} onChange={(e)=>{setSelectedcategory(e.target.value)}} filter options={categories} optionLabel="category" placeholder="Select Category"></Dropdown>
                                    </div>
                                </div>
                                <div className='p-col-12'>
                                    <div className="p-field">
                                        <label htmlFor="subcategory">Sub Category Name</label>
                                        <InputText id="subcategory" name='subcategory' placeholder='Sub Category Name' defaultValue={subCategoryName} onChange={(e)=>{setSubCategoryName(e.target.value)}} autoComplete='off' required/>
                                    </div>
                                </div>
                            </>
                        }
                    
                        </div>
                </form>
            </Dialog>

            <Dialog visible={subCategoryDialog} style={{ width: "550px" }} header={`Sub Categories`} modal className="p-fluid" onHide={()=>{setSubCategoryDialog(false)}} footer={subCatFooterDialog} maximizable={true} blockScroll={true} >
                <div className="card">
                <div className="p-grid crud-demo">
                    <DataTable value={subOfCategory} className="datatable-responsive" dataKey="_id" rowHover emptyMessage="No Sub Categories found." >
                        <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '6rem' }} ></Column>
                        <Column field="subcategory" header="Sub Category Name" sortable body={CommonBodyForTable} style={{ width: '15rem' }}></Column>
                        <Column field="status" header="Status" sortable body={CommonBodyForFormattedStrTable} style={{ width: '15rem' }}></Column>
                        {/* <Column header="Status" body={actionSubCategoryBodyTemplate}></Column> */}
                    </DataTable>
                </div>
                </div>
            </Dialog>

        </>  
    )
}
