import React, {useEffect,useState} from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { TabView } from 'primereact/tabview';
import { TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { AccountWiseCupChart } from './AccountWiseCupChart';
import { BeverageWiseCupChart } from './BeverageWiseCupChart';
import { AccountWiseTargetChart } from './AccountWiseTargetChart';
import { AccountWiseInventoryChart } from './AccountWiseInventoryChart';
import { FlavourWiseCupChart } from './FlavourWiseCupChart';
import { SalesOverallStat } from './SalesOverallStat';

// Services
import ApiServices from '../../service/api/ApiServices';
const apiServices =  new ApiServices();

export const MISReport = () =>{

    const [machineType, setMachineType] = useState({ name: 'CTVM', code:'ctvm' });
    const [accType, setAccType] = useState({ name: 'ACCOUNT', code: 'account', case: 'Account' });
    const [filterAccountsValue, setFilterAccountsValue] = useState({name:'All'});
    const [filterAccountsValue1, setFilterAccountsValue1] = useState({facname : 'All'});
    const [filterAccountsValue2, setFilterAccountsValue2] = useState({displayLabel : 'All'});
    const [multiselectValue, setMultiselectValue] = useState({ name: 'This Month', code:'this_month' });
    const [accountValues, setAccountValues] = useState(null);
    const [accountValues1, setAccountValues1] = useState(null);
    const [accountValues2, setAccountValues2] = useState(null);
    const [cupConSt, setcupConSt] = useState(null);
    const [facCupConSt, setfacCupConSt] = useState(null);
    const [TargetConSt, setTargetConSt] = useState(null);
    const [InventorySt, setInventorySt] = useState(null);
    const [distributorsStats, setDistributorsStats] = useState(null);
    const [totalMachines, setTotalMachines] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedFacId, setSelectedFacId] = useState(null);
    const [distributorsValue, setDistributorsValue] = useState(null);
    const [AccWiseChartLabels, setAccWiseChartLabels] = useState(null);
    const [AccWiseChartValues, setAccWiseChartValues] = useState(null);
    const [customDatesDialog, setCustomDatesDialog] = useState(false);
    const [FacWiseChartLabels, setFacWiseChartLabels] = useState(null);
    const [FacWiseChartValues, setFacWiseChartValues] = useState(null);
    const [AccWiseTargetLabels, setAccWiseTargetLabels] = useState(null);
    const [AccWiseTargetValues, setAccWiseTargetValues] = useState(null);
    const [SubWiseCupLabels, setSubWiseCupLabels] = useState(null);
    const [SubWiseCupValues, setSubWiseCupValues] = useState(null);
    const [subWiseTarLabels, setSubWiseTarLabels] = useState(null);
    const [subWiseTarValues, setSubWiseTarValues] = useState(null);
    const [fcWiseTarLabels, setFcWiseTarLabels] = useState(null);
    const [fcWiseTarValues, setFcWiseTarValues] = useState([]);
    const [bvgCupCData, setBvgCupCData] = useState(null);
    const [BvgCupCLabels, setBvgCupCLabels] = useState(null);
    const [BvgCupCValues, setBvgCupCvalues] = useState(null);
    const [BvgCupCColor, setBvgCupCColor] = useState(null);
    const [subMCupSt, setSubMCupSt] = useState(null);
    const [inventoryData, setInventoryData] = useState(null);
    const [InLoadedPackets, setInLoadedPackets] = useState(null);
    const [InUsedPackets, setInUsedPackets] = useState(null);
    const [InAccLabels, setInAccLabels] = useState(null);
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    const [flavourCupL, setFlavourCupL] = useState(null);
    const [flavourCupC, setFlavourCupC] = useState([]);
    const [flavourCupColor, setFlavourCupColor] = useState(null);
    const [machinCupConsumption, setMachinCupConsumption] = useState(null);
    const [machineTypeFilter, setMachineTypeFilter] = useState(false);
    const [machineTargetPerformance, setMachineTargetPerformance] = useState(null);
    const [mTargetPerformanceSt, setMTargetPerformanceSt] = useState(null);
    const [accountUsedPackets, setAccountUsedPackets] = useState(null);
    const [totalfacilities, setTotalfacilities] = useState(null);
    const [mInventorySt, setMInventorySt] = useState(null);
    const [sInventorySt, setSInventorySt] = useState(null);
    const [totalSubscription, setTotalSubscription] = useState(null);
    const [subscriptionCSt, setSubscriptionCSt] = useState(null);
    const [subscriptionTSt, setSubscriptionTSt] = useState(null);
    const [filteredArray, setFilteredArray] = useState([]);
    const [filteredMISArray, setFilteredMISArray] = useState([]);
    const [accountWiseCupData, setAccountWiseCupData] = useState(null);
    const [facWiseCupData, setFacWiseCupData] = useState(null);
    const [subWiseCupData, setSubWiseCupData] = useState(null);
    const [bulkCupConsumption, setBulkCupConsumption] = useState(null);
    const [misStatData, setMisStatData] = useState(null);
    const [allMachineData,setAllMachineData] = useState(null)
    const [loading, setLoading] = useState(true);
    
    const machineTypeValues = [
        { name: 'CTVM', code:'ctvm'}
        // { name: 'BULK', code:'bulk' }
    ];

    const AccountTypeValues = [
        { name: 'ACCOUNT', code:'account' , case: 'Account' },
        { name: 'FACILITY', code:'facility', case: 'Facility' },
        { name: 'SUBSCRIPTION', code:'subscription', case: 'Subscription' }
    ];

    const multiselectValues = [
        { name: 'All', code:'all' },
        { name: 'This Week', code:'this_week' },
        { name: 'This Month', code:'this_month' },
        { name: 'Last Month', code:'last_month' },
        { name: 'Custom', code:'custom' }
    ];

    // const multiselectValues = [
    //     { name: 'ALL', code:'all' },
    //     { name: 'THIS WEEK', code:'this_week' },
    //     { name: 'THIS MONTH', code:'this_month' },
    //     { name: 'LAST MONTH', code:'last_month' },
    //     { name: 'CUSTOM', code:'custom' }
    // ];

    const useStatInput = {
        date:{
            startDate: "", 
            endDate: ""},
        filter: "",
        value: "All"
    }
    const useStatInput1 = {filter: "account",value: "All"}
    const useStatInput3 = {userType: "",userid: ""}

    useEffect(() => {
        getInitialData()
    }, []);

    const getInitialData = () =>{

        const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 0);
        const lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(23, 59, 59, 0);
        var date = { startDate: new Date(firstDay).toISOString(), endDate:new Date(lastDay).toISOString() };
        setSelectedDate(date)
        getData({...useStatInput,filter:accType.code,date})
        // accWiseData({...useStatInput,filter:accType.code,date})
        var date = { startDate: new Date(firstDay).toISOString(), endDate:new Date(new Date()).toISOString() };
        setSelectedDate1(date)
        getMisPerformance({...useStatInput,filter:accType.code,date})
        getMisInventory({...useStatInput,filter:accType.code,date})
        getMachines()
    }

    const accWiseData = async (data) =>{

        console.log(data);

        // {
        //     "beverageWise": true,
        //    "date": {"startDate": "2023-05-31T18:30:00.000Z", "endDate": "2023-06-30T18:29:59.000Z"},
        //    "brand": [ "TAPRI", "TKAFFE"],
        //    "subscriptionType": "permanent", //["permanent", "demo", "all"]
        //    "userType": "owner",
        //    //"customer": "ld-em890gvlkwn7siq1", //Optional when customer is selected
        //     "userid": "usr-4kcz067ppk1bogdzy"
        // }

        const payload = {...data,
            "beverageWise": true,
            "brand": [ "TAPRI", "TKAFFE" ],
            "subscriptionType": "permanent"
            // "brand": [ "TAPRI", "TKAFFE", "MERI_TAPRI" ],
        }


        const response = await apiServices.accountWisePerformance(payload);
        console.log(response);

        const response2 = await apiServices.getUserFilter();

        console.log(response2?.data?.data);

        const arr = [];
        response2?.data?.data.forEach((element) => {
            const userid= element.userid;
            response?.data?.data.map((x) => {        
                if(userid == x.customer){
                    arr.push({...x,name:element.name}) 
                }
              });
        });

        const descending = arr.sort((a, b) => Number(b.cups) - Number(a.cups));
        console.log(descending);


    }

    const getData = async (data) =>{

        const response1 = await apiServices.findMisUsageStats(data);
        // console.log(response1.data.data[0].brvStats);
        const response2 = await apiServices.getUserFilter();
        console.log(response2?.data?.data);
        const response4 = await apiServices.getMaterialType();
        const response5 = await apiServices.getActiveCustomerCount();
        console.log(response5?.data?.data[0]?.count);

        // console.log(response2.data)
        response2?.data.data?.unshift( {name : 'All'} )
        setAccountValues(response2?.data?.data)
        
        // setDistributorsValue(response2?.data?.data?.length)
        setDistributorsValue(response5?.data?.data[0]?.count)

        const arr = [];
        response2?.data?.data.forEach((element) => {
            const userid= element.userid;
            response1?.data?.data[0]?.filter?.map((x) => {        
                if(userid == x._id){
                    arr.push({...x,name:element.name}) 
                }
              });
        });

        const arr1 = [];
        response4?.data?.mate?.forEach((element) => {
            const materialName = element.materialName;
            response1?.data?.data[0]?.brvStats?.map((x) => {        
                if(materialName == x._id){
                    arr1.push({...x,color:element.color}) 
                }
              });
        });

        const descending = arr.sort((a, b) => Number(b.cups) - Number(a.cups));

        setAccountWiseCupData(descending);
        setFacWiseCupData(arr)
        setcupConSt(descending[0]?.name)
        setAccWiseChartLabels(descending.map(a => a.name))
        setAccWiseChartValues(descending.map(a => a.cups))

        arr1.sort((a, b) => Number(b.totalCup) - Number(a.totalCup));
        
        setBvgCupCData(arr1)
        setBvgCupCLabels(arr1.map(a=>a._id))
        setBvgCupCvalues(arr1.map(a=>a.cups))
        setBvgCupCColor(arr1.map(a=>a.color))
        setFacWiseChartValues(response1?.data?.data[0]?.filter?.map(a => a.cups));
        setFacWiseChartLabels(response1?.data?.data[0]?.filter?.map(a => a._id?.facname));
        setMachinCupConsumption(response1?.data?.data[0]?.usagesStats[0]?.totalCup);
        setLoading(false)
    }

    const getData1 = async(data) => {

        console.log(data);

        const response1 = await apiServices.findMisUsageStats(data);
        // console.log(response1.data.data);
        const response2 = await apiServices.getUserFilter();
        // console.log(response2.data.data)
        const response3 = await apiServices.getFacilityByUser();
        // console.log(response3.data.data)
        const response4 = await apiServices.getMaterialType();

        response3?.data?.data?.unshift( {facname : 'All'} )
        setAccountValues1(response3?.data?.data)
        setTotalfacilities(response3?.data?.data?.length - 1)

        const arr = [];
        for(let i = 0; i < response1?.data?.data[0]?.filter?.length; i++){
            const cupCount = response1?.data?.data[0]?.filter[i].cups;
            arr.push({...response1?.data?.data[0]?.filter[i]._id,cups:cupCount})    
        }

        for(let i = 0; i < arr.length; i++){
            if(arr[i] != null){
                arr[i].name = arr[i]['facname'];
                arr[i]._id = arr[i]['facid'];
                delete arr[i].key1;
            }
        }

        var filtered = arr.filter(function (el) {
            return el.name != null;
          });


        const arr1 = [];
        response4?.data?.mate.forEach((element) => {
            const materialName = element.materialName;
            response1?.data?.data[0]?.brvStats?.map((x) => {        
                if(materialName == x._id){
                    arr1.push({...x,color:element.color}) 
                }
              });
        });


        console.log(filtered)
        // setAccountValues1(filtered)
        const descending = filtered.sort((a, b) => Number(b.cups) - Number(a.cups));
        setFacWiseCupData(descending)

        setcupConSt(descending[0]?.name)
        setFacWiseChartLabels(descending.map(a => a.name))
        setFacWiseChartValues(descending.map(a => a.cups))

        console.log(response1?.data?.data[0]?.filter[0]?._id?.facname);

        setfacCupConSt(response1?.data?.data[0]?.filter[0]?._id?.facname)

        arr1.sort((a, b) => Number(b.cups) - Number(a.cups));
        setBvgCupCData(arr1)
        setBvgCupCLabels(arr1.map(a=>a._id))
        setBvgCupCvalues(arr1.map(a=>a.cups))
        setBvgCupCColor(arr1.map(a=>a.color))
        setLoading(false)

        setMisStatData(response1?.data?.data[0])
    }

    const getData2 = async(data) => {
        
        const response1 = await apiServices.findMisUsageStats(data);
        const response2 = await apiServices.findMachines()
        const response3 = await apiServices.getMaterialType();

        console.log(response1?.data?.data);

        
        setSubWiseCupLabels(response1?.data?.data[0]?.filter?.map(a=>a._id))
        setSubWiseCupValues(response1?.data?.data[0]?.filter?.map(a=>a.cups))


        const arr = [];
        response1?.data.data[0]?.filter.map((x)=>{
            response2?.data?.data.map((y)=>{ if(x.subid == y?.subid){
                    arr.push({...x,comapnyName:y.siteName})
                }
            })
        })

        console.log(arr);

        const arr1 = [];
        response3.data.mate.forEach((element) => {
            const materialName = element.materialName;
            response1?.data?.data[0].brvStats?.map((x) => {        
                if(materialName == x._id){
                    arr1.push({...x,color:element.color}) 
                }
              });
        });

        console.log(arr1);

        arr1.sort((a, b) => Number(b.cups) - Number(a.cups));
        setBvgCupCData(arr1)
        setBvgCupCLabels(arr1.map(a=>a._id))
        setBvgCupCvalues(arr1.map(a=>a.cups))
        setBvgCupCColor(arr1.map(a=>a.color))

        setSubMCupSt(response1?.data?.data[0]?.usagesStats[0]?.totalCup)

        setLoading(false)
        if(arr.length > 0){
            setSubscriptionCSt(arr[0]?._id + ' / ' + arr[0]?.subid + ' / ' + arr[0]?.comapnyName);
        }
    }

    const getSubscribed = async (data) =>{
        const response1 = await apiServices.findMisUsageStats(data);
        const response4 = await apiServices.getMaterialType();

        const arr1 = [];
        response4?.data?.mate.forEach((element) => {
            const materialName = element.materialName;
            response1?.data?.data[0]?.brvStats?.map((x) => {        
                if(materialName == x._id){
                    arr1.push({...x,color:element.color}) 
                }
              });
        });

        setSubWiseCupLabels(response1?.data?.data[0]?.filter?.map(a=>a._id))
        setSubWiseCupValues(response1?.data?.data[0]?.filter?.map(a=>a.cups))
        setBvgCupCData(arr1)
        setBvgCupCLabels(arr1?.map(a=>a._id))
        setBvgCupCvalues(arr1?.map(a=>a.cups))

        setSubMCupSt(response1?.data?.data[0].usagesStats[0].totalCup)

        setLoading(false)
    }

    const getMisPerformance = async(data) =>{

        const response1 = await apiServices.getMisPerformance(data);
        console.log(response1?.data?.data)
        const response2 = await apiServices.getUserFilter();

        const arr = [];
        response2?.data?.data?.forEach((element) => {
            const userid= element.userid;
            response1?.data?.data?.map((x) => { if(userid == x._id){arr.push({...x,name:element.name})}});
        });


        console.log(arr);

        setFilteredArray(arr)
        setFilteredMISArray(response1?.data?.data)

        setSubWiseCupData(response1?.data?.data)

        setTargetConSt(arr.sort((a, b) => Number(b.ratio) - Number(a.ratio))[0]?.name)
        setSubWiseCupLabels(response1?.data?.data.sort((a, b) => Number(b.hcup) - Number(a.hcup)).map(a=>a.name));
        setSubWiseCupValues(response1?.data?.data.sort((a, b) => Number(b.hcup) - Number(a.hcup)).map(a=>a.hcup));
        setMachineTargetPerformance(response1?.data?.data[0]?.ratio)

        // setMTargetPerformanceSt(response1?.data?.data[0]?.name)
        setMTargetPerformanceSt(response1?.data?.data.sort((a, b) => Number(b.ratio) - Number(a.ratio))[0]?.name)
        
    }
    // totalwaeihght * 0.08 floor

    const getFacilityMisPerformance = async(data) =>{
        const response1 = await apiServices.getMisPerformance(data);
        setFcWiseTarLabels(response1?.data?.data.sort((a, b) => Number(b.ratio) - Number(a.ratio)).map(a=>a.name));
        setFcWiseTarValues(response1?.data?.data.sort((a, b) => Number(b.ratio) - Number(a.ratio)).map(a=>a.ratio));
        setFilteredMISArray(response1?.data?.data)
    }

    
    const getTargetMisPerformance = async(data) =>{
        const response1 = await apiServices.getMisPerformance(data);
        const response2 = await apiServices.findMachines()
        
        const arr = [];
        response1?.data.data.map((x)=>{
            response2?.data?.data.map((y)=>{ if(x.subid == y?.subid){
                    arr.push({...x,comapnyName:y.siteName})
                }
            })
        })

        if(arr?.length > 0){
            setSubscriptionTSt(arr[0]?.id + ' / ' + arr[0]?.name + ' / ' + arr[0]?.comapnyName)
        }
    }

    const getSubscriptions = async() => {
        const response1 = await apiServices.getSubscription();
        // setTotalSubscription('284')        
        setTotalSubscription(response1?.data?.data?.length)        
        const result = response1?.data?.data?.map((x) => {return {...x,displayLabel: x.subid + ' / ' + x.machineId + ' / '  + x.facilitydata?.facname}});
        result?.unshift( {displayLabel : 'All'} )
        setAccountValues2(result);
    }

    const getMisInventory = async (data) =>{
        const response = await apiServices.getMisInventory(data);
        setInventoryData(response?.data?.data)
        setInLoadedPackets(response?.data?.data.map(a => a.loaded_packets))
        setInUsedPackets(response?.data?.data?.map(a => a.used_packets))
        setInAccLabels(response?.data?.data.map(a => a.name))
        setInventorySt(response?.data?.data[0]?.name)
        setAccountUsedPackets(response?.data?.data[0]?.total_packets)
        setMInventorySt(response?.data?.data[0]?.name)
    }

    const getBulkData = async() =>{
        const response = await apiServices.getDistributorsStats();
        setDistributorsStats(response?.data?.stats)
        const response2 = await apiServices.getDistributorMachinesStats();
        setTotalMachines(response2?.data?.stats)
        const response1 = await apiServices.getBulkInventory();
        const response4 = await apiServices.getMaterialType();
        
        const arr = [];
        response4?.data?.mate.forEach((element) => {
            const materialName = element.materialName;
            response1?.data?.data[0].inv_flv.map((x) => {        
                if(materialName == x._id){
                    arr.push({...x,color:element.color}) 
                }
              });
        });

        arr.sort((a, b) => parseFloat(b.totalcups) - parseFloat(a.totalcups));

        setFlavourCupL(arr.map(a => a._id))
        setFlavourCupC(arr.map(a => a.totalcups))
        setFlavourCupColor(arr.map(a => a.color))

        const totalBulk = response1?.data?.data[0]?.stats[0]?.totalweight * 0.08
        const cupConsumption =  Math.floor(totalBulk);
        setBulkCupConsumption(cupConsumption)
    }

    const getMachines = async() =>{
        const response = await apiServices.findMachines()
        setAllMachineData(response?.data?.data)
    }

    
    const filterMachineTypeAction = async(e) =>{
        setMachineType(e.value)
        setAccType({ name: 'ACCOUNT', code: 'account', case: 'Account' })
        setFilterAccountsValue({name:'All'})
        setFilterAccountsValue1({facname : 'All'})
        setFilterAccountsValue2({displayLabel : 'All'})

        if(e.value.code == 'ctvm'){
            setMachineTypeFilter(false)
            getInitialData()
        }else if(e.value.code == 'bulk'){
            setMachineTypeFilter(true)
            getBulkData()
        }
    }

    const filterAccType = (e) =>{
        setLoading(true)
        setAccType(e.value)
        const accvalue = e.value.code;
        getMisInventory({...useStatInput,filter:accvalue,value:selectedUserId?selectedUserId:'All',date:selectedDate1})
        getMisPerformance({...useStatInput,filter:accvalue,value:selectedUserId?selectedUserId:'All',date:selectedDate1} )
        
        if(e.value.code == 'account'){
            getData({...useStatInput,filter:accvalue,value:selectedUserId?selectedUserId:'All',date:selectedDate})
            
        }else if(e.value.code == 'facility'){
            getData1({...useStatInput,filter:accvalue,value:selectedUserId?selectedUserId:'All',date:selectedDate})
            // getFacilitiesByUser( {...useStatInput3,userType:e.value.userType,userid:e.value.userid})
        }else if(e.value.code == 'subscription'){
            getData2({...useStatInput,filter:accvalue,value:selectedUserId?selectedUserId:'All',date:selectedDate})
            getSubscriptions()
            getTargetMisPerformance({...useStatInput,filter:accvalue,value:selectedUserId?selectedUserId:'All',date:selectedDate1})   
        }
    }

    const handleSubmit = (e) =>{
        setCustomDatesDialog(false)
        e.preventDefault()
        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        var date = { startDate: new Date(startt).toISOString(), endDate:new Date(endd).toISOString() };
        setSelectedDate(date)
        setSelectedDate1(date)

        if(accType.code == 'account'){
            getData({...useStatInput,filter:accType.code,value:selectedUserId?selectedUserId:'All',date})
            getMisPerformance({...useStatInput,filter:accType.code,value:selectedUserId?selectedUserId:'All',date})
            getMisInventory({...useStatInput,filter:accType.code,value:selectedUserId?selectedUserId:'All',date})
        }

            
        if(accType.code == 'facility'){

            if(filterAccountsValue1.facname == 'All'){
                getFacilityMisPerformance({filter: "facility",value: "All",date:date})
                getData1({filter: "facility",value: "All",date:date})
                getMisInventory({filter: "facility",value: "All",date:date})
            }else{

                getFacilityMisPerformance({...useStatInput,value:selectedFacId,userid:selectedUserId,filter:accType.code,date:date})
                getData1({...useStatInput,filter:accType.code,value:selectedFacId,userid:selectedUserId,date:date})
                getMisInventory({...useStatInput,filter:accType.code,value:selectedUserId,date:date})
            }
        }


        if(accType.code == 'subscription'){
            if(filterAccountsValue2.displayLabel == 'All'){
                getData2({filter: 'subscription',value:'All',date})
                getMisPerformance({filter: "subscription", value:'All',date})
            }else{
                getData2({filter: 'subscription',value: filterAccountsValue2.machineId,date})
                getMisPerformance({filter: "subscription", value: filterAccountsValue2.machineId,date})
            }
        }
    }   

    const OnChangeTimeValue = (e) =>{
        setLoading(true)
        setMultiselectValue(e.value)
        const currdate = new Date()

        switch (e.value.code) {
        case 'all':

            setSelectedDate(null)
            setSelectedDate1(null)

            if(accType.code == 'account'){
                getData({...useStatInput1,value:selectedUserId?selectedUserId:'All'})
                getMisPerformance({...useStatInput1,value:selectedUserId?selectedUserId:'All'})
                getMisInventory({...useStatInput1,value:selectedUserId?selectedUserId:'All'})
            }

            if(accType.code == 'facility'){

                if(filterAccountsValue1.facname == 'All'){
                    getFacilityMisPerformance({filter: "facility",value: "All"})
                    getData1({filter: "facility",value: "All"})
                    getMisInventory({filter: "facility",value: "All"})
                }else{
                    getFacilityMisPerformance({value:selectedFacId,userid:selectedUserId,filter:accType.code})
                    getData1({filter:accType.code,value:selectedFacId,userid:selectedUserId})
                    getMisInventory({filter:accType.code,value:selectedFacId,userid:selectedUserId})
                }
            }

            if(accType.code == 'subscription'){

                getData2({filter: 'subscription',value: filterAccountsValue2.displayLabel != 'All' ? filterAccountsValue2.machineId : 'All'})
                getMisPerformance({filter: "subscription", value: filterAccountsValue2.displayLabel != 'All' ? filterAccountsValue2.machineId : 'All'})
                getMisInventory({filter: 'subscription',value:filterAccountsValue2.displayLabel != 'All' ? filterAccountsValue2.machineId : 'All'})
            }

        break;
        case 'this_week':

            var firstday = new Date(currdate.setDate(currdate.getDate() - currdate.getDay())).setHours(0, 0, 0, 0);
            var lastday = new Date(currdate.setDate(currdate.getDate() - currdate.getDay()+6)).setHours(23, 59, 59, 0);
            var date = { startDate: new Date(firstday).toISOString(), endDate:new Date(lastday).toISOString() };            
            setSelectedDate(date)

            var date1 = { startDate: new Date(firstday).toISOString(), endDate:new Date(new Date()).toISOString() };
            setSelectedDate1(date1)

            if(accType.code == 'account'){
                getData({...useStatInput,filter:accType.code,value:selectedUserId?selectedUserId:'All',date})
                getMisPerformance({...useStatInput,filter:accType.code,value:selectedUserId?selectedUserId:'All',date})
                getMisInventory({...useStatInput,filter:accType.code,value:selectedUserId?selectedUserId:'All',date})
            }

            if(accType.code == 'facility'){

                if(filterAccountsValue1.facname == 'All'){
                    getFacilityMisPerformance({filter: "facility",value: "All",date:date1})
                    getData1({filter: "facility",value: "All",date:date1})
                    getMisInventory({filter: "facility",value: "All",date:date1})
                }else{
                    getFacilityMisPerformance({...useStatInput,value:selectedFacId,userid:selectedUserId,filter:accType.code,date:date1})
                    getData1({...useStatInput,filter:accType.code,value:selectedFacId,userid:selectedUserId,date:date1})
                    getMisInventory({...useStatInput,filter:accType.code,value:selectedUserId?selectedUserId:'All',date:date1})
                }
            }
            
            if(accType.code == 'subscription'){
                if(filterAccountsValue2.displayLabel == 'All'){
                    getData2({filter: 'subscription',value:'All',date})
                    getMisPerformance({filter: "subscription", value:'All',date})
                }else{
                    getData2({filter: 'subscription',value: filterAccountsValue2.machineId,date})
                    getMisPerformance({filter: "subscription", value: filterAccountsValue2.machineId,date})
                }
            }

        break;
        case 'this_month':
            var firstDay = new Date(currdate.getFullYear(), currdate.getMonth(), 1).setHours(0, 0, 0, 0);
            var lastDay = new Date(currdate.getFullYear(), currdate.getMonth() + 1, 0).setHours(23, 59, 59, 0);
            var date = { startDate: new Date(firstDay).toISOString(), endDate:new Date(lastDay).toISOString() };
            setSelectedDate(date)

            var date1 = { startDate: new Date(firstDay).toISOString(), endDate:new Date(new Date()).toISOString() };
            setSelectedDate1(date1)

            
            if(accType.code == 'account'){
                getData({...useStatInput,filter:accType.code,value:selectedUserId?selectedUserId:'All',date})
                getMisPerformance({...useStatInput,filter:accType.code,value:selectedUserId?selectedUserId:'All',date:date1})
                getMisInventory({...useStatInput,filter:accType.code,value:selectedUserId?selectedUserId:'All',date:date1})
            }

            if(accType.code == 'facility'){

                if(filterAccountsValue1.facname == 'All'){
                    getFacilityMisPerformance({filter: "facility",value: "All",date:date1})
                    getData1({filter: "facility",value: "All",date:date1})
                    getMisInventory({filter: "facility",value: "All",date:date1})
                }else{
                    getFacilityMisPerformance({...useStatInput,value:selectedFacId,userid:selectedUserId,filter:accType.code,date:date1})
                    getData1({...useStatInput,filter:accType.code,value:selectedFacId,userid:selectedUserId,date:date1})
                    getMisInventory({...useStatInput,filter:accType.code,value:selectedFacId,userid:selectedUserId,date:date1})
                }
            }

            if(accType.code == 'subscription'){

                if(filterAccountsValue2.displayLabel == 'All'){
                    getData2({filter: 'subscription',value:'All',date1})
                    getMisPerformance({filter: "subscription", value:'All',date1})
                }else{
                    getData2({filter: 'subscription',value:filterAccountsValue2.machineId,date:date1})
                    getMisPerformance({filter: "subscription", value:filterAccountsValue2.machineId,date:date1})
                }
            }

        break;
        case 'last_month':
            const lastMonthStart = new Date(currdate.getFullYear(), currdate.getMonth()-1, 1).setHours(0, 0, 0, 0);
            const lastMonthEnd = new Date(currdate.getFullYear(), currdate.getMonth(), 0).setHours(23, 59, 59, 0);
            var date = { startDate: new Date(lastMonthStart).toISOString(), endDate:new Date(lastMonthEnd).toISOString() };
            setSelectedDate(date)
            setSelectedDate1(date)


            if(accType.code == 'account'){
                getData({...useStatInput,filter:accType.code,value:selectedUserId?selectedUserId:'All',date})
                getMisPerformance({...useStatInput,filter:accType.code,value:selectedUserId?selectedUserId:'All',date})
                getMisInventory({...useStatInput,filter:accType.code,value:selectedUserId?selectedUserId:'All',date})
            }


            if(accType.code == 'facility'){

                if(filterAccountsValue1.facname == 'All'){
                    getFacilityMisPerformance({filter: "facility",value: "All",date})
                    getData1({filter: "facility",value: "All",date})
                    getMisInventory({filter: "facility",value: "All",date})
                }else{
                    getFacilityMisPerformance({...useStatInput,value:selectedFacId,userid:selectedUserId,filter:accType.code,date})
                    getData1({...useStatInput,filter:accType.code,value:selectedFacId,userid:selectedUserId,date})
                    getMisInventory({...useStatInput,filter:accType.code,value:selectedFacId,userid:selectedUserId,date})
                }
            }

            if(accType.code == 'subscription'){

                if(filterAccountsValue2.displayLabel == 'All'){
                    getData2({filter: 'subscription',value:'All',date1})
                    getMisPerformance({filter: "subscription", value:'All',date1})
                }else{
                    getData2({filter: 'subscription',value:filterAccountsValue2.machineId,date:date1})
                    getMisPerformance({filter: "subscription", value:filterAccountsValue2.machineId,date:date1})
                }
            }

        break;
        case 'custom':
            setCustomDatesDialog(true);
        break;

        default:
        }
    } 

    // const getFacilitiesByUser = async(data) =>{
    //     const response = await apiServices.getFacilityByUser();
    //     console.log(response.data)
    //     setTotalfacilities(response.data.data.length)
    // }
    
    const selectAccActions = (e) => {

        setFilterAccountsValue(e.value)

        if(e.value.name == 'All'){
            getInitialData()
        }else{   
            setLoading(true)
            setSelectedUserId(e.value.userid)
            getData({...useStatInput1,value:e.value.userid,date:selectedDate})
            getMisPerformance({...useStatInput1,value:e.value.userid,date:selectedDate1})
            getMisInventory({...useStatInput1,value:e.value.userid,date:selectedDate})
        }
    }

    const selectAccActions1 = (e) =>{

        setFilterAccountsValue1(e.value)

        if(e.value.facname == 'All'){
            getData1({...useStatInput,filter:'facility',value:'All',date:selectedDate1})
            getMisInventory({...useStatInput,filter:'facility',value:'All',date:selectedDate1})
            getMisPerformance({...useStatInput,filter:'facility',value:'All',date:selectedDate1} )

        }else{
            setLoading(true)
            setSelectedUserId(e.value.userid)
            setSelectedFacId(e.value.facid)
            getMisInventory({...useStatInput,filter:accType.code,value:e.value.facid,userid:e.value.userid,date:selectedDate});
            getData1({...useStatInput,filter:accType.code,value:e.value.facid,userid:e.value.userid,date:selectedDate})
            
            getFacilityMisPerformance({...useStatInput,value:e.value.facid,userid:e.value.userid,filter:accType.code,date:selectedDate1})   
        }
    }

    const selectAccActions2 = (e) =>{
        
        setLoading(true)
        console.log(e.value);

        if(e.value.displayLabel == 'All'){

            setFilterAccountsValue2(null)
            getMisInventory({...useStatInput,filter:'subscription',value:'All',date:selectedDate1})
            getMisPerformance({...useStatInput,filter:'subscription',value:'All',date:selectedDate1} )
            getData2({...useStatInput,filter:'subscription',value:'All',date:selectedDate})
            getSubscriptions()
            getTargetMisPerformance({...useStatInput,filter:'subscription',value:'All',date:selectedDate1})

        }else{
            setFilterAccountsValue2(e.value)
            setSelectedUserId(e.value.userid)
            getSubscribed({...useStatInput,value:e.value.machineId,filter:accType.code,date:selectedDate})
            getMisPerformance({...useStatInput,value:e.value.machineId,filter:accType.code,date:selectedDate1})
            getMisInventory({...useStatInput,filter:accType.code,value:e.value.subid,date:selectedDate})
        }
    }

    const hideCustomDialog = () =>{setCustomDatesDialog(false)}

    const leftToolbarTemplate = () => {
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="CVES TYPE" />
                    <Dropdown value={machineType} onChange={filterMachineTypeAction} options={machineTypeValues} optionLabel="name" placeholder="Select Machine Type " filter className="p-mr-2 multiselect-custom" />
                </div>

                {
                    machineTypeFilter== false ?
                    <>
                        <div className="p-inputgroup">
                            <Button className='custom-group-btn' label="ACC TYPE" />
                            <Dropdown value={accType} onChange={filterAccType} options={AccountTypeValues} optionLabel="name" placeholder="Select Account Type" filter className="p-mr-2 multiselect-custom" />
                        </div>
                        {
                            accType.code == 'account' &&
                            <div className="p-inputgroup">
                                <Button className='custom-group-btn' label="ACCOUNT" />
                                <Dropdown value={filterAccountsValue} onChange={selectAccActions} options={accountValues} optionLabel="name" placeholder="Select Account" filter className="p-m-0 multiselect-custom" />
                            </div>
                        }

                        {
                            accType.code == 'facility' &&
                            <div className="p-inputgroup">
                                <Button className='custom-group-btn' label="FACILITY" />
                                <Dropdown value={filterAccountsValue1} onChange={selectAccActions1} options={accountValues1} optionLabel="facname" placeholder="Select Facility" filter className="p-m-0 multiselect-custom" />
                            </div>
                        }

{/* Hello */}
                        {
                            accType.code == 'subscription' &&
                            <div className="p-inputgroup">
                                <Button className='custom-group-btn' label="SUBSCRIPTION" />
                                <Dropdown value={filterAccountsValue2} onChange={selectAccActions2} options={accountValues2} optionLabel="displayLabel" placeholder="Select" filter className="p-m-0 multiselect-custom" />
                            </div>
                        }

                    </>
                    :
                    <></>
                }
            </>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="DURATION" />
                    <Dropdown value={multiselectValue} onChange={OnChangeTimeValue} options={multiselectValues} optionLabel="name" placeholder="Select Filter" filter
                        className="p-m-0 multiselect-custom" />    
                </div>  
            </>
        )
    }

    return (
        <>
            <div className='card'>
            <Toolbar className="p-toolbar" left={leftToolbarTemplate} right={machineTypeFilter?'':rightToolbarTemplate}></Toolbar>
            </div>

            <div className='p-grid'>
                <div className='p-col-12'>
                    <SalesOverallStat subMCupSt={subMCupSt} filterAccountsValue2={filterAccountsValue2}  bulkCupConsumption={bulkCupConsumption} inventoryData={inventoryData} fcWiseTarValues={fcWiseTarValues} misStatData={misStatData} filterAccountsValue1={filterAccountsValue1} loading={loading} machineType={machineType} sInventorySt={sInventorySt} subscriptionTSt={subscriptionTSt} subscriptionCSt={subscriptionCSt} totalSubscription={totalSubscription} mInventorySt={mInventorySt} mTargetPerformanceSt={mTargetPerformanceSt} facCupConSt={facCupConSt} totalfacilities={totalfacilities} accountUsedPackets={accountUsedPackets} InUsedPackets={InUsedPackets} machineTargetPerformance={machineTargetPerformance}  machinCupConsumption={machinCupConsumption} filterAccountsValue={filterAccountsValue} accType={accType} totalMachines={totalMachines} distributorsStats={distributorsStats} machineTypeFilter={machineTypeFilter} InventorySt={InventorySt} TargetConSt={TargetConSt} distributorsValue={distributorsValue} cupConSt={cupConSt} />
                    {/* <SalesTimeStat/> */}
                    {/* <PerformanceStat superstarAcc={superstarAcc}/> */}
                </div>
            </div>

            <div className="p-grid crud-demo">
                <div className="p-col-12">
                <div className="card">

                    {
                        machineTypeFilter  ? 
                        
                        <TabView>
                            <TabPanel header="Flavourwise Cup Consumption">
                            <FlavourWiseCupChart flavourCupL={flavourCupL} flavourCupC={flavourCupC} flavourCupColor={flavourCupColor} />
                            </TabPanel>
                        </TabView>
                        :
                        <TabView>
                            <TabPanel header={`${accType.case}-Wise Cup Consumption`} >
                                <AccountWiseCupChart subWiseCupData={subWiseCupData} facWiseCupData={facWiseCupData} accountWiseCupData={accountWiseCupData}  accType={accType} SubWiseCupLabels={SubWiseCupLabels} SubWiseCupValues={SubWiseCupValues} FacWiseChartLabels={FacWiseChartLabels} FacWiseChartValues={FacWiseChartValues} AccWiseChartLabels={AccWiseChartLabels} AccWiseChartValues={AccWiseChartValues}/>
                            </TabPanel>
                            <TabPanel header={`${accType.case}-Wise Target`}>
                                <AccountWiseTargetChart filteredMISArray={filteredMISArray} filteredArray={filteredArray}  accType={accType} fcWiseTarLabels={fcWiseTarLabels} fcWiseTarValues={fcWiseTarValues} subWiseTarLabels={subWiseTarLabels} subWiseTarValues={subWiseTarValues} AccWiseTargetLabels={AccWiseTargetLabels} AccWiseTargetValues={AccWiseTargetValues} />
                                {/* getMisPerformance={getMisPerformance} min={min} setMin={setMin} max={max} setMax={setMax} */}
                            </TabPanel>
                            <TabPanel header="Beverage-Wise Cup Consumption">
                                <BeverageWiseCupChart bvgCupCData={bvgCupCData} BvgCupCLabels={BvgCupCLabels} BvgCupCValues={BvgCupCValues} BvgCupCColor={BvgCupCColor}/>
                            </TabPanel>
                            <TabPanel header={`${accType.case}-Wise Inventory`}>
                                <AccountWiseInventoryChart inventoryData={inventoryData} accType={accType} InAccLabels={InAccLabels} InLoadedPackets={InLoadedPackets} InUsedPackets={InUsedPackets} />
                            </TabPanel>
                        </TabView>
                    }
                   

                    {/* <TabPanel header="Flavourwise Cup Consumption">
                        <BeverageWiseCupChart />
                    </TabPanel>
                    <TabPanel header="Top 10 Distributors Performance wise">
                        <PerformanceWiseDist/>
                    </TabPanel> */}

                </div>
                </div>
            </div>

            <Dialog visible={customDatesDialog} style={{ width: '550px' }} header="Custom Filter"  onHide={hideCustomDialog} modal className="p-fluid">
                <form onSubmit={handleSubmit}>
                    <div className='p-grid'>
                        <div className='p-col-6'>
                                <label htmlFor="from">From</label>
                                <InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => {setCustomStart(e.value) } } required autoFocus/>
                        </div>
                        <div className='p-col-6'>
                                <label htmlFor="to">To</label>
                                <InputText id="to" type="date" name='end_date' value={customEnd} onChange={(e) => {setCustomEnd(e.value)}} required/>
                        </div>
                    </div>
                    <div className="p-dialog-footer p-mt-4">
                        <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
                        <button className="p-button p-button p-button-success" type="submit"><span className="p-button-icon p-c pi pi-check p-button-icon-left"></span><span className="p-button-label p-c">Submit</span><span className="p-ink"></span></button>
                    </div>
                </form>
            </Dialog>

        </>
    )
}