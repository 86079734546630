import React, { useState ,useEffect } from 'react';
import { Chart } from 'primereact/chart';

import { barDataForBarChart } from '../Common/CommonFunctions';
import { barOptionsForBarChart } from '../Common/CommonFunctions';

export const DayQrTransactionsChart = props => {

    const [labels,setLabels] = useState([])
    const [values,setValues] = useState([])

    useEffect(()=>{
        console.log(props.transChartData);
        getChartData(props.transChartData)
    },[props.transChartData])

    const getChartData = (data) =>{
        const dateData = data?.map((x)=>x._id);
        setLabels(dateData)
        const trans = data?.map((x)=>x.txn)
        setValues(trans)
    }

    const barData = barDataForBarChart(labels,values,'No of Transactions')
    const barOptions = barOptionsForBarChart('Date','No of transaction')


    return (
       <>
            <Chart type="bar" data={barData} options={barOptions} />
       </>
    )
}