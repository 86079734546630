import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";

// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

export const CustomerDialog = (props) =>{

    const gender = [
        { name : 'Mr.', code : 'Mr' },
        { name : 'Mrs.', code : 'Mrs' }
    ]

    const TemplateDefault = {
        prefix : "Mr"
    }

    const [openCustomerDialog,setOpenCustomerDialog] = useState(false)
    const [customerData,setCustomerData] = useState(null)
    const [customerDataTemplate,setCustomerDataTemplate] = useState(TemplateDefault)
    const [country,setCountry] = useState(null)
    const [state,setState] = useState(null)
    const [city,setCity] = useState(null)
    const [selectedCountry,setSelectedCountry] = useState(null)
    const [selectedState,setSelectedState] = useState(null)
    const [selectedCity,setSelectedCity] = useState(null)
    const [cnoVerify,setCnoVerify] = useState(null)
    const [contactNum,setContactNum] = useState(null)
    const [selectedGender,setSelectedGender] = useState({ name : 'Mr.', code : 'Mr' })

    
    const toast = useRef(null);

    useEffect(()=>{
        setOpenCustomerDialog(props.editCustomerDialog)
    },[props.editCustomerDialog])
    
    useEffect(()=>{
        getCustomerData(props.customerData)
    },[props.customerData])

    useEffect(()=> {
        setCountry(props.countries)
    }, [props.countries])

    // const getCountry = async() =>{
    //     const res = await apiServices.getCountry();
    //     setCountry(res?.data)
    // }

    const getCustomerData = async(data) =>{
        if(data){
            console.log("data", data);
            const payload = data?.prefix ? data : {...customerDataTemplate,...data};
            setCustomerDataTemplate(payload)
            setCustomerData(data)
            setContactNum(data?.cno)
            setSelectedCountry(data?.addr?.country)
            const res = await apiServices.getState({ country_id: data?.addr?.country?.id });
            setState(res?.data);
            setSelectedState(data?.addr?.state)
            const res1 = await apiServices.getCity({ state_id: data?.addr?.state?.id });
            setCity(res1?.data);
            setSelectedCity(data?.addr?.city)
            
            data?.prefix == 'Mrs' ? setSelectedGender({ name : 'Mrs.', code : 'Mrs' }) : setSelectedGender({ name : 'Mr.', code : 'Mr' })
        }
    }

    const changeCountry = async(e) =>{
        setSelectedCountry(e.target.value)
        const res = await apiServices.getState({ country_id: e.target.value.id });
        setState(res?.data);
        setSelectedState(null)
        setSelectedCity(null)
        setCustomerDataTemplate({...customerDataTemplate,addr:{...customerDataTemplate.addr,[e.target.name]:e.target.value} })
    }

    const changeState = async(e) =>{
        setSelectedState(e.target.value)
        const res1 = await apiServices.getCity({ state_id: e.target.value.id });
        setCity(res1.data);
        setCustomerDataTemplate({...customerDataTemplate,addr:{...customerDataTemplate.addr,[e.target.name]:e.target.value} })
    }

    const changeCity = (e) =>{
        setSelectedCity(e.target.value)
        setCustomerDataTemplate({...customerDataTemplate,addr:{...customerDataTemplate.addr,[e.target.name]:e.target.value} })
    }

    const callVerification = async(data) =>{
        if(contactNum != data){
            const res = await apiServices.verifyUser({cno: data});
            res?.data?.success ? setCnoVerify(true) : setCnoVerify(false)
        }else{
            setCnoVerify(false)
        }
    }

    const changeData = (e) =>{


        if(e.target.name == 'cno'){
            if(e.target.value.length == 10){
                const contact = '+91' + e.target.value;
                callVerification(contact)
                setCustomerDataTemplate({...customerDataTemplate,[e.target.name]:contact})
            }else if(e.target.value.length > 10){
                callVerification(e.target.value)
                setCustomerDataTemplate({...customerDataTemplate,[e.target.name]:e.target.value})
            }else{
                setCnoVerify(false)
            }
            
        }else if(e.target.name == 'name' || e.target.name == 'lastName' || e.target.name == 'email' || e.target.name == 'cup_cost' || e.target.name == 'beverages_offset' || e.target.name == 'gstNumber' ){

            setCustomerDataTemplate({...customerDataTemplate,[e.target.name]:e.target.value})

        }else if(e.target.name == 'addr' || e.target.name == 'pincode'){

            setCustomerDataTemplate({...customerDataTemplate,addr:{...customerDataTemplate.addr,[e.target.name]:e.target.value} })

        }else if(e.target.name == 'company'){
            setCustomerDataTemplate({...customerDataTemplate,billing_details:{...customerDataTemplate.billing_details,[e.target.name]:e.target.value} })

        }else if(e.target.name == 'prefix'){
            setSelectedGender(e.target.value)
            setCustomerDataTemplate({...customerDataTemplate,[e.target.name]:e.target.value.code})
        }   
        
    }


    const onCustomerSubmit = async(e) =>{
        e.preventDefault();

        console.log(customerDataTemplate)
        const response = await apiServices.updateUser(customerDataTemplate)
        console.log(response);
        if(response.data.success){
            toast.current.show({ severity: 'success', summary: 'Successful', detail:'User Updated Successfully', life: 3000 });
        }else{
            toast.current.show({ severity: 'error', summary: 'Failed', detail:'Failed to Update', life: 3000 });
        }
        props.setEditCustomerDialog(false)
        props.getUser()

    }

    const customerDialogFooter =(
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={()=>{props.setEditCustomerDialog(false)}} />
            <Button label="Submit" type="submit" form='cust-form' icon="pi pi-check" className="p-button-success" disabled={cnoVerify} />
        </>
    )

    return (
        <>
            <Toast ref={toast} position='center' />
            <Dialog visible={openCustomerDialog} style={{ width: "750px" }} header={`Edit Customer`} modal footer={customerDialogFooter} onHide={()=>{props.setEditCustomerDialog(false)}} className="p-fluid"  maximizable={true} blockScroll={true} >
                <form onSubmit={onCustomerSubmit} id="cust-form" className="p-fluid">
                    <h5>Account Details</h5>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="acc-name">Mr/Mrs</label>
                                <Dropdown name="prefix" filter optionLabel="name" placeholder="Select Gender Prefix" value={selectedGender} options={gender} onChange={changeData} required ></Dropdown>
                            </div>
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="acc-name">First Name</label>
                                <InputText id="acc-name" name="name" type="text" placeholder="First Name" defaultValue={customerData?.name} onChange={changeData}  required/>
                            </div>
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="acc-lastName">Last Name</label>
                                <InputText id="acc-lastName" name="lastName" type="text" placeholder="Last Name" defaultValue={customerData?.lastName} onChange={changeData}  required/>
                            </div>

                            {/* <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="acc-name">Account Name</label>
                                <InputText id="acc-name" name="name" type="text" placeholder="First Name" defaultValue={customerData?.name} onChange={changeData}  required/>
                            </div> */}

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="contact">Contact Number</label>
                                <InputText id="contact" name="cno" type="text" minLength={10} maxLength={13} defaultValue={customerData?.cno} placeholder="Contact Number" onChange={changeData} required/>  
                                { cnoVerify && <small className="p-error block">Number Already Exists.</small> }             
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="email-id">Email Id</label>
                                <InputText id="email-id" name="email" type="text" defaultValue={customerData?.email} placeholder="Email ID" onChange={changeData} required/>
                            </div>
                            
                            <div className="p-field p-col-12">
                                <label htmlFor="address">Address</label>
                                <InputTextarea id="address" name="addr" rows="2" placeholder="Address" defaultValue={customerData?.addr?.addr} onChange={changeData} required/>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="pincode">Pincode</label>
                                <InputText id="pincode" name="pincode" type="text" placeholder="Pincode" maxLength={6} defaultValue={customerData?.addr?.pincode} onChange={changeData} required/>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="country">Country</label>
                                <Dropdown name="country" filter optionLabel="name" placeholder="Select Country" value={selectedCountry} options={country} onChange={changeCountry} required ></Dropdown>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="dstate">State</label>
                                <Dropdown id="dstate" name="state"  optionLabel="name" filter placeholder="Select State" value={selectedState} options={state} onChange={changeState} required></Dropdown>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="dcity">City</label>
                                <Dropdown id="dcity" name="city" optionLabel="name" filter placeholder="Select City" value={selectedCity} options={city} onChange={changeCity} required></Dropdown>
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="gstNumber">GST Number</label>
                                <InputText id="gstNumber" name="gstNumber" type="text" placeholder="GST Number" defaultValue={customerData?.gstNumber} onChange={changeData} required/>
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="company">Company Name</label>
                                <InputText id="company" name="company" type="text" placeholder="Company Name" defaultValue={customerData?.billing_details?.company} onChange={changeData} required/>
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="cup-cost">Cup Cost</label>
                                <InputText id="cup-cost" name="cup_cost" min="0" step="0.01" type="number" placeholder="Cup Cost" defaultValue={customerData?.cup_cost} onChange={changeData} required/>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="bev-offset">Beverage Offset </label>
                                <InputText id="bev-offset" name="beverages_offset" type="number" min="-20" placeholder="Bevearge Offset" defaultValue={customerData?.beverages_offset ? customerData?.beverages_offset : 0} onChange={changeData} disabled={initialValue?.userType == 'operation'} required/>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                </form>
            </Dialog>
        </>
    )
}