import React, { useContext } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
// Components
import { Users } from './Users';
import { ActiveUsers } from './ActiveUsers';
import { permissionContext } from '../../AppWrapper';
import { BulkTab } from './BulkTab';
import { AccountsWise } from '../Notifications/AccountsWise';

export const UsersMain = () => {

    const rolePermissions = useContext(permissionContext);

    return (
            <>
                <div className="card">
                    <TabView >
                        <TabPanel header="Users">
                            <Users/>
                        </TabPanel>

                        {
                            rolePermissions?.activeUsersTab ? 
                            <TabPanel header="Active Commercial Accounts">
                                <ActiveUsers/>
                            </TabPanel>
                            :
                            <TabPanel header="" disabled> </TabPanel>
                        }

                    </TabView>
                </div>
            </>
    )
}



    {/* {
        initialValue?.userType === 'customer' ?
        <TabPanel header="Notification Manager">
            <AccountsWise/>
        </TabPanel>
        :
        <TabPanel header="" disabled></TabPanel>
    } */}
    
    {/* <TabPanel header="Bulk">
        <BulkTab/>
    </TabPanel> */}