import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import CountryService from '../service/CountryService';
import { useHistory } from 'react-router-dom';
// Services
import ApiServices from '../service/api/ApiServices';
const apiServices = new ApiServices();

const initialValue = { cno: "", logintype: "cno", platform: "dashboard" }
const verifyinitialValue = { cno: "", otp: "" }

// ==========================================================================================
// ========================================== LOGIN PAGE ====================================
// ==========================================================================================

export const Login = (props) => {

	let history = useHistory();
	const [country, setCountry] = useState({ name: 'India', code: 'IN' });
	// const [userData,setUserData ] = useState(null);
	const [user, setUser] = useState(initialValue);
	const [otpverify, setOtpverify] = useState(verifyinitialValue);
	const [countryOptions, setCountryOptions] = useState();
	const [numberSection, setNumberSection] = useState(true);
	const [otpSection, setOtpSection] = useState(false);
	const [resendSection, sendResendSection] = useState(true);
	const [resendBtnSection, sendResendBtnSection] = useState(false);
	const [mobile, setMobile] = useState(null);
	const [mobileNumber, setMobileNumber] = useState(null);
	const [disableOption, setDisableOption] = useState(true);
	const [resendDisabled, setResendDisabled] = useState(true);
	const [showMsg, setShowMsg] = useState(null);
	const [checkEnterCount, setCheckEnterCount] = useState(true);
	const imageRef = useRef(null);


	const onValueChange = (e) => {
		// console.log(e.target.value);
		if (e.target.value.length == '10') {
			setMobileNumber(e.target.value)
			setDisableOption(false);
			const mobilepre = "+91";
			const value = mobilepre.concat(e.target.value);
			setUser({ ...user, [e.target.name]: value })
			setMobile(value);
		} else {
			setDisableOption(true);
		}
	}

	const otpOnChange = (e) => {
		const data = { ...otpverify, [e.target.name]: e.target.value, "cno": mobile }
		setOtpverify(data)
		e.target.value.length == '4' ? setDisableOption(false) : setDisableOption(true);
	}

	useEffect(() => {
		const countryService = new CountryService();
		countryService.getCountries().then(data => setCountryOptions(data));
	}, []);

	useEffect(() => {
		if (imageRef.current) {
			imageRef.current.style.transform = 'perspective(3000px) rotateY(0deg)';
		}
	}, []);

	const runTimer = () => {
		var timer = 30;
		var timeOTP = setInterval(() => {
			timer -= 1;
			document.getElementById("timer").innerHTML = timer;
			if (timer == 0) {
				clearInterval(timeOTP)
				setResendDisabled(false)
				sendResendSection(false)
				sendResendBtnSection(true)
			}
		}, 1000)
	}

	const addUserDetails = async () => {
		const response = await apiServices.userAuthentication(user);
		console.log(response);
		if (response && response.status == 200) {
			// response.data.user.userType === 'superman' ||
			if (response.data.user.userType === 'dealer') {
				setShowMsg("Unauthorized User");
			} else {
				setNumberSection(false);
				setOtpSection(true);
				setDisableOption(true);
				setShowMsg('')
				runTimer()
			}
		} else if (response && response.status == 409) {
			setShowMsg(response?.data?.msg);
		} else {
			setShowMsg("Network Error");
		}
	}

	const verifyOtp = async () => {

		setCheckEnterCount(false)
		const response = await apiServices.userVerification(otpverify);

		// response.data?.userid == 'usr-em890ckvkkpd7xgm'
		if (response && response.status == 200) {

			const loginDate = Date.now();
			const response1 = await apiServices.userInfo(response.data?.userid, response.data?.idToken);

			// ========== This =========
			// const response2 = await apiServices.authenticateAppUser({country_code:"+91",contact:mobileNumber})
			// const loggedData = {...response.data,...response1?.data?.data,loginDate:loginDate,app_data:response2?.data?.response_obj}

			// Check Again 18-03-2023
			const appDataRes = await apiServices.authenticateAppUser({ country_code: "+91", contact: mobileNumber })

			// ========== Or ==========
			const loggedData1 = { ...response.data, ...response1?.data?.data, loginDate: loginDate, app_data: appDataRes?.data?.response_obj }

			// console.log(loggedData);

			const response2 = await apiServices.employeeTabAccess(loggedData1);
			console.log("TAB", response2);

			const rfidCheck = response2?.data?.data?.rfidCheck;
			const passwordCheck = response2?.data?.data?.passwordCheck;
			const employeeCheck = response2?.data?.data?.rfidCheck;
			const qrCheck = response2?.data?.data?.qrCheck;
			const onlineCheck = response2?.data?.data?.onlineCheck;
			const transactionCheck = response2?.data?.data?.onlineCheck;
			console.log({ rfidCheck, passwordCheck, employeeCheck, qrCheck, onlineCheck, transactionCheck })
			const tabChecker = { rfidCheck, passwordCheck, employeeCheck, qrCheck, onlineCheck, transactionCheck }
			const loggedData = { ...loggedData1, tabChecker }
			localStorage.setItem('user', JSON.stringify(loggedData));
			setCheckEnterCount(true)
			history.push('./overview');
			window.location.reload();
		} else {
			setCheckEnterCount(true)
			setShowMsg("Invalid OTP");
		}
	}

	const resendOtp = async () => {
		const response = await apiServices.userAuthentication(user);
		if (response && response.status == 200) {
			runTimer()
			sendResendSection(true)
			sendResendBtnSection(false)
		} else {
			setShowMsg("Not Send");
		}
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			if (e.target.value.length == '10') {
				onValueChange(e)
				addUserDetails()
			} else {
				setShowMsg("Please Enter Valid Mobile Number");
			}
		}
	}

	const handleKeyDown1 = (e) => {

		// if (otpverify?.otp !='' && e.key === 'Enter') {

		if (otpverify?.otp.length == '4' && e.key === 'Enter') {

			if (checkEnterCount) {
				setCheckEnterCount(false)
				verifyOtp()

				// setTimeout(() => {
				// 	setCheckEnterCount(true)
				// }, 2000);
			}
		}
	}

	const getOTP = async () => {
		if (mobile && otpSection) {
			var value = prompt("Enter Developer's password", "");
			if (value == '0708') {
				const response = await apiServices.getOtp();
				const fil = response?.data?.data?.filter((x) => { return x.cno == mobile; })
				const otpData = fil ? fil[0]?.otp : ''
				const data = { ...otpverify, otp: otpData, "cno": mobile }
				setOtpverify(data)
				setDisableOption(false)
			}
		}
	}

	return (

		<>
			<div className="login-body">
				<div className='p-grid'>
					<div className='p-col-6 p-p-0'>
						<div className="login-image">
							<img ref={imageRef} src={`assets/layout/images/new-login-img.png`} alt="Cherise" onDoubleClick={getOTP} />
						</div>
					</div>
					<div className='p-col-6 p-p-0'>
						<div className="login-panel p-fluid">
							<div className="login-div p-d-flex p-flex-column p-p-4" >
								<div className="p-d-flex p-ai-center p-my-3 logo-container">
									<img src="assets/layout/images/cherise-logo-white-old.png" alt="logo" />
								</div>
								<span className='error-span p-pb-4'><center>{showMsg}</center> </span>

								{
									numberSection &&
									<>
										<div className="form-container">
											<span className="p-input-icon-left">
												<i className="pi pi-globe"></i>
												<Dropdown style={{ paddingLeft: '22px' }} value={country} onChange={(e) => { setCountry(e.value); console.log(e.value); }} options={countryOptions} optionLabel="name" placeholder="India" filter className="p-mb-4 multiselect-custom" disabled />
											</span>
											<span className="p-input-icon-left">
												<i className="pi pi-phone"></i>
												<InputText className='p-mb-4' minLength={10} maxLength={10} onChange={(e) => onValueChange(e)} onKeyDown={(e) => handleKeyDown(e)} name='cno' type="text" placeholder="Mobile Number" autoFocus autoComplete="off" />
											</span>
										</div>
										<div className="button-container">
											<Button type="button" style={{ padding: '12px' }} className='p-custom-btn' label="LOGIN" disabled={disableOption} onClick={() => addUserDetails()}></Button>
										</div>
									</>
								}

								{
									otpSection &&
									<>
										<div className="form-container">
											<span className="p-input-icon-left">
												<i className="pi pi-phone"></i>
												<InputText className='p-mb-4' value={mobile} type="text" placeholder="Mobile Number" disabled />
											</span>
										</div>
										<div className="form-container">
											<span className="p-input-icon-left">
												<i className="pi pi-key"></i>
												<InputText className='p-mb-4' name='otp' type="text" placeholder="Enter OTP" minLength={4} maxLength={4} value={otpverify?.otp} onChange={(e) => otpOnChange(e)} onKeyDown={(e) => handleKeyDown1(e)} autoFocus />
											</span>
										</div>
										<div className="button-container">
											<Button type="button" style={{ padding: '12px' }} disabled={disableOption} onClick={() => verifyOtp()} className='p-custom-btn' label="VERIFY OTP" loading={!checkEnterCount} ></Button>
											{resendSection && <div className='p-text-center'>Resend OTP in <span id="timer">30</span> sec</div>}
											{resendBtnSection && <Button type="button" style={{ padding: '12px' }} className='p-custom-btn' onClick={() => resendOtp()} disabled={resendDisabled} label="RESEND OTP" ></Button>}
										</div>
									</>
								}

								<div className='social-caption'>
									<hr></hr>
								</div>
								<div className='social-div'>
									<center>
										<a href='https://cheriseglobal.com/' target='_blank'><i className="pi pi-globe p-m-2" style={{ 'fontSize': '1.5em' }}></i></a>
										<a href='https://www.facebook.com/cheriseglobal' target='_blank'><i className="pi pi-facebook p-m-2" style={{ 'fontSize': '1.5em' }}></i></a>
										{/* <a href='' target='_blank'><i className="pi pi-info-circle p-m-2" style={{'fontSize': '1.5em'}}></i></a> */}
										<a href='https://twitter.com/cheriseglobal?s=09' target='_blank'><i className="pi pi-twitter p-m-2" style={{ 'fontSize': '1.5em' }}></i></a>
										<a href='https://www.youtube.com/channel/UC-3QlbOMKDlXMDXtxHCjXhw' target='_blank'><i className="pi pi-youtube p-m-2" style={{ 'fontSize': '1.5em' }}></i></a>
										{/* <a href='' target='_blank'><i className="pi pi-linkedin p-m-2" style={{'fontSize': '1.5em'}}></i></a> */}
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}


