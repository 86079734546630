import React, {useState,useRef, useEffect} from "react";
import { Chart } from 'primereact/chart';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from "primereact/dropdown";
import { displayMonthlyOptions } from "./Constants";
import { barDataForBarChart } from "../Common/CommonFunctions";
import { barOptionsForBarChart } from "../Common/CommonFunctions";
import { CommonBodyForTable } from "../Common/CommonComponents";
import { exportReport } from "../Common/CommonFunctions";

// =================================================================================
// =================================================================================

export const AccountWiseCupChartNew = (props) =>{

    const [chartData, setChartData] = useState(null);
    const [label, setLabel] = useState(null);
    const [values, setValues] = useState(null);
    const dt = useRef(null);

    useEffect(() => {
        getData(props.accountWiseData)
    }, [props.accountWiseData])

    const getData = (data) =>{
        if(data){
            setChartData(data)
            const cups = data.map((x)=>{ return x.cups})
            setValues(cups)
            const dataLabel = data.map((x)=>{ return x.name})
            setLabel(dataLabel)
        }
    }

    const barData = barDataForBarChart(label,values,'Cup Consumption')
    const barOptions = barOptionsForBarChart('Accounts','Cup Consumption')
    const exportCSV = () =>{ exportReport(dt)}


    return (
        
        <>
            {
                props.loading ? 
                <>
                    <center>
                        <span><i className="pi pi-spin pi-spinner p-mt-5" style={{ fontSize: '2rem' }}></i></span>
                    </center>
                </>
                :
                <div className="card">
                    <div className="card-header">
                        <span>Account Wise Cup Consumption Performance Details</span>
                        <div className='p-col-sm-4'>
                                <div className="p-inputgroup">
                                    {/* <Button className='custom-group-btn' icon="pi pi-eye" /> */}
                                    {/* <Dropdown value={displayMonthlyOptions[0]} options={displayMonthlyOptions} optionLabel="name" placeholder="Select Display" filter className="p-m-0 multiselect-custom"/> */}
                                    <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-primary p-m-2" onClick={exportCSV} disabled={props.loading} />
                                </div>
                        </div>
                    </div>
                    <div className="graph p-mt-5">
                        <Chart type="bar" data={barData} options={barOptions} />
                    </div>
                </div>
            }
            
            <DataTable  style={{display:'none'}} ref={dt} value={chartData} dataKey="id" exportFilename={ `Account-Wise Cup Consumption Performance Details`}  className="datatable-responsive" emptyMessage="No data found.">
                <Column width="500" field="name" header="Account"  body={CommonBodyForTable}></Column>
                <Column field="cups" header="Cup Count" body={CommonBodyForTable}></Column>
            </DataTable>
        </>
    )
}