import React, { useState, useEffect, lazy, Suspense } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { MultiSelect } from "primereact/multiselect";
// Components
import { AnalyticalStat } from "./AnalyticalStat";

// Services
import CommonCal from '../../service/common/CommonCal';
import { managementCheck } from "../../service/admin/superadmin";
import { LoadingComponent } from "../Common/CommonComponents";
// Service
import ApiServices from "../../service/api/ApiServices";
import { FormWrapper, FullFormWrapper } from "../../utilComponents/Forms";
import { BevVendingMchOptions, mchStatusOptions, TimeSelectValues, TimeSelectValuesForManagement, customWise, machineTypeOptions, monthsData, yearsData } from "./Constants";
import { commonFormDialogFooter } from "../Common/CommonComponents";

const AnalyticalCharts = lazy(() =>
    import('./AnalyticalCharts').then(module => ({
        default: module.AnalyticalCharts
    }))
)

const apiServices = new ApiServices()
const commonCal = new CommonCal()
// Logged User
const initialValue = JSON.parse(localStorage.getItem("user"));
const google = window.google = window.google ? window.google : {}
const isManagement = managementCheck();


export const AnalyticsDemo = () => {

    const useStatInput = { id: [], date: { startDate: "", endDate: "" }, beverage: "All" }
    const useStatInput3 = { id: [""], beverage: "All" }

    const [machineselectValue, setMachineselectValue] = useState({ displayLabel: 'All', customer: 'All' });
    const [timeselectValue, setTimeselectValue] = useState({ name: 'This Month', code: 'this_month' });
    const [resultedMachine, setResultedMachine] = useState([])
    const [masterResultedMachines, setMasterResultedMachines] = useState([])
    const [filterButton, setFilterButton] = useState(true)
    const [machineData, setMachineData] = useState(null)
    const [machinesForCount, setMachinesForCount] = useState(null)
    const [allMachineData, setAllMachineData] = useState(null)
    const [allMachines, setAllMachines] = useState(null)
    const [allMachineCount, setAllMachineCount] = useState(null)
    const [selectSingleMachineF, setSelectSingleMachineF] = useState(false);

    const [userStatParam, setUserStatParam] = useState(null);
    const [paramForDailyCups, setParamForDailyCups] = useState(null);
    const [paramForBevWiseDaily, setParamForBevWiseDaily] = useState(null);
    const [filteredDate, setFilteredDate] = useState(null)
    const [datesData, setDatesData] = useState(null)
    const [customFilterDialog, setCustomFilterDialog] = useState(false)
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('this_month')
    const [customerValue, setCustomerValue] = useState(null)
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    const [onlineData, setOnlineData] = useState(null);
    const [allCupConsumeData, setAllCupConsumeData] = useState()
    const [cupCData, setCupCData] = useState([]);
    const [TimeCData, setTimeCData] = useState([]);
    const [allBeverages, setAllBeverages] = useState(null)
    const [meriTapriMachines, setMeriTapriMachines] = useState(null)
    const [tapriMachineData, setTapriMachineData] = useState({ displayLabel: 'All' })
    const [selectedTapri, setSelectedTapri] = useState({ displayLabel: 'All' })
    const [allTapriMachines, setAllTapriMachines] = useState([])
    const [selectedTimeValue, setSelectedTimeValue] = useState({ name: 'This Month', code: 'this_month' });
    const [tapriFilterButton, setTapriFilterButton] = useState(true)
    const [activeTabNumber, setActiveTabNumber] = useState(0);
    const [bevChartData, setBevChartData] = useState(null);
    const [selectedBVMBrand, setSelectedBVMBrand] = useState(BevVendingMchOptions);
    const [selectedMachStatus, setSelectedMachStatus] = useState(mchStatusOptions);
    // Custom
    const [lastMonthsListDropDown, setLastMonthsListDropDown] = useState([])
    const [customType, setCustomType] = useState(null)
    const [selectedCustomMonth, setSelectedCustomMonth] = useState(null)
    const [totalMachineCount, setTotalMachineCount] = useState(null)
    const [custKettleFilterDialog, setCustKettleFilterDialog] = useState(false)

    // const [selectedMachineType, setSelectedMachineType] = useState({ name:"Commercial", code : 'commercial' })
    const [selectedMachineType, setSelectedMachineType] = useState({ name: "All", code: 'all' })
    const [isKettleSelected, setIsKettleSelected] = useState(false)
    const [isKettleSelectedForStat, setIsKettleSelectedForStat] = useState(false)

    const [fMachineData, setFMachineData] = useState([]);
    const [filterMachineStatus, setFilterMachineStatus] = useState(false);
    const [selectedkettle, setSelectedkettle] = useState(null);
    const [selectSingleMachine, setSelectSingleMachine] = useState(false);
    const [customerClicks1, setCustomerClicks1] = useState(false);
    const [customMonthsData, setCustomMonthsData] = useState(null);
    const [customYearsData, SetCustomYearsData] = useState(null);
    const [dayWiseCDataSet, setDayWiseCDataSet] = useState(null);
    const [dayWiseCData, setDayWiseCData] = useState(null);
    const [totalTapriConsumption, setTotalTapriConsumption] = useState(null);
    const [tapriSuperstar, setTapriSuperstar] = useState(null);
    const [customValue, setCustomValue] = useState(false);
    const [timeselectValueF, setTimeselectValueF] = useState({ name: 'This Month', code: 'this_month' });


    useEffect(() => {
        getMachine()
        getDates()
        getTest()
        getMachineCoordinates()
        // getMachineTapri()
        // getmachineLatLong(null)
        // getkettleLatLong(null)
    }, [])

    const getTest = () => {

        setCustomMonthsData(monthsData.map(name => ({ name })));
        SetCustomYearsData(yearsData.map(name => ({ name })));

        const generateMonthsData = () => {
            const now = new Date();
            const arrOfValues = [];

            for (let i = 0; i < 24; i++) {
                const future = new Date(now.getFullYear(), now.getMonth() + i, 1);
                const month = monthsData[future.getMonth()];
                const year = future.getFullYear() - 2;
                arrOfValues.push({ name: `${month} ${year}`, code: `${month}-${year}` });
            }

            arrOfValues.splice(0, 5);
            return arrOfValues.reverse();
        };

        const customMonths = generateMonthsData();
        const lastArr = customMonths.map((x, i) => ({ ...x, index: i }));
        setLastMonthsListDropDown(lastArr);
    };

    const getCustomDates = (monthsAgo) => {
        const currentDate = new Date();
        const lastMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - (monthsAgo + 1), 1);
        const lastMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() - monthsAgo, 0);
        lastMonthStart.setHours(0, 0, 0, 0);
        lastMonthEnd.setHours(23, 59, 59, 0);
        const startDate = lastMonthStart.toISOString();
        const endDate = lastMonthEnd.toISOString();
        return { startDate, endDate };
    };

    const getOnlineStat = async (machineData, date) => {
        const isOnlineAndRfidEnabled = machineData?.mchmode?.paymode?.online === true && machineData?.mchmode?.paymode?.rfid === true;

        if (isOnlineAndRfidEnabled) {
            const machineId = machineData.id;

            try {
                const onlineData = await apiServices.empConsumptionCount({ machineid: machineId, date });
                const transactionStatsData = await apiServices.getTransactionStats({ id: machineId, date });

                const onlineCount = onlineData?.data?.data[0]?.count || null;
                const paytmCount = transactionStatsData?.data?.data[0]?.succ || null;

                setOnlineData({ online: onlineCount, paytm: paytmCount });
            } catch (error) {
                console.error("Error fetching online stats:", error);
                setOnlineData(null);
            }
        } else {
            setOnlineData(null);
        }
    };

    const getMachineCoordinates = async (data) => {

        let sub_status = null;
        if (isKettleSelected) {
            if (selectedMachStatus?.length == 1) {
                sub_status = selectedMachStatus[0].code;
            }
        }

        const brandOptions = selectedBVMBrand?.map((x) => { return x.code })

        let payload;
        if (sub_status) {
            payload = {
                "userId": initialValue?.userid,
                "userType": initialValue?.userType,
                "brand": brandOptions,
                "subscriptionType": selectedMachineType?.code == 'commercial' ? "permanent" : selectedMachineType?.code,
                "subscription_status": sub_status
            }
        } else {
            payload = {
                "userId": initialValue?.userid,
                "userType": initialValue?.userType,
                "brand": brandOptions,
                "subscriptionType": selectedMachineType?.code == 'commercial' ? "permanent" : selectedMachineType?.code
            }
        }

        const response = await apiServices.getMachineCoordinates(payload);
        console.log('map-coordinates', response?.data?.data);

        let arr = [];
        response?.data?.data?.forEach((x) => {
            arr.push({
                ...x,
                companyname: x.siteName,
                facid: x.facility?.facid,
                facname: x.facility?.facname,
                accountid: x.Customer_Account_ID,
                subid: x.subid
            })
        });

        const coordinates = (data != null) ? arr.filter((x) => { return x.id == data; }) : arr;

        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 4,
            center: { lat: 18.5979754, lng: 74.004489 },
            // mapTypeId: 'satellite',
            mapTypeId: google.maps.MapTypeId.HYBRID
        });

        console.log(coordinates);

        for (let i = 0; i < coordinates.length; i++) {

            const img = coordinates[i].connectionMode == "disConnected" ? '../../assets/demo/images/Icon/offline_location.png' : '../../assets/demo/images/Icon/online_location.png';

            const contentString =
                '<div id="map-content">' +
                `<h6 id="firstHeading" class="firstHeading">CVES ID : ${coordinates[i].id} (${coordinates[i].subscriptionType == 'permanent' ? 'Commercial' : coordinates[i].subscriptionType}) </h6>` +
                '<div id="bodyContent">' +
                `<p>Subscription ID : ${coordinates[i].subid}</p>` +
                `<p>Account ID : ${coordinates[i].accountid ? coordinates[i].accountid : 'NA'}</p>` +
                `<p>Account Name : ${coordinates[i].companyname}</p>` +
                `<p>Facility ID : ${coordinates[i].facid}</p>` +
                `<p>Facility Name : ${coordinates[i].facname}</p>` +
                "</div>" +
                "</div>";

            const infowindow = new google.maps.InfoWindow({ content: contentString, ariaLabel: "Uluru" });

            const marker = new google.maps.Marker({
                position: { lat: parseFloat(coordinates[i].location?.lat), lng: parseFloat(coordinates[i]?.location?.lng) },
                icon: img,
                map: map,
                title: 'CVES ID: ' + coordinates[i].id + '\n' + 'Subscription Type: ' + (coordinates[i].subscriptionType == 'permanent' ? 'Commercial' : 'Demo') + '\n' + 'Subscription ID: ' + coordinates[i].subid + '\n' + 'Account ID: ' + (coordinates[i].accountid ? coordinates[i].accountid : 'NA') + '\n' + 'Account Name: ' + coordinates[i].companyname + '\n' + 'Facility ID: ' + coordinates[i].facid + '\n' + 'Facility Name: ' + coordinates[i].facname,
            });

            marker.addListener('click', function () { infowindow.open(map, marker) });
        }
    }

    const getDates = async () => {
        const response = await commonCal.getDates()
        setDatesData(response);
    }

    const getMachineTapri = async () => {
        const filters = { brand: ['TAPRI', 'TKAFFE', 'MERI_TAPRI'] };

        try {
            const response = await apiServices.findMachines(filters);
            const tapriMachines = response?.data?.data;

            console.log(tapriMachines);
            setMeriTapriMachines(tapriMachines);

            const onBoardmachines = tapriMachines?.filter(m =>
                m?.datastore?.stage === 'finishedgoods' &&
                m?.subscription &&
                (m?.subscription?.package?.site === 'demo' || m?.subscription?.package?.site === 'permanent') &&
                m?.siteName !== ''
            );

            const formattedMachines = onBoardmachines.map(x => ({ ...x, displayLabel: `${x.id} / ${x.subid} / ${x.siteName}` }));

            formattedMachines?.unshift({ displayLabel: 'All' }, { displayLabel: 'Demo' }, { displayLabel: 'Commercial' });

            console.log(formattedMachines);
            setTapriMachineData(formattedMachines);

            const result = formattedMachines?.filter(checkMachine).map(a => a?.id);

            console.log(result);
            setAllTapriMachines(result);
            setTotalMachineCount(result);

            const currentDate = new Date();
            const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).setHours(0, 0, 0, 0);
            const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).setHours(23, 59, 59, 0);

            const startDate = new Date(firstDay).toISOString();
            const endDate = new Date(lastDay).toISOString();
            const date = { startDate, endDate };

            getUsageStatTapri({ id: result, date });
        } catch (error) {
            console.error('Error fetching machine data:', error);
        }
    };

    const getUsageStatTapri = async (data) => {
        setFilteredDate(data?.date)
        const response = await apiServices.getUserStatsForMeriTapri(data);
        console.log(response?.data?.data);
        setTotalTapriConsumption(response?.data?.data?.usagesStats[0]?.totalCup)
        const superstar = response?.data?.data?.brvStats?.sort((a, b) => b.totalCup - a.totalCup);
        superstar && setTapriSuperstar(superstar[0]?._id)
        setBevChartData(response?.data?.data?.brvStats)
    }

    const getMachine = async () => {
        try {
            const brandFilters = { brand: ['TAPRI', 'TKAFFE', 'MERI_TAPRI'] };
            // Fetch onboarded machines
            const onboardedMachinesResponse = await apiServices.onboardedMachines(brandFilters);
            const onboardedMachines = onboardedMachinesResponse?.data?.data;

            console.log(onboardedMachines);

            let combinedMachines = [];

            if (isManagement) {
                // Fetch IO machines if management
                const ioMachinesResponse = await apiServices.getIoMachines();
                const ioMachinesData = ioMachinesResponse?.data?.data?.map(x => ({ ...x, companyName: x.siteName }));
                combinedMachines = [...onboardedMachines, ...ioMachinesData];
            } else {
                combinedMachines = onboardedMachines && [...onboardedMachines];
            }

            console.log(combinedMachines);

            // Fetch user and agreement data
            const userResponse = await apiServices.getUserReduced();
            const agreementsResponse = await apiServices.agreementsList();

            // Process and combine data
            const processedMachines = combinedMachines?.map(x => {
                const matchingUser = userResponse?.data?.data.find(y => x.customer === y.userid);

                return {
                    ...x,
                    accountName: matchingUser?.name,
                    companyName: matchingUser?.billing_details?.company || x.companyName,
                    facilityName: x?.subscription?.facilitydata?.facname || 'NA'
                };
            });

            const machinesWithAgreementStatus = processedMachines?.map(x => {
                const checkUsername = obj => obj.id == x.id;
                const hasAgreement = agreementsResponse?.data?.data.some(checkUsername);
                return { ...x, agreementStatus: hasAgreement };
            });

            console.log(machinesWithAgreementStatus);

            setMachineData(machinesWithAgreementStatus);
            setMachinesForCount(machinesWithAgreementStatus);

            const result = machinesWithAgreementStatus?.map(a => a.id);
            setAllMachineData(result);
            setAllMachines(result);
            setAllMachineCount(result);
            getResultedMachines(machinesWithAgreementStatus);
            getInitialData(result);
        } catch (error) {
            console.error('Error fetching machine data:', error);
        }
    };

    const getInitialData = (machineData) => {
        const currentDate = new Date();
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        // Set hours, minutes, seconds, and milliseconds to get the exact start and end of the day
        firstDay.setHours(0, 0, 0, 0);
        lastDay.setHours(23, 59, 59, 0);

        const startDate = firstDay.toISOString();
        const endDate = lastDay.toISOString();
        const date = { startDate, endDate };

        setFilteredDate(date);

        const userStatParams = {
            params: { ...useStatInput, id: machineData, date: date },
            time: 'this_month'
        };
        setUserStatParam(userStatParams);

        const paramForDailyCups = { id: machineData, date: date };
        setParamForDailyCups(paramForDailyCups);
        getmachineStats({ ...useStatInput, id: machineData, date: date });
    };

    const getResultedMachines = (data) => {
        const filteredMachines = data?.filter(checkMachine);
        const mappedMachines = filteredMachines?.map((x) => ({
            ...x,
            displayLabel: `${x.id} / ${x.subid || 'CIPL'} / ${x.companyName}`
        }));

        const defaultMachine = { displayLabel: 'All', customer: 'All' };
        const machinesToSet = [defaultMachine, ...mappedMachines];

        setResultedMachine(machinesToSet);
        setMasterResultedMachines(machinesToSet);
    };

    const getmachineStats = async (data) => {
        try {
            const response = await apiServices.getMachineConsumption(data);
            console.log(response?.data?.data);

            const consumptionChart = response?.data?.data.map((x) => ({
                ...x,
                Charttime: new Date(x.time).toLocaleString()
            }));

            setAllCupConsumeData(consumptionChart);

            const cupResult = response?.data?.data?.map(a => a.cups);
            const timeResult = response?.data?.data?.map(a => new Date(a.time).toLocaleString());

            setCupCData(cupResult);
            setTimeCData(timeResult);
        } catch (error) {
            console.error('Error fetching machine consumption data:', error);
        }
    };

    function checkMachine(machine) { return machine?.datastore?.stage == "finishedgoods" && machine?.subscription && (machine?.subscription?.package?.site == "demo" || machine?.subscription?.package?.site == "permanent") && machine?.siteName != "" }
    function checkDemoMachine(machine) { return machine?.subscription?.package?.site == 'demo' && machine.siteName != '' };
    function checkpermaMachine(machine) { return machine?.subscription?.package?.site == 'permanent' && machine.siteName != '' };


    const changeBVMBrand = (e) => {
        console.log(e.value);

        if (!e.value || e.value.length === 0) {
            return; // No need to proceed if value is empty
        }

        setSelectedBVMBrand(e.value);
        setMachineselectValue({ displayLabel: 'All', customer: 'All' });

        const uniqueBrandCodes = Array.from(new Set(e.value.map(item => item.code)));

        setFilterButton(!(e.value.length > 0));

        setIsKettleSelected(uniqueBrandCodes.includes('MERI_TAPRI'));

        if (e.value.length === 1) {
            setSelectedMachStatus(mchStatusOptions);

            const filteredData = machineData?.filter(x => x?.brand === e.value[0]?.code);
            console.log(filteredData);
            filterByBrand(selectedMachineType, filteredData);
        } else {
            setIsKettleSelected(false);

            const brandFilter = (brands) => {
                const filteredData = machineData?.filter(x => brands.includes(x?.brand));
                filterByBrand(selectedMachineType, filteredData);
            };

            if (uniqueBrandCodes.length === 2) {
                if (uniqueBrandCodes.includes('TAPRI') && uniqueBrandCodes.includes('TKAFFE')) {
                    brandFilter(['TAPRI', 'TKAFFE']);
                } else if (uniqueBrandCodes.includes('TAPRI') && uniqueBrandCodes.includes('MERI_TAPRI')) {
                    brandFilter(['TAPRI', 'MERI_TAPRI']);
                } else if (uniqueBrandCodes.includes('TKAFFE') && uniqueBrandCodes.includes('MERI_TAPRI')) {
                    brandFilter(['TKAFFE', 'MERI_TAPRI']);
                }
            } else if (uniqueBrandCodes.length === 3) {
                brandFilter(['TAPRI', 'TKAFFE', 'MERI_TAPRI']);
            }
        }
    };

    const changeMchStatus = (e) => {
        setFilterButton(!(e.value?.length > 0));
        // setSelectedMachStatus(e.value);

        if (e.value?.length > 0) {
            console.log(masterResultedMachines);
            console.log(resultedMachine);

            if (e.value.length >= 2) {
                setSelectedMachStatus(mchStatusOptions);
                setResultedMachine(masterResultedMachines);

                const result = masterResultedMachines?.map(a => a.id);
                result.shift();
                setAllMachineData(result);
                setAllMachines(result);

            } else if (e.value.length === 1) {
                console.log(e.value);
                setSelectedMachStatus(e.value);

                const filterByStatus = (status) => {
                    // const testData = masterResultedMachines?.filter(x => x?.subscription?.status !== 'Active' && x?.subscription?.status !== 'Inactive' );
                    // console.log(testData);

                    let filteredData;
                    if(status == 'Active'){
                        filteredData = masterResultedMachines?.filter((x)=>{ return x?.subscription?.status == 'Active' || x?.subscription?.status == 'Demo' });                    
                    }else{
                        filteredData = masterResultedMachines?.filter(x => x?.subscription?.status === status);
                    }

                    filteredData?.unshift({ displayLabel: 'All', customer: 'All' });
                    console.log(filteredData);
                    setResultedMachine(filteredData);

                    const result = filteredData?.map(a => a.id);
                    result?.shift();
                    console.log(result);

                    setAllMachineData(result);
                    setAllMachines(result);
                };

                if (e.value[0].code === 'active') {
                    filterByStatus('Active');
                } else if (e.value[0].code === 'inactive') {
                    filterByStatus('Inactive');
                }
            }
        }else{
            setSelectedMachStatus(null);
        }
    };

    const filterByBrand = (name, data) => {
        let filteredData = [];
        let filterFunction = null;

        switch (name.code) {
            case 'all':
                filterFunction = checkMachine;
                break;
            case 'demo':
                filterFunction = checkDemoMachine;
                break;
            case 'commercial':
                filterFunction = checkpermaMachine;
                break;
            default:
                break;
        }

        if (filterFunction) {
            filteredData = data?.filter(filterFunction);
            console.log(filteredData);
            getResultedMachines(filteredData);
            const resultMapped = filteredData?.map((a) => a.id);
            setAllMachines(resultMapped);
            // allMachines
        }
    };

    const selectMachType = (e) => {
        console.log(e.value.code);
        setSelectedMachineType(e.value);
        setMachineselectValue({ displayLabel: 'All', customer: 'All' });

        const isBVMBrandSelected = selectedBVMBrand?.length > 0;
        setFilterButton(!isBVMBrandSelected);
        setSelectedMachStatus(mchStatusOptions);

        const filterByBrandCodes = (brandCodes) => {
            const filteredData = machineData?.filter((x) => brandCodes.includes(x?.brand));
            filterByBrand(e.value, filteredData);
        };

        if (isBVMBrandSelected) {
            if (selectedBVMBrand.length === 1) {
                const result = machineData.filter(checkMachine).filter((x) => x.brand === selectedBVMBrand[0]?.code);
                filterByBrand(e.value, result);
            } else if (selectedBVMBrand.length === 2) {
                const brandCodes = selectedBVMBrand.map((o) => o.code);
                filterByBrandCodes(brandCodes);
            } else if (selectedBVMBrand.length === 3) {
                const brandCodes = selectedBVMBrand.map((o) => o.code);
                filterByBrandCodes(brandCodes);
            }
        }
    };

    const SelectmachineFn = async (e) => {
        console.log(e.value);
        setMachineselectValue(e.value);
        setSelectSingleMachineF(true);

        const isBVMBrandSelected = selectedBVMBrand?.length > 0;
        setFilterButton(!isBVMBrandSelected);

        if (e.value.customer === 'All') {
            const selectedBrandCodes = selectedBVMBrand?.map((x) => x.code) || [];

            const filterMachinesByBrand = (brand) => {
                return selectedBrandCodes.includes(brand)
                    ? machineData.filter((x) => x.brand === brand)
                    : [];
            };

            const tapriMch = filterMachinesByBrand('TAPRI');
            const tkaffeMch = filterMachinesByBrand('TKAFFE');
            const kettleMch = filterMachinesByBrand('MERI_TAPRI');

            const filteredMachines = [...tapriMch, ...tkaffeMch, ...kettleMch];
            console.log(filteredMachines);

            const mappedArr = filteredMachines?.map((x) => x.id);

            setAllMachines(mappedArr);
            getResultedMachines(filteredMachines.filter(checkMachine));

        } else {
            const filterMachinesByType = (typeCheckFn) => {
                const resultedMachines = machineData.filter(typeCheckFn).map(a => a.id);
                setAllMachines(resultedMachines);
                getResultedMachines(machineData.filter(typeCheckFn));
            };

            if (e.value.customer === 'Demo') {
                filterMachinesByType(checkDemoMachine);
            } else if (e.value.customer === 'Commercial') {
                filterMachinesByType(checkpermaMachine);
            } else {
                setAllMachines([e.value.id]);
            }
        }
    };

    const OnChangeTypeValue = (e) => {
        setCustomerClicks1(true);
        if (e.value.code === 'custom') {
            setFilterButton(true);
            setCustomFilterDialog(true);
        } else {
            setTimeselectValue(e.value);
            setFilterButton(!(selectedBVMBrand?.length > 0));
        }
    }

    const getdateWiseCupStat = async (data) => {
        try {
            const response = await apiServices.getUsageStatByFilter(data);
            console.log(response.data);

            setAllBeverages(response.data.bevs);

            const createDataTemplate = (label, backgroundColor, data = []) => ({
                type: 'bar',
                label,
                backgroundColor,
                data,
            });

            const arr2 = response?.data?.bevs?.map(bev => {
                const materialName = bev?.materialName;
                const color = bev?.color;

                const arr1 = response.data.data.reduce((result, item) => {
                    const cups = item.data.find(d => d.name === materialName)?.cups || 0;
                    result.push(cups);
                    return result;
                }, []);

                return createDataTemplate(materialName, color, arr1);
            }) || [];

            console.log(arr2);
            setDayWiseCDataSet(arr2);
            setDayWiseCData(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const filterData = async () => {

        setFilterButton(true)
        setIsKettleSelectedForStat(isKettleSelected)
        setSelectedTimeSlot(timeselectValue?.code)

        if (timeselectValue?.code != 'custom') {
            setTimeselectValueF(timeselectValue);
            setSelectSingleMachine(selectSingleMachineF)
        }

        setAllMachineCount(allMachines)

        console.log(allMachines);

        const arr = [];
        machineData?.forEach((x) => {
            const isInclude = allMachines?.includes(x.id)
            if (isInclude) { arr.push(x) }
        })

        console.log(arr);
        setMachinesForCount(arr)

        const today = { startDate: datesData.startToday, endDate: new Date() };
        const yesterday = { startDate: datesData.YestStart, endDate: datesData.EndStart };
        const thisWeek = { startDate: datesData.weekstart, endDate: datesData.weekEnd };
        const thisMonth = { startDate: datesData.monthStart, endDate: datesData.endThisMonth };
        const lastMonth = { startDate: datesData.lastStart, endDate: datesData.lastend };
        // if(machineselectValue?.displayLabel == 'All' || machineselectValue?.displayLabel == 'Demo' || machineselectValue?.displayLabel == 'Commercial'){
        if (machineselectValue?.displayLabel == 'All') {

            setCustomerValue(null)
            getOnlineStat(null)
            getMachineCoordinates(null);

            if (selectedMachineType?.code == 'all') {
                var type = 'all';
            } else if (selectedMachineType?.code == 'demo') {
                var type = 'demo';
                let resultedD = machineData.filter(checkDemoMachine);
                console.log(resultedD);

            } else if (selectedMachineType?.code == 'commercial') {
                var type = 'commercial';
                let resultedC = machineData.filter(checkpermaMachine);
            }

            if (timeselectValue?.code == "all") {
                setUserStatParam({ params: { ...useStatInput3, id: allMachines, type: type }, time: 'all' })
                setParamForDailyCups({ id: allMachines })
            } else if (timeselectValue?.code == "today") {
                setFilteredDate(today)
                setUserStatParam({ params: { ...useStatInput, id: allMachines, date: today, type: type }, time: 'today' })
                setParamForDailyCups({ id: allMachines, date: today })
                getmachineStats({ ...useStatInput, id: allMachines, date: today });
            } else if (timeselectValue?.code == "yesterday") {
                setUserStatParam({ params: { ...useStatInput, id: allMachines, date: yesterday, type: type }, time: 'yesterday' })
                setParamForDailyCups({ id: allMachines, date: yesterday })
                getmachineStats({ ...useStatInput, id: allMachines, date: yesterday });
            } else if (timeselectValue?.code == "this_week") {
                setFilteredDate(thisWeek)
                setUserStatParam({ params: { ...useStatInput, id: allMachines, date: thisWeek, type: type }, time: 'this_week' })
                setParamForDailyCups({ id: allMachines, date: thisWeek })
                getmachineStats({ ...useStatInput, id: allMachines, date: thisWeek });
            } else if (timeselectValue?.code == "this_month") {
                setFilteredDate(thisMonth)
                setUserStatParam({ params: { ...useStatInput, id: allMachines, date: thisMonth, type: type }, time: 'this_month' })
                setParamForDailyCups({ id: allMachines, date: thisMonth })
                getmachineStats({ ...useStatInput, id: allMachines, date: thisMonth });
            } else if (timeselectValue?.code == "last_month") {
                setFilteredDate(lastMonth)
                setUserStatParam({ params: { ...useStatInput, id: allMachines, date: lastMonth, type: type }, time: 'last_month' })
                setParamForDailyCups({ id: allMachines, date: lastMonth })
                getmachineStats({ ...useStatInput, id: allMachines, date: lastMonth });
            } else if (timeselectValue?.code == "custom") {
                setCustomFilterDialog(true);
            }

        } else if (machineselectValue?.displayLabel != 'All') {

            const userResponse = await apiServices.userInfo(machineselectValue.customer);
            console.log(userResponse?.data?.data);
            setCustomerValue(userResponse?.data?.data?.billing_details?.company)
            // activeTabNumber == 0 ? getmachineLatLong(machineselectValue.id) : getkettleLatLong(machineselectValue.id)
            getMachineCoordinates(machineselectValue.id)

            if (timeselectValue?.code == "all") {
                setUserStatParam({ params: { ...useStatInput3, id: [machineselectValue.id] }, time: 'all' })
                setParamForDailyCups({ id: [machineselectValue.id] })
                setParamForBevWiseDaily({ filter: 'chart', id: machineselectValue.id })
                getmachineStats({ ...useStatInput3, id: [machineselectValue.id] });
                getdateWiseCupStat({ filter: 'chart', id: machineselectValue.id })
            } else if (timeselectValue?.code == "today") {
                setFilteredDate(today)
                getOnlineStat(machineselectValue, today)
                setUserStatParam({ params: { ...useStatInput, id: [machineselectValue.id], date: today }, time: 'today' })
                setParamForDailyCups({ id: [machineselectValue.id], date: today })
                setParamForBevWiseDaily({ filter: 'chart', id: machineselectValue.id, date: today })
                getmachineStats({ ...useStatInput, id: [machineselectValue.id], date: today });
                getdateWiseCupStat({ date: today, filter: 'chart', id: machineselectValue.id })
            } else if (timeselectValue?.code == "yesterday") {
                setFilteredDate(yesterday)
                getOnlineStat(machineselectValue, yesterday)
                setUserStatParam({ params: { ...useStatInput, id: [machineselectValue.id], date: yesterday }, time: 'yesterday' })
                setParamForDailyCups({ id: [machineselectValue.id], date: yesterday })
                setParamForBevWiseDaily({ filter: 'chart', id: machineselectValue.id, date: yesterday })
                getmachineStats({ ...useStatInput, id: [machineselectValue.id], date: yesterday });
                getdateWiseCupStat({ date: yesterday, filter: 'chart', id: machineselectValue.id })
            } else if (timeselectValue?.code == "this_week") {
                setFilteredDate(thisWeek)
                getOnlineStat(machineselectValue, thisWeek)
                setUserStatParam({ params: { ...useStatInput, id: [machineselectValue.id], date: thisWeek }, time: 'this_week' })
                setParamForDailyCups({ id: [machineselectValue.id], date: thisWeek })
                setParamForBevWiseDaily({ filter: 'chart', id: machineselectValue.id, date: thisWeek })
                getmachineStats({ ...useStatInput, id: [machineselectValue.id], date: thisWeek });
                getdateWiseCupStat({ date: thisWeek, filter: 'chart', id: machineselectValue.id })
            } else if (timeselectValue?.code == "this_month") {
                setFilteredDate(thisMonth)
                getOnlineStat(machineselectValue, thisMonth)
                setUserStatParam({ params: { ...useStatInput, id: [machineselectValue.id], date: thisMonth }, time: 'this_month' })
                setParamForDailyCups({ id: [machineselectValue.id], date: thisMonth })
                setParamForBevWiseDaily({ filter: 'chart', id: machineselectValue.id, date: thisMonth })
                getmachineStats({ ...useStatInput, id: [machineselectValue.id], date: thisMonth });
                getdateWiseCupStat({ date: thisMonth, filter: 'chart', id: machineselectValue.id })
            } else if (timeselectValue?.code == "last_month") {
                setFilteredDate(lastMonth)
                getOnlineStat(machineselectValue, lastMonth)
                setUserStatParam({ params: { ...useStatInput, id: [machineselectValue.id], date: lastMonth }, time: 'last_month' })
                setParamForDailyCups({ id: [machineselectValue.id], date: lastMonth })
                setParamForBevWiseDaily({ filter: 'chart', id: machineselectValue.id, date: lastMonth })
                getmachineStats({ ...useStatInput, id: [machineselectValue.id], date: lastMonth });
                getdateWiseCupStat({ date: lastMonth, filter: 'chart', id: machineselectValue.id })
            } else if (timeselectValue?.code == "custom") {
                setCustomFilterDialog(true);
            }
        }
    }

    const hideCustomDialog = () => {
        setCustomFilterDialog(false);
        setCustomType(null)
        // setTimeselectValue({name:'All',code:'all'});
    }

    const hideCustomeKettle = () => {
        setCustKettleFilterDialog(false)
        setCustomStart('')
        setCustomEnd('')
    }

    const handleSubmit = async (e) => {
        setCustomFilterDialog(false)
        // setTimeselectValue({name:'Custom',code:'custom'});
        setTimeselectValue(null);
        setSelectSingleMachine(selectSingleMachineF)
        setTimeselectValueF(timeselectValue);
        setCustomValue(true)
        e.preventDefault()

        let date;
        if (customType?.code == 'datewise') {
            const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
            const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
            const cust_start = new Date(startt).toISOString();
            const cust_end = new Date(endd).toISOString();
            date = { startDate: cust_start, endDate: cust_end };
        } else if (customType?.code == 'monthwise') {
            date = getCustomDates(selectedCustomMonth.index);
        }

        if (machineselectValue.displayLabel === "All" || machineselectValue?.displayLabel === 'Demo' || machineselectValue?.displayLabel === 'Commercial') {

            let type;
            if (machineselectValue?.displayLabel == 'All') {
                type = 'all';
            } else if (machineselectValue?.displayLabel == 'Demo') {
                type = 'demo';
            } else if (machineselectValue?.displayLabel == 'Commercial') {
                type = 'commercial';
            }

            setFilteredDate(date)
            // setUserStatParam( {params:{...useStatInput,id:allMachines,date:date,type:type},time:'custom'} )
            setUserStatParam({ params: { ...useStatInput, id: allMachines, date: date, type: type, month: selectedCustomMonth?.code }, time: 'custom' })
            setParamForDailyCups({ id: allMachines, date: date })
            getmachineStats({ ...useStatInput, id: allMachines, date });

        } else if (machineselectValue?.displayLabel != 'All' && machineselectValue?.displayLabel != 'Demo' && machineselectValue?.displayLabel != 'Commercial') {
            setFilteredDate(date)
            getOnlineStat(machineselectValue, date)
            // setUserStatParam( {params:{...useStatInput,id:[machineselectValue.id],date:date},time:'custom'} )
            setUserStatParam({ params: { ...useStatInput, id: [machineselectValue.id], date: date, month: selectedCustomMonth?.code }, time: 'custom' })
            setParamForDailyCups({ id: [machineselectValue.id], date: date })
            setParamForBevWiseDaily({ filter: 'chart', id: machineselectValue.id, date: date })
            getmachineStats({ ...useStatInput, id: [machineselectValue.id], date });
            getdateWiseCupStat({ date: date, filter: 'chart', id: machineselectValue.id })
            const userResponse = await apiServices.userInfo(machineselectValue.customer);
            setCustomerValue(userResponse?.data?.data?.billing_details?.company)
        }

        setDefaults()
    }

    const handleKettleSubmit = async (e) => {
        e.preventDefault();
        hideCustomeKettle();
        setSelectedTimeValue({ name: 'Custom', code: 'custom' });

        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        const cust_start = new Date(startt).toISOString();
        const cust_end = new Date(endd).toISOString();

        const date = { startDate: cust_start, endDate: cust_end };
        console.log(date);

        setFilteredDate(date);

        let selectedMachineData;

        if (selectedTapri.displayLabel === 'All' || selectedTapri?.displayLabel === 'Demo' || selectedTapri?.displayLabel === 'Commercial') {
            if (selectedTapri?.displayLabel === 'All') {
                selectedMachineData = allTapriMachines;
            } else if (selectedTapri?.displayLabel === 'Demo') {
                selectedMachineData = meriTapriMachines?.filter((m) => m?.datastore?.stage === 'finishedgoods' && m?.subscription && m?.subscription?.package?.site === 'demo' && m?.siteName !== '').map(a => a?.id);
            } else if (selectedTapri?.displayLabel === 'Commercial') {
                selectedMachineData = meriTapriMachines?.filter((m) => m?.datastore?.stage === 'finishedgoods' && m?.subscription && m?.subscription?.package?.site === 'permanent' && m?.siteName !== '').map(a => a?.id);
            }
            getUsageStatTapri({ id: selectedMachineData, date });
        } else {
            getUsageStatTapri({ id: [selectedTapri?.id], date });
        }
    }

    const setDefaults = () => {
        setCustomType(null)
        setSelectedCustomMonth(null)
    }

    const onChangeTab = (e) => {
        setActiveTabNumber(e.index);

        if (e.index === 0) {
            // getmachineLatLong(null);
            getMachineCoordinates(null);
        } else if (e.index === 1) {
            // getkettleLatLong(null);
            setParamForDailyCups({ id: totalMachineCount, date: filteredDate });
        }
    };


    const leftToolbarTemplate = () => (<><div className="p-inputgroup p-m-1"> <span><p><b>FILTERS: </b></p></span> </div> </>)

    const rightToolbarTemplate = () => {
        return (
            <>
                {
                    !isManagement &&
                    <div className="p-inputgroup p-m-1" style={{ minWidth: '450px', maxWidth: '450px' }}>
                        <Button className='custom-group-btn' label="Categories" />
                        <MultiSelect value={selectedBVMBrand} onChange={changeBVMBrand} options={BevVendingMchOptions} optionLabel="name" display="chip"
                            placeholder="Select Categories" maxSelectedLabels={3} className={`${selectedBVMBrand?.length == 0 && 'p-invalid'} w-full md:w-20rem`} filter />
                    </div>
                }

                <div className="p-inputgroup p-m-0">
                    <Button className='custom-group-btn' label="Type" tooltip="Subscription Type" />
                    <Dropdown id='select-mc' value={selectedMachineType} onChange={selectMachType} options={machineTypeOptions} optionLabel="name" placeholder="Select Type" filter className="p-mr-2 p-p-1 multiselect-custom " />
                </div>

                {
                    (!isManagement && isKettleSelected) &&
                    <div className="p-inputgroup p-m-1" style={{ maxWidth: '450px' }}>
                        <Button className='custom-group-btn' label="Status" />
                        <MultiSelect value={selectedMachStatus} onChange={changeMchStatus} options={mchStatusOptions} optionLabel="name" display="chip"
                            placeholder="Select Status" maxSelectedLabels={2} className={`${selectedMachStatus?.length == 0 && 'p-invalid'} w-full md:w-20rem`} filter />
                    </div>
                }

                <div className="p-inputgroup p-m-0">
                    <Button className='custom-group-btn' label="CVES" tooltip={`Total CVES : ${resultedMachine?.length - 1}`} />
                    <Dropdown id='select-mc' value={machineselectValue} onChange={SelectmachineFn} options={resultedMachine} optionLabel="displayLabel" placeholder="Select CVES" filter className="p-mr-2 p-p-1 multiselect-custom " />
                </div>

                <div className="p-inputgroup p-m-0">
                    <Button className='custom-group-btn' label="Duration" />
                    <Dropdown id='select-fil' value={timeselectValue} onChange={OnChangeTypeValue} options={isManagement ? TimeSelectValuesForManagement : TimeSelectValues} optionLabel="name" placeholder="Custom" filter
                        className="p-m-0 p-p-1 multiselect-custom" />
                </div>

                <div>
                    {!filterButton && <Button icon="pi pi-filter" label='Filter' className="custom-group-btn p-button p-my-2 p-mx-2" disabled={filterButton} onClick={filterData} />}
                </div>

            </>
        )
    }

    const customFooter = commonFormDialogFooter('Cancel', hideCustomDialog, 'Click to Filter', 'custom-form', customType)
    const customKettleFooter = commonFormDialogFooter('Cancel', hideCustomeKettle, 'Click to Filter', 'custom-kettle-form')

    const statProps = {
        filteredDate,
        userStatParam,
        selectedTimeSlot,
        allMachineCount,
        customerValue,
        onlineData,
        machinesForCount,
        machineselectValue,
        allMachineData,
        selectedBVMBrand,
        isKettleSelectedForStat,
        selectedMachStatus
    }

    const chartProps = {
        activeTabNumber,
        bevChartData,
        userStatParam,
        paramForDailyCups,
        customerValue,
        paramForBevWiseDaily,
        cupCData,
        TimeCData,
        allCupConsumeData,
        allBeverages,
        getmachineStats
    }

    const mapStyle = {
        height: '400px',
        width: '100%',
        borderRadius: '12px',
        border: '1px solid #06685d',
        boxShadow: '4px 4px 5px 3px #88888b47'
    }

    return (
        <>
            <div className="layout-dashboard">
                <div className='card'>

                    <TabView activeIndex={activeTabNumber} onTabChange={onChangeTab}>
                        <TabPanel header="CVES">
                            <Toolbar className="p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                            <AnalyticalStat {...statProps} />
                        </TabPanel>
                    </TabView>

                    <div id="map" style={mapStyle}></div>

                    <Suspense fallback={<LoadingComponent />} >
                        <AnalyticalCharts {...chartProps} />
                    </Suspense>
                </div>
            </div>

            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter" footer={customFooter} onHide={hideCustomDialog} modal className="p-fluid" maximizable={true} blockScroll={true}>
                {
                    selectedBVMBrand?.length == 0 ?
                        <center><h6 className="p-error">Please select atleast one category</h6></center>
                        :
                        <form onSubmit={handleSubmit} id="custom-form" className="p-fluid">
                            <div className='p-grid'>
                                <FullFormWrapper id="fil-type" label="Select Filter Type">
                                    <Dropdown id='fil-type' optionLabel="name" options={customWise} value={customType} onChange={(e) => { setCustomType(e.value) }} placeholder="Select Filter Type" filter className="p-mr-2 multiselect-custom " />
                                </FullFormWrapper>
                                {
                                    customType?.code == 'datewise' &&
                                    <>
                                        <FormWrapper id="from" label="From">
                                            <InputText id="from" type="date" name='start_date' max={new Date().toISOString().slice(0, 10)} value={customStart} onChange={(e) => { setCustomStart(e.target.value) }} required autoFocus />
                                        </FormWrapper>
                                        <FormWrapper id="to" label="To">
                                            <InputText id="to" type="date" name='end_date' min={customStart} max={new Date().toISOString().slice(0, 10)} value={customEnd} onChange={(e) => { setCustomEnd(e.target.value) }} required />
                                        </FormWrapper>
                                    </>
                                }

                                {
                                    customType?.code == 'monthwise' &&
                                    <>
                                        <FullFormWrapper id="select-months" label="Select Months">
                                            <Dropdown id='select-months' optionLabel="name" options={lastMonthsListDropDown} value={selectedCustomMonth} onChange={(e) => { setSelectedCustomMonth(e.value) }} placeholder="Select Last Months" filter className="p-mr-2 multiselect-custom " required />
                                        </FullFormWrapper>
                                    </>
                                }

                            </div>
                        </form>
                }
            </Dialog>

            <Dialog visible={custKettleFilterDialog} style={{ width: '550px' }} header="Custom Filter" footer={customKettleFooter} onHide={hideCustomeKettle} modal className="p-fluid" maximizable={true} blockScroll={true}>
                <form onSubmit={handleKettleSubmit} id="custom-kettle-form" className="p-fluid">
                    <div className='p-grid'>
                        <FormWrapper id="from" label="From">
                            <InputText id="from" type="date" name='start_date' max={new Date().toISOString().slice(0, 10)} value={customStart} onChange={(e) => { setCustomStart(e.target.value) }} required autoFocus />
                        </FormWrapper>
                        <FormWrapper id="to" label="To">
                            <InputText id="to" type="date" name='end_date' min={customStart} max={new Date().toISOString().slice(0, 10)} value={customEnd} onChange={(e) => { setCustomEnd(e.target.value) }} required />
                        </FormWrapper>
                    </div>
                </form>
            </Dialog>
        </>
    )
}
