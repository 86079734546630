import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Chip } from 'primereact/chip';
import { Dropdown } from 'primereact/dropdown';
// Components
import { TransactionStats } from './TransactionStats';
// Service
import CommonCal from '../../service/common/CommonCal';
import ApiServices from '../../service/api/ApiServices';

const apiServices = new ApiServices()
const commonCal = new CommonCal();

export const CustomQrTransaction = props => {

    const [globalFilter, setGlobalFilter] = useState(null);
    const [CQRTransactions, setCQRTransactions] = useState([]);

    const [allMachines,setAllMachines] = useState([{displayLabel:'All'}])
    const [selectedMachine,setSelectedMachine] = useState(null)
    const [dates,setDates] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedDurationDates,setSelectedDurationDates] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState({name: 'Today', code: 'today'});
    const [customFilterDialog, setCustomFilterDialog] = useState(false);
    const [selectedCustomDate,setSelectedCustomDate] = useState(null)
    const toast = useRef(null);
    const dt = useRef(null);

    
    useEffect(() => {
        props.allMachines && setAllMachines([...allMachines,...props.allMachines])
        getData(props.allMachines?props.allMachines[0]:null)
    }, [props.allMachines]);

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const getData = async(data) =>{
        const response = commonCal.getDates()
        setDates(response)
        setSelectedMachine(allMachines[0])
        setSelectedDurationDates({startDate: response.startToday, endDate: response.endToday})
        if(data){
            setLoading(true)
            getCustomQrData({date:{startDate: response.startToday, endDate: response.endToday}})
        }else{
            setLoading(false)
        }
    }

    const getCustomQrData = async(data) =>{
        const response = await apiServices.getQrdispense(data)
        console.log(response?.data?.data);
        const arr = []
        response?.data?.data?.map((x)=>{
            const date = new Date(x.date).toLocaleString()
            arr.push({...x,date})
        })
        setCQRTransactions(arr)
        setLoading(false)
    }

    const changeMachine = (e) =>{
        setLoading(true)
        setSelectedMachine(e.value)
        getCustomQrData({machineId:e.value.id,date:selectedDurationDates})
    }

    const changeDuration = (e) =>{
        setLoading(true)
        setSelectedDuration(e.value)
        switch (e.value.code) {

            case 'till_date':{
                    const data = {machineId:selectedMachine?.id}
                    getCustomQrData(data)
                    setSelectedDurationDates(null)
                break;
            }
            case 'today':{
                    const data = {machineId:selectedMachine?.id,date:{startDate:dates.startToday,endDate:dates.endToday}}
                    getCustomQrData(data)
                    setSelectedDurationDates(data.date)
                break;
            }
            case 'yesterday':{
                    const data = {machineId:selectedMachine?.id,date:{startDate:dates.YestStart,endDate:dates.EndStart}}
                    getCustomQrData(data)
                    setSelectedDurationDates(data.date)
                break;
            }
            case 'this_week':{
                    const data = {machineId:selectedMachine?.id,date:{startDate:dates.weekstart,endDate:dates.weekEnd}}
                    getCustomQrData(data)
                    setSelectedDurationDates(data.date)
                break;
            }
            case 'this_month':{
                    const data = {machineId:selectedMachine?.id,date:{startDate:dates.monthStart,endDate:dates.endThisMonth}}
                    getCustomQrData(data)
                    setSelectedDurationDates(data.date)
                break;
            }
            case 'last_month':{
                    const data = {machineId:selectedMachine?.id,date:{startDate:dates.lastStart,endDate:dates.lastend}}
                    getCustomQrData(data)
                    setSelectedDurationDates(data.date)
                break;
            }
            case 'custom':
                setCustomFilterDialog(true)
            break;
        
            default:{
                    const data = {machineId:selectedMachine?.id,date:{startDate:dates.startToday,endDate:dates.endToday}}
                    getCustomQrData(data)
                    setSelectedDurationDates(data.date)
                break;
            }       
        }
    }

    const changeCustomDate = e =>{
        setSelectedCustomDate({...selectedCustomDate,[e.target.name]:e.target.value})
    }

    const customSubmit = (e) =>{
        e.preventDefault()
        const startt = new Date(selectedCustomDate.start_date).setHours(0, 0, 0, 0);
        const endd = new Date(selectedCustomDate.end_date).setHours(23, 59, 59, 0);
        const date = { startDate: new Date(startt).toISOString(), endDate:new Date(endd).toISOString() };
        const data = {machineId:selectedMachine?.id,date:date}
        getCustomQrData(data)
        setSelectedDurationDates(date)
        setCustomFilterDialog(false)
    }

    const srBodyTemplate = (rowData, props) => (<><span className="p-column-title">{props.header}</span>{props.rowIndex + 1}</>)
    const despensedBodyTemplate = (rowData,props) => (<><span className="p-column-title">{props.header}</span>{rowData[props.field] ? <i className="pi pi-check p-success"></i> : <i className="pi pi-times p-error"></i>}</>)
    const bodyTemplate = (data, props) => (<><span className="p-column-title">{props.header}</span>{data[props.field]}</>)

    const dateBodyTemplate = (data, props) => {
        const date = new Date(data[props.field]).toLocaleString();
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {date}
            </>
        );
    };

    const header = (
        <div className="table-header">
            <h6 className="p-m-0">Custom Qr Transactions</h6>
            <span className="p-input-icon-left">
            <div className="p-inputgroup p-mr-2">
                <Button className='custom-group-btn' icon="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4'  onClick={exportCSV}/>
            </div>
            </span>
        </div>
    );

    const leftToolbarTemplate = () => (<><Chip label={`Total No. Of QR CVES : ${allMachines ? allMachines?.filter((x)=>{ return x.mchmode && x.mchmode.paymode.qr == true}).length :'-'}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" /></>)
    const rightToolbarTemplate = () => (
        <>     
            <div className="p-inputgroup p-mr-2">
                    <Button className='custom-group-btn' label="CVES" />
                    <Dropdown value={selectedMachine} onChange={changeMachine} options={allMachines} optionLabel="displayLabel" placeholder="Select Filter" filter
                        className="p-m-0 multiselect-custom" />
            </div>          
            <div className="p-inputgroup ">
                    <Button className='custom-group-btn' label="DURATION" />
                    <Dropdown value={selectedDuration} onChange={changeDuration} options={props.durationOptions} optionLabel="name" placeholder="Select Duration" filter
                    className="p-m-0 multiselect-custom" />
            </div>
        </>
    )


    return (

        <>
        {/* <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card"> */}
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar>

                    <div className="layout-dashboard">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                {
                                    (selectedMachine?.displayLabel == 'All') ? 
                                    <TransactionStats title={`Total CVES's`} value={allMachines ? allMachines?.filter((x)=>{ return x.mchmode && x.mchmode.paymode.qr == true}).length : '-'} icon="pi pi-copy" loading={loading} />
                                    : 
                                    <TransactionStats title={`CVES`} value={selectedMachine?.displayLabel} icon="pi pi-sliders-h" loading={loading} />
                                }
                            </div>
                            <div className="p-col-12 p-md-6">
                                <TransactionStats title={'Total Custom QR Transaction'} value={CQRTransactions?.length} icon="pi pi-sliders-h" loading={loading} />
                            </div>
                        </div>
                    </div>

                    <DataTable ref={dt} value={CQRTransactions} 
                        dataKey="id" paginator rows={20} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={globalFilter} emptyMessage="No Transactions found." header={header} loading={loading} exportFilename={`Custom-QR Transactions (${selectedMachine?.id})`}>
                        <Column field="id" header="Sr.No." sortable body={srBodyTemplate} exportable={false}></Column>
                        <Column field='orderId' header="Order ID" body={bodyTemplate}></Column>
                        <Column field="cups" header="Cups" body={bodyTemplate} sortable></Column>
                        <Column field="date" header="Date" sortable body={bodyTemplate}></Column>
                        <Column field="dispensed" header="Dispensed" sortable body={despensedBodyTemplate}></Column>
                    </DataTable>
                {/* </div>
            </div>
        </div> */}

        
        <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter"  onHide={()=>{setCustomFilterDialog(false)}} modal className="p-fluid">
            <form onSubmit={customSubmit}>
            <div className='p-grid'>
                <div className='p-col-6'>
                    <label htmlFor="from">From</label>
                    <InputText id="from" type="date" name='start_date' value={selectedCustomDate?.start_date} onChange={changeCustomDate} required autoFocus/>
                </div>
                <div className='p-col-6'>
                    <label htmlFor="to">To</label>
                    <InputText id="to" type="date" name='end_date' value={selectedCustomDate?.end_date} onChange={changeCustomDate} required/>
                </div>
            </div>
            <div className="p-dialog-footer p-mt-4">
                <button className="p-button p-button p-button-danger" onClick={()=>{setCustomFilterDialog(false)}}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
                <button className="p-button p-button-success" type="submit"><span className="p-button-icon p-c pi pi-check p-button-icon-left"></span><span className="p-button-label p-c">Submit</span><span className="p-ink"></span></button>
            </div>
            </form>
        </Dialog>

        </>
    );
}
