import React, { useEffect, useState } from 'react';

import { TabView } from 'primereact/tabview';
import { TabPanel } from 'primereact/tabview';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DemoMISStats } from './DemoMISStats';
import { managementCheck } from '../../service/admin/superadmin';
// Services
import ApiServices from '../../service/api/ApiServices';
const apiServices = new ApiServices();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

const ENDPOINT = process.env.REACT_APP_IOT;

export const DemoMISReport = (props) => {


    const [salesStat, setSalesStat] = useState([]);
    const [superstarAcc, setSuperstarAcc] = useState([]);
    const [demomachineCount, setDemomachineCount] = useState('-');
    const [demoCupCount, setDemoCupCount] = useState('-');
    const [timeWiseData, setTimeWiseData] = useState();
    const [hourWiseData, setHourWiseData] = useState();

    const [nineToTwoData, setNineToTwoData] = useState();
    const [twoToSevenData, setTwoToSevenData] = useState();
    const [sevenToTwelveData, setSevenToTwelveData] = useState();
    const [twelveToNineData, setTwelveToNineData] = useState();

    const [weekdaysData, setWeekdaysData] = useState();
    const [weekendData, setWeekendData] = useState();
    const [morningData, setMorningData] = useState();
    const [eveningData, setEveningData] = useState();
    const [beverageWiseDemoData, setBeverageWiseDemoData] = useState();
    const [destributorWiseDemoData, setDestributorWiseDemoData] = useState();
    const [onboardMachines, setOnboardMachines] = useState(null);
    const [allMachines, setAllMachines] = useState(null);
    const [totalCupCount, setTotalCupCount] = useState(null);
    const [ioStats, setIoStats] = useState(null);
    const [iOLoading, setIOLoading] = useState(true);
    const [superStarValue, setSuperstarValue] = useState(null);



    const [flavourWiseData, setFlavourWiseData] = useState([]);
    const [masterLoader, setMasterLoader] = useState(true);
    const [flavourData, setFlavourData] = useState(null)
    const [flavourNameData, setFlavourNameData] = useState(null)
    const [flavourColorData, setFlavourColorData] = useState(null)


    const machineTypeValues = [
        { name: 'CTVM' },
        { name: 'Bulk' }
    ];

    const AccountTypeValues = [
        { name: 'Account' },
        { name: 'Facility' },
        { name: 'Subscription' }
    ];

    const AccountValues = [
        { name: 'All' },
        { name: 'Kanakia Spaces Realty Pvt Ltd' },
        { name: 'Tapcapital pvt ltd' },
        { name: 'Sokrati Technology Pvt Ltd' },
        { name: 'jain Construction' },
    ]

    const multiselectValues = [
        { name: 'TILL DATE' },
        { name: 'TODAY' },
        { name: 'YESTERDAY' },
        { name: 'THIS WEEK' },
        { name: 'THIS MONTH' },
        { name: 'LAST MONTH' },
        { name: 'CUSTOM' }
    ];

    const timeWiseChart = {
        labels: ['Weekdays', 'Weekends', 'Morning', 'Evening'],
        datasets: [
            {
                // data: timeWiseData,
                data: [12476909, 3119228, 10917296, 4678841],
                backgroundColor: [
                    '#0F8BFD',
                    '#FC6161',
                    '#EC4DBC',
                    '#EEE500'
                ],
                hoverBackgroundColor: [
                    '#0F8BFD',
                    '#FC6161',
                    '#EC4DBC',
                    '#EEE500'
                ],
                borderColor: 'transparent',
                fill: true
            }
        ]
    };


    const hourWiseChart = {
        labels: ['9AM to 2PM', '2PM to 7PM', '7PM to 12AM', '12AM to 9AM'],
        datasets: [
            {
                data: hourWiseData,
                backgroundColor: [
                    '#0F8BFD',
                    '#FC6161',
                    '#00D0DE',
                    '#873EFE'
                ],
                hoverBackgroundColor: [
                    '#0F8BFD',
                    '#FC6161',
                    '#00D0DE',
                    '#873EFE'
                ],
                borderColor: 'transparent',
                fill: true
            }
        ]
    };

    const timeWiseChartOptions = {
        responsive: true
    };



    const basicData = {

        labels: flavourNameData,

        datasets: [
            {
                label: 'Cup Consumption',
                data: flavourData,
                backgroundColor: flavourColorData,
            }
        ]
    };


    const basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };


    const barData = {
        labels: [

            'Global Marketing Solutions',
            'The Blue Eye Beverages - AMC',
            'Khatri Enterprises',
            'Great World Enterprises',
            'Robinhood Foods',
            'Om Foods',
            'Sherawat Traders',
            'Omega Enterprises',
            'Bighammer Works Private Limited',
            'Bela Enterprises'

            // 'Devki Motors LLP',
            // 'Global Marketing Solution',
            // 'Omega Enterprise',
            // 'Bighammer Works Pvt Ltd',
            // 'Shillong Enterprise',
            // 'DN International',
            // 'Bandita Agency',
            // 'Saurav Agency',
            // 'Vikapl and CO',
            // 'Reliable Marketing',

            // 'NMDC data center Pvt ltd',
            // 'Tata Motors(Rudra Motors)',
            // 'SKECHERS South Asia India Pvt Ltd.',
            // 'Grauer & Weil (India) Limited',
            // 'Devki Motors LLP',
            // 'Ador Digatron Pvt. Ltd'

        ],



        datasets: [
            {
                label: 'No of Cups',
                backgroundColor: '#00585d',
                borderColor: '#00585d',
                data: destributorWiseDemoData,
                // data: [
                //     4999680,
                //     1714176,
                //     1571328,
                //     1285832,
                //     1142782,
                //     999936,
                //     857088,
                //     714240,
                //     571392,
                //     428544
                // ],

                barPercentage: 0.7

            }
        ]
    };

    const barOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#A0A7B5'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#A0A7B5'
                },
                grid: {
                    color: 'rgba(160, 167, 181, .3)',
                }
            },
            y: {
                ticks: {
                    color: '#A0A7B5'
                },
                grid: {
                    color: 'rgba(160, 167, 181, .3)',
                }
            }
        }
    };


    const salesData = [
        {
            "id": 1,
            "header": "No. Of Distributors",
            "value": "67",
            "icon": "pi pi-user"
        },
        {
            "id": 2,
            "header": "No of Accounts",
            "value": "204",
            "icon": "pi pi-th-large"
        },
        {
            "id": 3,
            "header": "No of CTVM's",
            "value": `${demomachineCount}`,
            "icon": "pi pi-sitemap"
        },
        {
            "id": 4,
            "header": "Number Of Cups Sold (till date)",
            "value": "14284800",
            "icon": "pi pi-slack"
        },
        {
            "id": 5,
            "header": "Average Per Cup Sale Cost",
            "value": "INR 5.75 + GST",
            "icon": "pi pi-wallet"
        },
        {
            "id": 6,
            "header": "All Time Favourite Flavor",
            "value": "MADRAS COFFEE",
            "icon": "pi pi-star-o"
        }
    ]

    const Superstardata = [
        {
            "id": 1,
            "header": "Maximum CTVM Deployment Super-Star Account",
            "value": "Ador Powertron Pvt Ltd",
            "key": "cves_deployed_super_star"

        },
        {
            "id": 2,
            "header": "Top Cup Consumption Super-Star Account",
            "value": "Topworth Pipes & Tubes Pvt Ltd",
            "key": 'cup_consumption_super_star'
        },
        {
            "id": 3,
            "header": "Highest Target Performance Super-Star Account",
            "value": "Sokrati Technology Pvt Ltd, Baner",
            "key": "target_performance_super_star"
        },
        {
            "id": 4,
            "header": "Maximum Inventory Holding Super-Star Account",
            "value": "NMDC data center Pvt ltd",
            "key": "inventory_super_star"
        }
    ]

    const useStatInput1 = { id: [""] }


    let horizontalOptions = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#A0A7B5'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    const basicData1 = {
        labels: ['Weekdays', 'Weekends', 'Morning', 'Evening'],
        datasets: [
            {
                label: '',
                backgroundColor: [
                    '#0F8BFD',
                    '#FC6161',
                    '#EC4DBC',
                    '#EEE500'
                ],

                data: timeWiseData,
                // data:  [12476909, 3119228, 10917296, 4678841],
            }
        ]
    };

    const basicData2 = {
        labels: ['9AM to 2PM', '2PM to 7PM', '7PM to 12AM', '12AM to 9AM'],
        datasets: [
            {
                label: '',

                // data:  [12476909, 3119228, 10917296, 4678841],

                data: hourWiseData,
                backgroundColor: [
                    '#0F8BFD',
                    '#FC6161',
                    '#00D0DE',
                    '#873EFE'
                ],
                hoverBackgroundColor: [
                    '#0F8BFD',
                    '#FC6161',
                    '#00D0DE',
                    '#873EFE'
                ],
                borderColor: 'transparent',
                fill: true
            }
        ]
    };



    const getDemoDataForChart = async (data) => {
        const response = await apiServices.getMaterialColor();
        let totalConsumption = data?.usagesStats[0]?.totalCup;
        const arr = [];
        response?.data?.data?.forEach((element) => {
            const materialName = element.materialName;
            data?.brvStats?.map((x) => {
                if (materialName == x._id) {
                    arr.push({ ...x, color: element.color })
                }
            });
        });
        arr.sort((a, b) => Number(b.totalCup) - Number(a.totalCup));
        // setFlavourWiseData(arr);
        console.log('arrr', arr);
        let superStarCalc = arr.length > 0? arr[0]._id:null;
        setSuperstarValue(superStarCalc)


        // const date1 = new Date('3/27/2022');
        // const date2 = new Date();
        // const diffTime = Math.abs(date2 - date1);
        // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        // const machinesCount = 512 + diffDays;

        // let NoofCups = 62;
        // let NoOfMachines = machinesCount;
        // let DaysPerMonth = 25;
        // let NoOfMonths = 18;


        // const inorganicIni = (NoofCups * NoOfMachines * DaysPerMonth * NoOfMonths) + totalConsumption;

        // setDemomachineCount(machinesCount)
        // setDemoCupCount(inorganicIni)

        console.log('Total Consumption', totalConsumption);

        setNineToTwoData(Math.ceil(totalConsumption * 40 / 100))
        setTwoToSevenData(Math.ceil(totalConsumption * 30 / 100))
        setSevenToTwelveData(Math.ceil(totalConsumption * 20 / 100))
        setTwelveToNineData(Math.ceil(totalConsumption * 10 / 100))

        setWeekdaysData(Math.ceil(totalConsumption * 80 / 100))
        setWeekendData(Math.ceil(totalConsumption * 20 / 100))
        setMorningData(Math.ceil(totalConsumption * 70 / 100))
        setEveningData(Math.ceil(totalConsumption * 30 / 100))

        const dataOfSalesWise = [Math.ceil(totalConsumption * 80 / 100), Math.ceil(totalConsumption * 20 / 100), Math.ceil(totalConsumption * 70 / 100), Math.ceil(totalConsumption * 30 / 100)]
        setTimeWiseData(dataOfSalesWise)

        const HourWiseArr = [Math.ceil(totalConsumption * 40 / 100), Math.ceil(totalConsumption * 30 / 100), Math.ceil(totalConsumption * 20 / 100), Math.ceil(totalConsumption * 10 / 100)];
        setHourWiseData(HourWiseArr)

        let result = arr.map(a => a.totalCup);
        let resultedNames = arr.map(a => a._id);
        let resultedColors = arr.map(a => a.color);

        console.log('result', result);
        console.log('resultedNames', resultedNames);
        console.log('resultedColors', resultedColors);

        setFlavourData(result)
        setFlavourNameData(resultedNames)
        setFlavourColorData(resultedColors)

        const BeverageDemoData = []
        setBeverageWiseDemoData(BeverageDemoData)

        const DestributorDemoData = [Math.ceil(totalConsumption * 35 / 100), Math.ceil(totalConsumption * 12 / 100), Math.ceil(totalConsumption * 11 / 100), Math.ceil(totalConsumption * 9 / 100), Math.ceil(totalConsumption * 8 / 100)
            , Math.ceil(totalConsumption * 7 / 100), Math.ceil(totalConsumption * 6 / 100), Math.ceil(totalConsumption * 5 / 100), Math.ceil(totalConsumption * 4 / 100), Math.ceil(totalConsumption * 3 / 100)
        ];

        setDestributorWiseDemoData(DestributorDemoData)
    }


    useEffect(() => {
        setSalesStat(salesData);
        getMachines()
        // setSuperstarAcc(Superstardata);
        // getDemoDataForChart()
        getIoStats()
        getCVESStats()
    }, []);



    const getCVESStats = async () => {
        const response = await apiServices.getCVESStats({});
        console.log(response?.data?.data);
        const filteredData = Superstardata.map((x) => {
            return { ...x, value: response?.data?.data[x?.key] ? response?.data?.data[x?.key] : x.value }
        })

        setSuperstarAcc(filteredData)

    }

    const getIoStats = async () => {
        const ioStatsResponse = await apiServices.getIoStats();
        console.log(ioStatsResponse.data.data);
        setIoStats(ioStatsResponse.data.data)
    }


    const getMachines = async () => {
        const filters = { brand: ['TAPRI', 'TKAFFE', 'MERI_TAPRI'] }
        const response = await apiServices.onboardedMachines(filters);
        const response1 = await apiServices.allUsers({ platform: "dashboard", index: 0, count: 2000 });
        let cvesArrayList = []
        response?.data?.data?.forEach((x) => {
            response1?.data?.data.filter((y) => {
                if (x.customer === y.userid) {
                    cvesArrayList.push(
                        {
                            ...x,
                            accountName: y.name,
                            companyName: y?.billing_details?.company || x.companyName,
                            facilityName: x?.subscription?.facilitydata?.facname || 'NA'
                        }
                    )
                }
            })
        })
        console.log(cvesArrayList);
        setOnboardMachines(cvesArrayList)
        setAllMachines(cvesArrayList);
        getStats(cvesArrayList)

    }



    const getStats = async (data) => {
        const idArray = await data.map(x => x.id)
        const response = await apiServices.getUserStats({ beverage: "All", id: idArray, userId: initialValue.userid, userType: initialValue.userType });
        console.log('response', response.data);
        setTotalCupCount(response.data.data.usagesStats[0].totalCup);
        getDemoDataForChart(response.data.data)
        setIOLoading(false)
    }


    const lineData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'First Dataset',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
                backgroundColor: 'rgb(255, 205, 86)',
                borderColor: 'rgb(255, 205, 86)',
                tension: .4
            },
            {
                label: 'Second Dataset',
                data: [28, 48, 40, 19, 86, 27, 90],
                fill: false,
                backgroundColor: 'rgb(75, 192, 192)',
                borderColor: 'rgb(75, 192, 192)',
                tension: .4
            }
        ]
    };



    const lineOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#A0A7B5'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#A0A7B5'
                },
                grid: {
                    color: 'rgba(160, 167, 181, .3)',
                }
            },
            y: {
                ticks: {
                    color: '#A0A7B5'
                },
                grid: {
                    color: 'rgba(160, 167, 181, .3)',
                }
            }
        }
    };



    return (

        <>

            <>

                <div className='p-grid'>
                    <div className='p-col-12'>



                        <div className='card'>
                            <h6>Sales Overall Statastics</h6>
                            <div className="p-grid">

                                <DemoMISStats onboardMachines={onboardMachines} totalCupCount={totalCupCount} ioStats={ioStats} iOLoading={iOLoading} superStarValue={superStarValue} />


                            </div>
                        </div>


                        <div className="card">
                            <div className="p-grid">

                                <div className="p-col-12 p-md-6">
                                    {
                                        iOLoading ?
                                            <>
                                                <center>
                                                    <span><i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i></span>
                                                </center>
                                            </>
                                            :
                                            <div className="card widget-country-graph">
                                                <div className="country-title">SALES TIME-WISE STATASTICS</div>
                                                <Chart type="bar" data={basicData1} options={horizontalOptions} />
                                            </div>
                                    }

                                </div>

                                <div className="p-col-12 p-md-6">

                                    {
                                        iOLoading ?
                                            <>
                                                <center>
                                                    <span><i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i></span>
                                                </center>
                                            </>
                                            :
                                            <div className="card widget-country-graph">
                                                <div className="country-title">SALES HOUR-WISE STATISTICS</div>
                                                <Chart type="bar" data={basicData2} options={horizontalOptions} />
                                            </div>
                                    }
                                </div>


                            </div>
                        </div>

                        <div className='card'>
                            <h6>PERFORMANCE-WISE SUPERSTAR ACCOUNTS</h6>
                            <div className="p-grid">

                                {
                                    superstarAcc.length ? superstarAcc.map(stat => (

                                        <div className="p-col-12 p-md-6">
                                            <div className="card widget-overview-box widget-overview-box-1">
                                                <span className="overview-title">
                                                    {stat.header}
                                                </span>
                                                <div className="p-d-flex p-jc-between">
                                                    <div className="overview-detail p-d-flex p-jc-between">
                                                        <div className="overview-text">
                                                            <b>{stat.value}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <i className="pi pi-sitemap side-icon" style={{ fontSize: '2rem' }}></i>
                                            </div>
                                        </div>

                                    )) : <p>No data is available</p>
                                }

                            </div>
                        </div>

                    </div>
                </div>

                <div className="p-grid crud-demo">
                    <div className="p-col-12">
                        {
                            iOLoading ?
                                <>
                                    <center>
                                        <span><i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i></span>
                                    </center>
                                </>
                                :
                                <div className="card">
                                    <TabView>
                                        <TabPanel header="Flavourwise Cup Consumption">
                                            <div className="card p-px-0">
                                                <h5>Beverage-Wise Cup Consumption Performance Details</h5>
                                                <Chart height="600" type="bar" data={basicData} options={basicOptions} />
                                            </div>
                                        </TabPanel>
                                        <TabPanel header="Top 10 Distributors Performance wise">

                                            <div className="card">
                                                <div className="card-header">
                                                    <span>Top 10 Distributors Performance Wise</span>
                                                </div>
                                                <div className="graph">
                                                    <Chart type="bar" data={barData} options={barOptions} />
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </TabView>
                                </div>
                        }

                    </div>
                </div>


            </>

        </>
    )
}

