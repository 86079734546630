import React, { useEffect, useState, useRef } from 'react'
import { Chart } from 'primereact/chart';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Chip } from 'primereact/chip';
// Constants
import { CommonBodyForFormattedStrTable, DateBodyForTable, commonCloseDialogFooter, statusBodyTemplate } from '../Common/CommonComponents';
import { durationOptions, viewOption } from './Constant';
import { srBodyTemplate, CommonBodyForTable } from '../Common/CommonComponents';
import { exportReport } from '../Common/CommonFunctions';

// =========================================================================
import CommonCal from '../../service/common/CommonCal';
import ApiServices from '../../service/api/ApiServices'
const apiServices = new ApiServices();
const commonCal = new CommonCal();

// ====================================================================================
// Swapnil / 29-09-2023
// ====================================================================================/

export const LevelChart = () => {

    const [loading, setLoading] = useState(true)
    const [globalFilter, setGlobalFilter] = useState("");
    const [levelLoading, setLevelLoading] = useState(true)
    const [machineOptions, setMachineOptions] = useState(null)
    const [selectedMch, setSelectedMch] = useState({ displayName : 'All'})
    const [levelData, setLevelData] = useState([])
    const [levelDataDialog, setLevelDataDialog] = useState(false)
    const [selectedBar, setSelectedBar] = useState(null)
    const [selectedDuration, setSelectedDuration] = useState({name:'This Month',code:'this_month'})
    const [datesData, setDatesData] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [usersData, setUsersData] = useState(null)
    const [barcodeDetails, setBarcodeDetails] = useState(null)
    const [barcodeFlag, setBarcodeFlag] = useState(false)
    const [selectedViewType, setSelectedViewType] = useState({name:'Day Wise', code:'daywise'})
    const [labelDates, setLabelDates] = useState(null)
    const [barcodeChart, setBarcodeChart] = useState(null)
    const [cricticalChart, setCricticalChart] = useState(null)
    const [standardChart, setStandardChart] = useState(null)
    const [canUnlockSet, setCanUnlockSet] = useState(null)
    const [canLockDialog, setCanLockDialog] = useState(false)
    const [canisterUnlockData, setCanisterUnlockData] = useState(null)
    const [canUnlockMasterData, setCanUnlockMasterData] = useState(null)
    const [selectedSubType, setSelectedSubType] = useState({name:'All', code:'all'})
    const [consumClicksChart, setConsumClicksChart] = useState(null)
    const [clicksMasterData, setClicksMasterData] = useState(null)
    const [clicksDialog, setClicksDialog] = useState(false)
    const [allAccountsData, setAllAccountsData] = useState(null)
    const [dataForReport, setDataForReport] = useState(null)

    const [customFilterDialog, setCustomFilterDialog] = useState(false)
	const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    const [statData, setStatData] = useState({});

    const dt = useRef(null);
    const dataReport = useRef(null);

    useEffect(()=>{
        // getMachine();
        getDates();
        getUsers()
    },[])

    const getUsers = async() =>{
        const userResponse = await apiServices.getUserReduced();
        console.log(userResponse?.data?.data);
        setUsersData(userResponse?.data?.data)
    }

    const getDates = () =>{
        const response = commonCal.getDates()
        setDatesData(response)

        if(response){
            const date = { date : { startDate: response.monthStart, endDate:response.endThisMonth } }
            setSelectedDate(date)
            getMachine(date)
        }
    }

    const getMachine = async (date) => {
        try {
            const response = await apiServices.findMachines();
            const machines = response?.data?.data || [];
            const filteredMachines = machines
                .filter((machine) => machine.siteName !== '')
                .map(({ id, subid, siteName, ...rest }) => ({
                    id, subid, siteName,
                    displayName: `${id}/${subid}/${siteName}`,
                    ...rest,
                }));

            const options = [{ displayName: 'All' }, ...filteredMachines];
            setMachineOptions(options);
            getData(date);
        } catch (error) {
            console.error('Error while fetching machines:', error);
        }
    };

    const getData = async(data) =>{
        setLoading(true)
        const payload = data;
        console.log(payload);

        if(payload['accountWise']){

            const response = await apiServices.getLevelChartData(payload);
            const canResponse = await apiServices.canisterUnloackData(payload);
            const clicksResponse = await apiServices.consumptionClicksData(payload);

            const barcodeData = response?.data?.data?.barcodeWise.map((x)=>{ 
                const data = usersData.filter((y)=>{ return y.userid == x._id });
                if(data.length > 0){
                    return { acc_id : x._id, id : `${data[0]?.billing_details?.company}`, barcodeCount : x.count}
                }else{
                    return { acc_id : x._id, id : x._id ? x._id : 'Unknown', barcodeCount : x.count } 
                }
            });

            const accWiseData = canResponse?.data?.data?.map((x)=>{
                const data = usersData.filter((y)=>{ return y.userid == x._id });
                if(data.length > 0){
                    return { acc_id : x._id, id : `${data[0]?.billing_details?.company}`, canUnlock : x.count}
                }else{
                    return { acc_id : x._id, id : x._id ? x._id : 'UnKnown', canUnlock : x.count};
                }
            })

            const clicksCount = clicksResponse?.data?.data?.map((x)=>{
                const data = usersData.filter((y)=>{ return y.userid == x._id });
                if(data.length > 0){
                    return { acc_id : x._id, id : `${data[0]?.billing_details?.company}`, clickCount : x.count}
                }else{
                    return { acc_id : x._id, id : x._id ? x._id : 'UnKnown', clickCount : x.count};
                }
            })

            const dateLabels = [...accWiseData,...clicksCount,...barcodeData]
            setAllAccountsData(dateLabels)

            const mapppedDates = dateLabels.map((x)=>{ return x.id});
            const uniqueDates = [...new Set(mapppedDates)];
            setLabelDates(uniqueDates);

            const concatenatedArray = dateLabels.reduce((acc, obj) => {
                const existingObj = acc.find(item => item.id === obj.id);
                if (existingObj) {
                    Object.assign(existingObj, obj);
                } else {
                    acc.push(obj);
                }
                return acc;
            }, []);
            setDataForReport(concatenatedArray)
            const chartBarcode = concatenatedArray?.map((x)=>{ return x.barcodeCount || 0 })
            const criticalLevel = concatenatedArray?.map((x)=>{ return x.criticalCalib || 0 })
            const stdLevel = concatenatedArray?.map((x)=>{ return x.stdCalib || 0 })
            const canUnlockData = concatenatedArray?.map((x)=>{ return x.canUnlock || 0 })
            const conClicksData = concatenatedArray?.map((x)=>{ return x.clickCount || 0 })

            setBarcodeChart(chartBarcode)
            setCricticalChart(criticalLevel)
            setStandardChart(stdLevel)
            setCanUnlockSet(canUnlockData)
            setConsumClicksChart(conClicksData)
            getTotalStats(concatenatedArray)

        }else{

            const response = await apiServices.getLevelChartData(payload);
            const canResponse = await apiServices.canisterUnloackData(payload);
            const clicksResponse = await apiServices.consumptionClicksData(payload);
            
            const barcodeData = response?.data?.data?.barcodeWise.map((x)=>{ return { id : x._id, barcodeCount : x.count } });
            const criticalCalibration = response?.data?.data?.levelWise[0].CRITICAL_CALIBRATE.map((x)=>{ return { id : x._id, criticalCalib : x.count } });
            const stdCalibration = response?.data?.data?.levelWise[0].STANDARD_CALIBRATE.map((x)=>{ return { id : x._id, stdCalib : x.count } });
            const canUnlockCount = canResponse.data.data.map((x)=>{ return { id : x._id, canUnlock : x.count} })
            const clicksCount = clicksResponse.data.data.map((x)=>{ return { id : x._id, clickCount : x.count} })

            const dateLabels = [...barcodeData,...criticalCalibration,...stdCalibration,...canUnlockCount,...clicksCount]
            const mapppedDates = dateLabels.map((x)=>{ return x.id});
            const uniqueDates = [...new Set(mapppedDates)];

            setLabelDates(uniqueDates);

            const concatenatedArray = dateLabels.reduce((acc, obj) => {
            const existingObj = acc.find(item => item.id === obj.id);
                if (existingObj) {
                    Object.assign(existingObj, obj);
                } else {
                    acc.push(obj);
                }
                return acc;
            }, []);

            console.log(concatenatedArray);
            setDataForReport(concatenatedArray)
            const chartBarcode = concatenatedArray?.map((x)=>{ return x.barcodeCount || 0 })
            const criticalLevel = concatenatedArray?.map((x)=>{ return x.criticalCalib || 0 })
            const stdLevel = concatenatedArray?.map((x)=>{ return x.stdCalib || 0 })
            const canUnlockData = concatenatedArray?.map((x)=>{ return x.canUnlock || 0 })
            const conClicksData = concatenatedArray?.map((x)=>{ return x.clickCount || 0 })

            setBarcodeChart(chartBarcode)
            setCricticalChart(criticalLevel)
            setStandardChart(stdLevel)
            setCanUnlockSet(canUnlockData)
            setConsumClicksChart(conClicksData)
            getTotalStats(concatenatedArray)

            console.log(chartBarcode);
            console.log(criticalLevel);
            console.log(stdLevel);
            console.log(canUnlockData);

        }

        setLoading(false)
    }

    const getTotalStats = (data) =>{
        if(data){

            const totalBarcode = data?.reduce((acc,curr)=>{ return (curr?.barcodeCount || 0) + acc; },0)
            const totalUnlocks = data?.reduce((acc,curr)=>{ return (curr?.canUnlock || 0) + acc; },0)
            const totalCritical = data?.reduce((acc,curr)=>{ return (curr?.criticalCalib || 0) + acc; },0)
            const totalStandard = data?.reduce((acc,curr)=>{ return (curr?.stdCalib || 0) + acc; },0)
            const totalClicks = data?.reduce((acc,curr)=>{ return (curr?.clickCount || 0) + acc; },0)

            setStatData( { totalBarcode, totalUnlocks, totalCritical, totalStandard, totalClicks } )
        }
    }

    const getRequiredData = (data) =>{
        const dataIndex = data[0]?.datasetIndex;
        if((dataIndex === 0 || dataIndex === 1)){
            getCalibrationDetailed(data, dataIndex)
        }else if(dataIndex === 2){
            getBarcodeDetailed(data, dataIndex)
        }else if(dataIndex === 3){
            getCanisterUnlockDetailed(data, dataIndex)
        }else if(dataIndex === 4){
            getClicksDetailed(data, dataIndex)
        }
    }

    const getClicksDetailed = (data, dataIndex) =>{

        const date = labelDates[data[0]?.index];
        if (date && dataIndex === 4) {
        //   setBarcodeFlag(true);
            setClicksDialog(true);
            setSelectedBar(date);

            if (selectedViewType.code === 'daywise') {
                const thisDates = getTwoDates(date);
                const payload = {
                    id: selectedMch?.displayName === 'All' ? null : selectedMch?.id,
                    date: { startDate: thisDates?.firstDate, endDate: thisDates?.lastDate },
                };
                getDetailedClicks(payload);
            } else if (selectedViewType.code === 'machinewise') {
                const payload = { id: date, date: selectedDate?.date };
                getDetailedClicks(payload);
            }else if(selectedViewType.code === 'accountWise'){
                const accountArr = allAccountsData.filter((x)=>{ return x.id === date })
                if(accountArr?.length > 0){
                    const payload = { userId: accountArr[0]?.acc_id, date: selectedDate?.date };
                    getDetailedClicks(payload);
                }
            }
        }
    }

    const getDetailedClicks = async(data) =>{
        setLevelLoading(true)
        const response = await apiServices.detailedClicksData(data);
        console.log(response?.data?.data);

        const filData = response?.data?.data?.map((x)=>{
                const data = usersData.filter((y)=>{ return y.userid == x.consumption_click_userid });
                const custData = usersData.filter((y)=>{ return y.userid == x.consumption_click_customerId });
                if(data.length > 0){
                    return {
                        ...x,
                        userName : `${data[0].name} ${data[0].lastName}`, 
                        customer : custData[0]?.billing_details?.company || `${custData[0]?.name} ${custData[0]?.lastName}`,
                        subscription_type : x.subscription_type === 'demo' ? 'Demo' : 'Commercial'
                    }
                }else{
                    return {...x, subscription_type : x.subscription_type === 'demo' ? 'Demo' : 'Commercial'};
                }
        })  

        console.log(filData);
        setClicksMasterData(filData)
        setLevelLoading(false)
    }

    const getCalibrationDetailed = (data, dataIndex) => {
        const date = labelDates[data[0]?.index];
        if (!date) return;

        let calType;
        if (dataIndex === 0) {
            calType = 'STANDARD_CALIBRATE';
        } else if (dataIndex === 1) {
            calType = 'CRITICAL_CALIBRATE';
        } else {
            return;
        }

        setLevelDataDialog(true);
        setSelectedBar(date);

        if (selectedViewType.code === 'daywise') {
            const thisDates = getTwoDates(date);
            const payload = {
                "date": { "startDate": thisDates?.firstDate, "endDate": thisDates?.lastDate },
                "calibrate_type": calType,
                "id": selectedMch?.displayName === 'All' ? null : selectedMch.id,
            };

            getDetailedLevelData(payload);

        } else if(selectedViewType.code === 'machinewise'){
            const payload = { "date": selectedDate?.date, "calibrate_type": calType, "id": date };
            getDetailedLevelData(payload);
        }
    };

    const getBarcodeDetailed = (data, dataIndex) => {
        const date = labelDates[data[0]?.index];
        console.log(date);
        if (date && dataIndex === 2) {
            setBarcodeFlag(true);
            setLevelDataDialog(true);
            setSelectedBar(date);

            if (selectedViewType.code === 'daywise') {
                const thisDates = getTwoDates(date);
                const payload = {
                    id: selectedMch?.displayName === 'All' ? null : selectedMch?.id,
                    date: { startDate: thisDates?.firstDate, endDate: thisDates?.lastDate },
                };
                getDetailedBarcode(payload);
            } else if (selectedViewType.code === 'machinewise') {
                const payload = { id: date, date: selectedDate?.date };
                getDetailedBarcode(payload);
            }else if(selectedViewType.code === 'accountWise') {
                const accountArr = allAccountsData.filter((x)=>{ return x.id === date })
                if(accountArr?.length > 0){
                    const payload = { userId: accountArr[0]?.acc_id, date: selectedDate?.date };
                    getDetailedBarcode(payload);
                }
            }
        }
    };

    const getCanisterUnlockDetailed = (data, dataIndex) => {
        const date = labelDates[data[0]?.index];

        if (date && dataIndex === 3) {
            setCanLockDialog(true);
            setSelectedBar(date);
        
            if (selectedViewType.code === 'daywise') {
                const thisDates = getTwoDates(date);
                const payload = {
                    id: selectedMch?.displayName === 'All' ? null : selectedMch?.id,
                    date: { startDate: thisDates?.firstDate, endDate: thisDates?.lastDate }
                };
                getDetailedCanisterUnlock(payload);
            } else if (selectedViewType.code === 'machinewise') {
                const payload = { id: date, date: selectedDate?.date };
                getDetailedCanisterUnlock(payload);
            }else if(selectedViewType.code === 'accountWise'){

                const accountArr = allAccountsData.filter((x)=>{ return x.id === date })
                if(accountArr?.length > 0){
                    console.log(accountArr[0]);
                    const payload = { userId: accountArr[0]?.acc_id, date: selectedDate?.date };
                    getDetailedCanisterUnlock(payload);
                }
            }
        }
    };

    const getTwoDates = (date) =>{
        const darr = date?.split("-");
        const first = new Date(parseInt(darr[2]), parseInt(darr[1]) - 1, parseInt(darr[0]));
        const firstDate = first.toISOString();
        const newLast = new Date(parseInt(darr[2]), parseInt(darr[1]) - 1, parseInt(darr[0])).setHours(23, 59, 59, 0);
        const lastDate = new Date(newLast).toISOString();
        return { firstDate , lastDate };
    }

    const getDetailedCanisterUnlock = async(data) =>{
        setLevelLoading(true)   
        const response = await apiServices.getCanUnlockdata(data);
        const filData = response?.data?.data?.map((x)=>{
                const data = usersData.filter((y)=>{ return y.userid == x.can_unlock_userid });
                const custData = usersData.filter((y)=>{ return y.userid == x.can_unlock_customerId });
                if(data.length > 0 || custData.length > 0){
                    return {
                        ...x, 
                        userName : data.length > 0 ? `${data[0]?.name} ${data[0]?.lastName}` : 'NA',
                        userCno : data[0]?.cno || 'NA',
                        customer : custData[0]?.billing_details?.company,
                        subscription_type : x.subscription_type === 'demo' ? 'Demo' : "Commercial"
                    }
                }else{
                    return {...x, subscription_type : x.subscription_type === 'demo' ? 'Demo' : "Commercial"};
                }
        })  
        console.log(filData);
        setCanUnlockMasterData(filData)
        setCanisterUnlockData(filData)
        setLevelLoading(false)
    }

    const getDetailedBarcode = async(data) =>{
        setLevelLoading(true)
        console.log(data);
        const response = await apiServices.detailedBarcodeList(data);
        const filData = response?.data?.data?.map((x)=>{ return {...x, site : x.site === 'demo' ? 'Demo' : 'Commercial'}})
        setBarcodeDetails(filData)
        setLevelLoading(false)
    }

    const getDetailedLevelData = async(data) =>{
        setLevelLoading(true)
        const response = await apiServices.getDetailedLevel(data);
        const filData = response?.data?.data?.map((x)=>{
            const data = usersData.filter((y)=>{ return y.userid == x.calibrate_userid });
            if(data.length > 0){
                return {
                    ...x, 
                    userName : `${data[0].name} ${data[0].lastName}`,
                    subscription_type : x.subscription_type === 'demo' ? 'Demo' : 'Commercial'
                }
            }else{
                return {...x,subscription_type : x.subscription_type === 'demo' ? 'Demo' : 'Commercial'};
            }
        })

        console.log(filData);
        setLevelData(filData);
        setLevelLoading(false)
    }

    const changeMachine = (e) => {
        console.log(e.value);
        setSelectedMch(e.value);
        const isMchWise = selectedViewType.code === 'machinewise' ? true : false;
        const baseData = { machineWise: isMchWise };
        const isAccWise = selectedViewType.code === 'accountWise' ? true : false;
        const baseAccData = { accountWise: isAccWise };
        const selectedId = e.value?.id;
        // const selectedId = e.value?.name;

        if(selectedViewType.code === 'accountWise'){
            if (selectedDate) {
                getData({ id: selectedId, ...selectedDate, ...baseAccData });
            } else {
                getData({ id: selectedId, ...baseAccData });
            }
        }else if(selectedViewType.code !== 'accountWise'){
            if (selectedDate) {
                getData({ id: selectedId, ...selectedDate, ...baseData });
            } else {
                getData({ id: selectedId, ...baseData });
            }
        }
    }

    const changeDuration = (e) => {
        e.value.code !== 'custom' && setSelectedDuration(e.value)
        const { startToday, endToday, YestStart, EndStart, weekstart, weekEnd, monthStart, endThisMonth, lastStart, lastend } = datesData;

        const today = { startDate: startToday, endDate: endToday };
        const yesterday = { startDate: YestStart, endDate: EndStart };
        const thisWeek = { startDate: weekstart, endDate: weekEnd };
        const thisMonth = { startDate: monthStart, endDate: endThisMonth };
        const lastMonth = { startDate: lastStart, endDate: lastend };

        const isMchWise = selectedViewType.code === 'machinewise' ? true : false;
        const isAccWise = selectedViewType.code === 'accountWise' ? true : false;

        switch (e.value.code) {
            case 'today':
                setDateAndGetData(today);
                break;
            case 'yesterday':
                setDateAndGetData(yesterday);
                break;
            case 'this_week':
                setDateAndGetData(thisWeek);
                break;
            case 'this_month':
                setDateAndGetData(thisMonth);
                break;
            case 'last_month':
                setDateAndGetData(lastMonth);
                break;
            case 'custom':
                setCustomFilterDialog(true);
                break;
            default:
                setSelectedDate(null);
                if (selectedMch?.displayName === 'All') {
                    getData({ machineWise: isMchWise });
                } else {
                    getData({ id: selectedMch.id, machineWise: isMchWise });
                }
                break;
        }
    }

    const setDateAndGetData = (date) => {
        setSelectedDate({ date });

        const isMchWise = selectedViewType.code === 'machinewise' ? true : false;
        const isAccWise = selectedViewType.code === 'accountWise' ? true : false;

        if(selectedViewType.code === 'accountWise'){
            if (selectedMch?.displayName === 'All') {
                getData({ date, accountWise: isAccWise });
            } else {
                getData({ id: selectedMch.id, date, accountWise: isAccWise });
            }
        }else{
            if (selectedMch?.displayName === 'All') {
                getData({ date, machineWise: isMchWise });
            } else {
                getData({ id: selectedMch.id, date, machineWise: isMchWise });
            }
        }
    };

    const handleSubmit = async(e) =>{
        setCustomFilterDialog(false)
        e.preventDefault()

		const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
		const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
		const cust_start = new Date(startt).toISOString();
		const cust_end = new Date(endd).toISOString();
		const date = { startDate: cust_start, endDate:cust_end };
		setSelectedDate(date)

        console.log(date);
        setDateAndGetData(date)
        setSelectedDuration(null)
    }

    const hideCustomDialog = () =>{
        setCustomFilterDialog(false);
		setCustomStart('')
		setCustomEnd('')
    }

    const customFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
            <button className="p-button p-button-success" type="submit" form="custom-form" ><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
        </>
    )

    const changeViewType = (e) =>{
        setSelectedViewType(e.value)
        console.log(e.value);
        if(e.value.code === 'machinewise'){
            getData({ id : selectedMch?.id, date : selectedDate?.date, machineWise : true });
        }else if(e.value.code === 'daywise'){
            getData({  id : selectedMch?.id, date : selectedDate?.date });
        }else if(e.value.code === 'accountWise'){
            getData({ id : selectedMch?.id, date : selectedDate?.date, accountWise : true });
        }
    }

    const changeSubscriptionForUnlock = (e) =>{
        setSelectedSubType(e.value)
        if(e.value.code === 'all'){
            setCanisterUnlockData(canUnlockMasterData)
        }else if(e.value.code === 'demo' || e.value.code === 'permanent'){
            const filData = canUnlockMasterData?.filter((x)=>{ return x.subscription_type === e.value.code })
            setCanisterUnlockData(filData)
        }
    }

    const exportCSV = () => { exportReport(dt) }
    const exportCSVMaster = () =>{ exportReport(dataReport) }

    const hideDetailed = () =>{ 
        setLevelDataDialog(false) 
        setBarcodeFlag(false)
        setSelectedSubType({name:'All', code:'all'})
        setGlobalFilter('')
    }

    const hideCanLockDetailed = () =>{ 
        setCanLockDialog(false) 
        setSelectedSubType({name:'All', code:'all'})
        setGlobalFilter('')
    }

    const hideClicksDetailed = () =>{ setClicksDialog(false)}

    const levelFooter = commonCloseDialogFooter('Close', hideDetailed);
    const canLockFooter = commonCloseDialogFooter('Close', hideCanLockDetailed);
    const clicksFooter = commonCloseDialogFooter('Close', hideClicksDetailed);
    
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    const stackedData = {
        labels: labelDates,
        datasets: [
            {
                type: 'bar',
                label: 'Standard Level Calibration',
                backgroundColor: '#E8C10B',
                data: standardChart,
                borderRadius: 5
            },
            {
                type: 'bar',
                label: 'Critical Level Calibration',
                backgroundColor: '#e48787',
                data: cricticalChart,
                borderRadius: 5
            },
            {
                type: 'bar',
                label: 'Barcode Used',
                backgroundColor: '#079558',
                data: barcodeChart,
                borderRadius: 5
            },
            {
                type: 'bar',
                label: 'Canister Unlock',
                backgroundColor: '#F03A2E',
                data: canUnlockSet,
                borderRadius: 5
            },
            {
                type: 'bar',
                label: 'Consumption Clicks',
                backgroundColor: '#0088c6',
                data: consumClicksChart,
                borderRadius: 5
            }
        ]
    };

    const stackedOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        },
        onClick: function (e, clickedElement){
            getRequiredData(clickedElement)
        }
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="CVES" />
                    <Dropdown id='select-mc' value={selectedMch} onChange={changeMachine} options={machineOptions} optionLabel="displayName" placeholder="Select CVES" filter className="p-mr-2 multiselect-custom " />
                </div>

                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="Duration" />
                    <Dropdown id='select-duration' value={selectedDuration} onChange={changeDuration} options={durationOptions} optionLabel="name" placeholder="Custom" filter className="p-mr-2 multiselect-custom" />
                </div>

                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-eye" />
                    <Dropdown id='select-duration' value={selectedViewType} onChange={changeViewType} options={viewOption} optionLabel="name" placeholder="Select Type" filter className="p-mr-2 multiselect-custom" />
                </div>
                <div className="p-inputgroup">
                    <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mx-2' onClick={exportCSVMaster}/>
                </div>

            </>
        )
    }

    const leftToolbarTemplate = () =>{
        return (
            <>

                <div className='p-grid p-mt-1' style={{display : 'flex'}}>
                    <div className='p-col-sm-4'>
                        <Chip label={`Total Standard Calibration : ${statData?.totalStandard || '0'}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                    </div>
                    <div className='p-col-sm-4'>
                        <Chip label={`Total Critical Calibration : ${statData?.totalCritical || '0'}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                    </div>
                    <div className='p-col-sm-4'>
                        <Chip label={`Total Barcode Used : ${statData?.totalBarcode || '0'}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                    </div>
                    <div className='p-col-sm-4'>
                        <Chip label={`Total Canister Unlocks : ${statData?.totalUnlocks || '0'}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                    </div>
                    <div className='p-col-sm-4'>
                        <Chip label={`Total Consumption Clicks : ${statData?.totalClicks || '0'}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
                    </div>
                </div>
            </>
        )
    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                {/* <div className="p-inputgroup" style={{width:'150px'}}>
                    <Button className='custom-group-btn' label='Type' />
                    <Dropdown id='select-sub' value={selectedSubType} onChange={changeSubscription} options={subscriptionOptions} optionLabel="name" placeholder="Select Subscription Type" filter className="p-mr-2 multiselect-custom " />
                </div> */}
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV}/>
                </div>
            </span>
        </div>
    )

    const header1 = (
        <div className="table-header">
            <span className="p-input-icon-right">
                {/* <div className="p-inputgroup" style={{width:'150px'}}>
                    <Button className='custom-group-btn' label='Type' />
                    <Dropdown id='select-sub' value={selectedSubType} onChange={changeSubscriptionForUnlock} options={subscriptionOptions} optionLabel="name" placeholder="Select Subscription Type" filter className="p-mr-2 multiselect-custom " />
                </div> */}
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV}/>
                </div>
            </span>
        </div>
    )

    return (
        <>
            <div className='card'>
                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            </div>
            {
                loading ? 
                <div className='p-mt-5'>
                    <center> <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i> </center>
                </div>
                :
                <div className='card'>
                    <Chart type="line" data={stackedData} options={stackedOptions} height='550' />
                </div>
            }

            <DataTable ref={dataReport} value={dataForReport} dataKey="id" exportFilename={`Audit System Report`} style={{display : 'none'}} >
                <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                <Column field="id" header="Date" body={CommonBodyForTable} sortable ></Column>
                <Column field="barcodeCount" header="Barcode Count" body={CommonBodyForTable} sortable ></Column>
                <Column field="stdCalib" header="Standard Calibration" body={CommonBodyForTable} sortable ></Column>
                <Column field="criticalCalib" header="Critical Calibration" body={CommonBodyForTable} sortable ></Column>
                <Column field="canUnlock" header="Canister Unlock" body={CommonBodyForTable} sortable ></Column>
                <Column field="clickCount" header="Click Count" body={CommonBodyForTable} sortable ></Column>
            </DataTable>

            <Dialog className="p-fluid" header={` ${barcodeFlag ? 'Used Barcode Details' : 'Level Calibration'} (${selectedBar})`} visible={levelDataDialog} style={{ width: '1150px' }} footer={levelFooter} onHide={hideDetailed} maximizable={true} blockScroll={true}>

                <div className="p-grid crud-demo">
                <div className="p-col-12">

                    {
                        barcodeFlag ? 

                        <div className="card">
                            <DataTable ref={dt}  value={barcodeDetails}
                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                                emptyMessage="No data found." exportFilename={`Used Barcode ${selectedBar}`} globalFilter={globalFilter} header={header} loading={levelLoading} >
                                <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                                <Column field="machineId" header="CVES Id" body={CommonBodyForTable} sortable ></Column>
                                <Column field="subid" header="Sub Id" body={CommonBodyForTable} sortable ></Column>
                                <Column field="site" header="Subscription Type" body={CommonBodyForTable} sortable ></Column>
                                <Column field="barcode" header="Barcode" body={CommonBodyForTable} sortable ></Column>
                                <Column field="itemName" header="Material Name" body={CommonBodyForTable} sortable ></Column>
                                <Column field="weight" header="Weight (Gms)" body={CommonBodyForTable} sortable ></Column>
                                <Column field="stage" header="Stage" body={CommonBodyForFormattedStrTable} sortable ></Column>
                            </DataTable>
                        </div>

                        :

                        <div className="card">
                            <DataTable ref={dt} value={levelData}
                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                                emptyMessage="No data found." exportFilename={`Level Calibration ${selectedBar}`} globalFilter={globalFilter} header={header} loading={levelLoading} >
                                <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                                <Column field="id" header="CVES Id" body={CommonBodyForTable} sortable ></Column>
                                <Column field="subid" header="Sub Id" body={CommonBodyForTable} sortable ></Column>
                                <Column field="subscription_type" header="Subscription Type" body={CommonBodyForTable} sortable ></Column>
                                <Column field="can_id" header="Canister Id" body={CommonBodyForTable} sortable ></Column>
                                <Column field="calibrate_type" header="Calibration Type" body={statusBodyTemplate} sortable ></Column>
                                <Column field="calibrate_mode" header="Calibration Mode" body={CommonBodyForFormattedStrTable} sortable ></Column>
                                <Column field="userName" header="Calibrated By" body={CommonBodyForTable} sortable ></Column>
                                <Column field="createdAt" header="Date & Time" body={DateBodyForTable} sortable ></Column>
                            </DataTable>
                        </div>
                    }

                </div>
                </div>
            </Dialog>

            <Dialog className="p-fluid" header={`Canister Unlock Details (${selectedBar})`} visible={canLockDialog} style={{ width: '1550px' }} footer={canLockFooter} onHide={hideCanLockDetailed} maximizable={true} blockScroll={true}>

                <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <DataTable ref={dt} value={canisterUnlockData}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                            emptyMessage="No data found." exportFilename={`Canister Unlock Details ${selectedBar}`} globalFilter={globalFilter} header={header1} loading={levelLoading} >
                            <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '4rem' }} exportable={false} ></Column>
                            <Column field="customer" header="Account Name" body={CommonBodyForTable} ></Column>
                            <Column field="id" header="CVES Id" body={CommonBodyForTable} sortable ></Column>
                            <Column field="subid" header="Sub Id" body={CommonBodyForTable} sortable ></Column>
                            <Column field="subscription_type" header="Subscription Type" body={CommonBodyForTable} sortable ></Column>
                            <Column field="can_id" header="Canister Id" body={CommonBodyForTable} sortable ></Column>
                            <Column field="can_unlock_mode" header="Unlocked From" body={CommonBodyForFormattedStrTable} ></Column>
                            <Column field="userName" header="Unlocked By" body={CommonBodyForTable} ></Column>
                            <Column field="userCno" header="Contact No." body={CommonBodyForTable} ></Column>
                            <Column field="can_unlock_category" header="Category" body={CommonBodyForTable} ></Column>
                            <Column field="can_unlock_comment" header="Comment" body={CommonBodyForTable} ></Column>
                            <Column field="createdAt" header="Date & Time" body={DateBodyForTable} sortable ></Column>
                        </DataTable>
                    </div>
                </div>
                </div>
            </Dialog>

            <Dialog className="p-fluid" header={`Consumption Clicks Details (${selectedBar})`} visible={clicksDialog} style={{ width: '1150px' }} footer={clicksFooter} onHide={hideClicksDetailed} maximizable={true} blockScroll={true}>
                <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <DataTable ref={dt} value={clicksMasterData}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                            emptyMessage="No data found." exportFilename={`Consumption Clicks Details ${selectedBar}`} globalFilter={globalFilter} header={header1} loading={levelLoading} >
                            <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                            <Column field="customer" header="Account Name" body={CommonBodyForTable}></Column>
                            <Column field="id" header="CVES Id" body={CommonBodyForTable} sortable ></Column>
                            <Column field="subid" header="Sub Id" body={CommonBodyForTable} sortable ></Column>
                            <Column field="subscription_type" header="Subscription Type" body={CommonBodyForTable} sortable ></Column>
                            <Column field="can_id" header="Canister Id" body={CommonBodyForTable} sortable ></Column>
                            <Column field="userName" header="Clicked By" body={CommonBodyForTable} ></Column>
                            <Column field="consumption_click_mode" header="Clicks From" body={CommonBodyForFormattedStrTable}></Column>
                            <Column field="consumption_click_count" header="Click Count" body={CommonBodyForTable} sortable ></Column>
                            <Column field="consumption_click_comment" header="Comment" body={CommonBodyForTable} ></Column>
                            <Column field="createdAt" header="Date & Time" body={DateBodyForTable} sortable ></Column>
                        </DataTable>
                    </div>
                </div>
                </div>
            </Dialog>


            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter" footer={customFooter}  onHide={hideCustomDialog} modal className="p-fluid" maximizable={true} blockScroll={true}>
                <form onSubmit={handleSubmit} id="custom-form" className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From </label>
                            <InputText id="from" type="date" name='start_date'  value={customStart} onChange={(e) => {setCustomStart(e.target.value) } } required autoFocus/>
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' min={customStart} value={customEnd} onChange={(e) => {setCustomEnd(e.target.value)}} required/>
                        </div>
                    </div>
                </form>
            </Dialog>

        </>

    )
}
