import React, {useState, useEffect, useRef} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Editor } from "primereact/editor";
import { Toast } from 'primereact/toast';
import { CommonBodyForTable } from '../Common/CommonComponents';
import { successToast, failureToast } from '../Common/CommonFunctions';

import ApiServices from '../../service/api/ApiServices';
const apiServices = new ApiServices();

// ================================================================================
// ================================================================================

export const FaqDetails = () => {

    const [loading, setLoading] = useState(true);
	const [faqData, setFaqData] = useState(null);
    const [addFaqDialog, setAddFaqDialog] = useState(false)
    const [answerText, setAnswerText] = useState(null)
    const [payload, setPayload] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [selectedFaq, setSelectedFaq] = useState(null)
    const [comfirmDelete, setComfirmDelete] = useState(false)    
    
    const toast = useRef(null);

    useEffect(() => {
        getData({})
    }, [])

    const getData = async(data) =>{
        const response = await apiServices.getFaq(data);
        console.log(response?.data?.data);
        const orderedData =  response?.data?.data.sort((a,b)=> (a.index > b.index ? 1 : -1))
        setFaqData(orderedData);
        setLoading(false)
    }

    const openFaqDialog = () =>{
        setAddFaqDialog(true)
        setIsEdit(false)
        setAnswerText(null)
        setSelectedFaq(null)
    }

    const changeFaq = (e) =>{
        if(e.target?.name == 'link' || e.target?.name == 'question'){
            setPayload({...payload, [e.target.name] : e.target.value})
        }else if(e.target?.name == 'index'){
            setPayload({...payload, [e.target.name] : Number(e.target.value)})
        }else{
            setTimeout(() => { setAnswerText(e.htmlValue) }, 1000);
        }
    }

    const submitFaq = async(e) =>{
        e.preventDefault(); 
        const data = {...payload, 'answer': answerText }

        if(isEdit){
            const response = await apiServices.updateFaq({_id: selectedFaq._id,...data});
            if(response?.data?.success){
                successToast(toast, 'FAQ Updated Successfully')
                hideDialog()
            }else{
                failureToast(toast, 'Failed to Update')                
            }
        }else{
            const response = await apiServices.addFaq(data);
            console.log(response?.data);
            if(response?.data?.response_code == 1){
                successToast(toast,'FAQ Added Successfully')
                hideDialog()
            }else{
                failureToast(toast, 'Failed to add')
            }
        }
    }

    const hideDialog = () =>{
        setAddFaqDialog(false)
        setAnswerText(null)
        setPayload({})
        setIsEdit(false)
        setSelectedFaq(null)
        setComfirmDelete(false)
        getData({})
    }

    const openEdit = (data) =>{
        console.log(data);
        setAddFaqDialog(true)
        setIsEdit(true)
        setSelectedFaq(data)
        setAnswerText(data?.answer)
        setPayload({ question : data?.question})
    }

    const comfirmDeleteFaq = (data) =>{
        setSelectedFaq(data)
        setComfirmDelete(true)
    }

    const deleteFaq = async() =>{
        const response = await apiServices.deleteFaq({_id : selectedFaq._id});
        console.log(response);
        if(response?.data?.success){
            successToast(toast,'FAQ Deleted Successfully')
            hideDialog()
        }else{
            failureToast(toast,'Failed to Delete')
        }
    }

    const htmlForTable = (data, props) =>(
        <> 
            <span className="p-column-title">{props.header}</span>
            <div dangerouslySetInnerHTML={{__html: data[props.field]}} />
        </> 
    )

    const actionBodyTemplate = (rowData) =>(
        <>
            <span className="p-column-title">Action</span>
            <Button icon="pi pi-pencil" title="Edit" className="p-button-rounded p-button-success p-mr-2" onClick={()=>{openEdit(rowData)}}/>
            <Button icon="pi pi-trash" title="Delete" className="p-button-rounded p-button-danger" onClick={()=>{comfirmDeleteFaq(rowData)}}/>
        </>
    )

    const rightToolbarTemplate = () =>( <Button className='p-custom-btn' label="ADD FAQ" icon="pi pi-plus-circle" iconPos="left" onClick={()=>{openFaqDialog()}}/> )
    
    const addfaqDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger" onClick={()=>{setAddFaqDialog(false)}} />
            { answerText && <Button label="Submit" type="submit" form='add-agreement' icon="pi pi-plus" className="p-button-success"/> } 
        </>
    );

    const deleteFaqDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-success p-mr-2 p-mb-2" onClick={()=>{setComfirmDelete(false)}}  />
            <Button label="Delete" icon="pi pi-trash" className="p-button-danger p-mr-2 p-mb-2" onClick={()=>{deleteFaq()}}/>
        </>
    );

    return (
        <>  
                <Toast ref={toast} position='center' />

                <div className="p-grid crud-demo">
                    <div className="p-col-12">
                        <div className="card">
                            <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar>
                            <DataTable value={faqData}
                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} FAQ's" emptyMessage="No data found." loading={loading}>
                                {/* <Column field="srno" header="Sr.No." style={{width:'8rem'}} sortable body={srBodyTemplate}></Column> */}
                                <Column field="index" header="Order Index" style={{width:'10rem'}} sortable body={CommonBodyForTable}></Column>
                                {/* <Column field="link" header="Link" sortable body={CommonBodyForTable}></Column> */}
                                <Column field="question" header="Question" style={{width:'25%'}} sortable body={CommonBodyForTable}></Column>
                                <Column field="answer" header="Answer" style={{width:'50%'}} sortable body={htmlForTable}></Column>
                                <Column header="Action" sortable body={actionBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

                <Dialog visible={addFaqDialog} style={{ width: '950px' }} header={`${isEdit ? 'Edit' : 'Add'} FAQ`} modal  footer={addfaqDialogFooter} onHide={()=>{hideDialog()}} maximizable={true} blockScroll={true}>
                    <form onSubmit={submitFaq} id="add-agreement" className="p-fluid">
                        <div className='p-grid'>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label>Question</label>
                                <InputText type="text" name='question' placeholder='Question' onChange={changeFaq} defaultValue={isEdit ? selectedFaq?.question : ''} autoFocus required autoComplete='off'/>
                            </div>
                        </div>
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label>Order Index</label>
                                <InputText type="number" name='index' placeholder='Order Index' min={1} onChange={changeFaq} defaultValue={isEdit ? selectedFaq?.index : ''} required autoComplete='off'/>
                            </div>
                        </div>
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label>Link</label>
                                <InputText name='link' placeholder='Link' onChange={changeFaq} defaultValue={isEdit ? selectedFaq?.link : ''} autoComplete='off'/>
                            </div>
                        </div>
                        <div className='p-col-12'>
                            {/* <Editor value={answerText} onTextChange={(e) => setAnswerText(e.htmlValue)} headerTemplate={header} style={{ height: '320px' }} required/> */}
                            <Editor name="editor" value={answerText} onTextChange={changeFaq} style={{ height: '320px' }} />
                        </div>
                        </div>
                    </form>
                </Dialog>

                <Dialog visible={comfirmDelete} style={{ width: '450px' }} header="Confirm" modal footer={deleteFaqDialogFooter} onHide={()=>{setComfirmDelete(false)}}>
                    <div className="confirmation-content">
                        <center>
                            <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br/>
                            <span className='p-mt-2'><b>Are you sure you want to delete the selected FAQ?</b></span>
                        </center>
                    </div>
                </Dialog>
        </>
  )
}
