import React,{ useState, useEffect} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import classNames from 'classnames';
import { srBodyTemplate, CommonBodyForTable, DateBodyForTable, amountBodyForTable } from "../Common/CommonComponents";
import { commonCloseDialogFooter } from "../Common/CommonComponents";
import { convertDateFn, getFormattedString } from "../Common/CommonFunctions";
import { dateBodyForTable } from "../Users/ActiveUsers";

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ==========================================================================================
// ==========================================================================================

export const OngoingOrders = (props) =>{

    const [loading, setLoading] = useState(true);
    const [ongoingOrder, setOngoingOrder] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState([]);
    const [detailedOrderDialog, setDetailedOrderDialog] = useState(false);
    const [allMaterials, setAllMaterials] = useState(null);
    
    useEffect(()=>{
        getData(props.ongoingOrders)
    },[props.ongoingOrders])

    const getData = (data) =>{
        setLoading(true)
        if(data){
            setOngoingOrder(data)
            setLoading(false)
        }else{
            setLoading(false)
        }
    }

    const openOrderDetailed = (data) =>{
        console.log(data);
        getMat(data)
        setSelectedOrder(data)
        setDetailedOrderDialog(true)        
    }
    
    const getMat = (data) =>{
        const res =  data?.combos?.map((x)=>{
            const data = props.allMaterials.filter((y)=>{ return x.id == y._id})
            if(data?.length > 0){
                return {...x, materialName : data[0]?.title, image : data[0]?.image }
            }else{
                return {...x, materialName : 'NA', image : ''}
            }
        })
        setAllMaterials(res)
    }
    
    const contactBodyTemplate = (rowData, props) => ( <><span className="p-column-title">{props.header}</span>{rowData?.user[props.field] ? rowData?.user[props.field] : '-' } </> )

    const statusBodyTemplate = (rowData, props) => {
        const status = rowData[props.field]?.replaceAll("_", " ")?.replace('ORDER',' ');
        return ( <> <span className="p-column-title">{props.header}</span>{status === 'COD' ? status : getFormattedString(status) }</>)
    }

    const priceBodyTemplate = (rowData, props) => ( <><span className="p-column-title">{props.header}</span>{Number(rowData[props.field])?.toFixed(2)} </> )

    const orderActionTemplate = (rowData) =>(
        <>
            <span className="p-column-title">Action</span>
            <div className="actions" style={{display:"flex"}}>
                <Button icon="pi pi-eye" title='View Order Details' className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={()=>{openOrderDetailed(rowData)}}  />
            </div>
        </>
    )

    return(
        <>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <DataTable  value={ongoingOrder}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data" emptyMessage="No data found." loading={loading}>
                            <Column field="srno" header="Sr.No." sortable body={srBodyTemplate} style={{width:'8rem'}} ></Column>
                            { initialValue?.userType != 'customer' && <Column field="company_name" header="Account Name" sortable body={CommonBodyForTable}></Column> }
                            { initialValue?.userType != 'customer' && <Column field="contact" header="Contact Number" sortable body={contactBodyTemplate}></Column> }
                            <Column field="order_no" header="Order Id" sortable body={CommonBodyForTable} style={{ overflowWrap:'break-word' }} ></Column>
                            <Column field="payment_mode" header="Payment Mode" sortable body={statusBodyTemplate} ></Column>
                            {/* <Column field="payment_status" header="Payment Status" sortable body={CommonBodyForTable} ></Column> */}
                            <Column field="status" header="Order Status" sortable body={statusBodyTemplate}></Column>
                            <Column field="total_price" header="Total Amount" sortable body={amountBodyForTable}></Column>
                            <Column field="createdAt" header="Created Date & Time" sortable body={DateBodyForTable}></Column>
                            {/* <Column field="payment_status" header="Payment Status" sortable body={CommonBodyForTable}></Column> */}
                            <Column header="View Details" body={orderActionTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            <DetailedOrder 
                detailedOrderDialog={detailedOrderDialog} 
                setDetailedOrderDialog={setDetailedOrderDialog} 
                selectedOrder={selectedOrder} 
                allMaterials={allMaterials} 
                type='cves'
            />

        </>
    )
}


export const CommonForDetails = props =>{
    return (
        <li className="person-item p-p-0">
            <div className="person-info">
                <div className="amount">{props.name}</div>
                <div className="name">{props.value}</div>
            </div>
        </li>
    )
}

export const imageBodyTemplate = (rowData) => {
    return <img src={`${rowData?.image}`} onError={(e) => e.target.src='assets/demo/images/user/default-packet.png'} alt={rowData?.image} className="product-image p-p-2" />;
}


export const DetailedOrder = (props) =>{

    const [selectedOrder, setSelectedOrder] = useState(null)
    const [orderTimeStamp, setOrderTimeStamp] = useState(null)

    useEffect(() => {
        getTrackingDetails(props.selectedOrder)
    }, [props.selectedOrder])

    const detailedOrderDialog = props.detailedOrderDialog;
    const allMaterials = props.allMaterials;

    const hideDetailedDialog = () =>{ props.setDetailedOrderDialog(false)}

    const detailedOrderFooter = commonCloseDialogFooter('Cancel',hideDetailedDialog);

    const getTrackingDetails = (data) =>{
        if(data){
            console.log(data);
            setSelectedOrder(data)
            const timestamp = data.timestamps;
            const arr = [];
            for (const key in timestamp) {

                if(key == 'received'){
                    const ob = {
                        index:1, 
                        status:[key],
                        date: timestamp[key],
                        description:'Your order for machine was placed successfully.',
                        title:'Order Placed', 
                        icon: timestamp[key] ? 'pi pi-check' : 'pi pi-times',
                        color: timestamp[key] ? '#037049': '#c61414' 
                    }
                    arr.push(ob)
                }
                
                if(key == 'agreement'){
                    const ob = {
                        index:2, 
                        status:[key],
                        date: timestamp[key],
                        description:'Your order agreement waas signed successfully.',
                        title:'Order Agreement', 
                        icon: timestamp[key] ? 'pi pi-check' : 'pi pi-times',
                        color: timestamp[key] ? '#037049': '#c61414'
                    };
                    arr.push(ob)
                }
                
                if(key == 'payment_confimed'){
                    const ob = {
                        index:3, 
                        status:[key],
                        date: timestamp[key],
                        description:'Your order payment was updated successfully.',
                        title:'Order Payment', 
                        icon: timestamp[key] ? 'pi pi-check' : 'pi pi-times',
                        color: timestamp[key] ? '#037049': '#c61414'
                    };
                    
                    arr.push(ob)
                }

                if(key == 'confirmed'){
                    
                    const ob = {
                        index:4,
                        status:[key],
                        date: timestamp[key],
                        description:`Your order has been ${props.type == 'cves' ? 'accepted' : 'confirmed'}`,
                        title:`Order ${props.type == 'cves' ? 'Accepted' : 'Confirmed'}`, 
                        icon: timestamp[key] ? 'pi pi-check' : 'pi pi-times',
                        color: timestamp[key] ? '#037049': '#c61414'
                    };

                    arr.push(ob)
                }
                
                if(key == 'dispatched'){
                    const ob = {
                        index:5, 
                        status:[key],
                        date: timestamp[key],
                        description:'Your order has been dispatched.',
                        title:'Order Dispatched', 
                        icon: timestamp[key] ? 'pi pi-check' : 'pi pi-times',
                        color: timestamp[key] ? '#037049': '#c61414'
                    };
                    arr.push(ob)
                }
                
                if(key == 'delivered'){
                    const ob = {
                        index:6, 
                        status:[key],
                        date: timestamp[key],
                        description:`Your machine has been delivered successfully. ${props.type == 'cves' ? 'Our Technoman will visit for installation.' : ''} `,
                        title:'Order Delivered', 
                        icon: timestamp[key] ? 'pi pi-check' : 'pi pi-times',
                        color: timestamp[key] ? '#037049': '#c61414'
                    };
                    arr.push(ob)
                }
                
                if(key == 'activated'){
                    const ob = {
                        index:7,
                        status:[key],
                        date: timestamp[key],
                        description:'Your Cherise Tapri machine has been installed. Tapri Piyo, Swasth Jiyo.',
                        title:'Machine Installed', 
                        icon: timestamp[key] ? 'pi pi-check' : 'pi pi-times',
                        color: timestamp[key] ? '#037049': '#c61414'
                    };
                    arr.push(ob)
                }
                
                if(key == 'cancelled' && timestamp[key]){
                    const ob = {
                        index:8, 
                        status:[key],
                        date: timestamp[key],
                        description:'Your order has been cancelled.',
                        title:'Order Cancelled', 
                        icon: timestamp[key] ? 'pi pi-check' : 'pi pi-times',
                        color: timestamp[key] ? '#037049': '#c61414'
                    };
                    arr.push(ob)
                }
                
                if(key == 'rejected' && timestamp[key]){
                    const ob = {
                        index:9, 
                        status:[key],
                        date: timestamp[key],
                        description:'Your order is rejected',
                        title:'Order Rejected', 
                        icon: timestamp[key] ? 'pi pi-check' : 'pi pi-times',
                        color: timestamp[key] ? '#037049': '#c61414'
                    };
                    arr.push(ob)
                }
            }

            const sortedArr = arr.sort((a,b) => a.index - b.index);
            setOrderTimeStamp(sortedArr);
        }
    }

    const openPI = (data) =>{ data && window.open(data, '_blank')}
    const customizedContent = (item) => ( <Card title={item.title} subTitle={item.date}><p>{item.description}</p> </Card>)
    const customizedMarker = (item) => ( <span className="custom-marker p-shadow-2" style={{ backgroundColor: item.color }}><i className={classNames('marker-icon', item.icon)}></i></span>)

    return (
        <>
              <Dialog visible={detailedOrderDialog} style={{ width: '750px' }} header={`Order Details (${selectedOrder?.order_no})`} modal footer={detailedOrderFooter} onHide={hideDetailedDialog}  maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                <div className="p-col-12">
                    <div className=" widget-performance">
                        <div className="content">
                            <ul>
                            <div className='p-grid'>
                                {
                                    selectedOrder?.company_name && <div className='p-col-4'><CommonForDetails name="Account Name" value={selectedOrder?.company_name}/></div>
                                }
                                <div className='p-col-4'><CommonForDetails name="Contact Number" value={selectedOrder?.user?.contact}/></div>
                                <div className='p-col-4'><CommonForDetails name="Email Id" value={selectedOrder?.user?.email}/></div>
                                <div className='p-col-4'><CommonForDetails name="Order Id" value={selectedOrder?.order_no}/></div>
                                <div className="p-col-4"><CommonForDetails name="Date & Time" value={convertDateFn(selectedOrder?.createdAt)}/></div>
                                <div className="p-col-4"><CommonForDetails name="Order Status" value={getFormattedString(selectedOrder?.status?.replaceAll('_',' '))}/></div>
                                <div className="p-col-4"><CommonForDetails name="Payment Mode" value={getFormattedString(selectedOrder?.payment_mode) }/></div>
                                { selectedOrder?.payment_status &&  <div className="p-col-4"><CommonForDetails name="Payment Status" value={getFormattedString(selectedOrder?.payment_status) || 'NA'}/></div> }
                                <div className="p-col-4"><CommonForDetails name="Coupon" value={selectedOrder?.coupon || 'NA'}/></div>
                                <div className="p-col-4"><CommonForDetails name="Total Amount" value={Number(selectedOrder?.total_price)?.toFixed(2)}/></div>
                                <Divider />
                                <div className="p-col-12"><CommonForDetails name="Shipping Address" value={`${selectedOrder?.address?.address || selectedOrder?.shipping_address?.address}, ${selectedOrder?.address?.state || selectedOrder?.shipping_address?.state}, ${selectedOrder?.address?.city || selectedOrder?.shipping_address?.city} ${selectedOrder?.address?.pincode || selectedOrder?.shipping_address?.pincode }`}/></div>
                                <Divider />
                                <div className="p-col-12"><CommonForDetails name="Billing Address" value={`${selectedOrder?.billing_address?.address}, ${selectedOrder?.billing_address?.state}, ${selectedOrder?.billing_address?.city} ${selectedOrder?.billing_address?.pincode ? selectedOrder?.billing_address?.pincode : '.'}`}/></div>

                            </div>
                            </ul>
                        </div>
                            <TabView>
                                <TabPanel header="Product Details">
                                    <div className="p-grid crud-demo">
                                        <div className="p-col-12">
                                            <div className="card">
                                            <DataTable  value={allMaterials}
                                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" header='List of Items'
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data" emptyMessage="No data found.">
                                                <Column field="srno" header="Sr. No." body={srBodyTemplate} style={{width : '4rem'}}></Column>
                                                {/* <Column field="_id" header="item id" body={CommonBodyForTable}></Column> */}
                                                <Column field="image" header="Image" body={imageBodyTemplate}></Column>
                                                <Column field="materialName" header="Title" body={CommonBodyForTable}></Column>
                                                {/* <Column field="price" header="Price" body={CommonBodyForTable}></Column> */}
                                                <Column field="qty" header="Quantity" body={CommonBodyForTable}></Column>
                                                {/* <Column field="cups_count" header="Cup Count" body={CommonBodyForTable}></Column> */}
                                            </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Order Tracking">

                                    <div className="p-grid timeline-demo">
                                        <div className="p-col-10"> </div>
                                        <div className="p-col-2"> 
                                        {
                                            selectedOrder?.proforma_invoice_created &&
                                            <Button icon="pi pi-eye" label='View PI' className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={()=>{openPI(selectedOrder?.proforma_invoice)}}  />
                                        }
                                        </div>
                                        <div className="p-col-12">
                                            <div className="card">
                                                <Timeline value={orderTimeStamp} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </TabView>
                                
                    </div>
                </div>
                </div>
            </Dialog>
            
        </>
    )
}
