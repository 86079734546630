import React, {useEffect, useState, useRef} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
// Common
import { srBodyTemplate, CommonBodyForTable, statusBodyTemplate, amountBodyForTable, CommonBodyForFormattedStrTable, orderStatusBodyTemplate } from '../Common/CommonComponents';
import { exportReport } from '../Common/CommonFunctions';
import { SubAnalyticalStatForTickets } from '../NewAnalytics.js/SubAnalyticalStat';
// Services
import ApiServices from '../../service/api/ApiServices'
const apiServices = new ApiServices();

// ============================================================================

export const RefillOrderTracker = () => {

    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [refillOrders, setRefillOrders] = useState([]);
    const [orderStats, setOrderStats] = useState();
    const [allCustomers, setAllCustomers] = useState();
    const [selectedAcc, setSelectedAcc] = useState({company : 'All'});

    const [first, setFirst] = useState(0);

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        getOrders({ page : 1 })
        getOrderStats({})
        getCustomers()
    }, [])

    const getOrders = async(data) =>{
        const response = await apiServices.refillOrderTrack({ ...data });
        console.log(response?.data?.response_obj)
        // setRefillOrders(response?.data?.response_obj)
        setRefillOrders([...refillOrders,...response?.data?.response_obj])
        setLoading(false)
    }

    const getOrderStats = async(data) =>{
        // const response = await apiServices.refillOrderStats(data);
        // console.log(response?.data?.response_obj);
        // setOrderStats(response?.data?.response_obj)
    }

    const getCustomers = async() =>{
        const response = await apiServices.getUsersByType({userType: "customer"});
        console.log(response?.data?.data);
        const arr = response?.data?.data?.map((x)=>{ return {...x, company : x.billing_details?.company}})
        const filData = arr?.filter((x)=>{return x.company != null})
        filData?.unshift( {company : 'All'} )
        setAllCustomers(filData)
    }

    const exportCSV = () =>{ exportReport(dt)}

    const changeAcc = (e) =>{
        console.log(e.value);
        setSelectedAcc(e.value)

        if(e.value.company == 'All'){
            getOrders({})
            getOrderStats({})
        }else{
            getOrders({ iotUserId : e.value?.userid })
            getOrderStats({ iotUserId : e.value?.userid })
        }
    }

    const onPageChange = async(e) => {
        console.log(e.rows);
        console.log(e.page);

        const startIndex = e.first;
        setFirst(startIndex);
        if((e.page + 1) % 2 === 0){
            getOrders({ page : e.page + 1})
        }

    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <div className="p-inputgroup p-m-1"></div>
            </span >

            <span className="p-input-icon-left">
                <div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV} />
                </div>
            </span>
        </div>
    );

    const rightToolbarTemplate = () => {
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="Customer" />
                    <Dropdown id='select-access' value={selectedAcc} onChange={changeAcc} options={allCustomers} optionLabel="company" placeholder="Select Type" filter className="p-mr-2 multiselect-custom " />
                </div> 
            </>
        )
    }

    return (
        <>
            <Toast ref={toast} position='center' />
            <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar>

            <div className="p-grid crud-demo">
                
                {/* <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Total Orders" value={orderStats?.TOTAL_ORDERS} loading={loading} />            
                </div>
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Placed Orders" value={orderStats?.ORDER_PLACED} loading={loading}/>
                </div>
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Confirmed Orders" value={orderStats?.ORDER_CONFIRMED} loading={loading} />
                </div>
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Delivered Orders" value={orderStats?.ORDER_DELIVERED} loading={loading}/>
                </div>
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Dispatched Orders" value={orderStats?.ORDER_DISPATCHED} loading={loading}/>
                </div>
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Cancelled Orders" value={orderStats?.ORDER_CANCELLED} loading={loading}/>
                </div>
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Rejected Orders" value={orderStats?.REJECTED} loading={loading}/>
                </div>
                <div className="p-col-3 p-md-3">
                    <SubAnalyticalStatForTickets header="Payment" value={orderStats?.PAYMENT} loading={loading}/>
                </div> */}

                <div className="p-col-12">
                    <div className="card">
                        <DataTable value={refillOrders} ref={dt}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 20]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Orders"
                            globalFilter={globalFilter} emptyMessage="No data found." header={header} loading={loading} first={first} onPage={onPageChange} >

                            <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '6rem' }} exportable={false}></Column>
                            <Column field="order_no" header="Order Id" body={CommonBodyForTable} ></Column>
                            <Column field="company_name" header="Account Name" body={CommonBodyForTable} ></Column>
                            <Column field="payment_mode" header="Payment Mode" body={statusBodyTemplate} ></Column>
                            <Column field="payment_status" header="Payment Status" body={CommonBodyForFormattedStrTable} ></Column>
                            <Column field="total_price" header="Total Amount" body={amountBodyForTable} ></Column>
                            <Column field="total_quantity" header="Total Quantity" body={CommonBodyForTable} ></Column>
                            <Column field="status" header="Order Status" body={orderStatusBodyTemplate} ></Column>
                            <Column field="createdAt" header="Date & Time" body={CommonBodyForTable} ></Column>
                            {/* <Column header="Actions" body={actionBodyTemplate}></Column> */}
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    )
}
