
export const stageOptions = [
    { name : 'All', code : 'all'},
    { name : 'Added', code : 'added'},
    { name : 'Assigned', code : 'assigned'},
    { name : 'Delivered', code : 'delivered'},
    { name : 'Loaded', code : 'loaded'},
    { name : 'Used', code : 'used'}
]

export const addedStageOptions = [
    { name : 'Added', code : 'added'}
]

export const assignedStageOptions = [
    { name : 'Assigned', code : 'assigned'}
]

export const deliveredStageOptions = [
    { name : 'Delivered', code : 'delivered'},
    { name : 'Loaded', code : 'loaded'},
    { name : 'Used', code : 'used'}
]

export const loadedStageOptions = [
    { name : 'Loaded', code : 'loaded'},
    { name : 'Delivered', code : 'delivered'},
    { name : 'Used', code : 'used'}
]

export const usedStageOptions = [
    { name : 'Used', code : 'used'},
    { name : 'Loaded', code : 'loaded'},
    { name : 'Delivered', code : 'delivered'}
]


export const addNewOptions = [
    { label : 'CVES', code : 'ctvm'},
    { label : 'Category', code : 'category'},
    { label : 'Device Type', code : 'deviceType'},
    { label : 'Device Model', code : 'deviceModel'},
]

export const brandOptions = [
    { label : 'Tapri', code : 'TAPRI'},
    { label : 'TKaffe', code : 'TKAFFE'}
]

export const modeOptions = [
    { name : "Display", code : "DISPLAY"},
    { name : "Keypad", code : "KEYPAD"}
]


export const cansOptions = [
    { label : '1', code : '1'},
    { label : '2', code : '2'},
    { label : '3', code : '3'},
    { label : '4', code : '4'}
]

export const addDTypeDefault = {app_cat : '', deviceType : ''}

export const addDModelDefault = {
    app_cat : '',
    deviceType : '',
    deviceModel : '',
    price:{ pricehalf : '', pricefull : ''}
}

export const defaultCTVMTemp = {
    "parameter":{
        "mixingmin1":50,
        "mixingmin2":50,
        "mixingmin3":50,
        "mixingmax1":100,
        "mixingmax2":100,
        "mixingmax3":100,
        "valvemin1":50,
        "valvemin2":50,
        "valvemin3":50,
        "valvemax1":100,
        "valvemax2":100,
        "valvemax3":100,
        "solenoidmin":50,
        "solenoidmax":100,
        "materialmin1":50,
        "materialmin2":50,
        "materialmin3":50,
        "materialmax1":100,
        "materialmax2":100,
        "materialmax3":100,
        "suckingmin":50,
        "suckingmax":100,
        "stripmin1":50,
        "stripmin2":50,
        "stripmin3":50,
        "stripmax1":100,
        "stripmax2":100,
        "stripmax3":100,
        "systemerror":"no",
        "strip1":"ok",
        "strip2":"ok",
        "strip3":"ok"
    },
    "alert":{
        "email":["cherees@gmail.com"],"mobile":["+919975551455"]
    },
    "details":{
        "image":{"contentType":"image/jpeg"}
    }
}

export const barcodeFilterOptions = [
    { label: 'All', code: 'all'},
    { label: 'Added', code: 'added'},
    { label: 'Assigned', code: 'assigned'},
    { label: 'Delivered', code: 'delivered'},
    { label: 'Loaded', code: 'loaded'},
    { label: 'Used', code: 'used'},
]


export const materialNatureOptions = [
    { label: 'Beverages', code: 'beverages'},
    // { label: 'Non-Beverages', code: 'non-beverages'}
]

export const materialBrandOptions = [
    { label: 'TAPRI', code: 'TAPRI'},
    { label: 'TKAFFE', code: 'TKAFFE'}
]

export const waterTypeOptions = [
    { label: 'Hot', code: 'Hot'},
    { label: 'Cold', code: 'Cold'},
    { label: 'Normal', code: 'Normal'}
]

export const materialTypeOption = [
    { label: 'Tea', code: 'Tea'},
    { label: 'Coffee', code: 'Coffee'},
    { label: 'Milk', code: 'Milk'},
    { label: 'Soup', code: 'Soup'},
    { label: 'Other', code: 'Other'}
]

export const defaultTemp = {
    materialNature : "beverages"
}



