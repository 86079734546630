import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { CommonForDetails } from "../CRM/Leads";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from "primereact/multiselect";
import { Badge } from "primereact/badge";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
// Common
import { deliveryModeOptions, stageOptions } from "./Constants";
import { CommonBodyForFormattedStrTable, CommonBodyForTable, commonCloseDialogFooter, commonDialogFooter, commonFormDialogFooter } from "../Common/CommonComponents";
// Services
import { successToast, failureToast } from "../Common/CommonFunctions";
import ApiServices from "../../service/api/ApiServices";

const apiServices = new ApiServices();

// ===============================================================================================
// ===============================================================================================

export const MaterialDelivery = (props) => {

    const [allData, setAllData] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');
    const [globalFilter1, setGlobalFilter1] = useState('');
    const [loading, setLoading] = useState(true);
    const [deliveryDialog, setDeliveryDialog] = useState(false);
    const [selectedDelivery, setSelectedDelivery] = useState(null);
    const [editTemplate, setEditTemplate] = useState(null);
    const [selectedStage, setSelectedStage] = useState({ name: 'All', code: 'all' });
    const [allBarcodes, setAllBarcodes] = useState([])
    const [filteredBarcodes, setFilteredBarcodes] = useState([])
    const [returnDialog, setReturnDialog] = useState(false);
    const [selectedBarcodes, setSelectedBarcodes] = useState(null);
    const [returnTemplate, setReturnTemplate] = useState([])
    const [editDeliveryDialog, setEditDeliveryDialog] = useState(false)
    const [allTechnoman, setAllTechnoman] = useState(null)
    const [selectedTechman, setSelectedTechman] = useState(null)
    const [deleteDeliveryDialog, setDeleteDeliveryDialog] = useState(false)
    const [verificationDialog, setVerificationDialog] = useState(false)
    const [verifyTemp, setVerifyTemp] = useState(null)
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [onDemandCount, setOnDemandCount] = useState(20)
    const [onDemandIndex, setOnDemandIndex] = useState(0)
    const [sourceData, setSourceData] = useState([])
    const [onDemandId, setOnDemandId] = useState(0)
    const [selectedDeliveryMode, setSelectedDeliveryMode] = useState(null)
    const [modeDisabled, setModeDisabled] = useState(false);
    const [returnMaterialBatch, setReturnMaterialBatch] = useState([]);


    const dt = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        getAssignedBatch()
        getTechnoman()
    }, [])

    const getTechnoman = async () => {
        const response = await apiServices.getUsersByType({ d_id: "self", userType: "superman" })
        const data = response?.data?.data.map((x) => { return { ...x, fullName: x.name + " " + x.lastName } })
        setAllTechnoman(data)
    }

    const getAssignedBatch = async () => {
        setLoading(true)
        const response = await apiServices.getAssignedBatch({ count: 20, index: 0 })
        console.log(response?.data?.batch);
        // setAllData(response?.data?.batch)
        setAllData(response?.data?.batch?.slice(first, first + rows));
        setSourceData(response?.data?.batch);
        setTotalRecords(response?.data?.batch?.length);
        setLoading(false)
    }

    const searchDelivery = async (data) => {
        if (data) {
            const response = await apiServices.searchAssignedBatch(data)
            setAllData(response?.data?.data)
            setSourceData(response?.data?.data);
            setTotalRecords(response?.data?.data?.length);
        } else {
            getAssignedBatch()
        }
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const header = (
        <div className="table-header">
            <h5></h5>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => searchDelivery(e.target.value)} placeholder="Search..." />
                    {/* <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." /> */}
                    {/* <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4'  onClick={exportCSV}/> */}
                </div>
            </span>
        </div>
    );

    const header1 = (
        <div className="table-header">
            <h5></h5>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter1(e.target.value)} placeholder="Search..." />
                    <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV} />
                </div>
            </span>
        </div>
    );


    const changeStage = (e) => {
        setSelectedStage(e.target.value)

        if (e.target.value.code == 'all') {
            setFilteredBarcodes(allBarcodes)
        } else {
            const fil = allBarcodes.filter((x) => { return x.stage == e.target.value.code; })
            setFilteredBarcodes(fil)
        }
    }

    const viewDelivery = (data) => {
        console.log("viewDelivery", data.batch_data.barcodes.length);
        setDeliveryDialog(true)
        setSelectedDelivery(data)
        setAllBarcodes(data?.batch_data?.barcodes)
        setFilteredBarcodes(data?.batch_data?.barcodes)
        setSelectedStage({ name: 'All', code: 'all' })
    }

    const returnDelivery = (data) => {
        const deliveryBatch = JSON.parse(JSON.stringify(data));
        console.log("deliveryBatch", deliveryBatch.batch_data.barcodes.length);
        deliveryBatch.batch_data.barcodes = deliveryBatch.batch_data.barcodes.filter(element => element.stage !== 'used').map((value) => {
            return {
                ...value,
                displayName: `${value.barcode} / ${value.materialName} / ${value.stage}`
            }
        });
        setReturnDialog(true)
        setReturnMaterialBatch(deliveryBatch)
        setSelectedBarcodes(null)
        setReturnTemplate([])
    }

    const changeReturn = (e) => {
        if (e.target.name == 'barcodes') {
            setSelectedBarcodes(e.target.value)
            // setReturnTemplate({...returnTemplate,[e.target.name] : e.target.value})
        }

        if (e.target.name == 'comment') {
            setReturnTemplate({ ...returnTemplate, [e.target.name]: e.target.value })
        }
    }

    const returnSubmit = async (e) => {
        e.preventDefault();
        setReturnDialog(false);
        const barcodes = selectedBarcodes.map((x) => { return x.barcode; })
        const data = { ...returnTemplate, barcodes: barcodes }
        const payload = { batch_id: returnMaterialBatch.batch_id, returnData: data }
        // console.log("payload ==>", payload);
        const response = await apiServices.returnMaterial(payload);
        console.log("response", response?.data?.success);
        response?.data?.success ? successToast(toast, 'Material Return Successfully') : failureToast(toast, 'Material Failed to Return')
        getAssignedBatch()
    }


    const editDelivery = (data, isDisabled = false) => {
        console.log(data);
        setModeDisabled(isDisabled)
        setSelectedDelivery(data)
        setEditTemplate(data)
        setEditDeliveryDialog(true)
        const fil = allTechnoman?.filter((x) => { return x.userid == data?.batch_data?.deliveryBy?.userid })
        setSelectedTechman(fil[0])
        setSelectedDeliveryMode(data?.batch_data?.deliveryBy?.name ? deliveryModeOptions[0] : deliveryModeOptions[1])
    }

    const changeEdit = (e) => {

        if (e.target.name == 'mode') {
            setSelectedTechman(null)
            setSelectedDeliveryMode(e.value)
            setEditTemplate({ ...editTemplate, batch_type: e.value.code == 'logistic' ? 'CustomerAssignedBatch' : 'supermanAssignBatch', batch_data: { ...editTemplate?.batch_data, deliveryBy: { mode: e.target.value.code } } })
        }

        if (e.target.name == 'technoman') {

            setSelectedTechman(e.target.value)
            setEditTemplate(
                {
                    ...editTemplate,
                    batch_type: 'supermanAssignBatch',
                    batch_data: {
                        ...editTemplate?.batch_data,
                        deliveryBy: {
                            mode: "superman",
                            name: e.target.value?.name,
                            userid: e.target.value?.userid
                        }
                    }
                }
            )
        }

        if (e.target.name == 'comment') {
            setEditTemplate({ ...editTemplate, batch_data: { ...editTemplate?.batch_data, deliveryBy: { ...editTemplate?.batch_data?.deliveryBy, [e.target.name]: e.target.value } } })
        }
    }

    const editSubmit = async (e) => {
        e.preventDefault()
        setEditDeliveryDialog(false)
        const barcodeData = editTemplate?.batch_data?.barcodes?.map((x) => { return x.barcode; })
        const final = { ...editTemplate, batch_data: { ...editTemplate.batch_data, barcodes: barcodeData } }
        console.log(final);
        const response = await apiServices.updateAssignedBatch(final)
        response?.data?.success ? successToast(toast, 'Material Assigned Updated Successfully') : failureToast(toast, 'Material Failed to Update')
        getAssignedBatch()
    }

    const deleteDelivery = (data) => {
        setSelectedDelivery(data)
        setDeleteDeliveryDialog(true)
    }

    const deleteSubmit = async () => {
        setDeleteDeliveryDialog(false)
        const data = { batch_id: selectedDelivery.batch_id }
        const response = await apiServices.deleteAssignedBatch(data)
        response?.data?.success ? successToast(toast, 'Material Assigned Deleted Successfully') : failureToast(toast, 'Material Failed Delete')
        getAssignedBatch()
    }

    const verifyDelivery = (data) => {
        setVerificationDialog(true)
        setVerifyTemp(null)
        setSelectedDelivery(data)
        const fil = allTechnoman?.filter((x) => { return x.userid == data?.batch_data?.deliveryBy?.userid })
        setSelectedTechman(fil[0])
        const cno = fil[0]?.cno;
        sendOtp(cno)
    }

    const sendOtp = async (data) => {
        const response = await apiServices.sendOtp({ cno: data })
        console.log(response);
    }

    const verifyTemplate = (e) => {

        if (e.target.value.length == 4) {
            const data = { "cno": selectedTechman?.cno, "otp": e.target.value, "logintype": "cno" }
            console.log(data);
            setVerifyTemp(data)
        } else {
            setVerifyTemp(null)
        }
    }

    const verifySubmit = async (e) => {
        e.preventDefault()
        const response = await apiServices.verifyOtp(verifyTemp)
        if (response?.data?.success) {
            setVerificationDialog(false)
            successToast(toast, 'Material Verified Successfully')
            updateMaterial()
            getAssignedBatch()
        } else {
            failureToast(toast, 'Invalid OTP')
        }
    }


    const updateMaterial = async () => {
        const barcodeData = selectedDelivery?.batch_data?.barcodes?.map((x) => { return x.barcode; })
        const final = { ...selectedDelivery, batch_data: { ...selectedDelivery.batch_data, barcodes: barcodeData }, batch_type: "CustomerAssignedBatch" }
        const response = await apiServices.updateAssignedBatch(final)
    }


    const onPageChange = async (event) => {
        console.log("onPageChange", event.rows, event.page);
        setOnDemandId(event.rows * event.page)
        setTimeout(() => {
            const startIndex = event.first;
            const endIndex = event.first + rows;
            setFirst(startIndex);
            setAllData(sourceData.slice(startIndex, endIndex));
        }, 250);

        if ((event.page + 1) % 2 == 0) {

            const data = { count: onDemandCount, index: onDemandIndex + 20 }
            // const response = await apiServices.getMaterialOrders(data)
            const response = await apiServices.getAssignedBatch(data)
            const arr = response?.data?.batch;
            const totalData = [...sourceData, ...arr]
            console.log(totalData);
            setSourceData(totalData);
            setTotalRecords(totalData.length);
            setAllData(totalData.slice(event.first, event.first + rows));
            setOnDemandIndex(onDemandIndex + 20)
        }
    }

    const hideDeliveryDialog = () => { setDeliveryDialog(false) }
    const hideReturnDialog = () => { setReturnDialog(false) }
    const hideEditDelivery = () => { setEditDeliveryDialog(false) }
    const hideDeleteDelDialog = () => { setDeleteDeliveryDialog(false) }
    const hideVerDialog = () => { setVerificationDialog(false) }

    const viewPlacedOrderFooter = commonCloseDialogFooter('Close', hideDeliveryDialog)
    const returnDialogFooter = commonFormDialogFooter('Close', hideReturnDialog, 'Return', 'return-form')
    const editDeliveryDialogFooter = commonFormDialogFooter('Close', hideEditDelivery, 'Update', 'edit-form')
    const delteDialogFooter = commonDialogFooter('Close', hideDeleteDelDialog, 'Yes, Delete it', deleteSubmit)
    const verificationDialogFooter = commonFormDialogFooter('Close', hideVerDialog, 'Verify', 'verify-form')

    const onDemandidTemplate = (rowData, props) => (<><span className="p-column-title">Sr.No.</span>{props.rowIndex + 1 + onDemandId}</>)
    const srBodyTemplate = (rowData, props) => { return (<> <span className="p-column-title">{props.header}</span>  {props.rowIndex + 1} </>) }

    const batchBodyTemplate = (rowData, props) => {

        let value;

        if (props.field == 'assignto') {
            value = rowData?.batch_data[props.field]?.name;
        } else if (props.field == 'deliveryBy') {
            value = rowData?.batch_data[props.field]?.mode == 'superman' ? rowData?.batch_data[props.field]?.name : (rowData?.batch_data[props.field]?.name || rowData?.batch_data[props.field]?.comment);
        } else {
            value = rowData?.batch_data[props.field];
        }

        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {value ? value : 'NA'}
            </>
        )
    }

    const stageBodyTemplate = (rowData, props) => {
        let flag;


        if (!rowData?.batch_data?.verified && rowData?.batch_data?.deliveryBy?.name && rowData?.batch_type == "supermanAssignBatch") {

            flag = 'Not Verified'
        }

        if (!rowData?.batch_data?.verified && rowData?.batch_data?.deliveryBy?.name && rowData?.batch_type == "CustomerAssignedBatch") {

            flag = 'Technoman'
        }

        if (rowData?.batch_data?.verified && rowData?.batch_data?.deliveryBy?.name && rowData?.batch_type == "CustomerAssignedBatch") {

            flag = 'Delivered'
        }

        if (rowData?.batch_data?.verified && rowData?.batch_data?.deliveryBy?.mode == "logistic" && rowData?.batch_type == "CustomerAssignedBatch") {

            flag = 'Delivered'
        }

        if (!rowData?.batch_data?.verified && rowData?.batch_data?.deliveryBy?.mode == "logistic" && rowData?.batch_type == "CustomerAssignedBatch") {

            flag = 'Dispatched'
        }

        if (rowData?.batch_data?.verified && rowData?.batch_data?.deliveryBy?.mode && rowData?.batch_type == "CustomerAssignedBatch") {

            flag = 'Delivered'
        }

        return (
            <>
                {(flag == 'Delivered') && <Badge value="Delivered" severity="success" ></Badge>}
                {(flag == 'Technoman') && <Badge value="Technoman" severity="info" ></Badge>}
                {(flag == 'Not Verified') && <Badge value="Not Verified" severity="danger" ></Badge>}
                {(flag == 'Dispatched') && <Badge value="Dispatched" severity="info" ></Badge>}
            </>
        )
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{ display: "flex" }}>
                    <Button icon="pi pi-eye" title='View Material Delivery Details' className="p-button-rounded p-button-info p-m-1" aria-label="Action" onClick={() => { viewDelivery(rowData) }} />

                    {
                        (
                            (rowData?.batch_data?.verified && rowData?.batch_data?.deliveryBy?.name && rowData?.batch_type == "CustomerAssignedBatch" && rowData?.batch_data?.barcodes?.length > 0) ||
                            (rowData?.batch_data?.verified && rowData?.batch_data?.deliveryBy?.mode && rowData?.batch_type == "CustomerAssignedBatch" && rowData?.batch_data?.barcodes?.length > 0) ||
                            (!rowData?.batch_data?.verified && rowData?.batch_data?.deliveryBy?.name && rowData?.batch_type == "CustomerAssignedBatch" && rowData?.batch_data?.barcodes?.length > 0)
                        ) &&

                        <Button icon="pi pi-backward" title='Return' className="p-button-rounded p-button-warning p-m-1" aria-label="Action" onClick={() => { returnDelivery(rowData) }} />
                    }

                    {
                        (!rowData?.batch_data?.verified && rowData?.batch_data?.deliveryBy?.mode !== "logistic" && rowData?.batch_type == "CustomerAssignedBatch") &&
                        <>
                            {
                                (props.rolePermissions?.mDBatchEdit) &&
                                <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-success p-m-1" aria-label="Action" onClick={() => { editDelivery(rowData, true) }} />
                            }
                        </>
                    }

                    {
                        (!rowData?.batch_data?.verified && rowData?.batch_data?.deliveryBy?.name && rowData?.batch_type == "supermanAssignBatch") &&
                        <>

                            {
                                (props.rolePermissions?.mDBatchEdit) &&
                                <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-success p-m-1" aria-label="Action" onClick={() => { editDelivery(rowData) }} />
                            }

                            {(props.rolePermissions?.mDBatchDelete) && <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-m-1" aria-label="Action" onClick={() => { deleteDelivery(rowData) }} />}

                            {
                                (props.rolePermissions?.mDBatchVerify) &&
                                <Button icon="pi pi-check-circle" title='Verify' className="p-button-rounded p-button-info p-m-1" aria-label="Action" onClick={() => { verifyDelivery(rowData) }} />
                            }

                        </>
                    }

                </div>
            </>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                <div className="p-inputgroup ">
                    <Button className='custom-group-btn' label="STAGE" />
                    <Dropdown value={selectedStage} onChange={changeStage} options={stageOptions} optionLabel="name" placeholder="Select Filter" filter
                        className="p-m-0 multiselect-custom" />
                </div>
            </>
        )
    }

    return (
        <>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} position='center' />

                        <DataTable value={allData}
                            dataKey="id" paginator rows={rows} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilter={globalFilter} emptyMessage="No Data found." header={header} loading={loading} totalRecords={totalRecords} lazy first={first} onPage={onPageChange} >

                            <Column field="srid" header="Sr.No." body={onDemandidTemplate} style={{ width: '4rem' }}></Column>
                            {/* <Column field="orderId" header="Order ID"  body={batchBodyTemplate} style={{ overflowWrap:'break-word',width: '12rem' }}></Column> */}
                            <Column field="SDid" header="Sales Order ID" body={batchBodyTemplate} style={{ overflowWrap: 'break-word', width: '10rem' }}></Column>
                            <Column field="batch_id" header="Batch ID" body={CommonBodyForTable}  ></Column>
                            <Column field="batch_name" header="Batch Name" body={CommonBodyForTable}  ></Column>
                            <Column field="assignto" header="Assigned to" body={batchBodyTemplate} ></Column>
                            <Column field="totalweight" header="Packets (Wt)" body={batchBodyTemplate} ></Column>
                            <Column field="deliveryBy" header="Delivery By" body={batchBodyTemplate} ></Column>
                            <Column field="name" header="Stage" body={stageBodyTemplate} style={{ width: '10rem' }} ></Column>
                            <Column field="batchdate" header="Delivery Date" body={batchBodyTemplate} ></Column>
                            <Column header="Actions" body={actionBodyTemplate} style={{ width: '15rem' }}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            <Dialog visible={deliveryDialog} style={{ width: '750px' }} header={`Material Delivery Details (${selectedDelivery?.batch_id}) `} modal footer={viewPlacedOrderFooter} onHide={() => { setDeliveryDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                    <div className="p-col-12">
                        <div className=" widget-performance">
                            <div className="content">
                                <ul>
                                    <div className='p-grid'>
                                        <CommonForDetails name="Delivery Id" value={selectedDelivery?.batch_id} />
                                        <CommonForDetails name="Delivery Name" value={selectedDelivery?.batch_name} />
                                        <CommonForDetails name="Delivery By" value={selectedDelivery?.batch_data?.deliveryBy?.mode || selectedDelivery?.batch_data?.deliveryBy?.name} />
                                        <CommonForDetails name="Total Weight" value={`${selectedDelivery?.batch_data?.barcodes?.reduce((sum, a) => sum + Number(a.weight), 0)} gms`} />
                                        <CommonForDetails name="Order Id" value={selectedDelivery?.batch_data?.orderId} />
                                        <CommonForDetails name="Assigned by" value={selectedDelivery?.batch_data?.assignby} />
                                        <CommonForDetails name="Assigned to" value={`${selectedDelivery?.batch_data?.assignto?.name} / ${selectedDelivery?.batch_data?.assignto?.userid}`} />
                                    </div>
                                </ul>
                            </div>
                        </div>

                        <div className="p-grid crud-demo">
                            <div className="p-col-12">
                                <div className="card">
                                    <h6>Material Details</h6>
                                    <Toast ref={toast} position='center' />
                                    <Toolbar right={rightToolbarTemplate}></Toolbar>
                                    <DataTable ref={dt} value={filteredBarcodes}
                                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records" exportFilename={`Material Details`}
                                        globalFilter={globalFilter1} emptyMessage="No Data found." header={header1} loading={loading} >
                                        <Column field="srid" header="Sr.No." body={srBodyTemplate} exportable={false} style={{ width: '4rem' }}></Column>
                                        <Column field="materialName" header="Material Name" body={CommonBodyForFormattedStrTable} style={{ overflowWrap: 'break-word', width: '12rem' }}></Column>
                                        <Column field="barcode" header="Barcode" body={CommonBodyForTable}></Column>
                                        <Column field="weight" header="Weight (Gms)" body={CommonBodyForTable} ></Column>
                                        <Column field="stage" header="Stage" body={CommonBodyForFormattedStrTable}  ></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Dialog>

            <Dialog visible={returnDialog} style={{ width: '650px' }} header={`Return Material`} modal footer={returnDialogFooter} onHide={() => { setReturnDialog(false) }} blockScroll={true}>
                <div className="p-fluid">
                    <form onSubmit={returnSubmit} id="return-form">
                        <div className='p-grid'>
                            <div className="p-col-12">
                                <div className=" widget-performance">
                                    <div className="content">
                                        <ul>
                                            <div className='p-grid'>
                                                <CommonForDetails name="Delivery Id" value={returnMaterialBatch?.batch_id} />
                                                <CommonForDetails name="Delivery Name" value={returnMaterialBatch?.batch_name} />
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='p-col-12'>
                                <div className="p-field">
                                    <label htmlFor="userId">Comment</label>
                                    <InputTextarea rows={3} cols={30} name='comment' onChange={changeReturn} autoResize />
                                </div>
                            </div>
                            <div className='p-col-12'>
                                <div className="p-field">
                                    <label htmlFor="barcode">Select Barcodes</label>
                                    <MultiSelect className="multiselect-custom" id="barcode" name="barcodes" optionLabel="displayName" placeholder="Select Barcodes" options={returnMaterialBatch?.batch_data?.barcodes} onChange={changeReturn} value={selectedBarcodes} filter showClear={true} showSelectAll={false} selectedItemsLabel={`${selectedBarcodes?.length} Barcodes Selected`} display="chip" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>

            <Dialog visible={editDeliveryDialog} style={{ width: '650px' }} header={`Update Delivery`} modal footer={editDeliveryDialogFooter} onHide={() => { setEditDeliveryDialog(false) }} blockScroll={true}>
                <div className="p-fluid">
                    <form onSubmit={editSubmit} id="edit-form">
                        <div className='p-grid'>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="SDid">Sales Order Id</label>
                                    <InputText id="SDid" name='SDid' placeholder="Sales Order Id" value={selectedDelivery?.batch_data?.SDid} disabled />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="acct">Account Type</label>
                                    <InputText id="acct" placeholder="Account Type" value={selectedDelivery?.batch_data?.assignto?.accountType} disabled />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="acc">Account</label>
                                    <InputText id="acc" placeholder="Account" value={selectedDelivery?.batch_data?.assignto?.name} disabled />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="delname">Material Delivery Name</label>
                                    <InputText id="delname" placeholder="material Delivery Name" value={selectedDelivery?.batch_name} disabled />
                                </div>
                            </div>
                            {/* <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="mat">Material name</label>
                                <InputText id="mat" placeholder="Material name" value={null} disabled />
                            </div>
                        </div> 
                        <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="batch">Material Batch</label>
                                <InputText id="batch" placeholder="Material Batch" value={null} disabled />
                            </div>
                        </div>  */}
                            {/* <div className='p-col-12'>
                            <div className="p-field">
                                <label htmlFor="barcode">Select Barcodes</label>
                                <MultiSelect className="multiselect-custom"  id="barcode" name="barcodes" optionLabel="barcode" placeholder="Select Barcodes" options={selectedDelivery?.batch_data?.barcodes} onChange={changeReturn} value={selectedBarcodes} filter showClear={true} showSelectAll={false} selectedItemsLabel={`${selectedBarcodes?.length} Barcodes Selected`}  display="chip" />
                            </div>
                        </div> */}

                            {/* <div className='p-col-6'>
                            <div className="p-field">
                                <label htmlFor="mode">Delivery Mode</label>
                                <InputText id="mode" placeholder="Delivery Mode" value={selectedDelivery?.batch_data?.deliveryBy?.name ? 'Technoman' : 'Logistic'} disabled />
                            </div>
                        </div>  */}

                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="mode">Delivery Mode</label>
                                    <Dropdown id='mode' name="mode" placeholder="Delivery Mode" value={selectedDeliveryMode} options={deliveryModeOptions} onChange={changeEdit} optionLabel="name" filter className="p-m-0 multiselect-custom" disabled={modeDisabled} />
                                </div>
                            </div>

                            {
                                selectedDeliveryMode?.code == 'technoman' &&
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="delBy">Delivery By</label>
                                        <Dropdown id='delBy' name="technoman" value={selectedTechman} options={allTechnoman} onChange={changeEdit} optionLabel="fullName" placeholder="Select Technoman" filter className="p-m-0 multiselect-custom" />
                                    </div>
                                </div>
                            }

                            {
                                selectedDeliveryMode?.code == 'logistic' &&
                                <div className='p-col-12'>
                                    <div className="p-field">
                                        <label htmlFor="comment">Comment</label>
                                        <InputTextarea id="comment" rows={2} cols={20} name='comment' placeholder="Comment" onChange={changeEdit} required />
                                    </div>
                                </div>
                            }

                        </div>
                    </form>
                </div>
            </Dialog>

            <Dialog visible={deleteDeliveryDialog} style={{ width: '450px' }} header={`Confirm ? (Batch ID : ${selectedDelivery?.batch_id})`} modal footer={delteDialogFooter} onHide={() => { setDeleteDeliveryDialog(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br /><br />
                        <b><span>Are you sure you want to delete this?</span></b> <br /><br />
                        <span>You wont be able to revert.</span>
                    </center>
                </div>
            </Dialog>

            <Dialog visible={verificationDialog} style={{ width: '550px' }} header={`OTP Verification`} modal footer={verificationDialogFooter} onHide={() => { setVerificationDialog(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br /><br />
                        <b><span>An OTP has been sent to techno-man {selectedTechman?.name} on moblie number {selectedTechman?.cno}.</span></b> <br /><br />
                    </center>
                    <form onSubmit={verifySubmit} id="verify-form" className="p-fluid p-m-4">
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <div className="p-field">
                                    {/* <label htmlFor="otp">Enter OTP</label> */}
                                    <InputText id="otp" name='otp' placeholder="Enter OTP" maxLength={4} onChange={verifyTemplate} autoFocus />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>

        </>
    )
}