import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from '../pages/NotFound';
import { UserTypeRoutes } from '../service/UserTypeRoutes';

const initialValue = JSON.parse(localStorage.getItem("user"));
const userType = initialValue?.userType;

// ===================================================================================
    // Description : Dashboard Page Access according to user type, called from App.js
// ===================================================================================

export const PageAccess = () => {

    const routesData = UserTypeRoutes[userType] || [];

    return (
        <>
            <div className="layout-main-content">
            <Switch>
                {routesData?.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        exact={true}
                        component={route.component}
                    />
                ))}
                <Route component={NotFound} />
            </Switch>
            </div>
        </>
    )
}
