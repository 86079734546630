import React,{useEffect,useState, useRef} from "react";
import { Badge } from "primereact/badge";
import { Chart } from "primereact/chart";
import { Avatar } from "primereact/avatar";
import { ProgressSpinner } from 'primereact/progressspinner';
// Components
import { PerformanceStat } from "./PerformanceStat";
// Services
import CommonCal from "../../service/common/CommonCal";
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices();
const commonCal = new CommonCal();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

export const BestCupPerformance = props => {

    const [loading, setLoading] = useState(false);  
    const [cupData,setCupData] = useState(null);
    const [statData, setStatData] = useState({});
    const [weekStatData, setWeekStatData] = useState({});

    useEffect(()=>{setLoading(props.loading)},[[props.loading]])
    useEffect(()=>{
        setCupData(props.performanceStats)
        console.log(props.performanceStats);
    },[props.performanceStats])

    useEffect(()=>{
        getUserStats()
    },[])

    const getUserStats = async() =>{

        const response = commonCal.getDates()
        console.log(response);

        const LastMonthData = {date: {startDate: response.lastStart, endDate: response.lastend},userType: initialValue.userType}
        console.log(LastMonthData);
        const response2 = await apiServices.getPerformance(LastMonthData);

        const xx = props?.id == 'cup' ? response2?.data?.data[0]?.bycups : response2?.data?.data[0]?.bytarget
        const best1 = props?.title?.includes('Best') ? xx?.best : xx?.worst
        const x1 = best1?.reduce((accumulator, currentValue) =>accumulator + currentValue.hcup,0);

        const ThisMonthData = {date: {startDate: response.monthStart, endDate: response.endThisMonth},userType: initialValue.userType}
        console.log(ThisMonthData);
        const response1 = await apiServices.getPerformance(ThisMonthData);

        const xx1 = props?.id == 'cup' ? response1?.data?.data[0]?.bycups : response1?.data?.data[0]?.bytarget
        const best = props?.title?.includes('Best') ? xx1?.best : xx1?.worst
        const x = best?.reduce((accumulator, currentValue) =>accumulator + currentValue.hcup,0);

        const per = 100*(x/x1)

        console.log(per);

        if(per > 100){
            let upDownVal = per - 100;
            let value = Math.round(upDownVal * 10) / 10
            setStatData({...statData,thisMonthUpDown:value,status:'up'})
        }else if(per < 100){
            let upDownVal = 100 - per;
            let value = Math.round(upDownVal * 10) / 10
            setStatData({...statData,thisMonthUpDown:value,status:'down'})
        }else if(per == 100){
            let upDownVal = per - 100;
            setStatData({...statData,thisMonthUpDown:upDownVal,status:'balance'})
        }else{
            setStatData({...statData,thisMonthUpDown:'0',status:'balance'})
        }
        
        const LastWeekData = {date: {startDate: response.firstDayOfLastWeek, endDate: response.lastDayOfLastWeek},userType: initialValue.userType}
        console.log(LastWeekData);
        const responseLast = await apiServices.getPerformance(LastWeekData);
        
        let calcLastWeek;
        
        if(responseLast?.data?.data){
            const xx2 = props?.id == 'cup' ? responseLast?.data?.data[0]?.bycups : responseLast?.data?.data[0]?.bytarget
            const bestLastWeek = props?.title?.includes('Best') ? xx2?.best : xx2?.worst
            calcLastWeek = bestLastWeek?.reduce((accumulator, currentValue) =>accumulator + currentValue.hcup,0);
            // console.log(calcLastWeek);
        }
        
        
        const thisWeekData = {date: {startDate: response.weekstart, endDate: response.weekEnd},userType: initialValue.userType}
        console.log(thisWeekData);
        const response3 = await apiServices.getPerformance(thisWeekData);
        const xx4 = props?.id == 'cup' ? response3?.data?.data[0]?.bycups : response3?.data?.data[0]?.bytarget
        const bestThisWeek = props?.title?.includes('Best') ? xx4?.best : xx4?.worst
        const calcThisWeek = bestThisWeek?.reduce((accumulator, currentValue) =>accumulator + currentValue.hcup,0);
        // console.log(calcThisWeek);
        
        const weekCalc = 100*(calcThisWeek/calcLastWeek)

        if(weekCalc > 100){
            let upDownVal = weekCalc - 100;
            let value = Math.round(upDownVal * 10) / 10
            console.log(value);
            setWeekStatData({...weekStatData,thisMonthUpDown:value,status:'up'})
        }else if(weekCalc < 100){
            let upDownVal = 100 - weekCalc;
            let value = Math.round(upDownVal * 10) / 10
            setWeekStatData({...weekStatData,thisMonthUpDown:value,status:'down'})
        }else if(weekCalc == 100){
            let upDownVal = weekCalc - 100;
            setWeekStatData({...weekStatData,thisMonthUpDown:upDownVal,status:'balance'})
        }else{
            setWeekStatData({...weekStatData,thisMonthUpDown:'0',status:'balance'})
        }

        
    }


    let arr = [];
    cupData?.forEach((element) => {
        const customerID = element.customer;
        props.allUsers?.map((x) => {        
            if(customerID == x.userid){
                arr.push({...element,companyname:x.billing_details.company?x.billing_details.company:'CIPL'}) 
            }
        });
    });
    // console.log(arr);

    const comapnyNames = arr.map(x => x.companyname)

    if(props.id == 'cup'){
        var dataValues = arr.map(x => x.hcup);
        var label = 'Cup Consumption';
        var xTitle = 'Accounts';
        var yTitle = 'Cup Peformance'
    }else{
        var dataValues = arr.map(x => x.ratio)
        var label = 'Ratio';
        var xTitle = 'Accounts';
        var yTitle = 'Peformance Ratio'
    }  

    const barData = {
        labels: comapnyNames,
        datasets: [
            {
                label: label,
                backgroundColor: '#00544d',
                borderColor: 'rgb(255, 99, 132)',
                data: dataValues,
                borderRadius: 5
            }
        ]
    };

    const barOptions = {
        plugins: {
            legend: {
                // labels: {
                //     color: '#A0A7B5'
                // },
                display:false
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                },
                title: {
                    display: true,
                    text: xTitle
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                },
                title: {
                    display: true,
                    text: yTitle
                }
            }
        }
    };


    return (

        <>
            {/* <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <PerformanceStat title="THIS MONTH" value1={statData?.thisMonthUpDown} value2="0.81%" status={statData?.status}/>
                </div>
                <div className="p-col-12 p-md-6">
                    <PerformanceStat title="THIS WEEK" value1={weekStatData?.thisMonthUpDown} value2="$306.2" status={weekStatData?.status}/>
                </div>
            </div> */}

            {
                loading ?

                <div className="spinner-div">
                    <center><ProgressSpinner strokeWidth="7" animationDuration='4s' /></center>
                </div>:

                cupData ? 
                    <>
                    <div className="p-grid">
                    <div className="p-col-12 p-md-3">
                    <div className="card widget-performance">
                    <div className="header">
                        <h6>Performance</h6>
                        <p className="subtitle">{props.id == 'cup'?'By Cup-Wise':'By Target Ratio'}</p>
                    </div>
                    <div className="content">
                        <ul>
                            {
                                arr.map((x,i)=>(
                                    <li className="person-item" key={i+1}>
                                        <Avatar image="assets/layout/images/dashboard/company-img.png" className="p-mr-2 p-overlay-badge" shape="circle"><Badge severity={props?.title?.includes('Best') ? 'success' : 'danger'} value={i+1} /></Avatar>
                                        <div className="person-info">
                                            <div > {x.subid} / {x.id}</div>
                                            <span><b>{x.companyname} </b> </span>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    </div>
                    </div>
                    
                    <div className="p-col-12 p-md-9">
                        <div className="card">
                        
                            <h6 className="centerText">{props.title}</h6>
                            <Chart type="bar" data={barData} options={barOptions} />
                        </div>
                    </div>

                    </div>
                    
                    </>
                
                :   
                <>
                    <div className="p-mt-5">
                        <center>
                            <span className="p-error "><h5>No Data Found</h5></span>    
                        </center>
                    </div>
                </>   
            }
        </>
        
    )
}