import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

export const DistributorDialog = (props) =>{

    const RolesDropdownItems = [
        { name: "Financial Partner", code: "financial_partner" },
        { name: "Distributor Partner", code: "distributor_partner" },
        { name: "Stratergy Partner", code: "stratergy_partner" }
    ]

    const invoiceCycleItems = [
        { name: "Every Month End", code: "month_end" },
        { name: "Monthly (30 Days)", code: "monthly" },
        { name: "Weekly (7 Days)", code: "weekly" }
    ]

    const [distributorDialog,setDistributorDialog] = useState(false)
    const [distributor,setDistributor] = useState(null)
    const [userRole , setUserRole] = useState(null)
    const [userRoleDesc , setUserRoleDesc] = useState(null)
    const [distTemplate,setDistTemplate] = useState({})
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [city, setCity] = useState([]);
    const [selectedCountry,setSelectedCountry] = useState(null)
    const [selectedState,setSelectedState] = useState(null)
    const [selectedCity,setSelectedCity] = useState(null)
    const [cnoVerify, setCnoVerify] = useState(false);
    const [pincodeVerify, setPincodeVerify] = useState(false);
    const [billPincodeVerify, setBillPincodeVerify] = useState(false);
    const [selectedinvoiceItem, setSelectedinvoiceItem] = useState(null);
    const [billingCountry , setBillingCountry] = useState(null)
    const [billingState , setBillingState] = useState(null)
    const [billingStates , setBillingStates] = useState(null)
    const [billingCity , setBillingCity] = useState(null)
    const [selectedBillingCity , setSelectedBillingCity] = useState(null)


    const toast = useRef(null);

    useEffect(()=>{
        setDefault()
        setDistributorDialog(props.addDistriDialog)
    },[props.addDistriDialog])

    useEffect(()=>{
        getDistributorData(props.distributor)
        // getCountries()
    },[props.distributor])


    
    useEffect(()=> {
        setCountries(props.countries)
    }, [props.countries])

    const setDefault = () =>{
        setUserRole(null)
        setUserRoleDesc(false)
        setSelectedCountry(null)
        setSelectedState(null)
        setSelectedCity(null)
        setSelectedinvoiceItem(null)
        setBillingCountry(null)
        setBillingState(null)
        setBillingCity(null)
    }

    const getDistributorData = async(data) =>{
        setDistributor(data)
        if(data){

            console.log(data);
            setDistTemplate(data)

            if(data.userRole.financial){
                setUserRole({ name: "Financial Partner", code: "financial_partner" })
            }else if(data.userRole.partner){
                setUserRole({ name: "Distributor Partner", code: "distributor_partner" })
            }else if(data.userRole.stratigical){
                setUserRole({ name: "Stratergy Partner", code: "stratergy_partner" })
            }

            setSelectedCountry(data?.addr?.country)
            const res = await apiServices.getState({ country_id: data?.addr?.country.id });
            setStates(res.data);
            setSelectedState(data?.addr?.state)
            const res1 = await apiServices.getCity({ state_id: data?.addr?.state.id });
            setCity(res1.data);
            setSelectedCity(data?.addr?.city)

            if(data?.billing_details?.country){
                setBillingCountry(data?.billing_details?.country)
                const res2 = await apiServices.getState({ country_id: data?.billing_details?.country?.id });
                setBillingStates(res2.data)
                setBillingState(data?.billing_details?.state)   
                const res3 = await apiServices.getCity({ state_id: data?.billing_details?.state?.id });
                setBillingCity(res3.data)
                setSelectedBillingCity(data?.billing_details?.city)
            }

            if(data?.billing_details?.invoiceCycle == 'month_end'){
                setSelectedinvoiceItem({ name: "Every Month End", code: "month_end" })
            }else if(data?.billing_details?.invoiceCycle == 'monthly'){
                setSelectedinvoiceItem({ name: "Monthly (30 Days)", code: "monthly" })
            }else if(data?.billing_details?.invoiceCycle == 'weekly'){
                setSelectedinvoiceItem({ name: "Weekly (7 Days)", code: "weekly" })
            }
        
        }
    }

    // const getCountries = async () => {
    //     const res = await apiServices.getCountry();
    //     setCountries(res?.data)
    // }

    const onDistributorSubmit = async(e) =>{
        e.preventDefault();

        if(distributor){
            console.log(distTemplate);
            const response = await apiServices.updateUser(distTemplate);
            if(response?.data?.success){
                toast.current.show({ severity: 'success', summary: 'Successful', detail:'User Updated Successfully', life: 3000 });
            }else{
                toast.current.show({ severity: 'error', summary: 'Failed', detail:'Failed to Update', life: 3000 });
            }

        }else{

            if(cnoVerify || pincodeVerify || billPincodeVerify){
                alert('Invalid Action')
            }else{
                const response = await apiServices.createUser(distTemplate);
                if(response?.data?.success){
                    toast.current.show({ severity: 'success', summary: 'Successful', detail:'User Added Successfully', life: 3000 });
                }else{
                    toast.current.show({ severity: 'error', summary: 'Failed', detail:'Failed to Add', life: 3000 });
                }
            }
        }
        props.setAddDistriDialog(false)
        props.getUser()
    }

    const changeUserRole = (e) =>{
        console.log(e.value);
        setUserRole(e.value)
        switch (e.value.code) {
            case 'financial_partner':
                var distTem = {...distTemplate,userRole: { financial: true , partner: false , stratigical: false },userType: "distributor",status: true}
                setDistTemplate(distTem)
                setUserRoleDesc('The one who is simply investor partner of cipl and will have viewing rights same as cipl, in this case invoice for subscription and consumable will be via cipl.')
            break;
            case 'distributor_partner':
                var distTem = {...distTemplate,userRole: { financial: false , partner: true , stratigical: false },userType: "distributor",status: true}
                setDistTemplate(distTem)
                setUserRoleDesc('The one who simply takes machine from cipl and deploy it to their customer at their cost, in this case invoice for subscription and consumable will be via partner.')
            break;
            case 'stratergy_partner':
                var distTem = {...distTemplate,userRole: { financial: false , partner: false , stratigical: true },userType: "distributor",status: true}
                setDistTemplate(distTem)
                setUserRoleDesc('The one who is reponsible to provide leads and will help cipl to deploy more and more ctvms, in this case consumable invoice will be via cipl and subscription via partner.')
            break;
        
            default:
                break;
        }
    }

    const onDistValues = (e) =>{
        let distTem = {...distTemplate,[e.target.name] : e.target.value}
        setDistTemplate(distTem)
    }

    const onDistAddr = (e) =>{

            let distTem = {...distTemplate,addr:{...distTemplate.addr,[e.target.name] : e.target.value}}
            setDistTemplate(distTem)
    }

    const onPincodeChange = async(e) =>{

        if(e.target.value.toString().length == 6){

            const response = await apiServices.getPincodeDetails(e.target.value);

            if(response?.data[0]?.PostOffice?.length > 0){

                const country = response?.data[0]?.PostOffice[0].Country;
                const state = response?.data[0]?.PostOffice[0].State;
                const city = response?.data[0]?.PostOffice[0].District;

                const defaultCountry = countries.filter((x)=>{ if(x.name == country){ return x; } })
                setSelectedCountry(defaultCountry[0])

                const res = await apiServices.getState({ country_id: defaultCountry[0].id });
                setStates(res?.data);
            
                const defaultState = res?.data?.filter((x)=>{ if(x.name == state){ return x; }})
                setSelectedState(defaultState[0])

                const res1 = await apiServices.getCity({ state_id: defaultState[0].id });
                setCity(res1.data);

                const defaultCity = res1?.data?.filter((x)=>{ if(x.name == city){ return x; }})
                setSelectedCity(defaultCity[0])

                let distTem = {...distTemplate,addr:{...distTemplate.addr, country:defaultCountry[0],city:defaultCity[0],state:defaultState[0],[e.target.name] : e.target.value}}
                setDistTemplate(distTem)


            }else{
                setPincodeVerify(true)
                defaultPincodevalues()
            }
        }else{
            setPincodeVerify(false)
            defaultPincodevalues()
        }
    }

    const onBillingPincodeChange = async(e)=>{

        if(e.target.value.toString().length == 6){

            const response = await apiServices.getPincodeDetails(e.target.value);

            if(response?.data[0]?.PostOffice?.length > 0){

                const country = response?.data[0]?.PostOffice[0].Country;
                const state = response?.data[0]?.PostOffice[0].State;
                const city = response?.data[0]?.PostOffice[0].District;

                const defaultCountry = countries.filter((x)=>{ if(x.name == country){ return x; } })
                setBillingCountry(defaultCountry[0])

                const res = await apiServices.getState({ country_id: defaultCountry[0].id });
                setBillingStates(res?.data)
            
                const defaultState = res?.data?.filter((x)=>{ if(x.name == state){ return x; }})
                setBillingState(defaultState[0])

                const res1 = await apiServices.getCity({ state_id: defaultState[0].id });
                setBillingCity(res1.data)

                const defaultCity = res1?.data?.filter((x)=>{ if(x.name == city){ return x; }})
                setSelectedBillingCity(defaultCity[0])

                let distTem = {...distTemplate,billing_details:{...distTemplate?.billing_details, country:defaultCountry[0],city:defaultCity[0],state:defaultState[0],[e.target.name] : e.target.value}}
                setDistTemplate(distTem)

            }else{
                setBillPincodeVerify(true)
                defaultPincodeValueForBill()
            }
        }else{
            setBillPincodeVerify(false)
            defaultPincodeValueForBill()
        }
    }

    const defaultPincodevalues = () =>{
        setSelectedCountry(null)
        setSelectedState(null)
        setSelectedCity(null)
    }

    const defaultPincodeValueForBill = () =>{
        setBillingCountry(null)
        setBillingStates(null)
        setBillingCity(null)
    }

    const callVerification = async(data) =>{
        const res = await apiServices.verifyUser({cno: data});
        res?.data?.success ? setCnoVerify(true) : setCnoVerify(false)
    }

    const toVerifyNumber = (e) =>{
        setCnoVerify(false)
        const contact = '+91'+ e.target.value;
        let distTem = {...distTemplate,[e.target.name] : contact}
        setDistTemplate(distTem)
        if(e.target.value.length == 10){
            callVerification(contact)
        }            
    }

    const onCountryDrop = async (e) => {
        setSelectedCountry(e.value);
        const res = await apiServices.getState({ country_id: e.value.id });
        setStates(res.data);
        let distTem = {...distTemplate,addr:{...distTemplate.addr, [e.target.name] : e.value}}
        setDistTemplate(distTem)
    }

    const onStateDrop = async (e) => {
        setSelectedState(e.value);
        console.log(e.value);
        const res = await apiServices.getCity({ state_id: e.value.id });
        setCity(res.data);
        let distTem = {...distTemplate,addr:{...distTemplate.addr, [e.target.name] : e.value}}
        setDistTemplate(distTem)
    };

    const onCityDrop = (e) =>{
        setSelectedCity(e.value)
        let distTem = {...distTemplate,addr:{...distTemplate.addr, [e.target.name] : e.value}}
        setDistTemplate(distTem)
    }

    const onInvoiceCycle = (e) =>{
        setSelectedinvoiceItem(e.value)
        let distTem = {...distTemplate,billing_details:{...distTemplate?.billing_details, [e.target.name] : e.value.code}}
        setDistTemplate(distTem)
    }

    const onBillingChange = (e) =>{
        let distTem = {...distTemplate,billing_details:{...distTemplate?.billing_details, [e.target.name] : e.target.value}}
        setDistTemplate(distTem)
    }

    const onBillingCountry = async (e) => {
        setBillingCountry(e.value);
        const res = await apiServices.getState({ country_id: e.value.id });
        setBillingStates(res.data);
        setDistTemplate({...distTemplate,billing_details:{...distTemplate?.billing_details, [e.target.name] : e.value}})
    }

    const onBillingState = async(e) => {
        setBillingState(e.value);
        const res = await apiServices.getCity({ state_id: e.value.id });
        setBillingCity(res.data);
        setDistTemplate({...distTemplate,billing_details:{...distTemplate?.billing_details, [e.target.name] : e.value}})
    }
    
    const onBillingCity = (e) =>{
        setSelectedBillingCity(e.value)
        setDistTemplate({...distTemplate,billing_details:{...distTemplate?.billing_details, [e.target.name] : e.value}})
    }   

    const distFooter =(
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={()=>{props.setAddDistriDialog(false)}} />
            <Button label="Submit" type="submit" form='dist-form' icon="pi pi-check" className="p-button-success" disabled={(cnoVerify || pincodeVerify || billPincodeVerify) ? true: false} />
        </>
    )

    // !cnoVerify && !pincodeVerify


    return (

        <>
            <Toast ref={toast} position='center' />
            <Dialog visible={distributorDialog} style={{ width: "750px" }} header={`${distributor?'Edit':'Add'} Distributor`} modal footer={distFooter} onHide={()=>{props.setAddDistriDialog(false)}} className="p-fluid"  maximizable={true} blockScroll={true} >
            <form onSubmit={onDistributorSubmit} id="dist-form" className="p-fluid">
            <h5>Details</h5>
            <div className="p-grid">
                <div className="p-col-12 p-md-12">
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="user_role">User Role</label>
                    <Dropdown  onChange={changeUserRole} value={userRole} options={RolesDropdownItems} filter optionLabel="name" placeholder="Select User Role" disabled={distributor} required autoFocus></Dropdown>
                    </div>
                    {
                        userRoleDesc &&
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="dfirstname">Role Description</label><br/>
                            <span className="p-error">{userRoleDesc}</span>
                        </div>
                    }
                    
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="dfirstname">First Name</label>
                        <InputText id="dfirstname" name="name" type="text" placeholder="First Name" onChange={onDistValues} defaultValue={distributor?.name} autoComplete='off' required/>
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="dlastname">Last Name</label>
                        <InputText id="dlastname" name="lastName" type="text" placeholder="Last Name" onChange={onDistValues} defaultValue={distributor?.lastName} autoComplete='off' required />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="demailid">Email Id</label>
                        <InputText id="demailid" name="email" type="email" placeholder="Email ID" onChange={onDistValues} defaultValue={distributor?.email} autoComplete='off' required/>
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="dcontact">Contact Number</label>
                        <InputText id="dcontact" name="cno" type="text" maxLength={10} placeholder="Contact Number" onChange={toVerifyNumber} defaultValue={distributor?.cno} autoComplete='off' required/>
                        { cnoVerify && <small className="p-error block">Number Already Exists.</small> }                    
                    </div>
                    <div className="p-field p-col-12">
                        <label htmlFor="daddress">Address</label>
                        <InputTextarea id="daddress" name="addr" rows="2" placeholder="Address" onChange={onDistAddr} defaultValue={distributor?.addr?.addr} required/>
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="company-name">Company Name</label>
                        <InputText id="company-name" name="company" type="text" onChange={onDistAddr} placeholder="Company Name" defaultValue={distributor?.addr?.company} autoComplete='off' required />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="dlocality">Locality</label>
                        <InputText id="dlocality" name="locality" type="text" placeholder="Locality" onChange={onDistAddr} defaultValue={distributor?.addr?.locality} autoComplete='off' required/>
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="dPin">Pincode</label>
                        <InputText id="dPin" name="pincode" type="text" maxLength={6} placeholder="Pincode" onChange={onPincodeChange} defaultValue={distributor?.addr?.pincode} autoComplete='off' required/>
                        { pincodeVerify && <small className="p-error block">Invalid Pincode.</small> }   
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="dcountry">Country</label>
                        <Dropdown name="country" onChange={onCountryDrop} value={selectedCountry} options={countries} filter optionLabel="name" placeholder="Select Country" required disabled ></Dropdown>
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="dstate">State</label>
                        <Dropdown id="dstate" name="state" onChange={onStateDrop} value={selectedState} options={states} optionLabel="name" filter placeholder="Select State" required disabled></Dropdown>
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="dcity">City</label>
                        <Dropdown id="dcity" name="city" value={selectedCity} onChange={onCityDrop} options={city} optionLabel="name" filter placeholder="Select City" required></Dropdown>
                    </div>
                </div>
                </div>
            </div>
            <h5>BILLING DETAILS</h5>
            <div className="p-grid">
                <div className="p-col-12 p-md-12">
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="invoice-cycle">Invoice Cycle</label>
                        <Dropdown id="invoice-cycle" name="invoiceCycle" value={selectedinvoiceItem} onChange={onInvoiceCycle} options={invoiceCycleItems} optionLabel="name" placeholder="Select Invoice Cycle" required ></Dropdown>
                    </div> 
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="ex-period">Extension Period (Calendar Days)</label>
                        <InputText id="ex-period" name="extension" type="number" min="0" onChange={onBillingChange} defaultValue={distributor?.billing_details?.extension} placeholder="Extension Period (Calendar Days)" autoComplete='off' required/>
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="company-name">Company Name</label>
                        <InputText id="company-name" name="cmpname" type="text" onChange={onBillingChange} defaultValue={distributor?.billing_details?.cmpname} placeholder="Company Name" autoComplete='off' required />
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="gst-number">GST Number</label>
                        <InputText id="gst-number" name="gstNumber" type="text" onChange={onDistValues} placeholder="GST Number" defaultValue={distributor?.gstNumber} autoComplete='off' required />
                    </div>

                    <div className="p-field p-col-12">
                        <label htmlFor="daddress">Address</label>
                        <InputTextarea id="daddress" name="addr" rows="2" onChange={onBillingChange} defaultValue={distributor?.addr?.addr} placeholder="Address" required/>
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="dlocality">Locality</label>
                        <InputText id="dlocality" name="locality" onChange={onBillingChange} type="text" placeholder="Locality" defaultValue={distributor?.addr?.locality} autoComplete='off' required />
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="dPin">Pincode</label>
                        <InputText id="dPin" name="pincode" onChange={onBillingPincodeChange} type="text" placeholder="Pincode" defaultValue={distributor?.addr?.pincode} autoComplete='off' maxLength={6} required />
                        { billPincodeVerify && <small className="p-error block">Invalid Pincode.</small> }   
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="dcountry">Country</label>
                        <Dropdown id="dcountry" name="country" options={countries} onChange={onBillingCountry} value={billingCountry}  filter optionLabel="name" placeholder="Select Country" required disabled></Dropdown>
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="dstate">State</label>
                        <Dropdown id="dstate" name="state" options={billingStates} onChange={onBillingState} value={billingState} optionLabel="name" filter placeholder="Select State" required disabled></Dropdown>
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="dcity">City</label>
                        <Dropdown id="dcity" name="city" optionLabel="name" options={billingCity} onChange={onBillingCity} value={selectedBillingCity} filter placeholder="Select City"  required></Dropdown>
                    </div>
                </div>
                </div>
            </div>
            </form>
            </Dialog>
        </>
    )
}