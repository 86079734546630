import axios from 'axios';
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);
const erp_url = process.env.REACT_APP_ERP;
const iot_url = process.env.REACT_APP_IOT;
const flutter_url = process.env.REACT_APP_FLUTTER_DEV;


const options = {
    headers: {
        "x-access-token" : initialValue?.idToken,
        "Content-type": "application/json; charset=UTF-8"
    }
};

const appOptions = {
    headers: {
        "Authorization" : initialValue?.app_data?.token,
        "Content-type": "application/json"   
    }
};

const appOptionsforFormSubmission = {
    headers: {
      Authorization: initialValue?.app_data?.token,
      "Content-Type": "multipart/form-data",
    },
  };


//====================== IOT SERVICE =======================//

export const getIotService = async (end_url) => {
    try {
        return await axios.get(`${iot_url}${end_url}`);
    } catch (error) {
        return error.response;
    }
}

export const postIotService = async (end_url,data, payloadOptions=options) => {
    try {
        return await axios.post(`${iot_url}${end_url}`, data, payloadOptions);
    } catch (error) {
        return error.response;
    }
}

//====================== ERP SERVICE =======================//

export const getErpService = async (end_url, payloadOptions = options) => {
    try {
        return await axios.get(`${erp_url}${end_url}`, payloadOptions);
    } catch (error) {
        return error.response;
    }
}

export const getErpMediaService = async (end_url, responceType, data, payloadOptions = options) => {
    try {
        return await axios.get(`${erp_url}${end_url}`, responceType, data, payloadOptions);
    } catch (error) {
        return error.response;
    }
}

export const postErpService = async (end_url, data, payloadOptions = options) => {
    try {
        return await axios.post(`${erp_url}${end_url}`, data, payloadOptions);
    } catch (error) {
        return error.response;
    }
}

export const putErpService = async (end_url,data) => {
    try {
        return await axios.put(`${erp_url}${end_url}`, data, options);
    } catch (error) {
        return error.response;
    }
}

export const getZohoService = async (end_url, token) => {

    console.log(token);
    const header = {
        headers: {
            "Authorization" : token,
            "Access-Control-Allow-Origin" : "*",
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
            'Access-Control-Allow-Headers': 'Content-Type,Authorization',
            // 'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, PUT, PATCH, GET, DELETE, OPTIONS',
            'Access-Control-Allow-Credentials': true,
        }
    };

    console.log(end_url);

    try {
        return await axios.get(end_url,{ crossdomain: true }, header);
    } catch (error) {
        return error.response;
    }
}



//====================== FLUTTER SERVICE =======================//


export const getFlutterService = async (end_url, newOptions = appOptions ) => {
    try {
        return await axios.get(`${flutter_url}${end_url}`, newOptions);
    } catch (error) {
        return error.response;
    }
}


export const postFlutterService = async(end_url, data, newOptions = appOptions) =>{
    try {
        return await axios.post(`${flutter_url}${end_url}`,data, newOptions);
    } catch(error) {
        return error.response;
    } 
}


export const postFlutterServiceUpdate = async ( end_url, data, newOptions = appOptionsforFormSubmission ) => {
    try {
        return await axios.post(`${flutter_url}${end_url}`, data, newOptions);
    } catch (error) {
        return error.response;
    }
};
