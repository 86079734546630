import React, {useState, useEffect} from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
// Component
import { KeyPeformanceStat } from "./KeyPerformanceStat";
import { AccWiseCupChart } from "./AccWiseCupChart";
import { AccWiseTargetChart } from "./AccWiseTargetChart";
import { AccWiseInventoryChart } from "./AccWiseInventoryChart";
// Services
import CommonCal from "../../service/common/CommonCal";
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices();
const commonCal = new CommonCal()

export const KeyAccountPerformance = () =>{

    const [userData, setUserData] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedType, setSelectedType] = useState({name:'Account',code:'account'})
    const [selectedDuration, setSelectedDuration] = useState({name:'This Month',code:'this_month'})
    const [dateData, setDateData] = useState(null)
    const [accWiseConData, setAccWiseConData] = useState([])
    const [accWiseTargetData, setAccWiseTargetData] = useState([])
    const [inventoryPerData, setInventoryPerData] = useState([])
    const [selectedDate, setSelectedDate] = useState(null)
    const [customDialog, setCustomDialog] = useState(false)
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');

    const [cupLoading, setCupLoading] = useState(false)
    const [targetLoading, setTargetLoading] = useState(false)
    const [inventoryLoading, setInventoryLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(false)
    

    const accountType = [
        {name:'Account',code:'account'},
        {name:'Facility',code:'facility'},
        {name:'Subscription',code:'subscription'}
    ]

    const TimeSelectValues = [
        {name:'All',code:'all'},
        {name:'Today',code:'today'},
        {name:'Yesterday',code:'yesterday'},
        {name:'This Week',code:'this_week'},
        {name:'This Month',code:'this_month'},
        {name:'Last Month',code:'last_month'},
        {name:'Custom',code:'custom'}
    ]

    useEffect(()=>{
        getUsers()
        getInitialData()
    },[])

    const getUsers = async() =>{
        
    }

    const getInitialData = async() =>{
        setInitialLoading(true)
        const response = await apiServices.getUserReduced() 
        const res = response?.data?.data?.filter((pos) => pos.userType == 'accountmanager')
        const arr = [];
        res?.map((x)=>{arr.push({...x,display:x.name + " " + x.lastName})})
        console.log(arr);
        setUserData(arr)
        setSelectedUser(arr.length > 0 ? arr[0] : null)

        const response1 = commonCal.getDates()
        setDateData(response1)
        setSelectedDate({startDate: response1.monthStart, endDate: response1.nowDate})
        const data = {date: {startDate: response1.monthStart, endDate: response1.nowDate},filter: selectedType.code, value: "All"}
        const data1 = {date: {startDate: response1.monthStart, endDate: response1.nowDate},filter: selectedType.code, value: "All"}
        getMisInventoryStats(data)
        getMisPerformance(data1,arr[0],selectedType)

        console.log(data);
        console.log(arr[0]);
        console.log(selectedType);
        misUsageStats(data,arr[0],selectedType)
        setInitialLoading(false)
    }

    const getMisInventoryStats = async(data) =>{
        setInventoryLoading(true)
        const res = await apiServices.getUserReduced() 
        const res1 = await apiServices.getMisInventory(data)
        const res2 = await apiServices.findMachines()

        const arr = [];
        res1?.data?.data.forEach((element) => {
            const userid = element.id;
            res.data.data.map((x) => {        
                if(userid == x.userid){
                    arr.push({...element,name:x.name}) 
                }
              });
        });

        console.log(arr);
    
        const arr1 = [];
        arr.forEach((d) => {
            const aa = res2.data.data.filter((x) => { if(d._id == x.customer){ return x;}});
            arr1.push({...d,accManager:aa[0]?.superman?.accountmanager})
        });

        const final = arr1.filter((obj)=>{ if(obj.accManager == userData?.userid){ return obj;}})
        setInventoryPerData(final)
        setInventoryLoading(false)
    }

    const getMisPerformance = async(data,userData,userType)=>{
        setTargetLoading(true)
        const res = await apiServices.getUserReduced() 
        const res1 = await apiServices.getMisPerformance(data)
        const res2 = await apiServices.findMachines()

        const arr = [];
        res2?.data?.data.forEach((element) => {
            res?.data?.data.map((x) => {        
                if(element.customer === x.userid){
                    arr.push({...element,name:x.name,companyName:x.billing_details ? x.billing_details.company : 'Cherise India Pvt. Ltd.'})
                }
              });
        });

        const arr1=[];
        arr.forEach((data) => { if(userData?.userid === data?.superman?.accountmanager){ arr1.push({...data}) } });
        console.log(arr1);

        if(userType?.code == 'account'){

            var arr2 = [];
            arr1.forEach( (data)=> {
                res1.data.data.forEach((data2)=> {
                    if(data.subscription.userid === data2._id) {
                        arr2.push({...data2,name:data.name});
                    }
                })
            })
            
        }else if(userType?.code == 'facility'){

            var arr2 = [];
            arr1.forEach( (data)=> {
                res1.data.data.forEach((data2)=> {
                    if(data2?.facid){
                        if(data?.subscription?.facilitydata?.facid === data2?.facid) {
                            arr2.push({...data2,name:data.name});
                        }
                    }
                })
            })

        }else if(userType?.code == 'subscription'){
            
            var arr2 = [];
            arr1.forEach( (data)=> {
                res1.data.data.forEach((data2)=> {
                    if(data.subid === data2.subid) {
                        arr2.push({...data2,name:data.name});
                    }
                })
            })

        }else{
            var arr2 = []
        }

        const unique = Array.from(new Set(arr2.map(JSON.stringify))).map(JSON.parse);
        const sorted = unique.sort((a, b) => parseFloat(b.ratio) - parseFloat(a.ratio));
        setAccWiseTargetData(sorted)
        setTargetLoading(false)
    }

    const misUsageStats = async(data,userData,userType) =>{

        setCupLoading(true)
        console.log(userType)

        const res = await apiServices.getUserReduced() 
        // console.log(res.data.data);
        const res1 = await apiServices.findMisUsageStats(data)
        console.log(res1?.data?.data);
        const res2 = await apiServices.findMachines()
        console.log(res2?.data?.data);

        const arr = [];
        res2?.data?.data.forEach((element) => {
            res?.data?.data.map((x) => {
                if(element.customer === x.userid){ 
                    arr.push({...element,
                        name:x.name + " " + x.lastName,
                        companyName:x.billing_details ? x.billing_details.company : 'Cherise India Pvt. Ltd.'})}
              });
        });

        const arr1=[];
        arr.forEach((data) => {
            if(userData?.userid === data?.superman?.accountmanager){ 
                arr1.push({...data})
            }
        });

        console.log(arr1);

        if(userType?.code == 'account'){

            var arr2 = [];
            arr1.forEach( (data)=> {
                res1.data.data[0].filter.forEach((data2)=> {
                    if(data.subscription.userid === data2._id) {
                        arr2.push({...data2,name:data.name,companyName:data.companyName});
                    }
                })
            })
    
        }else if(userType?.code == 'facility'){

            var arr2 = [];
            arr1.forEach( (data)=> {
                res1.data.data[0].filter.forEach((data2)=> {
                    if(data2?._id?.facid){
                        if(data?.subscription?.facilitydata?.facid === data2?._id?.facid) {
                            arr2.push({...data2,name:data.name,companyName:data.companyName});
                        }
                    }
                })
            })

        }else if(userType?.code == 'subscription'){

            var arr2 = [];
            arr1.forEach( (data)=> {
                res1.data.data[0].filter.forEach((data2)=> {
                if(data.subid === data2.subid) {
                    arr2.push({...data2,name:data.name,companyName:data.companyName});
                }
                })
            })        
        }else{
            var arr2 = []
        }

        const unique = Array.from(new Set(arr2?.map(JSON.stringify))).map(JSON.parse);
        const sorted = unique.sort((a, b) => parseFloat(b.cups) - parseFloat(a.cups));
        console.log(sorted);
        setAccWiseConData(sorted)
        setCupLoading(false)
    }


    const changeUser = (e) =>{
        setSelectedUser(e.value)
        const data = {date: selectedDate, filter: selectedType.code, value: "All"}
        getMisInventoryStats(data)
        getMisPerformance(data,e.value,selectedType)
        misUsageStats(data,e.value,selectedType)
    }

    const changeType = (e) =>{
        setSelectedType(e.value)
        const data = {date: selectedDate, filter: e.value.code, value: "All"}
        getMisInventoryStats(data)
        getMisPerformance(data,selectedUser,e.value)
        misUsageStats(data,selectedUser,e.value)
    }

    const changeDuration = (e) =>{

        setSelectedDuration(e.value)

        switch (e.value.code) {
            case 'all':
                var data = {filter: selectedType.code,value: "All"}
                setSelectedDate(null)
                getMisInventoryStats(data)
                getMisPerformance(data,selectedUser,selectedType)
                misUsageStats(data,selectedUser,selectedType)
            break;

            case 'today':
                var data = {date: {startDate: dateData.startToday, endDate: dateData.endToday}, filter: selectedType.code, value: "All"}
                setSelectedDate({startDate: dateData.startToday, endDate: dateData.endToday})
                getMisInventoryStats(data)
                getMisPerformance(data,selectedUser,selectedType)
                misUsageStats(data,selectedUser,selectedType)
            break;

            case 'yesterday':
                
                var data = {date: {startDate: dateData.YestStart, endDate: dateData.EndStart}, filter: selectedType.code, value: "All"}
                setSelectedDate({startDate: dateData.YestStart, endDate: dateData.EndStart})
                getMisInventoryStats(data)
                getMisPerformance(data,selectedUser,selectedType)
                misUsageStats(data,selectedUser,selectedType)

            break;

            case 'this_week':

                var data = {date: {startDate: dateData.weekstart, endDate: dateData.weekEnd}, filter: selectedType.code, value: "All"}
                setSelectedDate({startDate: dateData.weekstart, endDate: dateData.weekEnd})
                getMisInventoryStats(data)
                getMisPerformance(data,selectedUser,selectedType)
                misUsageStats(data,selectedUser,selectedType)

            break;

            case 'this_month':

                var data = {date: {startDate: dateData.monthStart, endDate: dateData.endThisMonth}, filter: selectedType.code, value: "All"}
                setSelectedDate({startDate: dateData.monthStart, endDate: dateData.endThisMonth})
                getMisInventoryStats(data)
                getMisPerformance(data,selectedUser,selectedType)
                misUsageStats(data,selectedUser,selectedType)

            break;

            case 'last_month':

                var data = {date: {startDate: dateData.lastStart, endDate: dateData.lastend}, filter: selectedType.code, value: "All"}
                setSelectedDate({startDate: dateData.lastStart, endDate: dateData.lastend})
                getMisInventoryStats(data)
                getMisPerformance(data,selectedUser,selectedType)
                misUsageStats(data,selectedUser,selectedType)

            break;

            case 'custom':

                setCustomDialog(true)

            break;

            default:
                break;
        }
    }

    const handleSubmit = async(e) =>{
        e.preventDefault();
        const start = e.target.start_date.value;
        const end = e.target.end_date.value;
        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        let cust_start = new Date(startt).toISOString();
        let cust_end = new Date(endd).toISOString();
        const data = {date: {startDate: cust_start, endDate: cust_end}, filter: selectedType.code, value: "All"}
        getMisInventoryStats(data)
        getMisPerformance(data,selectedUser,selectedType)
        misUsageStats(data,selectedUser,selectedType)
        setCustomDialog(false)
    }


    const leftToolbarTemplate = () => (
        <>
            <div className="p-inputgroup">
                <Button className='custom-group-btn' label="ACCOUNT FILTER" />
                <Dropdown value={selectedUser} onChange={changeUser} options={userData} optionLabel="display" placeholder="Select Filter" filter
                    className="p-m-0 multiselect-custom" />
            </div>

            <div className="p-inputgroup p-ml-2">
                <Button className='custom-group-btn' label="TYPE" />
                <Dropdown value={selectedType} onChange={changeType} options={accountType} optionLabel="name" placeholder="Select Filter" filter
                    className="p-m-0 multiselect-custom" />
            </div>
        </>
    )

    const rightToolbarTemplate = () => (
        <>
            <div className="p-inputgroup ">
                <Button className='custom-group-btn' label="DURATION" />
                <Dropdown value={selectedDuration} onChange={changeDuration} options={TimeSelectValues} optionLabel="name" placeholder="Select Filter" filter
                    className="p-m-0 multiselect-custom" />
            </div>
        </>
    ) 

    return (

        <>
        
        <div className="p-grid crud-demo">
            <div className="p-col-12">
            <div className="card">
                <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <div className="p-grid">
                    <KeyPeformanceStat header="Key Accounts Manager" value={selectedUser?.display ? selectedUser?.display : '-'} icon="pi pi-user" loading={initialLoading}/>
                    <KeyPeformanceStat header="Cup Consumption Super-Star" value={accWiseConData.length > 0 ? accWiseConData[0].name : '-'} icon="pi pi-star" loading={cupLoading}/>
                    <KeyPeformanceStat header="Target Performance Super-Star" value={accWiseTargetData.length > 0 ? accWiseTargetData[0].name : '-'} icon="pi pi-star" loading={targetLoading}/>
                    <KeyPeformanceStat header="Inventory Super-Star" value={inventoryPerData.length > 0 ? inventoryPerData[0].name : '-' } icon="pi pi-star" loading={inventoryLoading}/>
                </div>

                <TabView>
                    <TabPanel header="Account Wise Consumption Performance Details">
                        <AccWiseCupChart accWiseConData={accWiseConData}/>
                    </TabPanel>
                    <TabPanel header="Account Wise Target Performance Details">
                        <AccWiseTargetChart accWiseTargetData={accWiseTargetData}/>
                    </TabPanel>
                    <TabPanel header="Account Wise Inventory Performance Details">
                        <AccWiseInventoryChart inventoryPerData={inventoryPerData}/>
                    </TabPanel>
                </TabView>

            </div>
            </div>
        </div>

        <Dialog visible={customDialog} style={{ width: '550px' }} header="Custom Filter"  onHide={()=>{setCustomDialog(false)}} modal className="p-fluid">
            <form onSubmit={handleSubmit}>
                <div className='p-grid'>
                    <div className='p-col-6'>
                            <label htmlFor="from">From</label>
                            <InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => {setCustomStart(e.value) } } required autoFocus/>
                    </div>
                    <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' value={customEnd} onChange={(e) => {setCustomEnd(e.value)}} required/>
                    </div>
                </div>
                <div className="p-dialog-footer p-mt-4">
                    <button className="p-button p-button-danger" onClick={()=>{setCustomDialog(false)}}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
                    <button className="p-button p-button-success" type="submit"><span className="p-button-icon p-c pi pi-check p-button-icon-left"></span><span className="p-button-label p-c">Submit</span><span className="p-ink"></span></button>
                </div>
            </form>
        </Dialog>

        </>

    )
}



