import React, { useContext } from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { AllAnalysisChart } from "./AllAnalysisChart";
import { AllComparisonChart } from "./AllComparisonChart";
import { OverallPerformance } from "./OverallPerformance";
import { KeyAccountPerformance } from "./KeyAccountPerformance";
import { KeyAccountAnalytics } from "./KeyAccountAnalytics";
import { permissionContext } from "../../AppWrapper";
import { PerformanceByLocation } from "./PerformanceByLocation";

export const KAMPerformance = () => {

    const rolePermissions = useContext(permissionContext);

    return(
    
        <div className="p-grid crud-demo">
            <div className="p-col-12">
            <div className="card">    
                <TabView>
                
                    <TabPanel header="KAM Analytics">
                        <KeyAccountAnalytics/>
                    </TabPanel>

                    {
                        rolePermissions?.PerformanceAllKAMTab ?
                        <TabPanel header="KAM/Sales Manager Performance">
                            <KeyAccountPerformance/>
                        </TabPanel>
                        :
                        <TabPanel header=""></TabPanel>
                    }

                    
                </TabView>
            </div>
            </div>
        </div>
        
    )
}