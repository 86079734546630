import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { TabView, TabPanel } from 'primereact/tabview';
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Chip } from "primereact/chip";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
// Common
import { exportReport } from "../Common/CommonFunctions";
import { CommonBodyForTable, commonCloseDialogFooter } from "../Common/CommonComponents";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ==========================================================================================================
// ==========================================================================================================

export const BeverageInventory = (props) => {

        const [globalFilter, setGlobalFilter] = useState(null);
        const [globalFilter1, setGlobalFilter1] = useState(null);
        const [expandedRows, setExpandedRows] = useState([]);
        const [packetDialog, setPacketDialog] = useState(false);
        const [selectedInAcc,setSelectedInAcc] = useState(null);
        const [custPacketInfo,setCustPacketInfo] = useState([]);
        const [invetoryData,setInventoryData] = useState();
        const [allInventoryData,setAllInventoryData] = useState([]);
        const [selectedInventoryData,setSelectedInventoryData] = useState();
        const [mchWiseInventory,setMchWiseInventory] = useState([]);
        const dt = useRef(null);
        const toast = useRef(null);

        useEffect(()=>{
            getDataFunction(props.invetoryData)
        },[props.invetoryData])

        const getDataFunction = (data) =>{
            const arr = []
            data?.map((x)=>{
                const finalData = {...x,machineCount:x?.machines?.length,totalDispatched:(x.packets + x.loaded_packets +x.used_packets),
                totalDispatchedCups:(x.total_cups + x.loaded_cups + x.used_cups),
                totalMchDisPackets:(x.pac_delivered + x.pac_loaded + x.pac_used),
                totalMchDisCups:(x.delivered + x.loaded + x.used)
                }
                arr.push(finalData)
            })
            setAllInventoryData(arr)
        }

        const srBodyTemplate = (rowData,props) => (<><span className="p-column-title">Sr.No</span>{props.rowIndex + 1}</>)
        const accountNameTemplate = rowData => (<><span className="p-column-title">Account Name</span><p className="p-my-3">{rowData.account}</p></>)
        const viewCvesInventory = rowData => (<><span className="p-column-title">Action</span><Button icon="pi pi-eye" title="View Details" className="p-button-rounded p-button-success" onClick={()=>{openInventoryDialog(rowData)}}/></>) 

        const dispatchedBodyTemplate = data => {
            const totalDispatched = (data.packets + data.loaded_packets +data.used_packets);
            return (<><span className="p-column-title">Total Dispatched Material</span>{totalDispatched}</>)
        };

        const specialBodyTemplate = (data, props) => {            
            const machine = typeof(data?._id) == 'string'?data?._id:'-'
            return ( <> <span className="p-column-title">{props.header}</span>{machine}</>)
        }

        const convertFormat = (data) =>{
            const arr = [];
            data.map((x)=>{
                const date = x.dates.assigned ? new Date(x.dates.assigned)?.toISOString().substring(0, 10) : '-';
                arr.push({...x,assignedDate:date})
            })       
            return arr;  
        }

        const openInventoryDialog = async(rowData) => {
            setPacketDialog(true)

            if(props.selectedMach){
                setSelectedInAcc(rowData)
                const response = await apiServices.getMaterialByCustomer1({customerid: props.defaultMachine.customer,subid:props.defaultMachine.subid})
                const data = convertFormat(response.data.mate)
                setCustPacketInfo(data)
            }else{
                setSelectedInAcc(rowData)
                const response = await apiServices.getMaterialByCustomer({customerid: rowData?._id?.cust})
                const data = convertFormat(response.data.mate)
                setCustPacketInfo(data)
            }

            var response = await apiServices.getInventoryData({customerid: rowData?._id?.cust,userType: initialValue.userType});
            setInventoryData(response.data.data)
            setSelectedInventoryData(response.data.data[0])
            
            if(response.data.data[0]){
                const response1 = await apiServices.getMaterialByCustomer1({customerid: rowData?._id?.cust,subid: response.data.data[0].subid})
                console.log(response1);
                const data = convertFormat(response1.data.mate)
                setMchWiseInventory(data);
            }
        };
        
        const hidePacketDialog = () => {
            setPacketDialog(false);
            setMchWiseInventory([])
        };

        const changeCVES = async(e) =>{
            setSelectedInventoryData(e.target.value)
            const response1 = await apiServices.getMaterialByCustomer1({customerid: selectedInAcc?._id?.cust ,subid: e.target.value?.subid})
            const data = convertFormat(response1.data.mate)
            setMchWiseInventory(data);
        }

        const exportCSV = () =>{ exportReport(dt)}    
        const packetDialogFooter = commonCloseDialogFooter('Cancel', hidePacketDialog)

        const header = (
            <div className="table-header">
                <span className="p-input-icon-right"></span>
                <span className="p-input-icon-left">
                    <div className="p-inputgroup">
                        <Button className='custom-group-btn' icon="pi pi-search" />
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                        {/* <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV}/> */}
                    </div>
                </span>
            </div>
        );


        const header1 = (
            <div className="table-header">
                <span className="p-input-icon-right"></span>
                <span className="p-input-icon-left">
                    <div className="p-inputgroup">
                        <Button className='custom-group-btn' icon="pi pi-search" />
                        <InputText type="search" onInput={(e) => setGlobalFilter1(e.target.value)} placeholder="Search..." />
                        {/* <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4'  onClick={exportCSV}/> */}
                    </div>
                </span>
            </div>
        );

        
        const leftToolbarTemplate = () => (
            <>
                {/* <Chip label={`Total Dispatched Material : ${(selectedInventoryData?selectedInventoryData.pac_loaded:0 + selectedInventoryData ? selectedInventoryData.pac_delivered:0 + selectedInventoryData?selectedInventoryData.pac_used:0)}`} icon="pi pi-angle-double-right" className="p-mr-1" /> */}
                {/* <Chip label={`Loaded : ${selectedInventoryData?selectedInventoryData.pac_loaded:0}`} icon="pi pi-angle-double-right" className="p-mr-1" /> */}
                {/* <Chip label={`Delivered : ${selectedInventoryData?selectedInventoryData.pac_delivered:0}`} icon="pi pi-angle-double-right" className="p-mr-1" /> */}
                <Chip label={`Used : ${selectedInventoryData?selectedInventoryData.pac_used:0}`} icon="pi pi-angle-double-right" className="p-mr-1" />
            </>
        )
         
        const leftToolbarTemplate1 = () => (
            <>
                <Chip label={`Total Dispatched Material : ${selectedInAcc?.pac_loaded + selectedInAcc?.pac_delivered + selectedInAcc?.pac_used}`} icon="pi pi-angle-double-right"  className="p-mr-1"/>
                <Chip label={`Loaded : ${selectedInAcc?.pac_loaded} `} icon="pi pi-angle-double-right" className="p-mr-1"/>
                <Chip label={`Delivered : ${selectedInAcc?.pac_delivered}`} icon="pi pi-angle-double-right" className="p-mr-1" />
                <Chip label={`Used : ${selectedInAcc?.pac_used}`}  icon="pi pi-angle-double-right" className="p-mr-1"/>
            </>
        ) 
        
        const leftToolbarTemplate2 = () =>  (
            <>
                <Chip label={`Total Dispatched Material : ${selectedInAcc?.loaded_packets + selectedInAcc?.packets + selectedInAcc?.used_packets}`} icon="pi pi-angle-double-right"  className="p-mr-2 p-mb-2"/>
                {/* <Chip label={`Loaded : ${selectedInAcc?.loaded_packets} `} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2"/> */}
                {/* <Chip label={`Delivered : ${selectedInAcc?.packets}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" /> */}
                {/* <Chip label={`Used : ${selectedInAcc?.used_packets}`}  icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2"/> */}
            </>
        )

        const rightToolbarTemplate = () => (
            <>
                <div className="p-col-12">
                    <div className="p-inputgroup">
                        <Button className="custom-group-btn" label="CVES" />
                        <Dropdown value={selectedInventoryData} onChange={changeCVES}  options={invetoryData} optionLabel="_id" placeholder="Select CVES" filter className="p-mr-2 multiselect-custom" />
                    </div>
                </div>
            </>
        ) 
        
        const footer = `In total there are ${allInventoryData.length?allInventoryData.length:0} inventories.`;

        return (
        <>
            {
                !props.selectedMach &&

                <div className="p-grid table-demo">
                    <div className="p-col-12">
                    <div className="card">

                    {
                        (props.selectedFilterItems.code == 'packets') &&

                        <div className="datatable-rowexpansion-demo">
                            <Toast ref={toast} />
                            <DataTable ref={dt} paginator rows={10} rowsPerPageOptions={[5, 10, 25]} value={allInventoryData?allInventoryData:null}  
                            className="p-datatable-customers datatable-responsive" dataKey="_id.cust"  responsiveLayout="scroll" loading={props.loading1} header={header} footer={footer} 
                            globalFilter={globalFilter} exportFilename={`Inventory Details (Packet-wise)`}>
                                <Column headerStyle={{ width: "3rem" }} />
                                <Column field="id" header="Sr.No."  body={srBodyTemplate} exportable={false}></Column>
                                <Column field="account" header="Account Name" sortable body={accountNameTemplate}></Column>
                                <Column field="packets" header="Delivered"  body={CommonBodyForTable} ></Column>
                                <Column field="loaded_packets" header="Loaded"  body={CommonBodyForTable} ></Column>
                                <Column field="used_packets" header="Used"  body={CommonBodyForTable} ></Column>
                                <Column field="totalDispatched" header="Total Dispatched Material"  body={dispatchedBodyTemplate}></Column>
                                <Column field="machineCount" header="Total CVES"  body={CommonBodyForTable} ></Column>
                                <Column header="Action" body={viewCvesInventory} ></Column>
                            </DataTable>
                        </div>
                    }

                    {
                        (props.selectedFilterItems.code == 'cups') &&

                        <div className="datatable-rowexpansion-demo">
                            <Toast ref={toast} />
                            
                            <DataTable ref={dt} paginator rows={10} rowsPerPageOptions={[5, 10, 25]} value={allInventoryData}  
                            className="p-datatable-customers datatable-responsive" dataKey="_id"  onRowToggle={(e) => setExpandedRows(e.data)} responsiveLayout="scroll" header={header} footer={footer}
                            exportFilename={`Inventory Details (Cup-wise)`} loading={props.loading1} globalFilter={globalFilter} >
                                <Column  headerStyle={{ width: "3rem" }} />
                                <Column field="id" header="Sr.No. (C)" body={srBodyTemplate} exportable={false}></Column>
                                <Column field="account" header="Account Name" sortable body={accountNameTemplate}></Column>
                                <Column field="total_cups" header="Delivered"  body={CommonBodyForTable} ></Column>
                                <Column field="loaded_cups" header="Loaded"  body={CommonBodyForTable} ></Column>
                                <Column field="used_cups" header="Used"  body={CommonBodyForTable} ></Column>
                                <Column field="totalDispatchedCups" header="Total Dispatched Material"  body={CommonBodyForTable}></Column>
                                <Column field="machineCount" header="Total CVES"  body={CommonBodyForTable} ></Column>
                                {/* <Column header="Action" body={viewCvesInventory} ></Column> */}
                            </DataTable>
                        </div>
                    }

                    </div>
                    </div>
                </div>

            }


            { 
                props.selectedMach &&
            
                <div className="p-grid table-demo">
                    <div className="p-col-12">
                    <div className="card">
                    {
                        (props.selectedFilterItems.code == 'packets') &&
                        <DataTable ref={dt} value={allInventoryData} paginator className="p-datatable-customers" rows={10} dataKey="_id" rowHover  emptyMessage="No customers found." header={header}
                        exportFilename={`Packet-wise Inventory Details (${props.defaultMachine?.id})`} globalFilter={globalFilter}>
                            <Column field="id" header="Sr.No."  body={srBodyTemplate} exportable={false} ></Column>
                            <Column field="subid" header="Subscription Id" sortable body={CommonBodyForTable} ></Column>
                            <Column field="xx" header="CVES Id" sortable body={specialBodyTemplate} exportable={false}></Column>
                            <Column field="pac_delivered" header="Delivered" sortable body={CommonBodyForTable} ></Column>
                            <Column field="pac_loaded" header="Loaded" sortable body={CommonBodyForTable} ></Column>
                            <Column field="pac_used" header="Used" sortable body={CommonBodyForTable} ></Column>
                            <Column field="totalMchDisPackets" header="Total Dispatched Material" sortable body={CommonBodyForTable} ></Column>
                            <Column header="Action" body={viewCvesInventory} exportable={false} ></Column>
                        </DataTable>
                    }

                    {
                        (props.selectedFilterItems.code == 'cups') &&
                        <DataTable ref={dt} value={allInventoryData} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover  emptyMessage="No customers found." header={header} 
                        exportFilename={`Cup-wise Inventory Details (${props.defaultMachine?.id})`} globalFilter={globalFilter}>
                            <Column field="id" header="Sr.No."  body={srBodyTemplate} exportable={false}></Column>
                            <Column field="subid" header="Subscription Id" sortable body={CommonBodyForTable} ></Column>
                            <Column field="xx" header="CVES Id" sortable body={specialBodyTemplate} exportable={false}></Column>
                            <Column field="delivered" header="Delivered" sortable body={CommonBodyForTable} ></Column>
                            <Column field="loaded" header="Loaded" sortable body={CommonBodyForTable} ></Column>
                            <Column field="used" header="Used" sortable body={CommonBodyForTable} ></Column>
                            <Column field="totalMchDisCups" header="Total Dispatched Material" sortable body={CommonBodyForTable} ></Column>
                        </DataTable>
                    }
                    </div>
                    </div>
                </div>
            }

            <Dialog visible={packetDialog} style={{ width: "950px" }} header={`Packet Information (${props.selectedMach?selectedInAcc?._id:selectedInAcc?.account})`} modal className="p-fluid" footer={packetDialogFooter} onHide={hidePacketDialog} maximizable={true} blockScroll={true}>  
                <div className="card">
                    <TabView >
                        <TabPanel header="ALL">
                                { props.selectedMach && <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate1} ></Toolbar> }
                                { !props.selectedMach && <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate2} ></Toolbar> }

                                <div className="p-grid table-demo p-m-2">
                                    <DataTable ref={dt} value={custPacketInfo} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover  emptyMessage="No Data found." header={header1} 
                                    exportFilename={`Packet Information`} globalFilter={globalFilter1}>
                                        <Column field="id" header="Sr.No."  body={srBodyTemplate} exportable={false}></Column>
                                        <Column field="materialName" header="Packet Name" sortable body={CommonBodyForTable} ></Column>
                                        <Column field="barcode" header="Barcode" sortable body={CommonBodyForTable} ></Column>
                                        <Column field="stage" header="Stage" sortable body={CommonBodyForTable} ></Column>
                                        <Column field="assignedDate" header="Date" sortable body={CommonBodyForTable} ></Column>
                                    </DataTable>
                                </div>
                        </TabPanel>
                        <TabPanel header="CVES WISE">
                            {mchWiseInventory?.length > 0 ? <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar> : null}
                            <div className="p-grid table-demo p-m-2">
                                <DataTable ref={dt} value={mchWiseInventory} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover  emptyMessage="No Inventory Data found." header={mchWiseInventory.length > 0 ? header1 : null} 
                                globalFilter={globalFilter1} exportFilename={`Machine-Wise Inventory (${selectedInventoryData?._id})`}>
                                    <Column field="id" header="Sr.No."  body={srBodyTemplate} exportable={false}></Column>
                                    <Column field="materialName" header="Packet Name" sortable body={CommonBodyForTable} ></Column>
                                    <Column field="barcode" header="Barcode" sortable body={CommonBodyForTable} ></Column>
                                    <Column field="stage" header="Stage" sortable body={CommonBodyForTable} ></Column>
                                    <Column field="assignedDate" header="Date" sortable body={CommonBodyForTable} ></Column>
                                </DataTable>
                            </div>

                        </TabPanel>
                    </TabView>
                </div>
            </Dialog>
        </>
    )

}