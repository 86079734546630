import React, { useState, useEffect, useRef } from "react";
// import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
// Common
import { inventoryFilterItems, dateFilterItems, viewFilterItems } from "./Constants";
// Components
import { InventoryStats } from "./InventoryStats";
import { BeverageInventory } from "./BeverageInventory";
// import { InventoryMaterialStatus } from "./InventoryMaterialStatus";
// import { AccountMaterialDetails } from "./AccountMaterialDetails";
// Service
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ==========================================================================================================
// ==========================================================================================================

export const BevInventoryTop = () => {

	const [loading1, setLoading1] = useState(true);
	const [invetoryData, setInvetoryData] = useState([]);
	const [allMachines, setAllMachines] = useState();
	const [defaultMachine, setDefaultMachine] = useState([]);
	const [inventoryStat, setInventoryStat] = useState(null);
	const [selectedMach, setSelectedMach] = useState(false);
	const [selectedFilterItems, setSelectedFilterItems] = useState({ name: 'Packets', code: 'packets' });
	const [selectedDateFilter, setSelectedDateFilter] = useState({ name: 'Till Date', code: 'till_date' });
	const [selectedInventory, setSelectedInventory] = useState({ name: "Total Inventory", code: "total_inventory" });
	const [customFilterDialog, setCustomFilterDialog] = useState(false);
	const [customStart, setCustomStart] = useState('');
	const [customEnd, setCustomEnd] = useState('');
	const [selectedDate, setSelectedDate] = useState(null);

	useEffect(() => {
		getPostInvoice({ userType: initialValue.userType, userid: initialValue.userid }, false, null);
		getMachine();
	}, []);

	const selectedMachine = async (e) => {
		setLoading1(true)
		setDefaultMachine(e.value)
		if (e.value.displayLabel == 'All') {
			setSelectedMach(false)
			selectedDate ? getPostInvoice({ date: selectedDate, userType: initialValue.userType, userid: initialValue.userid }, false, null) : getPostInvoice({ userType: initialValue.userType, userid: initialValue.userid }, false, null)
		} else {
			console.log("Machine Selected");
			setSelectedMach(true)
			selectedDate ? getPostInvoice({ date: selectedDate, customerid: e.value.customer, userType: initialValue.userType }, true, e.value) : getPostInvoice({ customerid: e.value.customer, userType: initialValue.userType }, true, e.value)
		}
	};

	const changeViewItem = (e) => {
		setSelectedFilterItems(e.target.value)
	}

	const changeDates = (e) => {
		setSelectedDateFilter(e.target.value)
		const currdate = new Date()
		console.log(selectedMach)

		switch (e.target.value.code) {

			case 'till_date':
				setSelectedDate(null)
				const data1 = { userType: initialValue.userType, userid: initialValue.userid }
				getPostInvoice(data1, false, null);
				break;

			case 'today':
				const startToday = new Date(currdate.setDate(currdate.getDate())).setHours(0, 0, 0, 0);
				const endToday = new Date(currdate.setDate(currdate.getDate())).setHours(23, 59, 59, 0);
				const date = { startDate: new Date(startToday).toISOString(), endDate: new Date(endToday).toISOString() };
				setSelectedDate(date)
				selectedMach ? getPostInvoice({ date: date, customerid: defaultMachine.customer, userType: initialValue.userType }, true, defaultMachine) : getPostInvoice({ date: date, userType: initialValue.userType, userid: initialValue.userid }, false, null)
				break;

			case 'yesterday':
				const Yestmilisecond1 = new Date(currdate.setDate(currdate.getDate() - 1)).setHours(0, 0, 0, 0);
				const Yestmilisecond2 = new Date(currdate.setDate(currdate.getDate())).setHours(23, 59, 59, 0);
				const date1 = { startDate: new Date(Yestmilisecond1), endDate: new Date(Yestmilisecond2) }
				setSelectedDate(date1)
				selectedMach ? getPostInvoice({ date: date1, customerid: defaultMachine.customer, userType: initialValue.userType }, true, defaultMachine) : getPostInvoice({ date: date1, userType: initialValue.userType, userid: initialValue.userid }, false, null)
				break;

			case 'this_week':
				const firstWeekday = new Date(currdate.setDate(currdate.getDate() - currdate.getDay())).setHours(0, 0, 0, 0);
				const lastWeekday = new Date(currdate.setDate(currdate.getDate() - currdate.getDay() + 6)).setHours(23, 59, 59, 0);
				const date2 = { startDate: new Date(firstWeekday).toISOString(), endDate: new Date(lastWeekday).toISOString() }
				setSelectedDate(date2)
				selectedMach ? getPostInvoice({ date: date2, customerid: defaultMachine.customer, userType: initialValue.userType }, true, defaultMachine) : getPostInvoice({ date: date2, userType: initialValue.userType, userid: initialValue.userid }, false, null)
				break;

			case 'this_month':
				const firstThisMonthDay = new Date(currdate.getFullYear(), currdate.getMonth(), 1).setHours(0, 0, 0, 0);
				const lastThisMonthDay = new Date(currdate.getFullYear(), currdate.getMonth() + 1, 0).setHours(23, 59, 59, 0);
				const date3 = { startDate: new Date(firstThisMonthDay).toISOString(), endDate: new Date(lastThisMonthDay).toISOString() }
				setSelectedDate(date3)
				selectedMach ? getPostInvoice({ date: date3, customerid: defaultMachine.customer, userType: initialValue.userType }, true, defaultMachine) : getPostInvoice({ date: date3, userType: initialValue.userType, userid: initialValue.userid }, false, null)
				break;

			case 'last_month':
				const lastMonthStart = new Date(currdate.getFullYear(), currdate.getMonth() - 1, 1).setHours(0, 0, 0, 0);
				const lastMonthEnd = new Date(currdate.getFullYear(), currdate.getMonth(), 0).setHours(23, 59, 59, 0);
				const date4 = { startDate: new Date(lastMonthStart).toISOString(), endDate: new Date(lastMonthEnd).toISOString() }
				setSelectedDate(date4)
				selectedMach ? getPostInvoice({ date: date4, customerid: defaultMachine.customer, userType: initialValue.userType }, true, defaultMachine) : getPostInvoice({ date: date4, userType: initialValue.userType, userid: initialValue.userid }, false, null)
				break;

			case 'custom':
				setCustomFilterDialog(true)
				break;

			default:
			// Default
		}
	}

	const handleCustomSubmit = (e) => {
		e.preventDefault()
		const start = e.target.start_date.value;
		const end = e.target.end_date.value;
		const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
		const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
		let date = { startDate: new Date(startt).toISOString(), endDate: new Date(endd).toISOString() };
		setSelectedDate(date)
		selectedMach ? getPostInvoice({ date: date, customerid: defaultMachine.customer, userType: initialValue.userType }, true, defaultMachine) : getPostInvoice({ date: date, userType: initialValue.userType, userid: initialValue.userid }, false, null)
		setCustomFilterDialog(false)
	}

	const changeInvetory = (e) => {
		setSelectedInventory(e.target.value)
	}

	const getPostInvoice = async (data1, status, machine) => {
		setLoading1(true)
		const response = await apiServices.getInventoryData(data1);
		const response1 = await apiServices.getUsersByType({ userType: 'customer' });
		const userResponse = await apiServices.getUsersByCTVM();


		for (var j = 0; j < response1?.data?.data?.length; j++) {
			for (var k = 0; k < response?.data?.data?.length; k++) {
				if (response.data.data[k]?._id?.cust == response1.data.data[j].userid) {
					response.data.data[k].username = response1.data.data[j].name;
					response.data.data[k].account = response1.data.data[j]?.billing_details?.company;
				}
			}
		}

		const trimmeddata = response?.data?.data.sort((a, b) => {
			if (a.account?.trim() < b.account?.trim())
				return -1;
			if (a.account?.trim() > b?.account?.trim())
				return 1;
			return 0;
		});


		let data;

		if (initialValue.userType == 'accountmanager') {

			const accdata = [];
			userResponse.data.data.forEach(x => {
				trimmeddata.forEach(y => {
					if (x.userid == y._id.cust) {
						accdata.push(y)
					}
				});
			});

			data = accdata;

		} else {

			data = trimmeddata;
		}

		if (initialValue.userType == 'owner') {
			setInvetoryData(data);
		} else if (initialValue.userType == 'customer') {
			var custData = data.filter((x) => { if (x?._id?.cust == initialValue.userid) { return x; } })
			setInvetoryData(custData);
		} else if (initialValue.userType == 'marketmanager' || initialValue.userType == 'accountmanager') {
			setInvetoryData(data);
		}

		setLoading1(false)
		let uppercards = { totalDispatchedCups: 0, totalDispatchedPackets: 0, totalDeliveredCups: 0, totalDeliveredPackets: 0, totalLoadedCups: 0, totalLoadedPackets: 0, totalUsedCups: 0, totalUsedPackets: 0 }

		if (status) {

			const filData = data.filter((val) => { return val._id == machine.id })
			setInvetoryData(filData);

			uppercards.totalLoadedCups = uppercards.totalLoadedCups + filData[0]?.loaded ? filData[0]?.loaded : 0
			uppercards.totalLoadedPackets = uppercards.totalLoadedPackets + filData[0]?.pac_loaded ? filData[0]?.pac_loaded : 0;
			uppercards.totalUsedCups = uppercards.totalUsedCups + filData[0]?.used ? filData[0]?.used : 0;
			uppercards.totalUsedPackets = uppercards.totalUsedPackets + filData[0]?.pac_used ? filData[0]?.pac_used : 0
			uppercards.totalDeliveredCups = uppercards.totalDeliveredCups + filData[0]?.delivered ? filData[0]?.delivered : 0;
			uppercards.totalDeliveredPackets = uppercards.totalDeliveredPackets + filData[0]?.pac_delivered ? filData[0]?.pac_delivered : 0;
			uppercards.totalDispatchedCups = uppercards.totalLoadedCups + uppercards.totalUsedCups + uppercards.totalDeliveredCups;
			uppercards.totalDispatchedPackets = (filData[0]?.pac_used ? filData[0]?.pac_used : 0) + (filData[0]?.pac_delivered ? filData[0]?.pac_delivered : 0) + (filData[0]?.pac_loaded ? filData[0]?.pac_loaded : 0);

		} else {

			for (var i = 0; i < data?.length; i++) {

				uppercards.totalLoadedCups = uppercards.totalLoadedCups + data[i].loaded_cups;
				uppercards.totalLoadedPackets = uppercards.totalLoadedPackets + data[i].loaded_packets;
				uppercards.totalUsedCups = uppercards.totalUsedCups + data[i].used_cups;
				uppercards.totalUsedPackets = uppercards.totalUsedPackets + data[i].used_packets;
				uppercards.totalDeliveredCups = uppercards.totalDeliveredCups + data[i].total_cups;
				uppercards.totalDeliveredPackets = uppercards.totalDeliveredPackets + data[i].packets;
				uppercards.totalDispatchedCups = uppercards.totalLoadedCups + uppercards.totalUsedCups + uppercards.totalDeliveredCups;
				uppercards.totalDispatchedPackets = uppercards.totalLoadedPackets + uppercards.totalUsedPackets + uppercards.totalDeliveredPackets;
			}
		}

		setInventoryStat(uppercards)
		setLoading1(false)

	};

	const getMachine = async () => {

		const response = await apiServices.findMachines();
		const response1 = await apiServices.getUserReduced();
		const userred = response1?.data?.data.map((x) => { return x.userid })
		let arr = []
		response?.data?.data?.map((x) => { if (userred.includes(x.customer)) { arr.push(x) } })
		const result = arr.filter((m) => { return m.siteName !== "" });

		var resultedMachine = result.map((x) => {
			const customer = x.customer
			const subid = x.subid ? x.subid : 'CIPL';
			const address = response1.data.data.find(o => o.userid === x.customer)
			return {
				...x,
				displayLabel: x.id + ' / ' + subid + ' / ' + address?.billing_details?.company,
				address: address?.addr
			}
		});

		resultedMachine.unshift({ displayLabel: 'All', customer: 'All' })
		setAllMachines(resultedMachine);
		setDefaultMachine(resultedMachine[0])
	}



	const leftToolbarTemplate = () => {
		return (
			<>
				<div className="p-grid">
					<div className="p-col-sm-6">
						<div className="p-inputgroup p-m-1" >
							<Button className="custom-group-btn" label="CVES" />
							<Dropdown value={defaultMachine} onChange={selectedMachine} options={allMachines} optionLabel="displayLabel" placeholder="Select CVES Id" filter className="p-mr-2 multiselect-custom" />
						</div>
					</div>

					{
						initialValue?.userType != 'customer' &&

						<div className="p-col-sm-6">
							<div className="p-inputgroup p-m-1" >
								<Button className="custom-group-btn" label="Duration" />
								<Dropdown value={selectedDateFilter} onChange={changeDates} options={dateFilterItems} optionLabel="name" placeholder="Select Date Filter" filter className="p-mr-2 multiselect-custom" />
							</div>
						</div>
					}
				</div>
			</>
		)
	};

	const rightToolbarTemplate = () => {
		return (
			<>
				<div className="p-grid">
					<div className="p-col-sm-6" >
						<div className="p-inputgroup p-m-1 ">
							<Button className="custom-group-btn" label="View" />
							<Dropdown value={selectedFilterItems} onChange={changeViewItem} options={viewFilterItems} optionLabel="name" placeholder="Select View Item" filter className="p-mr-2 multiselect-custom" />
						</div>
					</div>

					{
						initialValue?.userType != 'customer' &&
						<div className="p-col-sm-6">
							<div className="p-inputgroup p-m-1">
								<Button className="custom-group-btn" label="Inventory" />
								<Dropdown value={selectedInventory} onChange={changeInvetory} options={inventoryFilterItems} optionLabel="name" placeholder="Select Inventory Type" filter className="p-mr-2 multiselect-custom" />
							</div>
						</div>
					}

				</div>
			</>
		)
	};




	return (

		<>
			{
				!loading1 &&
				<div className="card">
					<Toolbar className="p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>
				</div>
			}



			{
				(selectedFilterItems.code == 'packets' && (initialValue.userType == 'owner' || initialValue.userType == 'marketmanager' || initialValue.userType == 'accountmanager')) &&
				<div className="card">
					<div className="p-grid">
						{
							selectedInventory.code == 'total_inventory' &&
							<>
								<div className="p-col-12 p-md-6">
									<InventoryStats header="Total Dispatched Material (Material which is dispatched from the Plant)" value={inventoryStat?.totalDispatchedPackets ? inventoryStat?.totalDispatchedPackets : '0'} icon="pi pi-send" loading1={loading1} />
								</div>
								<div className="p-col-12 p-md-6">
									<InventoryStats header="Delivered (Material which is at the technoman level)" value={inventoryStat?.totalDeliveredPackets ? inventoryStat?.totalDeliveredPackets : '0'} icon="pi pi-inbox" loading1={loading1} />
								</div>
								<div className="p-col-12 p-md-6">
									<InventoryStats header="Loaded (Material which is loaded on CVES)" value={inventoryStat?.totalLoadedPackets ? inventoryStat?.totalLoadedPackets : '0'} icon="pi pi-table" loading1={loading1} />
								</div>
								<div className="p-col-12 p-md-6">
									<InventoryStats header="Used (Material which is dispensed from the CVES)" value={inventoryStat?.totalUsedPackets ? inventoryStat?.totalUsedPackets : '0'} icon="pi pi-tags" loading1={loading1} />
								</div>
							</>
						}

						{
							(selectedInventory.code == 'remaining_inventory' && (initialValue.userType == 'owner' || initialValue.userType == 'marketmanager')) &&
							<>
								<div className="p-col-12 p-md-6">
									<InventoryStats header="Delivered (Material which is at the technoman level)" value={inventoryStat?.totalDeliveredPackets ? inventoryStat?.totalDeliveredPackets : '0'} icon="pi pi-inbox" loading1={loading1} />
								</div>
								<div className="p-col-12 p-md-6">
									<InventoryStats header="Loaded (Material which is loaded on CVES)" value={inventoryStat?.totalLoadedPackets ? inventoryStat?.totalLoadedPackets : '0'} icon="pi pi-table" loading1={loading1} />
								</div>
							</>
						}
					</div>
				</div>
			}


			{
				(selectedFilterItems.code == 'cups' && (initialValue.userType == 'owner' || initialValue.userType == 'marketmanager' || initialValue.userType == 'accountmanager')) &&
				<div className="p-grid">
					{


						selectedInventory.code == 'total_inventory' &&
						<>
							<div className="p-col-12 p-md-6">
								<InventoryStats header="Total Dispatched Material (Material which is dispatched from the Plant)" value={inventoryStat?.totalDispatchedCups ? inventoryStat?.totalDispatchedCups : '0'} icon="pi pi-send" loading1={loading1} />
							</div>
							<div className="p-col-12 p-md-6">
								<InventoryStats header="Delivered (Material which is at the technoman level)" value={inventoryStat?.totalDeliveredCups ? inventoryStat?.totalDeliveredCups : '0'} icon="pi pi-inbox" loading1={loading1} />
							</div>
							<div className="p-col-12 p-md-6">
								<InventoryStats header="Loaded (Material which is loaded on CVES)" value={inventoryStat?.totalLoadedCups ? inventoryStat?.totalLoadedCups : '0'} icon="pi pi-table" loading1={loading1} />
							</div>
							<div className="p-col-12 p-md-6">
								<InventoryStats header="Used (Material which is dispensed from the CVES)" value={inventoryStat?.totalUsedCups ? inventoryStat?.totalUsedCups : '0'} icon="pi pi-tags" loading1={loading1} />
							</div>
						</>
					}

					{
						(selectedInventory.code == 'remaining_inventory' && (initialValue.userType == 'owner' || initialValue.userType == 'marketmanager' || initialValue.userType == 'accountmanager')) &&
						<>
							<div className="p-col-12 p-md-6">
								<InventoryStats header="Delivered (Material which is at the technoman level)" value={inventoryStat?.totalDeliveredCups ? inventoryStat?.totalDeliveredCups : '0'} icon="pi pi-inbox" loading1={loading1} />
							</div>
							<div className="p-col-12 p-md-6">
								<InventoryStats header="Loaded (Material which is loaded on CVES)" value={inventoryStat?.totalLoadedCups ? inventoryStat?.totalLoadedCups : '0'} icon="pi pi-table" loading1={loading1} />
							</div>
						</>
					}

				</div>
			}

			<Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter" onHide={() => { setCustomFilterDialog(false) }} modal className="p-fluid">
				<form onSubmit={handleCustomSubmit}>
					<div className='p-grid'>
						<div className='p-col-6'>
							<label htmlFor="from">From</label>
							<InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => { setCustomStart(e.value) }} required autoFocus />
						</div>
						<div className='p-col-6'>
							<label htmlFor="to">To</label>
							<InputText id="to" type="date" name='end_date' value={customEnd} onChange={(e) => { setCustomEnd(e.value) }} required />
						</div>
					</div>
					<div className="p-dialog-footer p-mt-4">
						<button className="p-button p-component p-button-danger" onClick={() => { setCustomFilterDialog(false) }}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
						<button className="p-button p-component p-button-success" type="submit"><span className="p-button-icon p-c pi pi-check p-button-icon-left"></span><span className="p-button-label p-c">Submit</span><span className="p-ink"></span></button>
					</div>
				</form>
			</Dialog>


			<BeverageInventory allMachines={allMachines} selectedFilterItems={selectedFilterItems} selectedMach={selectedMach} defaultMachine={defaultMachine} invetoryData={invetoryData} loading1={loading1} />

			{/* <div className="card">
			<TabView >
				<TabPanel header="BEVERAGE INVENTORY AT ACCOUNT FACILITY">
					<BeverageInventory allMachines={allMachines} selectedFilterItems={selectedFilterItems} selectedMach={selectedMach} defaultMachine={defaultMachine} invetoryData={invetoryData} loading1={loading1}/>
				</TabPanel>
				<TabPanel header="MATERIAL DELIVERY STATUS">
					<InventoryMaterialStatus/>
				</TabPanel>
				<TabPanel header="MATERIAL DETAILS">
					<AccountMaterialDetails/>
				</TabPanel>
			</TabView>
		</div>  */}

		</>
	)
}
