
import React, { useState, useEffect, useRef, useContext, version } from 'react';
import { Accordion, AccordionTab } from "primereact/accordion"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { InputSwitch } from "primereact/inputswitch"
import { Dialog } from "primereact/dialog";
import { CommonForDetails } from "../CRM/Leads";
import { TableWrapper } from "../../utilComponents/DataTableWrapper";
import { InputNumber } from "primereact/inputnumber";
import { Column } from 'primereact/column';
import { DataTable } from "primereact/datatable";
import { srBodyTemplate, CommonBodyForTable, commonDialogFooter, commonFormDialogFooter, commonCloseDialogFooter, DateBodyForTable } from '../Common/CommonComponents';
import { useHistory } from 'react-router-dom';
import { FormWrapper, FullFormWrapper } from '../../utilComponents/Forms';
import { Toast } from 'primereact/toast';
import { failureToast, successToast } from '../Common/CommonFunctions';



// import canB
import ApiServices from "../../service/api/ApiServices";
import { permissionContext } from '../../AppWrapper';


const apiServices = new ApiServices;
export const OverviewActions = ({ selectedMachine }) => {

    let history = useHistory();
    const toast = useRef(null);
    const rolePermissions = useContext(permissionContext);
    const [barcodeDialog, setBarcodeDialog] = useState(false);
    const [reactivateDialog, setReactivateDialog] = useState(false);
    const [exitAppDialog, setExitAppDialog] = useState(false);
    const [doorUnlockDialog, setDoorUnlockDialog] = useState(false);
    const [viewMaterial, setViewMaterial] = useState(null);
    const [waterInletDialog, setWaterInletDialog] = useState(false);
    const [restoreDialog, setRestoreDialog] = useState(false);
    const [temperatureDialog, setTemperatureDialog] = useState(false);
    const [mchWtrInlet, setMchWtrInlet] = useState(null);
    const [tempValue, setTempValue] = useState(null);
    const [onlineRfid, setOnlineRfid] = useState(false);
    const [onlineRefill, setOnlineRefill] = useState(false);
    const [levelAccess, setLevelAccess] = useState(false);
    const [machineBackup, setMachineBackup] = useState([]);
    const [restLoading, setRestLoading] = useState(true);
    const [restoreData, setRestoreData] = useState({});
    const [viewRestoreDialog, setViewRestoreDialog] = useState(false)
    const [editRestoreDialog, setEditRestoreDialog] = useState(false)
    const [restoreDispenseQty, setRestoreDispenseQty] = useState({});
    const [deleteRestoreDialog, setDeleteRestoreDialog] = useState(false);
    const [unlockDoorStatus, setUnlockDoorStatus] = useState(true);

    const barcode = useRef();


    useEffect(() => {
        setOnlineRefill(selectedMachine?.mchmode?.onlineRefill == true ? selectedMachine.mchmode?.onlineRefill : false);
        setLevelAccess(selectedMachine?.mchmode?.levelAccess == true ? selectedMachine.mchmode?.levelAccess : false);
        setOnlineRfid(selectedMachine?.mchmode?.paymode?.onlineRfid == true ? selectedMachine.mchmode?.paymode?.onlineRfid : false);
        openWaterInlet();
    }, [selectedMachine])

    const viewDetails = async () => {
        if (barcode.current.value != '') {
            const response = await apiServices.materialDetails({ barcode: barcode.current.value });
            console.log("barcode", response?.data?.data[0]);
            setViewMaterial(response?.data?.data[0]);
            setBarcodeDialog(true);
            barcode.current.value = '';
        } else {
            failureToast(toast, 'Please Enter The Barcode');
        }

    }

    const reactivateBarcode = () => {
        if (barcode.current.value != '') {
            setReactivateDialog(true);
        } else {
            failureToast(toast, 'Please Enter The Barcode');
        }
    }

    // const saveWaterInlet = async () => {
    // const response = await apiServices.setWaterInlet({ id: selectedMachine.id, inlet: mchWtrInlet ? 1 : 0 });
    // response?.data?.success ? successToast(toast, 'Water Inlet Sets Successfully') : failureToast(toast, 'Failed to Set Water Inlet.');
    // hideWaterInletDialog()
    // }

    const ChangeWaterValue = async (e) => {
        setMchWtrInlet(e.target.value)
        const response = await apiServices.setWaterInlet({ id: selectedMachine.id, inlet: mchWtrInlet ? 1 : 0 });
        response?.data?.success ? successToast(toast, `Water Inlet(${e.target.value ? 'Bottom' : 'Top'}) Sets Successfully`) : failureToast(toast, 'Failed to Set Water Inlet.');
    }

    const openRestoreDialog = async () => {
        setRestoreDialog(true);
        const response = await apiServices.setBackupData({ id: selectedMachine.id });
        const data1 = response.data.data.map((x, i) => { x.srid = i + 1; return x })
        setMachineBackup(data1)
        setRestLoading(false)
    }
    const setRestore = async (rowData) => {
        const response = await apiServices.setRestoreData(rowData);
        response?.data?.success ? successToast(toast, 'Data restored successfully') : failureToast(toast, 'Failed to update');
    }



    const deleteRestore = (rowData) => {
        setRestoreData(rowData);
        setDeleteRestoreDialog(true);
    }

    const confirmDelete = async () => {
        const data = { id: selectedMachine.id, _id: restoreData._id }
        const response = await apiServices.removeRestoreData(data);
        response?.data?.success ? successToast(toast, 'Restore data deleted successfully.') : failureToast(toast, 'Failed to delete.');
        hideDeleteRestoreDialog(false);
        openRestoreDialog();
    }

    const viewRestore = async (rowData) => {
        setViewRestoreDialog(true)
        rowData.beverages = await rowData?.beverages.map((x) => {
            return {
                ...x,
                dispenseQty: x.dispense_qty?.half?.quantity,
                dispenseWater: x.dispense_qty?.half?.water,
                dispenseBev: x.dispense_qty?.half?.beverage
            }
        })
        setRestoreData(rowData);
    }

    const editRestoreData = (rowData) => {
        setEditRestoreDialog(true);
        setRestoreDispenseQty({
            name: rowData.name,
            dispense_qty: {
                half: rowData.dispense_qty.half,
                full: rowData.dispense_qty.half
            },
            wtrValue: rowData.wtrValue,
            wtr_offset: rowData.wtr_offset,
            canisterMaintainace: rowData.canisterMaintainace
        })
        console.log("editRestore", restoreDispenseQty);
    }

    const updateRestore = async (e) => {
        e.preventDefault();
        const masterData = restoreData?.beverages?.map((x) => {
            if (x.name === restoreDispenseQty.name) {
                return {
                    ...x, ...restoreDispenseQty
                }
            } else {
                return x;
            }
        })

        const payload = {
            beverages: masterData,
            id: selectedMachine.id,
            _id: restoreData?._id
        }

        console.log("payload", payload);

        const response = await apiServices.updateBackupData(payload);
        response?.data?.success ? successToast(toast, 'Data Updated Successful') : failureToast(toast, 'Failed to Update');
        setEditRestoreDialog(false);
        setViewRestoreDialog(false);
        openRestoreDialog();
    }

    const saveTemperature = async () => {
        console.log("saveTemperature");
        const response = await apiServices.setMachineTemperature({ id: selectedMachine.id, temp: tempValue });
        response?.data?.success ? successToast(toast, 'CVES Temperature Updated Successfully') : failureToast(toast, 'Failed to Update Temperature.');
        hideTemperatureDialog()
    }
    const unlockDoor = async () => {
        if (unlockDoorStatus) {
            setUnlockDoorStatus(false)
            setTimeout(() => { setUnlockDoorStatus(true) }, 5000);
            const response = await apiServices.unlockDoor({ id: selectedMachine.id });
            response?.data?.success ? successToast(toast, 'Unlock Door Successfully') : failureToast(toast, 'Failed to Unlock Door.');
            hideDoorUnlockDialog()
        } else {
            failureToast(toast, 'Please Wait...')
        }

    }

    const exitApp = async () => {
        const response = await apiServices.exitTheApp({ id: selectedMachine.id });
        response?.data?.success ? successToast(toast, 'CVES App exit Successfully') : failureToast(toast, 'Failed to Exit App');
        hideExitAppDialog()
    }

    const viewEmployee = () => {
        history.push('./employees');
        window.location.reload();
    }
    const handleChange = async (e) => {
        if (e.target.name == 'onlineRfid') {
            setOnlineRfid(e.target.value);
            const response = await apiServices.CvesSettingUpdate({ id: selectedMachine.id, mode: "onlineRfid", onlineRfid: e.target.value });
            response?.data?.success ? successToast(toast, 'Rfid Type Updated') : failureToast(toast, 'Failed to Update');
        } else if (e.target.name == 'onlineRefill') {
            setOnlineRefill(e.target.value);
            const response = await apiServices.CvesSettingUpdate({ id: selectedMachine.id, mode: "onlineRefill", onlineRefill: e.target.value });
            response?.data?.success ? successToast(toast, 'Refill Type Updated') : failureToast(toast, 'Failed to Update');
        } else if (e.target.name == 'levelAccess') {
            setLevelAccess(e.target.value)
            const response = await apiServices.CvesSettingUpdate({ id: selectedMachine.id, mode: "levelAccess", levelAccess: e.target.value });
            response?.data?.success ? successToast(toast, 'Level Access Updated') : failureToast(toast, 'Failed to Update');
        } else if (e.target.name == 'quantity' || e.target.name == 'beverage' || e.target.name == 'water') {
            setRestoreDispenseQty(
                {
                    ...restoreDispenseQty,
                    dispense_qty: {
                        half: {
                            ...restoreDispenseQty.dispense_qty.half,
                            [e.target.name]: Number(e.target.value)
                        }
                    }
                }
            )
        } else if (e.target.name == 'wtr_offset' || e.target.name == 'wtrValue') {
            setRestoreDispenseQty(
                {
                    ...restoreDispenseQty,
                    [e.target.name]: Number(e.target.value)
                }
            )
        } else if (e.target.name == 'canisterMaintainace') {
            setRestoreDispenseQty(
                {
                    ...restoreDispenseQty,
                    [e.target.name]: e.target.value
                }
            )
        }
    }
    const dateBodyTemplate = (rowData, props) => {
        const convertedDate = props.field ? new Date(rowData[props.field]).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' }) : null;
        return (<><span className="p-column-title">{props.header}</span>{convertedDate}</>)
    }

    const actionRestoreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{ display: "flex" }}>
                    <Button icon="pi pi-eye" title='View' className="p-button-rounded p-button-warning p-mr-2 p-mb-2" onClick={() => viewRestore(rowData)} />
                    <Button icon="pi pi-undo" title='Restore' className="p-button-rounded p-button-success p-mr-2 p-mb-2" id={rowData._id} onClick={() => setRestore(rowData)} />
                    <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mr-2 p-mb-2" onClick={() => deleteRestore(rowData)} />
                </div>
            </>
        )
    }
    const canBodyTemplate = (rowData, props) => { return (<><span className="p-column-title">{props.header}</span>{rowData?.beverages[props.row]?.name ? rowData?.beverages[props.row]?.name : 'NA'}</>) }


    const openDoorUnlockDialog = () => { setDoorUnlockDialog(true) }
    const openTemperatureDialog = async () => {
        setTemperatureDialog(true)
        const response = await apiServices.getMachineTemperature({ id: selectedMachine.id });
        setTempValue(response?.data?.data?.beverage?.hottemp)
    }
    const openWaterInlet = async () => {
        const response = await apiServices.getWaterInlet({ id: selectedMachine.id });
        console.log(response?.data?.data);
        setMchWtrInlet(response?.data?.data?.beverage?.waterinlet)
    }

    const reuseBarcode = async () => {
        const response = await apiServices.reuseBarcode({ barcode: barcode.current.value });
        response?.data?.success ? successToast(toast, 'Barcode Reused Successfully') : failureToast(toast, 'Failed to Reuse Barcode');
        hideReactivateDialog();
    }
    const openExitAppDialog = () => { setExitAppDialog(true) }
    // const 

    const hideExitAppDialog = () => { setExitAppDialog(false) }
    // const hideWaterInletDialog = () => { setWaterInletDialog(false) }
    const hideTemperatureDialog = () => { setTemperatureDialog(false); }
    const hideDoorUnlockDialog = () => { setDoorUnlockDialog(false); }
    const hideRestoreDialog = () => { setRestoreDialog(false); }
    const hideBarcodeDetails = () => { setBarcodeDialog(false) }
    const hideReactivateDialog = () => {
        setReactivateDialog(false)
        barcode.current.value = '';
    }
    const hideViewRestoreDialog = () => { setViewRestoreDialog(false) }
    const hideEditRestoreDialog = () => { setEditRestoreDialog(false) }
    const hideDeleteRestoreDialog = () => { setDeleteRestoreDialog(false) }


    const reactivateDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className=" p-button-danger p-mr-2 p-mb-2" onClick={hideReactivateDialog} />
            <Button label="Confirm" icon="pi pi-check" className=" p-button-success p-mr-2 p-mb-2" onClick={reuseBarcode} />
        </>
    )
    const viewDetailsFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className=" p-button-danger p-mr-2 p-mb-2" onClick={hideBarcodeDetails} />
        </>
    )
    const actionOnBevData = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{ display: "flex" }}>
                    {
                        rolePermissions?.editBackupBevData &&
                        <Button icon="pi pi-pencil" title='View' className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={() => { editRestoreData(rowData) }} />
                    }
                </div>
            </>
        )
    }
    const doorUnlockDialogFooter = commonDialogFooter('Cancel', hideDoorUnlockDialog, 'Save', unlockDoor, (selectedMachine?.connectionMode != "disConnected" && selectedMachine?.connectionMode !== undefined && selectedMachine?.connectionMode != "Unknown") ? true : false)
    const restoreDialogFooter = commonCloseDialogFooter('Close', hideRestoreDialog);
    const temperatureDialogFooter = commonDialogFooter('Cancel', hideTemperatureDialog, 'Save', saveTemperature, (selectedMachine?.connectionMode != "disConnected" && selectedMachine?.connectionMode !== undefined && selectedMachine?.connectionMode != "Unknown") ? true : false)
    // const WaterInletDialogFooter = commonDialogFooter('Cancel', hideWaterInletDialog, 'Save', saveWaterInlet, (selectedMachine?.connectionMode != "disConnected" && selectedMachine?.connectionMode !== undefined && selectedMachine?.connectionMode != "Unknown") ? true : false)
    const exitAppDialogFooter = commonDialogFooter('Cancel', hideExitAppDialog, 'Save', exitApp, (selectedMachine?.connectionMode != "disConnected" && selectedMachine?.connectionMode !== undefined && selectedMachine?.connectionMode != "Unknown") ? true : false)
    const viewRestoreDialogFooter = commonCloseDialogFooter('Cancel', hideViewRestoreDialog)
    const editRestoreDialogFooter = commonDialogFooter('Cancel', hideEditRestoreDialog, 'Update', updateRestore)
    const deleteRestoreDialogFooter = commonDialogFooter('No', hideDeleteRestoreDialog, 'Yes, Delete it', confirmDelete)



    return (<>
        <Toast ref={toast} position="center" />
        <Accordion style={{ padding: "0px 16px" }} className="card" activeIndex={0} collapseIcon='null' >
            <AccordionTab headerStyle={{ fontSize: "18px", fontWeight: 600 }} header="Barcode Reactivation" disabled>
                <div className='p-grid'>
                    <div className='p-col-12 p-md-3'>
                        <div className="p-inputgroup p-mt-2">
                            <InputText ref={barcode} name='barcode' placeholder='Enter Barcode Number' maxLength={8} autoFocus />
                        </div>
                    </div>
                    <div className='p-col-12 p-md-3'>
                        <div className="p-inputgroup p-mt-2">
                            <Button onClick={reactivateBarcode} style={{ height: "38px", width: "143px", padding: "22px", justifyContent: "space-between" }} label='Reactivate' className="p-button-success p-m-1" />
                            <Button onClick={viewDetails} icon="pi pi-eye" style={{ height: "38px", width: "143px", padding: "22px", justifyContent: "space-between" }} label='View Details' className="p-button-warning p-m-1" />
                        </div>
                    </div>
                </div>
            </AccordionTab>
        </Accordion>

        <Accordion style={{ padding: "5px !important" }} className="card" activeIndex={0} collapseIcon='null' >
            <AccordionTab headerStyle={{ fontSize: "18px", fontWeight: 600 }} header="Machine Monitor" disabled>
                <div className='p-grid'>

                    {(selectedMachine?.mchmode?.paymode?.free == false && selectedMachine?.mchmode?.paymode?.rfid == true)
                        && <div className="p-inputgroup p-md-2" >
                            <label htmlFor="refill"style={{ fontSize: "12px" }}>Online RFID:&nbsp;&nbsp;</label>
                            <InputSwitch onChange={handleChange} checked={onlineRfid} id='onlineRfid' name='onlineRfid' />
                        </div>}
                    <div className="p-inputgroup p-md-2" >
                        <label htmlFor="refill" style={{ fontSize: "12px" }}>Online Refill:&nbsp;&nbsp;</label>
                        <InputSwitch onChange={handleChange} checked={onlineRefill} id='onlineRefill' name='onlineRefill' />
                    </div>
                    <div className="p-inputgroup p-md-2" >
                        <label htmlFor="refill" style={{ fontSize: "12px" }}>Level Access:&nbsp;&nbsp;</label>
                        <InputSwitch onChange={handleChange} checked={levelAccess} id='levelAccess' name='levelAccess' />
                    </div>
                    <div className="p-inputgroup p-md-2" >
                        <label htmlFor="set-emp" style={{ fontSize: "12px" }}>Water Inlet ({mchWtrInlet ? 'Bottom' : 'Top'}):&nbsp;&nbsp;</label><br></br>
                        <InputSwitch id='wtr-inlet' name='wtr_inlet' checked={mchWtrInlet ? true : false} onChange={ChangeWaterValue} />
                    </div>
                    <div className="p-inputgroup p-md-2" >
                        <Button onClick={openTemperatureDialog} icon="pi pi-sort-amount-up" label='Set Temprature' className="p-button-warning" />
                    </div>
                    <div className="p-inputgroup p-md-2" >
                        <Button onClick={openRestoreDialog} icon="pi pi-undo" label='Restore App' className="p-button-secondary" />
                    </div>
                    <div className="p-inputgroup p-md-2" >
                        <Button onClick={openExitAppDialog} icon="pi pi-directions" label='Exit App' className="p-button-info" />
                    </div>
                    <div className="p-inputgroup p-md-2" >
                        <Button onClick={openDoorUnlockDialog} icon="pi pi-sign-out" label='Door Open' className="p-button-plain" />
                    </div>

                </div>
            </AccordionTab>
        </Accordion>


        <Dialog visible={barcodeDialog} style={{ width: '500px' }} header="View Material Details" modal footer={viewDetailsFooter} onHide={hideBarcodeDetails}>
            <div className='p-grid'>
                <div className="p-col-12">
                    <div className=" widget-performance">
                        <div className="content">
                            <ul>
                                <div className='p-grid'>
                                    <CommonForDetails name="Batch Name" value={viewMaterial?.batch?.batch_name} />
                                    <CommonForDetails name="Batch Id" value={viewMaterial?.batch?.batch_id} />
                                    <CommonForDetails name="Barcode" value={viewMaterial?.barcode} />
                                    <CommonForDetails name="Stage" value={viewMaterial?.stage} />
                                    <CommonForDetails name="Used Status" value={viewMaterial?.usedStatus == true ? 'True' : 'False'} />
                                    <CommonForDetails name="Weight" value={viewMaterial?.weight} />
                                    <CommonForDetails name="Material Name" value={viewMaterial?.materialName} />
                                    <CommonForDetails name="SKU Master Name" value={viewMaterial?.itemName} />
                                    <CommonForDetails name="User Name" value={viewMaterial?.customer.fullName} />
                                    <CommonForDetails name="Company Name" value={viewMaterial?.customer.company_name} />
                                    <CommonForDetails name="User Id" value={viewMaterial?.customer.userid} />
                                    <CommonForDetails name="User Type" value={viewMaterial?.customer.userType} />
                                    <CommonForDetails name="Warehouse Name" value={viewMaterial?.wareHouse.name} />
                                    <CommonForDetails name="Warehouse Id" value={viewMaterial?.wareHouse.userid} />
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>


        <Dialog visible={reactivateDialog} style={{ width: '450px' }} header="Confirm" modal footer={reactivateDialogFooter} onHide={hideReactivateDialog}>
            <div className="confirmation-content">
                <center>
                    <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br /><br />
                    <b><span>Are you sure you want to reactivate barcode?</span></b> <br /><br />
                </center>
            </div>
        </Dialog>


        <Dialog visible={exitAppDialog} style={{ width: '450px' }} header="Confirm" modal footer={exitAppDialogFooter} onHide={hideExitAppDialog}>
            <div className="confirmation-content">
                <center>
                    <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br /><br />
                    {
                        (selectedMachine?.connectionMode == "disConnected" || selectedMachine?.connectionMode === undefined || selectedMachine?.connectionMode == "Unknown") ?
                            <div>
                                <center> <b><span className='p-error'>SYSTEM IS OFFLINE</span></b> </center>
                            </div> :
                            <div>
                                <b><span>Are you sure you want to exit cves app?</span></b> <br /><br />
                            </div>

                    }
                </center>
            </div>
        </Dialog>


        {/* <Dialog visible={waterInletDialog} style={{ width: '550px' }} header={`Set CVSE Water Inlet (${selectedMachine.id})`} className="p-fluid" modal footer={WaterInletDialogFooter} onHide={hideWaterInletDialog}>
            <div className='p-grid'>
                <div className='p-col-12'>
                    {
                        (selectedMachine?.connectionMode == "disConnected" || selectedMachine?.connectionMode === undefined || selectedMachine?.connectionMode == "Unknown") ?
                            <div>
                                <center> <b><span className='p-error'>SYSTEM IS OFFLINE</span></b> </center>
                            </div> :
                            <div className="p-field">
                                <label htmlFor="set-emp">Set Water Inlet ({mchWtrInlet ? 'Bottom' : 'Top'}) </label><br></br>
                                <InputSwitch id='wtr-in' name='wtr_' checked={mchWtrInlet ? true : false} onChange={ChangeWaterValue} />
                            </div>
                    }
                </div>
            </div>
        </Dialog> */}

        <Dialog visible={restoreDialog} style={{ width: '1000px' }} header={`Restore CVES Data (${selectedMachine.id ? selectedMachine.id : '-'})`} modal footer={restoreDialogFooter} onHide={hideRestoreDialog} maximizable={true} blockScroll={true}>
            {
                (selectedMachine?.connectionMode == "disConnected" || selectedMachine?.connectionMode === undefined || selectedMachine?.connectionMode == "Unknown") ?
                    <div>
                        <center><b><span className='p-error p-mt-5'>SYSTEM IS OFFLINE</span></b></center>
                    </div> :
                    <TableWrapper>
                        <DataTable value={machineBackup}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data" emptyMessage="No data found." loading={restLoading} >
                            <Column field="srid" header="Sr.No." sortable body={CommonBodyForTable}></Column>
                            <Column field="date" header="Date & Time" sortable body={dateBodyTemplate}></Column>
                            <Column field="canister1" header="Canister 1" row="0" sortable body={canBodyTemplate}></Column>
                            <Column field="canister2" header="Canister 2" row="1" sortable body={canBodyTemplate}></Column>
                            {selectedMachine?.cans == 3 && <Column field="canister3" header="Canister 3" row="2" sortable body={canBodyTemplate}></Column>}
                            {selectedMachine?.cans == 4 && <Column field="canister3" header="Canister 3" row="2" sortable body={canBodyTemplate}></Column>}
                            {selectedMachine?.cans == 4 && <Column field="canister4" header="Canister 4" row="3" sortable body={canBodyTemplate}></Column>}
                            <Column header="Action" body={actionRestoreBodyTemplate}></Column>
                        </DataTable>
                    </TableWrapper>
            }
        </Dialog>


        <Dialog visible={temperatureDialog} style={{ width: '550px' }} header={`Set CVSE Temperature (${selectedMachine.id})`} className="p-fluid" modal footer={temperatureDialogFooter} onHide={hideTemperatureDialog}>
            <div className='p-grid'>
                <div className='p-col-12'>
                    {
                        (selectedMachine?.connectionMode == "disConnected" || selectedMachine?.connectionMode === undefined || selectedMachine?.connectionMode == "Unknown") ?
                            <div>
                                <center> <b><span className='p-error'>SYSTEM IS OFFLINE</span></b> </center>
                            </div> :
                            <div className="p-field">
                                <label htmlFor="set-emp">Set Temperature</label>
                                <InputNumber id='set-emp' value={tempValue ? tempValue : 0} min="75" max="93" onValueChange={(e) => setTempValue(e.value)} showButtons mode="decimal"></InputNumber>
                            </div>
                    }
                </div>
            </div>
        </Dialog>


        <Dialog visible={doorUnlockDialog} style={{ width: '450px' }} header="Confirm" modal footer={doorUnlockDialogFooter} onHide={hideDoorUnlockDialog}>
            <div className="confirmation-content">

                <center>
                    <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br /><br />
                    {
                        (selectedMachine?.connectionMode == "disConnected" || selectedMachine?.connectionMode === undefined || selectedMachine?.connectionMode == "Unknown") ?
                            <div>
                                <center> <b><span className='p-error'>SYSTEM IS OFFLINE</span></b> </center>
                            </div> :
                            <div>
                                <b><span>Are you sure you want to unlock door?</span></b> <br /><br />
                            </div>

                    }
                </center>
            </div>
        </Dialog>


        <Dialog visible={viewRestoreDialog} style={{ width: '850px' }} header={`View Restore Data`} modal footer={viewRestoreDialogFooter} onHide={hideViewRestoreDialog} maximizable={true} blockScroll={true}>
            <TableWrapper>
                <DataTable value={restoreData.beverages}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data" emptyMessage="No data found." >
                    <Column field="Sr.Id" header="Sr.No." sortable body={srBodyTemplate}></Column>
                    <Column field="name" header="Material Name" sortable body={CommonBodyForTable}></Column>
                    <Column field="dispenseQty" header="Dispense Qty" sortable body={CommonBodyForTable}></Column>
                    <Column field="dispenseWater" header="Dispense Water" sortable body={CommonBodyForTable}></Column>
                    <Column field="dispenseBev" header="Dispense Beverage" sortable body={CommonBodyForTable}></Column>
                    <Column field="usedDate" header="Used Date" sortable body={DateBodyForTable}></Column>
                    <Column header="Action" body={actionOnBevData}></Column>
                </DataTable>
            </TableWrapper>
        </Dialog>


        <Dialog visible={editRestoreDialog} style={{ width: '550px' }} header={`Update Beverage Details`} className="p-fluid" modal footer={editRestoreDialogFooter} onHide={hideEditRestoreDialog}>

            <div className='p-grid'>
                <FormWrapper id="dis-qty" label="Quantity">
                    <InputText id="dis-qty" type='number' name='quantity' placeholder='Quantity' defaultValue={restoreDispenseQty?.dispense_qty?.half?.quantity} onChange={handleChange} required />
                </FormWrapper>
                <FormWrapper id="dis-water" label="Water">
                    <InputText id="dis-water" type='number' name='water' placeholder='Dispense Water' defaultValue={restoreDispenseQty?.dispense_qty?.half?.water} onChange={handleChange} required />
                </FormWrapper>
                <FormWrapper id="dis-bev" label="Beverage">
                    <InputText id="dis-bev" type='number' step="0.01" name='beverage' placeholder='Dispense Beverage (ml)' defaultValue={restoreDispenseQty?.dispense_qty?.half?.beverage} onChange={handleChange} required />
                </FormWrapper>
                <FormWrapper id="wtr-val" label="Water Value">
                    <InputText id="wtr-val" type='number' min={0} name='wtrValue' placeholder='Water Value' defaultValue={restoreDispenseQty?.wtrValue} onChange={handleChange} required />
                </FormWrapper>
                <FormWrapper id="wtr-offset" label="Water Offset">
                    <InputText id="wtr-offset" type='number' min={0} name='wtr_offset' placeholder='Water Offset' defaultValue={restoreDispenseQty?.wtr_offset} onChange={handleChange} required />
                </FormWrapper>
                <FormWrapper id="dis-bev" label="Canister Maintenance">
                    <InputSwitch className='p-mt-2' name='canisterMaintainace' checked={restoreDispenseQty?.canisterMaintainace} onChange={handleChange} />
                </FormWrapper>
            </div>
        </Dialog>


        <Dialog visible={deleteRestoreDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteRestoreDialogFooter} onHide={hideDeleteRestoreDialog}>
            <div className="confirmation-content">
                <center>
                    <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br />
                    <b><span>Are you sure you delete this?</span></b>
                </center>
            </div>
        </Dialog>



    </>)


}