import React from "react";
import SalesStatComp from "./SalesStatComp";

export const SalesOverallStat = (props) => {

    // console.log(props.inventoryData);

    return (
        <>
            <div className='card'>                
            <h6>Management Information System</h6>

            {
                (props.machineTypeFilter && props.machineType.code == 'bulk') &&

                <div className="p-grid">
                    <SalesStatComp header='Number of Distributors' icon='pi pi-user'  value={props.distributorsStats ? props.distributorsStats : '-'} loading={props.loading}/>
                    <SalesStatComp header='Total Number of Machines' icon='pi pi-star-o' value={props.totalMachines ? props.totalMachines : '-'} loading={props.loading} />
                    <SalesStatComp header='Number of Cups Sold' icon='pi pi-slack' value={props.bulkCupConsumption ? props.bulkCupConsumption : '-'} loading={props.loading} />
                </div>
            }

            {
                (props.accType.code == 'account' && props.filterAccountsValue?.name == 'All' && props.machineType.code == 'ctvm') &&

                <div className="p-grid">
                    {/* <SalesStatComp header='Total No of Accounts' icon='pi pi-user'  value={props.distributorsValue ? (props.distributorsValue - 1) : '-'} loading={props.loading} /> */}
                    <SalesStatComp header='Total No of Accounts' icon='pi pi-user'  value={props.distributorsValue ? props.distributorsValue : '-'} loading={props.loading} />
                    <SalesStatComp header='Cup Consumption Super-Star' icon='pi pi-star-o' value={props.cupConSt ? props.cupConSt : '-'} loading={props.loading} />
                    <SalesStatComp header='Target Performance Super-Star' icon='pi pi-slack' value={props.TargetConSt ? props.TargetConSt : '-'} loading={props.loading} />
                    <SalesStatComp header='Inventory Super-Star' icon='pi pi-wallet' value={props.InventorySt ? props.InventorySt : '-'} loading={props.loading} />
                </div>
            }

            {
                (props.machineType.code != 'bulk' && props.accType.code == 'account' && props.filterAccountsValue?.name != 'All' ) &&

                <div className="p-grid">
                    <SalesStatComp header='Accounts' icon='pi pi-user'  value={props.filterAccountsValue?.name ? props.filterAccountsValue?.name : '-'} loading={props.loading}/>
                    <SalesStatComp header='Cup Consumption' icon='pi pi-star-o' value={props.machinCupConsumption ? props.machinCupConsumption : '0'} loading={props.loading} />
                    <SalesStatComp header='Target Performance' icon='pi pi-slack' value={props.machineTargetPerformance ? Number(props.machineTargetPerformance).toFixed(2) : '0'} loading={props.loading} />
                    <SalesStatComp header='Inventory' icon='pi pi-wallet' value={props.accountUsedPackets ? props.accountUsedPackets : '0'} loading={props.loading} />
                </div>
            }

            {
                (props.accType.code == 'facility' && props.filterAccountsValue1.facname == 'All') &&

                <div className="p-grid">
                    <SalesStatComp header='No. Of Facilities' icon='pi pi-user' value={props.totalfacilities ? props.totalfacilities : '-'} loading={props.loading} />
                    <SalesStatComp header='Cup Consumption Super-Star' icon='pi pi-star-o' value={props.facCupConSt ? props.facCupConSt : '-'} loading={props.loading} />
                    <SalesStatComp header='Target Performance Super-Star' icon='pi pi-slack' value={props.mTargetPerformanceSt ? props.mTargetPerformanceSt : '-'} loading={props.loading} />
                    <SalesStatComp header='Inventory Super-Star' icon='pi pi-wallet' value={props.mInventorySt ? props.mInventorySt : '-'} loading={props.loading} />
                </div>
            }

            {
                (props.accType.code == 'facility' && props.filterAccountsValue1.facname != 'All') &&

                <div className="p-grid">
                    <SalesStatComp header='Facility' icon='pi pi-user' value={props?.filterAccountsValue1?.facname} loading={props.loading} />
                    <SalesStatComp header='Cup Consumption' icon='pi pi-star-o' value={props.misStatData?.usagesStats[0]?.totalCup ? props.misStatData?.usagesStats[0]?.totalCup : '-'} loading={props.loading} />
                    <SalesStatComp header='Target Performance' icon='pi pi-slack' value={props.fcWiseTarValues.length > 0 ? Number(props.fcWiseTarValues[0]).toFixed(2) : '-'} loading={props.loading} />
                    <SalesStatComp header='Inventory' icon='pi pi-wallet' value={props.accountUsedPackets ? props.accountUsedPackets : 0} loading={props.loading} />
                </div>
            }
            

            {
                (props.accType.code == 'subscription' && props.filterAccountsValue2.displayLabel == 'All')  &&

                <div className="p-grid">
                    <SalesStatComp header='No. Of Subscription' icon='pi pi-user' value={props.totalSubscription ? props.totalSubscription : '-'} loading={props.loading} />
                    <SalesStatComp header='Cup Consumption Super-Star' icon='pi pi-star-o' value={props.subscriptionCSt ? props.subscriptionCSt : '-'} loading={props.loading} />
                    <SalesStatComp header='Target Performance Super-Star' icon='pi pi-slack' value={props.subscriptionTSt ? props.subscriptionTSt : '-'} loading={props.loading} />
                    <SalesStatComp header='Inventory Super-Star' icon='pi pi-wallet' value={props.mInventorySt ? props.mInventorySt : '-'} loading={props.loading} />
                </div>
            }


            {
                (props.accType.code == 'subscription' && props.filterAccountsValue2.displayLabel != 'All')  &&

                <div className="p-grid">
                    <SalesStatComp header='Subscription Id' icon='pi pi-user' value={props.filterAccountsValue2?.facilitydata?.facname ? props.filterAccountsValue2?.facilitydata?.facname : '-'} loading={props.loading} />
                    <SalesStatComp header='Cup Consumption' icon='pi pi-star-o' value={props.subMCupSt ? props.subMCupSt : '-'} loading={props.loading} />
                    <SalesStatComp header='Target Performance' icon='pi pi-slack' value={props.machineTargetPerformance ? Number(props.machineTargetPerformance).toFixed(2) : '-'} loading={props.loading} />
                    <SalesStatComp header='Inventory' icon='pi pi-wallet' value={props.accountUsedPackets ? props.accountUsedPackets : '0'} loading={props.loading} />
                </div>
            }





            </div>
        </>
    )   
         
}