import { useEffect, useState, useRef } from "react"
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Checkbox } from 'primereact/checkbox';
import { billingTypeOptions, paymentTermOptions, paymentTypeOptions, prefixOptions, rentleOptions, subScriptionOptions, entityOptions, defaultValues, billingDefault, dispensingOptions, paidBeverageSetting, extentionPeriodOptions } from "./Constants";
// Api
import ApiServices from "../../service/api/ApiServices";
import { failureToast, successToast } from "../Common/CommonFunctions";
const apiServices = new ApiServices()

// ===================================================================================
// ===================================================================================

export const AddNewCustomerNew = (props) => {

    const [createUserDialog, setCreateUserDialog] = useState(false)
    const [payloadData, setPayloadData] = useState(defaultValues)
    const [billingData, setBillingData] = useState(billingDefault)
    const [shoppingData, setShoppingData] = useState(null)
    const [countries, setCountries] = useState(null);
    const [states, setStates] = useState(null);
    const [cities, setCities] = useState(null);
    const [bStates, setBStates] = useState(null);
    const [bCities, setBCities] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState({ id: '101', sortname: 'IN', name: 'India', phonecode: '91' });
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedBCountry, setSelectedBCountry] = useState({ id: '101', sortname: 'IN', name: 'India', phonecode: '91' });
    const [selectedBState, setSelectedBState] = useState(null);
    const [selectedBCity, setSelectedBCity] = useState(null);
    const [selectedBillingType, setSelectedBillingType] = useState({ name: "Cup Wise", code: "Cupwise" });
    const [selectedMode, setSelectedMode] = useState({ name: "Prepaid", code: "PREPAID" });
    const [seletedExtPeriod, setSelectedExtPeriod] = useState(7);
    const [selectedPaymentType, setSelectedPaymentType] = useState({ name: "Direct Bank Account", code: "Direct Bank Account" });
    const [shippingCheck, setShippingCheck] = useState(false);
    const [signingCheck, setSigningCheck] = useState(false);
    const [selectedPrefix, setSelectedPrefix] = useState({ name: 'Mr.' })
    const [selectedPrefixForSign, setSelectedPrefixForSign] = useState({ name: 'Mr.' })
    const [selectedRentleOption, setSelectedRentleOption] = useState(null)
    const [selectedSubOption, setSelectedSubOptions] = useState({ name: 'Free', code: 'FREE' })
    // const [selectedTypeOfEntity, setSelectedTypeOfEntity] = useState(null)
    const [selectedTypeOfEntity, setSelectedTypeOfEntity] = useState(entityOptions[0])
    const [billingPincode, setBillingPincode] = useState('')
    const [shippingPincode, setShippingPincode] = useState('')
    const [defaultUser, setDefaultUser] = useState(null)
    const [facilityData, setFacilityData] = useState([{ name: 'None' }])
    const [selectedFacility, setSelectedFacility] = useState({ name: 'None' })
    const [billingDefaults, setBillingDefaults] = useState(null)
    const [shippingDefaults, setShippingDefaults] = useState(null)
    const [signingAuthorityMobile, setSigningAuthorityMobile] = useState('')
    const [isFacSelected, setIsFacSelected] = useState(false)
    const [isClicked, setIsClicked] = useState(true);
    const [selectedDispensingMode, setselectedDispensingMode] = useState({ name: "Free", code: "FREE" });
    const [paidOptions, setPaidOptions] = useState(paidBeverageSetting);

    const toast = useRef(null);

    useEffect(() => {
        getData(props.createUserDialog)
    }, [props.createUserDialog])

    useEffect(() => {
        setShoppingData({ ...shoppingData, "location": { "lat": props.latLong?.currentLatitude, "lng": props.latLong?.currentLongitude } })
    }, [props.latLong])

    useEffect(() => {
        getDefaultUserValues(props.verifiedUserData)
    }, [props.verifiedUserData])

    const getDefaultUserValues = (data) => {
        console.log("getDefaultUserValues", data);
        if (data) {
            setFacilityData([...facilityData, ...data?.facilities])

            if (data?.ctvm_user_data) {
                console.log("data", data);
                setDefaultUser(data);
                getDefaultData(data);
            } else if (data?.local_user_data) {

            }

        }
    }

    useEffect(()=> {
        console.log('billingData', billingData);
    }, [billingData])

    const getDefaultData = (data) => {
        // setBillingPincode(Number(data?.ctvm_user_data?.billing_details?.pincode))
        data?.ctvm_user_data?.billing_pattern == 'Cupwise' && setSelectedBillingType({ name: "Cup Wise", code: "Cupwise" })
        data?.ctvm_user_data?.billing_pattern == 'PacketWise' && setSelectedBillingType({ name: "Packet Wise", code: "PacketWise" })

        // data?.ctvm_user_data?.payemntTerms?.Prepaid_Payment_Type_in_word && setSelectedPaymentType
        data?.ctvm_user_data?.payemntTerms?.mode == "PREPAID" && setSelectedMode({ name: "Prepaid", code: "PREPAID" })
        data?.ctvm_user_data?.payemntTerms?.mode == "POSTPAID" && setSelectedMode({ name: "Postpaid", code: "POSTPAID" })
        data?.ctvm_user_data?.payemntTerms?.mode == "PARTIAL" && setSelectedMode({ name: "Partial", code: "PARTIAL" })

    }

    const getData = async (data) => {
        if (data) {
            // console.log(data);
            setCreateUserDialog(data)
            const facData = props.selectedFac;
            // console.log(facData);
            facData ? setAllDefaultData(facData) : setDefaultForFac();
        }
    }

    const setDefaultForFac = () => {
        const verifiedData = props.verifiedUserData;

        if (verifiedData?.ctvm_user_data) {

            setBillingDefaults(verifiedData?.ctvm_user_data?.billing_details)
            getStatesData(verifiedData?.ctvm_user_data?.billing_details)
            setShippingDefaults(null)

            const billing_address = {
                "address": verifiedData?.ctvm_user_data?.billing_details?.addr,
                "address_2": "",
                "city": verifiedData?.ctvm_user_data?.billing_details?.city?.name,
                "state": verifiedData?.ctvm_user_data?.billing_details?.state?.name,
                "pincode": verifiedData?.ctvm_user_data?.billing_details?.pincode,
                "country": verifiedData?.ctvm_user_data?.billing_details?.country?.name,
                "gst_no": verifiedData?.ctvm_user_data?.billing_details?.gstnumber
            }

            setBillingData({ ...billingData, ...billing_address })

            const defaultPayload = {
                'personal_prefix': 'Mr.',
                'signing_authority_prefix': 'Mr.',
                'subscription_type': 'FREE',
                'name': verifiedData?.ctvm_user_data?.name,
                'personal_last_name': verifiedData?.ctvm_user_data?.lastName,
                'company_name': verifiedData?.ctvm_user_data?.billing_details?.company,
                'cup_quantity': 60 + Number(verifiedData?.ctvm_user_data?.beverages_offset) || 0,
                'email': verifiedData?.ctvm_user_data?.email,
                'packet_cost': verifiedData?.ctvm_user_data?.packet_cost,
                'signing_authority_person_name': "",
                'signing_authority_person_last_name': "",
                'signing_authority_designation': "",
                'signing_authority_mobile': "",
                'signing_authority_email': "",
                'pan': "",
                'amc': 0,
                'speciality_rate': verifiedData?.ctvm_user_data?.speciality_cost ? verifiedData?.ctvm_user_data?.speciality_cost : verifiedData?.ctvm_user_data?.cup_cost,
                'rent_amount': "",
                'refundable_deposit': 0,
                'number_of_cves': 1,
                'cup_cost': verifiedData?.ctvm_user_data?.cup_cost,
                'rental_plan': "",
                'legal_entity_name': "Private Limited Company",
                'billing_type': verifiedData?.ctvm_user_data?.billing_pattern,
                'payment_terms': verifiedData?.ctvm_user_data?.payemntTerms
            }
            setPayloadData({ ...payloadData, ...defaultPayload })

        } else {
            getStatesData()
            setPayloadData({ ...payloadData })
        }

    }

    const setAllDefaultData = (data) => {
        console.log("facility", data);
        setIsFacSelected(true)
        setBillingDefaults(data?.facility_billing_details)
        getStatesData(data?.facility_billing_details)
        setShippingCheck(false)
        setShippingDefaults(data?.shipping_address)
        getBillingStates(data?.shipping_address)

        const billing_address = {
            "address": data?.facility_billing_details?.addr,
            "address_2": "",
            "city": data?.facility_billing_details?.city?.name,
            "state": data?.facility_billing_details?.state?.name,
            "pincode": data?.facility_billing_details?.pincode,
            "country": data?.facility_billing_details?.country?.name,
            "gst_no": data?.facility_billing_details?.gstnumber
        }

        setBillingData({ ...billingData, ...billing_address })
        const shipping = { ...data?.shipping_address, country: data?.shipping_address?.country?.name, state: data?.shipping_address?.state?.name, city: data?.shipping_address?.city?.name };
        setShoppingData({ ...shipping })
        const user = props.verifiedUserData;
        const defaultPayload = {
            'personal_prefix': 'Mr.',
            'signing_authority_prefix': 'Mr.',
            'subscription_type': 'FREE',
            'name': user?.ctvm_user_data?.name,
            'personal_last_name': user?.ctvm_user_data?.lastName,
            'company_name': user?.ctvm_user_data?.billing_details?.company,
            'cup_quantity': 60 + Number(user?.ctvm_user_data?.beverages_offset) || 0,
            'email': user?.ctvm_user_data?.email,
            'packet_cost': user?.ctvm_user_data?.packet_cost,
            'signing_authority_person_name': "",
            'signing_authority_person_last_name': "",
            'signing_authority_designation': "",
            'signing_authority_mobile': "",
            'signing_authority_email': "",
            'pan': "",
            'amc': 0,
            'rent_amount': "",
            'refundable_deposit': 0,
            'number_of_cves': 1,
            'cup_cost': user?.ctvm_user_data?.cup_cost,
            'speciality_rate': user?.ctvm_user_data?.cup_cost,
            'rental_plan': "",
            'legal_entity_name': "Private Limited Company",
            'billing_type': user?.ctvm_user_data?.billing_pattern,
            'payment_terms': user?.ctvm_user_data?.payemntTerms,
            'facility_name': data?.name ? data.name : user?.ctvm_user_data?.billing_details?.company,
            'latitude': data?.shipping_address?.location?.lat,
            'longitude': data?.shipping_address?.location?.lat
        }

        setPayloadData({ ...payloadData, ...defaultPayload })
    }

    const getBillingStates = async (data) => {
        // console.log(data);
        getCountry();
        setSelectedBCountry({ id: '101', sortname: 'IN', name: 'India', phonecode: '91' })
        const state = await getStates({ country_id: '101' })
        setBStates(state)
        const defaultState = state.filter((x) => { return (x.name == data?.state.name) });

        if (defaultState?.length > 0) {
            setSelectedBState(defaultState[0])
            const city = await getCities({ state_id: defaultState[0].id })
            setBCities(city)
            const defaultCity = city.filter((x) => { return (x.name == data?.city.name) });
            setSelectedBCity(defaultCity.length > 0 ? defaultCity[0] : null)
        }

        setShippingPincode(data?.pincode && Number(data?.pincode))
    }

    const getStatesData = async (data) => {
        // console.log(data);
        getCountry();
        const state = await getStates({ country_id: '101' })
        setStates(state)
        setBStates(state)
        const defaultState = state.filter((x) => { return (x.name == data?.state?.name) });
        if (defaultState?.length > 0) {
            setSelectedState(defaultState[0])
            const city = await getCities({ state_id: defaultState[0].id })
            setCities(city)
            const defaultCity = city.filter((x) => { return (x.name == data?.city?.name) });
            setSelectedCity(defaultCity?.length > 0 ? defaultCity[0] : null)
        }
        setBillingPincode(Number(data?.pincode))
    }

    const getCountry = async () => {
        const response = await apiServices.getCountry();
        setCountries(response?.data)
    }

    const getStates = async (data) => {
        const response = await apiServices.getState(data);
        return response?.data;
    }

    const getCities = async (data) => {
        const response = await apiServices.getCity(data);
        return response?.data;
    }

    const changeUser = (e) => {

        const name = e.target.name;

        if (name == 'facility') {
            console.log('facility', e.value);
            setSelectedFacility(e.value)
        }

        if (name == 'personal_prefix') {
            setSelectedPrefix(e.value)
            setPayloadData({ ...payloadData, [e.target.name]: e.value.name })
        }

        if (name == 'signing_authority_prefix') {
            setSelectedPrefixForSign(e.value)
            setPayloadData({ ...payloadData, [e.target.name]: e.value.name })
        }

        if (name == 'subscription_type') {
            setSelectedSubOptions(e.value)
            setPayloadData({ ...payloadData, [e.target.name]: e.value.code })
        }

        if (name == 'name' || name == 'personal_last_name' || name == 'company_name' || name == 'cup_quantity' || name == 'email' || name == 'packet_cost'
            || name == 'signing_authority_person_name' || name == 'signing_authority_person_last_name' || name == 'signing_authority_designation' || name == 'signing_authority_email' || name == 'signing_authority_pan' || name == 'pan'
            || name == 'amc' || name == 'facility_name' || name == 'latitude' || name == 'longitude'
        ) {
            setPayloadData({ ...payloadData, [e.target.name]: e.target.value })
        }

        if (name == 'signing_authority_mobile') {
            const isValid = isNaN(Number(e.target.value));
            console.log(isValid);
            if (!isValid) {
                setSigningAuthorityMobile(e.target.value)
                setPayloadData({ ...payloadData, [e.target.name]: e.target.value })
            }
        }

        if (name == 'rent_amount' || name == 'cup_cost' || name == 'speciality_rate' || name == 'refundable_deposit') {
            console.log(payloadData);
            setPayloadData({ ...payloadData, [e.target.name]: e.target.value })
        }

        if (name == 'number_of_cves' || name == 'credit_amount') {
            console.log(payloadData);
            setPayloadData({ ...payloadData, [e.target.name]: Number(e.target.value) })
        }

        if (name == 'rental_plan') {
            setSelectedRentleOption(e.value)
            setPayloadData({ ...payloadData, [e.target.name]: e.value.code })
        }

        if (name == 'contact_no') {
            const isValid = isNaN(Number(e.target.value));
            !isValid && setPayloadData({ ...payloadData, [e.target.name]: e.target.value })
        }

        if (name == 'billing_type') {
            setSelectedBillingType(e.value)
            setPayloadData({ ...payloadData, [e.target.name]: e.value.code, cup_cost: 0, packet_cost: 0 })
        }

        if (name == 'minAmtPercent') {
            setPayloadData({ ...payloadData, payment_terms: { ...payloadData?.payment_terms, [e.target.name]: e.target.value } })
        }

        if (name == 'extPeriod') {
            setSelectedExtPeriod(e.target.value);
            setPayloadData({ ...payloadData, payment_terms: { ...payloadData?.payment_terms, [e.target.name]: e.target.value } })
        }

        if (name == 'mode') {
            setSelectedMode(e.value)
            setPayloadData({ ...payloadData, payment_terms: { ...payloadData?.payment_terms, [e.target.name]: e.value.code } })
        }

        if (name == 'prepaid_payment_type') {
            setSelectedPaymentType(e.value)
            setPayloadData({ ...payloadData, payment_terms: { ...payloadData?.payment_terms, [e.target.name]: e.value.code } })
        }

        if (name == 'legal_entity_name') {
            setSelectedTypeOfEntity(e.value)
            setPayloadData({ ...payloadData, [e.target.name]: e.value.name })
        }

        if (name == 'dispensing_option') {
            setselectedDispensingMode(e.value);
            setPayloadData({ ...payloadData, [e.target.name]: e.value.code })
        }

        if (name === 'rfid' || name === 'nfc' || name === 'fingerprint' || name === 'qr' || name === 'onlinePayment' || name === 'password') {
            setPaidOptions({ ...paidOptions, [e.target.name]: e.target.checked })
            setPayloadData({ ...payloadData, [e.target.name]: e.target.checked })
        }

        if (name == 'upi_amount' || name == 'password_key') {
            setPayloadData({ ...payloadData, [e.target.name]: e.target.value })
        }

    }

    const changeBilling = async (e) => {
        const name = e.target.name;

        if (name == 'address' || name == 'address_2' || name == 'gst_no') {
            setBillingData({ ...billingData, [e.target.name]: e.target.value })
        }

        if (name == 'pincode') {
            const isValid = isNaN(Number(e.target.value));
            if (!isValid) {
                const trimmed = e.target.value?.trim()
                setBillingPincode(trimmed)
                setBillingData({ ...billingData, [e.target.name]: trimmed })
            }
        }

        if (name == 'country') {
            setSelectedCountry(e.value)
            setBillingData({ ...billingData, [e.target.name]: e.value.name })
            const state = await getStates({ country_id: e.value.id })
            setStates(state)
            setSelectedCity(null)
            setSelectedState(null)
            setCities(null)
        }

        if (name == 'state') {
            setSelectedState(e.value)
            setBillingData({ ...billingData, [e.target.name]: e.value.name })
            const city = await getCities({ state_id: e.value.id })
            setCities(city)
        }

        if (name == 'city') {
            setSelectedCity(e.value)
            setBillingData({ ...billingData, [e.target.name]: e.value.name })
        }
    }

    const changeShipping = async (e) => {
        const name = e.target?.name;

        if (name == 'address' || name == 'address_2') { setShoppingData({ ...shoppingData, [e.target.name]: e.target.value }) }

        if (name == 'pincode') {
            const isValid = isNaN(Number(e.target.value));
            if (!isValid) {
                const trimmed = e.target.value?.trim();
                setShippingPincode(trimmed)
                setShoppingData({ ...shoppingData, [e.target.name]: trimmed })
            }
        }

        if (name == 'country') {
            setSelectedBCountry(e.value)
            setShoppingData({ ...shoppingData, [e.target.name]: e.value.name })
            const state = await getStates({ country_id: e.value.id })
            setBStates(state)
            setSelectedBCity(null)
            setSelectedBState(null)
            setBCities(null)
        }

        if (name == 'state') {
            setSelectedBState(e.value)
            setShoppingData({ ...shoppingData, [e.target.name]: e.value.name })
            const city = await getCities({ state_id: e.value.id })
            setBCities(city)
        }

        if (name == 'city') {
            setSelectedBCity(e.value)
            setShoppingData({ ...shoppingData, [e.target.name]: e.value.name })
        }
    }

    const changeSigned = (e) => {
        setSigningCheck(e.checked)
        if (e.checked) {
            setSelectedPrefixForSign(selectedPrefix)
            setSigningAuthorityMobile(props.verifiedUserData?.contact)
            setPayloadData({
                ...payloadData,
                'signing_authority_prefix': payloadData?.personal_prefix,
                'signing_authority_person_name': payloadData?.name ? payloadData?.name : '',
                'signing_authority_person_last_name': payloadData?.personal_last_name ? payloadData?.personal_last_name : '',
                'signing_authority_mobile': props.verifiedUserData?.contact,
                'signing_authority_email': payloadData?.email ? payloadData?.email : ''
            })
        } else {
            setSelectedPrefixForSign({ name: 'Mr.' })
            setSigningAuthorityMobile('')
            setPayloadData({
                ...payloadData,
                'signing_authority_prefix': 'Mr.',
                'signing_authority_person_name': '',
                'signing_authority_person_last_name': '',
                'signing_authority_mobile': '',
                'signing_authority_email': ''
            })
        }
    }

    const getFacilitiesDafault = (data) => {
        // console.log(data);
        console.log(billingDefaults);
        if (data?.name == 'None') {

            console.log('Hello');




        } else {

        }
    }

    const getShippingDefaults = (data) => {
        console.log(data?.shipping_address.pincode)
        data?.shipping_address.pincode && setShippingPincode(Number(data?.shipping_address.pincode))
    }

    const getBillingDefaults = (data) => {
        // console.log(data?.facility_billing_details?.pincode);
        setBillingPincode(Number(data?.facility_billing_details?.pincode))


    }

    const submitCustomer = async (e) => {
        e.preventDefault();
        if (isClicked) {
            setIsClicked(false);
            setTimeout(() => { setIsClicked(true) }, 5000);

            let shippingData;

            if (shippingCheck) {
                shippingData = {
                    "address": billingData?.address,
                    "city": billingData?.city,
                    "state": billingData?.state,
                    "pincode": billingData?.pincode,
                    "country": billingData?.country,
                    "location": { "lat": props.latLong?.currentLatitude, "lng": props.latLong?.currentLongitude }
                }
            } else {
                shippingData = { ...shoppingData, country: shoppingData?.country, state: shoppingData?.state, city: shoppingData?.city }
            }

            const lastPayload = { ...payloadData, contact_no: props.verifiedUserData?.contact, billing_address: billingData, shipping_address: shippingData, user_id: props.verifiedUserData?.id };
            console.log("lastPayload", lastPayload);

            const response = await apiServices.saveCustomerData(lastPayload);

            if (response.data?.response_code == 1) {
                props.setCreateUserDialog(false)
                props.openPreferenceFromCust(lastPayload)
                props.getSignedUpData(response?.data?.response_obj);
                hideUserDialog()
                successToast(toast, 'Customer Registered Successfully.');
            } else {
                failureToast(toast, 'Customer Failed to Registered.')
            }
        }
    }

    const hideUserDialog = () => {
        // props.setCreateUserDialog(false)
        setCreateUserDialog(false)
        setBillingData(null)
        setShoppingData(null)
        setCountries(null)
        setStates(null)
        setCities(null)
        setBStates(null)
        setBCities(null)
        setSelectedCountry({ id: '101', sortname: 'IN', name: 'India', phonecode: '91' })
        setSelectedState(null)
        setSelectedCity(null)
        setSelectedBCountry(null)
        setSelectedBState(null)
        setSelectedBCity(null)
        setSelectedBillingType({ name: "Cup Wise", code: "Cupwise" })
        setSelectedMode({ name: "Prepaid", code: "PREPAID" })
        setSelectedPrefix({ name: 'Mr.' })
        setSelectedPrefixForSign({ name: 'Mr.' })
        setSelectedRentleOption(null)
        setBillingPincode('')
        setShippingPincode('')
        setBillingDefaults(null)
        setDefaultUser(null)
        setSigningAuthorityMobile('')
        setSelectedTypeOfEntity(entityOptions[0])
        setSigningCheck(false)
        setPayloadData(defaultValues)
        setSelectedSubOptions({ name: 'Free', code: 'FREE' });
        setselectedDispensingMode({ name: 'Free', code: 'FREE' })
        setPaidOptions({ rfid: false, nfc: false, onlinePayment: false, password: false })
        setIsFacSelected(false)
    }

    const userFooterDialog = (
        <>
            {/* <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideUserDialog}/> */}
            <Button label="Submit" icon="pi pi-check" type='submit' form="cust-form" className="p-button-success p-mr-2 p-mb-2" />
        </>
    );



    return (

        <>
            <Toast position='center' ref={toast} />
            <Dialog visible={createUserDialog} style={{ width: '1250px' }} header={`User Details ( +91 ${props.verifiedUserData?.contact})`} modal footer={userFooterDialog} className='p-fluid' onHide={() => { hideUserDialog() }} maximizable={true} blockScroll={true}>
                <form onSubmit={submitCustomer} id="cust-form">
                    {props.selectedFac?.name && <h6>Facility : {props.selectedFac?.name}</h6>}
                    <h5>Personal Information</h5>
                    <div className="p-grid">
                        <div className="p-col-2">
                            <div className="p-field">
                                <label htmlFor="prefix">Prefix</label>
                                <Dropdown id="prefix" name="personal_prefix" value={selectedPrefix} onChange={changeUser} options={prefixOptions} optionLabel="name" placeholder="Prefix"
                                    className="multiselect-custom" disabled={defaultUser?.ctvm_user_data?.prefix ? true : false} required />
                            </div>
                        </div>
                        <div className="p-col-5">
                            <div className="p-field">
                                <label htmlFor="name">First Name</label>
                                <InputText id="name" name="name" placeholder="First Name" onChange={changeUser} defaultValue={defaultUser?.ctvm_user_data?.name ? defaultUser?.ctvm_user_data?.name : ''} disabled={defaultUser?.ctvm_user_data?.name ? true : false} required />
                            </div>
                        </div>
                        <div className="p-col-5">
                            <div className="p-field">
                                <label htmlFor="lname">Last Name</label>
                                <InputText id="lname" name="personal_last_name" placeholder="Last Name" onChange={changeUser} defaultValue={defaultUser?.ctvm_user_data?.lastName ? defaultUser?.ctvm_user_data?.lastName : ''} disabled={defaultUser?.ctvm_user_data?.lastName ? true : false} required />
                            </div>
                        </div>
                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="email">Email Id</label>
                                <InputText id="email" type="email" name="email" placeholder="Email Id" onChange={changeUser} defaultValue={defaultUser?.ctvm_user_data?.email ? defaultUser?.ctvm_user_data?.email : ''} disabled={defaultUser?.ctvm_user_data?.email ? true : false} required />
                            </div>
                        </div>
                    </div>
                    <hr></hr>

                    <h5>BILLING INFORMATION</h5>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="p-field">
                                <label htmlFor="address1">Address</label>
                                <InputText id="address1" name="address" placeholder="Address" onChange={changeBilling} defaultValue={billingDefaults?.addr ? billingDefaults?.addr : ''} disabled={isFacSelected && billingDefaults?.addr ? true : false} required />
                            </div>
                        </div>
                        {/* disabled={isFacSelected && billingDefaults?.addr} */}
                        {/* <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="address_2">Address</label>
                                    <InputText id="address_2" name="address_2" placeholder="Address" onChange={changeBilling} required/>
                                </div>
                            </div> */}
                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="country1">Country</label>
                                <Dropdown id="Country1" name="country" value={selectedCountry} onChange={changeBilling} options={countries} optionLabel="name"
                                    disabled={isFacSelected && selectedCountry} placeholder="Select Country" filter className="multiselect-custom" required />
                            </div>
                        </div>
                        {/* disabled={isFacSelected && selectedCountry} */}
                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="state1">State</label>
                                <Dropdown id="state1" name="state" value={selectedState} onChange={changeBilling} options={states} optionLabel="name"
                                    disabled={isFacSelected && selectedState}
                                    placeholder="Select State" filter className="multiselect-custom" required />
                            </div>
                        </div>
                        {/* disabled={isFacSelected && selectedState} */}
                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="city1">City</label>
                                <Dropdown id="city1" name="city" value={selectedCity} onChange={changeBilling} options={cities} optionLabel="name"
                                    disabled={isFacSelected && selectedCity}
                                    placeholder="Select City" filter className="multiselect-custom" required />
                            </div>
                        </div>
                        {/* disabled={isFacSelected && selectedCity} */}
                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="pincode1">Pincode  </label>
                                <InputText type="text" id="pincode1" name="pincode" placeholder="Pincode" minLength={6} maxLength={6} value={billingPincode ? billingPincode : ''}
                                    disabled={isFacSelected && billingPincode}
                                    onChange={changeBilling} required />
                            </div>
                        </div>
                        {/* disabled={isFacSelected && billingPincode} */}
                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="gst_no1">GST No <small className="p-error">(example : 18AABCU9603R1ZM) </small> </label>
                                <InputText type="text" id="gst_no1" name="gst_no" placeholder="GST No" minLength={15} maxLength={15} pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                                    defaultValue={billingDefaults?.gstnumber ? billingDefaults?.gstnumber : ''} onChange={changeBilling} />
                            </div>
                        </div>
                        {/* disabled={isFacSelected && billingDefaults?.gstnumber != 'NA'} */}
                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="panCard">PAN Id <small className="p-error">(exmaple : ABCTY1234D)</small></label>

                                <InputText id="panCard" name="pan" placeholder="Pan Card" pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" onChange={changeUser}
                                // disabled={payloadData?.pan != '' ? true : false}
                                />
                            </div>
                        </div>

                    </div>
                    <hr></hr>
                    <h5>SHIPPING INFORMATION {!props.selectedFac && <small>(same as billing <Checkbox onChange={(e) => { setShippingCheck(e.checked) }} checked={shippingCheck}></Checkbox> )</small>}</h5>
                    {
                        !shippingCheck ?
                            <>
                                <div className="p-grid">
                                    <div className="p-col-6">
                                        <div className="p-field">
                                            <label htmlFor="facility_name">Facility Name</label>
                                            <InputText id="facility_name" name="facility_name" placeholder="Facility Name" onChange={changeUser} defaultValue={payloadData?.facility_name ? payloadData.facility_name : ''} disabled={defaultUser && isFacSelected && payloadData.facility_name && payloadData.facility_name != '' ? true : false} required />
                                        </div>
                                    </div>

                                    <div className="p-col-6">
                                        <div className="p-field">
                                            <label htmlFor="address1">Address</label>
                                            <InputText id="address1" name="address" placeholder="Address" onChange={changeShipping} defaultValue={shippingDefaults?.address ? shippingDefaults?.address : ''} disabled={shippingDefaults?.address && shippingDefaults?.address != '' ? true : false} required />
                                        </div>
                                    </div>

                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="country">Country</label>
                                            <Dropdown id="country" name="country" value={selectedBCountry} onChange={changeShipping} options={countries} optionLabel="name" placeholder="Select Country" filter className="multiselect-custom" disabled={isFacSelected && selectedBCountry} required />
                                        </div>
                                    </div>
                                    {/* disabled={isFacSelected && selectedBCountry} */}
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="state">State</label>
                                            <Dropdown id="state" name="state" value={selectedBState} onChange={changeShipping} options={bStates} optionLabel="name" placeholder="Select States" filter className="multiselect-custom" disabled={isFacSelected && selectedBState} required />
                                        </div>
                                    </div>
                                    {/* disabled={isFacSelected && selectedBState} */}
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="city">City</label>
                                            <Dropdown id="city" name="city" value={selectedBCity} onChange={changeShipping} options={bCities} optionLabel="name" placeholder="Select City" filter className="multiselect-custom" disabled={isFacSelected && selectedBCity} required />
                                        </div>
                                    </div>
                                    {/* disabled={isFacSelected && selectedBCity} */}
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="pincode">Pincode</label>
                                            <InputText type="text" id="pincode" name="pincode" placeholder="Pincode" minLength={6} maxLength={6} value={shippingPincode} disabled={isFacSelected && shippingPincode} onChange={changeShipping} required />
                                        </div>
                                    </div>
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="latitude">Latitude</label>
                                            <InputText type="text" id="latitude" name="latitude" placeholder="Latitude" disabled={ isFacSelected && defaultUser && payloadData?.latitude ? true : false} defaultValue={payloadData?.latitude} onChange={changeUser} required />
                                        </div>
                                    </div>
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="longitude">Longitude</label>
                                            <InputText type="text" id="longitude" name="longitude" placeholder="Longitude" disabled={ isFacSelected && defaultUser && payloadData?.longitude ? true : false} defaultValue={payloadData?.longitude} onChange={changeUser} required />
                                        </div>
                                    </div>
                                </div>
                                {/* disabled={isFacSelected && shippingPincode} */}
                            </>
                            :
                            <>
                                <div className="p-grid">
                                    <div className="p-col-6">
                                        <div className="p-field">
                                            <label htmlFor="facility_name">Facility Name</label>
                                            <InputText id="facility_name" name="facility_name" placeholder="Facility Name" onChange={changeUser} defaultValue={payloadData?.facility_name ? payloadData.facility_name : ''} required />
                                        </div>
                                    </div>
                                    <div className="p-col-6">
                                        <div className="p-field">
                                            <label htmlFor="addre">Address</label>
                                            <InputText id="addre" name="address" placeholder="Address" defaultValue={billingData?.address} disabled />
                                        </div>
                                    </div>
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="country">Country</label>
                                            <InputText id="country" name="country" placeholder="Country" value={selectedCountry?.name} disabled />
                                        </div>
                                    </div>
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="state">State</label>
                                            <InputText id="state" name="state" placeholder="State" value={selectedState?.name} disabled />
                                        </div>
                                    </div>
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="city">City</label>
                                            <InputText id="city" name="city" placeholder="City" value={selectedCity?.name} disabled />
                                        </div>
                                    </div>
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="pincode">Pincode</label>
                                            <InputText type="text" id="pincode" name="pincode" placeholder="Pincode" minLength={6} maxLength={6} value={billingPincode ? billingPincode : ''} disabled />
                                        </div>
                                    </div>
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="latitude">Latitude</label>
                                            <InputText type="text" id="latitude" name="latitude" placeholder="Latitude" defaultValue={payloadData?.latitude} onChange={changeUser} required />
                                        </div>
                                    </div>
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="longitude">Longitude</label>
                                            <InputText type="text" id="longitude" name="longitude" placeholder="Longitude" defaultValue={payloadData?.longitude} onChange={changeUser} required />
                                        </div>
                                    </div>
                                </div>
                            </>
                    }

                    <hr></hr>
                    <h5>COMMERCIAL INFORMATION</h5>
                    <div className="p-grid">
                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="company_name">Company Name</label>
                                <InputText id="company_name" name="company_name" placeholder="Company Name" onChange={changeUser} defaultValue={defaultUser?.ctvm_user_data?.billing_details?.company ? defaultUser?.ctvm_user_data?.billing_details?.company : ''} disabled={defaultUser?.ctvm_user_data?.billing_details?.company ? true : false} required />
                            </div>
                        </div>
                        {/* disabled={isFacSelected && defaultUser?.ctvm_user_data?.billing_details?.company} */}
                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="billing_type">Billing Type</label>
                                <Dropdown id="billing_type" name="billing_type" value={selectedBillingType} onChange={changeUser} options={billingTypeOptions} optionLabel="name" placeholder="Select Billing Type" filter className="multiselect-custom" disabled={defaultUser?.ctvm_user_data?.billing_pattern ? true : false} required />
                            </div>
                        </div>
                        {/* disabled={isFacSelected && selectedBillingType} */}
                        {
                            selectedBillingType?.code == "Cupwise" &&
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="cup_cost">Cup Cost</label>
                                    <InputText id="cup_cost" type="number" name="cup_cost" placeholder="Cup Cost" step="0.01" min={4} max={100} onChange={changeUser} defaultValue={defaultUser?.ctvm_user_data?.cup_cost ? Number(defaultUser?.ctvm_user_data?.cup_cost) : 0} disabled={defaultUser?.ctvm_user_data?.cup_cost ? true : false} 
                                    onWheelCapture={(e) => { e.target.blur() }}
                                    required />
                                </div>
                            </div>
                        }

                        {
                            selectedBillingType?.code == "PacketWise" &&
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="packet_cost">Packet Cost</label>
                                    <InputText id="packet_cost" type="number" name="packet_cost" placeholder="Packet Cost" min={200} max={9999} onChange={changeUser} defaultValue={defaultUser?.ctvm_user_data?.packet_cost ? defaultUser?.ctvm_user_data?.packet_cost : 0} disabled={defaultUser?.ctvm_user_data?.packet_cost ? true : false} onWheelCapture={(e) => { e.target.blur() }} required />
                                </div>
                            </div>
                        }
                        {/* disabled={isFacSelected && defaultUser?.ctvm_user_data?.packet_cost} */}
                        <div className="p-col-4">
                            <div className="pdd-field">
                                <label htmlFor="cup_quantity">Beverage Quantity (ml)</label>
                                <InputText id="cup_quantity" type="number" name="cup_quantity" placeholder="Beverage Quantity" defaultValue={payloadData?.cup_quantity ? payloadData?.cup_quantity : 40} min={40} max={300} onChange={changeUser} disabled={defaultUser?.ctvm_user_data?.beverages_offset ? true : false} onWheelCapture={(e) => { e.target.blur() }} />
                            </div>
                        </div>

                        {/* disabled={isFacSelected && payloadData?.cup_quantity} */}
                        {/* <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="amc">AMC (INR)</label>
                                <InputText id="amc" type="number" name="amc" placeholder="AMC (INR)" defaultValue={payloadData?.amc ? payloadData?.amc : 0} min={0} max={100000} onChange={changeUser} />
                            </div>
                        </div> */}

                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="Subscription_plan">Subscription Plan</label>
                                <Dropdown id="Subscription" name="subscription_type" value={selectedSubOption} onChange={changeUser} options={subScriptionOptions} optionLabel="name" placeholder="Subscription Plan" filter className="multiselect-custom" required />
                            </div>
                        </div>
                        {/* disabled={isFacSelected && selectedSubOption} */}
                        {
                            selectedSubOption?.code == 'RENT' &&
                            <>
                                <div className="p-col-4">
                                    <div className="p-field">
                                        <label htmlFor="rental_plan">Maintenance Plan</label>
                                        <Dropdown id="rental_plan" name="rental_plan" value={selectedRentleOption} onChange={changeUser} options={rentleOptions} optionLabel="name" placeholder="Maintenance Plan" filter className="multiselect-custom" required />
                                    </div>
                                </div>
                                {/* disabled={isFacSelected && selectedRentleOption} */}
                                <div className="p-col-4">
                                    <div className="p-field">
                                        <label htmlFor="rent_amount">Maintenance Charge (INR)</label>
                                        <InputText id="rent_amount" type="number" name="rent_amount" placeholder="Maintenance Charge" min={0} max={99999} onWheelCapture={(e) => { e.target.blur() }} onChange={changeUser} required />
                                    </div>
                                </div>
                            </>
                        }

                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="refundable_deposit">Refundable Security Deposit (INR)</label>
                                <InputText id="refundable_deposit" type="number" name="refundable_deposit" placeholder="Security Deposit" defaultValue={0} onWheelCapture={(e) => { e.target.blur() }} min={0} step={1} max={99999} onChange={changeUser} required />
                            </div>
                        </div>

                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="number_of_cves">No of CVES (Tapri KIOSK)</label>
                                <InputText id="number_of_cves" type="number" name="number_of_cves" placeholder="No of CVES (Tapri KIOSK)" defaultValue={1} min={1} max={100} onChange={changeUser} onWheelCapture={(e) => { e.target.blur() }} required />
                            </div>
                        </div>

                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="speciality_rate">Speciality Cost (INR)</label>
                                <InputText id="speciality_rate" type="number" name="speciality_rate" placeholder="Speciality Cost (INR)" defaultValue={payloadData?.speciality_rate ? Number(payloadData?.speciality_rate) : 0} step="0.01" disabled={defaultUser?.user_data?.speciality_cost ? true : false} min={0} max={100000} onChange={changeUser} onWheelCapture={(e) => { e.target.blur() }} />
                            </div>
                        </div>

                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="typeOfEntity">Type of Entity</label>
                                <Dropdown id="typeOfEntity" name="legal_entity_name" value={selectedTypeOfEntity} onChange={changeUser} options={entityOptions} optionLabel="name" placeholder="Type of Entity" filter className="multiselect-custom" required />
                            </div>
                        </div>

                    </div>
                    <hr></hr>
                    <h5>PAYMENT TERMS</h5>
                    <div className="p-grid">
                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="mode">Mode</label>
                                <Dropdown id="mode" name="mode" value={selectedMode} onChange={changeUser} options={paymentTermOptions} optionLabel="name" placeholder="Select Mode" filter className="multiselect-custom" disabled={defaultUser?.ctvm_user_data?.payemntTerms?.mode} required />
                            </div>
                        </div>
                        {/* disabled={isFacSelected && selectedMode} */}
                        {
                            (selectedMode?.code == "PREPAID" || selectedMode?.code == "PARTIAL") &&
                            <>
                                <div className="p-col-4">
                                    <div className="p-field">
                                        <label htmlFor="prepaid_payment_type">Payment Type</label>
                                        <Dropdown id="prepaid_payment_type" name="prepaid_payment_type" value={selectedPaymentType} onChange={changeUser} options={paymentTypeOptions} optionLabel="name" placeholder="Select Mode" filter className="multiselect-custom" required />
                                    </div>
                                </div>
                            </>
                        }
                        {/* disabled={isFacSelected && selectedPaymentType}  */}
                        {
                            (selectedMode?.code == "POSTPAID" || selectedMode?.code == "PARTIAL") &&
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="extPeriod">Extension Period</label>

                                    <Dropdown id="extPeriod" name="extPeriod" value={seletedExtPeriod} onChange={changeUser} options={extentionPeriodOptions} optionLabel="value" placeholder="Extension Period" filter className="multiselect-custom" disabled={defaultUser?.ctvm_payment_terms?.extPeriod ? true : false} required />

                                </div>
                            </div>
                        }

                        {
                            selectedMode?.code == "POSTPAID" &&
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="extPeriod">Credit Limit</label>
                                    <InputText id="credit_amount" type="number" name="credit_amount" placeholder="Credit Limit" defaultValue={0} onWheelCapture={(e) => { e.target.blur() }} min={1} max={100000} onChange={changeUser} required />
                                </div>
                            </div>
                        }

                        {/* disabled={isFacSelected && defaultUser?.ctvm_payment_terms?.extPeriod} */}
                        {
                            selectedMode?.code == "PARTIAL" &&
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="minAmtPercent">Min Amt Percent</label>
                                    <InputText type="number" id="minAmtPercent" onWheelCapture={(e) => { e.target.blur() }}name="minAmtPercent" placeholder="Min Amt Percent" min={1} max={99} onChange={changeUser} defaultValue={defaultUser?.ctvm_payment_terms?.minAmtPercent ? Number(defaultUser?.ctvm_payment_terms?.minAmtPercent) : ''} disabled={defaultUser?.ctvm_payment_terms?.minAmtPercent ? true : false} required />
                                </div>
                            </div>
                        }
                    </div>
                    <hr></hr>

                    <hr></hr>
                    <h5>DISPENSING OPTIONS</h5>
                    <div className="p-grid">
                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="dispensing_option">Dispensing Mode</label>
                                <Dropdown id="dispensing_option" name="dispensing_option" value={selectedDispensingMode} onChange={changeUser} options={dispensingOptions} optionLabel="name" placeholder="Select Dispensing Mode" filter className="multiselect-custom" required />
                            </div>
                        </div>

                        {
                            selectedDispensingMode?.code == "PAID" &&

                            <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="bev-set">Beverage Setting</label>
                                    <div className='p-grid'>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-field-checkbox">
                                                <Checkbox id="rfid" name="rfid" checked={paidOptions.rfid} onChange={changeUser} />
                                                <label>RFID</label>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-field-checkbox">
                                                <Checkbox name="nfc" checked={paidOptions.nfc} onChange={changeUser} />
                                                <label>NFC</label>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-md-2">
                                            <div className="p-field-checkbox">
                                                <Checkbox name="onlinePayment" checked={paidOptions.onlinePayment} onChange={changeUser} />
                                                <label>UPI Payment</label>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-md-2">
                                            <div className="p-field-checkbox">
                                                <Checkbox name="password" checked={paidOptions.password} onChange={changeUser} />
                                                <label>Password</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                        {selectedDispensingMode?.code == "PAID" && paidOptions.onlinePayment && <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="upi_amount">Online Cup Cost</label>
                                <InputText id="upi_amount" type="number" name="upi_amount" placeholder="UPI Amount" min={4} max={100} onChange={changeUser} defaultValue={defaultUser?.ctvm_user_data?.cup_cost ? Number(defaultUser?.ctvm_user_data?.cup_cost) : 0} onWheelCapture={(e) => { e.target.blur() }} required />
                            </div>
                        </div>}
                        {selectedDispensingMode?.code == "PAID" && paidOptions.password && <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="password_key">Password Key</label>
                                <InputText id="password_key" type="number" name="password_key" placeholder="Password Key" min={999} onWheelCapture={(e) => { e.target.blur() }} max={9999} onChange={changeUser} required />
                            </div>
                        </div>}
                    </div>
                    <hr></hr>


                    <h5>SIGNING AUTHORITY INFORMATION <small> (Same as Personal <Checkbox onChange={(e) => { changeSigned(e) }} checked={signingCheck}></Checkbox>)</small> </h5>
                    <div className="p-grid">
                        <div className="p-col-2">
                            <div className="p-field">
                                <label htmlFor="prefix">Prefix</label>
                                <Dropdown id="prefix" name="signing_authority_prefix" value={selectedPrefixForSign} onChange={changeUser} options={prefixOptions} optionLabel="name" placeholder="Prefix" className="multiselect-custom" required />
                            </div>
                        </div>
                        <div className="p-col-5">
                            <div className="p-field">
                                <label htmlFor="auth_name">First Name</label>
                                <InputText id="auth_name" name="signing_authority_person_name" placeholder="First Name" onChange={changeUser} value={payloadData?.signing_authority_person_name ? payloadData?.signing_authority_person_name : ''} required />
                            </div>
                        </div>
                        <div className="p-col-5">
                            <div className="p-field">
                                <label htmlFor="auth_name">Last Name</label>
                                <InputText id="auth_name" name="signing_authority_person_last_name" placeholder="Last Name" onChange={changeUser} value={payloadData?.signing_authority_person_last_name ? payloadData?.signing_authority_person_last_name : ''} required />
                            </div>
                        </div>
                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="designation">Designation</label>
                                <InputText id="designation" name="signing_authority_designation" placeholder="Designation" onChange={changeUser} required />
                            </div>
                        </div>
                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="signing_authority_mobile">Mobile Number</label>
                                {/* <InputText id="signing_authority_mobile" name="signing_authority_mobile" placeholder="Mobile Number" maxLength={10} onChange={changeUser} defaultValue={payloadData?.signing_authority_mobile} required /> */}
                                <InputText id="signing_authority_mobile" name="signing_authority_mobile" placeholder="Mobile Number" minLength={10} maxLength={10} value={signingAuthorityMobile} onChange={changeUser} required />
                            </div>
                        </div>
                        <div className="p-col-4">
                            <div className="p-field">
                                <label htmlFor="email">Email Id</label>
                                <InputText id="email" type="email" name="signing_authority_email" placeholder="Email Id" onChange={changeUser} value={payloadData?.signing_authority_email} required />
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    )
}