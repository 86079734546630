import React,{ useState, useEffect} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

import ProductService from "../../service/ProductService";
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices();
const productService = new ProductService();

export const PastOrders = () =>{

    const [products, setProducts] = useState([]);

    useEffect(() => {
        // productService.getProductsSmall().then(data => setProducts(data));
        getData()
    }, []); 

    const getData = async() =>{
        const response = await apiServices.getMyOrders({page:1});
        console.log(response);
    }

    const statusTemplate = (rowData) => {
        return <i className="pi pi-check-circle"></i>
    }

    const trackOrder = (rowData) => {
        return (
            <>
            <span className="p-column-title"></span>
            <div className="actions" style={{display:"flex"}}>
                <Button icon="pi pi-file" title='Log Files' className="p-button-rounded p-button-help " aria-label="Log File"/>
                
            </div>
            </>
        );
    }

    return(
        <>
            <div className="card">
                <DataTable value={products} header="Beverage Orders" responsiveLayout="scroll">
                    <Column field="srno" header="Sr.No." sortable ></Column>
                    <Column field="order_no" header="Order Id" sortable ></Column>
                    <Column field="payment_status" header="Payment Status" sortable ></Column>
                    <Column field="status" header="Order Status" sortable ></Column>
                    <Column field="total_price" header="Total Amount" sortable ></Column>
                    <Column field="createdAt" header="Date" sortable></Column>
                </DataTable>
            </div>
        </>
    )
}