import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices();

export const AddLead = (props) => {
   
    const [useid, setUserid] = useState(null);
    const [leadData, setLeadData] = useState(null);
    const [allLeads, setAllLeads] = useState(null);
    const [leadDialog, setLeadDialog] = useState(false);
    const [leadState, setLeadState] = useState(null);
    const [selectleadState, setSelectleadState] = useState(null);
    const [leadCity, setLeadCity] = useState(null);
    const [selectleadCity, setSelectleadCity] = useState(null);

    const [selectedSector, setSelectedSector ] = useState(null)
    const [selectedExSetupMachine, setSelectedExSetupMachine ] = useState(null)
    const [selectedTypeOfExMachine, setSelectedTypeOfExMachine ] = useState(null)
    const [selectedOnBoardInterest, setSelectedOnBoardInterest ] = useState(null)
    const [selectedMachineBevType, setSelectedMachineBevType ] = useState(null)
    
    const [addLeadTemplate, setAddLeadTemplate ] = useState({})
    const [addLeadShopTemplate, setAddLeadShopTemplate ] = useState({shoparea: "Organised"})
    const [cnoVerify, setCnoVerify ] = useState(false)
    
    const toast = useRef(null);
    
    const sectorTypes = [{ name:"Manufacturing"},{ name:"IT"},{ name:"BPO"},{ name:"Institutions"},{ name:"Other"}]
    const exSetupMachine = [{ name:"Godrej"},{ name:"CCD"},{ name:"HUL"},{ name:"True Value"},{ name:"Chai Point"},{ name:"Lavazza"},{ name:"Fresh and Honest"},{ name:"Local Vendor"},
        { name:"Centen"},{ name:"Out Side"},{ name:"Handmade"},{ name:"Other"}]
    const typeOfExMachine = [{ name:"Fresh Milk"},{ name:"Primix"},{ name:"Fresh Beans(Coffee)"},{ name:"Fresh Tea Leaves"},{ name:"Other"}]
    const onBoardInterest = [{ name:"Interested"},{ name:"Semi-Interested"},{ name:"Not-Interested"},{ name:"Pitch-Again"}]    
    const machineBevType = [{ name:"Cold"},{ name:"Hot"},{ name:"Cold & Hot"},{ name:"Fresh Milk"},{ name:"Beans Machine"},{ name:"Other"},]


    useEffect(()=>{ 
        setLeadDialog(props.leadDialog)
        getState(props.leadDialog)
    },[props.leadDialog])
    
    useEffect(()=>{
        getLeadData(props.selectedLead)
        console.log(props.selectedLead);
    },[props.selectedLead])


    const getLeadData = async(data) =>{
        if(data){
            setLeadData(data)
            setSelectleadState(data?.addr?.state)
            const response = await apiServices.getCity({state_id: data?.addr?.state?.id});  
            setLeadCity(response.data)
            setSelectleadCity(data?.addr?.city)
            data.shopdetails?.sector ? setSelectedSector({name:data.shopdetails?.sector}) : setSelectedSector(null)
            data.shopdetails?.machine ? setSelectedExSetupMachine({name : data.shopdetails?.machine }) : setSelectedExSetupMachine(null)
            data.shopdetails?.machinetype ? setSelectedTypeOfExMachine({name : data.shopdetails?.machinetype }) : setSelectedTypeOfExMachine(null)
            data.install ? setSelectedOnBoardInterest({ name : data.install }) : setSelectedOnBoardInterest(null)
            data.shopdetails?.want ? setSelectedMachineBevType({name : data.shopdetails?.want}) : setSelectedMachineBevType(null)

        }else{
            setLeadData(null)
            setSelectleadState(null)
            setSelectleadCity(null)
            setSelectedSector(null)
            setSelectedExSetupMachine(null)
            setSelectedTypeOfExMachine(null)
            setSelectedOnBoardInterest(null)
            setSelectedMachineBevType(null)
        }
    }



    const getState = async(data) =>{
        if(data){
            const response = await apiServices.getState({country_id: 101});  
            setLeadState(response?.data)
        }
    }
    
    const success = (msg) =>{
        toast.current.show({ severity: 'success', summary: 'Successful', detail:`${msg}`, life: 3000 });
    } 

    const failure = (msg) => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail:`${msg}`, life: 3000 });
    }

    const leadSubmit = async(e) =>{

        e.preventDefault();

        if(leadData){

            const data1 = {...leadData,...addLeadTemplate,addedBy: {owner: useid?.userid}}
            const data = {...data1,shopdetails:addLeadShopTemplate}
            const response = await apiServices.updateLead(data);  
            response?.data?.success ? success('Lead updated successfully') : failure('Failed to Update')
            
        }else{

            const data = {...addLeadTemplate,addedBy: {owner: useid?.userid},d_id: ["self"],rounds: [],image: {contentType: "image/jpeg"},startDate:new Date().toISOString(),shopdetails:addLeadShopTemplate}

            console.log(data);
            // const response = await apiServices.addLead(data);  
            // response?.data?.success ? success('Lead added successfully') : failure('Failed to Add')
        }

        hideDialog()
        props.getLeads()
    }


    const callVerification = async(data) =>{
        const res = await apiServices.verifyUser({cno: data});
        res?.data?.success ? setCnoVerify(true) : setCnoVerify(false)
    }

    const toVerifyNumber = (e) =>{
        if(Number(e.target.value)){
            setCnoVerify(false)
            const contact = '+91'+ e.target.value;
            setAddLeadTemplate({...addLeadTemplate,[e.target.name]:contact})
            if(e.target.value.length >= 10){
                callVerification(contact)
            }            
        }else if(!Number(e.target.value)){
            setCnoVerify(true)
        }
    }

    const changeLeadShop = (e) =>{
        setAddLeadShopTemplate({...addLeadShopTemplate,[e.target.name]:e.target.value})
    }

    const changeLeadCno = (e) =>{
        setAddLeadTemplate({...addLeadTemplate,[e.target.name]:e.target.value})
    }

    const changeLeadTem = (e) =>{
        setAddLeadTemplate({...addLeadTemplate,addr:{...addLeadTemplate.addr,[e.target.name]:e.target.value}})
    }
    
    const setLStateFn = async(e) =>{
        setSelectleadState(e.value)
        const response = await apiServices.getCity({state_id: e.value.id});  
        setLeadCity(response.data)
        setAddLeadTemplate({...addLeadTemplate,addr:{...addLeadTemplate.addr,[e.target.name]:e.value}})
    }

    const setLCityFn = (e) =>{
        setSelectleadCity(e.value)
        setAddLeadTemplate({...addLeadTemplate,addr:{...addLeadTemplate.addr,[e.target.name]:e.value}})
    }


    const changeSectorFn = (e) =>{
        setSelectedSector(e.value)
        setAddLeadShopTemplate({...addLeadShopTemplate,[e.target.name]:e.target.value.name})
    }

    const changeExSetFun = (e) =>{
        setSelectedExSetupMachine(e.value)
        setAddLeadShopTemplate({...addLeadShopTemplate,[e.target.name]:e.target.value.name})
    }

    const changeTypeExFn = (e) =>{
        setSelectedTypeOfExMachine(e.value)
        setAddLeadShopTemplate({...addLeadShopTemplate,[e.target.name]:e.target.value.name})
    }

    const changeLeadInt = (e)=>{
        setSelectedOnBoardInterest(e.value)
        setAddLeadTemplate({...addLeadTemplate,[e.target.name]:e.target.value.name})
    }

    const changeWant = (e) =>{
        setSelectedMachineBevType(e.value)
        setAddLeadShopTemplate({...addLeadShopTemplate,[e.target.name]:e.value.name})
    }

    const hideDialog = () =>{
        props.setLeadDialog(false)
        setLeadDialog(false)
    }


    const addLeadFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={hideDialog} />
            <Button label="Submit" type="submit" form='add-lead-form' icon="pi pi-plus" className="p-button-success" disabled={cnoVerify}/>
        </>
    );

    return (
        <>

        <Toast ref={toast} position='center'/>
         
        <Dialog visible={leadDialog} style={{ width: '850px' }} header={`${leadData ? 'Update' : 'Add'} Organized Lead`} modal  footer={addLeadFooter} onHide={hideDialog} maximizable={true} blockScroll={true}>
            <form onSubmit={leadSubmit} id="add-lead-form" className="p-fluid">
                <h5 className='p-mt-2'>LEAD DETAILS</h5>
                <div className='p-grid'>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label>Comapny Name</label>
                        <InputText type="text" id="shopdetails" name='cname' onChange={changeLeadShop} defaultValue={leadData?.shopdetails?.cname} placeholder='Company Name' autoComplete='off' autoFocus required/>
                    </div>
                    </div>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="end-int">Admin HR Name</label>
                        <InputText type="text" id="info" name='name' placeholder='Admin HR Name' onChange={changeLeadCno}  defaultValue={leadData?.name} autoComplete='off' required/>
                    </div>
                    </div>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="end-int">Email</label>
                        <InputText type="email" id="info" name='email' onChange={changeLeadCno} placeholder='Email Id' defaultValue={leadData?.email} autoComplete='off' required/>
                    </div>
                    </div>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="end-int">Contact</label>
                        <InputText type="text" id="info" name='cno' onChange={toVerifyNumber} placeholder='Contact' minLength={10} maxLength={10} defaultValue={leadData?.cno} autoComplete='off' required/>
                        { cnoVerify && <small className="p-error block">Number Already Exists Or Invalid Number.</small> }     
                    </div>
                    </div>
                </div>

                <h5 className='p-mt-2'>TOTAL NUMBER OF OFFICES PAN INDIA</h5>
                <div className='p-grid'>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label>Number of Offices</label>
                        <InputText type="number" id="shopdetails" name='addrcount' min={0} onChange={changeLeadShop}  defaultValue={leadData?.shopdetails?.addrcount} placeholder='Number of Offices' autoComplete='off' required/>
                    </div>
                    </div>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="end-int">Address</label>
                        <InputText type="text" name='addr' placeholder='Address' onChange={changeLeadTem} defaultValue={leadData?.addr?.addr} autoComplete='off' required/>
                    </div>
                    </div>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="end-int">State</label>
                        <Dropdown optionLabel="name" id='addr' name='state' placeholder="Select State" options={leadState} value={selectleadState} onChange={setLStateFn} filter required></Dropdown>
                    </div> 
                    </div>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="end-int">City</label>
                        <Dropdown optionLabel="name" id='addr' name='city' placeholder="Select City" options={leadCity} value={selectleadCity} onChange={setLCityFn} filter required></Dropdown>
                    </div>
                    </div>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="end-int">Pincode</label>
                        <InputText type="text" id="info" maxLength='6' name='pincode'  onChange={changeLeadShop} defaultValue={leadData?.shopdetails?.pincode} placeholder='Pincode' autoComplete='off' required/>
                    </div>
                    </div>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="end-int">Locality</label>
                        <InputText type="text" id="info" name='location' placeholder='location' onChange={changeLeadCno} defaultValue={leadData?.location} autoComplete='off' required/>
                    </div>
                    </div>
                </div>

                <h5 className='p-mt-2'>TYPE OF SECTOR</h5>
                <div className='p-grid'>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label>Sector</label>
                        <Dropdown optionLabel="name" placeholder="Select Sector" name='sector' options={sectorTypes} value={selectedSector} onChange={changeSectorFn} filter required></Dropdown>
                    </div>
                    </div>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="end-int">Total Employee Strength</label>
                        <InputText type="number" min={0} id="info" name='employee' onChange={changeLeadShop} defaultValue={leadData?.shopdetails?.employee} placeholder='Total Employee Strength' autoComplete='off' required/>
                    </div>
                    </div>
                </div>

                <h5 className='p-mt-2'>EXISTING SETUP MACHINE/VENDOR</h5>
                <div className='p-grid'>
                    <div className='p-col-12'>
                    <div className="p-field">
                        <label>Setup</label>
                        <Dropdown optionLabel="name" name='machine' placeholder="Select Setup" options={exSetupMachine} value={selectedExSetupMachine} onChange={changeExSetFun} filter required></Dropdown>
                    </div>
                    </div>
                </div>
                <h5 className='p-mt-2'>TYPE OF EXISTING MACHINE</h5>
                <div className='p-grid'>
                    <div className='p-col-12'>
                    <div className="p-field">
                        <label>Machine Type</label>
                        <Dropdown optionLabel="name" placeholder="Machine Type" name='machinetype' options={typeOfExMachine} value={selectedTypeOfExMachine} onChange={changeTypeExFn} filter required></Dropdown>
                    </div>
                    </div>
                </div>

                <h5 className='p-mt-2'>ARE YOU INTERESTED IN ON-BOARDING CHEREES?</h5>
                <div className='p-grid'>
                    <div className='p-col-12'>
                    <div className="p-field">
                        <label>On-Boarding</label>
                        <Dropdown optionLabel="name" placeholder="On-Boarding" name='install' options={onBoardInterest} value={selectedOnBoardInterest} onChange={changeLeadInt} filter required></Dropdown>
                    </div>
                    </div>
                </div>

                <h5 className='p-mt-2'>MACHINE AND BEVERAGE WANTED FROM US</h5>
                <div className='p-grid'>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label>Machine and Beverage</label>
                        <Dropdown optionLabel="name" placeholder="Machine and Beverage" name='want' options={machineBevType} value={selectedMachineBevType} onChange={changeWant} filter required></Dropdown>
                    </div>
                    </div>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="end-int">Reason</label>
                        <InputText type="text" id="info" name='reason' onChange={changeLeadShop} defaultValue={leadData?.shopdetails?.reason} placeholder='Reason' autoComplete='off' required/>
                    </div>
                    </div>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="end-int">Proposal</label>
                        <InputText type="text" id="info" name='proposal' onChange={changeLeadShop} defaultValue={leadData?.shopdetails?.proposal} placeholder='Proposal' autoComplete='off' required/>
                    </div>
                    </div>
                    <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="end-int">Demo Date</label>
                        <InputText type="date" id="info" name='name' placeholder='Demo Date' value={new Date().toISOString().slice(0, 10)} disabled={true} autoComplete='off'/>
                    </div>
                    </div>

                    {/* <div className='p-col-6'>
                    <div className="p-field">
                        <label htmlFor="end-int">Lead photo</label>
                        <InputText type="file" id="info" name='data' placeholder='Lead Image' onChange={changeLeadImg} />

                    </div>
                    </div> */}


                        {/* <FileUpload mode="basic" name="image" accept="image/*" maxFileSize={1000000} onUpload={changeLeadImg} /> */}
                    
                </div>
            </form>
        </Dialog>


        </>
    )

}