import React, { useState, useRef, useContext, useEffect } from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Chips } from "primereact/chips";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";

import { Dropdown } from "primereact/dropdown";
// Components
import { MaterialDelivery } from "./MaterialDelivery";
import { MaterialOrders } from "./MaterialOrders";
import { WareHouseOrders } from "./WareHouseOrders";
import { WareHouseDelivery } from "./WareHouseDelivery";
// Context
import { permissionContext } from "../../AppWrapper";
// common
import { successToast, failureToast } from "../Common/CommonFunctions";
// Services
import ApiServices from "../../service/api/ApiServices";

const apiServices = new ApiServices()
const initialValue = JSON.parse(localStorage.getItem("user"));

// ====================================================================================================
// ====================================================================================================

export const InventoryCycle = () => {

    // console.log("logged In user ==>", initialValue.userType);
    const rolePermissions = useContext(permissionContext);
    const [switchDialog, setSwitchDialog] = useState(false)
    const [allUser, setAllUser] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [barcodes, setBarcodes] = useState(null);
    const [barcodeArray, setBarcodeArray] = useState(null);
    const [addingTemplate, setAddingTemplate] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [wareHouseList, setWareHouseList] = useState([]);
    const [selectedWareHouse, setSelectedWareHouse] = useState(null);
    const [allocateBarcode, setAllocateBarcode] = useState([]);

    const toast = useRef(null);

    const getAccounts = async () => {
        const response = initialValue.userType == 'owner' ? await apiServices.getUsersByType({ userType: "customer" }) : await apiServices.activeDemoUsers()
        // console.log(response?.data?.data);
        const data = response?.data?.data?.map((x) => { return { ...x, displayName: (x?.billing_details?.company || x?.name) + ' / ' + x?.userid } })
        console.log("data ==>", data);
        setAllUser(data)
    }

    const getWareHouseList = async () => {
        const response = await apiServices.allUsers({ userType: 'wareHouse', platform: "App" });
        console.log("response", response?.data);
        if (response?.data?.success) {
            const data = response?.data?.data?.map((x) => { return { ...x, displayName: x?.name + ' / ' + x?.userid } })
            setWareHouseList(data);
        }
    }

    const changeTemp = (e) => {

        if (e.target.name == 'barcode') {
            setBarcodes(e.value)
            setAddingTemplate({ ...addingTemplate, barcodes: e.value })
        } else if (e.target.name == 'userid') {
            setSelectedUser(e.target.value);
            setAddingTemplate({ ...addingTemplate, customer: e.target.value.userid })
        } else if (e.target.name == 'barcode-array') {
            setBarcodeArray(e.value)
            setAddingTemplate({ ...addingTemplate, barcodeArray: e.value })
        }  else if (e.target.name == 'warehouse-barcode') {
            setAllocateBarcode(e.value)
            setAddingTemplate({ ...addingTemplate, wareHouseBarcode: e.value })
        } 
        else if (e.target.name == 'wareHouseId') {
            setSelectedWareHouse(e.target.value);
            setAddingTemplate({ ...addingTemplate, wareHouseId: e.target.value.userid })
        }
    }


    const openAddDialog = async () => {
        setSwitchDialog(true)
        getAccounts()
        setDefault()
    }

    const hideDialog = () => {
        setSwitchDialog(false)
        setDefault()
    }

    const saveChangeBarcode = async (e) => {
        hideDialog()
        e.preventDefault();
        const response = initialValue.userType == 'operation' ? await apiServices.switchDemoBarcodes(addingTemplate) : await apiServices.switchBarcodes(addingTemplate)
        response?.data?.success ? successToast(toast, 'Barcode Switched Successfully') : failureToast(toast, 'Failed to Switch')
    }


    const submitAddedBarcode = async (e) => {
        hideDialog()
        e.preventDefault()
        console.log({ barcode: barcodeArray });
        const response = await apiServices.updateBarcodeStage({ barcode: barcodeArray })
        response?.data?.success ? successToast(toast, 'Barcode stage updated Successfully') : failureToast(toast, 'Failed to update stage')
    }

    const submitAllocatedBarcode = async (e) => {
        hideDialog()
        e.preventDefault();
        console.log({ barcode: allocateBarcode, wareHouseId: selectedWareHouse?.userid });
        const response = await apiServices.allocateBarcode({ barcode: allocateBarcode, wareHouseId: selectedWareHouse?.userid })
        response?.data?.success ? successToast(toast, 'Barcode stage updated Successfully') : failureToast(toast, 'Failed to update stage')
    }

    const handleTabChange = (e) => {
        setActiveIndex(e.index)
    }

    const setDefault = () => {
        setSelectedUser(null)
        setBarcodes([])
        setAddingTemplate([])
        setBarcodeArray([])
        setAllocateBarcode([])
        setSelectedWareHouse(null)
    }

    const rightToolBarTemplate = (<Button icon="pi pi-cog" onClick={openAddDialog} ></Button>)

    const switchDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger" onClick={() => { hideDialog() }} />
            {((activeIndex === 0 && addingTemplate?.barcodes?.length > 0) || (activeIndex === 1 && addingTemplate?.barcodeArray?.length > 0) || (activeIndex === 2 && addingTemplate?.wareHouseBarcode?.length > 0) ) && <Button label="Submit" type='submit' form='barcode-form' icon="pi pi-check" className="p-button-success" />}
        </>
    );

    useEffect(() => {
        getWareHouseList();
    }, [])


    return (
        <>
            <Toast ref={toast} position='center' />
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        {rolePermissions?.inventorySettingBtn && <Toolbar className="p-mb-4" right={rightToolBarTemplate}></Toolbar>}
                        <TabView>
                            <TabPanel header="Refill Orders">
                                <MaterialOrders wareHouse={wareHouseList} />
                            </TabPanel>
                            <TabPanel header="Refill Delivery">
                                <MaterialDelivery rolePermissions={rolePermissions} />
                            </TabPanel>
                            <TabPanel header="Bulk Orders">
                                <WareHouseOrders rolePermissions={rolePermissions} />
                            </TabPanel>
                            <TabPanel header="Bulk Delivery">
                                <WareHouseDelivery />
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>

            <Dialog visible={switchDialog} style={{ width: '550px' }} header={`Inventory Setting`} modal footer={switchDialogFooter} onHide={() => { hideDialog() }} maximizable={true} blockScroll={true}>
                {initialValue.userType == 'owner' ? < TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
                    <TabPanel header="Swiching Barcodes">
                        <form onSubmit={saveChangeBarcode} id="barcode-form" className="p-fluid">
                            <div className='p-grid'>
                                <div className='p-col-12'>
                                    <div className="p-field">
                                        <label htmlFor="userid">User ID</label>
                                        <Dropdown id="userid" name='userid' filter value={selectedUser} options={allUser} onChange={changeTemp} optionLabel="displayName" placeholder="Select User" autoFocus required></Dropdown>
                                        {!selectedUser && <label style={{ color: "red" }} htmlFor="userid">Please select customer name.</label>}
                                    </div>
                                </div>
                                <div className='p-col-12'>
                                    <div className="p-field">
                                        <label htmlFor="barcode">Enter Barcode</label>
                                        <Chips name="barcode" value={barcodes} onChange={changeTemp} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </TabPanel>
                    <TabPanel header="Update Barcodes Stage (Added)">
                        <form onSubmit={submitAddedBarcode} id="barcode-form" className="p-fluid">
                            <div className='p-grid'>
                                <div className='p-col-12'>
                                    <div className="p-field">
                                        <label htmlFor="barcode">Enter Barcode</label>
                                        <Chips name="barcode-array" value={barcodeArray} onChange={changeTemp} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </TabPanel>

                    <TabPanel header="Update Barcodes Stage (Allocated)">
                        <form onSubmit={submitAllocatedBarcode} id="barcode-form" className="p-fluid">
                            <div className='p-grid'>
                                <div className='p-col-12'>
                                    <div className="p-field">
                                        <label htmlFor="wareHouseId">WareHouse ID</label>
                                        <Dropdown id="wareHouseId" name='wareHouseId' filter value={selectedWareHouse} options={wareHouseList} onChange={changeTemp} optionLabel="displayName" placeholder="Select WareHouse" autoFocus required></Dropdown>
                                        {!addingTemplate?.wareHouseId && <label style={{ color: "red" }} htmlFor="wareHouseId">Please select WareHouse.</label>}
                                    </div>
                                </div>
                                <div className='p-col-12'>
                                    <div className="p-field">
                                        <label htmlFor="barcode">Enter Barcode</label>
                                        <Chips name="warehouse-barcode" value={allocateBarcode} onChange={changeTemp} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </TabPanel>

                </TabView> : < TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
                    <TabPanel header="Swiching Barcodes">
                        <form onSubmit={saveChangeBarcode} id="barcode-form" className="p-fluid">
                            <div className='p-grid'>
                                <div className='p-col-12'>
                                    <div className="p-field">
                                        <label htmlFor="userid">User ID</label>
                                        <Dropdown id="userid" name='userid' filter value={selectedUser} options={allUser} onChange={changeTemp} optionLabel="displayName" placeholder="Select User" autoFocus required></Dropdown>
                                        {!selectedUser && <label style={{ color: "red" }} htmlFor="userid">Please select customer name.</label>}
                                    </div>
                                </div>
                                <div className='p-col-12'>
                                    <div className="p-field">
                                        <label htmlFor="barcode">Enter Barcode</label>
                                        <Chips name="barcode" value={barcodes} onChange={changeTemp} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </TabPanel>

                </TabView>}

            </Dialog>

        </>
    )
}