import React from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
// Components
import { AccountMaterialDetails } from "./AccountMaterialDetails";
import { BevInventoryTop } from "./BevInventoryTop";
// import { CustBevInventoryTop } from "./CustBevInventoryTop";
// const saved = localStorage.getItem("user");
// const initialValue = JSON.parse(saved);

// ====================================================================================================
// ====================================================================================================

export const Inventory = () =>{

    return (
        <>
            {
                // initialValue.userType == 'customer' ? 

                // <div className="card">
                //     <TabView >
                //         <TabPanel header="BEVERAGE INVENTORY AT ACCOUNT FACILITY">
                //             <CustBevInventoryTop/>
                //         </TabPanel>
                //         <TabPanel header="MATERIAL DETAILS">
                //             <AccountMaterialDetails/>
                //         </TabPanel>
                //     </TabView>
                // </div>
                // :
                
                <div className="card">
                    <TabView >
                        {/* <TabPanel header="BEVERAGE INVENTORY ON ACCOUNT">
                            <BevInventoryTop/>
                        </TabPanel> */}
                        <TabPanel header="REFILL INVENTORY ORDERS">
                            <AccountMaterialDetails/>
                        </TabPanel>
                    </TabView>
                </div>
            }

        </>
    )
}