export const bevVendingMchOptions = [
    {name:'TAPRI KIOSK',code:'TAPRI'},
    {name:'TKAFFE KIOSK',code:'TKAFFE'},
    {name:'TAPRI KETTLE',code:'MERI_TAPRI'}
]

export const accountTypeValues = [
    { name: 'ACCOUNT', code:'account' , case: 'Account' },
    // { name: 'FACILITY', code:'facility', case: 'Facility' },
    // { name: 'SUBSCRIPTION', code:'subscription', case: 'Subscription' }
];

export const subTypeOptions = [
    { name : "All", code : "all" },
    { name : "Permanent", code : "permanent" },
    { name : "Demo", code : "demo" }
]

export const durationOptions = [
    { name: 'ALL', code:'all' },
    { name: 'THIS WEEK', code:'this_week' },
    { name: 'THIS MONTH', code:'this_month' },
    { name: 'LAST MONTH', code:'last_month' },
    { name: 'CUSTOM', code:'custom' }
];

export const displayMonthlyOptions = [
    { name: 'Monthly', code: 'monthly' }
]


