import React from 'react';

export const InventoryStats = (props) =>{

    return (
        <>
            <div className="card widget-overview-box widget-overview-box-1">
                <span className="overview-title">
                {props.header}
                </span>
                <div className="p-d-flex p-jc-between">
                    <div className="overview-detail p-d-flex p-jc-between">
                        <div className="overview-text">
                            {  
                                props.loading1 ?
                                <b><i className="pi pi-spin pi-spinner" /></b>:
                                <b>{props.value}</b>
                            }
                        </div>                               
                    </div>
                </div>
                <i className={`${props.icon} side-icon`} style={{ fontSize: '2rem' }}></i>
            </div>
        </>
    )

}