export const constantRoutes = [
    { parent: 'Dashboard', label: 'Overview' },
    { parent: 'Dashboard', label: 'Dashboard' },
    { parent: 'Dashboard', label: 'CVES-Manager' },
    { parent: 'Dashboard', label: 'CVES-onboard' },
    { parent: 'Dashboard', label: 'Orders' },
    { parent: 'Dashboard', label: 'CTVM-Orders' },
    { parent: 'Dashboard', label: 'Employees' },
    { parent: 'Dashboard', label: 'Users' },
    { parent: 'Dashboard', label: 'Analytics' },
    { parent: 'Dashboard', label: 'Inventory' },
    { parent: 'Dashboard', label: 'Customer-Inventory' },
    { parent: 'Dashboard', label: 'Performance' },
    { parent: 'Dashboard', label: 'KAM-Performance' },
    { parent: 'Dashboard', label: 'Transactions' },
    { parent: 'Dashboard', label: 'QR-Transaction' },
    { parent: 'Dashboard', label: 'Rfid-Transaction' },
    { parent: 'Dashboard', label: 'Custom-QR-transaction' },
    { parent: 'Dashboard', label: 'MIS-Report' },
    { parent: 'Dashboard', label: 'MIS' },
    { parent: 'Dashboard', label: 'Products' },
    { parent: 'Dashboard', label: 'Ctvm-Orders' },
    { parent: 'Dashboard', label: 'Review-Order' },
    { parent: 'Dashboard', label: 'Track-Order' },
    { parent: 'Dashboard', label: 'My-Order' },
    { parent: 'Dashboard', label: 'Refill-Order' },
    { parent: 'Dashboard', label: 'Machine-Order' },
    { parent: 'Dashboard', label: 'FgMachines' },
    { parent: 'Dashboard', label: 'fgbeverage' },
    { parent: 'Dashboard', label: 'Inventory-Cycle' },
    { parent: 'Dashboard', label: 'Technoman-Tracking' },
    { parent: 'Dashboard', label: 'Inventory-Setting' },
    { parent: 'Dashboard', label: 'Packages' },
    { parent: 'Dashboard', label: 'Raw-Material' },
    { parent: 'Dashboard', label: 'Admin-Manager' },
    { parent: 'Dashboard', label: 'Contacts' },
    { parent: 'Dashboard', label: 'Ticket-Manager' },
    { parent: 'Dashboard', label: 'Ticket-Analytics' },
    { parent: 'Dashboard', label: 'Billing' },
    { parent: 'Dashboard', label: 'Refill-Orders' }, 
    { parent: 'Dashboard', label: 'Machine-Orders' }, 
    { parent: 'Dashboard', label: 'Leads' }, 
    { parent: 'Dashboard', label: 'Tasks' }, 
    { parent: 'Dashboard', label: 'Fog-Overview' }, 
    { parent: 'Dashboard', label: 'Order-Master' }, 
    { parent: 'Dashboard', label: 'App-Manager' }, 
    { parent: 'Dashboard', label: 'Notifications' }, 
    { parent: 'Dashboard', label: 'CVES-Predictive-Maintenance' }, 
    { parent: 'Dashboard', label: 'Audit-System' }, 
    { parent: 'Dashboard', label: 'Training-Videos' }, 
    { parent: 'Dashboard', label: 'FAQ-details' }, 
    { parent: 'Dashboard', label: 'Sales-Targets' }, 
    { parent: 'Dashboard', label: 'CVES-Actions' },
    { parent: 'Dashboard', label: 'App-Version-Control' },
    { parent: 'Dashboard', label: 'Merchants' },
    

]