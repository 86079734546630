import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
// Services
import ApiServices from './service/api/ApiServices';
const apiServices = new ApiServices()

const AppRightPanel = (props) => {

	const [userData, setUserData] = useState(null);
	const [editUser, setEditUser] = useState(false);
	const [editedData, setEditedData] = useState(null);
	const openEditUserDialog = () =>{setEditUser(true)}
	const hideUserDialog = () =>{setEditUser(false)}
	const toast = useRef(null);
	
	useEffect(()=>{	
		setUserData(props.user)
	},[props.user])
	
	const changeProfile = (e) => {
		let reader = new FileReader();

		if(e.target?.files[0]?.type?.includes('image')){

			if (e.target?.files?.length > 0) {
				let file = e.target.files[0];
				reader.readAsDataURL(file);
				reader.onload = async() => {
					const filePreview = 'data:image/png' + ';base64,' + String(reader.result).split(',')[1];
					console.log(filePreview);
					const response = await apiServices.updateProfilePicture({type:file.type,image:filePreview})	
					console.log(response.data.success);
					if(response?.data?.success){
						props.getUserInfo()
						toast.current.show({ severity: 'success', summary: 'Successful', detail:"Profile Image Uploaded Successfully", life: 3000 });
					}else{
						toast.current.show({ severity: 'error', summary: 'Failed', detail:"Failed to Update", life: 3000 });
					}
				}
			}

		}else{

			toast.current.show({ severity: 'error', summary: 'Failed', detail:"Please select valid file type", life: 3000 });
		}

	}

	const changeInput = (e) =>{
		const changedData = {...editedData,[e.target.name]:e.target.value}
		setEditedData(changedData)
	}
	
	const userSubmit = async(e) =>{
		e.preventDefault()
		const data = {...userData,...editedData}
		const response = await apiServices.updateProfileInfo(data)
		hideUserDialog()
		if(response.data.success){
			props.getUserInfo()
			toast.current.show({ severity: 'success', summary: 'Successful', detail:"User Details Updated Successfully", life: 3000 });
		}else{
			toast.current.show({ severity: 'error', summary: 'Failed', detail:"Failed to Update", life: 3000 });
		}
	}


	const deleteEditUserDialogFooter = (
        <>
			<Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={hideUserDialog}/>
			{/* <Button label="Update" type="submit" form='user-form' icon="pi pi-user-edit" className="p-button-success"/> */}
        </>
    );


	return (
		<>
		<Toast ref={toast} position="center" />
		<div className={classNames('layout-rightmenu', { 'layout-rightmenu-active': props.rightMenuActive })} onClick={props.onRightMenuClick} >
			<button onClick={() => props.onRightMenuActiveChange(false)} className="layout-rightmenu-close p-link">
				<i className="pi pi-times"></i>
			</button>
			<div className="user-detail-wrapper">
				<div className="user-detail-content">
					<label htmlFor="file-input">
						<img src={userData?.image?.data ? userData.image.data:'assets/demo/images/user/default-user.png'} alt="Profile" className="user-image"/>
					</label>
					<input id='file-input' type="file" accept="image/png, image/gif, image/jpeg" style={{display:'none'}} onChange={changeProfile} ></input>
					<span className="user-name">{userData?.name} {userData?.lastName}</span>
					<span className="user-number">{userData?.cno?userData.cno:'-'}</span>
				</div>
				<div className="user-tasks">
					{/* <div className="user-tasks-item in-progress">
						<button className="task-number p-link">23</button>
						<span className="task-name">Progress</span>
					</div>
					<div className="user-tasks-item">
						<button className="task-number p-link">6</button>
						<span className="task-name">Overdue</span>
					</div>
					<div className="user-tasks-item">
						<button className="task-number p-link">38</button>
						<span className="task-name">All deals</span>
					</div> */}
				</div>
			</div>
			{/* <div>
				<Calendar value={date} onChange={(e) => setDate(e.value)} inline></Calendar>
			</div> */}
			<div className="daily-plan-wrapper">
				<ul>
					<li><span className="event-time">Name</span><span className="event-topic">{userData?.name} </span></li>
					<li><span className="event-time">last Name</span><span className="event-topic">{userData?.lastName?userData?.lastName:'-'}</span></li>
					<li><span className="event-time">Email Id</span><span className="event-topic">{userData?.email?userData?.email:'-'}</span></li>
					<li><span className="event-time">Mobile Number</span><span className="event-topic">{userData?.cno?userData?.cno:'-'}</span></li>
					<li><span className="event-time">Address</span><span className="event-topic">{userData?.addr?.city?.name?userData.addr?.city?.name:'-'}</span></li>
				</ul>
			</div>
			<div className='p-mt-4'>
				<Button label="Edit Profile" onClick={openEditUserDialog} style={{width:'100%',background: '#00544D',color: '#ffffff'}} className="p-button-outlined  p-mr-2 p-mb-2" />
			</div>
		</div>

		<Dialog visible={editUser} style={{ width: '550px' }} header="Edit Profile" modal footer={deleteEditUserDialogFooter} onHide={hideUserDialog} className="p-fluid"  maximizable={true} blockScroll={true}>
			<form onSubmit={userSubmit} id="user-form" className="p-fluid">
			<div className='p-grid'>
				<div className='p-col-6'>
					<div className="p-field">
						<label htmlFor="user-first">First Name</label>
						<InputText id="user-first" placeholder='First Name' name='name' defaultValue={userData?.name} onChange={changeInput} required autoFocus disabled />
					</div>
				</div>
				<div className='p-col-6'>
					<div className="p-field">
						<label htmlFor="user-last">Last Name</label>
						<InputText id="user-last" placeholder='Last Name' name='lastName' defaultValue={userData?.lastName?userData?.lastName:null} onChange={changeInput} required disabled />
					</div>
				</div>
				<div className='p-col-6'>
					<div className="p-field">
						<label htmlFor="user-email">Email Id</label>
						<InputText id="user-email" type='email' placeholder='Email Id' name='email' defaultValue={userData?.email?userData?.email:null} onChange={changeInput} disabled />
					</div>
				</div>
				<div className='p-col-6'>
					<div className="p-field">
						<label htmlFor="user-mobile">Mobile Number</label>
						<InputText id="user-mobile" placeholder='Mobile Number' name='cno' defaultValue={userData?.cno?userData?.cno:null} onChange={changeInput} required disabled />
					</div>
				</div>
				{/* <div className='p-col-12'>
					<div className="p-field">
						<label htmlFor="user-address">Address</label>
						<InputText id="user-address" placeholder='Address' defaultValue={userData?.addr?.city?.name?userData.addr?.city?.name:null} required />
					</div>
				</div> */}
			</div>
			</form>
		
		</Dialog>

		</>
	)
}

export default AppRightPanel;