import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { InputTextarea } from "primereact/inputtextarea";
// Common
import { CommonBodyForFormattedStrTable, CommonBodyForTable, commonDialogFooter, commonFormDialogFooter } from "../Common/CommonComponents";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

// ================================================================================================
// ================================================================================================


export const SubscriptionPackages = () => {

    // const packageNameOptions = [
    //     { name:'Daily', code:'daily'},
    //     { name:'Monthly', code:'monthly'},
    //     { name:'Quarterly', code:'quarterly'},
    //     { name:'Half Yearly', code:'halfyearly'},
    //     { name:'Yearly', code:'yearly'},
    // ]

    const [allPackages, setAllPackages] = useState([])
    const [allDeviceNodels, setAllDeviceNodels] = useState([])
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [addPackageDialog, setAddPackageDialog] = useState(false);
    const [selectedModels, setSelectedModels] = useState(null);
    const [addPackageTemplate, setAddPackageTemplate] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [deletePackageDialog, setDeletePackageDialog] = useState(false);
    const toast = useRef(null)

    useEffect(() => {
        getpackages()
        getDeviceModel()
    }, [])

    const getpackages = async () => {
        const response = await apiServices.getAllPackage();
        setAllPackages(response?.data?.package);
    }

    const getDeviceModel = async () => {
        const response = await apiServices.getDeviceModels();
        const filtered = response?.data?.data.map((x, i) => { return { ...x, id: i } })
        setAllDeviceNodels(filtered)
    }

    const openAddPackage = () => {
        setAddPackageDialog(true)
        setSelectedPackage(null)
    }

    const changePackage = (e) => {

        if (e.target.name == 'model') {
            setSelectedModels(e.value)
            const arr = e.value;
            console.log(arr)
            const modelArr = arr?.map((x) => { return { id: x.id, itemName: x.deviceModel } })
            console.log(modelArr)
            setAddPackageTemplate({ ...addPackageTemplate, [e.target.name]: modelArr })
        }

        if (e.target.name == 'packageName' || e.target.name == 'description') {
            setAddPackageTemplate({ ...addPackageTemplate, [e.target.name]: e.target.value })
        }

        if (e.target.name == 'days' || e.target.name == 'extperiod' || e.target.name == 'price') {
            setAddPackageTemplate({ ...addPackageTemplate, [e.target.name]: Number(e.target.value) })
        }

        if (e.target.name == 'created_date') {
            const data = e.target.value;
            console.log(data);
            setAddPackageTemplate({ ...addPackageTemplate, [e.target.name]: isNaN(data) ? new Date(data)?.toISOString() : new Date(new Date)?.toISOString() })
        }
    }

    const openUpdatePackage = (data) => {
        setSelectedPackage(data)
        setAddPackageDialog(true)
        setAddPackageTemplate(data)

        const finalArr = [];
        data?.model.map((x, i) => {
            const itemName = x.itemName;
            const itemIndex = x.id
            allDeviceNodels?.filter((x, ind) => { if (x.deviceModel == itemName && ind == itemIndex) { finalArr.push(x) } })
        })

        console.log(data.model);
        console.log(finalArr);
        setSelectedModels(finalArr)
    }

    const confirmDelete = (data) => {
        setSelectedPackage(data)
        setDeletePackageDialog(true)
    }

    const submitPackage = async (e) => {
        e.preventDefault()
        hideAddDialog()
        const response = selectedPackage ? await apiServices.updatePackage(addPackageTemplate) : await apiServices.addPackage(addPackageTemplate);
        response?.data?.success ? success(`Package ${selectedPackage ? 'Updated' : 'Added'} Successfully`) : error(`Failed to ${selectedPackage ? 'Update' : 'Add'}`)
        getpackages()
    }

    const deletePackage = async (e) => {
        setDeletePackageDialog(false)
        const response = await apiServices.deletePackage({ packageName: selectedPackage.packageName })
        response?.data?.success ? success(`Package is deleted successfully`) : error(`Failed to Delete`)
        getpackages()
    }

    const hideAddDialog = () => {
        setAddPackageDialog(false)
        setSelectedModels(null);
        setAddPackageTemplate(null);
    }

    const header = () => (<></>)

    const srBodyTemplate = (rowData, props) => (<><span className="p-column-title">Sr.No</span>{props.rowIndex + 1}</>)
    const rightToolbarTemplate = () => (<Button className='p-custom-btn' label="ADD PACKAGE" icon="pi pi-plus-circle" iconPos="left" onClick={() => { openAddPackage() }} />)

    const actionBodyTemplate = rowData => (
        <>
            <span className="p-column-title">Action</span>
            <Button icon="pi pi-pencil" title="Edit" className="p-button-rounded p-button-success p-mr-2" onClick={() => { openUpdatePackage(rowData) }} />
            <Button icon="pi pi-trash" title="Delete" className="p-button-rounded p-button-danger" onClick={() => { confirmDelete(rowData) }} />
        </>
    )

    const hideDeleteDialog = () => { setDeletePackageDialog(false) }
    const addDialogFooter = commonFormDialogFooter('Close', hideAddDialog, 'Submit', 'add-form')
    const deleteDialogFooter = commonDialogFooter('Cancel', hideDeleteDialog, 'Yes, Delete it.', deletePackage)

    const success = (msg) => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: `${msg}`, life: 3000 });
    }

    const error = (msg) => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: `${msg}`, life: 3000 });
    }


    return (
        <>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} position='center' />
                        <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate} ></Toolbar>
                        <DataTable value={allPackages} dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Packages" globalFilter={globalFilter}
                            emptyMessage="No Package found." header={header} loading={loading}>
                            <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '6rem' }} ></Column>
                            <Column field="packageName" header="Package Name" body={CommonBodyForFormattedStrTable} sortable ></Column>
                            <Column field="description" header="Subscription" body={CommonBodyForFormattedStrTable} sortable ></Column>
                            <Column field="price" header="Price" body={CommonBodyForTable} sortable ></Column>
                            <Column header="Action" body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            <Dialog visible={addPackageDialog} style={{ width: '550px' }} header={`${selectedPackage ? 'Update' : 'Add'} Package`} modal footer={addDialogFooter} onHide={() => { hideAddDialog() }} maximizable={true} blockScroll={true}>
                <form onSubmit={submitPackage} id="add-form" className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label htmlFor="package-name">Package Name</label>
                                <InputText id="package-name" name="packageName" placeholder="Package Name" defaultValue={selectedPackage?.packageName} onChange={changePackage}></InputText>
                            </div>
                        </div>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label htmlFor="model">Machine Model</label>
                                <MultiSelect id="model" name="model" display="chip" optionLabel="deviceModel" placeholder="Select Model" value={selectedModels} options={allDeviceNodels} onChange={changePackage} showSelectAll={false} />
                            </div>
                        </div>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label htmlFor="noDays">No of Days</label>
                                <InputText id="noDays" type="number" name="days" placeholder="No of Days" defaultValue={selectedPackage?.days} onChange={changePackage}></InputText>
                            </div>
                        </div>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label htmlFor="period">Extension Period</label>
                                <InputText id="period" type="number" name="extperiod" placeholder="Extension Period" defaultValue={selectedPackage?.extperiod} onChange={changePackage}></InputText>
                            </div>
                        </div>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label htmlFor="date">Date</label>
                                <InputText id="date" type="date" name="created_date" placeholder="Date" defaultValue={selectedPackage?.created_date} onChange={changePackage}></InputText>
                            </div>
                        </div>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label htmlFor="price">Price</label>
                                <InputText id="price" type="number" name="price" placeholder="Price" defaultValue={selectedPackage?.price} onChange={changePackage}></InputText>
                            </div>
                        </div>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label htmlFor="description">Description</label>
                                <InputTextarea id="description" name="description" rows={5} cols={30} autoResize defaultValue={selectedPackage?.description} onChange={changePackage} />
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={deletePackageDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={(e) => { setDeletePackageDialog(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br /><br />
                        <b><span>Are you sure you want to delete package?</span></b> <br /><br />
                    </center>
                </div>
            </Dialog>

        </>
    )
}