import React, { useEffect, useState } from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
// Components
import { OngoingOrders } from "./OngoingOrders";
import { PastOrders } from "./PastOrders";
import { RefillOrdersData } from "./RefillOrdersData";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices() 

export const MyOrders = () =>{

    const [ongoingOrders,setOngoingOrders] = useState([])
    const [completedOrders,setCompletedOrders] = useState([])
    const [allMaterials,setAllMaterials] = useState([])

    useEffect(() => {
        getData()
        getProducts()
    }, []); 

    const getData = async() =>{
        // const response = await apiServices.getMyOrders({page:1});
        const response = await apiServices.getCtvmMyOrders({page:0});
        console.log(response?.data);
        setOngoingOrders(response?.data?.response_obj)
        setCompletedOrders(response?.data?.response_obj?.completed)
    }

    const getProducts = async() =>{
        const response = await apiServices.getAllBeverageProducts();
        console.log(response?.data.response_obj?.combos);
        setAllMaterials(response?.data.response_obj?.combos)
    }

    return (
            <>
                <div className="p-grid ">
                    <div className="p-col-12">
                    <div className="card">
                        <TabView>
                        <TabPanel header="Refill Orders">
                            <RefillOrdersData allMaterials={allMaterials}/>
                        </TabPanel>
                        <TabPanel header="CVES Orders">
                            <OngoingOrders ongoingOrders={ongoingOrders} allMaterials={allMaterials}/>
                        </TabPanel>
                        {/* <TabPanel header="CVES Orders (Completed)">
                            <PastOrders/>
                        </TabPanel> */}
                        </TabView>
                    </div>
                    </div>
                </div> 
            </>
    )
    
}
