import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

export const NotFound = (props) => {

	const history = useHistory();

	const goDashboard = () => {
		history.push('/')
	}

	return (
		<div className="exception-body notfound">
			<div className="exception-panel">
				<h1>404</h1>
				<h3>Not Found</h3>
				<p>The page that you are looking for does not exist or you are not allowed to visit.</p>
				<Button className='p-custom-btn' type="button" icon="pi pi-home" label="GO BACK TO HOME" onClick={goDashboard}></Button>

			</div>
			{/* <div className="exception-footer">
				<img src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="exception-logo" alt="expection-logo" />
				<img src={`assets/layout/images/appname-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="exception-appname" alt="expection-appname"/>
			</div> */}
		</div>
	)
}