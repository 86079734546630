import React from 'react';
import { TabPanel } from 'primereact/tabview';
import { TabView } from 'primereact/tabview';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { AccountsWise } from './AccountsWise';
import { NotificationManager } from './NotificationManager';

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ===========================================================================================
// ===========================================================================================

export const Notifications = () => {

	return (
		<>
			<div className='card'>
				<div className="p-grid crud-demo">
					<div className="p-col-12">
					<div className="card">

						{
							initialValue.userType !== 'owner' &&
							<TabView>
								<TabPanel header="Notification Manager">
									<AccountsWise/>
								</TabPanel>
							</TabView>
						}

						{
							initialValue.userType === 'owner' &&
							<TabView>
								<TabPanel header="Notification Manager">
									<AccountsWise/>
								</TabPanel>
								<TabPanel header="Notification Admin">
									<NotificationManager/>
								</TabPanel>
							</TabView>
						}

					</div>
					</div>
				</div>
			</div>
		</>
	)
}
