import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { Chip } from 'primereact/chip';
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Messages } from 'primereact/messages';
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import { CommonForDetails } from "../CRM/Leads";
import { srBodyTemplate, CommonBodyForTable } from "../Common/CommonComponents";
import { commonCloseDialogFooter, commonFormDialogFooter, commonDialogFooter } from "../Common/CommonComponents";
// 
import { successToast, failureToast } from "../Common/CommonFunctions";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

export const ActiveUsers = () => {

    const agreementStatusOptions = [
        { name: 'All', code: 'all' },
        { name: 'Uploaded', code: 'uploaded' },
        { name: 'Pending', code: 'pending' }
    ]

    const customerStatusOptions = [
        { name: 'All', code: 'all' },
        { name: 'Active', code: 'ACTIVE' },
        { name: 'Pending', code: 'PENDING' },
        { name: 'Inactive', code: 'INACTIVE' },
    ]

    const agreementCommentOptions = [
        { name: 'Machine Pullout' },
        { name: 'Customer Signature Pending' },
        { name: 'VIP Customer Agreement Not Created' },
        { name: 'Agreement Not Available' },
        { name: 'No Agreement Needed For Kettle' }
    ]

    const agreementCommentOptionsForFilter = [
        { name: 'All' },
        { name: 'Machine Pullout' },
        { name: 'Customer Signature Pending' },
        { name: 'VIP Customer Agreement Not Created' },
        { name: 'Agreement Not Available' },
        { name: 'No Agreement Needed For Kettle' },
        // { name: 'Other'}
    ]

    const [globalFilter, setGlobalFilter] = useState('');
    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(true);
    const [allUsersByCtvm, setAllUsersByCtvm] = useState(null);
    const [allActiveUsers, setAllActiveUsers] = useState(null);
    const [facilityDialog, setFacilityDialog] = useState(false)
    const [facilityUser, setFacilityUser] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [viewCustomerDialog, setViewCustomerDialog] = useState(false)
    const [customerData, setCustomerData] = useState(null)
    const [editFacData, setEditFacData] = useState(false);
    const [viewFcDialog, setViewFcDialog] = useState(false);
    const [facilityMachines, setFacilityMachines] = useState(null)
    const [agreementDialog, setAgreementDialog] = useState(false)
    const [agreementList, setAgreementList] = useState([])
    const [uploaderDialog, setUploaderDialog] = useState(false)
    const [selectedAccount, setSelectedAccount] = useState(null)
    const [selectedAgreement, setSelectedAgreement] = useState(null)
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false)
    const [toDate, setToDate] = useState(null)
    const [minDate, setMinDate] = useState(null)
    const [selectedAgreementStatus, setSelectedAgreementStatus] = useState(agreementStatusOptions[0])
    const [agreeCommentDialog, setAgreeCommentDialog] = useState(false)
    const [commentPayload, setCommentPayload] = useState(null)
    const [agreeCommentData, setAgreeCommentData] = useState([])
    const [selectedComment, setSelectedComment] = useState(null)
    const [selectedFilteredComment, setSelectedFilteredComment] = useState(agreementCommentOptionsForFilter[0])
    const [isAgreeSigned, setIsAgreeSigned] = useState(true)
    const [orderList, setOrderList] = useState([])
    const [selectedOrder, setSelectedOrder] = useState([])

    const [selectedCustomerStatus, setSelectedCustomerStatus] = useState(customerStatusOptions[1]);
    
    const [selectedAcc, setSelectedAcc] = useState(null)
    const [accountsCves, setAccountsCves] = useState(null);
    const [viewCvesDetailedDialog, setViewCvesDetailedDialog] = useState(false);



    const toast = useRef(null);
    const dt = useRef(null);
    const msgs = useRef(null)

    useEffect(() => {
        getUserByCTVM();
        // getStartDate();

    }, []);

    const getStartDate = () => {
        let date = new Date();
        const yr = date.getFullYear()
        const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const todayDate = `${yr}-${mnth}-${day}`;
        setToDate(todayDate)
    }

    const getUserByCTVM = async () => {
        const res = await apiServices.getActiveZohoUser();
        // const response = await apiServices.getAllAgreements({});
        // const userred = response?.data?.data?.map((x) => { return x.userid })
        // const arr = [];
        // res?.data?.data?.forEach((x) => {
        //     userred?.includes(x?.userid) ? arr.push({ ...x, isAgreementUploaded: true, agreementStatus: 'Uploaded' }) : arr.push({ ...x, isAgreementUploaded: false, agreementStatus: 'Pending' })
        // })
        // console.log(res);

        const filData = res?.data?.data.sort((a, b) => a.name.trim() !== b.name.trim() ? a.name.trim() < b.name.trim() ? -1 : 1 : 0);

        const finalData = filData?.map((x) => { return { ...x, address: x.addr?.addr, company: x?.billing_details?.company } })
        const finalArr = finalData.sort((a, b) => a.company.trim() !== b.company.trim() ? a.company.trim() < b.company.trim() ? -1 : 1 : 0);

        console.log("finalData", finalArr);
        setAllActiveUsers(finalArr);
        const filtered = await finalArr.filter((x) => { return x.userStatus == 'ACTIVE' });
        setAllUsersByCtvm(filtered)
        setLoading(false)
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const viewFacility = async (e, rowData) => {
        console.log(rowData);
        setFacilityUser([])
        callFacility(rowData.userid)
        setFacilityDialog(true);
        setSelectedUser(rowData)
    };

    const callFacility = async (userid) => {
        // setFacilityLoading(true)
        const response = await apiServices.getFacility({ userid: userid });
        const IDdata = response.data.data.map((x, i) => { x.srid = i + 1; return x; });
        console.log(IDdata);
        setFacilityUser(IDdata);
        // setFacilityLoading(false)
    }

    const viewCustomer = (e, rowData) => {
        console.log(rowData);
        setViewCustomerDialog(true)
        setCustomerData(rowData)
    }

    const openViewFc = (rowData) => {
        setEditFacData(rowData)
        setViewFcDialog(true)
        getMachinesByFac(rowData)
    }

    const getMachinesByFac = async (data) => {
        const payload = {
            facid: data?.facid,
            userType: 'customer',
            userid: data?.userid
        }
        const response = await apiServices.getMachineByFacid(payload);
        console.log(response);
        setFacilityMachines(response?.data?.data)
    }

    const openAgreement = async (rowData) => {
        console.log("rowData", rowData);
        const link = `https://creatorapp.zohopublic.com/parimal4/member/record-summary/Customer_View/${rowData.zohoId}/h4waEK7rPqQy1JNO6w4epqtz1Dv27Qqjw7U9B0xB0j2BeDgYWMVPF7vSv2Gh2AmSatjB4JnOeqNge8CRmP8hutDdy2YnV4jdb8GO`;
        window.open(link, '_blank');
        // setLoading1(true)
        // setAgreementDialog(true);
        // setSelectedAccount(rowData)
        // const payload = { "userid": rowData?.userid }
        // getAllAgreements(payload)
        // getAgreeComment(payload)
    }

    const getAgreeComment = async (data) => {
        const response = await apiServices.getAgreeComments(data);
        console.log(response?.data?.data);
        setAgreeCommentData(response?.data?.data)
        const arr = response?.data?.data;
        arr?.length > 0 && msgs?.current?.show([{ severity: 'error', detail: arr[arr.length - 1]?.agreement_comment, sticky: true, closable: false }])
    }

    const getAllAgreements = async (data) => {
        setLoading1(true)
        const response = await apiServices.getAgreements(data);
        console.log(response?.data?.data);
        setAgreementList(response?.data?.data);
        setLoading1(false)
    }

    const openAgreeUploader = async () => {
        setMinDate(null)
        setUploaderDialog(true)
        getOrderList()
    }

    const getOrderList = async () => {
        const reviseNumber = selectedAccount?.cno.replace("+91", "");
        const response = await apiServices.getOrderList({ "contact": reviseNumber });
        console.log(response?.data?.response_obj);
        response?.data?.response_obj && setOrderList(response?.data?.response_obj)
    }

    const viewAgreement = async (rowData) => {
        console.log(rowData);
        const response = await apiServices.sendNewAgreement(rowData);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const downloadURL = window.URL.createObjectURL(blob);
        window.open(downloadURL, '_blank');
    }

    const submitAgreement = async (e) => {
        e.preventDefault();

        const data = new FormData(e.target);
        const multiArr = selectedOrder?.map((x) => { return x.order_no })
        const zohoArr = selectedOrder?.map((x) => { return x.zoho_id })

        data.append("app_orderId", multiArr);
        data.append("zoho_id", zohoArr);

        const response = await apiServices.uploadAgreement(data);
        console.log(response?.data);
        if (response?.data?.success) {
            successToast(toast, 'Agreement Uploaded Successfully')
            const payload = { "userid": selectedAccount?.userid }
            getAllAgreements(payload)
        } else {
            failureToast(toast, 'Failed to Upload')
        }
        setUploaderDialog(false)
    }

    const confirmDeleteAgree = (rowData) => {
        setSelectedAgreement(rowData)
        setConfirmDeleteDialog(true)
    }

    const deleteAgreement = async () => {
        setConfirmDeleteDialog(false)
        const response = await apiServices.deleteAgreement(selectedAgreement);
        console.log(response);
        if (response?.data?.success) {
            successToast(toast, 'Agreement Deleted Successfully')
            setTimeout(() => {
                const payload = { "userid": selectedAccount?.userid }
                getAllAgreements(payload)
            }, 2000);

        } else {
            failureToast(toast, 'Failed to Delete')
        }
    }

    const changeDate = (e) => {
        if (e.target.name == 'startDate') {
            setMinDate(e.target.value)
        }

        if (e.target.name == 'agreement_signed') {
            setIsAgreeSigned(e.checked)
        }

        if (e.target.name == 'app_orderIds') {
            console.log(e.value);
            setSelectedOrder(e.value)
        }
    }

    const changingAgreeStatus = (e) => {
        setSelectedAgreementStatus(e.value)

        switch (e.value.code) {
            case 'all': {
                setAllUsersByCtvm(allActiveUsers)
                break;
            }
            case 'uploaded': {
                const filtered = allActiveUsers.filter((x) => { return x.isAgreementUploaded == true });
                setAllUsersByCtvm(filtered)
                break;
            }
            case 'pending': {
                const filtered = allActiveUsers.filter((x) => { return x.isAgreementUploaded == false });
                setAllUsersByCtvm(filtered)
                setSelectedFilteredComment(agreementCommentOptionsForFilter[0])
                break;
            }
            default:
                break;
        }
    }

    

    const handleStatusChange = (e) => {
        setSelectedCustomerStatus(e.value)

        switch (e.value.code) {
            case 'all': {
                setAllUsersByCtvm(allActiveUsers)
                break;
            }
            case 'ACTIVE': {
                const filtered = allActiveUsers.filter((x) => { return x.userStatus == 'ACTIVE' });
                setAllUsersByCtvm(filtered)
                break;
            }
            case 'INACTIVE': {
                const filtered = allActiveUsers.filter((x) => { return x.userStatus == 'INACTIVE' });
                setAllUsersByCtvm(filtered)
                break;
            }
            case 'PENDING': {
                const filtered = allActiveUsers.filter((x) => { return x.userStatus == 'PENDING' });
                setAllUsersByCtvm(filtered)
                break;
            }
            default:
                break;
        }
    }

    const changingComment = (e) => {
        setSelectedFilteredComment(e.value)


        if (e.value.name == 'All') {
            const filteredData = allActiveUsers.filter((x) => { if (x.isAgreementUploaded == false) { return x; } })
            setAllUsersByCtvm(filteredData)
        } else if (e.value.name == 'Other') {

            const filteredData = allActiveUsers.filter((x) => {
                const otherArr = agreementCommentOptions.some(item => item.name === x.agreement_comment);
                if (!otherArr && x.isAgreementUploaded == false) { return x; }
            })

            setAllUsersByCtvm(filteredData)

        } else {

            const filteredData = allActiveUsers.filter((x) => {
                if ((x.isAgreementUploaded == false) && (x.agreement_comment == e.value.name)) {
                    return x;
                }
            })

            setAllUsersByCtvm(filteredData)
        }
    }

    const openAgreeComment = () => {
        setAgreeCommentDialog(true)
    }

    const changeAgreeC = (e) => {
        if (e.target.name == 'agreement_comment') {
            setSelectedComment(e.value)
            setCommentPayload({ [e.target.name]: e.value?.name })
        }
    }

    const submitAgreementComment = async (e) => {
        e.preventDefault();
        const data = { ...commentPayload, userid: selectedAccount?.userid }
        const response = await apiServices.addAgreeComments(data);
        response?.data?.success ? successToast(toast, 'Comment Added Successfully') : failureToast(toast, 'Comment Failed to Add')
        hideAgreeComment()
        msgs?.current?.clear()
        getAgreeComment({ "userid": selectedAccount?.userid })
    }

    const deleteComment = async (data) => {
        const response = await apiServices.deleteAgreeComments({ _id: data?._id });
        response?.data?.success ? successToast(toast, 'Comment Deleted Successfully') : failureToast(toast, 'Failed to Delete')
        hideAgreeComment()
        msgs?.current?.clear()
        getAgreeComment({ "userid": selectedAccount?.userid })
    }

    // setCommentPayload

    const namebodyTemplate = (data, props) => {
        return (
            <><span className="p-column-title">{props.header}</span>{data?.prefix + " " + data[props.field] + " " + data?.lastName}</>
        )
    };

    const mobileTemplate = (data, props) => {

        const cno = data[props.field]?.length == 10 ? '+91' + data[props.field] : data[props.field];
        return (
            <><span className="p-column-title">{props.header}</span>{cno ? cno : '-'}</>
        )
    };

    
    const viewCVESModal = (rowData) => {
        console.log(rowData);
        setViewCvesDetailedDialog(true)
        setSelectedAcc(rowData)
        getMachinesByAccount(rowData)
    }

    const getMachinesByAccount = async (data) => {
        const payload = { "userType": data?.userType, "userid": data?.userid }
        const response = await apiServices.getMchByAccount(payload)
        console.log(response?.data?.data);
        setAccountsCves(response?.data?.data)
    }


    const actionBodyTemplate = (rowData) => {
        // console.log(rowData._id);
        return (
            <>
                <span className="p-column-title">Actions</span>
                <Button icon="pi pi-eye" title="View Customer" className="p-button-rounded p-button-warning p-mr-2 p-mb-2" onClick={(e) => viewCustomer(e, rowData)} />
                <Button icon="pi pi-th-large" title="Facility" className="p-button-rounded p-button-info p-mr-2 p-mb-2" id={rowData._id} onClick={(e) => viewFacility(e, rowData)} />
                <Button icon="pi pi-file-pdf" title={`${rowData?.zoho_agreement == true ? 'View Agreement' : 'No Agreements Uploaded'}`} className={`${rowData?.zoho_agreement == true ? 'p-button-primary' : 'p-button-danger'}   p-button-rounded p-mr-2 p-mb-2`} onClick={() => { openAgreement(rowData) }} />
                <Button icon="pi pi-info-circle" title='View CVES Details' className="p-button-rounded p-button-warning p-mr-2 p-mb-2" onClick={() => { viewCVESModal(rowData) }} />

                {/* <Button icon="pi pi-file-pdf" title='Agreement' className="p-button-rounded p-button-success p-mr-2 p-mb-2" aria-label="View Agreement" onClick={()=>{openAgreement(rowData)}}/> */}
            </>
        )
    }

    const actionFacBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions" style={{ display: "flex" }}>
                    {/* <span className="p-column-title">Actions</span> */}
                    {<Button icon="pi pi-eye" title="View facility" className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={() => { openViewFc(rowData) }} />}
                </div>
            </>
        );
    };

    const actionAgreeCommentBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions" style={{ display: "flex" }}>
                    <span className="p-column-title">Delete</span>
                    <Button icon="pi pi-trash" title="Delete Comment" className="p-button-rounded p-button-danger p-mr-2 p-mb-2" onClick={() => { deleteComment(rowData) }} />
                </div>
            </>
        );
    };



    const facilityAddressTemplate = (data) => { return (<> <span className="p-column-title">Facility Address</span>{data?.address?.address}</>) }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                <h6 className="p-mt-3 p-mr-5">Active Commercial Accounts</h6>
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV} />
                </div>
            </span>
        </div>
    )

    const hidefacilityDialog = () => { setFacilityDialog(false) }
    const hideCustomerDialog = () => { setViewCustomerDialog(false) }
    const hidefcDialog = () => { setViewFcDialog(false) }
    const hideAgreeDialog = () => { setAgreementDialog(false) }
    const hideUploadAgree = () => {
        setUploaderDialog(false)
        setSelectedOrder([])
        setIsAgreeSigned(true)
    }
    const hideConAgreeDialog = () => { setConfirmDeleteDialog(false) }
    const hideAgreeComment = () => {
        setCommentPayload(null)
        setSelectedComment(null)
        setAgreeCommentDialog(false)
    }
    const hideViewCvesDialog = () =>{ setViewCvesDetailedDialog(false) }

    const cvesDialogFooter = commonCloseDialogFooter('Close', hideViewCvesDialog)

    const facilityDFooter = commonCloseDialogFooter('Cancel', hidefacilityDialog)
    const viewCustomerFooter = commonCloseDialogFooter('Close', hideCustomerDialog)
    const viewFcFooter = commonCloseDialogFooter('Close', hidefcDialog)
    const agreementDialogFooter = commonCloseDialogFooter('Close', hideAgreeDialog)
    // const uploadAgreeDialogFooter = commonFormDialogFooter('Close', hideUploadAgree, 'Submit', 'add-agreement' )
    const deleteAgreeDialogFooter = commonDialogFooter('Cancel', hideConAgreeDialog, 'Delete', deleteAgreement)
    const agreeCommentDialogFooter = commonFormDialogFooter('Close', hideAgreeComment, 'Submit', 'add-agreement-comment')



    const uploadAgreeDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger" onClick={hideUploadAgree} />

            {
                ((!isAgreeSigned && selectedOrder?.length > 0) || isAgreeSigned) &&
                <Button label="Submit" type="submit" form='add-agreement' icon="pi pi-plus" className="p-button-success" />
            }
        </>
    );

    // const deleteAgreeDialogFooter = (
    //     <>
    //         <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={()=>{setConfirmDeleteDialog(false)}}  />
    //         <Button label="Delete" icon="pi pi-trash" className="p-button-success p-mr-2 p-mb-2" onClick={()=>{deleteAgreement()}}/>
    //     </>
    // );

    const agreementRightToolbarTemplate = () => {
        return (
            <>
                <Button label="Upload Agreement" icon="pi pi-upload" className="p-button-rounded p-custom-btn p-mr-2 p-mb-2" onClick={() => { openAgreeUploader() }} />
            </>
        )
    }

    const agreementLeftToolbarTemplate = () => (
        <>
            {
                !selectedAccount?.isAgreementUploaded &&
                <Button label="Add Comment" icon="pi pi-plus" className="p-button-rounded p-custom-btn p-mr-2 p-mb-2" onClick={() => { openAgreeComment() }} />
            }

            {/* <div className='p-grid'>
                <div className='p-col-sm-4'>
                    <Chip label={`Account Name : ${selectedAccount?.company ? selectedAccount?.company : 'NA'}`} icon="pi pi-angle-double-right" className="p-mt-2" />
                </div>
            </div>  */}
        </>
    )

    const actionAgreeBodyTemplate = (rowData, props) => {
        return (
            <div className="actions" style={{ display: "flex" }}>
                <Button icon="pi pi-eye" title='View Agreement' className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={() => { viewAgreement(rowData) }} />
                <Button icon="pi pi-trash" title='Delete Agreement' className="p-button-rounded p-button-danger p-mx-2" aria-label="Delete" onClick={() => confirmDeleteAgree(rowData)} />
            </div>
        );
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                {/* <div className="p-inputgroup">
                <Button className='custom-group-btn' label="Agreement Status" />
                <Dropdown value={selectedAgreementStatus} options={agreementStatusOptions} onChange={changingAgreeStatus} optionLabel="name" placeholder="Select Agreement Status" filter className="p-mr-2 multiselect-custom" />
            </div>
            {
                selectedAgreementStatus?.code == 'pending' &&
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="Comment" />
                    <Dropdown value={selectedFilteredComment} options={agreementCommentOptionsForFilter} onChange={changingComment} optionLabel="name" placeholder="Select Comment" filter className="p-mr-2 multiselect-custom" />
                </div>
            } */}

                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="Customer Status" />
                    <Dropdown value={selectedCustomerStatus} options={customerStatusOptions} onChange={handleStatusChange} optionLabel="name" placeholder="Select Cutomer Status" filter className="p-mr-2 multiselect-custom" />
                </div>
            </>
        )
    }

    return (
        <>
            <div className="p-grid table-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} position='center' />
                        <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar>
                        <DataTable value={allUsersByCtvm} paginator className="p-datatable-customers" rows={10} rowsPerPageOptions={[5, 10, 25]} dataKey="id" rowHover
                            globalFilter={globalFilter} emptyMessage="No Users Found." paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
                            ref={dt} header={header} loading={loading} exportFilename="Active User Details" >
                            <Column field="srno" header="Sr.No." body={srBodyTemplate} exportable={false} style={{ width: '6rem' }} ></Column>
                            <Column field="company" header="Account Name" sortable body={CommonBodyForTable}></Column>
                            <Column field="Type_of_Entity" header="Type of Entity" sortable body={CommonBodyForTable}></Column>
                            <Column field="Customer_Account_ID" header="Account ID" sortable body={CommonBodyForTable}></Column>
                            <Column field="name" header="User Name" sortable body={namebodyTemplate}></Column>
                            {/* <Column field="userType" header="User Type" sortable body={typeBodyTemplate}></Column> */}
                            <Column field="cno" header="Contact Number" sortable body={mobileTemplate}></Column>
                            <Column field="address" header="Address" sortable body={CommonBodyForTable}></Column>
                            <Column field="userStatus" header="Status" sortable body={CommonBodyForTable}></Column>
                            {/* <Column field="agreementStatus" header="Agreement Status" sortable body={CommonBodyForTable} style={{ display: 'none' }}></Column> */}
                            <Column header="Action" body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            <Dialog visible={facilityDialog} style={{ width: "800px" }} header="Facility Details" modal className="p-fluid" onHide={() => { setFacilityDialog(false) }} footer={facilityDFooter} maximizable={true} blockScroll={true} >
                <>
                    {/* <Toolbar className="p-m-2 p-toolbar" right={addFacRightToolbarTemplate}></Toolbar> */}
                    <div className="card">
                        <div className="p-grid crud-demo">
                            <DataTable value={facilityUser} paginator className="datatable-responsive" rows={10} dataKey="_id" rowHover emptyMessage="No facility found." >
                                <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '6rem' }} ></Column>
                                <Column field="facid" header="Facility Id" sortable body={CommonBodyForTable}></Column>
                                <Column field="facname" header="Facility Name" sortable body={CommonBodyForTable}></Column>
                                <Column field="facility_address" header="Facility Address" sortable body={facilityAddressTemplate} ></Column>
                                <Column header="Action" body={actionFacBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </>
            </Dialog>

            <Dialog visible={viewCustomerDialog} style={{ width: '650px' }} header={`Customer Details`} modal footer={viewCustomerFooter} onHide={() => { setViewCustomerDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                    <div className="p-col-12">
                        <div className=" widget-performance">
                            <div className="content">
                                <ul>
                                    <div className='p-grid'>
                                        <CommonForDetails name="Name" value={customerData?.prefix + ' ' + customerData?.name + ' ' + (customerData?.lastName ? customerData?.lastName : '')} />
                                        <CommonForDetails name="Contact Number" value={customerData?.cno} />
                                        <CommonForDetails name="Email Id" value={customerData?.email} />
                                        <CommonForDetails name="Address" value={customerData?.addr?.addr} />
                                        <CommonForDetails name="Pincode" value={customerData?.addr?.pincode || 'NA'} />
                                        <CommonForDetails name="Country" value={customerData?.addr?.country?.name} />
                                        <CommonForDetails name="State" value={customerData?.addr?.state?.name} />
                                        <CommonForDetails name="City" value={customerData?.addr?.city?.name} />
                                        <CommonForDetails name="GST Number" value={customerData?.billing_details?.gstNumber || 'NA'} />
                                        <CommonForDetails name="Company" value={customerData?.billing_details?.company} />
                                        <CommonForDetails name="Cup Cost" value={customerData?.cup_cost || 0} />
                                        <CommonForDetails name="Beverage Offset" value={customerData?.beverages_offset || 0} />
                                        <CommonForDetails name="Ownership" value={customerData?.ownerShip || 'NA'} />
                                    </div>
                                    <hr></hr>
                                    <h6 className="p-ml-2" >Payment Terms</h6>
                                    <hr></hr>
                                    <div className='p-grid'>
                                        <CommonForDetails name="Mode" value={customerData?.payemntTerms?.mode || 'NA'} />
                                        <CommonForDetails name="Extension Period" value={customerData?.payemntTerms?.extPeriod || 'NA'} />
                                        <CommonForDetails name="Advance Amount Percent" value={customerData?.payemntTerms?.minAmtPercent || 'NA'} />
                                        <CommonForDetails name="Zoho Id" value={customerData?.payemntTerms?.zohoID || 'NA'} />
                                        <CommonForDetails name="Credit Limit" value={customerData?.payemntTerms?.Credit_Limit || 'NA'} />
                                        <CommonForDetails name="Prepaid Payment Type" value={customerData?.payemntTerms?.Prepaid_Payment_Type ? (customerData?.payemntTerms?.Prepaid_Payment_Type ? 'Yes' : 'No') : 'NA'} />
                                        <CommonForDetails name="Billing Pattern" value={customerData?.billing_pattern || 'NA'} />

                                    </div>

                                    {
                                        customerData?.distributor_data &&
                                        <>
                                            <hr></hr>
                                            <h6 className="p-ml-2">Distributor's Data</h6>
                                            <hr></hr>
                                            <div className='p-grid'>
                                                <CommonForDetails name="Company Name" value={customerData?.distributor_data?.company_name || 'NA'} />
                                                <CommonForDetails name="Cup Cost" value={customerData?.distributor_data?.cup_cost || 'NA'} />
                                                <CommonForDetails name="Packet Cost" value={customerData?.distributor_data?.packet_cost || 'NA'} />
                                                <CommonForDetails name="Role" value={customerData?.distributor_data?.role || 'NA'} />
                                                <CommonForDetails name="Speciality Cost" value={customerData?.distributor_data?.speciality_cost || 'NA'} />
                                            </div>
                                        </>
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={viewFcDialog} style={{ width: '650px' }} header={`Facility Details`} modal footer={viewFcFooter} onHide={() => { setViewFcDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                    <div className="p-col-12">
                        <div className=" widget-performance">
                            <div className="content">
                                <ul>
                                    <div className='p-grid'>
                                        <CommonForDetails name="Facility Name" value={editFacData?.facname} />
                                    </div>
                                    <h5>Shipping Details</h5>
                                    <div className='p-grid'>

                                        <CommonForDetails name="Pincode" value={editFacData?.address?.pincode ? editFacData?.address?.pincode : 'NA'} />
                                        <CommonForDetails name="Address" value={editFacData?.address?.address} />
                                        <CommonForDetails name="Country" value={editFacData?.address?.country?.name} />
                                        <CommonForDetails name="State" value={editFacData?.address?.state?.name} />
                                        <CommonForDetails name="City" value={editFacData?.address?.city?.name} />
                                        <CommonForDetails name="Latitude" value={editFacData?.address?.location?.lat} />
                                        <CommonForDetails name="Longitude" value={editFacData?.address?.location?.lng} />
                                    </div>

                                    <h5>Billing Details</h5>
                                    <div className='p-grid'>
                                        <CommonForDetails name="Company Name" value={editFacData?.billing_details?.cmpname} />
                                        <CommonForDetails name="GST Number" value={editFacData?.billing_details?.gstnumber} />
                                        <CommonForDetails name="Address" value={editFacData?.billing_details?.addr} />
                                        <CommonForDetails name="State" value={editFacData?.billing_details?.state?.name} />
                                        <CommonForDetails name="City" value={editFacData?.billing_details?.city?.name} />
                                        <CommonForDetails name="Pincode" value={editFacData?.billing_details?.pincode} />
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="p-grid crud-demo">
                            <DataTable value={facilityMachines} className="datatable-responsive" rows={10} dataKey="_id" rowHover emptyMessage="No CVES found." >
                                <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '6rem' }} ></Column>
                                <Column field="id" header="CVES ID" sortable body={CommonBodyForTable}></Column>
                                <Column field="subid" header="Subscription ID" sortable body={CommonBodyForTable}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={agreementDialog} style={{ width: '1150px' }} header={`Agreements (${selectedAccount?.company ? selectedAccount?.company : 'NA'}) `} modal footer={agreementDialogFooter} onHide={() => { setAgreementDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                <Toolbar className="p-mb-4 p-toolbar" left={agreementLeftToolbarTemplate} right={agreementRightToolbarTemplate}></Toolbar>

                {agreementList?.length == 0 && <Messages ref={msgs} />}

                {
                    loading1 ?
                        <center><h5><i className="pi pi-spin pi-spinner" /></h5></center> :
                        <div className="p-grid crud-demo">
                            <div className="card">
                                <DataTable value={agreementList} className="datatable-responsive" responsiveLayout="scroll" emptyMessage="No data found.">
                                    <Column field="code" header="Sr. No." body={srBodyTemplate} style={{ width: '8rem' }} ></Column>
                                    <Column field="filename" header="File Name" body={fileBodyForTable} style={{ width: '12rem', overflowWrap: 'break-word' }}></Column>
                                    <Column field="startDate" header="Start Date" body={dateBodyForTable} ></Column>
                                    <Column field="endDate" header="End Date" body={dateBodyForTable} ></Column>
                                    <Column field="createdAt" header="Uploaded At" body={dateBodyForTable} ></Column>
                                    <Column field="agreement_signed" header="Signed" body={boolenBodyForTable} ></Column>
                                    <Column header="Action" body={actionAgreeBodyTemplate}></Column>
                                </DataTable>
                            </div>
                        </div>
                }

            </Dialog>

            <Dialog visible={uploaderDialog} style={{ width: '550px' }} header={`Upload Agreement`} modal footer={uploadAgreeDialogFooter} onHide={hideUploadAgree} maximizable={true} blockScroll={true}>
                <form onSubmit={submitAgreement} encType="multipart/form-data" id="add-agreement" className="p-fluid">

                    {/* <InputText type="hidden" name='id' value={selectedMachineForM?.id} required/> */}
                    {/* <InputText type="hidden" name='subid' value={selectedMachineForM?.subid} required/> */}
                    {/* <InputText type='hidden' name='Uploaded_By' value={useid?.userid} /> */}

                    <div className='p-grid'>
                        <div className='p-col-12' style={{ display: 'none' }}>
                            <div className="p-field">
                                <label>User Id</label>
                                <InputText name='userid' value={selectedAccount?.userid} required autoComplete='off' />
                            </div>
                        </div>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label>Start Date</label>
                                <InputText type="date" name='startDate' onChange={changeDate} required autoComplete='off' />
                            </div>
                        </div>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label>End Date</label>
                                <InputText type="date" name='endDate' min={minDate} required autoComplete='off' />
                            </div>
                        </div>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label>Upload Agreement</label>
                                <InputText type="file" id="agreement_file" name='File' accept="application/pdf,application/vnd.ms-excel" required />
                            </div>
                        </div>

                        <div className='p-col-12'>
                            <div className="flex align-items-center">
                                <Checkbox inputId="signed" name="agreement_signed" onChange={changeDate} data-val={`${isAgreeSigned}`} value={`${isAgreeSigned}`} checked={isAgreeSigned} />
                                <label htmlFor="signed" className="ml-2"> Agreement Signed</label>
                            </div>
                        </div>

                        {
                            !isAgreeSigned &&
                            <>
                                <div className='p-col-12'>
                                    <div className="p-field">
                                        <label>Order Id</label>
                                        {/* <Dropdown name='app_orderId' value={selectedOrder} options={orderList} onChange={changeDate} optionLabel="order_no" placeholder="Select Order Id" filter className="p-mr-2 multiselect-custom" /> */}
                                        <MultiSelect className="multiselect-custom" name="app_orderIds" optionLabel="order_no" placeholder="Select Order ID" options={orderList} onChange={changeDate} value={selectedOrder} filter showClear={true} showSelectAll={false} display="chip" required />
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </form>
            </Dialog>

            <Dialog visible={confirmDeleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteAgreeDialogFooter} onHide={() => { setConfirmDeleteDialog(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br />
                        <span className='p-mt-2'><b>Are you sure you want to delete the selected agreement?</b></span>
                    </center>
                </div>
            </Dialog>

            <Dialog visible={agreeCommentDialog} style={{ width: '550px' }} header={`Add Comment`} modal footer={agreeCommentDialogFooter} onHide={() => { setAgreeCommentDialog(false) }} maximizable={true} blockScroll={true}>
                <form onSubmit={submitAgreementComment} id="add-agreement-comment" className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-12'>
                            <div className="p-field">
                                <label>Comment</label>
                                <Dropdown name='agreement_comment' value={selectedComment} options={agreementCommentOptions} onChange={changeAgreeC} optionLabel="name" placeholder="Select Comment" filter className="p-mr-2 multiselect-custom" />

                                {/* <InputTextarea rows={5} cols={30} name='agreement_comment' placeholder="Type Comment Here..." onChange={changeAgreeC} autoResize autoFocus required/> */}
                            </div>
                        </div>
                    </div>
                </form>

                <div className="p-grid crud-demo">
                    <div className="card">
                        <DataTable value={agreeCommentData} className="datatable-responsive" responsiveLayout="scroll" emptyMessage="No data found.">
                            <Column field="Sr. No." header="Sr. No." body={srBodyTemplate} ></Column>
                            <Column field="agreement_comment" header="Comment" body={CommonBodyForTable}></Column>
                            <Column header="Delete" body={actionAgreeCommentBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>

            </Dialog>

            <Dialog visible={viewCvesDetailedDialog} style={{ width: '450px' }} header={`CVES Details`} modal footer={cvesDialogFooter} onHide={() => { setViewCvesDetailedDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className="p-grid table-demo">
                    <div className="p-col-12">
                        <div className="card p-m-0">
                            <Toast ref={toast} />
                            <DataTable value={accountsCves} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover
                                emptyMessage="No CVES Found.">
                                <Column field="srno" header="Sr. No." body={srBodyTemplate} ></Column>
                                <Column field="id" header="CVES ID" body={CommonBodyForTable}></Column>
                                <Column field="siteName" header="Subscription Name" body={CommonBodyForTable}></Column>
                                <Column field="subid" header="Subscription Id" body={CommonBodyForTable}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>

        </>
    )
}


export const dateBodyForTable = (data, props) => (
    <>
        <span className="p-column-title">{props.header}</span>{data?.[props.field] ? new Date(data?.[props.field]).toString('YYYY-MM-dd') : 'NA'}
    </>
)

export const fileBodyForTable = (data, props) => (
    <>
        <span className="p-column-title">{props.header}</span>{data?.File[props.field] ? data?.File[props.field] : 'NA'}
    </>
)

export const boolenBodyForTable = (data, props) => (
    <>
        <span className="p-column-title">{props.header}</span>{data[props.field] ? <i className="pi pi-check"></i> : <i className="pi pi-times"></i>}
    </>
)


