import React, { useState, useEffect, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { CommonForDetails } from "../CRM/Leads";
// Constnts
import { stageOptions, addedStageOptions, assignedStageOptions, deliveredStageOptions, loadedStageOptions, usedStageOptions } from "./Constants";
import {  commonFormDialogFooter, commonDialogFooter, CommonBodyForFormattedStrTable, commonCloseDialogFooter } from "../Common/CommonComponents";
import { copyData } from "../Common/CommonFunctions";
// Context
import { permissionContext } from "../../AppWrapper";
// Service
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

export const BeverageTab = (props) => {

    const rolePermissions = useContext(permissionContext);

    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [onDemandCount, setOnDemandCount] = useState(50)
    const [onDemandIndex, setOnDemandIndex] = useState(0)
    const [sourceData, setSourceData] = useState([])
    const [onDemandId, setOnDemandId] = useState(0)
    const [beveragesData, setBeveragesData] = useState([]);
    const [selectedBeverage, setSelectedBeverage] = useState(null);
    const [updateMaterialTemp, setUpdateMaterialTemp] = useState(null);
    const [editBevDialog, setEditBevDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState('');
    const [usedStatus, setUsedStatus] = useState(false);
    const [selectedStageOptions, setSelectedStageOptions] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null);
    const [allMaterialTypes, setAllMaterialTypes] = useState(null);
    const [selectedStageFilter, setSelectedStageFilter] = useState({ name: 'All', code: 'all' })
    const [selectedMaterialType, setSelectedMaterialType] = useState({ materialName: 'All' })
    const [selectedPayloadData, setSelectedPayloadData] = useState(null)
    const [deletePromptDialog, setDeletePromptDialog] = useState(false)
    const [searchCall, setSearchCall] = useState(true)
    const [searchedInput, setSearchedInput] = useState(null);
    const [viewMaterial, setViewMaterial] = useState(null);
    const [viewPromptDialog, setViewPromptDialog] = useState(false);

    const dt = useRef(null);
    const toast = useRef(null)

    useEffect(() => {
        const data = { count: onDemandCount, index: onDemandIndex }
        setSelectedPayloadData(data)
        getMaterial(data)
        getMaterialTypes()
    }, [])

    const getMaterialTypes = async () => {
        const response = await apiServices.getMaterialTypes();
        response?.data?.mate?.unshift({ materialName: 'All' })
        setAllMaterialTypes(response?.data?.mate)
    }

    const getMaterial = async (data) => {
        const response = await apiServices.getMaterials(data)
        const arr = response?.data?.mate;
        console.log(arr);
        setSourceData(arr)
        setBeveragesData(arr?.slice(0, rows))
        setTotalRecords(arr?.length)
        setLoading(false)
    }

    const searchMaterial = async (data) => {


        // console.log(selectedMaterialType);
        // console.log(selectedMaterialType?.materialName)

        const response = await apiServices.getMaterials(data)
        const arr = response?.data?.data;
        setSourceData(arr)
        setBeveragesData(arr?.slice(0, rows))
        setTotalRecords(arr?.length)
        setLoading(false)
    }


    const changeFilter = (e) => {

        setOnDemandIndex(0)
        if (e.target.name == 'materialName') {

            setSelectedMaterialType(e.target.value)

            if (e.target.value.materialName == 'All') {

                const data = selectedStageFilter.code == 'all' ? { count: onDemandCount, index: 0 } : { count: onDemandCount, index: 0, barcodeStatus: selectedStageFilter.code }
                setSelectedPayloadData(data)
                getMaterial(data)

            } else if (e.target.value.materialName != 'All') {

                const data = selectedStageFilter.code == 'all' ? { count: onDemandCount, index: 0, materialName: e.target.value.materialName } : { count: onDemandCount, index: 0, materialName: e.target.value.materialName, barcodeStatus: selectedStageFilter.code }
                setSelectedPayloadData(data)
                getMaterial(data)

            }


        } else if (e.target.name == 'matStatus') {

            setSelectedStageFilter(e.target.value)
            if (e.target.value?.code == 'all') {

                const data = selectedMaterialType.materialName == 'All' ? { count: onDemandCount, index: 0 } : { count: onDemandCount, index: 0, materialName: selectedMaterialType.materialName }
                setSelectedPayloadData(data)
                getMaterial(data)

            } else if (e.target.value?.code != 'all') {

                const data = selectedMaterialType.materialName == 'All' ? { count: onDemandCount, index: 0, barcodeStatus: e.target.value?.code } : { count: onDemandCount, index: 0, materialName: selectedMaterialType?.materialName, barcodeStatus: e.target.value?.code }
                setSelectedPayloadData(data)
                getMaterial(data)
            }
        }
    }

    const onSearched = async (e) => {
        setSearchedInput(e.target.value)
        if (searchCall) {
            setSearchCall(false)
            setTimeout(() => {

                if (e.target.value) {
                    const data = { count: onDemandCount, index: onDemandIndex, chars: e.target.value, materialName: selectedMaterialType?.materialName == 'All' ? null : selectedMaterialType?.materialName }
                    searchMaterial(data)
                } else {
                    getMaterial(selectedPayloadData)
                }

                setSearchCall(true)
            }, 2000);
        }
    }

    const onPageChange = async (event) => {

        setOnDemandId(event.rows * event.page)
        setTimeout(() => {
            const startIndex = event.first;
            const endIndex = event.first + rows;
            setFirst(startIndex);
            setBeveragesData(sourceData.slice(startIndex, endIndex));
        }, 250);

        if ((event.page + 1) % 5 == 0) {

            let totalData;

            if (searchedInput) {

                const data = {
                    count: onDemandCount,
                    index: onDemandIndex + 50,
                    chars: searchedInput,
                    materialName: selectedMaterialType?.materialName == 'All' ? null : selectedMaterialType?.materialName
                }

                const response = await apiServices.getMaterials(data);
                const arr = response?.data?.data;
                totalData = [...sourceData, ...arr]

            } else {

                const data = { count: onDemandCount, index: onDemandIndex + 50 }
                const payloadData = { ...selectedPayloadData, ...data }
                const response = await apiServices.getMaterials(payloadData)
                const arr = response?.data?.mate;
                totalData = [...sourceData, ...arr]
            }

            setSourceData(totalData);
            setTotalRecords(totalData.length);
            setBeveragesData(totalData.slice(event.first, event.first + rows));
            // setOnDemandCount(onDemandCount + 50)
            setOnDemandIndex(onDemandIndex + 50)
        }
    }

    const openEdit = (rowData) => {
        console.log(rowData);
        setSelectedBeverage(rowData)
        setUpdateMaterialTemp(rowData)
        setEditBevDialog(true)
        setUsedStatus(rowData?.usedStatus)

        if (rowData?.stage == 'added') {
            setSelectedStageOptions(addedStageOptions)
            setSelectedStage({ name: 'Added', code: 'added' })
        } else if (rowData?.stage == 'assigned') {
            setSelectedStageOptions(assignedStageOptions)
            setSelectedStage({ name: 'Assigned', code: 'assigned' })
        } else if (rowData?.stage == 'delivered') {
            setSelectedStageOptions(deliveredStageOptions)
            setSelectedStage({ name: 'Delivered', code: 'delivered' })
        } else if (rowData?.stage == 'loaded') {
            setSelectedStageOptions(loadedStageOptions)
            setSelectedStage({ name: 'Loaded', code: 'loaded' })
        } else if (rowData?.stage == 'used') {
            setSelectedStageOptions(usedStageOptions)
            setSelectedStage({ name: 'Used', code: 'used' })
        }
    }

    const changeUpMat = (e) => {

        if (e.target.name == 'usedStatus') {
            setUsedStatus(e.target.checked)
            setUpdateMaterialTemp({ ...updateMaterialTemp, [e.target.name]: e.target.checked })
        }

        if (e.target.name == 'stage') {
            setSelectedStage(e.target.value)
            setUpdateMaterialTemp({ ...updateMaterialTemp, [e.target.name]: e.target.value.code })
        }

        if (e.target.name == 'resuseComment') {
            setUpdateMaterialTemp({ ...updateMaterialTemp, reuse: { ...updateMaterialTemp.reuse, [e.target.name]: e.target.value, date: new Date().toISOString() } })
        }

        // if(e.target.name == 'weight'){
        //     setUpdateMaterialTemp({...updateMaterialTemp,sku : {...updateMaterialTemp.sku, [e.target.name] : e.target.value }})
        // }

        // if(e.target.name == 'dis_cup'){
        //     setUpdateMaterialTemp({...updateMaterialTemp,[e.target.name] : e.target.value })
        // }

    }

    const editBevSubmit = async (e) => {
        e.preventDefault()
        setEditBevDialog(false)
        console.log(updateMaterialTemp)
        const response = await apiServices.updateMaterial(updateMaterialTemp)
        response?.data?.success ? success('Material Updated Successfully') : failure('Failed to Update')

        if (searchedInput) {
            const data = { count: onDemandCount, index: onDemandIndex, chars: searchedInput }
            searchMaterial(data)
        } else {
            getMaterial(selectedPayloadData)
        }
    }

    const openDelete = (rowData) => {
        setSelectedBeverage(rowData)
        setDeletePromptDialog(true)
    }

    const deleteMaterial = async () => {
        setDeletePromptDialog(false)
        const response = await apiServices.removeMaterial({ barcode: selectedBeverage?.barcode })
        response?.data?.success ? success('Material Deleted Successfully') : failure('Failed to Delete')
        getMaterial(selectedPayloadData)
    }


    const openMaterialDialog = async (rowData) => {
        const response = await apiServices.materialDetails({ barcode: rowData.barcode });
        console.log("response", response?.data?.data[0])
        setViewMaterial(response?.data?.data[0]);
        setViewPromptDialog(true);
    }

    const hideMaterialDialog = async (rowData) => {
        setViewPromptDialog(false);
        setViewMaterial(null);
    }

    // const closeBatch

    const onclickThis = (e) => {
        const copyText = e.target.innerText;
        copyData(copyText)
        showInfo()
    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right p-m-1" >
                <div className="p-inputgroup p-mr-2" style={{ display: 'flex' }}>
                    <Button className='custom-group-btn' label="Material Name" />
                    <Dropdown value={selectedMaterialType} name='materialName' onChange={changeFilter} options={allMaterialTypes} optionLabel="materialName" placeholder="Select" filter
                        className="p-mr-2 multiselect-custom" />
                    {/* </div>  

                <div className="p-inputgroup"> */}
                    <Button className='custom-group-btn' label="Status" />
                    <Dropdown value={selectedStageFilter} name='matStatus' onChange={changeFilter} options={stageOptions} optionLabel="name" placeholder="Select" filter
                        className="p-m-0 multiselect-custom" />
                </div>

            </span>
            <span className="p-input-icon-left p-m-1">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={onSearched} placeholder="Search..." />
                </div>
            </span>
        </div>
    );

    const onDemandidTemplate = (rowData, props) => (<><span className="p-column-title">Sr.No.</span>{props.rowIndex + 1 + onDemandId}</>)

    const itemNameBodyTemplate = (rowData, props) => {
        return (
            <> <span className="p-column-title">{props.header}</span> {rowData[props.field] ? rowData[props.field] : rowData.materialName}</>
        )
    }

    const usedDateBodyTemplate = (rowData, props) => {
        const value = rowData?.dates[props.field];
        const date = new Date(value).toLocaleString();

        return (
            <> <span className="p-column-title">{props.header}</span> {date ? date : 'NA'}</>
        )
    }

    const commonBodyTemplate = (rowData, props) => {

        let value;
        if (props.field === 'batch_name') {
            value = rowData?.batch && rowData?.batch?.[props.field];
        } else if (props.field === 'msubid') {
            value = rowData.machineId ? `${rowData.machineId} / ${rowData.subid}` : 'NA';
        } else {
            value = rowData[props.field];
        }

        return (
            <> <span className="p-column-title">{props.header}</span> {value ? value : 'NA'}</>
        )
    }

    const commonBodyTemplate1 = (rowData, props) => {
        return (
            <>
                <> <span className="p-column-title" >{props.header}</span>
                    {
                        rowData[props.field] ? <Button label={rowData[props.field]} className="p-button-secondary p-button-text p-p-0" iconPos="right" onClick={onclickThis} /> : 'NA'
                    }
                </>
            </>
        )
    }

    const dateBodyTemplate = (rowData, props) => {
        const value = rowData.dates[rowData.stage];
        const convertedDate = new Date(value).toLocaleString('en-IN');
        return (<> <span className="p-column-title" >{props.header}</span>{convertedDate}</>)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{ display: "flex" }}>
                    {rolePermissions?.fgBeverageEdit ? <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-warning p-mx-1" aria-label="Edit" onClick={() => { openEdit(rowData) }} /> : '-'}
                    {<Button icon="pi pi-eye" title='View Details' className="p-button-rounded p-button-info p-mx-2" aria-label="View Details" onClick={() => { openMaterialDialog(rowData) }} />}
                    {(rolePermissions?.fgBeverageDelete && rowData?.stage == 'added') && <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-2" aria-label="Delete" onClick={() => { openDelete(rowData) }} />}
                </div>
            </>
        )
    }

    const hideEditBevDialog = () => { setEditBevDialog(false) }
    const hideDeletePromtDialog = () => { setDeletePromptDialog(false) }

    const bevDialogFooter = commonFormDialogFooter('Close', hideEditBevDialog, 'Submit', 'edit-Bev')
    const deleteBevDialogFooter = commonDialogFooter('Close', hideDeletePromtDialog, 'Delete', deleteMaterial)
    const viewPromptDialogFooter = commonCloseDialogFooter('Close', hideMaterialDialog)


    const success = (msg) => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: `${msg}`, life: 3000 });
    }

    const failure = (msg) => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: `${msg}`, life: 3000 });
    }

    const showInfo = (msg) => {
        toast.current.show({ severity: 'info', summary: 'Copied', detail: 'Barcode Copied to Clipboad', life: 1000 });
    }

    return (
        <>
            <Toast ref={toast} position='center' />
            <DataTable ref={dt} value={beveragesData}
                dataKey="id" paginator rows={rows} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Barcodes"
                globalFilter={globalFilter} emptyMessage="No Data found." header={header} loading={loading} totalRecords={totalRecords} lazy first={first} onPage={onPageChange} >
                <Column field="srid" header="Sr.No." body={onDemandidTemplate} exportable={false} style={{ width: '4rem' }} ></Column>
                <Column field="itemName" header="Beverage Name" body={itemNameBodyTemplate} style={{ width: '25rem' }}></Column>
                <Column field="batch_name" header="Batch Name" body={commonBodyTemplate}></Column>
                <Column field="barcode" header="Barcode" body={commonBodyTemplate1}  ></Column>
                <Column field="weight" header="Weight (gms)" body={commonBodyTemplate}  ></Column>
                <Column field="msubid" header="CVES/Subid" body={commonBodyTemplate}  ></Column>
                <Column field="stage" header="Status" body={CommonBodyForFormattedStrTable}  ></Column>
                {/* <Column field="used" header="Used Date" body={usedDateBodyTemplate}  ></Column> */}
                <Column field="date" header="Date & Time" body={dateBodyTemplate}  ></Column>
                <Column header="Actions" body={actionBodyTemplate}></Column>
            </DataTable>

            <Dialog visible={editBevDialog} style={{ width: '850px' }} header={`Update Beverages`} modal className="p-fluid" footer={bevDialogFooter} onHide={() => { setEditBevDialog(false) }} maximizable={true} blockScroll={true}>
                <form onSubmit={editBevSubmit} id="edit-Bev" className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="mat-name">Material Name</label>
                        <InputText id="mat-name" value={selectedBeverage?.materialName} autoFocus disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="batch">Batch</label>
                        <InputText id="batch" value={selectedBeverage?.batch?.batch_name} disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="barcode">Barcode</label>
                        <InputText id="barcode" value={selectedBeverage?.barcode} disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="sku">SKU</label>
                        <InputText id="sku" value={selectedBeverage?.sku?.item_name} disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="weight">Weight(gms)</label>
                        <InputText id="weight" name='weight' value={selectedBeverage?.sku?.weight} disabled />
                    </div>

                    <div className="p-field">
                        <label htmlFor="use-Stat">Used Status</label><br></br>
                        <Checkbox id="use-Stat" name="usedStatus" checked={usedStatus} onChange={changeUpMat} ></Checkbox>
                    </div>

                    <div className="p-field">
                        <label htmlFor="stage">Stage</label>
                        <Dropdown id="stage" name="stage" value={selectedStage} options={selectedStageOptions} optionLabel="name" placeholder="Select Stage" onChange={changeUpMat} filter required></Dropdown>
                    </div>

                    {
                        (selectedStage?.code == 'loaded' || selectedStage?.code == 'used') &&
                        <div className="p-field">
                            <label htmlFor="reu-c">Reuse Comment</label>
                            <InputText id="reu-c" name="resuseComment" placeholder="Comment" defaultValue={selectedBeverage?.reuse?.resuseComment} onChange={changeUpMat} required />
                        </div>
                    }

                    <div className="p-field">
                        <label htmlFor="dis-cup">Dispensable Cups</label>
                        <InputText id="dis-cup" name="dis_cup" value={selectedBeverage?.dis_cup} disabled />
                    </div>

                    <div className="p-field">
                        <label htmlFor="mf-Date">Manufacture Date </label>
                        <InputText id="mf-Date" value={new Date(selectedBeverage?.mfDate).toString('YYYY-MM-dd')} disabled />
                    </div>

                    <div className="p-field">
                        <label htmlFor="exp-Date">Expiry Date</label>
                        <InputText id="exp-Date" value={new Date(selectedBeverage?.expiryDate).toString('YYYY-MM-dd')} disabled />
                    </div>
                </form>
            </Dialog>

            <Dialog visible={deletePromptDialog} style={{ width: '450px' }} header={`Confirm ? (${selectedBeverage?.barcode}) `} modal footer={deleteBevDialogFooter} onHide={() => { setDeletePromptDialog(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br />
                        <span className='p-mt-4'><b>Are you sure you want to delete this?</b></span>
                    </center>
                </div>
            </Dialog>


            <Dialog visible={viewPromptDialog} style={{ width: '500px' }} header={`View Material Details`} modal footer={viewPromptDialogFooter} onHide={() => { hideMaterialDialog(false) }}>
                <div className='p-grid'>
                    <div className="p-col-12">
                        <div className=" widget-performance">
                            <div className="content">
                                <ul>
                                    <div className='p-grid'>
                                        <CommonForDetails name="Batch Name" value={viewMaterial?.batch?.batch_name}  />
                                        <CommonForDetails name="Batch Id" value={viewMaterial?.batch?.batch_id}  />
                                        <CommonForDetails name="Barcode" value={viewMaterial?.barcode}  />
                                        <CommonForDetails name="Stage" value={viewMaterial?.stage}  />
                                        <CommonForDetails name="Weight" value={viewMaterial?.weight}  />
                                        <CommonForDetails name="Material Name" value={viewMaterial?.materialName}  />
                                        <CommonForDetails name="SKU Master Name" value={viewMaterial?.itemName}  />
                                        <CommonForDetails name="User Name" value={viewMaterial?.customer.fullName}  />
                                        <CommonForDetails name="User Id" value={viewMaterial?.customer.userid}  />
                                        <CommonForDetails name="User Type" value={viewMaterial?.customer.userType}  />
                                        <CommonForDetails name="Warehouse Name" value={viewMaterial?.wareHouse.name}  />
                                        <CommonForDetails name="Warehouse Id" value={viewMaterial?.wareHouse.userid}  />
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>


        </>
    )
}