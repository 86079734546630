import React, {useState, useRef, useEffect, memo} from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { CommonBodyForTable } from '../Common/CommonComponents';


const DetailedCupConsumption = (props) => {

    // console.log(props.allBeverages)
    const dt = useRef(null);
    const [beverageType, setBeverageType] = useState({materialName : 'All'});
    const [beverages,setBeverages] = useState([{materialName : 'All'}])
    const [resultedCupData,setResultedCupData] = useState(null)
    const [allLineData, setAllLineData] = useState(null)
    const [resultedTimeData, setResultedTimeData] = useState(null)
    
    useEffect((x)=>{
        getBev(props.allBeverages)
    },[props.allBeverages])

    useEffect(()=>{
        const resCupData = props.cupConsumData;
        setResultedCupData(resCupData)
        const data = resCupData.map((s => a => s += a)(0));
        setAllLineData(data)
    },[props.cupConsumData])

    useEffect(()=>{
        setResultedTimeData(props.timeCupData)
    },[props.timeCupData])


    const getBev = (data) =>{
        if(data){
            setBeverages([...beverages,...data])
        }
    }

    const chartData = {
        labels: resultedTimeData,
        datasets: [{
            type: 'line',
            label: 'Total Cups',
            borderColor: '#42A5F5',
            borderWidth: 2,
            fill: false,
            tension: .4,
            data: allLineData
        }, {
            type: 'bar',
            label: 'Cups',
            backgroundColor: '#00544d',
            data: resultedCupData,
            borderColor: 'white',
            borderWidth: 2
        }]
    }


    const lightOptions = {
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    }


    const BeverageTypeAction = (e) =>{
        const userInput = e.value.materialName == 'All' ? {...props.paramsData.params} : {...props.paramsData.params,beverage:e.value.materialName}
        props.getmachineStats(userInput);
        setBeverageType(e.value);
    }

    const exportCSV = () => { dt.current.exportCSV();}

    return (
        <>
            <div className="p-grid">
            <div className="p-col-12 p-md-12">
                <div className="card">
                <div className="card-header">
                    <span>Detailed Cup Consumption</span>
                    <div className='p-col-sm-4'>
                    <div className="p-inputgroup">
                        <Button className='custom-group-btn' label="Beverage" />
                        <Dropdown value={beverageType} onChange={BeverageTypeAction} options={beverages} optionLabel="materialName" placeholder="Select Beverage" filter className="p-mr-2 multiselect-custom " />
                        <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-primary p-ml-2 p-mt-2" onClick={exportCSV} />
                    </div>
                    </div>
                </div>

                    {
                        resultedCupData?.length > 0  ? (
                            <div className="graph">
                                {/* <Chart type="line" data={lineData} options={lineOptions} /> */}
                                <Chart type="bar" data={chartData} options={lightOptions} />
                            </div>
                        ):(
                            <center><h4>NO DATA AVAILABLE</h4></center>
                        )
                    }

                </div>
            </div>
            </div>
            {/*  */}
            <DataTable style={{display:'none'}} ref={dt} value={props.allCupConsumeData} dataKey="id" exportFilename="All Cup Consumptions Data"  className="datatable-responsive" emptyMessage="No data found.">
                <Column field="Charttime" header="Date and Time"  body={CommonBodyForTable}></Column>
                <Column field="cups" header="Total Cup Count" body={CommonBodyForTable}></Column>
            </DataTable>
        </>
    )
}

export default memo(DetailedCupConsumption);