import axios from "axios";

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

export default class Permissions {
    
    
    getPermissionsDetails() {

        const userCheck = [ 'usr-em890k81kypncp79','usr-4kcz06434k16hr64q', 'usr-em8907y3leqy5c8v', 'usr-4kcz067ppk1bogdzy', 'usr-em890mjolcq0j6sy' ]
        const check = userCheck.includes(initialValue?.userid)

        return axios.get(`assets/permissions/${check ? 'developer' : initialValue?.userType}.json`).then(res => res.data.data);
       
    }


    
}