import React, {useState, useMemo, useEffect, useCallback, useRef} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Paginator } from 'primereact/paginator';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { DateBodyForTable } from '../Common/CommonComponents';

// =====================================
import ApiServices from '../../service/api/ApiServices';
import { successToast } from '../Common/CommonFunctions';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
const apiServices = new ApiServices();
// =====================================================
// =====================================================

export const CustomerAgreementDialog = (props) => {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [first, setFirst] = useState(0);
    const [fileUrl, setFileUrl] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [openAgreeDialog, setOpenAgreeDialog] = useState(false);
    const [agreementData, setAgreementData] = useState([]);
    const [selectedAgreement, setSelectedAgreement] = useState(null);
    const [verifyFormDialog, setVerifyFormDialog] = useState(false);
    const [enteredOtp,setEnteredOtp] = useState('');
    const toast = useRef(null);

    useEffect(() => {
        getData(props.initialValue)
    }, [props.initialValue])

    const getData = (data) =>{
        // setFileUrl(data?.agreement_url)
        const filtered = data?.agreement_data?.filter((x)=>{ return x.agreement_signed == false});
        console.log(filtered);
        setAgreementData(filtered);

        if(filtered?.length == 1){
            setFileUrl(filtered[0]?.agreement_url);
            setSelectedAgreement(filtered[0])
        }
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const onPageChange = (event) => {
        setFirst(event.first);
        const count = event.first == 0 ? 1 : event.first + 1;
        setPageNumber(count)
    };

    const rendered = () =>{
        for(let index = pageNumber; index < numPages; index++) {
            return(
                <Page pageNumber={index} renderTextLayer={false} renderAnnotationLayer={false} />                    
            )
        }
    }

    const openAgreement = (data) =>{
        setOpenAgreeDialog(true)
        setFileUrl(data?.agreement_url)
        setSelectedAgreement(data)
    }

    const hideAgreeDialog = () =>{
        setOpenAgreeDialog(false)
    }

    const hideVerifyForm = () =>{
        setVerifyFormDialog(false)
        setIsChecked(false)
    }

    const sendOtp = async() =>{
        const payload = {cno : props.initialValue?.cno};
        const response = await apiServices.sendOtp(payload);
        console.log(response?.data?.success);
        if(response?.data?.success){
            setVerifyFormDialog(true);
            props.getUserData()
        }
    }
    
    const changeOTP = (e) =>{
        const isValid = isNaN(Number(e.target.value));
        if(e.target.name == "otp"){
            !isValid && setEnteredOtp(e.target.value)
        }
    }

    const submitSignedAgreement = async() =>{

        const payload = {
            "cno": props.initialValue?.cno,
            "otp": enteredOtp,
            "agreement_signed_mode": "DASHBOARD",
            "agreement_id": selectedAgreement?.agreement_id,
            "agreement_signed": true,
            "term_and_condition": true   
        }

        const response = await apiServices.signAgreement(payload);

        if(response?.data?.success){
            hideVerifyForm()
            successToast(toast,'Agreement Signed Successfully.')
        }
    }
   
    const changeCheck = (e) =>{ setIsChecked(e.checked)}

    const fileBodyForTable = (data, props) =>( <> <span className="p-column-title">{'File Name'}</span>{data?.agreement_File?.filename ? data?.agreement_File?.filename : 'NA'} </> )

    const agreeActionTemplate = (rowData, props) => { 
        return (<> <Button icon="pi pi-pencil" title="Sign Agreement" id={props.rowIndex} className="p-button-rounded p-button-primary" onClick={()=>{openAgreement(rowData)}} /></>) 
    }

    const agreementDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideAgreeDialog}/>
            {/* <Button label="Submit" icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2"/> */}
        </>
    );

    const verifyFormDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideVerifyForm}/>
            <Button label="Submit" icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" onClick={submitSignedAgreement}/>
        </>
    );

    
    return (
        <>  
            <Toast ref={toast} position="center" />

            {
                (agreementData?.length > 1) ? 
                
                <div className='card'>
                <div className="p-grid crud-demo">
                    <div className="card" >
                        <DataTable className="datatable-responsive" header={'List of Unsigned Agreements'} value={agreementData} style={{ margin: '0 auto', textAlign: 'center' }} responsiveLayout="scroll">
                            <Column field="filename" header="File Name" body={fileBodyForTable}></Column>
                            <Column field="agreement_startDate" header="Start Date" body={DateBodyForTable}></Column>
                            <Column field="agreement_endDate" header="End date" body={DateBodyForTable}></Column>
                            <Column field="agreement_created" header="Created At" body={DateBodyForTable}></Column>
                            <Column field="Action" header="Sign Agreement" body={agreeActionTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
                </div>

                :

                <div className="card">
                    <div className='p-grid'>
                        <div className="p-sm-12 p-md-7">
                            <div className='card'>
                                <center>
                                <Paginator first={first} rows={1} totalRecords={numPages} onPageChange={onPageChange} template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" />
                                    <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                                        <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />                    
                                    </Document>
                                <Paginator first={first} rows={1} totalRecords={numPages} onPageChange={onPageChange} template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" />
                                </center>
                            </div>
                        </div>
                        <div className="p-sm-12 p-md-5">
                            <div style={{marginTop:'10%'}}>
                            <div className="p-grid crud-demo">
                                <div className="card" >
                                    <DataTable className="datatable-responsive" header={'Agreement Details'} value={agreementData} style={{ margin: '0 auto', textAlign: 'center' }} responsiveLayout="scroll">
                                        {/* <Column field="filename" header="File Name" body={fileBodyForTable}></Column> */}
                                        <Column field="agreement_startDate" header="Start Date" body={DateBodyForTable}></Column>
                                        <Column field="agreement_endDate" header="End date" body={DateBodyForTable}></Column>
                                        <Column field="agreement_created" header="Created At" body={DateBodyForTable}></Column>
                                        {/* <Column field="Action" header="Sign Agreement" body={agreeActionTemplate}></Column> */}
                                    </DataTable>
                                </div>
                            </div>
                                
                            <h6>Terms and Conditions</h6>
                            <p>
                                <small>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                                    molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
                                    numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
                                    optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
                                    obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam
                                    nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit,
                                    tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit,
                                    quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos 
                                    sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam
                                    recusandae alias error harum maxime adipisci amet laborum. Perspiciatis 
                                    minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit 
                                </small>
                            </p>

                            <div className="flex align-items-center">
                                <Checkbox inputId="checkBoxed" onChange={changeCheck} checked={isChecked} />
                                <label htmlFor="checkBoxed" className="ml-2"> <small><b>Agree with Terms and Conditions</b></small> </label>
                            </div>

                            <Button className='p-custom-btn  p-col-12 p-mt-5' iconPos="right" label='Signed By OTP' onClick={sendOtp} disabled={!isChecked} ></Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        
            <Dialog visible={openAgreeDialog} style={{ width: '750px' }} header="Agreement" modal className="p-fluid" footer={agreementDialogFooter} onHide={hideAgreeDialog} blockScroll={true}>
                <div>
                    <center>
                        <Paginator first={first} rows={1} totalRecords={numPages} onPageChange={onPageChange} template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" />
                            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                                <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />                    
                            </Document>
                        <Paginator first={first} rows={1} totalRecords={numPages} onPageChange={onPageChange} template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" />
                    </center>

                    <div style={{marginTop:'10%'}}>
                        <h6>Terms and Conditions</h6>
                        <p>
                            <small>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                                molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
                                numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
                                optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
                                obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam
                                nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit,
                                tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit,
                                quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos 
                                sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam
                                recusandae alias error harum maxime adipisci amet laborum. Perspiciatis 
                                minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit 
                            </small>
                        </p>
                        <div className="flex align-items-center">
                            <Checkbox inputId="checkBoxed" onChange={changeCheck} checked={isChecked} />
                            <label htmlFor="checkBoxed" className="ml-2"> <small><b>Agree with terms and Conditions</b></small> </label>
                        </div>
                        <Button className='p-custom-btn  p-col-12 p-mt-5' iconPos="right" label='Signed By OTP' disabled={!isChecked} onClick={sendOtp}></Button>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={verifyFormDialog} style={{ width: '550px' }} header={`Verify OTP`} modal footer={verifyFormDialogFooter} onHide={hideVerifyForm} className='p-fluid' blockScroll={true}>
                <p className='p-success'>OTP Sent on registered mobile number <b>{props.initialValue?.cno}</b>. </p>
                <div className="p-field">
                    <label htmlFor="enterOTP">Enter OTP</label>
                    <InputText id="enterOTP" name="otp" placeholder="Enter OTP" value={enteredOtp} maxLength={4} onChange={changeOTP} autoFocus />
                </div>
            </Dialog>

        </>
    )
}


