import React, {useState, useEffect, useRef} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
// services
import CommonCal from "../../service/common/CommonCal";
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices();
const commonCal = new CommonCal();

export const TechnomanTracking = () =>{

    const durationOptions = [
        { label: 'All', code : 'all'},
        { label: 'Today', code : 'today'},
        { label: 'Yesterday', code : 'yesterday'},
        { label: 'This Month', code : 'this-month'},
        { label: 'Custom', code : 'custom'}
    ]

    const [ trackingData, setTrackingData] = useState(null)
    const [ filteredTrackingData, setFilteredTrackingData] = useState(null)
    const [ globalFilter, setGlobalFilter] = useState('')
    const [ loading, setLoading] = useState(true)
    const [ technomans, setTechnomans] = useState(null)
    const [ selectedTechnoman, setSelectedTechnoman] = useState({ name : 'All'})
    const [ selectedDuration, setSelectedDuration] = useState({ label: 'All', code : 'all'})
    const [ allDates, setAllDates] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [customFilterDialog, setCustomFilterDialog] = useState(false);
    
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');

    const dt = useRef(null);

    useEffect(()=>{
        getData()
        getUsers()
        getTimes()
    },[])

    const getData = async() =>{
        const response = await apiServices.getTechnomanTrackings();
        console.log(response?.data?.data);
        setTrackingData(response?.data?.data);
        setFilteredTrackingData(response?.data?.data)
        setLoading(false)
    }

    const getUsers = async() =>{
        const response = await apiServices.getUsersByType({userType : "superman"});
        console.log(response?.data?.data);
        response?.data?.data.unshift({ name : 'All'})
        setTechnomans(response?.data?.data)
    }

    const getTimes = async() =>{
        const response = await commonCal.getDates()
        console.log(response);
        setAllDates(response)
    }
 
    const changeTechnoman = (e) => {
        setSelectedTechnoman(e.target.value)
        if(e.target.value?.name == 'All'){
            const data = selectedDate ? trackingData.filter((x)=>{ return x.date > selectedDate.startDate && x.date < selectedDate.endDate }) : trackingData;
            setFilteredTrackingData(data)
        }else{
            const fil = selectedDate ? trackingData.filter((x)=>{ return x.date > selectedDate.startDate && x.date < selectedDate.endDate && x.userName == e.target.value?.name }) : trackingData?.filter((x)=>{ return x.userName == e.target.value?.name });
            setFilteredTrackingData(fil)
        }
    }

    const changeDuarion = (e) =>{
        setSelectedDuration(e.target.value)
        switch (e.target.value.code) {
            case 'all':{
                const data = selectedTechnoman.name == 'All' ? trackingData : trackingData.filter((x)=>{ return x.userName == selectedTechnoman.name});
                setFilteredTrackingData(data)
                setSelectedDate(null)
                break;
            }
            case 'today':{
                const data = selectedTechnoman.name == 'All' ? trackingData.filter((x)=>{ return x.date > allDates.startToday && x.date < allDates.endToday }) : trackingData.filter((x)=>{ return x.date > allDates.startToday && x.date < allDates.endToday && x.userName == selectedTechnoman.name})
                setFilteredTrackingData(data)
                setSelectedDate({startDate :allDates.startToday , endDate : allDates.endToday })
                break;
            }
            case 'yesterday':{
                const data = selectedTechnoman.name == 'All' ? trackingData.filter((x)=>{ return x.date > allDates.YestStart && x.date < allDates.EndStart}) : trackingData.filter((x)=>{ return x.date > allDates.YestStart && x.date < allDates.EndStart && x.userName == selectedTechnoman.name})
                setFilteredTrackingData(data)
                setSelectedDate({startDate :allDates.YestStart , endDate : allDates.EndStart })
                break;
            }
            case 'this-month':{
                const data = selectedTechnoman.name == 'All' ? trackingData.filter((x)=>{ return x.date > allDates.monthStart && x.date < allDates.endThisMonth }) : trackingData.filter((x)=>{ return x.date > allDates.monthStart && x.date < allDates.endThisMonth && x.userName == selectedTechnoman.name })
                setFilteredTrackingData(data)
                setSelectedDate({startDate :allDates.monthStart , endDate : allDates.endThisMonth })
                break;
            }
            case 'custom' : {
                setCustomFilterDialog(true);
                break;
            }
            default:{
                const data = selectedTechnoman.name == 'All' ? trackingData.filter((x)=>{ return x.date > allDates.startToday && x.date < allDates.endToday }) : trackingData.filter((x)=>{ return x.date > allDates.startToday && x.date < allDates.endToday && x.userName == selectedTechnoman.name })
                setFilteredTrackingData(data)
                setSelectedDate({startDate :allDates.startToday , endDate : allDates.endToday })
                break;
            }
        }
    }

    const hideCustomDialog = () =>{
        setCustomFilterDialog(false);
        setSelectedDuration({label:'All',code:'all'});
    }

    const handleSubmit = (e) =>{
        setCustomFilterDialog(false)
        e.preventDefault()
        const start = e.target.start_date.value;
        const end = e.target.end_date.value;
        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        let cust_start = new Date(startt).toISOString();
        let cust_end = new Date(endd).toISOString();
        setSelectedDate({startDate :cust_start , endDate : cust_end })
        const data = selectedTechnoman.name == 'All' ? trackingData.filter((x)=>{ return x.date > cust_start && x.date < cust_end }) : trackingData.filter((x)=>{ return x.date > cust_start && x.date < cust_end && x.userName == selectedTechnoman.name })
        setFilteredTrackingData(data)
    }

    const header = (
        <div className="table-header">
            <h6>Technoman's Tracking Data</h6>
            <span className="p-input-icon-left">
                <div className="p-inputgroup" >
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </div>
            </span>
        </div>
    );


    const srBodyTemplate = (rowData, props) => {return (<><span className="p-column-title">{props.header}</span>{props.rowIndex + 1}</>);}

    const bodyTemplate = (data, props) => {        
        return (
            <> <span className="p-column-title">{props.header}</span>{ data[props.field] ? data[props.field] : '-'} </>
        )
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Profile</span>
                {/* <img src={ "assets/demo/images/user/default-user.png" } alt="profile" className="userImage" /> */}
                <img src={ !rowData.image ? "assets/demo/images/user/default-user.png" : 'data:image/png;base64,' + rowData.image } alt="profile" className="userImage" />
            </>
        )
    }

    const dateBodyTemplate = (data, props) => {   
        const date = data[props.field];
        return (
            <><span className="p-column-title">{props.header}</span>{ date ? new Date(date).toLocaleString('en-IN') : '-'}</>
        )
    };

    const statusbodyTemplate = (rowData, props) =>{return (
        <>
            <span className="p-column-title">{props.header}</span>
            { rowData[props.field] ? rowData[props.field] == 'checkOut' ? <h6 className="p-error"> Check Out <i className="pi pi-sign-out"></i></h6> : <h6 style={{color : '#00A242'}} >Check In <i className="pi pi-sign-in"></i></h6>   : '-'}
        </>
    )};

    const rightToolbarTemplate = () => {
        return (
            <>
                <div className='p-col-12'>
                    <div className="p-inputgroup p-mt-2">
                        <Button className='custom-group-btn' label="Technoman" />
                        <Dropdown value={selectedTechnoman} onChange={changeTechnoman} options={technomans} optionLabel="name" placeholder="Select Technoman" filter className="multiselect-custom" />
                    </div>
                </div>
            </>
        )
    }

    const leftToolbarTemplate = () =>{
        return (
            <>
                <div className='p-col-12'>
                    <div className="p-inputgroup p-mt-2">
                        <Button className='custom-group-btn' label="Duration" />
                        <Dropdown value={selectedDuration} onChange={changeDuarion} options={durationOptions} optionLabel="label" placeholder="Select Duration" filter className="multiselect-custom" />
                    </div>
                </div>
            </>
        )
    }


    return(
        <>
            <div className="p-grid crud-demo">
            <div className="p-col-12">
            <div className="card">
                { !loading && <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> } 
                <DataTable ref={dt} value={filteredTrackingData}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Data"
                    globalFilter={globalFilter} emptyMessage="No Data found." header={header} loading={loading}>
                    <Column field="srno" header="Sr. No." body={srBodyTemplate} sortable ></Column>
                    <Column field="image" header="Image" body={imageBodyTemplate} sortable></Column>
                    <Column field="machineId" header="CTVM Id" body={bodyTemplate} sortable></Column>
                    <Column field="userName" header="Name" body={bodyTemplate} sortable></Column>
                    <Column field="status" header="Status" body={statusbodyTemplate} sortable></Column>
                    <Column field="date" header="Date (mm-dd-yyyy)" body={dateBodyTemplate} sortable></Column>
                </DataTable>
            </div>
            </div>
            </div>


            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter"  onHide={hideCustomDialog} modal className="p-fluid">
                <form onSubmit={handleSubmit}>
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From</label>
                            <InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => {setCustomStart(e.value) } } required autoFocus/>
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' value={customEnd} onChange={(e) => {setCustomEnd(e.value)}} required/>
                        </div>
                    </div>
                    <div className="p-dialog-footer p-mt-4">
                        <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
                        <button className="p-button p-button-success" type="submit"><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
                    </div>
                </form>
            </Dialog>
            
        </>
    )
}